import gql from 'graphql-tag';

import type { GraphqlMutationAction } from 'store/models';

const namespace = 'MENTION_NOTIFICATION';
const NOTIFY_ALL_IN_CONVERSATION = `${namespace}/NOTIFY_ALL_IN_CONVERSATION`;
const NOTIFY_ALL_MENTIONED = `${namespace}/NOTIFY_ALL_MENTIONED`;
const NOTIFY_SOME_MENTIONED = `${namespace}/NOTIFY_SOME_MENTIONED`;
const NOTIFY_ALL_MENTIONED_IN_EVENT_NOTE = `${namespace}/NOTIFY_ALL_MENTIONED_IN_EVENT_NOTE`;
const NOTIFY_SOME_MENTIONED_IN_EVENT_NOTE = `${namespace}/NOTIFY_SOME_MENTIONED_IN_EVENT_NOTE`;

const NOTIFY_ALL_MUTATION = gql`
  mutation ($payload: AllMentionedNotificationInput!) {
    notifyAllMentioned(data: $payload)
  }
`;

type notifyAllMentionedAC = (payload: {
  location: string;
  noteId: string;
  settings: string;
}) => GraphqlMutationAction;

export const notifyAllMentioned: notifyAllMentionedAC = payload => ({
  type: NOTIFY_ALL_MENTIONED,
  payload: {
    key: 'notifyAllMentioned',
    graphql: {
      mutation: NOTIFY_ALL_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const NOTIFY_ALL_IN_CONVERSATION_MUTATION = gql`
  mutation ($payload: AllMentionedNotificationInput!) {
    notifyAllInConversation(data: $payload)
  }
`;

type notifyAllInConversationAC = (payload: {
  location: string;
  noteId: string;
  settings: string;
}) => GraphqlMutationAction;

export const notifyAllInConversation: notifyAllInConversationAC = payload => ({
  type: NOTIFY_ALL_IN_CONVERSATION,
  payload: {
    key: 'notifyAllInConversation',
    graphql: {
      mutation: NOTIFY_ALL_IN_CONVERSATION_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const NOTIFY_ALL_MUTATION_IN_EVENT_NOTE = gql`
  mutation ($payload: AllMentionedNotificationInput!) {
    notifyAllMentionedInEventNote(data: $payload)
  }
`;

export const notifyAllMentionedInEventNote: notifyAllMentionedAC = payload => ({
  type: NOTIFY_ALL_MENTIONED_IN_EVENT_NOTE,
  payload: {
    key: 'notifyAllMentionedInEventNote',
    graphql: {
      mutation: NOTIFY_ALL_MUTATION_IN_EVENT_NOTE,
      variables: {
        payload,
      },
    },
  },
});

const NOTIFY_SOME_MUTATION = gql`
  mutation ($payload: SomeMentionedNotificationInput!) {
    notifySomeMentioned(data: $payload)
  }
`;

type notifySomeMentionedAC = (payload: {
  mentionedIds: string[];
  location: string;
  noteId: string;
  settings: string;
}) => GraphqlMutationAction;

export const notifySomeMentioned: notifySomeMentionedAC = payload => ({
  type: NOTIFY_SOME_MENTIONED,
  payload: {
    key: 'notifySomeMentioned',
    graphql: {
      mutation: NOTIFY_SOME_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const NOTIFY_SOME_MUTATION_IN_EVENT_NOTE = gql`
  mutation ($payload: SomeMentionedNotificationInput!) {
    notifySomeMentionedInEventNote(data: $payload)
  }
`;

type notifySomeMentionedInEventNoteAC = (payload: {
  mentionedIds: string[];
  location: string;
  noteId: string;
  settings: string;
}) => GraphqlMutationAction;

export const notifySomeMentionedInEventNote: notifySomeMentionedInEventNoteAC =
  payload => ({
    type: NOTIFY_SOME_MENTIONED_IN_EVENT_NOTE,
    payload: {
      key: 'notifySomeMentionedInEventNote',
      graphql: {
        mutation: NOTIFY_SOME_MUTATION_IN_EVENT_NOTE,
        variables: {
          payload,
        },
      },
    },
  });
