import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import { LOGOUT } from 'modules/auth/AuthActions';
import type {
  Group,
  Well,
  WellColumnMappingItem,
} from 'modules/well/models/well';
import {
  FETCH_WELLS,
  FETCH_WELL_COLUMN_MAPPING,
  POPULATE_WELLS,
  POPULATE_WELLS_LEASE_TO_ID_MAPPING,
  SET_WELLS_WITH_CUSTOM_VALUES,
} from './WellActions';
import { getGraphqlPayload } from 'store/helpers';
import type { Action, Selector } from 'store/models';
import { getFilters } from 'modules/filter/FilterReducer';

const FETCH_WELLS_SUCCESS = `${FETCH_WELLS}_SUCCESS`;
const FETCH_WELL_COLUMN_MAPPING_SUCCESS = `${FETCH_WELL_COLUMN_MAPPING}_SUCCESS`;
export const STATE_KEY = 'well';

export type WellState = {
  wells: { [id: string]: Well };
  wellsWithCustomValues: { [id: string]: Well };
  wellsLeaseToIdMapping: Record<string, string>;
  columnMapping: WellColumnMappingItem[];
};

const initialState = {} as WellState;

const WellReducer = (state: WellState = initialState, action: Action) => {
  switch (action.type) {
    case POPULATE_WELLS: {
      return R.assoc<{ [id: string]: Well }, WellState>(
        'wells',
        action.payload,
        state,
      );
    }
    case POPULATE_WELLS_LEASE_TO_ID_MAPPING: {
      return { ...state, wellsLeaseToIdMapping: action.payload };
    }
    case SET_WELLS_WITH_CUSTOM_VALUES: {
      return R.assoc('wellsWithCustomValues', action.payload, state);
    }
    case FETCH_WELL_COLUMN_MAPPING_SUCCESS: {
      const columnMapping = getGraphqlPayload(action);
      const groupFromColumns = columnMapping
        .filter(column => column.groupByOrder)
        .sort((a, b) => a.groupByOrder - b.groupByOrder)
        .map(column => ({
          key: column.sourceName,
          title: column.displayName,
          index: column.groupByOrder,
        }));
      const groups = [
        {
          key: 'all',
          title: 'All',
          index: 0,
        },
        ...groupFromColumns,
        {
          key: 'well',
          title: 'Well',
          index: groupFromColumns.length + 1,
        },
      ];

      return { ...state, columnMapping, groups };
    }
    case LOGOUT: {
      return R.assoc<string, WellState>('currentId', '', state);
    }
    default: {
      return state;
    }
  }
};

export const getWellsState = (_: Record<string, any>) =>
  R.pathOr({}, [STATE_KEY], _);
export const getAllWells = (_: Record<string, any>) =>
  R.pathOr({}, [STATE_KEY, 'wells'], _);
export const getWellsAsArray = createSelector(
  getWellsState,
  (_: Record<string, any>) => R.values(R.pathOr({}, ['wells'], _)),
);
export const getWellsWithCustomValues = createSelector(
  getWellsState,
  (_: Record<string, any>) => R.pathOr({}, ['wellsWithCustomValues'], _),
);
export const getWellsWithCustomValuesAsArray = createSelector(
  getWellsState,
  (_: Record<string, any>) =>
    R.values(R.pathOr({}, ['wellsWithCustomValues'], _)),
);
export const getLeaseToIdMapping = (_: Record<string, any>) =>
  R.pathOr({}, [STATE_KEY, 'wellsLeaseToIdMapping'], _);
export const getColumnMapping = (_: Record<string, any>) =>
  R.pathOr([], [STATE_KEY, 'columnMapping'], _);
export const getColumnMappingByIndex = (_: Record<string, any>) =>
  R.compose(
    R.indexBy<number, Record<string, any>>(R.prop('columnIndex')),
    R.pathOr([], [STATE_KEY, 'columnMapping']),
  )(_);
export const getColumnMappingById = (_: Record<string, any>) =>
  R.compose(
    R.indexBy<number, Record<string, any>>(R.prop('id')),
    R.pathOr([], [STATE_KEY, 'columnMapping']),
  )(_);
export const getColumnMappingBySource = (_: Record<string, any>) =>
  R.compose(
    R.indexBy<string, Record<string, any>>(
      obj => obj.wiserockBinding || obj.sourceName,
    ),
    R.pathOr([], [STATE_KEY, 'columnMapping']),
  )(_);
export const getWellName = (_: Record<string, any>, wellId: string) => {
  const wells = R.pathOr({}, [STATE_KEY, 'wells'], _);

  if (!wells[wellId]) return '';

  return wells[wellId].LEASE;
};

export const getWellInfo = (_: Record<string, any>, wellId: string) =>
  R.pathOr({}, [STATE_KEY, 'wells', wellId], _);
export const getWellsByCriteria = (
  _: Record<string, any>,
  criteria: string,
  item: string,
): Well[] => {
  const allWells = getWellsAsArray(_);
  return allWells.filter(well => well[criteria] === item);
};

export const getGroups: Selector<Group[]> = (state: Record<string, any>) =>
  state[STATE_KEY].groups || [];

export const getGroupsByKey: Selector<{
  [key: string]: Group;
}> = createSelector(getGroups, groups =>
  R.indexBy<any, Record<string, any>>(R.prop('key'), groups),
);

export const getAdditionalGroupKeys: Selector<string[]> = createSelector(
  getGroups,
  (groups: { key: string }[]) =>
    groups
      .map(group => group.key)
      .filter(groupKey => groupKey !== 'well' && groupKey !== 'all'),
);

export const getWellsOfGroupQuant = createSelector(
  getFilters,
  (_, param) => param,
  state => state.drilldownTable.currentMarkedRows,
  state => state.drilldownTable.currentMarkedRows.length > 1,
  getWellsWithCustomValues,
  (
    filters: { [filterKey: string]: string[] },
    currentGroup,
    currentMarkedRows,
    isMarkingRows,
    wells: { [id: string]: Well },
  ) => {
    return Object.values(wells).filter(well => {
      const matchesGroup =
        currentGroup.subject === 'all' ||
        (isMarkingRows
          ? currentMarkedRows.includes(well[currentGroup.subject || 'LEASE'])
          : well[currentGroup.subject] === currentGroup.item ||
            (currentGroup.item === '' && R.isNil(well[currentGroup.subject])));
      const matchesFilters = Object.keys(filters).every(key => {
        if (filters[key].length === 0) return true;
        const value = R.clone(filters[key]);
        if (value.includes('')) {
          value.push(null);
        }
        return value.includes(well[key]);
      });
      return matchesGroup && matchesFilters;
    }).length;
  },
);

export default filterActions(WellReducer, [
  FETCH_WELLS_SUCCESS,
  SET_WELLS_WITH_CUSTOM_VALUES,
  POPULATE_WELLS,
  POPULATE_WELLS_LEASE_TO_ID_MAPPING,
  FETCH_WELL_COLUMN_MAPPING_SUCCESS,
  LOGOUT,
]);
