import gql from 'graphql-tag';
import * as R from 'ramda';

import type {
  Action,
  GraphqlQueryAction,
  GraphqlMutationAction,
} from 'store/models';

export const namespace = 'NOTES';
export const FETCH_NOTES = `${namespace}/FETCH_NOTES`;
export const ADD_NOTE = `${namespace}/ADD_NOTE`;
export const REMOVE_NOTE = `${namespace}/REMOVE_NOTE`;
export const UPDATE_NOTE = `${namespace}/UPDATE_NOTE`;
export const DELETE_NOTE_ATTACHMENT_LOCALLY = `${namespace}/DELETE_NOTE_ATTACHMENT_LOCALLY`;

const FETCH_NOTES_QUERY = gql`
  query ($wellId: ID!) {
    listWellNotes(wellId: $wellId) {
      id
      userId
      wellId
      timeStamp
      productionDate
      noteText
      isEdited
      attachments {
        id
        name
        url
      }
    }
  }
`;

type fetchNotesAC = (wellId: string) => GraphqlQueryAction;

export const fetchNotes: fetchNotesAC = wellId => ({
  type: FETCH_NOTES,
  payload: {
    key: 'listWellNotes',
    graphql: {
      query: FETCH_NOTES_QUERY,
      variables: { wellId },
    },
  },
});

const ADD_NOTE_MUTATION = gql`
  mutation ($payload: NoteInput!) {
    createNote(data: $payload) {
      id
      userId
      wellId
      timeStamp
      productionDate
      noteText
      isEdited
      attachments {
        id
        name
        url
      }
    }
  }
`;

type addNoteAC = (payload: {
  userId: string;
  wellId: string;
  timeStamp: string;
  productionDate: Date;
  noteText: string;
  attachments?: {
    add?: Record<string, any>[];
    remove?: string[];
  };
}) => GraphqlMutationAction;

export const addNote: addNoteAC = payload => ({
  type: ADD_NOTE,
  payload: {
    key: 'createNote',
    graphql: {
      mutation: ADD_NOTE_MUTATION,
      variables: { payload },
    },
  },
});

const UPDATE_NOTE_MUTATION = gql`
  mutation ($id: ID!, $payload: NoteInput!) {
    updateNote(id: $id, data: $payload) {
      id
      userId
      wellId
      timeStamp
      productionDate
      noteText
      isEdited
      attachments {
        id
        name
        url
      }
    }
  }
`;

type updateNoteAC = (payload: any) => Action;

export const updateNote: updateNoteAC = payload => ({
  type: UPDATE_NOTE,
  payload: {
    key: 'updateNote',
    graphql: {
      mutation: UPDATE_NOTE_MUTATION,
      variables: {
        id: payload.id,
        payload: R.omit(['id', 'attachments'], payload),
      },
    },
  },
});

const REMOVE_NOTE_MUTATION = gql`
  mutation ($id: ID!) {
    deleteNote(id: $id)
  }
`;

type removeNoteAC = (payload: { wellId: string; noteId: string }) => Action;

export const removeNote: removeNoteAC = ({ wellId, noteId }) => ({
  type: REMOVE_NOTE,
  payload: {
    key: 'deleteNote',
    graphql: {
      mutation: REMOVE_NOTE_MUTATION,
      variables: {
        wellId,
        id: noteId,
      },
    },
  },
});

type deleteNoteAttachmentLocallyAC = (payload: {
  wellId: string;
  noteId: string;
  attachmentId: string;
}) => Action;

export const deleteNoteAttachmentLocally: deleteNoteAttachmentLocallyAC =
  payload => ({
    type: DELETE_NOTE_ATTACHMENT_LOCALLY,
    payload,
  });
