import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import paths from 'modules/router/utils/paths';
import DashboardPage from 'modules/dashboard/pages/DashboardPage';
import FilterPage from 'modules/filter/pages/FilterPage';
import { AuthCallback } from 'modules/auth/pages/AuthCallback';
import SpotfirePersistor from 'modules/spotfire/components/DashboardsPersistor';
import { Redirect } from 'react-router';

const AdminRoute = loadable(() => import('modules/auth/components/AdminRoute'));
const BacklogPage = loadable(() => import('modules/backlog/pages/BacklogPage'));
const ChangePasswordPage = loadable(
  () => import('modules/auth/pages/ChangePasswordPage'),
);
const LoginPage = loadable(() => import('modules/auth/pages/LoginPage'));
const LogoutPage = loadable(() => import('modules/auth/pages/LogoutPage'));
const PrivateRoute = loadable(
  () => import('modules/auth/components/PrivateRoute'),
);
const SharePage = loadable(() => import('modules/settings/pages/SharePage'));
const ForgotPassword = loadable(
  () => import('modules/user/pages/ForgotPasswordPage'),
);
const UserManagementPage = loadable(
  () => import('modules/user/pages/UserManagementPage'),
);
const SpotfireDashboardsPage = loadable(
  () => import('modules/spotfire/pages/SpotfireDashboardsPage'),
);
const SpotfireDashboardPage = loadable(
  () => import('modules/spotfire/pages/SpotfireDashboardPage'),
);

export default (
  <div>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path={paths.AUTH_CALLBACK()} component={AuthCallback} />
        <Route
          exact
          path={paths.AUTH_LOGIN()}
          render={() => <LoginPage loginType="common" />}
        />
        <Route
          exact
          path={paths.AUTH_LOGIN_INTERNAL()}
          render={() => <LoginPage loginType="internal" />}
        />
        <Route exact path={paths.AUTH_LOGOUT()} component={LogoutPage} />
        <Route
          exact
          path={paths.AUTH_PASSWORD_RESET()}
          component={ForgotPassword}
        />
        <Route path={paths.SHARE()} component={SharePage} />
        <Route
          exact
          path={paths.AUTH_PASSWORD_CHANGE()}
          component={ChangePasswordPage}
        />
        <PrivateRoute
          exact
          path={paths.DASHBOARD()}
          component={DashboardPage}
        />
        <PrivateRoute
          exact
          path={paths.DASHBOARD_FOR_WELL()}
          component={DashboardPage}
        />
        <PrivateRoute
          exact
          path={paths.DASHBOARD_FOR_GROUP()}
          component={DashboardPage}
        />
        <PrivateRoute exact path={paths.FILTER()} component={FilterPage} />
        <PrivateRoute exact path={paths.BACKLOG()} component={BacklogPage} />
        <AdminRoute
          exact
          path={paths.USER_MANAGEMENT()}
          component={UserManagementPage}
        />

        <PrivateRoute
          exact
          path={paths.SPOTFIRE_DASHBOARDS()}
          component={SpotfireDashboardsPage}
        />

        <PrivateRoute
          exact
          path={paths.SPOTFIRE_DASHBOARD()}
          component={SpotfireDashboardPage}
        />
        <Route exact path="" render={() => <Redirect to="/login" />} />
      </Switch>
      <SpotfirePersistor />
    </Suspense>
  </div>
);
