import { Field, Form, Formik } from 'formik';
import * as R from 'ramda';
import React from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { getCurrentSeriesLayoutData } from '../SeriesLayoutsReducer';

import AlertWindow from 'components/AlertWindow';
import Button from 'components/Button';
import { SaveIcon } from 'components/Icons';
import LayoutDialogWindow from '../components/LayoutDialogWindow';
import LayaoutDragOption from '../components/LayaoutDragOption';
import { SeriesLayoutOption } from '../models/seriesLayouts';
import { getId } from 'modules/auth/AuthReducer';
import { isInside } from 'modules/chart/utils';
import {
  createSeriesLayoutRemotely,
  renameSeriesLayoutRemotely,
  removeSeriesLayoutRemote,
  reorderUserLayoutsLocally,
  reorderSeriesLayoutsRemotely,
  updateSeriesLayoutRemotely,
} from '../SeriesLayoutsActions';
import useSeriesLayouts from '../hooks/useSeriesLayouts';

const getReplaceLayoutText = (name: string) =>
  `Would you like to replace the existing saved layout named "${name}"?`;

const SeriesLayouts = () => {
  const dispatch = useDispatch();
  const userId = useSelector(getId);
  const currentSeriesLayoutData = useSelector(getCurrentSeriesLayoutData);
  const dropDownListRef = React.useRef<HTMLDivElement>(null);
  const dropDownHeaderRef = React.useRef<HTMLDivElement>(null);
  const [isOpenSaveDialogWindow, setIsOpenSaveDialogWindow] =
    React.useState(false);
  const [isOpenDropDownList, setIsOpenDropDownList] = React.useState(false);
  const [hoveredOption, setHoveredOption] = React.useState<string>('');
  const [idLayoutToRename, setIdLayoutToRename] = React.useState('');
  const [idLayoutToRemove, setIdLayoutToRemove] = React.useState('');
  const [existedLayout, setExistedLayout] = React.useState<any>(false);
  const [isOpenReplaceDialog, setIsOpenReplaceDialog] = React.useState(false);

  const {
    baseSeriesLayouts,
    userSeriesLayouts,
    currentSeriesLayout,
    setCurrentSeriesLayout,
  } = useSeriesLayouts();

  const layoutToRename = React.useMemo(
    () => userSeriesLayouts.find(l => l.id === idLayoutToRename),
    [idLayoutToRename, userSeriesLayouts],
  );

  const toggleDropDownList = React.useCallback(
    () => setIsOpenDropDownList(!isOpenDropDownList),
    [isOpenDropDownList, setIsOpenDropDownList],
  );

  const openSaveDialogWindow = React.useCallback(
    () => setIsOpenSaveDialogWindow(true),
    [setIsOpenSaveDialogWindow],
  );
  const closeSaveDialogWindow = React.useCallback(
    () => setIsOpenSaveDialogWindow(false),
    [setIsOpenSaveDialogWindow],
  );

  const setLayout = React.useCallback(
    (setFieldValue, layout) => {
      setFieldValue('layaout', layout);
      setCurrentSeriesLayout(layout);
      setIsOpenDropDownList(false);
    },
    [setIsOpenDropDownList],
  );

  const changeLayoutOrder = React.useCallback(
    (dragId, hoverId) => {
      dispatch(reorderUserLayoutsLocally({ dragId, hoverId }));
    },
    [dispatch],
  );

  const onSubmitSaveLayout = React.useCallback(
    (name: string) => {
      const newLayout: SeriesLayoutOption = {
        id: uuid(),
        userId,
        name,
        configuration: currentSeriesLayout.configuration,
        order: userSeriesLayouts.length + 1,
      };
      dispatch(createSeriesLayoutRemotely(newLayout));
      closeSaveDialogWindow();
    },
    [
      currentSeriesLayout,
      createSeriesLayoutRemotely,
      dispatch,
      closeSaveDialogWindow,
      userId,
    ],
  );

  const onSubmitRenameLayout = React.useCallback(
    (id: string, name: string) => {
      dispatch(renameSeriesLayoutRemotely({ id, name }));
      setIdLayoutToRename('');
    },
    [dispatch, renameSeriesLayoutRemotely],
  );

  const onCreateLayout = React.useCallback(
    (name: string) => {
      const alreadyExistedLayout = userSeriesLayouts.find(
        layout => layout.name === name,
      );
      if (alreadyExistedLayout) {
        setIsOpenReplaceDialog(true);
        setExistedLayout(alreadyExistedLayout);
      } else {
        onSubmitSaveLayout(name);
      }
    },
    [onSubmitSaveLayout, setIsOpenReplaceDialog, existedLayout],
  );

  const onRenameLayout = React.useCallback(
    (id: string, name: string) => {
      const alreadyExistedLayout = userSeriesLayouts.find(
        layout => layout.name === name,
      );
      if (alreadyExistedLayout) {
        setIsOpenReplaceDialog(true);
        setExistedLayout(alreadyExistedLayout);
      } else {
        onSubmitRenameLayout(id, name);
      }
    },
    [
      onSubmitSaveLayout,
      idLayoutToRename,
      setIsOpenReplaceDialog,
      existedLayout,
    ],
  );

  const onReplaceLayout = React.useCallback(() => {
    const layoutWithConfiguration = idLayoutToRename.length
      ? layoutToRename
      : currentSeriesLayout;

    if (layoutWithConfiguration) {
      dispatch(
        updateSeriesLayoutRemotely(
          R.assoc(
            'configuration',
            layoutWithConfiguration.configuration,
            existedLayout,
          ),
        ),
      );
    }

    if (idLayoutToRename.length && layoutWithConfiguration) {
      setIdLayoutToRename('');
      dispatch(removeSeriesLayoutRemote({ id: layoutWithConfiguration.id }));
    }

    setIsOpenSaveDialogWindow(false);
  }, [setIsOpenReplaceDialog, existedLayout, currentSeriesLayout]);

  const onCloseReplaceDialog = React.useCallback(() => {
    setIsOpenReplaceDialog(false);
  }, [setIsOpenReplaceDialog]);

  const onSubmitDeleteLayout = React.useCallback(
    id => {
      dispatch(removeSeriesLayoutRemote({ id }));
    },
    [dispatch, renameSeriesLayoutRemotely],
  );

  const reorderUsersLayoutRemotly = React.useCallback(
    () => dispatch(reorderSeriesLayoutsRemotely({ data: userSeriesLayouts })),
    [dispatch, reorderSeriesLayoutsRemotely, userSeriesLayouts],
  );

  const getContainerAreaCoords = React.useCallback(
    elementBoundingRect => ({
      x1: elementBoundingRect.x,
      x2: elementBoundingRect.x + elementBoundingRect.width,
      y1: elementBoundingRect.y,
      y2: elementBoundingRect.y + elementBoundingRect.height,
    }),
    [],
  );

  const onMouseClickBlurHandler = React.useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const currentPointerPosition = {
        clientX,
        clientY,
      };
      if (
        dropDownListRef.current &&
        dropDownHeaderRef.current &&
        !isOpenSaveDialogWindow &&
        isOpenDropDownList &&
        !idLayoutToRename &&
        !idLayoutToRemove
      ) {
        const dropDownListCoords = getContainerAreaCoords(
          dropDownListRef.current.getBoundingClientRect(),
        );

        const dropDownHeaderCoords = getContainerAreaCoords(
          dropDownHeaderRef.current.getBoundingClientRect(),
        );
        const currentPointerIsInsideList = isInside(
          currentPointerPosition,
          dropDownListCoords,
        );

        const currentPointerIsInsideHeader = isInside(
          currentPointerPosition,
          dropDownHeaderCoords,
        );
        if (!currentPointerIsInsideList && !currentPointerIsInsideHeader) {
          toggleDropDownList();
        }
      }
    },
    [
      setIsOpenDropDownList,
      isOpenSaveDialogWindow,
      isOpenDropDownList,
      idLayoutToRename,
      getContainerAreaCoords,
      idLayoutToRemove,
    ],
  );

  React.useEffect(() => {
    const dashboardWrapper = document.querySelector('#imageDetailRoot');

    dashboardWrapper?.addEventListener(
      'mousedown',
      onMouseClickBlurHandler as EventListener,
    );
    return () =>
      dashboardWrapper?.removeEventListener(
        'mousedown',
        onMouseClickBlurHandler as EventListener,
      );
  }, [onMouseClickBlurHandler]);

  return (
    <>
      <SeriesLayouts.Wrapper>
        {/* @ts-expect-error */}
        <Formik
          enableReinitialize
          initialValues={{
            layaout: currentSeriesLayoutData || baseSeriesLayouts[0],
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <Field name="layaout" id="layaout">
                {({ field }) => (
                  <SeriesLayouts.DropDownContainer>
                    <SeriesLayouts.DropDownHeaderContainer
                      isNewLayout={!currentSeriesLayout.id}
                    >
                      <SeriesLayouts.DropDownHeader
                        ref={dropDownHeaderRef}
                        onClick={toggleDropDownList}
                      >
                        <SeriesLayouts.DropDownHeaderTitle>
                          {!currentSeriesLayout.id
                            ? 'Unsaved Layout'
                            : currentSeriesLayoutData.name || field.value.name}
                        </SeriesLayouts.DropDownHeaderTitle>
                        <SeriesLayouts.DropDownArrowIcon />
                      </SeriesLayouts.DropDownHeader>
                      {!currentSeriesLayout.id && (
                        <>
                          <Button
                            width={30}
                            height={30}
                            onClick={openSaveDialogWindow}
                          >
                            <SaveIcon />
                          </Button>
                        </>
                      )}
                    </SeriesLayouts.DropDownHeaderContainer>
                    {isOpenDropDownList && (
                      <SeriesLayouts.DropDownListContainer
                        ref={dropDownListRef}
                      >
                        <SeriesLayouts.DropDownList>
                          <SeriesLayouts.ListGroupLabel>
                            Shared Layouts:
                          </SeriesLayouts.ListGroupLabel>
                          {baseSeriesLayouts.map(layout => (
                            <SeriesLayouts.ListItem
                              onClick={() => setLayout(setFieldValue, layout)}
                              key={Math.random()}
                            >
                              <div />
                              <span>{layout.name}</span>
                            </SeriesLayouts.ListItem>
                          ))}
                          <SeriesLayouts.ListGroupLabel>
                            Your Layouts:
                          </SeriesLayouts.ListGroupLabel>
                          {userSeriesLayouts.map((layout, index) => (
                            <LayaoutDragOption
                              reorderUsersLayoutRemotly={
                                reorderUsersLayoutRemotly
                              }
                              key={layout.id + layout.configuration}
                              setLayout={setLayout}
                              setFieldValue={setFieldValue}
                              layout={layout}
                              setHoveredOption={setHoveredOption}
                              hoveredOption={hoveredOption}
                              changeLayoutOrder={changeLayoutOrder}
                              index={index}
                              setIdLayoutToRename={setIdLayoutToRename}
                              setIdLayoutToRemove={setIdLayoutToRemove}
                            />
                          ))}
                        </SeriesLayouts.DropDownList>
                      </SeriesLayouts.DropDownListContainer>
                    )}
                  </SeriesLayouts.DropDownContainer>
                )}
              </Field>
            </Form>
          )}
        </Formik>
      </SeriesLayouts.Wrapper>
      {idLayoutToRename && (
        <LayoutDialogWindow
          action="edit"
          onClose={() => setIdLayoutToRename('')}
          onSubmit={onRenameLayout}
          oldName={userSeriesLayouts.find(i => i.id === idLayoutToRename).name}
          layoutId={idLayoutToRename}
        />
      )}
      {isOpenSaveDialogWindow && (
        <LayoutDialogWindow
          action="save"
          onClose={closeSaveDialogWindow}
          onSubmit={onCreateLayout}
        />
      )}
      {idLayoutToRemove && (
        <AlertWindow
          handleClose={() => setIdLayoutToRemove('')}
          onDelete={() => onSubmitDeleteLayout(idLayoutToRemove)}
          layoutName={
            userSeriesLayouts.find(i => i.id === idLayoutToRemove).name
          }
        />
      )}
      {isOpenReplaceDialog && (
        <AlertWindow
          actionButton={'Replace'}
          handleClose={onCloseReplaceDialog}
          onDelete={onReplaceLayout}
          text={getReplaceLayoutText(R.propOr('', 'name', existedLayout))}
        />
      )}
    </>
  );
};

SeriesLayouts.Wrapper = styled.div``;

SeriesLayouts.DropDownContainer = styled.div`
  width: 100%;
  padding: 10px 9px 10px 16px;
`;

SeriesLayouts.DropDownHeaderContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 5px;
  grid-template-columns: ${props =>
    !props.isNewLayout ? 'auto' : 'auto 30px'};
`;

SeriesLayouts.DropDownHeader = styled.div`
  display: grid;
  grid-template-columns: auto 25px;

  padding-top: auto;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  border: 1px solid #c1c1c1;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  font-family: 'Lato', sans-serif;
  color: #484848;

  :hover {
    cursor: pointer;
  }
`;

SeriesLayouts.DropDownHeaderTitle = styled.span`
  padding-left: 5px;
  padding-top: 7px;
`;

SeriesLayouts.DropDownListContainer = styled.div`
  position: absolute;
  z-index: 54;
  width: 297px;
`;

SeriesLayouts.DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  font-weight: 500;
  padding-bottom: 5px;
`;

SeriesLayouts.ListGroupLabel = styled.div`
  font-weight: bold;
  padding: 5px;
`;

SeriesLayouts.ListItem = styled.li`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  grid-template-columns: 8px auto;
  align-items: center;
  gap: 5px;
  list-style: none;
  padding: 3px 0 4px 9.5px;

  :hover {
    background: #efefef;
    cursor: pointer;
  }
`;

SeriesLayouts.DragIconWrapper = styled.div`
  align-self: center;
  cursor: ns-resize;
`;

SeriesLayouts.DropDownArrowIcon = styled(RiArrowDropDownLine)`
  font-size: 30px;
`;

export default SeriesLayouts;
