import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import type { Action } from 'store/models';
import { getGraphqlPayload } from 'store/helpers';

import { SpotfireDashboard, SpotfireData } from './models';
import {
  FETCH_SPOTFIRE_SETTINGS_SUCCESS,
  FETCH_SPOTFIRE_DASHBOARDS_SUCCESS,
  SET_SPOTFIRE_FILTERED_WELLS,
  SET_SPOTFIRE_MARKED_WELLS,
  SET_SPOTFIRE_VIEW_START_DATE,
  SET_SPOTFIRE_VIEW_END_DATE,
} from './SpotfireActions';

export const STATE_KEY = 'spotfire';

export interface SpotfireState {
  settings?: SpotfireData;
  dashboards?: SpotfireDashboard[];
  filteredWells?: string[];
  markedWells?: string[];
  viewStartDate?: string;
  viewEndDate?: string;
}

const initialState: SpotfireState = {};

const SpotfireReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_SPOTFIRE_SETTINGS_SUCCESS: {
      const settings = getGraphqlPayload(action);
      return { ...state, settings };
    }
    case FETCH_SPOTFIRE_DASHBOARDS_SUCCESS: {
      const dashboards = getGraphqlPayload(action);
      return { ...state, dashboards };
    }
    case SET_SPOTFIRE_FILTERED_WELLS: {
      return { ...state, filteredWells: action.payload };
    }
    case SET_SPOTFIRE_MARKED_WELLS: {
      return { ...state, markedWells: action.payload };
    }
    case SET_SPOTFIRE_VIEW_START_DATE: {
      return { ...state, viewStartDate: action.payload };
    }
    case SET_SPOTFIRE_VIEW_END_DATE: {
      return { ...state, viewEndDate: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const getSpotfireState = (state: Record<string, any>) =>
  state[STATE_KEY] as SpotfireState;

export const getSpotfireSettings = createSelector(
  getSpotfireState,
  state => state.settings,
);

export const getSpotfireDashboards = createSelector(
  getSpotfireState,
  state => state.dashboards,
);

export const getSpotfireFilteredWells = createSelector(
  getSpotfireState,
  state => state.filteredWells,
);

export default filterActions(SpotfireReducer, [
  FETCH_SPOTFIRE_SETTINGS_SUCCESS,
  FETCH_SPOTFIRE_DASHBOARDS_SUCCESS,
  SET_SPOTFIRE_FILTERED_WELLS,
  SET_SPOTFIRE_MARKED_WELLS,
  SET_SPOTFIRE_VIEW_START_DATE,
  SET_SPOTFIRE_VIEW_END_DATE,
]);
