import { utcHour, TimeInterval } from 'd3-time';
import * as R from 'ramda';

import { getMinDate } from 'helpers';
import type { CapacityChangeEvent } from '../models/capacityChangeEvent';
import { ALL_PHASES_LIST } from 'modules/phase/models/phase';

import countCapacity from './countCapacity';

const countCapacityChartDataByPhase = ({
  capacityEvents,
  lastDate,
  nri,
}: {
  capacityEvents: CapacityChangeEvent[];
  lastDate: Date;
  nri?: number;
}) => {
  const interval = utcHour.every(12) as TimeInterval;
  const timeRange = capacityEvents.map((capEvent, i) => {
    const lastRangeDate =
      i !== 0 ? getMinDate(capacityEvents[i - 1].dayInit, lastDate) : lastDate;

    const datesInterval = interval.range(
      capEvent.dayInit,
      utcHour.offset(lastRangeDate, 12),
    );

    return datesInterval;
  });

  const timeRangesPhaseMap = ALL_PHASES_LIST.reduce((acc, phase) => {
    acc[phase] = R.clone(timeRange);

    return acc;
  }, {});

  return R.mapObjIndexed<Date[][], { date: Date; capacity: number }[][]>(
    (timeRanges, phase) =>
      timeRanges.map((range, i) =>
        range.map(date => ({
          date,
          capacity: nri
            ? countCapacity(date, capacityEvents[i], phase.toLowerCase()) * nri
            : countCapacity(date, capacityEvents[i], phase.toLowerCase()),
        })),
      ),
    timeRangesPhaseMap,
  );
};

export default countCapacityChartDataByPhase;
