import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import useStorage from 'hooks/useStorage';
import RightPanel from 'modules/dashboard/components/RightPanel';
import { getColumnMappingById } from 'modules/well/WellReducer';

import { LAYOUTS_PANEL_SIZES } from '../models';
import AvailableFiltersMenu from '../components/AvailableFiltersMenu';
import SelectedFiltersMenu from '../components/SelectedFiltersMenu';
import {
  getAvailableFiltersLayoutsOptionsArray,
  getCustomAvailableFiltersLayoutsOptionsArray,
  getCustomOptionsList,
  getSelectedFiltersLayoutsOptionsArray,
} from '../FilterLayoutsReducer';
import FiltersLayoutsSelect from './FiltersLayoutsSelect';

interface FilterLayoutsProps {
  isPanelOpen: boolean;
  onPanelClose: () => void;
  onChangeOptionVisible: (optionId: string) => void;
  onRemoveOption: (optionId: string, filterName: string) => void;
}

const FilterLayoutsPanel = ({
  isPanelOpen,
  onPanelClose,
  onChangeOptionVisible,
  onRemoveOption,
}: FilterLayoutsProps) => {
  const customFiltersLayoutsOptionsArray = useSelector(getCustomOptionsList);
  const columnMapingByIndex = useSelector(getColumnMappingById);
  const availableFiltersLayoutsOptions = useSelector(
    getAvailableFiltersLayoutsOptionsArray,
  );
  const customFiltersLayoutsOptions = useSelector(
    getCustomAvailableFiltersLayoutsOptionsArray,
  );
  const selectedFiltersLayoutsOptions = useSelector(
    getSelectedFiltersLayoutsOptionsArray,
    (left, right) => {
      const leftStringified = left.map(l => JSON.stringify(l));
      const rightStringified = right.map(r => JSON.stringify(r));
      return R.equals(leftStringified, rightStringified);
    },
  );

  const [viewportHeight, setViewportHeight] = React.useState(
    window.innerHeight,
  );
  const [isDragHandleDragging, setDragHandleDragging] = React.useState(false);

  const [availableFilterLayoutsHeight, setAvailableFilterLayoutsHeight] =
    useStorage(
      'availableFilterLayoutsHeight',
      availableFiltersLayoutsOptions.length
        ? Math.max(
            availableFiltersLayoutsOptions.length *
              LAYOUTS_PANEL_SIZES.optionHeight +
              LAYOUTS_PANEL_SIZES.labelHeight,
            LAYOUTS_PANEL_SIZES.minAvailableAreaHeight,
          )
        : LAYOUTS_PANEL_SIZES.minAvailableAreaHeight,
      'session',
    );

  const selectedLayoutsAreaHeight = React.useMemo(() => {
    const height =
      viewportHeight -
      //padding
      5 * 2 -
      availableFilterLayoutsHeight -
      LAYOUTS_PANEL_SIZES.titleBlock -
      LAYOUTS_PANEL_SIZES.dragArea;
    return Math.max(LAYOUTS_PANEL_SIZES.minSelectedAreaHeight, height);
  }, [viewportHeight, availableFilterLayoutsHeight]);

  const getNewAvailableAreaHeight = React.useCallback(
    (mouseYPos: number): number => viewportHeight - mouseYPos - 25,
    [viewportHeight],
  );

  const onDragHandleMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (!isDragHandleDragging) return;
      const mouseYPos = e.clientY;
      const newHeight = getNewAvailableAreaHeight(mouseYPos);
      setAvailableFilterLayoutsHeight(
        Math.max(LAYOUTS_PANEL_SIZES.minAvailableAreaHeight, newHeight),
      );
    },
    [
      setAvailableFilterLayoutsHeight,
      getNewAvailableAreaHeight,
      isDragHandleDragging,
    ],
  );

  const checkIfAttributeNameAlreadyExists = React.useCallback(
    (name: string) =>
      customFiltersLayoutsOptionsArray.find(o => o.displayName === name) !==
      undefined,
    [customFiltersLayoutsOptionsArray],
  );

  React.useEffect(() => {
    const resizeHandler = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <FilterLayoutsPanel.Container
      onMouseMove={onDragHandleMouseMove}
      onMouseUp={() => setDragHandleDragging(false)}
    >
      <RightPanel
        onDialogClose={onPanelClose}
        title="Layout Configuration"
        isShown
      >
        {isPanelOpen && (
          <FilterLayoutsPanel.Content className="filter-layouts-interactive interactive">
            <FilterLayoutsPanel.SelectedAreaWrapper
              areaHeight={selectedLayoutsAreaHeight}
            >
              <FilterLayoutsPanel.SelectWrapper>
                <FiltersLayoutsSelect />
              </FilterLayoutsPanel.SelectWrapper>

              <SelectedFiltersMenu
                checkIfAttributeNameAlreadyExists={
                  checkIfAttributeNameAlreadyExists
                }
                columnMapingByIndex={columnMapingByIndex}
                filtersLayoutsOptions={selectedFiltersLayoutsOptions}
                onChangeOptionVisible={onChangeOptionVisible}
                onRemoveOption={onRemoveOption}
              />
            </FilterLayoutsPanel.SelectedAreaWrapper>

            <AvailableFiltersMenu
              checkIfAttributeNameAlreadyExists={
                checkIfAttributeNameAlreadyExists
              }
              columnMapingByIndex={columnMapingByIndex}
              customFiltersLayoutsOptions={customFiltersLayoutsOptions}
              filtersLayoutsOptions={availableFiltersLayoutsOptions}
              height={availableFilterLayoutsHeight}
              setDragHandleDragging={() => setDragHandleDragging(true)}
            />
          </FilterLayoutsPanel.Content>
        )}
      </RightPanel>
    </FilterLayoutsPanel.Container>
  );
};

FilterLayoutsPanel.Container = styled.div`
  border: 1px solid #ccc;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 10%);
`;

FilterLayoutsPanel.SelectWrapper = styled.div`
  border-bottom: 1px solid #c1c1c1;
  margin-bottom: 16px;
  box-shadow: 0 3px 6px -2px rgba(8, 3, 3, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.05);
`;

FilterLayoutsPanel.MarginBtnWrapper = styled.div`
  margin-left: 10px;
`;

FilterLayoutsPanel.SelectedAreaWrapper = styled.div`
  overflow-y: auto;
  height: ${props => (props.areaHeight ? props.areaHeight + 'px' : '100%')};
`;

FilterLayoutsPanel.BorromContent = styled.div`
  display: grid;
  align-content: end;
  margin-bottom: 9px;
`;

FilterLayoutsPanel.Content = styled.div`
  display: grid;
  grid-template-rows:
    minmax(${LAYOUTS_PANEL_SIZES.minSelectedAreaHeight}px, auto)
    minmax(${LAYOUTS_PANEL_SIZES.minAvailableAreaHeight}px, max-content);
  padding-bottom: 10px;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
`;
export default FilterLayoutsPanel;
