import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import { OIL } from 'modules/phase/models/phase';

import {
  COMPARE_OPTION,
  MaxColumnWidth,
  NO_CAPACITY_MESSAGE,
  NO_FORECAST_MESSAGE,
} from '../models/drilldownTable';

const SPACE_RE = /\s/g;

const getRowTextMap = (rowData, titleName) => {
  if (!rowData.matched || (rowData.matched && R.isEmpty(rowData.matched)))
    return {
      beforeUnderlined: rowData[titleName],
      underlined: '',
      afterUnderlined: '',
    };
  const { matched } = rowData;
  const title = rowData[titleName];
  const beforeUnderlined = title
    .substring(0, matched[0])
    .replace(SPACE_RE, String.fromCharCode(160));
  const underlined = title
    .substring(matched[0], matched[1])
    .replace(SPACE_RE, String.fromCharCode(160));
  const afterUnderlined = title
    .substring(matched[1])
    .replace(SPACE_RE, String.fromCharCode(160));

  return { beforeUnderlined, underlined, afterUnderlined };
};

interface WellTableRowProps {
  className: string;
  columns: React.ReactNode;
  compareOption: string;
  bgColor?: string;
  format: (format: number) => string;
  index: number;
  isTotalRow: boolean;
  maxColumnWidth: MaxColumnWidth;
  onRowClick: (row: {
    event: React.MouseEvent<HTMLDivElement>;
    index: number;
    rowData: Record<string, any>;
  }) => void;
  rowData: Record<string, any>;
  style: { [prop: string]: string };
  firstColDataKey: string;
  secondColDataKey: string;
  hasColorEffects?: boolean;
  isHighlighted?: boolean;
  thirdColDataKey: string;
  fourthColDataKey: string;
}

const WellTableRow = ({
  className,
  compareOption,
  format,
  index,
  isHighlighted,
  isTotalRow,
  maxColumnWidth,
  onRowClick,
  rowData,
  style,
  firstColDataKey,
  secondColDataKey,
  hasColorEffects,
  thirdColDataKey,
  fourthColDataKey,
}: WellTableRowProps) => {
  const rowTextForAllWells = {
    beforeUnderlined: 'All',
    underlined: '',
    afterUnderlined: '',
  };
  const valueRowText =
    rowData[firstColDataKey] === 'all'
      ? rowTextForAllWells
      : getRowTextMap(rowData, firstColDataKey);

  const isExtVsCapMod = compareOption === COMPARE_OPTION.extVsCap;
  const isActVsExtMod = compareOption === COMPARE_OPTION.actVsExt;
  const isGroupped = React.useMemo(
    () => firstColDataKey !== 'well',
    [firstColDataKey],
  );

  const columnValues = React.useMemo(() => {
    const secondValue = rowData[secondColDataKey];
    const thirdValue = rowData[thirdColDataKey];
    const fourthValue = rowData[fourthColDataKey];

    if (isActVsExtMod) {
      return {
        second:
          Math.max(secondValue, thirdValue) === 0
            ? format(0)
            : format(secondValue),
        third: thirdValue === null ? '*' + format(0) : format(thirdValue),
        thirdMessage: thirdValue === null ? NO_FORECAST_MESSAGE : null,
        fourth:
          !isTotalRow && thirdValue === null
            ? '*' + format(fourthValue)
            : format(fourthValue),
        fourthMessage:
          !isTotalRow && thirdValue === null ? NO_FORECAST_MESSAGE : null,
      };
    }

    if (isExtVsCapMod) {
      return {
        second:
          isGroupped || secondValue !== null
            ? format(secondValue)
            : '*' + format(0),
        secondMessage:
          isGroupped || secondValue !== null ? null : NO_FORECAST_MESSAGE,
        third:
          isGroupped || thirdValue !== null || isTotalRow
            ? format(thirdValue)
            : '*' + format(rowData.actual),
        thirdMessage:
          isGroupped || thirdValue !== null || isTotalRow
            ? null
            : NO_CAPACITY_MESSAGE,
        fourth:
          !isTotalRow && (thirdValue === null || secondValue === null)
            ? '*' + format(fourthValue)
            : format(fourthValue),
        fourthMessage: !isTotalRow
          ? thirdValue === null
            ? NO_CAPACITY_MESSAGE
            : secondValue === null
            ? NO_FORECAST_MESSAGE
            : null
          : null,
      };
    }

    return {
      second:
        Math.max(secondValue, thirdValue) === 0
          ? format(0)
          : format(secondValue),
      third:
        isGroupped || thirdValue !== null || isTotalRow
          ? format(thirdValue)
          : '*' + format(secondValue),
      thirdMessage:
        isGroupped || thirdValue !== null || isTotalRow
          ? null
          : NO_CAPACITY_MESSAGE,
      fourth:
        !isTotalRow && thirdValue === null
          ? '*' + format(0)
          : format(fourthValue),
      fourthMessage:
        !isTotalRow && thirdValue === null ? NO_CAPACITY_MESSAGE : null,
    };
  }, [
    isExtVsCapMod,
    isActVsExtMod,
    rowData,
    isTotalRow,
    secondColDataKey,
    thirdColDataKey,
    fourthColDataKey,
  ]);

  const rowStyle = isHighlighted
    ? style
    : { ...style, backgroundColor: rowData.color || 'transparent' };

  return (
    <div
      aria-rowindex={index}
      aria-label="row"
      tabIndex={0}
      className={className}
      role="row"
      style={rowStyle}
      onClick={event => onRowClick?.call(null, { event, index, rowData })}
    >
      <WellTableRow.FirstCol
        aria-colindex="1"
        className="ReactVirtualized__Table__rowColumn"
        role="gridcell"
        title={rowData[firstColDataKey]}
      >
        {
          <>
            <span>{valueRowText.beforeUnderlined}</span>
            <WellTableRow.SearchResult>
              {valueRowText.underlined}
            </WellTableRow.SearchResult>
            <span>{valueRowText.afterUnderlined}</span>
          </>
        }
      </WellTableRow.FirstCol>

      <WellTableRow.MiddleCol
        aria-colindex="2"
        className="ReactVirtualized__Table__rowColumn"
        role="gridcell"
        title={columnValues.secondMessage ?? columnValues.second}
        hasColorEffects={hasColorEffects}
        isHighlighted={isHighlighted}
        positive={rowData[secondColDataKey] > 0}
        width={maxColumnWidth.secondColWidth}
      >
        {columnValues.second}
      </WellTableRow.MiddleCol>
      <WellTableRow.MiddleCol
        aria-colindex="2"
        className="ReactVirtualized__Table__rowColumn"
        role="gridcell"
        title={columnValues.thirdMessage ?? columnValues.third}
        hasColorEffects={hasColorEffects}
        isHighlighted={isHighlighted}
        positive={rowData[thirdColDataKey] > 0}
        width={maxColumnWidth.thirdColWidth}
      >
        {columnValues.third}
      </WellTableRow.MiddleCol>
      <WellTableRow.FourthCol
        aria-colindex="2"
        className="ReactVirtualized__Table__rowColumn"
        role="gridcell"
        title={columnValues.fourthMessage ?? columnValues.fourth}
        hasColorEffects={
          hasColorEffects &&
          Math.round(10 * rowData[fourthColDataKey]) !== 0 &&
          columnValues.fourth !== 'N/A'
        }
        isHighlighted={isHighlighted}
        positive={Math.round(10 * rowData[fourthColDataKey]) > 0}
        width={maxColumnWidth.fourthColWidth}
      >
        {columnValues.fourth}
      </WellTableRow.FourthCol>
    </div>
  );
};

export default WellTableRow;

WellTableRow.Col = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 12px;
`;

WellTableRow.FirstCol = styled(WellTableRow.Col)`
  flex: 1 1 200px;
  color: black;
`;

WellTableRow.MiddleCol = styled(WellTableRow.Col)`
  flex: 0 0 ${props => props.width}px;
  color: ${(props: Record<string, any>) => props.theme.colors.primaryText};
  box-sizing: content-box;
`;

WellTableRow.FourthCol = styled(WellTableRow.Col)`
  flex: 0 0 ${(props: Record<string, any>) => props.width}px;
  box-sizing: content-box;
  color: ${(props: Record<string, any>) =>
    props.hasColorEffects
      ? props.positive
        ? props.theme.colors[OIL]
        : props.theme.colors.criticalText
      : 'inherit'};
  font-weight: ${(props: Record<string, any>) =>
    props.hasColorEffects ? (props.positive ? 'normal' : 'bold') : 'normal'};
`;

WellTableRow.SearchResult = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;

WellTableRow.Span = styled.span`
  white-space: pre;
`;
