import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useNonInputKeydown } from 'hooks/useKeydown';
import { getFeatures, getAppConfig } from 'modules/appConfig/AppConfigReducer';

import useForecast from 'modules/externalForecast/hooks/useForecast';
import { addSeveralFilters, setFilter } from 'modules/filter/FilterActions';
import { getFilters } from 'modules/filter/FilterReducer';
import {
  changeGroupModeSubject,
  disableGroupMode,
  enableGroupMode,
  enableRegionOfInterestMode,
  resetActivePanel,
  setCurrentWellId,
  setCurrentGroup,
  activateDrilldownPanel,
} from 'modules/ui/UIActions';
import {
  getActivePanel,
  getCurrentWellId,
  getCurrentGroup,
  getGroupMode,
} from 'modules/ui/UIReducer';
import { DRILLDOWN_PANEL } from 'modules/ui/models/ui';
import {
  getAllWells,
  getColumnMapping,
  getGroups,
  getGroupsByKey,
} from 'modules/well/WellReducer';
import useStorage from 'hooks/useStorage';

import DrilldownTableForm from 'modules/drilldownTable/forms/DrilldownTableForm';
import WellDrilldownTable from 'modules/drilldownTable/components/WellDrilldownTable';
import VarianceDrilldownTable from 'modules/drilldownTable/components/VarianceDrilldownTable';
import useDrilldownTable from 'modules/drilldownTable/hooks/useDrilldownTable';
import { usePreventContext } from 'context/PreventContext';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
import {
  getCurrentMarkedRows,
  getIsMarkingRows,
} from '../DrilldownTableReducer';

export const DrilldownPanel = () => {
  const dispatch = useDispatch();

  const rightPanel = useRightPanel();
  const allWells = useSelector(getAllWells);
  const currentWellId = useSelector(getCurrentWellId);
  const currentGroup = useSelector(getCurrentGroup);
  const groupMode = useSelector(getGroupMode);
  const columnMapping = useSelector(getColumnMapping);
  const hasNri = columnMapping.some(item => item.wiserockBinding === 'NRI');
  const filters = useSelector(getFilters);
  const activePanel = useSelector(getActivePanel);
  const groups = useSelector(getGroups);
  const groupsByKey = useSelector(getGroupsByKey);
  const devFeatures = useSelector(getFeatures);
  const appConfig = useSelector(getAppConfig);
  const filtersApplied = R.pickBy(filter => !R.isEmpty(filter), filters);
  const filterQuant = R.keys(filtersApplied).length;
  const isMarkingRows = useSelector(getIsMarkingRows);
  const currentMarkedRows = useSelector(getCurrentMarkedRows);

  const setWellAsCurrentTable = React.useCallback(() => {
    setCurrentTable('WellDrilldownTable');
  }, []);
  const setVarianceAsCurrentTable = React.useCallback(() => {
    setCurrentTable('VarianceDrilldownTable');
  }, []);

  const forecast = useForecast();
  const drilldown = useDrilldownTable({
    subject: groupMode.subject,
    onResetVarianceOption: setWellAsCurrentTable,
    onVarianceTableRowChoose: setVarianceAsCurrentTable,
    onAddVarianceOption: setVarianceAsCurrentTable,
    onRemoveVarianceOption: setVarianceAsCurrentTable,
    onAddSeveralVarianceOptionIds: setVarianceAsCurrentTable,
  });

  const regionOfInterestModeOn = React.useCallback(() => {
    dispatch(resetActivePanel());
    dispatch(enableRegionOfInterestMode());
  }, [dispatch]);

  const turnOnGrouping = React.useCallback(
    subject => dispatch(enableGroupMode(subject)),
    [dispatch],
  );
  const turnOffGrouping = React.useCallback(
    () => dispatch(disableGroupMode()),
    [dispatch],
  );
  const changeGrouping = React.useCallback(
    subject => dispatch(changeGroupModeSubject(subject)),
    [dispatch],
  );

  const onDrilldownPanelClick = React.useCallback(
    () => dispatch(activateDrilldownPanel()),
    [dispatch],
  );

  const isActive = activePanel === DRILLDOWN_PANEL;
  const [storageVarianceTableHeight, setStorageVarianceTableHeight] =
    useStorage('varianceTableHeight', null, 'session');
  const [varianceTableHeight, setVarianceTableHeight] = React.useState(0);
  const [searchWellWord, setSearchWellWord] = React.useState('');
  const [searchVarianceWord, setSearchVarianceWord] = React.useState('');

  const [viewportHeight, setViewportHeight] = React.useState(
    window.innerHeight,
  );
  const [currentTable, setCurrentTable] = React.useState('WellDrilldownTable');
  const prevent = usePreventContext();

  const wellTableHeight = React.useMemo(() => {
    const withoutStaticElements = viewportHeight - 128;
    return !drilldown.isComparingActual
      ? withoutStaticElements + 20
      : withoutStaticElements - varianceTableHeight;
  }, [varianceTableHeight, viewportHeight, drilldown.isComparingActual]);

  const onWellTableRowChoose = React.useCallback(
    (rowData: { [key: string]: string | number }) => {
      const proceed = () => {
        rightPanel.unsetDialogOfType('CapacityChangeEvent');
        if (!groupMode.isOn) {
          dispatch(setCurrentWellId(rowData.wellId as string));
        } else {
          dispatch(
            setCurrentGroup({
              subject: groupMode.subject,
              item: rowData[groupMode.subject] as string,
            }),
          );
        }
      };

      prevent.dispatchEvent(proceed);
    },
    [dispatch, prevent.dispatchEvent, groupMode],
  );

  const closeVarianceDrilldownTable = React.useCallback(() => {
    setVarianceTableHeight(0);
  }, [setVarianceTableHeight]);

  const filterToCurrentItem = React.useCallback(() => {
    if (isMarkingRows) {
      dispatch(
        addSeveralFilters({
          filterName: currentGroup.subject ? currentGroup.subject : 'LEASE',
          filterValues: currentMarkedRows,
        }),
      );

      return;
    }

    const currentGroupingCriteria = currentGroup.subject
      ? currentGroup.subject
      : 'well';
    if (currentGroupingCriteria === 'all') {
      return;
    }
    if (currentGroupingCriteria === 'well') {
      dispatch(
        setFilter({
          filterName: 'LEASE',
          filterValue: allWells[currentWellId].LEASE,
        }),
      );
      return;
    }
    dispatch(
      setFilter({
        filterName: currentGroup.subject,
        filterValue: currentGroup.item,
      }),
    );
  }, [
    allWells,
    currentGroup,
    currentWellId,
    dispatch,
    currentMarkedRows,
    isMarkingRows,
  ]);

  const handleGroupSelection = React.useCallback(
    e => {
      if (e.currentTarget.id) {
        const groupingSubject = e.currentTarget.id;
        if (groupingSubject === 'well') {
          turnOffGrouping();
        } else if (!groupMode.isOn) {
          turnOnGrouping(groupingSubject);
        } else {
          changeGrouping(groupingSubject);
        }
      }
    },
    [groupMode, changeGrouping, turnOffGrouping, turnOnGrouping],
  );

  const handleFilterGroupSelection = React.useCallback(
    e => {
      filterToCurrentItem();
      handleGroupSelection(e);
    },
    [handleGroupSelection, filterToCurrentItem],
  );

  React.useEffect(() => {
    if (!drilldown.isComparingActual && varianceTableHeight > 0) {
      closeVarianceDrilldownTable();
    }
  }, [
    drilldown.isComparingActual,
    varianceTableHeight,
    closeVarianceDrilldownTable,
  ]);

  React.useEffect(() => {
    if (storageVarianceTableHeight === null) return;
    setVarianceTableHeight(storageVarianceTableHeight);
  }, [storageVarianceTableHeight]);

  useNonInputKeydown(
    ({ keyCode }) => {
      if (keyCode === 'KeyF' && drilldown.isComparingActual) {
        forecast.toggleVisibility();
      }
    },
    [drilldown.isComparingActual, forecast.toggleVisibility],
  );

  React.useEffect(() => {
    const resizeHandler = e => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div onClick={onDrilldownPanelClick}>
      <DrilldownTableForm
        appConfig={appConfig}
        beforeSubmit={drilldown.clear}
        currentDrilldownTableState={drilldown.currentDrilldownTableState}
        hasNri={hasNri}
        isActive={isActive}
        onSetCompareOption={drilldown.setCompareOption}
        regionOfInterestModeOn={regionOfInterestModeOn}
        setMaxDrilldownTableDate={drilldown.setMaxDate}
        setMinDrilldownTableDate={drilldown.setMinDate}
        setDrilldownTableOption={drilldown.setPhase}
        setGrossNet={drilldown.setGrossNet}
        setRateVolume={drilldown.setRateVolume}
      />
      <DrilldownPanel.InternalContainer
        varianceTableHeight={varianceTableHeight}
      >
        <WellDrilldownTable
          compareOption={drilldown.currentDrilldownTableState.compareOption}
          appConfig={appConfig}
          currentDrilldownTableState={drilldown.currentDrilldownTableState}
          currentGroup={currentGroup}
          currentTable={currentTable}
          currentWellId={currentWellId}
          devFeatures={devFeatures}
          groupForecastTable={drilldown.groupForecastTable}
          groupMode={groupMode}
          groupsByKey={groupsByKey}
          groupsByOrder={groups}
          groupedTable={drilldown.groupedTable}
          filterQuant={filterQuant}
          handleFilterGroupSelection={handleFilterGroupSelection}
          handleGroupSelection={handleGroupSelection}
          hasNri={hasNri}
          height={wellTableHeight}
          isTableLoading={
            drilldown.drilldownLoadingStatus.isWellTableFetching ||
            R.isEmpty(groupsByKey)
          }
          filterToCurrentItem={filterToCurrentItem}
          onSetCompareOption={drilldown.setCompareOption}
          onWellTableRowChoose={onWellTableRowChoose}
          searchWord={searchWellWord}
          setCurrentTable={setCurrentTable}
          setSearchWord={setSearchWellWord}
          setSorting={drilldown.setSorting}
          sortCriteria={drilldown.sortCriteria}
          sortDirection={drilldown.sortDirection}
          sortVarDirectionIndex={drilldown.sortVarDirectionIndex}
          totalRow={drilldown.totalRow}
          volumeType={
            drilldown.currentDrilldownTableState.rateVolume === 'Volume'
          }
          wellDrilldownTable={drilldown.wellDrilldownTable}
          wellForecastTable={drilldown.wellForecastTabale}
        />
        <VarianceDrilldownTable
          addSeveralOptions={drilldown.addSeveralVarianceOptionIds}
          addVarianceOption={drilldown.addVarianceOption}
          currentTable={currentTable}
          currentVarianceOptionIds={drilldown.currentVarianceOptionIds}
          height={varianceTableHeight}
          isTableLoading={
            drilldown.drilldownLoadingStatus.isVarianceTableFetching ||
            R.isEmpty(groupsByKey) ||
            R.isEmpty(drilldown.varianceDrilldownTable)
          }
          isDisplayed={drilldown.isComparingActual}
          onVarianceTableRowChoose={drilldown.onVarianceTableRowChoose}
          removeVarianceOption={drilldown.removeVarianceOption}
          resetVarianceOption={drilldown.resetVarianceOption}
          searchWord={searchVarianceWord}
          setSearchWord={setSearchVarianceWord}
          setStorageVarianceTableHeight={setStorageVarianceTableHeight}
          varianceDrilldownTable={drilldown.varianceDrilldownTable}
          volumeType={
            drilldown.currentDrilldownTableState.rateVolume === 'Volume'
          }
          setVarianceTableHeight={setVarianceTableHeight}
        />
      </DrilldownPanel.InternalContainer>
    </div>
  );
};

DrilldownPanel.InternalContainer = styled.div`
  overflow: hidden;
  margin: 0 8px;
  border-bottom: ${(props: Record<string, any>) =>
    props.varianceTableHeight <= 32 ? '1px solid #c1c1c1' : ''};
`;
