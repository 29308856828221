import { scaleUtc } from 'd3-scale';
import { utcDay } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';

interface OverlayAddCapacityProps {
  additionModeOff: () => void;
  extremeDates: { [key: string]: Date };
  height: number;
  leftOffset: number;
  onAddSegment: (dayInit: Date) => void;
  width: number;
}

const OverlayAddCapacity = (
  {
    additionModeOff,
    extremeDates,
    height,
    leftOffset,
    onAddSegment,
    width,
  }: OverlayAddCapacityProps,
  ref,
) => {
  const xScale = React.useMemo(
    () =>
      scaleUtc().range([0, width]).domain([extremeDates.min, extremeDates.max]),
    [extremeDates, width],
  );

  const [linePosition, setLinePosition] = React.useState(0);
  const moveLine = React.useCallback(
    e => {
      const newPosition = e.pageX - leftOffset - Y_AXIS_WIDTH - 10;
      setLinePosition(newPosition);
    },
    [leftOffset, setLinePosition],
  );
  const hideLine = () => setLinePosition(0);

  return (
    <OverlayAddCapacity.Container
      height={height}
      className="capacity-interactive interactive"
    >
      <svg
        ref={ref}
        preserveAspectRatio="none"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <g
          onClick={e => {
            e.persist();
            const dayInit = utcDay.floor(xScale.invert(linePosition));
            onAddSegment(dayInit);
            additionModeOff();
          }}
          onMouseMove={e => moveLine(e)}
          onMouseOver={e => moveLine(e)}
          onMouseLeave={hideLine}
        >
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={process.env.DEBUG ? 'rgba(0, 150, 150, 0.5)' : 'transparent'}
          />

          <line
            x1={0}
            y1={0}
            x2={0}
            y2={height}
            transform={`translate(${linePosition}, 0)`}
            strokeWidth="1"
            stroke="black"
            strokeDasharray="2"
          />
        </g>
      </svg>
    </OverlayAddCapacity.Container>
  );
};

OverlayAddCapacity.Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 75;
`;

export default React.memo<OverlayAddCapacityProps & { ref: any }>(
  React.forwardRef<Element, OverlayAddCapacityProps>(OverlayAddCapacity),
);
