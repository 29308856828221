import { utcDay } from 'd3-time';
import * as R from 'ramda';

import { Series } from '../models';

const normalizeSeries = (series: Series[]) => {
  const formatedSeries = series.map(data => ({
    ...R.omit(['wellId'], data),
    day: utcDay.round(new Date(data.day)),
  }));
  return formatedSeries.sort((a, b) => a.day.getTime() - b.day.getTime());
};

export default normalizeSeries;
