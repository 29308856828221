export const PERMISSION_KEYS = {
  isAllowedEditCapChanges: 'Edit Capacity Change Events',
  isAllowedEditVarEvents: 'Edit Variance Events',
  isAllowedEditAllocIssues: 'Edit Allocation Issues',
  isAllowedEditRibbonEvents: 'Edit Ribbon Events',
  isAdmin: 'Administrator',
  isUserManager: 'User Management',
};

export const PERMISSION_ORDERS = {
  isAllowedEditCapChanges: 1,
  isAllowedEditVarEvents: 2,
  isAllowedEditRibbonEvents: 3,
  isAdmin: 4,
  isUserManager: 5,
  isAllowedEditAllocIssues: 6,
};
