import { area, curveStepAfter } from 'd3-shape';
import { utcDay } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';

import { getTrellisGeneralClipPathId } from 'modules/chart/utils';
import type { ProductionPoint } from 'modules/production/models/production';

import { COLORS } from '../models/chart';

interface MainBarsGroupProps {
  data: Record<string, any>[] | ProductionPoint[];
  purpose: 'production' | 'variance';
  type: 'group' | 'well';
  phase?: string;
  xScale: any;
  yScale: any;
}

const MainBarsGroup = ({
  data,
  purpose,
  type,
  phase,
  xScale,
  yScale,
}: MainBarsGroupProps) => {
  const dateKey: string = purpose === 'variance' ? 'date' : 'day';
  const targetDataKey =
    type === 'well' && purpose === 'production' && phase
      ? phase.toLowerCase()
      : purpose;
  const lastRealDate = R.pathOr(new Date(), [data.length - 1, dateKey], data);
  const newDate = utcDay.offset(lastRealDate, 1);
  const dataWithEmptyLastDay = [...data, { [dateKey]: newDate }];

  const createArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x(d => xScale(R.pathOr(0, [dateKey], d)))
        .y1(d => yScale([R.pathOr(0, [targetDataKey], d)]))
        .y0(yScale(0))
        .curve(curveStepAfter),
    [xScale, yScale, dateKey, targetDataKey],
  );

  const color =
    purpose === 'variance'
      ? COLORS.uncategorizedVariance
      : COLORS.productionBar;

  return (
    <path
      className="panInteraction"
      fill={color}
      d={createArea(dataWithEmptyLastDay)}
      clipPath={`url(#${getTrellisGeneralClipPathId(phase || purpose)})`}
    />
  );
};

export default React.memo<MainBarsGroupProps>(MainBarsGroup);
