import { scaleUtc } from 'd3-scale';
import { utcDay, utcYear } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';

import { GroupToDisplay } from 'modules/chartOptions/models';
import { ChartOption } from 'modules/chartOptions/ChartOptionsSagas';
import { COMPARE_OPTION } from 'modules/drilldownTable/models/drilldownTable';
import { OIL, GAS, WATER, BOE } from 'modules/phase/models/phase';
import { getMinDate, getMaxDate } from 'helpers';

import { VARIANCE_TRELLIS, Y_AXIS_WIDTH } from '../models/chart';

interface OverlayRegionOfInterestProps {
  changeDrilldownTableParams: (drilldownParams: {
    phase?: string;
    minDate?: Date;
    maxDate?: Date;
    grossNet?: string;
    rateVolume?: string;
    compareOption?: string;
  }) => void;
  compareOption: string;
  coreSeries: ChartOption[];
  dataSeries: GroupToDisplay;
  phase?: string;
  extremeDates: { min: Date; max: Date };
  height: number;
  leftOffset: number;
  width: number;
  regionOfInterestModeOff: () => void;
  today: Date;
}

const OverlayRegionOfInterest = (
  {
    changeDrilldownTableParams,
    compareOption,
    coreSeries,
    dataSeries,
    extremeDates,
    height,
    leftOffset,
    phase,
    regionOfInterestModeOff,
    today,
    width,
  }: OverlayRegionOfInterestProps,
  ref,
) => {
  //TODO: component should receive that as prop
  const topOffset = 64;
  const xScale = scaleUtc()
    .range([0, width])
    .domain([extremeDates.min, extremeDates.max]);

  const [lineXPosition, setLineXPosition] = React.useState(0);
  const [lineYPosition, setLineYPosition] = React.useState(0);
  const [firstDate, setFirstDate] = React.useState<Date | null>(null);
  const [secondDate, setSecondDate] = React.useState<Date | null>(null);
  const [currentTrellis, setCurrentTrellis] = React.useState<string | null>(
    null,
  );

  const currentTrellisOrder = coreSeries.findIndex(
    series => series.id === currentTrellis,
  );

  const trellisHeight = height / (dataSeries.length + coreSeries.length);

  const clickHandler = React.useCallback(() => {
    if (!currentTrellis) return;
    const dateOfCursor = utcDay.round(xScale.invert(lineXPosition));
    if (!firstDate) {
      setFirstDate(dateOfCursor);
      return;
    }

    if (firstDate.getDate() === dateOfCursor.getDate()) {
      const dateWithOffset = new Date(dateOfCursor);
      dateWithOffset.setDate(dateWithOffset.getDate() + 1);
      setSecondDate(dateWithOffset);
    } else {
      setSecondDate(dateOfCursor);
    }
  }, [firstDate, xScale, currentTrellis, lineXPosition]);

  const maxAvailableDate = React.useMemo(() => {
    if (compareOption === COMPARE_OPTION.extVsCap) {
      return utcYear.offset(today, 5);
    }
    return today;
  }, [today, compareOption]);

  const moveLine = React.useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      const pointerXPosition = e.pageX - leftOffset - Y_AXIS_WIDTH - 10;
      const pointerYPosition = e.pageY - topOffset;
      const trellisIndex = Math.round(
        (pointerYPosition - (pointerYPosition % trellisHeight)) / trellisHeight,
      );
      if (
        trellisIndex >= coreSeries.length ||
        !(
          coreSeries[trellisIndex].id === OIL ||
          coreSeries[trellisIndex].id === GAS ||
          coreSeries[trellisIndex].id === WATER ||
          coreSeries[trellisIndex].id === BOE ||
          coreSeries[trellisIndex].id === VARIANCE_TRELLIS
        )
      ) {
        hideLine();
        return;
      }
      setCurrentTrellis(coreSeries[trellisIndex].id);
      setLineYPosition(pointerYPosition - (pointerYPosition % trellisHeight));
      setLineXPosition(Math.min(pointerXPosition, xScale(maxAvailableDate)));
    },
    [
      leftOffset,
      topOffset,
      trellisHeight,
      xScale,
      maxAvailableDate,
      coreSeries,
    ],
  );

  const hideLine = () => {
    setLineXPosition(0);
  };
  React.useEffect(() => {
    if (firstDate && secondDate && currentTrellis) {
      const min = getMinDate(firstDate, secondDate);
      const max = getMaxDate(firstDate, secondDate);
      if (currentTrellis === VARIANCE_TRELLIS) {
        changeDrilldownTableParams({
          minDate: min,
          maxDate: utcDay.offset(max, -1),
          phase,
          compareOption,
        });
      } else {
        changeDrilldownTableParams({
          minDate: min,
          maxDate: utcDay.offset(max, -1),
          phase: currentTrellis,
          compareOption,
        });
      }
      regionOfInterestModeOff();
    }
  }, [
    changeDrilldownTableParams,
    currentTrellis,
    firstDate,
    compareOption,
    phase,
    secondDate,
    regionOfInterestModeOff,
  ]);

  return (
    <OverlayRegionOfInterest.Container>
      <svg
        ref={ref}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        onClick={() => clickHandler()}
        onMouseMove={e => moveLine(e)}
        onMouseLeave={hideLine}
      >
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={process.env.DEBUG ? 'rgba(150, 0, 150, 0.5)' : 'transparent'}
        />

        {firstDate && (
          <OverlayRegionOfInterest.Line
            x1={xScale(firstDate)}
            x2={xScale(firstDate)}
            y1={currentTrellisOrder * trellisHeight}
            y2={currentTrellisOrder * trellisHeight + trellisHeight}
            vectorEffect="non-scaling-stroke"
          />
        )}

        {secondDate && (
          <OverlayRegionOfInterest.Line
            x1={xScale(secondDate)}
            x2={xScale(secondDate)}
            y1={currentTrellisOrder * trellisHeight}
            y2={currentTrellisOrder * trellisHeight + trellisHeight}
            vectorEffect="non-scaling-stroke"
          />
        )}

        {lineXPosition > 0 && (
          <OverlayRegionOfInterest.Line
            strokeDasharray="2"
            x1={0}
            x2={0}
            y1={0}
            y2={trellisHeight}
            transform={`translate(${lineXPosition}, ${lineYPosition})`}
            vectorEffect="non-scaling-stroke"
          />
        )}
      </svg>
    </OverlayRegionOfInterest.Container>
  );
};

OverlayRegionOfInterest.Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
`;

OverlayRegionOfInterest.Line = styled.line`
  stroke: ${(props: Record<string, any>) => props.theme.colors.primary};
  stroke-width: 2;
`;

export default React.forwardRef<Element, OverlayRegionOfInterestProps>(
  OverlayRegionOfInterest,
);
