import * as React from 'react';

import useComponentSize from 'hooks/useComponentSize';
import { getTrellisGeneralClipPathId, getTrellisId } from 'modules/chart/utils';
import type { TooltipData, TrellisTooltipData } from 'modules/ui/models/ui';

import GroupVarianceSelectedBar from './GroupVarianceSelectedBar';
import VarianceStackedGroup from './VarianceStackedGroup';
interface SVGGroupVarianceProps {
  formatedLayers: any;
  height: number;
  isAxisDragging: boolean;
  layers: any;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  today: Date;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  varianceColors: { [varianceOptionId: string]: string };
  xScale: any;
  yScale: any;
  yAxisLinePos: number | null;
}

const SVGGroupVariance = ({
  formatedLayers,
  height,
  isAxisDragging,
  layers,
  onXAxisScaling,
  onSetTooltipData,
  today,
  tooltipData,
  trellisTitle,
  xScale,
  varianceColors,
  yScale,
  yAxisLinePos,
}: SVGGroupVarianceProps) => {
  const svgEl: React.RefObject<HTMLElement | null> = React.useRef(null);
  const [eventId, setEventId] = React.useState('null');
  const svgBoundingRect = useComponentSize(svgEl);
  const onWheelHandler = React.useCallback(
    e => {
      onXAxisScaling(e, svgEl);
    },
    [svgEl, onXAxisScaling],
  );

  return (
    <svg
      //@ts-expect-error
      ref={svgEl}
      className="varianceTrellis_chart panInteraction"
      height="100%"
      id={getTrellisId(trellisTitle)}
      preserveAspectRatio="none"
      viewBox={`0 0 ${xScale.range()[1]} ${height}`}
      width={xScale.range()[1] - 2}
      shapeRendering="geometricPrecision"
      onWheel={onWheelHandler}
      style={{ cursor: isAxisDragging ? 'grabbing' : 'auto' }}
    >
      <defs>
        <clipPath id={getTrellisGeneralClipPathId(trellisTitle)}>
          <rect height={height} width={xScale.range()[1] - 1} />
        </clipPath>
      </defs>
      <VarianceStackedGroup
        layers={layers}
        xScale={xScale}
        yScale={yScale}
        setEventId={setEventId}
        clipPath={`url(#${getTrellisGeneralClipPathId(trellisTitle)})`}
        varianceColors={varianceColors}
      />
      <line
        stroke="#000000"
        strokeWidth={1}
        x1={0}
        x2={xScale.range()[1]}
        y1={yScale(0)}
        y2={yScale(0)}
      />
      {yAxisLinePos && (
        <line
          stroke="#c42424"
          strokeWidth={1}
          x1={0}
          x2={xScale.range()[1]}
          y1={yAxisLinePos}
          y2={yAxisLinePos}
          vectorEffect="non-scaling-stroke"
        />
      )}
      <GroupVarianceSelectedBar
        formatedLayers={formatedLayers}
        eventId={eventId}
        onSetTooltipData={onSetTooltipData}
        tooltipData={tooltipData}
        svgBoundingRect={svgBoundingRect}
        today={today}
        xScale={xScale}
        yScale={yScale}
      />
    </svg>
  );
};

export default React.memo<SVGGroupVarianceProps>(SVGGroupVariance);
