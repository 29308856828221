import * as R from 'ramda';
import { filterActions } from 'redux-ignore';

import { PUSH, POP, CLEAR, OPEN, HIDE, namespace } from './NotificationActions';

import type { SnackbarNotification } from './models/snackbar-notification';

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'notification';

type NotificationState = {
  notifications: SnackbarNotification[];
  currentNotification: SnackbarNotification | null;
};

export const initialState: NotificationState = {
  notifications: [],
  currentNotification: null,
};

const NotificationReducer = (
  state: NotificationState = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case PUSH: {
      const notification = R.prop('payload', action);
      const notifications = R.compose(
        R.dropRepeats,
        R.append(notification),
        R.prop('notifications'),
      )(state);

      return R.assoc<SnackbarNotification[], Record<string, any>>(
        'notifications',
        notifications,
        state,
      );
    }
    case POP: {
      const notifications = R.compose(
        R.drop(1),
        R.prop('notifications'),
      )(state);

      return R.assoc<SnackbarNotification[], Record<string, any>>(
        'notifications',
        notifications,
        state,
      );
    }
    case CLEAR: {
      return initialState;
    }
    case OPEN: {
      return R.assoc<Record<string, any>, Record<string, any>>(
        'currentNotification',
        R.prop('payload', action),
        state,
      );
    }
    case HIDE: {
      return R.assoc<null, Record<string, any>>(
        'currentNotification',
        null,
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export const getNotifications = R.path<SnackbarNotification[]>([
  STATE_KEY,
  'notifications',
]);
// $FlowFixMe
export const getLatestNotification = R.compose(
  R.head,
  R.path([STATE_KEY, 'notifications']),
);
export const getNotification = R.path([STATE_KEY, 'currentNotification']);
// $FlowFixMe
export const isNotificationOpen = R.compose(
  Boolean,
  R.path([STATE_KEY, 'currentNotification']),
);

export default filterActions(NotificationReducer, action =>
  action.type.match(filterRegExp),
);
