import * as React from 'react';
import styled from 'styled-components';

import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';

interface CapacityVisibleDividerProps {
  capacityEvents: CapacityChangeEvent[];
  index: number;
  isEditable?: boolean;
  neighbourIsEditable?: boolean;
  showPrevEvent: boolean;
  xScale: any;
}

const CapacityVisibleDivider = ({
  capacityEvents,
  index,
  isEditable,
  neighbourIsEditable,
  showPrevEvent,
  xScale,
}: CapacityVisibleDividerProps) => {
  const capacityEvent = capacityEvents[index];
  const nextCapacityEvent = capacityEvents[index + 1];
  const prevCapacityEvent = capacityEvents[index - 1];

  return (
    <CapacityVisibleDivider.Container>
      <CapacityVisibleDivider.BorderBox
        isEditable={isEditable}
        xPos={xScale(capacityEvent.dayInit)}
      />
      {showPrevEvent && nextCapacityEvent && (
        <CapacityVisibleDivider.BorderBox
          isEditable={neighbourIsEditable}
          xPos={xScale(nextCapacityEvent.dayInit)}
        />
      )}
      {prevCapacityEvent && (
        <CapacityVisibleDivider.BorderBox
          isEditable={neighbourIsEditable}
          xPos={xScale(prevCapacityEvent.dayInit)}
        />
      )}
    </CapacityVisibleDivider.Container>
  );
};

CapacityVisibleDivider.Container = styled.div`
  position: absolute;
  z-index: 74;
  height: 100%;
  width: 100%;
  left: 0;
  pointer-events: none;
  overflow: hidden;
`;

CapacityVisibleDivider.BorderBox = styled.div`
  position: absolute;
  background: transparent;
  width: 10px;
  height: 100%;
  border-left-width: ${(props: { isEditable: boolean }) =>
    props.isEditable ? '3px' : '1px'};
  border-style: solid;
  border-color: black;
  transform: ${(props: Record<string, any>) =>
    `translateX(${props.isEditable ? props.xPos - 1.5 : props.xPos - 0.5}px)`};
  box-sizing: border-box;
`;

export default React.memo<CapacityVisibleDividerProps>(CapacityVisibleDivider);
