import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import type { Action, Selector } from 'store/models';
import { getGraphqlPayload } from 'store/helpers';

import {
  FETCH_ALL_LATEST_EVENT_NOTES,
  FETCH_ALL_LATEST_EVENT_NOTES_BACKGROUND,
  FETCH_INCOMING_LATEST_EVENT_NOTES,
  FETCH_INCOMING_LATEST_EVENT_NOTES_BACKGROUND,
  FETCH_SENT_LATEST_EVENT_NOTES,
  FETCH_SENT_LATEST_EVENT_NOTES_BACKGROUND,
  FETCH_LATEST_UNREAD_NOTES,
  namespace,
  REFETCH_LATEST_EVENT_NOTES,
  SET_ACTIVE_FILTER,
  OPEN_CONVERSATION,
  RESET_STATE,
  MARK_AS_READ,
  MARK_AS_UNREAD,
  ARCHIVE,
  SET_SELECTED_NOTE,
  MOVE_TO_INBOX,
  GO_TO_PAGE,
} from './InboxConversationActions';
import {
  NOTES_PER_PAGE,
  LatestEventNote,
  NoteFilter,
  NOTES_PER_INITIAL_LOAD,
  PageConfiguration,
} from './models';
import { areNotesEqual, normalizeLatestEventNotes } from './utils';
import { getFilteredWellTable } from 'modules/drilldownTable/DrilldownTableReducer';

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'inboxConversation';

interface LatestNotesSubState {
  currentPage: number;
  items: LatestEventNote[];
  isFetching: boolean;
}

interface InboxConversationState {
  activeFilter: NoteFilter;
  latestNotes: { [key in keyof typeof NoteFilter]: LatestNotesSubState };
  selectedNote: LatestEventNote | null;
  unreadConversations: number;
}

const initialState: InboxConversationState = {
  activeFilter: NoteFilter.incoming,
  latestNotes: {
    all: {
      currentPage: 1,
      items: [],
      isFetching: false,
    },
    sent: {
      currentPage: 1,
      items: [],
      isFetching: false,
    },
    incoming: {
      currentPage: 1,
      items: [],
      isFetching: false,
    },
  },
  selectedNote: null,
  unreadConversations: 0,
};

const InboxConversationReducer = (
  state: InboxConversationState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case FETCH_ALL_LATEST_EVENT_NOTES:
    case FETCH_INCOMING_LATEST_EVENT_NOTES:
    case FETCH_SENT_LATEST_EVENT_NOTES: {
      return R.assocPath(
        ['latestNotes', state.activeFilter, 'isFetching'],
        true,
        state,
      );
    }
    case `${FETCH_ALL_LATEST_EVENT_NOTES}_SUCCESS`:
    case `${FETCH_SENT_LATEST_EVENT_NOTES}_SUCCESS`:
    case `${FETCH_INCOMING_LATEST_EVENT_NOTES}_SUCCESS`: {
      const notes = getGraphqlPayload(action);

      const currentFilter = action.type
        .replace(/^.+(ALL|INCOMING|SENT).+$/, '$1')
        .toLowerCase();

      const normalizedNotes = normalizeLatestEventNotes(notes);
      const joinedNotes =
        state.latestNotes[currentFilter].items.concat(normalizedNotes);

      return R.compose(
        R.assocPath(['latestNotes', currentFilter, 'items'], joinedNotes),
        R.assocPath(['latestNotes', currentFilter, 'isFetching'], false),
      )(state);
    }
    case `${FETCH_LATEST_UNREAD_NOTES}_SUCCESS`: {
      return R.assoc(
        'unreadConversations',
        action.payload.data.getUnreadConversationsCount,
        state,
      );
    }
    case `${FETCH_ALL_LATEST_EVENT_NOTES_BACKGROUND}_SUCCESS`:
    case `${FETCH_INCOMING_LATEST_EVENT_NOTES_BACKGROUND}_SUCCESS`:
    case `${FETCH_SENT_LATEST_EVENT_NOTES_BACKGROUND}_SUCCESS`: {
      const notes = getGraphqlPayload(action);
      const currentFilter = action.type
        .replace(/^.+(ALL|INCOMING|SENT).+$/, '$1')
        .toLowerCase();
      const stateNotes = state.latestNotes[currentFilter].items;

      const normalizedNotes = normalizeLatestEventNotes(notes);
      const notesMap = normalizedNotes.reduce((acc, n) => {
        acc[n.eventType + n.eventId] = n;
        return acc;
      }, {});
      const lastDate = normalizedNotes[0]?.noteTimestamp;

      const filteredNotes = stateNotes
        .filter(
          n =>
            !lastDate ||
            (n.noteTimestamp < lastDate && !notesMap[n.eventType + n.eventId]),
        )
        .concat(normalizedNotes);

      return R.assocPath(
        ['latestNotes', currentFilter, 'items'],
        filteredNotes.sort((a, b) => b.noteTimestamp - a.noteTimestamp),
        state,
      );
    }
    case SET_ACTIVE_FILTER: {
      return R.assoc('activeFilter', action.payload, state);
    }
    case SET_SELECTED_NOTE:
    case OPEN_CONVERSATION: {
      return R.assoc('selectedNote', action.payload, state);
    }
    case MARK_AS_READ:
    case MARK_AS_UNREAD: {
      const conversations = action.payload.graphql.variables.payload;
      const read = action.type === MARK_AS_READ;
      const newNotes: LatestEventNote[] = [];
      let inboxMarkedCount = 0;

      for (const item of state.latestNotes[state.activeFilter].items) {
        if (conversations.find(c => areNotesEqual(c, item))) {
          const newItem = {
            ...item,
            read,
            inbox: item.isWatching ? item.inbox : true,
          };
          newNotes.push(newItem);
          if (newItem.inbox && item.read !== read) inboxMarkedCount++;

          continue;
        }

        newNotes.push(item);
      }

      const toAddUnread = inboxMarkedCount * (read ? -1 : 1);

      return R.compose(
        R.assocPath(['latestNotes', state.activeFilter, 'items'], newNotes),
        R.assoc('unreadConversations', state.unreadConversations + toAddUnread),
      )(state);
    }
    case ARCHIVE: {
      const conversations = action.payload.graphql.variables.payload;
      const toSubtractUnread = state.latestNotes[
        state.activeFilter
      ].items.filter(
        n => !n.read && n.inbox && conversations.find(c => areNotesEqual(c, n)),
      ).length;

      if (
        state.activeFilter === NoteFilter.all ||
        state.activeFilter === NoteFilter.sent
      ) {
        const newConversations = state.latestNotes[
          state.activeFilter
        ].items.map(n =>
          conversations.find(c => areNotesEqual(c, n))
            ? { ...n, inbox: false }
            : n,
        );

        const inboxConversations = state.latestNotes[
          NoteFilter.incoming
        ].items.filter(
          c => !newConversations.find(n => !n.inbox && areNotesEqual(c, n)),
        );

        return R.compose(
          R.assocPath(
            ['latestNotes', state.activeFilter, 'items'],
            newConversations,
          ),
          R.assocPath(
            ['latestNotes', NoteFilter.incoming, 'items'],
            inboxConversations,
          ),
          R.assoc(
            'unreadConversations',
            state.unreadConversations - toSubtractUnread,
          ),
        )(state);
      }

      const newConversations = state.latestNotes[
        state.activeFilter
      ].items.filter(n => !conversations.find(c => areNotesEqual(c, n)));

      return R.compose(
        R.assocPath(
          ['latestNotes', state.activeFilter, 'items'],
          newConversations,
        ),
        R.assoc(
          'unreadConversations',
          state.unreadConversations - toSubtractUnread,
        ),
      )(state);
    }
    case MOVE_TO_INBOX: {
      const conversations = action.payload.graphql.variables.payload;
      const inbox: LatestEventNote[] = [];
      const newConversations = state.latestNotes[state.activeFilter].items.map(
        n => {
          if (!conversations.find(c => areNotesEqual(c, n))) return n;

          const newConversation = {
            ...n,
            inbox: true,
            read: n.isWatching ? n.read : true,
            isWatching: true,
          };
          inbox.push(newConversation);
          return newConversation;
        },
      );
      const toAddUnread = state.latestNotes[state.activeFilter].items.filter(
        n => !n.read && conversations.find(c => areNotesEqual(c, n)),
      ).length;

      const inboxSorted = state.latestNotes[NoteFilter.incoming].items
        .concat(inbox)
        .sort((a, b) => Number(b.noteTimestamp > a.noteTimestamp) * 2 - 1);

      return R.compose(
        R.assocPath(
          ['latestNotes', state.activeFilter, 'items'],
          newConversations,
        ),
        R.assocPath(['latestNotes', NoteFilter.incoming, 'items'], inboxSorted),
        R.assoc('unreadConversations', state.unreadConversations + toAddUnread),
      )(state);
    }
    case REFETCH_LATEST_EVENT_NOTES: {
      return {
        ...state,
        latestNotes: {
          ...state.latestNotes,
          [state.activeFilter]: {
            ...initialState.latestNotes[state.activeFilter],
            isFetching: state.latestNotes[state.activeFilter].isFetching,
            currentPage: state.latestNotes[state.activeFilter].currentPage,
          },
        },
      };
    }
    case RESET_STATE: {
      return {
        ...initialState,
        unreadConversations: state.unreadConversations,
        selectedNote: state.selectedNote,
        activeFilter: state.activeFilter,
      };
    }
    case GO_TO_PAGE: {
      return R.assocPath(
        ['latestNotes', state.activeFilter, 'currentPage'],
        action.payload,
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export const getState = (state: Record<string, any>) => state[STATE_KEY];

export const getActiveFilter: Selector<NoteFilter> = createSelector(
  getState,
  state => state.activeFilter,
);

export const getLatestEventNotes: Selector<LatestEventNote[]> = createSelector(
  getState,
  state => state.latestNotes[state.activeFilter].items,
);

export const getCurrentPage: Selector<number> = createSelector(
  getState,
  state => state.latestNotes[state.activeFilter].currentPage,
);

export const getPageConfiguration: Selector<PageConfiguration> = createSelector(
  getLatestEventNotes,
  notes => ({
    page: Math.round(0.5 + notes.length / NOTES_PER_INITIAL_LOAD),
    count: NOTES_PER_INITIAL_LOAD,
  }),
);

export const getFilteredLatestEventNotes: Selector<LatestEventNote[]> =
  createSelector(getLatestEventNotes, getFilteredWellTable, (notes, wells) =>
    notes.filter(note => wells.some(w => w.wellId === note.wellId)),
  );

export const getAvailablePagesCount: Selector<number> = createSelector(
  getFilteredLatestEventNotes,
  notes => {
    const pages = Math.ceil(notes.length / NOTES_PER_PAGE);

    return pages === 0 ? 1 : pages;
  },
);

export const getPaginatedLatestEventNotes: Selector<LatestEventNote[]> =
  createSelector(
    getFilteredLatestEventNotes,
    getAvailablePagesCount,
    getCurrentPage,
    (notes, pages, page) => {
      if (page > pages) return [];
      const startFrom = (page - 1) * NOTES_PER_PAGE;
      return notes.slice(startFrom, startFrom + NOTES_PER_PAGE);
    },
  );

export const getSelectedNote: Selector<number> = createSelector(
  getState,
  state => state.selectedNote,
);

export const getFetchingState: Selector<boolean> = createSelector(
  getState,
  state => state.latestNotes[state.activeFilter].isFetching,
);
export const getShouldIgnoreFetchingOnScroll: Selector<boolean> =
  createSelector(
    getState,
    state => state.latestNotes[state.activeFilter].shouldIgnoreFetchingOnScroll,
  );

export const getUnreadConversations: Selector<number> = createSelector(
  getState,
  state => state.unreadConversations,
);

export default filterActions(InboxConversationReducer, action =>
  action.type.match(filterRegExp),
);
