import * as R from 'ramda';
import * as React from 'react';
import { DropTarget, DragSource, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { CloseIcon } from 'components/Icons';
import { NormalizedSeriesMapping } from 'modules/series/models';
import LongNameMouseTooltip from 'components/LongNameMouseTooltip/LongNameMouseTooltip';

import {
  AvailableDataSeries,
  DraggableOption,
  ChartOptionsGroup as ChartOptionsGroupType,
  ITEM_TYPES,
  ItemTypes,
  ListChartOptions,
  OPTIONS_TYPES,
  MAX_SELECTED_NAME_WIDTH,
  NOT_EXISTING_SENSOR_SERIES_MESSAGE,
} from '../models';
import GroupItem from './GroupItem';
import LeftOptionButton from './LeftOptionButton';
import { getSeriesColor } from '../utils';
import checkSensorSeriesExists from '../utils/checkSensorSeriesExists';
import { getSensorSeriesMapping } from '../../series/SeriesReducer';
import { getCurrentWellId } from '../../ui/UIReducer';

interface GroupInstance {
  getNode(): HTMLDivElement | null;
}

const groupTarget = {
  drop(
    props: ChartOptionsGroupProps,
    monitor: DropTargetMonitor,
    component: GroupInstance,
  ) {
    const item = monitor.getItem<any>();
    const node = component.getNode();
    if (!node || monitor.didDrop()) {
      return;
    }

    const hoverBoundingRect = node.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
    const newGroupIndex =
      hoverClientY < hoverMiddleY ? props.index : 1 + props.index;

    if (!monitor.didDrop() && item.props.itemType === ITEM_TYPES.available) {
      props.onCreateNewGroup({
        optionId: item.props.option.id,
        newGroupIndex,
      });
    } else if (
      !monitor.didDrop() &&
      item.props.itemType === ITEM_TYPES.selected
    ) {
      props.onCreateNewGroup({
        optionId: item.props.option.id,
        newGroupIndex,
        groupId: item.props.perentGroup.groupId,
      });
    } else if (
      !monitor.didDrop() &&
      item.props.itemType === ITEM_TYPES.singleOptionGroup &&
      item.id !== props.currentGroup.groupId &&
      item.props.currentGroup.options[0]
    ) {
      props.onAddOptionToGroup({
        optionId: item.props.currentGroup.options[0],
        groupId: props.currentGroup.groupId,
        newOptionIndex: props.hoveredOptionIndex ?? 0,
        previousGroupId: item.props.currentGroup.groupId,
      });
    }
  },
  hover(
    props: ChartOptionsGroupProps,
    monitor: DropTargetMonitor,
    component: GroupInstance,
  ) {
    const item = monitor.getItem<any>();

    if (!component) {
      return;
    }

    const node = component.getNode();
    if (!node) {
      return null;
    }

    const dragIndex = item.index;
    const hoverIndex = props.index;
    const hoverBoundingRect = node.getBoundingClientRect();
    const { y } = monitor.getClientOffset() as XYCoord;
    const { bottom, top } = hoverBoundingRect;

    const zoneBottom = bottom - (R.isNil(props.tempGroupIndex) ? 8 : 28);
    const zoneTop =
      top + (props.index === 0 && props.tempGroupIndex === 0 ? 22 : 8);
    const isOutOfZone = y < zoneBottom && y > zoneTop ? false : true;
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
    const optionId = R.path(['props', 'option', 'id'], item);
    const optionType = R.path(['options', optionId, 'type'], props);
    const tempGroupIndex =
      hoverClientY < hoverMiddleY ? props.index : 1 + props.index;
    const isLikeOption =
      (item.itemType !== ITEM_TYPES.group &&
        item.itemType !== ITEM_TYPES.singleOptionGroup) ||
      (item.itemType === ITEM_TYPES.singleOptionGroup && !isOutOfZone);
    const isForbidAdding = props.currentGroup.options.length > 5;
    if (props.hoveredGroupIndex !== props.index) {
      props.onSetHoveredGroupIndex(props.index);
    }
    if (!R.isNil(props.tempGroupIndex)) {
      props.onSetTempGroupIndex(null);
    }
    if (props.itemType === ITEM_TYPES.group) {
      if (isForbidAdding) {
        if (
          item.props.itemTypes === ITEM_TYPES.singleOptionGroup ||
          item.props.itemTypes === ITEM_TYPES.selected ||
          item.props.itemTypes === ITEM_TYPES.available
        ) {
          props.onSetTempGroupIndex(tempGroupIndex);
          return;
        }
      } else {
        if (
          item.props.itemTypes === ITEM_TYPES.singleOptionGroup ||
          item.props.itemTypes === ITEM_TYPES.selected ||
          (item.props.itemTypes === ITEM_TYPES.available &&
            optionType === OPTIONS_TYPES.series)
        ) {
          props.onSetTempGroupIndex(null);
          return;
        }
      }

      if (
        item.props.itemType === ITEM_TYPES.singleOptionGroup &&
        item.props.currentGroup.groupId !== props.currentGroup.groupId &&
        props.isShowDraggedGroup
      ) {
        props.onSetHoveredGroupIndex(props.index);
        props.onSetIsShowDraggedGroup(false);
      }
    }
    if (props.itemType === ITEM_TYPES.singleOptionGroup) {
      if (
        item.props.itemTypes === ITEM_TYPES.singleOptionGroup ||
        item.props.itemTypes === ITEM_TYPES.selected ||
        (item.props.itemTypes === ITEM_TYPES.available &&
          optionType === OPTIONS_TYPES.series)
      ) {
        return;
      }
      if (
        item.props.itemType === ITEM_TYPES.singleOptionGroup &&
        item.props.currentGroup.groupId !== props.currentGroup.groupId &&
        props.isShowDraggedGroup
      ) {
        props.onSetIsShowDraggedGroup(false);
      }
    }
    if (
      isLikeOption ||
      props.currentGroup.groupId === item.id ||
      dragIndex === hoverIndex
    ) {
      return;
    }

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.onSetIsShowDraggedGroup(true);
    props.onMoveGroup(dragIndex, hoverIndex);
    monitor.getItem<any>().index = hoverIndex;
  },
};
const groupSource = {
  beginDrag: (props: ChartOptionsGroupProps) => {
    return {
      id: props.currentGroup.groupId,
      index: props.index,
      itemType: props.itemType,
      props: props,
    };
  },
  endDrag(props: ChartOptionsGroupProps) {
    props.onStopHovering();
  },
};
const collectForDrag = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  item: monitor.getItem(),
});

interface ChartOptionsGroupProps {
  connectDragSource: (dragSource: any) => any;
  connectDropTarget: (target: any) => any;
  currentGroup: ChartOptionsGroupType;
  hoveredGroupIndex: number | null;
  hoveredOptionIndex: number | null;
  index: number;
  isDragging: boolean;
  isInsideAvaliableArea: boolean;
  isInsideDragArea: boolean;
  isShowDraggedGroup: boolean;
  isShowOriginOption: boolean;
  item: any;
  itemType: string;
  movedOption: DraggableOption;
  onAddOptionToGroup: (data: {
    groupId: string;
    optionId: string;
    newOptionIndex: number;
    previousGroupId?: string;
  }) => void;
  onChartTypeMenuOpen: (data: {
    optionId: string;
    elemRect: ClientRect;
    event: MouseEvent;
  }) => void;
  onCreateNewGroup: (data: {
    optionId: string;
    newGroupIndex: number;
    groupId?: string;
  }) => void;
  onMoveGroup: (groupIndex: number, hoveredGroupIndex: number) => void;
  onSetHoveredGroupIndex: (value: number) => void;
  onSetHoveredOptionIndex: (index: number) => void;
  onSetIsShowDraggedGroup: (value: boolean) => void;
  onSetIsShowOriginOption: (value: boolean) => void;
  onSetMovedOption: (value: DraggableOption) => void;
  onSetNewOptionsOrder: (option: ChartOptionsGroupType) => void;
  onSetTempGroupIndex: (value: number | null) => void;
  onStopHovering: () => void;
  onSwitchOptionVisibility: (optionId: string) => void;
  options: ListChartOptions;
  onRemoveOption: (groupId: string, option: AvailableDataSeries) => void;
  seriesMapping: NormalizedSeriesMapping;
  tempGroupIndex: number | null;
  isOver: boolean;
}

const ChartOptionsGroup = (
  {
    connectDropTarget,
    connectDragSource,
    currentGroup,
    hoveredGroupIndex,
    hoveredOptionIndex,
    index,
    isDragging,
    isInsideAvaliableArea,
    isShowDraggedGroup,
    isShowOriginOption,
    item,
    movedOption,
    onAddOptionToGroup,
    onChartTypeMenuOpen,
    onSetHoveredGroupIndex,
    onSetHoveredOptionIndex,
    onSetIsShowOriginOption,
    onSetMovedOption,
    onSetNewOptionsOrder,
    onSwitchOptionVisibility,
    onStopHovering,
    options,
    onRemoveOption,
    seriesMapping,
    tempGroupIndex,
    isOver,
  }: ChartOptionsGroupProps,
  ref,
) => {
  const sensorSeriesMapping = useSelector(getSensorSeriesMapping);
  const currentWellId = useSelector(getCurrentWellId);
  const [optionsIds, setOptionsIds] = React.useState(currentGroup.options);
  const [movedOptionIndex, setMovedOptionIndex] = React.useState<number | null>(
    null,
  );

  const onSetMovedOptionIndex = React.useCallback(
    (index: number | null) => {
      if (movedOptionIndex !== index) {
        setMovedOptionIndex(index);
      }
    },
    [movedOptionIndex, setMovedOptionIndex],
  );

  const groupOptions = React.useMemo(() => {
    if (
      R.isEmpty(options) ||
      optionsIds.some(id => !options[id]) ||
      !sensorSeriesMapping
    ) {
      return;
    }

    return optionsIds.map(optionId => {
      if (/s/.test(optionId)) {
        return {
          ...options[optionId],
          exists: checkSensorSeriesExists(
            options[optionId],
            sensorSeriesMapping,
            currentWellId,
          ),
        };
      }
      return options[optionId];
    });
  }, [optionsIds, options, sensorSeriesMapping]);
  const onMoveOption = React.useCallback(
    (hoveredOptionIndex: number, optionIndex?: number) => {
      if (typeof optionIndex !== 'undefined') {
        const currentEl = optionsIds[optionIndex];
        const newArr = [...optionsIds];
        newArr.splice(optionIndex, 1);
        newArr.splice(hoveredOptionIndex, 0, currentEl);
        setOptionsIds(newArr);
        onSetMovedOptionIndex(hoveredOptionIndex);
      }
    },
    [optionsIds, setOptionsIds, onSetMovedOptionIndex],
  );

  const onStopOptionMoving = React.useCallback(() => {
    onSetNewOptionsOrder({ ...currentGroup, options: optionsIds });
  }, [onSetNewOptionsOrder, optionsIds, currentGroup]);

  const isPerent =
    R.pathOr('', ['props', 'perentGroup', 'groupId'], item) ===
    currentGroup.groupId;

  React.useEffect(() => {
    setOptionsIds(currentGroup.options);
  }, [currentGroup.options, setOptionsIds]);

  const disabledAddButton =
    R.path(['props', 'itemType'], item) === ITEM_TYPES.group;

  const onGroupOptionHover = React.useCallback(
    (optionIndex: number) => {
      if (optionIndex !== hoveredOptionIndex) {
        onSetHoveredOptionIndex(optionIndex);
        onSetHoveredGroupIndex(index);
      }
    },
    [
      onSetHoveredGroupIndex,
      onSetHoveredOptionIndex,
      index,
      hoveredOptionIndex,
    ],
  );
  const elementRef = React.useRef(null);
  React.useImperativeHandle<any, GroupInstance>(ref, () => ({
    getNode: () => elementRef.current,
  }));
  const isLikeOption =
    isDragging &&
    currentGroup.options.length === 1 &&
    !R.isNil(hoveredOptionIndex);

  const [tooltipText, setTooltipText] = React.useState('');
  const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });
  const [isAnyOptionDraging, setIsAnyOptionDraging] = React.useState(false);

  return connectDragSource(
    connectDropTarget(
      <div ref={elementRef}>
        <ChartOptionsGroup.Wrapper
          isLikeOption={isLikeOption}
          isDragging={isDragging}
          isHidden={
            (isInsideAvaliableArea && isDragging) ||
            (isDragging && !isShowDraggedGroup)
          }
        >
          <ChartOptionsGroup.Container isDragging={isDragging}>
            {groupOptions && !R.isEmpty(groupOptions) && (
              <ChartOptionsGroup.Content isDragging={isDragging}>
                <LeftOptionButton />
                <ChartOptionsGroup.OptionWrapper>
                  {groupOptions.map((option, i) => (
                    <ChartOptionsGroup.ItemWrapper
                      key={option.id}
                      title={
                        option.exists === false &&
                        NOT_EXISTING_SENSOR_SERIES_MESSAGE
                      }
                      isFirst={
                        i === 0 ||
                        (movedOption.groupId === currentGroup.groupId &&
                          ((movedOptionIndex === 0 && i === 1) ||
                            movedOptionIndex === i) &&
                          !isOver)
                      }
                      isNotExist={option.exists === false}
                    >
                      <GroupItem
                        color={getSeriesColor(option, seriesMapping)}
                        groupId={currentGroup.groupId}
                        groupIndex={index}
                        hoveredGroupIndex={hoveredGroupIndex}
                        hoveredOptionIndex={hoveredOptionIndex}
                        index={i}
                        isCorrentGroupHover={hoveredGroupIndex === index}
                        isGroupDragged={isDragging}
                        isShowOriginOption={isShowOriginOption}
                        itemType={ITEM_TYPES.selected}
                        onAddOptionToGroup={onAddOptionToGroup}
                        onChartTypeMenuOpen={onChartTypeMenuOpen}
                        onMoveOption={onMoveOption}
                        onSetHoveredOptionIndex={onGroupOptionHover}
                        onSetIsShowOriginOption={onSetIsShowOriginOption}
                        onSetMovedOption={onSetMovedOption}
                        onSetMovedOptionIndex={onSetMovedOptionIndex}
                        onStopOptionMoving={onStopOptionMoving}
                        onSwitchOptionVisibility={onSwitchOptionVisibility}
                        onStopHovering={onStopHovering}
                        option={option}
                        optionsList={options}
                        perentGroup={currentGroup}
                        setIsAnyOptionDraging={setIsAnyOptionDraging}
                        setTooltipPosition={setTooltipPosition}
                        setTooltipText={setTooltipText}
                        tempGroupIndex={tempGroupIndex}
                        isNotExist={option.exists === false}
                      />
                    </ChartOptionsGroup.ItemWrapper>
                  ))}
                </ChartOptionsGroup.OptionWrapper>
                <ChartOptionsGroup.CloseButtonWrapper>
                  {groupOptions.map(
                    (option, i) =>
                      !(
                        movedOptionIndex === i &&
                        (!R.isNil(tempGroupIndex) ||
                          hoveredGroupIndex !== index)
                      ) && (
                        <ChartOptionsGroup.Button
                          key={option.id}
                          isFirst={
                            i === 0 ||
                            (movedOption.groupId === currentGroup.groupId &&
                              ((movedOptionIndex === 0 && i === 1) ||
                                movedOptionIndex === i) &&
                              !isOver)
                          }
                          onClick={() =>
                            onRemoveOption(currentGroup.groupId, option)
                          }
                        >
                          {!(
                            movedOptionIndex === i ||
                            (hoveredGroupIndex === index &&
                              hoveredOptionIndex === i &&
                              (movedOption.groupId !== currentGroup.groupId ||
                                (movedOption.groupId === '' && isOver)))
                          ) && <CloseIcon />}
                        </ChartOptionsGroup.Button>
                      ),
                  )}
                  {index === hoveredGroupIndex &&
                    R.isNil(tempGroupIndex) &&
                    !isDragging &&
                    !disabledAddButton &&
                    !isPerent && (
                      <ChartOptionsGroup.Button>
                        {hoveredOptionIndex !== groupOptions.length && (
                          <CloseIcon />
                        )}
                      </ChartOptionsGroup.Button>
                    )}
                </ChartOptionsGroup.CloseButtonWrapper>
              </ChartOptionsGroup.Content>
            )}
          </ChartOptionsGroup.Container>
        </ChartOptionsGroup.Wrapper>
        {tooltipText && !isAnyOptionDraging ? (
          <LongNameMouseTooltip
            position={tooltipPosition}
            text={tooltipText}
            maxWidth={MAX_SELECTED_NAME_WIDTH}
          />
        ) : null}
      </div>,
    ),
  );
};

ChartOptionsGroup.Container = styled.div`
  border: ${props =>
    props.isDragging ? props.theme.borders.thingray : 'none'};
  margin: ${props => (props.isDragging ? '0' : '1px 0')};
`;

ChartOptionsGroup.Wrapper = styled.div`
  display: ${props => (props.isHidden ? 'none' : 'block')};
`;

ChartOptionsGroup.Content = styled.div`
  opacity: ${props => (props.isDragging ? 0 : 1)};
  background-color: #fbfbfb;
  display: grid;
  grid-template-columns: 15px auto 25px;
  border: ${props => props.theme.borders.thingray};
`;

ChartOptionsGroup.OptionWrapper = styled.div``;

ChartOptionsGroup.ItemWrapper = styled.div`
  border-top: ${props =>
    props.isFirst ? 'none' : props.theme.borders.thingray};
  color: ${({ isNotExist }) => (isNotExist ? '#9a9a9a;' : '#484848;')};
`;

ChartOptionsGroup.CloseButtonWrapper = styled.div`
  display: grid;
`;

ChartOptionsGroup.Button = styled.button`
  padding: 0;
  width: 25px;
  min-height: 20px;
  height: 100%;
  border: none;
  border-left: ${props => props.theme.borders.thingray};
  outline: none;
  border-top: ${props =>
    props.isFirst ? 'none' : props.theme.borders.thingray};

  > svg {
    width: 15px;
  }
`;

export default DragSource(
  ItemTypes.SERIES,
  groupSource,
  collectForDrag,
)(
  DropTarget(
    ItemTypes.SERIES,
    groupTarget,
    collect,
  )(
    React.forwardRef<HTMLDivElement, ChartOptionsGroupProps>(ChartOptionsGroup),
  ),
);
