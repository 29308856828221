import * as R from 'ramda';

import { Environment } from './EnvironmentConfig';

const sentryConfig = {
  dsn: 'https://7597cb20d7b14a9298d29ff02c8ac5e4@sentry.io/1328746',
  environment: R.defaultTo(
    Environment.local,
    process.env.REACT_APP_ENVIRONMENT,
  ),
  beforeBreadcrumb: (breadcrumb: any) => {
    return breadcrumb.category === 'console' ? null : breadcrumb;
  },
};

export default sentryConfig;
