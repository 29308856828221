import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Stats from 'stats.js';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';

import theme, { muiTheme } from 'components/Theme';
import BaseStyles from 'components/Theme/base-styles';
import CheckDevice from 'components/CheckDevice';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import client from 'graphql-middleware/client';
import NotificationCenter from 'modules/notification/components/NotificationCenter';

import routes from './routes';
import configureStore from './store/configureStore';
import DB from './database';

const { history, store, persistor } = configureStore();

const App = () => {
  const onClose = React.useCallback(() => DB.destroy(), []);
  const refreshDB = React.useCallback(() => DB.clearWellProduction(), []);

  React.useEffect(() => {
    setInterval(refreshDB, 1000 * 3600 * 12);
    window.addEventListener('beforeunload', onClose);
  }, [onClose]);

  // fps tracker
  if (window.location.search.includes('debug=1')) {
    const stats = new Stats();
    stats.showPanel(0);
    const fpsTracker = stats.dom;
    fpsTracker.id = 'fps_tracker';
    document.body.appendChild(fpsTracker);
    const animate = () => {
      stats.update();
      requestAnimationFrame(animate);
    };
    requestAnimationFrame(animate);
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Helmet titleTemplate="Wise Rock" defaultTitle="Wise Rock" />
              <BaseStyles />
              <DndProvider backend={HTML5Backend}>
                <ConnectedRouter history={history}>
                  <CheckDevice>
                    <ScrollToTop>{routes}</ScrollToTop>
                  </CheckDevice>
                </ConnectedRouter>
              </DndProvider>
              {/* In-page notifications */}
              <NotificationCenter />
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default App;
