import { utcDay, utcMinute, timeDay, timeMinute } from 'd3-time';
import { utcFormat } from 'd3-time-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { throttle } from 'throttle-debounce';

import { getId } from 'modules/auth/AuthReducer';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import RightPanel from 'modules/dashboard/components/RightPanel';
import { addNote, updateNote, removeNote } from 'modules/notes/NotesActions';
import {
  getWellNotesByDay,
  getWellNotesDates,
} from 'modules/notes/NotesReducer';
import {
  pushNotification,
  hideNotification,
} from 'modules/notification/NotificationActions';
import {
  uploadAttachment,
  deleteAttachment,
} from 'modules/noteAttachment/NoteAttachmentActions';
import { NOTES_PANEL } from 'modules/ui/models/ui';
import { enableNotesMode, setNotesProductionDay } from 'modules/ui/UIActions';
import {
  getActivePanel,
  getCurrentWellId,
  getNotesProductionDay,
} from 'modules/ui/UIReducer';
import { getUsers, getUsersByEmailName } from 'modules/user/UserReducer';

import Button from 'components/Button';
import DateInput from 'components/DateInput';
import { PlusIcon, MinusIcon, MousePointer } from 'components/Icons';
import { getMinDate } from 'helpers';

import usePrevious from 'hooks/usePrevious';
import { useNonInputKeydown } from 'hooks/useKeydown';

import NoteForm from '../forms/NoteForm';
import NotesSectionGroup from '../components/NotesSectionGroup';
import AlertWindow from 'components/AlertWindow';
import useComponentSize from 'hooks/useComponentSize';
import ModalWindow from 'components/ModalWindow';
import { usePreventContext } from 'context/PreventContext';
import { WIDE_RIGHT_PANEL_WIDTH } from 'modules/dashboard/models/dashboard';
import useRightPanel from 'modules/ui/hooks/useRightPanel';

interface AddingDialog {
  show: boolean;
  productionDay: Date | null;
}

interface AlertDeleteWindow {
  isOpen: boolean;
  noteId: string | null;
  productionDay: Date | null;
}

const DAY_SECTION_CLASS = 'day-section';
const DAY_HEADER_CLASS = 'day-header';

const getDataDayAttribute = dayString => `[data-day="${dayString}"]`;

const indexOfClosest = (productionDays, targetDate) => {
  const parsedDates = productionDays.map(day => new Date(day).getTime());
  const daysBefore = parsedDates.filter(day => day <= targetDate.getTime());
  const closest = Math.max(...daysBefore);
  const closestIndex = parsedDates.findIndex(day => day === closest);

  return closestIndex;
};

const WellNotesPanel = () => {
  const dispatch = useDispatch();
  const rightPanel = useRightPanel();
  const appConfig = useSelector(getAppConfig);
  const contentEl: React.RefObject<HTMLElement | null> =
    React.useRef<HTMLElement>(null);
  const lastNoteRef = React.useRef<HTMLDivElement | null>(null);
  const contentElSize = useComponentSize(contentEl);
  const lastNoteSize = useComponentSize(lastNoteRef);
  const currentWellId = useSelector(getCurrentWellId);
  const notes = useSelector(store => getWellNotesByDay(store, currentWellId));
  const notesProductionDay = useSelector(getNotesProductionDay);
  const users = useSelector(getUsers);
  const usersByEmailName = useSelector(getUsersByEmailName);
  const currentUserId = useSelector(getId);
  const activePanel = useSelector(getActivePanel);
  const notesDates = useSelector(store =>
    getWellNotesDates(store, currentWellId),
  );
  const isActive = activePanel === NOTES_PANEL;
  const today = React.useMemo(
    () =>
      getMinDate(
        appConfig.today,
        utcDay.floor(
          timeMinute.offset(
            timeDay.floor(new Date()),
            -new Date().getTimezoneOffset(),
          ),
        ),
      ),
    [appConfig],
  );
  const [hasUnsaved, setHasUnsaved] = React.useState();
  const prevent = usePreventContext();

  const notificate = React.useCallback(
    message => dispatch(pushNotification({ message })),
    [dispatch],
  );
  const hideMessage = React.useCallback(
    () => dispatch(hideNotification()),
    [dispatch],
  );

  const prevWellId = usePrevious(currentWellId);

  const [addingDialog, setAddingDialog] = React.useState<AddingDialog>({
    show: false,
    productionDay: null,
  });
  const [editingDialog, setEditingDialog] = React.useState({
    show: false,
    noteId: null,
  });

  const [noteIdsInProgress, setNoteIdsInProgress] = React.useState([]);
  const addNoteInProgress = React.useCallback(
    noteId => setNoteIdsInProgress(R.append(noteId, noteIdsInProgress)),
    [noteIdsInProgress],
  );
  const removeFromNotesInProgress = React.useCallback(
    noteId =>
      setNoteIdsInProgress(
        noteIdsInProgress.filter(
          noteIdInProgress => noteIdInProgress !== noteId,
        ),
      ),
    [noteIdsInProgress],
  );
  const [productionDaysAreAddingNote, setProductionDayIsAddingNote] =
    React.useState<Date[]>([]);
  const addProductionDayIsAddingNote = React.useCallback(
    day =>
      setProductionDayIsAddingNote(R.append(day, productionDaysAreAddingNote)),
    [productionDaysAreAddingNote],
  );
  const removeProductionDayIsAddingNote = React.useCallback(
    day =>
      setProductionDayIsAddingNote(
        productionDaysAreAddingNote.filter(
          dayInProgress => dayInProgress.getTime() === day.getTime(),
        ),
      ),
    [productionDaysAreAddingNote],
  );

  const onAttachmentUpload = React.useCallback(
    file => dispatch(uploadAttachment(file)),
    [dispatch],
  );

  const onAttachmentDelete = React.useCallback(
    attachmentId => dispatch(deleteAttachment(attachmentId)),
    [dispatch],
  );

  const [alertDeleteWindow, setAlertDeleteWindow] =
    React.useState<AlertDeleteWindow>({
      isOpen: false,
      noteId: null,
      productionDay: null,
    });
  const closeAlertDeleteWindow = React.useCallback(
    () =>
      setAlertDeleteWindow({
        isOpen: false,
        noteId: null,
        productionDay: null,
      }),
    [setAlertDeleteWindow],
  );
  const openAlertDeleteWindow = React.useCallback(
    (noteId, productionDay) =>
      setAlertDeleteWindow({ isOpen: true, noteId, productionDay }),
    [setAlertDeleteWindow],
  );

  const prevDay = usePrevious(notesProductionDay);
  const prevNotes = usePrevious(notes);

  const productionDaysUnsorted = React.useMemo(
    () =>
      addingDialog.show &&
      !R.keys(notes).includes(
        (addingDialog.productionDay as Date).toISOString(),
      )
        ? R.append(
            (addingDialog.productionDay as Date).toISOString(),
            R.keys(notes),
          )
        : R.keys(notes),
    [addingDialog, notes],
  );

  const productionDaysSorted = React.useMemo(
    () =>
      productionDaysUnsorted.sort(
        (a, b) => new Date(b).getTime() - new Date(a).getTime(),
      ),
    [productionDaysUnsorted],
  );

  const closeAddingDialog = React.useCallback(() => {
    setAddingDialog({ show: false, productionDay: null });
  }, [setAddingDialog]);

  const closeEditingDialog = React.useCallback(() => {
    setEditingDialog({ show: false, noteId: null });
  }, [setEditingDialog]);

  const scrollToDate = React.useCallback(
    async (date: Date) => {
      if (!date) return;
      const closest =
        productionDaysSorted[indexOfClosest(productionDaysSorted, date)];
      const scrollToElement = document.querySelector(
        getDataDayAttribute(closest),
      ) as HTMLElement;
      if (scrollToElement && date) {
        await setIgnoreScrollEvent(true);
        //scroll over production day header if date is among note sections
        // const top =
        //   closest === date.toISOString()
        //     ? scrollToElement.offsetTop + 32
        //     : scrollToElement.offsetTop;

        if (contentEl.current) {
          contentEl.current.scroll({
            top: scrollToElement.offsetTop + 2,
            left: 0,
          });
        }
        await setIgnoreScrollEvent(false);
      }
    },
    [productionDaysSorted],
  );

  const throttledScrollToDate = React.useCallback(
    throttle(150, false, (date: Date) => scrollToDate(date)),
    [productionDaysSorted],
  );

  const openAddingDialog = React.useCallback(
    async productionDay => {
      await setIgnoreScrollEvent(true);
      if (addingDialog.show) closeAddingDialog();
      if (editingDialog) closeEditingDialog();
      await setAddingDialog({ show: true, productionDay });
      await dispatch(setNotesProductionDay(productionDay));
      setIgnoreScrollEvent(false);
    },
    [
      addingDialog,
      editingDialog,
      closeAddingDialog,
      closeEditingDialog,
      dispatch,
    ],
  );

  const openEditingDialog = React.useCallback(
    ({ noteId, productionDay }) => {
      if (addingDialog.show) closeAddingDialog();
      if (editingDialog) closeEditingDialog();
      setEditingDialog({ show: true, noteId });
    },
    [
      setEditingDialog,
      addingDialog.show,
      editingDialog,
      closeAddingDialog,
      closeEditingDialog,
    ],
  );

  const [ignoreScrollEvent, setIgnoreScrollEvent] = React.useState(false);

  // indicates whether notes production day was changed by scroll handler on component or from notes production line in chart
  const [indicatorInputDaySource, setDayIndicator] =
    React.useState(notesProductionDay);

  const onScroll = React.useCallback(
    e => {
      if (addingDialog.show || editingDialog.show || ignoreScrollEvent) return;
      const { target } = e;
      const scrolled = target.scrollTop;

      if (scrolled === 0) {
        setDayIndicator(today);
        dispatch(setNotesProductionDay(today));
        return;
      }

      const sections = Array.from(
        document.getElementsByClassName(DAY_SECTION_CLASS),
      ) as HTMLElement[];
      const sizes = sections.map(dayEl => ({
        top: dayEl.offsetTop,
        bottom: dayEl.offsetTop + dayEl.offsetHeight,
      }));

      // 2 pixels - empty gap for date input value when panel is scrolled from date input
      const index = sizes.findIndex(
        (size, i) => size.top + 2 < scrolled && size.bottom > scrolled,
      );

      if (index < 0) {
        return;
      }

      const newDate = utcDay.floor(
        new Date(sections[index].dataset.day as string),
      );
      setDayIndicator(newDate);
      dispatch(setNotesProductionDay(newDate));
    },
    [addingDialog, editingDialog, ignoreScrollEvent, dispatch, today],
  );

  const onDialogClose = React.useCallback(() => {
    const proceed = () => {
      closeAddingDialog();
      rightPanel.unsetDialog();
    };

    if (hasUnsaved) {
      prevent.dispatchEvent(proceed);
    } else {
      proceed();
    }
  }, [hasUnsaved, dispatch, closeAddingDialog, prevent.dispatchEvent]);

  const onAddNotesSubmit = React.useCallback(
    async (noteText, productionDay, attachmentIds) => {
      await addProductionDayIsAddingNote(productionDay);
      const newNote = {
        userId: currentUserId,
        wellId: currentWellId,
        timeStamp: utcMinute.round(new Date()).toISOString(),
        productionDate: productionDay,
        noteText,
        attachmentIds,
      };
      dispatch(addNote(newNote))
        .then(() => closeAddingDialog())
        .then(() => {
          removeProductionDayIsAddingNote(productionDay);
          dispatch(setNotesProductionDay(productionDay));
        });
    },
    [
      currentWellId,
      currentUserId,
      dispatch,
      closeAddingDialog,
      addProductionDayIsAddingNote,
      removeProductionDayIsAddingNote,
    ],
  );

  const onNoteDelete = React.useCallback(async () => {
    const { noteId, productionDay } = alertDeleteWindow;
    if (noteId !== null) {
      await addNoteInProgress(noteId);
      closeAlertDeleteWindow();
      await dispatch(
        removeNote({
          wellId: currentWellId,
          noteId,
        }),
      );
      if (productionDay) {
        // await dispatch(setNotesProductionDay(productionDay));
        await removeFromNotesInProgress(noteId);
        scrollToDate(productionDay);
        dispatch(setNotesProductionDay(productionDay));
      }
    }
  }, [
    currentWellId,
    dispatch,
    alertDeleteWindow,
    closeAlertDeleteWindow,
    scrollToDate,
    addNoteInProgress,
    removeFromNotesInProgress,
  ]);

  const onEditNoteSubmit = React.useCallback(
    note => {
      addNoteInProgress(note.id);
      closeEditingDialog();
      dispatch(updateNote(note)).then(() => removeFromNotesInProgress(note.id));
    },
    [
      addNoteInProgress,
      closeEditingDialog,
      dispatch,
      removeFromNotesInProgress,
    ],
  );

  // scroll panel while line dragging
  React.useEffect(() => {
    if (prevDay && (prevDay as Date).getTime() === notesProductionDay.getTime())
      return;
    if (indicatorInputDaySource.getTime() === notesProductionDay.getTime())
      return;
    throttledScrollToDate(notesProductionDay);
  }, [
    notes,
    notesProductionDay,
    prevDay,
    indicatorInputDaySource,
    throttledScrollToDate,
  ]);

  const notesDatesSorted = React.useMemo(
    () => notesDates.sort((a, b) => a.getTime() - b.getTime()),
    [notesDates],
  );
  const datesAfter: Date[] = React.useMemo(
    () =>
      notesDatesSorted.filter(
        date => date.getTime() > notesProductionDay.getTime(),
      ),
    [notesProductionDay, notesDatesSorted],
  );
  const datesBefore: Date[] = React.useMemo(
    () =>
      notesDatesSorted.filter(
        date => date.getTime() < notesProductionDay.getTime(),
      ),
    [notesProductionDay, notesDatesSorted],
  );

  const additionalScrollSpace = React.useMemo(() => {
    if (
      contentElSize.height === undefined ||
      lastNoteSize.height === undefined ||
      (contentEl.current &&
        contentEl.current.scrollHeight <= contentEl.current.clientHeight)
    )
      return 0;

    return contentElSize.height - lastNoteSize.height;
  }, [contentEl.current, contentElSize, lastNoteSize]);

  const isShownLeftArrow = React.useMemo(
    () => !R.isEmpty(datesBefore),
    [datesBefore],
  );

  const isShownRightArrow = React.useMemo(
    () => !R.isEmpty(datesAfter),
    [datesAfter],
  );

  const onLeftArrowClick = React.useCallback(() => {
    if (R.isEmpty(datesBefore)) return;
    dispatch(setNotesProductionDay(datesBefore[datesBefore.length - 1]));
  }, [datesBefore, dispatch]);
  const onRightArrowClick = React.useCallback(() => {
    if (R.isEmpty(datesAfter)) return;
    dispatch(setNotesProductionDay(datesAfter[0]));
  }, [datesAfter, dispatch]);

  const onSaveStateChange = React.useCallback(
    state => setHasUnsaved(state),
    [setHasUnsaved],
  );

  //scroll to notes production day on mounting
  React.useEffect(() => {
    const notesInput =
      (!prevNotes || R.isEmpty(prevNotes)) && notes && !R.isEmpty(notes);
    const notesDayInput = !!(!prevDay && notesProductionDay);
    const currentNotesExist = !R.isEmpty(notes);
    const currentDayExists = !!notesProductionDay;
    if (
      (notesInput || notesDayInput) &&
      currentNotesExist &&
      currentDayExists
    ) {
      scrollToDate(notesProductionDay);
    }
  }, [prevDay, prevNotes, notes, notesProductionDay, scrollToDate]);

  React.useEffect(() => {
    if (currentWellId !== prevWellId) {
      closeAddingDialog();
      closeEditingDialog();
    }
  }, [currentWellId, prevWellId, closeAddingDialog, closeEditingDialog]);

  const shouldPreventNotesClosing = React.useMemo(() => {
    return hasUnsaved && prevent.event;
  }, [hasUnsaved, prevent.event]);

  React.useEffect(() => {
    if (hasUnsaved || !prevent.event || !rightPanel.isDialogOfType('WellNotes'))
      return;

    prevent.event.proceed();
  }, [rightPanel.isDialogOfType, hasUnsaved, prevent.event]);

  //Date Input Props
  const dateMin = React.useMemo(() => new Date(0), []);
  const clonedDate = React.useMemo(
    () => new Date(notesProductionDay),
    [notesProductionDay],
  );
  const onDateInputProcess = React.useCallback(
    (date: Date) => {
      // setIgnoreScrollEvent(true);
      dispatch(setNotesProductionDay(date));
    },
    [dispatch],
  );

  useNonInputKeydown(
    ({ keyName }) => {
      if (!rightPanel.isDialogOfType('WellNotes')) return;
      if (keyName === 'ESCAPE') return onDialogClose();
      if (keyName === 'RIGHT') return onRightArrowClick();
      if (keyName === 'LEFT') return onLeftArrowClick();
    },
    [
      rightPanel.isDialogOfType,
      onDialogClose,
      onLeftArrowClick,
      onRightArrowClick,
    ],
  );

  if (!rightPanel.isDialogOfType('WellNotes')) return null;

  return (
    <RightPanel
      isShown
      onDialogClose={onDialogClose}
      title="Well Notes"
      isShownLeftArrow={isShownLeftArrow}
      isShownRightArrow={isShownRightArrow}
      onLeftArrowClick={onLeftArrowClick}
      onRightArrowClick={onRightArrowClick}
    >
      <>
        <WellNotesPanel.TopBar>
          <WellNotesPanel.TopDateInputWrapper>
            <DateInput
              id="current-production-date"
              name="current-production-date"
              activeColor="primaryText"
              isActive={isActive}
              min={dateMin}
              max={today}
              date={clonedDate}
              onProcess={onDateInputProcess}
            />
            <Button
              width={30}
              height={30}
              onClick={() => dispatch(enableNotesMode())}
            >
              <MousePointer />
            </Button>
          </WellNotesPanel.TopDateInputWrapper>
          {addingDialog.show &&
          addingDialog.productionDay &&
          addingDialog.productionDay.getTime() ===
            notesProductionDay.getTime() ? (
            <Button transparent onClick={closeAddingDialog}>
              <MinusIcon />
            </Button>
          ) : (
            <Button
              transparent
              onClick={() => {
                setIgnoreScrollEvent(true);
                openAddingDialog(notesProductionDay);
              }}
            >
              <PlusIcon />
            </Button>
          )}
        </WellNotesPanel.TopBar>

        <WellNotesPanel.Content
          ref={contentEl}
          onScroll={ignoreScrollEvent ? null : onScroll}
        >
          {R.isEmpty(users) ? (
            <WellNotesPanel.PanelInProgress>
              <CircularProgress size={24} thickness={4} />
            </WellNotesPanel.PanelInProgress>
          ) : (
            <WellNotesPanel.Scrollable additionalSpace={additionalScrollSpace}>
              {productionDaysSorted.map((dayString, idx) => (
                <div
                  ref={
                    idx === productionDaysSorted.length - 1 ? lastNoteRef : null
                  }
                  key={dayString}
                  className={DAY_SECTION_CLASS}
                  data-day={dayString}
                >
                  <WellNotesPanel.DayTitle className={DAY_HEADER_CLASS}>
                    {utcFormat('%x')(new Date(dayString))}
                    {addingDialog.show &&
                    addingDialog.productionDay &&
                    addingDialog.productionDay.getTime() ===
                      new Date(dayString).getTime() ? (
                      <Button transparent onClick={closeAddingDialog}>
                        <MinusIcon />
                      </Button>
                    ) : (
                      <Button
                        transparent
                        onClick={() => {
                          setIgnoreScrollEvent(true);
                          openAddingDialog(new Date(dayString));
                        }}
                      >
                        <PlusIcon />
                      </Button>
                    )}
                  </WellNotesPanel.DayTitle>
                  {addingDialog.show &&
                    addingDialog.productionDay &&
                    addingDialog.productionDay.getTime() ===
                      new Date(dayString).getTime() && (
                      <NoteForm
                        onSubmit={(noteText, attachmentIds) =>
                          onAddNotesSubmit(
                            noteText,
                            addingDialog.productionDay,
                            attachmentIds,
                          )
                        }
                        isInProgress={productionDaysAreAddingNote.includes(
                          addingDialog.productionDay,
                        )}
                        close={closeAddingDialog}
                        usersByEmailName={usersByEmailName}
                        onSaveStateChange={onSaveStateChange}
                        onAttachmentDelete={onAttachmentDelete}
                        onAttachmentUpload={onAttachmentUpload}
                        notificate={notificate}
                        hideNotification={hideMessage}
                      />
                    )}
                  {notes[dayString] && (
                    <NotesSectionGroup
                      notes={notes[dayString]}
                      editingDialog={editingDialog}
                      noteIdsInProgress={noteIdsInProgress}
                      onEditNoteSubmit={onEditNoteSubmit}
                      closeEditingDialog={closeEditingDialog}
                      usersByEmailName={usersByEmailName}
                      onAttachmentDelete={onAttachmentDelete}
                      onAttachmentUpload={onAttachmentUpload}
                      onSaveStateChange={onSaveStateChange}
                      notificate={notificate}
                      hideMessage={hideMessage}
                      users={users}
                      currentUserId={currentUserId}
                      openEditingDialog={openEditingDialog}
                      openAlertDeleteWindow={openAlertDeleteWindow}
                      dayString={dayString}
                    />
                  )}
                </div>
              ))}
            </WellNotesPanel.Scrollable>
          )}
          {alertDeleteWindow.isOpen && (
            <AlertWindow
              handleClose={closeAlertDeleteWindow}
              onDelete={onNoteDelete}
              subject="note"
            />
          )}
          {prevent.event && shouldPreventNotesClosing && (
            <WellNotesPanel.ModalWindowContainer>
              <ModalWindow
                title="Are you sure you want to leave without saving your note?"
                close={prevent.event.cancel}
                style={{
                  width: window.innerWidth - WIDE_RIGHT_PANEL_WIDTH - 10,
                }}
                backdropStyle={{
                  width: window.innerWidth - WIDE_RIGHT_PANEL_WIDTH - 10,
                }}
                controls={[
                  {
                    text: 'Yes, discard the note',
                    action: prevent.event.proceed,
                    style: {
                      width: 150,
                    },
                    type: 'danger',
                  },
                  {
                    text: 'No, continue editing',
                    action: prevent.event.cancel,
                    style: {
                      width: 150,
                    },
                  },
                ]}
              />
            </WellNotesPanel.ModalWindowContainer>
          )}
        </WellNotesPanel.Content>
      </>
    </RightPanel>
  );
};

WellNotesPanel.Content = styled.div`
  height: ${(props: Record<string, any>) =>
    props.condensed ? 'calc(100% - 330px)' : 'calc(100% - 55px)'};
  overflow-y: auto;
  font-family: 'Lato', sans-serif;
  position: relative;
`;

WellNotesPanel.Scrollable = styled.div`
  padding-bottom: ${({ additionalSpace }) => additionalSpace ?? 0}px;
`;

WellNotesPanel.TopBar = styled.div`
  height: 55px;
  background: ${(props: Record<string, any>) => props.theme.colors.lightgrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

WellNotesPanel.TopDateInputWrapper = styled.div`
  display: flex;
  width: 150px;

  > button {
    margin-left: 6px;
  }
`;

WellNotesPanel.DayTitle = styled.div`
  height: 33px;
  background: ${(props: Record<string, any>) => props.theme.colors.lightgrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
`;

WellNotesPanel.PanelInProgress = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

WellNotesPanel.ModalWindowContainer = styled.div`
  width: 500px;
`;

export default React.memo<Record<string, any>>(WellNotesPanel);
