import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import type { User } from 'modules/user/models/user';

import Button from 'components/Button';
import { ArrowIcon, CloseIcon, DeleteIcon, EditIcon } from 'components/Icons';
import DetailsOverlayTextInput from './DetailsOverlayTextInput';
import { EventNote, extensionsRegex } from '../models';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
interface ImageToDisplay {
  noteIndex: number;
  url: string;
  attachmentId: string;
}
interface ImageDatailOverlayProps {
  currentNote: EventNote;
  notesWithImages: EventNote[];
  noteText: string;
  userName: string;
  dateLabel: string;
  onCloseImageDetailsOverlay: () => void;
  onDeleteClick: () => void;
  onSetSelectedNote: (note: EventNote) => void;
  onSubmit: (noteText: string) => Promise<any> | void;
  usersByEmailName: { [emailName: string]: User };
  setText: (text: string) => void;
  initialText: string;
  tempAttachments: any;
  isAllowModification: boolean;
  selectedImage: string;
  setSelectedImage: (fileId: string) => void;
}

const ImageDatailOverlay = ({
  currentNote,
  dateLabel,
  initialText,
  isAllowModification,
  noteText,
  notesWithImages,
  onCloseImageDetailsOverlay,
  onDeleteClick,
  onSetSelectedNote,
  onSubmit,
  selectedImage,
  setText,
  tempAttachments,
  userName,
  usersByEmailName,
  setSelectedImage,
}: ImageDatailOverlayProps) => {
  const rightPanel = useRightPanel();
  const imagesToDisplay = React.useMemo(() => {
    const images = notesWithImages.reduce((noteAcc, note, i) => {
      note.attachments.reduce((attachmentAcc, attachment) => {
        if (attachment.name.match(extensionsRegex)) {
          attachmentAcc.push({
            noteIndex: i,
            url: attachment.url,
            attachmentId: attachment.id,
          });
        }
        return attachmentAcc;
      }, noteAcc);
      return noteAcc;
    }, [] as ImageToDisplay[]);
    return images.reverse();
  }, [notesWithImages]);

  const imageIndex = React.useMemo(() => {
    return imagesToDisplay.findIndex(
      image => image.attachmentId === selectedImage,
    );
  }, [selectedImage, imagesToDisplay]);
  const [isEditing, setIsEditing] = React.useState(false);

  const isShownLeftArrow = imageIndex > 0;
  const isShownRightArrow = imageIndex < imagesToDisplay.length - 1;
  const onLeftArrowClick = React.useCallback(() => {
    if (imageIndex > 0) {
      const newImage = imagesToDisplay[imageIndex - 1];
      const newNote = notesWithImages[newImage.noteIndex];
      setSelectedImage(newImage.attachmentId);
      if (currentNote.id !== newNote.id) {
        onSetSelectedNote(notesWithImages[newImage.noteIndex]);
      }
    }
  }, [
    onSetSelectedNote,
    notesWithImages,
    imageIndex,
    currentNote,
    imagesToDisplay,
    setSelectedImage,
  ]);

  const onRightArrowClick = React.useCallback(() => {
    if (imageIndex < imagesToDisplay.length - 1) {
      const newImage = imagesToDisplay[imageIndex + 1];
      const newNote = notesWithImages[newImage.noteIndex];
      setSelectedImage(newImage.attachmentId);

      if (currentNote.id !== newNote.id) {
        onSetSelectedNote(notesWithImages[newImage.noteIndex]);
      }
    }
  }, [
    onSetSelectedNote,
    notesWithImages,
    imageIndex,
    currentNote,
    imagesToDisplay,
    setSelectedImage,
  ]);

  const onImageDetailClose = React.useCallback(() => {
    onCloseImageDetailsOverlay();
    setSelectedImage('');
  }, [onCloseImageDetailsOverlay, setSelectedImage]);

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      const { target, key } = e;
      if (!(target instanceof window.HTMLElement)) {
        return;
      }

      const { tagName } = target;
      if (tagName === 'INPUT' || tagName === 'TEXTAREA') return;
      if (key === 'ArrowRight') {
        onRightArrowClick();
      } else if (key === 'ArrowLeft') {
        onLeftArrowClick();
      }
      return;
    },
    [onRightArrowClick, onLeftArrowClick],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <ImageDatailOverlay.Container righPanelWidth={rightPanel.width}>
      <ImageDatailOverlay.Header>
        <ImageDatailOverlay.InfoLabel>
          Image Details
        </ImageDatailOverlay.InfoLabel>
        <ImageDatailOverlay.ButtonContainer>
          <ImageDatailOverlay.ButtonWrapper>
            {isShownLeftArrow ? (
              <Button transparent onClick={onLeftArrowClick}>
                <ArrowIcon height={35} width={30} />
              </Button>
            ) : null}
          </ImageDatailOverlay.ButtonWrapper>
          <div>
            {imagesToDisplay.length > 1 ? (
              <ImageDatailOverlay.ImageCount>
                {`${imageIndex + 1} of ${imagesToDisplay.length}`}
              </ImageDatailOverlay.ImageCount>
            ) : null}
          </div>
          <ImageDatailOverlay.ButtonWrapper>
            {isShownRightArrow ? (
              <ImageDatailOverlay.RightArrow>
                <Button
                  transparent
                  onClick={() => {
                    onRightArrowClick();
                  }}
                >
                  <ArrowIcon height={35} width={30} />
                </Button>
              </ImageDatailOverlay.RightArrow>
            ) : null}
          </ImageDatailOverlay.ButtonWrapper>
          <ImageDatailOverlay.CloseIconWrapper>
            <Button
              transparent
              onClick={() => {
                onImageDetailClose();
              }}
            >
              <CloseIcon height={19} width={19} />
            </Button>
          </ImageDatailOverlay.CloseIconWrapper>
        </ImageDatailOverlay.ButtonContainer>
      </ImageDatailOverlay.Header>
      <ImageDatailOverlay.ImageContainer>
        <ImageDatailOverlay.ImageWrapper>
          <ImageDatailOverlay.Image
            src={R.path([imageIndex, 'url'], imagesToDisplay)}
          />
        </ImageDatailOverlay.ImageWrapper>
      </ImageDatailOverlay.ImageContainer>
      <ImageDatailOverlay.InputContainer>
        <ImageDatailOverlay.NoteInfo>
          <div>
            <ImageDatailOverlay.InfoLabel>
              {userName}
            </ImageDatailOverlay.InfoLabel>
            <ImageDatailOverlay.Date>{dateLabel}</ImageDatailOverlay.Date>
          </div>
          {isAllowModification ? (
            <ImageDatailOverlay.OwnerButtonContainer>
              {!isEditing ? (
                <Button
                  width={30}
                  transparent
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <EditIcon />
                </Button>
              ) : null}
              <Button width={30} transparent onClick={onDeleteClick}>
                <DeleteIcon />
              </Button>
            </ImageDatailOverlay.OwnerButtonContainer>
          ) : null}
        </ImageDatailOverlay.NoteInfo>
        <DetailsOverlayTextInput
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          usersByEmailName={usersByEmailName}
          noteText={noteText}
          onSubmit={onSubmit}
          setText={setText}
          initialText={initialText}
        />
      </ImageDatailOverlay.InputContainer>
    </ImageDatailOverlay.Container>
  );
};

ImageDatailOverlay.Container = styled.div`
  position: absolute;
  top: 5px;
  left: 6px;
  height: 100%;
  width: calc(100% - ${({ righPanelWidth }) => righPanelWidth + 16}px);
  max-height: calc(100% - 10px);
  z-index: 110;
  overflow: hidden;
  display: grid;
  grid-template-rows: 48px auto 150px;
  background: white;
`;

ImageDatailOverlay.Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #c1c1c1;
  box-shadow: 0 2px 3px rgb(0 0 0 / 15%);
`;

ImageDatailOverlay.Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato', sans-serif;
  position: relative;
`;

ImageDatailOverlay.Textarea = styled.textarea`
  padding: 10px 10px 6px;
  width: 100%;
  height: 0px;
  height: 100%;
  border: none;
  resize: none;
  border: 1px solid #c1c1c1;
  box-shadow: 0 2px 3px rgb(0 0 0 / 15%);
  :focus {
    outline: 0;
  }
`;

ImageDatailOverlay.Controls = styled.div`
  width: 100%;
  padding: 0 5px 5px 0;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
`;

ImageDatailOverlay.ButtonContainer = styled.div`
  margin-right: -10px;
  display: grid;
  grid-template-columns: 30px auto 30px 36px;
`;

ImageDatailOverlay.CloseIconWrapper = styled.div`
  padding-top: 1px;
  justify-self: flex-end;
`;

ImageDatailOverlay.ButtonWrapper = styled.div`
  > button {
    padding: 0;
    width: 30px;
    height: 35px;
  }
`;

ImageDatailOverlay.RightArrow = styled.div`
  transform: rotate(180deg);
  > button {
    padding: 0;
    width: 30px;
    height: 35px;
  }
`;

ImageDatailOverlay.Header = styled.div`
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px 15px 20px;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid #000;
  background: white;
`;

ImageDatailOverlay.NoteInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

ImageDatailOverlay.ImageWrapper = styled.div`
  min-height: 100%;
  min-width: 100%;
  background: white;
  overflow: hidden;
  position: relative;
  display: inline-block;
  overflow: hidden;
`;

ImageDatailOverlay.ImageContainer = styled.div`
  padding: 10px 25px 10px 35px;
`;

ImageDatailOverlay.InputContainer = styled.div`
  min-height: 100px;
  min-width: 100px;
  padding: 0px 25px 10px 35px;
  display: grid;
  grid-template-rows: 35px auto;
`;

ImageDatailOverlay.InfoLabel = styled.span`
  font-size: 20px;
  color: #484848;
  margin-bottom: 4px;
`;

ImageDatailOverlay.OwnerButtonContainer = styled.div`
  display: flex;
  > button {
    > svg {
      width: 20px;
      height: 20px;
    }
  }
`;

ImageDatailOverlay.ImageCount = styled.span`
  font-size: 20px;
  color: #484848;
  position: relative;
  padding: 0 3px;
  top: 7px;
`;

ImageDatailOverlay.Date = styled.span`
  font-size: 20px;
  color: #a9a9a9;
  margin: 0 3px 0 10px;
`;

ImageDatailOverlay.Button = styled(Button)`
  font-size: 11px;
  max-height: 25px;
`;

export default ImageDatailOverlay;
