import { utcMonth, utcYear } from 'd3-time';
import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import { getToday } from 'modules/appConfig/AppConfigReducer';
import type { Action, Selector } from 'store/models';
import { OIL, GAS, WATER } from 'modules/phase/models/phase';
import { getMaxDate, getMinDate } from 'helpers';

import type { GroupChartPoint } from './models/groupChart';
import {
  CLEAR_GROUP_CHART,
  CLEAR_ALL_GROUP_CHARTS,
  POPULATE_GROUP_CHART,
  POPULATE_PARTIAL_GROUP_CHART,
  POPULATE_GROUP_VARIANCE_CHART,
} from './GroupChartActions';
import { getDrilldownTableParams } from 'modules/drilldownTable/DrilldownTableReducer';

export const STATE_KEY = 'groupChart';

export interface GroupChartState {
  [netGross: string]: {
    [groupSubject: string]: {
      [groupName: string]: GroupChartPoint[];
    };
  };
}

const initialState: GroupChartState = {};

const GroupChartReducer = (
  state: GroupChartState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case POPULATE_GROUP_CHART: {
      const { groupSubject, groupName, chart, isNet } = action.payload;
      if (isNet) {
        return R.assocPath<string, GroupChartState, GroupChartPoint[]>(
          ['net', groupSubject, groupName],
          chart,
          state,
        );
      }
      return R.assocPath<string, GroupChartState, GroupChartPoint[]>(
        ['gross', groupSubject, groupName],
        chart,
        state,
      );
    }
    case POPULATE_GROUP_VARIANCE_CHART: {
      const { groupSubject, groupName, chart, isNet } = action.payload;
      if (isNet) {
        return R.assocPath<string, GroupChartState, GroupChartPoint[]>(
          ['variance', 'net', groupSubject, groupName],
          chart,
          state,
        );
      }
      return R.assocPath<string, GroupChartState, GroupChartPoint[]>(
        ['variance', 'gross', groupSubject, groupName],
        chart,
        state,
      );
    }
    case POPULATE_PARTIAL_GROUP_CHART: {
      const { groupSubject, groupName, chart, isNet } = action.payload;
      if (isNet) {
        return R.assocPath<string, GroupChartState, GroupChartPoint[]>(
          ['net', groupSubject, groupName],
          chart,
          state,
        );
      }

      return R.assocPath<string, GroupChartState, GroupChartPoint[]>(
        ['gross', groupSubject, groupName],
        chart,
        state,
      );
    }
    case CLEAR_ALL_GROUP_CHARTS: {
      if (!action.payload) return initialState;

      const { subject, item } = action.payload;
      const netChart = R.path(['net', subject, item], state);
      const grossChart = R.path(['gross', subject, item], state);
      const netVarianceChart = R.path(
        ['variance', 'net', subject, item],
        state,
      );
      const grossVarianceChart = R.path(
        ['variance', 'gross', subject, item],
        state,
      );
      return R.compose<
        Record<string, any>,
        Record<string, any>,
        Record<string, any>
      >(
        R.assocPath<string, GroupChartState, GroupChartPoint[] | null>(
          ['net', subject, item],
          netChart,
        ),
        R.assocPath<string, GroupChartState, GroupChartPoint[] | null>(
          ['gross', subject, item],
          grossChart,
        ),
        R.assocPath<string, GroupChartState, GroupChartPoint[] | null>(
          ['variance', 'net', subject, item],
          netVarianceChart,
        ),
        R.assocPath<string, GroupChartState, GroupChartPoint[] | null>(
          ['variance', 'gross', subject, item],
          grossVarianceChart,
        ),
      )(initialState);
    }
    case CLEAR_GROUP_CHART: {
      const { subject, item } = action.payload;
      return R.compose(
        R.assocPath<string, GroupChartState, null>(
          ['net', subject, item],
          null,
        ),
        R.assocPath<string, GroupChartState, null>(
          ['gross', subject, item],
          null,
        ),
        R.assocPath<string, GroupChartState, null>(
          ['variance', 'net', subject, item],
          null,
        ),
        R.assocPath<string, GroupChartState, null>(
          ['variance', 'gross', subject, item],
          null,
        ),
      )(state);
    }
    default: {
      return state;
    }
  }
};

export const getGroupChart: Selector<GroupChartPoint[]> = createSelector(
  (state: any) => state,
  (_, params) => params,
  getDrilldownTableParams,
  (
    state,
    { subject, item }: { subject: string; item: string },
    drilldownParams,
  ) => {
    const grossNet = R.pathOr(
      'gross',
      ['grossNet'],
      drilldownParams,
    ).toLowerCase();
    const chart = R.pathOr({}, [STATE_KEY, grossNet, subject, item], state);

    return chart;
  },
);
export const getGroupVarianceChart: Selector<GroupChartPoint[]> =
  createSelector(
    (state: any) => state,
    (_, params) => params,
    getDrilldownTableParams,
    (
      state,
      { subject, item }: { subject: string; item: string },
      drilldownParams,
    ) => {
      const grossNet = R.pathOr(
        'gross',
        ['grossNet'],
        drilldownParams,
      ).toLowerCase();
      const chart = R.pathOr(
        {},
        [STATE_KEY, 'variance', grossNet, subject, item],
        state,
      );

      return chart;
    },
  );
export const getFirstGroupProdDay: Selector<Date> = createSelector(
  getGroupChart,
  getToday,
  (groupChart, today) => {
    const defaultDate = utcMonth.floor(utcYear.offset(today, -2));
    if (R.isEmpty(groupChart)) return defaultDate;
    let oilFirstDay = defaultDate;
    for (const point of groupChart[OIL]) {
      if (point.production > 0) {
        oilFirstDay = point.day;
        break;
      }
    }
    let gasFirstDay = defaultDate;
    for (const point of groupChart[GAS]) {
      if (point.production > 0) {
        gasFirstDay = point.day;
        break;
      }
    }
    let waterFirstDay = defaultDate;
    for (const point of groupChart[WATER]) {
      if (point.production > 0) {
        waterFirstDay = point.day;
        break;
      }
    }

    return getMinDate(getMinDate(oilFirstDay, gasFirstDay), waterFirstDay);
  },
);

export const getLastGroupProdDay: Selector<Date> = createSelector(
  getGroupChart,
  groupChart => {
    const defaultDate = utcMonth.floor(utcMonth.offset(new Date(), 2));
    if (R.isEmpty(groupChart)) return defaultDate;
    const oilLastDay = groupChart[OIL].reduce((acc, point, i) => {
      if (point.production === 0) return acc;
      const nextPoint = groupChart[OIL][i + 1];
      if (nextPoint && nextPoint.production === 0) {
        return point.day;
      }
      return acc;
    }, defaultDate);
    const gasLastDay = groupChart[GAS].reduce((acc, point, i) => {
      if (point.production === 0) return acc;
      const nextPoint = groupChart[GAS][i + 1];
      if (nextPoint && nextPoint.production === 0) {
        return point.day;
      }
      return acc;
    }, defaultDate);
    const waterLastDay = groupChart[WATER].reduce((acc, point, i) => {
      if (point.production === 0) return acc;
      const nextPoint = groupChart[WATER][i + 1];
      if (nextPoint && nextPoint.production === 0) {
        return point.day;
      }
      return acc;
    }, defaultDate);

    return getMaxDate(getMaxDate(oilLastDay, gasLastDay), waterLastDay);
  },
);

export default filterActions(GroupChartReducer, [
  CLEAR_GROUP_CHART,
  CLEAR_ALL_GROUP_CHARTS,
  POPULATE_GROUP_CHART,
  POPULATE_GROUP_VARIANCE_CHART,
  POPULATE_PARTIAL_GROUP_CHART,
]);
