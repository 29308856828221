import * as React from 'react';
import styled from 'styled-components';

export type ButtonProps = {
  children: React.ReactNode;
  transparent?: boolean;
  width?: number;
  height?: number;
  title?: string;
  variant?: 'normal' | 'critical';
  type?: 'button' | 'submit';
  onClick?:
    | ((e: MouseEvent) => void)
    | ((e: React.FormEvent<HTMLFormElement>) => void);
  style?: any;
  disabled?: boolean;
  className?: string;
};

const Button = ({
  variant = 'normal',
  type = 'button',
  ...props
}: ButtonProps) => {
  return <Button.Styled variant={variant} {...props} type={type} />;
};

Button.Styled = styled.button`
  width: ${(props: any) => (props.width ? props.width : '39')}px;
  height: ${(props: any) => (props.height ? props.height : '33')}px;
  background: ${(props: any) =>
    props.transparent
      ? 'transparent'
      : 'linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%)'};
  border: ${(props: any) => (props.transparent ? 'none' : '1px solid #c1c1c1')};
  box-shadow: ${(props: any) =>
    props.transparent ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.15)'};
  outline: none;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  color: ${(props: any) =>
    props.disabled
      ? 'lightgrey'
      : props.variant === 'normal'
      ? props.theme.colors.primaryText
      : props.theme.colors.criticalText};
  cursor: ${(props: any) => (props.disabled ? 'auto' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 3px 2px 3px;
  position: relative;
`;

export default Button;
