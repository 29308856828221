import { all, fork } from 'redux-saga/effects';

import allocIssueSagas from 'modules/allocIssue/AllocIssueSagas';
import chartSagas from 'modules/chart/ChartSagas';
import externalForecastSagas from 'modules/externalForecast/ExternalForecastSagas';
import allocIssueCauseSagas from 'modules/allocIssueCause/AllocIssueCauseSagas';
import allocIssueStatusSagas from 'modules/allocIssueStatus/AllocIssueStatusSagas';
import appSagas from 'modules/app/AppSagas';
import appConfigSagas from 'modules/appConfig/AppConfigSagas';
import authSagas from 'modules/auth/AuthSagas';
import categorySagas from 'modules/category/CategorySagas';
import capacitySagas from 'modules/capacityChangeEvent/CapacityChangeEventSagas';
import chartOptionsSagas from 'modules/chartOptions/ChartOptionsSagas';
import drilldownTableSagas from 'modules/drilldownTable/DrilldownTableSagas';
import eventNotesSagas from 'modules/eventNotes/EventNotesSagas';
import filterSagas from 'modules/filter/FilterSagas';
import groupChartSagas from 'modules/groupChart/GroupChartSagas';
import inboxConversationSagas from 'modules/inboxConversation/InboxConversationSagas';
import mentionNotificationSagas from 'modules/mentionNotification/MentionNotificationSagas';
import notificationSagas from 'modules/notification/NotificationSagas';
import notesSagas from 'modules/notes/NotesSagas';
import phaseSagas from 'modules/phase/PhaseSagas';
import productionSagas from 'modules/production/ProductionSagas';
import ribbonSagas from 'modules/ribbon/RibbonSagas';
import routerSagas from 'modules/router/RouterSagas';
import seriesSagas from 'modules/series/SeriesSagas';
import settingsSagas from 'modules/settings/SettingsSagas';
import uiSagas from 'modules/ui/UISagas';
import userSagas from 'modules/user/UserSagas';
import varianceEventSagas from 'modules/varianceEvent/VarianceEventSagas';
import wellSagas from 'modules/well/WellSagas';
import seriesLayoutsSagas from 'modules/seriesLayouts/SeriesLayoutsSagas';
import filtersLayoutsSagas from 'modules/filterLayouts/FilterLayoutsSaga';
import metricsSagas from 'modules/metrics/MetricsSagas';

function* rootSaga(): Generator<any, any, any> {
  yield all([
    fork(allocIssueSagas),
    fork(allocIssueCauseSagas),
    fork(allocIssueStatusSagas),
    fork(appConfigSagas),
    fork(appSagas),
    fork(authSagas),
    fork(capacitySagas),
    fork(categorySagas),
    fork(chartOptionsSagas),
    fork(chartSagas),
    fork(drilldownTableSagas),
    fork(eventNotesSagas),
    fork(externalForecastSagas),
    fork(filterSagas),
    fork(filtersLayoutsSagas),
    fork(groupChartSagas),
    fork(inboxConversationSagas),
    fork(mentionNotificationSagas),
    fork(notificationSagas),
    fork(notesSagas),
    fork(phaseSagas),
    fork(productionSagas),
    fork(ribbonSagas),
    fork(routerSagas),
    fork(seriesSagas),
    fork(seriesLayoutsSagas),
    fork(settingsSagas),
    fork(userSagas),
    fork(uiSagas),
    fork(varianceEventSagas),
    fork(wellSagas),
    fork(metricsSagas),
  ]);
}

export default rootSaga;
