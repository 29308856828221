import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faPercent } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';

import SearchForm from 'components/SearchForm';

import { CELL_HEIGHT } from '../models/drilldownTable';
import VarianceDrilldownTableColumns from '../components/VarianceDrilldownTableColumns';
import Button from 'components/Button';
import { setDrilldownTableOptionColumns } from '../DrilldownTableActions';

interface VarianceDrilldownTableProps {
  addSeveralOptions: (optionIds: string[]) => void;
  addVarianceOption: (optioinId: string) => void;
  currentTable: string | null;
  currentVarianceOptionIds: string[];
  varianceDrilldownTable: {
    [key: string]: string | number;
  }[];
  height: number;
  isDisplayed: boolean;
  isTableLoading: boolean;
  onVarianceTableRowChoose: (rowData: Record<string, any>) => void;
  removeVarianceOption: (optioinId: string) => void;
  resetVarianceOption: () => void;
  searchWord: string;
  setSearchWord: (searchWord: string) => void;
  setStorageVarianceTableHeight: (tableHeight: number) => void;
  setVarianceTableHeight: (
    value: number | ((prevVar: number) => number),
  ) => void;
  volumeType: boolean;
}

const VarianceDrilldownTable = ({
  addSeveralOptions,
  addVarianceOption,
  currentTable,
  currentVarianceOptionIds,
  varianceDrilldownTable,
  height,
  isDisplayed,
  isTableLoading,
  onVarianceTableRowChoose,
  removeVarianceOption,
  resetVarianceOption,
  searchWord,
  setSearchWord,
  setStorageVarianceTableHeight,
  setVarianceTableHeight,
  volumeType,
}: VarianceDrilldownTableProps) => {
  const dispatch = useDispatch();

  const calculateDrilldownHeight = (e: MouseEvent) => {
    const mouseYPos = e.clientY;
    const viewportHeight = window.innerHeight;
    const height = Math.max(viewportHeight - mouseYPos - 22, 0);
    const maxHeight = viewportHeight - 174 - CELL_HEIGHT * 5;
    return Math.min(height, maxHeight);
  };

  const onHandleMouseMove = React.useCallback(
    (e: MouseEvent) => {
      const varianceTableHeight = calculateDrilldownHeight(e);
      setVarianceTableHeight(varianceTableHeight);
    },
    [setVarianceTableHeight],
  );

  const firstColDataKey = 'subCause';
  const secondColDataKey = 'percent';
  const thirdColDataKey = 'currency';
  const fourthColDataKey = 'variance';

  const onHandleMouseUp = React.useCallback(
    e => {
      const varianceTableHeight = calculateDrilldownHeight(e);
      setStorageVarianceTableHeight(varianceTableHeight);
      removeMouseUpEvent();
      document.removeEventListener('mousemove', onHandleMouseMove);
    },
    [onHandleMouseMove, setStorageVarianceTableHeight],
  );

  const removeMouseUpEvent = () => {
    document.removeEventListener('mouseup', onHandleMouseUp);
  };

  const onHandleMouseDown = React.useCallback(() => {
    document.addEventListener('mousemove', onHandleMouseMove);
    document.addEventListener('mouseup', onHandleMouseUp);
  }, [onHandleMouseMove, onHandleMouseUp]);

  return (
    <VarianceDrilldownTable.Container
      style={{ height: height + 11 + 'px' }}
      isDisplayed={isDisplayed}
    >
      <VarianceDrilldownTable.MenuWrapper>
        <VarianceDrilldownTable.DragHandle onMouseDown={onHandleMouseDown}>
          <VarianceDrilldownTable.DragIcon />
        </VarianceDrilldownTable.DragHandle>
        <VarianceDrilldownTable.SectionWithFilters>
          <SearchForm
            placeholder="Search"
            inputHandler={setSearchWord}
            value={searchWord}
            width={340}
          />
          <VarianceDrilldownTable.OptionButton
            width={30}
            height={26}
            onClick={() => {
              dispatch(setDrilldownTableOptionColumns(secondColDataKey));
            }}
          >
            <FontAwesomeIcon icon={faPercent} />
          </VarianceDrilldownTable.OptionButton>
          <VarianceDrilldownTable.OptionButton
            width={30}
            height={26}
            onClick={() => {
              dispatch(setDrilldownTableOptionColumns(thirdColDataKey));
            }}
          >
            <FontAwesomeIcon icon={faDollarSign} />
          </VarianceDrilldownTable.OptionButton>
        </VarianceDrilldownTable.SectionWithFilters>
      </VarianceDrilldownTable.MenuWrapper>
      <VarianceDrilldownTableColumns
        addSeveralOptions={addSeveralOptions}
        addVarianceOption={addVarianceOption}
        currentTable={currentTable}
        currentVarianceOptionIds={currentVarianceOptionIds}
        drilldownTable={varianceDrilldownTable}
        isLoading={isTableLoading}
        onChoose={onVarianceTableRowChoose}
        removeVarianceOption={removeVarianceOption}
        resetCurrentVarianceOption={resetVarianceOption}
        searchWord={searchWord}
        tableHeight={height}
        volumeType={volumeType}
        firstColDataKey={firstColDataKey}
        secondColDataKey={secondColDataKey}
        thirdColDataKey={thirdColDataKey}
        fourthColDataKey={fourthColDataKey}
      />
    </VarianceDrilldownTable.Container>
  );
};

VarianceDrilldownTable.Container = styled.div`
  border-left: 1px solid #c1c1c1;
  border-right: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  display: ${props => (props.isDisplayed ? 'block' : 'none')};
`;

VarianceDrilldownTable.MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  height: 42px;
  border-top: 1px solid #c1c1c1;
  padding: 7px 6px 2px;
  position: relative;

  > button {
    z-index: 3;
    padding: 1px 0 2px 2px;
  }
`;

VarianceDrilldownTable.DragHandle = styled.div`
  height: 13px;
  width: 100%;
  position: absolute;
  top: -2px;
  left: 0;
  cursor: ns-resize;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

VarianceDrilldownTable.SectionWithFilters = styled.div`
  height: 42px;
  display: grid;
  width: 100%;
  grid-template: 1fr / minmax(0, 1fr) auto auto;
  gap: 8px;
  align-items: center;

  input {
    width: 100%;
  }
`;

VarianceDrilldownTable.OptionButton = styled(Button)`
  border: ${props => {
    return props.open ? '1px solid #3297fd' : '';
  }};
`;

VarianceDrilldownTable.DragIcon = styled.div`
  height: 4px;
  width: 20px;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
`;
export default VarianceDrilldownTable;
