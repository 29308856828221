import { all, delay, put, takeLatest, select } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import * as R from 'ramda';

import { isIdNew } from 'helpers';
import {
  CLOSE_VARIANCE_DIALOG,
  setNewlyCreatedVarianceEvent,
  setRightPanelDialog,
  SET_CURRENT_WELL_ID,
} from 'modules/ui/UIActions';
import { getDefaultVarianceCategoryId } from 'modules/category/CategoryReducer';
import { Action } from 'store/models';

import {
  CREATE_VARIANCE_EVENT_LOCALLY,
  UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY,
  UPDATE_VARIANCE_EVENT_DATES_LOCALLY,
  UPDATE_VARIANCE_EVENT_NOTES_LOCALLY,
  UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY,
  fetchVarianceEvents,
  updateRemoteVarianceEvent,
  populateVarianceEventsAfterCreating,
  deleteRemoteVarianceEvent,
  DELETE_VARIANCE_EVENT_LOCALLY,
  CREATE_REMOTE_VARIANCE_EVENT,
  FETCH_VARIANCE_EVENTS,
  UPDATE_VARIANCE_EVENT_DESCRIPTION_LOCALLY,
  updateRemoteVarianceEventDescription,
  populateVarianceEvents,
} from './VarianceEventActions';
import {
  getVarianceEvent,
  getWellVarianceEventsIndexedById,
  getWellVarianceEventsSortedByDate,
} from './VarianceEventReducer';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import { getCurrentWellId, getRightPanelDialog } from 'modules/ui/UIReducer';
import { parseSearchParams } from 'modules/router/utils/router';
import { EventPanel } from 'modules/router/models/router';
import { OPEN_VARIANCE_CONVERSATION } from 'modules/inboxConversation/InboxConversationActions';
import { VarianceEvent } from './models/varianceEvent';
import { normalizeVarianceEvents } from './utils';

function* fetchWellVarianceEventsSaga(
  action: Action,
): Generator<any, any, any> {
  if (
    window.location.pathname.includes('/dashboard') ||
    window.location.pathname.includes('/filter') ||
    window.location.pathname.includes('/share')
  ) {
    const currentWellId = action.payload;
    yield put(fetchVarianceEvents(currentWellId));
  }
}

function* initSyncVarianceWithApiSaga(action): Generator<any, any, any> {
  const { varianceEventId, wellId } = action.payload;
  if (isIdNew(varianceEventId)) return;

  yield delay(700);

  const varianceEvent = yield select(getVarianceEvent, {
    wellId,
    varianceEventId,
  });

  yield put(updateRemoteVarianceEvent(varianceEvent));
}

function* calculateNewVarianceEventSaga(action): Generator<any, any, any> {
  const { wellId, date } = action.payload;
  const oldVarianceEvents = yield select(getWellVarianceEventsIndexedById, {
    wellId,
  });
  const defaultVarianceCategory = yield select(getDefaultVarianceCategoryId);
  const newVarianceEventId = uuid();
  const newEvent: VarianceEvent = {
    id: newVarianceEventId,
    dayStart: date,
    dayEnd: date,
    wellId,
    varianceOptionId: defaultVarianceCategory,
    extraInputsData: null,
    description: null,
    notes: null,
  };
  const newVarianceEvents = {
    ...oldVarianceEvents,
    [newVarianceEventId]: newEvent,
  };

  yield put(setNewlyCreatedVarianceEvent(newEvent));
  yield put(populateVarianceEventsAfterCreating({ wellId, newVarianceEvents }));
}

function* deleteVarianceEventRemotelySaga(action): Generator<any, any, any> {
  const { varianceEventId } = action.payload;

  if (!isIdNew(varianceEventId)) {
    yield put(deleteRemoteVarianceEvent(varianceEventId));
  }
}

function* updateRouteWithEventPanelAfterCreatingSaga(
  action,
): Generator<any, any, any> {
  const wellId = yield select(getCurrentWellId);
  const events = yield select(getWellVarianceEventsSortedByDate, { wellId });
  const oldVarianceEvent = yield select(getRightPanelDialog);
  const newVarianceEvent = getGraphqlPayload(action);

  const eventIndex = events.findIndex(e => e.id === newVarianceEvent.id);
  if (eventIndex === -1 || !isIdNew(oldVarianceEvent?.data?.id ?? '')) return;

  yield put(
    setRightPanelDialog({
      type: 'VarianceChangeEvent',
      data: {
        id: newVarianceEvent.id,
        index: eventIndex,
      },
    }),
  );
}

function* openVariancePanelFromRouteSaga(action): Generator<any, any, any> {
  const events = getGraphqlPayload(action);
  const searchParams = parseSearchParams(window.location.search);
  const currentWellId = yield select(getCurrentWellId);

  if (
    !searchParams.eventPanel ||
    searchParams.eventPanel.type !== EventPanel.variance ||
    R.isEmpty(events)
  )
    return;

  const { id } = searchParams.eventPanel;
  const index = events
    .map(e => ({ date: Date.parse(e.dayStart), id: e.id, wellId: e.wellId }))
    .sort((a, b) => b.date - a.date)
    .findIndex(e => e.id === id && e.wellId === currentWellId);

  if (index < 0) {
    return yield put({ type: CLOSE_VARIANCE_DIALOG });
  }

  yield put(
    setRightPanelDialog({ type: 'VarianceChangeEvent', data: { index, id } }),
  );
}

function* openVarianceConversationSaga(action): Generator<any, any, any> {
  const { id, wellId } = action.payload;

  const events = yield select(getWellVarianceEventsSortedByDate, {
    wellId,
  });
  const eventIndex = events.findIndex(e => e.id === id);
  const index = Math.max(0, eventIndex);
  const data = { index, id };

  yield put(setRightPanelDialog({ type: 'VarianceChangeEvent', data }));
}

function* updateVarianceEventDescriptionRemotelySaga(
  action,
): Generator<any, any, any> {
  const { description, id } = action.payload;
  yield put(
    updateRemoteVarianceEventDescription({
      description,
      id,
    }),
  );
}

function* populateVarianceEventsSaga(action): Generator<any, any, any> {
  const rawVariance = getGraphqlPayload(action);
  const { wellId } = getGraphqlPrevActionVariables(action);

  const normalizedVarianceEvents: {
    [id: string]: VarianceEvent;
  } = normalizeVarianceEvents(rawVariance);

  yield put(
    populateVarianceEvents({
      wellId,
      events: normalizedVarianceEvents,
    }),
  );
}

function* varianceEventSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(SET_CURRENT_WELL_ID, fetchWellVarianceEventsSaga),
    takeLatest(`${FETCH_VARIANCE_EVENTS}_SUCCESS`, populateVarianceEventsSaga),
    takeLatest(
      [
        UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY,
        UPDATE_VARIANCE_EVENT_DATES_LOCALLY,
        UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY,
        UPDATE_VARIANCE_EVENT_NOTES_LOCALLY,
      ],
      initSyncVarianceWithApiSaga,
    ),
    takeLatest(OPEN_VARIANCE_CONVERSATION, openVarianceConversationSaga),
    takeLatest(
      `${CREATE_REMOTE_VARIANCE_EVENT}_SUCCESS`,
      updateRouteWithEventPanelAfterCreatingSaga,
    ),
    takeLatest(CREATE_VARIANCE_EVENT_LOCALLY, calculateNewVarianceEventSaga),
    takeLatest(DELETE_VARIANCE_EVENT_LOCALLY, deleteVarianceEventRemotelySaga),
    takeLatest(
      UPDATE_VARIANCE_EVENT_DESCRIPTION_LOCALLY,
      updateVarianceEventDescriptionRemotelySaga,
    ),
    takeLatest(
      `${FETCH_VARIANCE_EVENTS}_SUCCESS`,
      openVariancePanelFromRouteSaga,
    ),
  ]);
}

export default varianceEventSagas;
