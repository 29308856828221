import gql from 'graphql-tag';

import { GraphqlMutationAction } from 'store/models';

const namespace = 'NOTE_ATTACHMENT';
export const UPLOAD_NOTE_ATTACHMENT = `${namespace}/UPLOAD_NOTE_ATTACHMENT`;
export const DELETE_NOTE_ATTACHMENT = `${namespace}/DELETE_NOTE_ATTACHMENT`;

const UPLOAD_ATTACHMENT_MUTATION = gql`
  mutation ($payload: Upload!) {
    uploadAttachment(file: $payload) {
      id
      url
      name
    }
  }
`;

type uploadAttachmentAC = (
  payload: Record<string, any>,
) => GraphqlMutationAction;

export const uploadAttachment: uploadAttachmentAC = payload => ({
  type: UPLOAD_NOTE_ATTACHMENT,
  payload: {
    key: 'uploadAttachment',
    graphql: {
      mutation: UPLOAD_ATTACHMENT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const DELETE_ATTACHMENT_MUTATION = gql`
  mutation ($payload: ID!) {
    deleteAttachment(id: $payload)
  }
`;

type deleteAttachmentAC = (id: string) => GraphqlMutationAction;

export const deleteAttachment: deleteAttachmentAC = payload => ({
  type: DELETE_NOTE_ATTACHMENT,
  payload: {
    key: 'deleteAttachment',
    graphql: {
      mutation: DELETE_ATTACHMENT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});
