import React from 'react';
import { useSelector } from 'react-redux';

import { getId, getFullName } from 'modules/auth/AuthReducer';
import {
  getRegionOfInterestData,
  getFilteredWellTable,
  getCurrentMarkedRows,
} from 'modules/drilldownTable/DrilldownTableReducer';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import {
  getSelectedRibbons,
  getRightPanelDialog,
  getCurrentGroup,
  getCurrentWellId,
} from 'modules/ui/UIReducer';
import { getForecastStatus } from 'modules/externalForecast/ExternalForecastReducer';
import {
  getDataSeriesGroupsToDisplay,
  getCoreSeriesOptionsToDisplay,
} from 'modules/chartOptions/ChartOptionsReducer';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import { getLeaseToIdMapping } from 'modules/well/WellReducer';

import { SpotfireDashboardInput } from '../models';
import useSpotfireInputStorage from '../hooks/useSpotfireInputStorage';
import spotfireDate from '../utils/spotfireDate';
import spotfireIdsArray from '../utils/spotfireIdsArray';

const DashboardsInputPersistor = () => {
  const userId: number = useSelector(getId);
  const userName = useSelector(getFullName);
  const roi = useSelector(getRegionOfInterestData);
  const extremeDates = useSelector(getExtremeDates);
  const coreSeries = useSelector(getCoreSeriesOptionsToDisplay);
  const dataSeries = useSelector(getDataSeriesGroupsToDisplay);
  const appConfig = useSelector(getAppConfig);
  const filteredWellTable = useSelector(getFilteredWellTable);
  const forecastStatus = useSelector(getForecastStatus);
  const selectedRibbons = useSelector(getSelectedRibbons);
  const rightPanelDialog = useSelector(getRightPanelDialog);
  const currentGroup = useSelector(getCurrentGroup);
  const currentWellId = useSelector(getCurrentWellId);
  const currentMarkedRows = useSelector(getCurrentMarkedRows);
  const wellsLeaseToIdMapping = useSelector(getLeaseToIdMapping);

  const storage = useSpotfireInputStorage();

  const setInput = React.useCallback(
    (key: keyof SpotfireDashboardInput, value) => {
      storage.setData({ [key]: value });
    },
    [storage.setData],
  );

  React.useEffect(() => {
    if (!currentMarkedRows.length) {
      setInput('inMarkedWells', `"${currentWellId}"`);
      return;
    }

    const wellIds = currentMarkedRows
      .map(row => wellsLeaseToIdMapping[row])
      .filter(Boolean);

    const markedWellsIds = wellIds.length
      ? spotfireIdsArray.stringify(wellIds)
      : `"${currentWellId}"`;

    setInput('inMarkedWells', markedWellsIds);
  }, [setInput, currentWellId, currentMarkedRows, wellsLeaseToIdMapping]);

  const filteredWellsString = React.useMemo(
    () => spotfireIdsArray.stringify(filteredWellTable.map(w => w.wellId)),
    [filteredWellTable],
  );

  React.useEffect(() => {
    setInput('inCoreFilteredWells', filteredWellsString);
  }, [setInput, filteredWellsString]);

  React.useEffect(() => {
    setInput('inViewStartDate', spotfireDate.stringify(extremeDates?.min));
  }, [setInput, extremeDates?.min]);

  React.useEffect(() => {
    setInput('inViewEndDate', spotfireDate.stringify(extremeDates?.max));
  }, [setInput, extremeDates?.max]);

  React.useEffect(() => {
    const val = coreSeries.map(o => o.id);
    setInput('inCoreSeries', val);
  }, [setInput, coreSeries]);

  React.useEffect(() => {
    const val = dataSeries.map(o => o.ids);
    setInput('inDataSeries', val);
  }, [setInput, dataSeries]);

  React.useEffect(() => {
    setInput('inRibbons', selectedRibbons);
  }, [setInput, selectedRibbons]);

  React.useEffect(() => {
    const val = forecastStatus && appConfig.showExternalForecast;
    setInput('inShowExternalForecast', val);
  }, [setInput, forecastStatus, appConfig.showExternalForecast]);

  React.useEffect(() => {
    setInput('inRoiStartDate', spotfireDate.stringify(roi.startDate));
  }, [setInput, roi.startDate]);

  React.useEffect(() => {
    setInput('inRoiEndDate', spotfireDate.stringify(roi.endDate));
  }, [setInput, roi.endDate]);

  React.useEffect(() => {
    setInput('inRoiCompare', roi.compare);
  }, [setInput, roi.compare]);

  React.useEffect(() => {
    setInput('inRoiGrossOrNet', roi.grossOrNet);
  }, [setInput, roi.grossOrNet]);

  React.useEffect(() => {
    setInput('inRoiPhase', roi.phase);
  }, [setInput, roi.phase]);

  React.useEffect(() => {
    setInput('inRoiRateOrVolume', roi.rateOrVolume);
  }, [setInput, roi.rateOrVolume]);

  React.useEffect(() => {
    setInput('inRoiGroupBy', currentGroup?.subject);
  }, [setInput, currentGroup?.subject]);

  React.useEffect(() => {
    setInput('inRoiSortBy', roi.sortBy);
  }, [setInput, roi.sortBy]);

  React.useEffect(() => {
    setInput('inEventPanel', {
      type: rightPanelDialog?.type,
      event: rightPanelDialog?.data,
    });
  }, [setInput, rightPanelDialog]);

  React.useEffect(() => {
    setInput('inUserMetadata', {
      user_id: userId,
      user_name: userName,
    });
  }, [setInput, userId, userName]);

  return null;
};

export default React.memo(DashboardsInputPersistor);
