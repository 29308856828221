import * as React from 'react';

import { getTextColorFromBgColor } from 'helpers';
import {
  RibbonEvent,
  RibbonOption,
  RibbonEventBounds,
} from 'modules/ribbon/models';

interface RibbonEventRectProps {
  currentEvent: RibbonEvent;
  currentBounds: RibbonEventBounds;
  isAxisDragging: boolean;
  onRibbonEventDialogOpen(string): void;
  onSetActiveEventId: (id: string | null) => void;
  ribbonOptions: { [ribbon: string]: RibbonOption };
}

const RibbonEventRect = ({
  currentEvent,
  currentBounds,
  isAxisDragging,
  onRibbonEventDialogOpen,
  onSetActiveEventId,
  ribbonOptions,
}: RibbonEventRectProps) => {
  const currentOption = ribbonOptions[currentEvent.ribbonOptionId] || {};
  const type = currentOption.type || '';
  const textWidth = type.length * 6;
  const { x1, x2, y1, y2 } = currentBounds;

  const beginEventStartDrag = e => {
    e.stopPropagation();
    onRibbonEventDialogOpen(currentEvent.id);
  };

  return (
    <g key={currentEvent.id} onClick={beginEventStartDrag} pointerEvents="auto">
      <g
        cursor={`${isAxisDragging ? 'grabbing' : 'pointer'}`}
        onMouseEnter={() => onSetActiveEventId(currentEvent.id)}
        onMouseLeave={() => onSetActiveEventId(null)}
      >
        <polygon
          stroke="black"
          fill={currentOption.color || '#000000'}
          points={`${x1},${y1} ${x2},${y1} ${x2},${y2} ${x1},${y2}`}
        />
        {currentBounds.width > textWidth && (
          <text
            textAnchor="middle"
            x={currentBounds.textX}
            y={y1 - 2}
            fontFamily="Verdana"
            fontSize="10"
            fill={getTextColorFromBgColor(currentOption.color || '#000000')}
          >
            {type}
          </text>
        )}
      </g>
    </g>
  );
};

export default React.memo<RibbonEventRectProps>(RibbonEventRect);
