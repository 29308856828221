import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import { getId, getFullName } from 'modules/auth/AuthReducer';
import { getUser } from 'modules/user/UserReducer';

import Button from 'components/Button';
import TitledDivider from 'components/TitledDivider';
import Modal from 'components/ModalWindowV2';

import { PERMISSION_KEYS } from '../constants';

type AccountWindowProps = {
  open: boolean;
  handleClose: () => void;
};

const AccountWindow = ({ open, handleClose }: AccountWindowProps) => {
  const userId = useSelector(getId);
  const name = useSelector(getFullName);
  const user = useSelector(store => getUser(store, userId));

  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      title="My Account"
      width={460}
    >
      <AccountWindow.UserName>{name}</AccountWindow.UserName>

      <AccountWindow.UserEmail>{user.email}</AccountWindow.UserEmail>

      <TitledDivider>Permissions</TitledDivider>
      <AccountWindow.Permissions>
        {R.keys(PERMISSION_KEYS).map(permission => {
          if (!user[permission]) return null;
          return (
            <AccountWindow.Permission key={permission}>
              <AccountWindow.CheckIcon />
              {PERMISSION_KEYS[permission]}
            </AccountWindow.Permission>
          );
        })}
      </AccountWindow.Permissions>

      <Button width={85} onClick={handleClose}>
        Close
      </Button>
    </Modal>
  );
};

AccountWindow.UserName = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 6px;
`;

AccountWindow.UserEmail = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

AccountWindow.Permissions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

AccountWindow.Permission = styled.p`
  flex-basis: 50%;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  position: relative;
  padding: 0 10px 0 20px;
`;

AccountWindow.CheckIcon = styled(FaCheck)`
  color: #41ab5d;
  position: absolute;
  top: 2px;
  left: 0;
`;

export default AccountWindow;
