import { utcDay } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';

interface EventVisibleDividerProps {
  color: string;
  eventDates: { dayStart: Date; dayEnd: Date };
  hideLine?: string;
  isEditable?: boolean;
  xScale: any;
}

const EventVisibleDivider = ({
  color,
  eventDates,
  hideLine,
  isEditable,
  xScale,
}: EventVisibleDividerProps) => {
  return (
    <EventVisibleDivider.Container>
      {hideLine !== 'left' && (
        <EventVisibleDivider.Line
          position={xScale(eventDates.dayStart)}
          color={color}
          lineWidth={isEditable ? '3' : '1'}
          isEditable={isEditable}
        />
      )}
      {hideLine !== 'right' && (
        <EventVisibleDivider.Line
          position={xScale(utcDay.offset(eventDates.dayEnd, 1))}
          lineWidth={isEditable ? '3' : '1'}
          color={color}
        />
      )}
    </EventVisibleDivider.Container>
  );
};

EventVisibleDivider.Container = styled.div`
  position: absolute;
  z-index: 74;
  height: 100%;
  width: 100%;
  left: 0;
  pointer-events: none;
  overflow: hidden;
`;

EventVisibleDivider.Line = styled.div`
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: transparent;
  border-style: solid;
  border-color: ${(props: Record<string, any>) => props.color};
  border-left-width: ${(props: Record<string, any>) => props.lineWidth}px;
  transform: ${(props: Record<string, any>) =>
    `translateX(${props.position - props.lineWidth / 2}px)`};
  pointer-events: none;
`;

export default React.memo<EventVisibleDividerProps>(EventVisibleDivider);
