import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { timeFormat } from 'd3-time-format';
import Tooltip from '@material-ui/core/Tooltip';

import { CommentIcon } from 'components/Icons';
import { EventNote } from 'modules/eventNotes/models';
import { extensionsRegex } from '../models';
import { getLastWellEventsNotes } from 'modules/eventNotes/EventNotesReducer';
import { getUsers } from 'modules/user/UserReducer';
import { User } from 'modules/user/models/user';

interface TotalRowCount {
  noteCount: number;
  rowCount: number;
}

interface EventNoteTooltipProps {
  wellId: string | number;
  eventType: string;
  eventId: string | number;
}

const getUserDisplayName = (users: { [id: string]: User }, note: EventNote) => {
  const { userOverwrite, userId } = note;
  if (!R.isNil(userOverwrite) && userOverwrite !== '') {
    return userOverwrite as string;
  }
  const firsName = R.pathOr('', [userId, 'firstName'], users);
  const lastName = R.pathOr('', [userId, 'lastName'], users);
  const userName = `${firsName} ${lastName}`;

  return userName.trim() !== '' ? userName : 'Deleted user';
};

const EventNoteTooltip = ({
  wellId,
  eventType,
  eventId,
}: EventNoteTooltipProps) => {
  const users = useSelector(getUsers);
  const eventNotes = useSelector(store =>
    getLastWellEventsNotes(store, {
      wellId: wellId,
      eventType: eventType,
    }),
  );
  const canvas = React.useMemo(() => document.createElement('canvas'), []);
  const getTextWidth = React.useCallback(
    text => {
      const context = canvas.getContext('2d');
      //@ts-expect-error
      context.font = '11px lato';
      //@ts-expect-error
      const metrics = context.measureText(text);
      return metrics.width;
    },
    [canvas],
  );

  const tooltipEventNotes = React.useMemo(
    () => R.values(eventNotes[eventId]).reverse(),
    [eventNotes, eventId],
  );

  const getTooltipText = React.useCallback((note: any) => {
    const attachments = R.pathOr([], ['attachments'], note);
    const noteText = R.pathOr('', ['noteText'], note);
    if (attachments.length) {
      const attachmentsTypes = attachments.reduce(
        (acc, attachment) => {
          if (attachment.name.match(extensionsRegex)) {
            acc.images++;
          } else {
            acc.files++;
          }
          return acc;
        },
        { images: 0, files: 0 },
      );
      const imageAttachments =
        attachmentsTypes.images > 1
          ? `[${attachmentsTypes.images} images]\n`
          : attachmentsTypes.images === 1
          ? '[image]\n'
          : '';
      const fileAttachments =
        attachmentsTypes.files > 1
          ? `[${attachmentsTypes.files} files]\n`
          : attachmentsTypes.files === 1
          ? '[file]\n'
          : '';
      return imageAttachments + fileAttachments + noteText;
    }
    return noteText;
  }, []);

  const getRowCount = React.useCallback(
    (note: any) => {
      const text = getTooltipText(note);
      const textByLines = text.split('\n');
      const rowCount = textByLines.reduce((acc, row) => {
        const totalWidth = getTextWidth(row);
        return (acc += Math.ceil(totalWidth / 280));
      }, 0);
      return rowCount;
    },
    [getTooltipText, getTextWidth],
  );

  const totalRowCount = React.useMemo(
    (): TotalRowCount =>
      R.values(eventNotes[eventId]).reduce(
        (acc, note) => {
          if (acc.rowCount <= 30) {
            acc.noteCount++;
          }
          acc.rowCount++;
          const text = getTooltipText(note);
          const textByLines = text.split('\n');
          acc.rowCount += textByLines.reduce((rowAcc, row) => {
            const totalWidth = getTextWidth(row);

            return (rowAcc += Math.ceil(totalWidth / 280));
          }, 0);

          return acc;
        },
        { noteCount: 0, rowCount: 0 } as TotalRowCount,
      ),
    [eventNotes, eventId, getTooltipText, getTextWidth],
  );

  const maxHeight = React.useMemo(() => {
    if (!totalRowCount.rowCount) {
      return 0;
    }
    return (
      R.min(totalRowCount.rowCount, 30) * 15 +
      (totalRowCount.noteCount - 1) * 7.5
    );
  }, [totalRowCount]);

  return (
    <Tooltip
      title={
        <EventNoteTooltip.Container className="tooltipWrapper">
          <EventNoteTooltip.TextWrapper maxHeight={maxHeight}>
            {eventNotes[eventId]
              ? tooltipEventNotes.map((note: any) => (
                  <EventNoteTooltip.ItemContainer key={note.id + note.eventId}>
                    <EventNoteTooltip.ItemHeader>
                      {getUserDisplayName(users, note) +
                        timeFormat(' on %_m/%e/%y at %_I:%M %p')(
                          note.noteTimestamp,
                        )}
                    </EventNoteTooltip.ItemHeader>
                    <EventNoteTooltip.ItemContent rowCount={getRowCount(note)}>
                      {getTooltipText(note)}
                    </EventNoteTooltip.ItemContent>
                  </EventNoteTooltip.ItemContainer>
                ))
              : null}
          </EventNoteTooltip.TextWrapper>
          {totalRowCount.rowCount > 20 ? (
            <EventNoteTooltip.MoreLabel>
              <span>...</span>
            </EventNoteTooltip.MoreLabel>
          ) : null}
        </EventNoteTooltip.Container>
      }
      placement="bottom-start"
      enterDelay={0}
    >
      <div>
        {R.path([eventId], eventNotes) &&
        !R.isEmpty(R.path([eventId], eventNotes)) ? (
          <EventNoteTooltip.CommentIconWrapper>
            <CommentIcon />
            <EventNoteTooltip.NoteCount>
              {R.values(eventNotes[eventId]).length}
            </EventNoteTooltip.NoteCount>
          </EventNoteTooltip.CommentIconWrapper>
        ) : null}
      </div>
    </Tooltip>
  );
};

EventNoteTooltip.Container = styled.div`
  white-space: pre-line;
  max-width: 290px;
`;

EventNoteTooltip.TextWrapper = styled.div`
  white-space: pre-line;
  max-width: 290px;
  max-height: ${props => props.maxHeight}px;
  overflow: hidden;
`;

EventNoteTooltip.MoreLabel = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.5em;
  height: 15px;
`;

EventNoteTooltip.ItemContainer = styled.div`
  margin-bottom: 7.5px;
`;

EventNoteTooltip.ItemHeader = styled.h1`
  font-weight: bold !important;
  font-size: 12px !important;
  height: 15px;
`;

EventNoteTooltip.NoteCount = styled.span`
  margin-left: 2px;
  font-size: 12px;
`;

EventNoteTooltip.ItemContent = styled.p`
  height: ${props => props.rowCount * 15}px;
  overflow-y: hidden;
`;

EventNoteTooltip.CommentIconWrapper = styled.div`
  margin-left: 3px;
`;

export default EventNoteTooltip;
