import { timeFormat } from 'd3-time-format';
import { utcDay } from 'd3-time';
import streamSaver from 'streamsaver';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import { FaDownload } from 'react-icons/fa';

import type { Note } from 'modules/notes/models/notes';
import type { User } from 'modules/user/models/user';
import { MENTION_REG_EXP } from 'modules/mentionNotification/models/mentionNotification';

import Button from 'components/Button';
import { EditIcon, DeleteIcon } from 'components/Icons';
import { linkify } from 'helpers';

const LFCR_REG_EXP = /(\n|\r|\n\r)/g;

const highlightMentions = (
  noteText: string,
  usersByEmailName: { [name: string]: User },
) => {
  const emailNames = R.keys(usersByEmailName);
  const withMentions = noteText.replace(MENTION_REG_EXP, match => {
    const emailName = match.substring(1);
    if (emailNames.includes(emailName)) {
      return `<span style="color:#35995b">${match}</span>`;
    }

    return match;
  });
  const withBreaks = withMentions.replace(LFCR_REG_EXP, '<br/>');

  return { __html: withBreaks };
};

interface NoteSectionProps {
  note: Note;
  authorUser: User;
  currentUserId: string;
  isInProgress: boolean;
  openEditingDialog: (dialogData: {
    noteId: string;
    productionDay: Date;
  }) => void;
  removeNote: (noteId: string, productionDay: Date) => void;
  productionDayString: string;
  usersByEmailName: { [name: string]: User };
}

const NoteSection = ({
  note,
  authorUser,
  currentUserId,
  isInProgress,
  openEditingDialog,
  removeNote,
  productionDayString,
  usersByEmailName,
}: NoteSectionProps) => {
  const onAttachmentClick = React.useCallback(attachment => {
    const url = attachment.url;
    const fileStream = streamSaver.createWriteStream(attachment.name);
    fetch(url).then(res => {
      const readableStream = res.body;
      if (!readableStream) return;
      const writer = fileStream.getWriter();
      const reader = readableStream.getReader();
      const pump = () =>
        reader
          .read()
          .then(res =>
            res.done ? writer.close() : writer.write(res.value).then(pump),
          );
      pump();
    });
  }, []);
  const newText = linkify(note.noteText);

  return (
    <NoteSection.Note key={note.id} data-day={productionDayString}>
      {isInProgress || !authorUser ? (
        <NoteSection.ProgressWrapper>
          <CircularProgress size={24} thickness={4} />
        </NoteSection.ProgressWrapper>
      ) : (
        <>
          <NoteSection.FirstRow>
            <NoteSection.NoteAuthor>
              {authorUser.firstName + ' ' + authorUser.lastName}
            </NoteSection.NoteAuthor>
            <NoteSection.NoteInfo>
              {utcDay.floor(note.timeStamp).getTime() ===
              utcDay.floor(note.productionDate).getTime()
                ? ''
                : ' ' + timeFormat('%x')(note.timeStamp)}
              {' ' + timeFormat('%I:%M %p')(note.timeStamp)}
              {note.isEdited ? ' (Edited)' : ''}
            </NoteSection.NoteInfo>
          </NoteSection.FirstRow>
          {note.userId === currentUserId && (
            <NoteSection.NoteControls>
              <Button
                transparent
                width={30}
                onClick={() =>
                  openEditingDialog({
                    noteId: note.id,
                    productionDay: note.productionDate,
                  })
                }
              >
                <EditIcon />
              </Button>
              <Button
                transparent
                width={30}
                onClick={() => removeNote(note.id, note.productionDate)}
              >
                <DeleteIcon />
              </Button>
            </NoteSection.NoteControls>
          )}
          <NoteSection.NoteText
            dangerouslySetInnerHTML={highlightMentions(
              newText,
              usersByEmailName,
            )}
          />
          {!R.isEmpty(note.attachments) &&
            note.attachments.map(attachment => (
              <NoteSection.Attachment
                key={attachment.id}
                onClick={() => onAttachmentClick(attachment)}
              >
                {attachment.name}
                <FaDownload />
              </NoteSection.Attachment>
            ))}
        </>
      )}
    </NoteSection.Note>
  );
};

NoteSection.Note = styled.div`
  padding: 12px 10px;
  position: relative;

  &:not(:last-child) {
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);
  }
`;

NoteSection.FirstRow = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

NoteSection.NoteAuthor = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: ${(props: Record<string, any>) => props.theme.colors.darkText};
  line-height: 21px;
`;

NoteSection.NoteControls = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  right: 0;
`;

NoteSection.NoteInfo = styled.div`
  margin-left: 7px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  opacity: 0.5;
  line-height: 21px;
`;

NoteSection.NoteText = styled.div`
  background: white;
  font-size: 15px;
  line-height: 1.5em;
  word-wrap: break-word;
  & > a {
    color: #1890ff !important;
    text-decoration: auto;
  }
`;

NoteSection.File = styled.div`
  border: 1px solid #d5d5d5;
  border-radius: 15px;
  height: 30px;
  padding: 0 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 250px;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
`;

NoteSection.FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

NoteSection.ProgressWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

NoteSection.Attachment = styled.div`
  border: 1px solid #d5d5d5;
  border-radius: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 7px 13px;
  cursor: pointer;

  &:hover {
    background-color: ${(props: Record<string, any>) =>
      props.theme.colors.lightgrey};
  }
`;

export default NoteSection;
