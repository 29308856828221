import { utcFormat } from 'd3-time-format';
import * as React from 'react';
import styled from 'styled-components';

import type { RibbonTooltipData } from 'modules/ui/models/ui';

interface InformatioinTooltipProps {
  tooltipData: RibbonTooltipData;
}

const RibbonInformation = ({ tooltipData }: InformatioinTooltipProps) => {
  const { dayStart, dayEnd, ribbonType, optionType } = tooltipData;
  return (
    <>
      {dayStart && (
        <RibbonInformation.Date>
          {utcFormat('%m/%d/%Y')(dayStart)} -{' '}
          {utcFormat('%m/%d/%Y')(dayEnd as Date)}
        </RibbonInformation.Date>
      )}
      <RibbonInformation.Ribbon>
        <span>{'Ribbon: '}</span>
        <RibbonInformation.Value>{ribbonType}</RibbonInformation.Value>
      </RibbonInformation.Ribbon>
      {optionType && (
        <RibbonInformation.Category>
          <span>{'Event: '}</span>

          <RibbonInformation.Value>{optionType}</RibbonInformation.Value>
        </RibbonInformation.Category>
      )}
    </>
  );
};

RibbonInformation.Date = styled.span`
  grid-area: 5 / 1 / 5 / 3;
  text-align: center;
`;

RibbonInformation.Value = styled.span`
  text-align: right;
`;

RibbonInformation.Category = styled.span`
  grid-area: 4 / 1 / 5 / 3;
  text-align: center;
`;

RibbonInformation.Ribbon = styled.span`
  grid-area: 1 / 1 / 2 / 3;
  text-align: center;
`;
export default RibbonInformation;
