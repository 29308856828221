import * as R from 'ramda';
import { utcDay } from 'd3-time';

import countBoeValue from './countBoeValue';
import countGorValue from './countGorValue';
import countGlrValue from './countGlrValue';
import countWatercutValue from './countWatercutValue';

import type { ProductionPoint } from '../models/production';
import countTotalLiquidValue from './countTotalLiquidValue';

const normalizeProduction = ({
  rawProduction,
  readyProduction,
}: {
  rawProduction: any;
  readyProduction: ProductionPoint[];
}): ProductionPoint[] => {
  const newProductionNormalized: ProductionPoint[] = rawProduction.map(
    rawProductionPoint => ({
      ...rawProductionPoint,
      day: utcDay.round(new Date(rawProductionPoint.day)),
      boe: countBoeValue(rawProductionPoint.oil, rawProductionPoint.gas),
      gor: countGorValue(rawProductionPoint.oil, rawProductionPoint.gas),
      watercut: countWatercutValue(
        rawProductionPoint.oil,
        rawProductionPoint.water,
      ),
      total_liquid: countTotalLiquidValue(
        rawProductionPoint.oil,
        rawProductionPoint.water,
      ),
      glr: countGlrValue(
        rawProductionPoint.oil,
        rawProductionPoint.gas,
        rawProductionPoint.water,
      ),
    }),
  );
  const wholeProduction = readyProduction
    ? newProductionNormalized.concat(readyProduction)
    : newProductionNormalized;
  const productionWithoutRepeats = R.dropRepeats(wholeProduction);
  const sortedProduction: ProductionPoint[] = productionWithoutRepeats.sort(
    (a, b) => a.day.getTime() - b.day.getTime(),
  );

  return sortedProduction;
};

export default normalizeProduction;
