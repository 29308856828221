import { utcMinute } from 'd3-time';
import {
  NormalizedSensorSeriesMapping,
  NormalizedSeries,
  SensorSeries,
} from '../models';

export const normalizeSensorSeries = (
  data: SensorSeries[],
  wellId: string,
  seriesMapping: NormalizedSensorSeriesMapping,
): NormalizedSeries[] => {
  return data.reduce<NormalizedSeries[]>((acc, d) => {
    const mapping = Object.values(seriesMapping).find(m =>
      m.tags.some(t => t.tagId === d.tag && t.wellId === wellId),
    );

    if (!mapping) return acc;

    //@ts-ignore
    acc.push({
      day: utcMinute.round(new Date(d.recordedAt)),
      [mapping.sensorSeriesId]: d.value,
    });

    return acc;
  }, []);
};
