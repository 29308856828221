import { area, curveStepAfter } from 'd3-shape';
import { utcDay } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';

import { NormalizedSeries, SeriesMapping } from '../models';

interface SeriesBarsProps {
  data: NormalizedSeries[];
  currentSeriesMapping: SeriesMapping;
  customColor: string | undefined;
  xScale: any;
  yScale: any;
}

const SeriesBars = ({
  data,
  currentSeriesMapping,
  customColor,
  xScale,
  yScale,
}: SeriesBarsProps) => {
  const defaultDataKey = `series${currentSeriesMapping.seriesIndex}`;
  const lastRealDate = R.pathOr(new Date(), [data.length - 1, 'day'], data);
  const lastDayData = R.pathOr({}, [data.length - 1], data);
  const newDate = utcDay.offset(lastRealDate, 1);
  const dataWithEmptyLastDay = React.useMemo(() => {
    if (!currentSeriesMapping.id.startsWith('s'))
      return [...data, { ...lastDayData, day: newDate }];

    return data;
  }, [currentSeriesMapping, data]);

  const roundTime = React.useCallback(
    (date: Date) => {
      if (!currentSeriesMapping.id.startsWith('s')) return utcDay(date);

      return date;
    },
    [xScale, currentSeriesMapping],
  );

  const createArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(roundTime(new Date(d.day)) || 0))
        .y1(d => yScale(d[defaultDataKey] || d[currentSeriesMapping.id] || 0))
        .y0(yScale(0))
        .curve(curveStepAfter),
    [xScale, yScale, defaultDataKey],
  );
  return (
    <path
      className="panInteraction"
      fill={customColor || currentSeriesMapping.color}
      d={createArea(dataWithEmptyLastDay)}
    />
  );
};

export default React.memo<SeriesBarsProps>(SeriesBars);
