import { utcDay, timeDay, timeMinute } from 'd3-time';
import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import type { Selector } from 'store/models';

import {
  NORMALIZE_APP_CONFIG,
  SET_APP_CONFIG,
  SET_APP_CONFIG_LOCALLY,
} from './AppConfigActions';
import { Action } from 'store/models';
import {
  LOCAL_APP_SETTINGS,
  LOCAL_APP_SETTINGS_KEYS,
} from './models/appConfig';
import { AppSettingType } from 'modules/appSettings/model';

export const STATE_KEY = 'appConfig';

export type AppConfigState = { [key: string]: any };

const calculateToday = (dbToday: string, todayOffset: number) => {
  if (dbToday && !todayOffset) {
    return utcDay.round(new Date(dbToday));
  } else if (dbToday && todayOffset) {
    return utcDay.offset(utcDay.round(new Date(dbToday)), todayOffset);
  }
  const today = utcDay.floor(
    timeMinute.offset(
      timeDay.floor(new Date()),
      -new Date().getTimezoneOffset(),
    ),
  );

  if (!R.isNil(todayOffset) && todayOffset !== 0) {
    return utcDay.offset(today, todayOffset);
  }
  return today;
};

const initialState: AppConfigState = {
  drilldownDefaultFromDate: utcDay.round(
    new Date(new Date().getFullYear(), 0, 1),
  ),
  drilldownDefaultToDate: utcDay.round(
    new Date(new Date().getFullYear(), 0, 31),
  ),
  today: utcDay.floor(
    timeMinute.offset(
      timeDay.floor(new Date()),
      -new Date().getTimezoneOffset(),
    ),
  ),
  groupBy: false,
  conversationsPolling: true,
  varianceTrellis: false,
  minSummedCapacityDateSetting: new Date(0),
  default_minSummedCapacityDateSetting: new Date(0),
  drilldownMarking: false,
  sensorSeries: false,
};

const AppConfigReducer = (
  state: AppConfigState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case NORMALIZE_APP_CONFIG: {
      const config = action.payload;
      const newConfig = {
        ...config,
        spotfireDashboards: config.spotfireDashboards,
        drilldownDefaultFromDate: utcDay.round(
          new Date(config.drilldownDefaultFromDate),
        ),
        drilldownDefaultToDate: utcDay.round(
          new Date(config.drilldownDefaultToDate),
        ),
        ...LOCAL_APP_SETTINGS.reduce((acc, setting) => {
          const value =
            config[setting.key] === undefined || config[setting.key] === null
              ? setting.default
              : setting.convert(config[setting.key]);
          acc[setting.key] = value;
          acc['default_' + setting.key] = value;
          return acc;
        }, {}),
        today: calculateToday(config.today, config.todayOffset),
        groupBy: config.groupBy,
        varianceTrellis: config.varianceTrellis,
        timeSeries: config.timeSeries,
        showExternalForecast: config.showExternalForecast,
        capacityVsExternal: config.capacityVsExternal,
        allocationIssues: config.allocationIssues,
        drilldownMarking: config.drilldownMarking,
        sensorSeries: config.sensorSeries,
        varianceDrilldownMarkingFilter: config.varianceDrilldownMarkingFilter,
      };
      return R.merge(state, newConfig);
    }
    case SET_APP_CONFIG_LOCALLY: {
      const payload = action.payload;

      return R.merge(state, payload);
    }
    case SET_APP_CONFIG: {
      const payload = action.payload.graphql.variables.data;

      return R.merge(state, payload);
    }
    default: {
      return state;
    }
  }
};

export const getAppConfig = (state: any): AppConfigState =>
  R.propOr({}, STATE_KEY, state);

export const getLocalAppSettings: Selector<{ [key: string]: any }> =
  createSelector(getAppConfig, appConfig =>
    R.pick(LOCAL_APP_SETTINGS_KEYS, appConfig),
  );

export const getFormattedLocalAppSettings: Selector<AppSettingType<any>[]> =
  createSelector(getAppConfig, appConfig =>
    LOCAL_APP_SETTINGS.map(setting => ({
      ...R.pick(['name', 'key'], setting),
      current: appConfig[setting.key],
      default: appConfig['default_' + setting.key],
    })),
  );

export const getToday = (state: any): Date =>
  R.pathOr(initialState.today, [STATE_KEY, 'today'], state);

export const getFeatures: Selector<any> = createSelector(
  getAppConfig,
  appConfig =>
    R.omit(
      [
        'drilldownDefaultFromDate',
        'drilldownDefaultToDate',
        'minSummedCapacityDateSetting',
        'today',
        '_persist',
      ],
      appConfig,
    ),
);

export default filterActions(AppConfigReducer as any, [
  SET_APP_CONFIG,
  SET_APP_CONFIG_LOCALLY,
  NORMALIZE_APP_CONFIG,
]);
