import { NormalizedSensorSeriesMappingItem } from 'modules/series/models';
import { ChartOption } from '../models';

const checkSensorSeriesExists = (
  option: ChartOption,
  seriesMapping: NormalizedSensorSeriesMappingItem,
  wellId: string,
) => {
  const sensorSerie = seriesMapping[option.id];
  if (!sensorSerie) return false;

  return sensorSerie.tags.some(t => t.wellId === wellId);
};

export default checkSensorSeriesExists;
