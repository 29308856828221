import { utcDay } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import { getVarianceExtremeDates } from 'helpers';
import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import { createNormalYScale } from 'modules/chart/utils';
import type { ProductionPoint } from 'modules/production/models/production';
import type { IdIndexDialog } from 'modules/ui/models/ui';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import {
  getRateHandleClipPathId,
  getDividerExtraPositionsFromPoints,
} from '../utils';

import AllocIssueDivider from './allocIssue/AllocIssueDivider';
import CapacityDivider from './capacityEvent/CapacityDivider';
import InteractiveCapacityLine from './capacityEvent/InteractiveCapacityLine';
import EventClickableArea from './varianceEvent/EventClickableArea';
import EventDivider from './varianceEvent/EventDivider';

interface SVGPhaseTrellisInteractionProps {
  allocIssueDialog: IdIndexDialog;
  allocIssues: AllocIssue[];
  allocIssuesVisibility: boolean;
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    grossNet: string;
  };
  eventCapacityData: { date: Date; capacity: number }[][];
  height: number;
  width: number;
  capacity: CapacityChangeEvent[];
  capacityDialog: { show: boolean; index: number };
  varianceEvents: VarianceEvent[];
  trellisTitle: string;
  xScale: any;
  startDrag: () => void;
  finishDrag: () => void;
  isAxisDragging: boolean;
  isDragging: boolean;
  isPossibleEditCapacity: boolean;
  isPossibleEditVariance: boolean;
  isPossibleEditAlloc: boolean;
  leftOffset: number;
  maxDataPoint: number;
  nri: number;
  onCapacityDialogOpen: (index: number, eventId: string) => void;
  onDayInitChange: (dayData: {
    capacityEventId: string;
    newDayInit: Date;
  }) => void;
  onCapacityDividerHover: (eventId: number) => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  onHighlightCapacityDividerOff: () => void;
  capacityLineData: Array<{ date: Date; capacity: number }[]>;
  onAllocIssueUpdate: ({ updatedIssue: AllocIssue, data: Object }) => void;
  onAllocIssueDialogOpen: ({ index: number, id: string }) => void;
  onBFactorDrag: (bFactorData: {
    phase: string;
    capacityEventId: string;
    rate: number;
    date: Date;
  }) => void;
  onDeclineInitChange: (declineData: {
    phase: string;
    capacityEventId: string;
    date: Date;
    rate: number;
  }) => void;
  onRateInitChange: (rateData: {
    phase: string;
    capacityEventId: string;
    newRate: number;
  }) => void;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  onEventDividerHover: (eventId: number) => void;
  onHighlightEventDividerOff: () => void;
  onLocalAllocIssueUpdate: (allocData: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  onVarianceEventUpdate: (varianceData: {
    dates: Date[];
    varianceEventId: string;
  }) => void;
  onAllocIssueDividerHover: (eventId: string) => void;
  onHighlightAllocIssueDividerOff: () => void;
  production: ProductionPoint[];
  today: Date;
  varianceDialog: { show: boolean; index: number };
}

const SVGPhaseTrellisInteraction = ({
  allocIssueDialog,
  allocIssues,
  allocIssuesVisibility,
  eventCapacityData,
  height,
  width,
  capacity,
  capacityDialog,
  drilldownTableParams,
  varianceEvents,
  trellisTitle,
  xScale,
  startDrag,
  finishDrag,
  isAxisDragging,
  isDragging,
  isPossibleEditCapacity,
  isPossibleEditVariance,
  isPossibleEditAlloc,
  maxDataPoint,
  nri,
  leftOffset,
  onAllocIssueUpdate,
  onAllocIssueDialogOpen,
  onCapacityDialogOpen,
  onDayInitChange,
  onCapacityDividerHover,
  onXAxisScaling,
  onHighlightCapacityDividerOff,
  capacityLineData,
  onBFactorDrag,
  onDeclineInitChange,
  onRateInitChange,
  onVarianceDialogOpen,
  onEventDividerHover,
  onHighlightEventDividerOff,
  onVarianceEventUpdate,
  onLocalAllocIssueUpdate,
  onAllocIssueDividerHover,
  onHighlightAllocIssueDividerOff,
  production,
  today,
  varianceDialog,
}: SVGPhaseTrellisInteractionProps) => {
  const svgEl = React.useRef<SVGSVGElement>(null);
  const [draggedMaxDataPoint, setDraggedMaxDataPoint] =
    React.useState(maxDataPoint);
  const yScale = React.useMemo(
    () => createNormalYScale(height, draggedMaxDataPoint),
    [draggedMaxDataPoint, height],
  );

  const noOpenDialog = React.useMemo(
    () =>
      !capacityDialog.show && !varianceDialog.show && !allocIssueDialog.show,
    [capacityDialog.show, varianceDialog.show, allocIssueDialog.show],
  );

  const onWheelHandler = React.useCallback(
    e => {
      onXAxisScaling(e, svgEl);
    },
    [svgEl, onXAxisScaling],
  );

  React.useEffect(() => {
    if (isDragging || maxDataPoint === draggedMaxDataPoint) return;
    setDraggedMaxDataPoint(maxDataPoint);
  }, [isDragging, maxDataPoint, draggedMaxDataPoint, setDraggedMaxDataPoint]);

  return (
    <svg
      ref={svgEl}
      height="100%"
      width={width}
      preserveAspectRatio="none"
      viewBox={`0 0 ${width} ${height}`}
      className="panInteraction"
      onWheel={onWheelHandler}
    >
      <defs>
        {capacity.map(capacityPoint => (
          <clipPath
            id={getRateHandleClipPathId(trellisTitle, capacityPoint.id)}
            key={getRateHandleClipPathId(trellisTitle, capacityPoint.id)}
          >
            <polygon
              fill="transparent"
              points={`${xScale(capacityPoint.dayInit) - 5},0 ${
                xScale(capacityPoint.dayInit) + 50
              },0 ${xScale(capacityPoint.dayInit) + 50},${height} ${
                xScale(capacityPoint.dayInit) - 5
              },${height}`}
            />
          </clipPath>
        ))}
      </defs>
      {(noOpenDialog || (capacityDialog.show && !isPossibleEditCapacity)) &&
        capacity.map((capacityEvent, i) => {
          const dataset = capacityLineData[i];

          return (
            !R.isEmpty(dataset) &&
            capacityEvent.id && (
              <InteractiveCapacityLine
                capacityDialog={capacityDialog}
                capacityEvent={capacityEvent}
                capacitySegment={dataset}
                finishDrag={finishDrag}
                grossNet={drilldownTableParams.grossNet}
                isEditable={false}
                key={`capacityLine_${capacityEvent.id}`}
                nri={nri}
                onBFactorDrag={onBFactorDrag}
                onCapacityDialogOpen={onCapacityDialogOpen}
                onDeclineInitChange={onDeclineInitChange}
                onRateInitChange={onRateInitChange}
                pointIndex={i}
                startDrag={startDrag}
                trellisTitle={trellisTitle}
                xScale={xScale}
                yScale={yScale}
                svgSizes={
                  svgEl.current ? svgEl.current.getBoundingClientRect() : null
                }
              />
            )
          );
        })}
      {noOpenDialog &&
        allocIssuesVisibility &&
        allocIssues.map((issue, i) => {
          const startPos = xScale(issue.dateStart);
          const endPos = xScale(utcDay.offset(issue.dateEnd, 1));

          return (
            <polygon
              key={issue.id}
              points={`${startPos},0 ${endPos},0 ${endPos},${height} ${startPos},${height}`}
              onMouseUp={() =>
                onAllocIssueDialogOpen({ index: i, id: issue.id })
              }
              fill="transparent"
              cursor={isAxisDragging ? 'grabbing' : 'pointer'}
              className="alloc-interactive interactive panInteraction"
            />
          );
        })}

      {noOpenDialog &&
        capacity.map(
          (capacityEvent, i) =>
            capacityEvent.id && (
              <CapacityDivider
                key={`capacityDivider_${capacityEvent.id}`}
                capacityDialog={capacityDialog}
                capacityEvent={capacityEvent}
                extraPositions={getDividerExtraPositionsFromPoints(
                  capacity,
                  i,
                  xScale,
                )}
                finishDrag={finishDrag}
                height={height}
                isEditable={false}
                isDragging={isDragging}
                leftOffset={leftOffset}
                onCapacityDialogOpen={onCapacityDialogOpen}
                onDayInitChange={onDayInitChange}
                onDividerHover={onCapacityDividerHover}
                onHighlightCapacityDividerOff={onHighlightCapacityDividerOff}
                pointIndex={i}
                startDrag={startDrag}
                xScale={xScale}
              />
            ),
        )}

      {isPossibleEditCapacity && capacityDialog.show && (
        <CapacityDivider
          key={`capacityDivider_${capacity[capacityDialog.index].id}`}
          capacityDialog={capacityDialog}
          capacityEvent={capacity[capacityDialog.index]}
          extraPositions={getDividerExtraPositionsFromPoints(
            capacity,
            capacityDialog.index,
            xScale,
          )}
          finishDrag={finishDrag}
          height={height}
          isEditable={true}
          isDragging={isDragging}
          leftOffset={leftOffset}
          onCapacityDialogOpen={onCapacityDialogOpen}
          onDayInitChange={onDayInitChange}
          onDividerHover={onCapacityDividerHover}
          onHighlightCapacityDividerOff={onHighlightCapacityDividerOff}
          pointIndex={capacityDialog.index}
          startDrag={startDrag}
          xScale={xScale}
        />
      )}
      {noOpenDialog &&
        varianceEvents.map((varianceEvent, i) => {
          return (
            <EventDivider
              key={`eventDivider_${varianceEvent.id}`}
              varianceEvent={varianceEvent}
              varianceDialog={varianceDialog}
              eventIndex={i}
              extremeEventDates={getVarianceExtremeDates(
                allocIssues,
                varianceEvents,
                varianceEvent.id,
                today,
                'Variance Event',
              )}
              finishDrag={finishDrag}
              height={height}
              isAxisDragging={isAxisDragging}
              isEditable={false}
              isDragging={isDragging}
              leftOffset={leftOffset}
              onVarianceDialogOpen={onVarianceDialogOpen}
              onDividerHover={onEventDividerHover}
              onHighlightEventDividerOff={onHighlightEventDividerOff}
              onVarianceEventUpdate={onVarianceEventUpdate}
              startDrag={startDrag}
              xScale={xScale}
            />
          );
        })}

      {varianceDialog.show &&
        isPossibleEditVariance &&
        varianceEvents[varianceDialog.index] && (
          <>
            <EventClickableArea
              key={varianceEvents[varianceDialog.index].id}
              eventProductionData={production}
              eventCapacityData={eventCapacityData[varianceDialog.index]}
              eventIndex={varianceDialog.index}
              isAxisDragging={isAxisDragging}
              onVarianceDialogOpen={onVarianceDialogOpen}
              productionKey={trellisTitle.toLowerCase()}
              title={trellisTitle}
              varianceEvent={varianceEvents[varianceDialog.index]}
              xScale={xScale}
              yScale={yScale}
            />
            <EventDivider
              key={`eventDivider_${varianceEvents[varianceDialog.index].id}`}
              varianceEvent={varianceEvents[varianceDialog.index]}
              varianceDialog={varianceDialog}
              eventIndex={varianceDialog.index}
              extremeEventDates={getVarianceExtremeDates(
                allocIssues,
                varianceEvents,
                varianceEvents[varianceDialog.index].id,
                today,
                'Variance Event',
              )}
              finishDrag={finishDrag}
              height={height}
              isAxisDragging={isAxisDragging}
              isEditable={true}
              isDragging={isDragging}
              leftOffset={leftOffset}
              onVarianceDialogOpen={onVarianceDialogOpen}
              onDividerHover={onEventDividerHover}
              onHighlightEventDividerOff={onHighlightEventDividerOff}
              onVarianceEventUpdate={onVarianceEventUpdate}
              startDrag={startDrag}
              xScale={xScale}
            />
          </>
        )}

      {noOpenDialog &&
        varianceEvents.map((varianceEvent, i) => {
          return (
            <EventClickableArea
              key={varianceEvent.id}
              eventProductionData={production}
              eventCapacityData={eventCapacityData[i]}
              eventIndex={i}
              isAxisDragging={isAxisDragging}
              onVarianceDialogOpen={onVarianceDialogOpen}
              productionKey={trellisTitle.toLowerCase()}
              title={trellisTitle}
              varianceEvent={varianceEvent}
              xScale={xScale}
              yScale={yScale}
            />
          );
        })}

      {isPossibleEditCapacity && capacityDialog.show && (
        <InteractiveCapacityLine
          capacityDialog={capacityDialog}
          capacityEvent={capacity[capacityDialog.index]}
          capacitySegment={capacityLineData[capacityDialog.index]}
          finishDrag={finishDrag}
          grossNet={drilldownTableParams.grossNet}
          isEditable={true}
          key={`capacityLine_${capacity[capacityDialog.index].id}`}
          nri={nri}
          onBFactorDrag={onBFactorDrag}
          onCapacityDialogOpen={onCapacityDialogOpen}
          onDeclineInitChange={onDeclineInitChange}
          onRateInitChange={onRateInitChange}
          pointIndex={capacityDialog.index}
          startDrag={startDrag}
          trellisTitle={trellisTitle}
          xScale={xScale}
          yScale={yScale}
          svgSizes={
            svgEl.current ? svgEl.current.getBoundingClientRect() : null
          }
        />
      )}

      {noOpenDialog &&
        allocIssuesVisibility &&
        allocIssues.map((issue, i) => (
          <AllocIssueDivider
            key={`allocIssueDivider_${issue.id}`}
            allocIssue={issue}
            isAxisDragging={isAxisDragging}
            issueIndex={i}
            extremeAllocDates={getVarianceExtremeDates(
              allocIssues,
              varianceEvents,
              issue.id,
              today,
              'Allocation Issue',
            )}
            finishDrag={finishDrag}
            height={height}
            isDragging={isDragging}
            isEditable={false}
            leftOffset={leftOffset}
            onAllocIssueUpdate={onAllocIssueUpdate}
            onLocalAllocIssueUpdate={onLocalAllocIssueUpdate}
            onAllocIssueDividerHover={onAllocIssueDividerHover}
            onAllocIssueDialogOpen={onAllocIssueDialogOpen}
            onHighlightAllocIssueDividerOff={onHighlightAllocIssueDividerOff}
            startDrag={startDrag}
            xScale={xScale}
          />
        ))}

      {allocIssueDialog.show &&
        allocIssuesVisibility &&
        isPossibleEditAlloc &&
        allocIssues[allocIssueDialog.index] && (
          <>
            <AllocIssueDivider
              allocIssue={allocIssues[allocIssueDialog.index]}
              issueIndex={allocIssueDialog.index}
              extremeAllocDates={getVarianceExtremeDates(
                allocIssues,
                varianceEvents,
                allocIssues[allocIssueDialog.index].id,
                today,
                'Allocation Issue',
              )}
              finishDrag={finishDrag}
              height={height}
              isDragging={isDragging}
              isAxisDragging={isAxisDragging}
              isEditable={true}
              leftOffset={leftOffset}
              onAllocIssueUpdate={onAllocIssueUpdate}
              onLocalAllocIssueUpdate={onLocalAllocIssueUpdate}
              onAllocIssueDividerHover={onAllocIssueDividerHover}
              onAllocIssueDialogOpen={onAllocIssueDialogOpen}
              onHighlightAllocIssueDividerOff={onHighlightAllocIssueDividerOff}
              startDrag={startDrag}
              xScale={xScale}
            />
            <polygon
              points={`${xScale(
                allocIssues[allocIssueDialog.index].dateStart,
              )},0 ${xScale(
                utcDay.offset(allocIssues[allocIssueDialog.index].dateEnd, 1),
              )},0 ${xScale(
                utcDay.offset(allocIssues[allocIssueDialog.index].dateEnd, 1),
              )},${height} ${xScale(
                allocIssues[allocIssueDialog.index].dateStart,
              )},${height}`}
              onMouseUp={() =>
                onAllocIssueDialogOpen({
                  index: allocIssueDialog.index,
                  id: allocIssues[allocIssueDialog.index].id,
                })
              }
              fill="transparent"
              cursor={isAxisDragging ? 'grabbing' : 'pointer'}
              className="alloc-interactive interactive panInteraction"
            />
          </>
        )}
    </svg>
  );
};

export default SVGPhaseTrellisInteraction;
