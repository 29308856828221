export const TOKEN_EXPIRED = 'Token has expired';
export const USER_IS_INACTIVE = 'User is inactive';
export const USER_SHOULD_CHANE_PASSWORD = 'Must change password';
export const USER_NOT_AUTHENTICATED = 'Not Authenticated!';
export const CUSTOMER_IS_NOT_VERIFIED = 'Customer is not verified!';

declare global {
  interface Window {
    heap: any;
  }
}

export type AuthConfig = {
  authScheme: 'internal' | 'sso';
  authConfig: {
    provider: string;
  };
  internalAuthConfig: {
    provider: string;
  };
  cognitoAuthDomain: string;
  cognitoGlobalUrl: string;
  cognitoClientId: string;
  redirectUri: string;
};
