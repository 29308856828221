import * as R from 'ramda';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { Action } from 'store/models';
import { NORMALIZE_APP_CONFIG } from 'modules/appConfig/AppConfigActions';
import { getId } from 'modules/auth/AuthReducer';
import { changeFilterView } from 'modules/ui/UIActions';
import { getIsFilterPageTableView } from 'modules/ui/UIReducer';

import {
  CHANGE_OPTIONS_ORDER,
  CHANGE_OPTION_VISIBLE,
  REMOVE_FILTER_OPTION,
  SELECT_FILTER_OPTION,
  setCurrentFiltersConfiguration,
  setSelectedFilterOptions,
  SET_FILTER_OPTIONS,
  SET_SELECTED_FILTER_OPTIONS,
  fetchFiltersLayouts,
  SET_CURRENT_FILTERS_LAYOUT,
  setFilterOptions,
  REORDER_FILTERS_LAYOUTS_LOCALY,
  setFiltersLayoutsLocally,
  setCurrentFiltersLayout,
  FETCH_FILTERS_LAYOUTS,
  CHANGE_OPTIONS_COLUMNS_ORDER,
  fetchWellCustomAttributes,
  setCustomFilterOptions,
  SET_CUSTOM_FILTER_OPTIONS,
  FETCH_WELL_CUSTOM_ATTRIBUTES,
  fetchWellCustomAttributesValues,
  REMOVE_WELL_CUSTOM_ATTRIBUTE,
  clearSelectedFilterOptions,
} from './FilterLayoutsActions';
import {
  getBaseLayouts,
  getCurrentLayout,
  getCustomOptions,
  getCustomOptionsList,
  getFiltersLayouts,
  getFiltersLayoutsOptionsArray,
  getFiltersOptions,
  getSelectedOptions,
  getUserLayouts,
} from './FilterLayoutsReducer';
import { getGraphqlPayload } from 'store/helpers';
import { FETCH_WELL_COLUMN_MAPPING } from 'modules/well/WellActions';

function* setCurrentConfiguration(action: Action): Generator<any, any, any> {
  const selectedOptions = yield select(getSelectedOptions);
  const filtersOptions = yield select(getFiltersOptions);
  const customOptions = yield select(getCustomOptions);
  const configuration = selectedOptions.reduce((acc: string, id: string) => {
    const option = id.includes('custom')
      ? customOptions[id]
      : filtersOptions[id];
    if (option && option.isShown) {
      if (acc === '') {
        return id;
      }
      return acc + '-' + id;
    }
    return acc;
  }, '');
  yield put(setCurrentFiltersConfiguration({ configuration }));
}

function* reorderFilterOptionsLayouts(
  action: Action,
): Generator<any, any, any> {
  const {
    payload: { dragId, hoverId },
  } = action;
  const originSelectedOptions = yield select(getSelectedOptions);
  const selectedOptions = R.clone(originSelectedOptions);
  const dragedIndex = selectedOptions.indexOf(dragId);
  const hoveredIndex = selectedOptions.indexOf(hoverId);
  [selectedOptions[dragedIndex], selectedOptions[hoveredIndex]] = [
    selectedOptions[hoveredIndex],
    selectedOptions[dragedIndex],
  ];
  yield put(setSelectedFilterOptions({ selectedOptions }));
}

function* fetchAllFiltersLayouts(action: Action): Generator<any, any, any> {
  const userId = yield select(getId);
  yield put(fetchFiltersLayouts(userId));
}

function* setDefaultLayout(action: Action): Generator<any, any, any> {
  const currentLayout = yield select(getCurrentLayout);
  const userLayouts = yield select(getUserLayouts);
  const baseLayouts = yield select(getBaseLayouts);

  if (!currentLayout.configuration) {
    if (!R.isEmpty(baseLayouts)) {
      yield put(setCurrentFiltersLayout(baseLayouts[0]));
      return;
    }

    if (!R.isEmpty(userLayouts)) {
      yield put(setCurrentFiltersLayout(userLayouts[0]));
      return;
    }
  }
}

function* setFiltersLayout(action: Action): Generator<any, any, any> {
  const {
    payload: { configuration },
  } = action;
  const filtersOptions = yield select(getFiltersLayoutsOptionsArray);
  const customFilterOptions = yield select(getCustomOptionsList);
  const selectedOptions = configuration.split('-');
  yield put(setSelectedFilterOptions({ selectedOptions }));
  const options = filtersOptions.concat(customFilterOptions).reduce(
    (acc, opt) => {
      const isCustom = opt.id.includes('custom');
      const isSelected = selectedOptions.includes(opt.id);
      opt.isShown = opt.isSelected = isSelected;
      acc[isCustom ? 'custom' : 'default'][opt.id] = opt;
      return acc;
    },
    { custom: {}, default: {} },
  );
  yield put(setCustomFilterOptions({ options: options.custom }));
  yield put(setFilterOptions({ options: options.default }));
}

function* setCustomAttributes(action: Action): Generator<any, any, any> {
  const options = {};
  const selectedOptions = yield select(getSelectedOptions);
  const customOptions = selectedOptions.filter(option =>
    option.includes('custom'),
  );
  const fetchedOptions = getGraphqlPayload(action);
  const mapFetchedOptions = fetchedOptions.reduce((acc, option) => {
    acc['custom' + option.id] = option;
    return acc;
  }, {});
  const isNonexistentOption = customOptions.reduce((acc, data) => {
    const isExist = mapFetchedOptions[data];
    if (!isExist) {
      return true;
    }
    return acc;
  }, false);
  if (isNonexistentOption) {
    yield put(clearSelectedFilterOptions());
  }
  fetchedOptions.forEach((o, i) => {
    const isShown = isNonexistentOption
      ? false
      : selectedOptions.includes('custom' + o.id);
    options['custom' + o.id] = {
      id: 'custom' + o.id,
      displayName: o.name,
      filterName: `custom ${o.name}`,
      order: i,
      isShown: isShown,
      isSelected: isShown,
    };
  });
  yield put(setCustomFilterOptions({ options }));
}

function* reorderFiltersLayouts(action: Action): Generator<any, any, any> {
  const {
    payload: { dragId, hoverId },
  } = action;
  const userLayouts = yield select(getFiltersLayouts);
  const dragLayout = userLayouts[dragId];
  const hoverLayout = userLayouts[hoverId];
  const layouts = R.compose(
    R.assocPath([dragId, 'order'], hoverLayout.order),
    R.assocPath([hoverId, 'order'], dragLayout.order),
  )(userLayouts);
  yield put(setFiltersLayoutsLocally(layouts));
}

function* changeColumnsOrder(action: Action): Generator<any, any, any> {
  const {
    payload: { dragIndex, dropIndex },
  } = action;
  const selectedOptions = yield select(getSelectedOptions);

  const dragedOption = selectedOptions[dragIndex];
  selectedOptions.splice(dragIndex, 1);
  if (dragIndex > dropIndex) {
    selectedOptions.splice(dropIndex, 0, dragedOption);
  } else {
    selectedOptions.splice(dropIndex, 0, dragedOption);
  }
  yield put(setSelectedFilterOptions({ selectedOptions }));
}

function* checkFilterViewSaga(action: Action): Generator<any, any, any> {
  const {
    payload: { filterLayouts },
  } = action;
  const isTableView = yield select(getIsFilterPageTableView);
  if (!filterLayouts && isTableView) {
    yield put(changeFilterView());
  }
}

function* fetchWellCustomAttributesSaga(): Generator<any, any, any> {
  yield put(fetchWellCustomAttributes());
}

function* fetchWellCustomAttributesValuesSaga(): Generator<any, any, any> {
  yield put(fetchWellCustomAttributesValues());
}

function* filtersLayoutsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      [
        SET_FILTER_OPTIONS,
        SET_CUSTOM_FILTER_OPTIONS,
        SELECT_FILTER_OPTION,
        REMOVE_FILTER_OPTION,
        CHANGE_OPTION_VISIBLE,
        SET_SELECTED_FILTER_OPTIONS,
      ],
      setCurrentConfiguration,
    ),
    takeLatest(CHANGE_OPTIONS_ORDER, reorderFilterOptionsLayouts),
    takeLatest(
      `${FETCH_WELL_COLUMN_MAPPING}_SUCCESS`,
      fetchWellCustomAttributesSaga,
    ),
    takeLatest(
      `${FETCH_WELL_COLUMN_MAPPING}_SUCCESS`,
      fetchWellCustomAttributesValuesSaga,
    ),
    takeLatest(
      [
        `${FETCH_WELL_CUSTOM_ATTRIBUTES}_SUCCESS`,
        `${REMOVE_WELL_CUSTOM_ATTRIBUTE}_SUCCESS`,
      ],
      fetchAllFiltersLayouts,
    ),
    takeLatest(`${FETCH_WELL_CUSTOM_ATTRIBUTES}_SUCCESS`, setCustomAttributes),
    takeLatest(SET_CURRENT_FILTERS_LAYOUT, setFiltersLayout),
    takeLatest(REORDER_FILTERS_LAYOUTS_LOCALY, reorderFiltersLayouts),
    takeLatest(`${FETCH_FILTERS_LAYOUTS}_SUCCESS`, setDefaultLayout),
    takeLatest(CHANGE_OPTIONS_COLUMNS_ORDER, changeColumnsOrder),
    takeLatest(NORMALIZE_APP_CONFIG, checkFilterViewSaga),
  ]);
}
export default filtersLayoutsSagas;
