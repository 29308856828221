import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import { setCurrentGroup, setCurrentWellId } from 'modules/ui/UIActions';
import { getGroupMode } from 'modules/ui/UIReducer';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSeveralMarkedRows,
  addMarkedRow,
  removeMarkedRow,
  markAllRows,
} from '../DrilldownTableActions';
import {
  getCurrentMarkedRows,
  getIsMarkingRows,
} from '../DrilldownTableReducer';

export const useDrilldownTableMarking = () => {
  const dispatch = useDispatch();
  const groupMode = useSelector(getGroupMode);
  const isMarkingRows = useSelector(getIsMarkingRows);
  const markedRows = useSelector(getCurrentMarkedRows);
  const isAllowedToMark = useSelector(getAppConfig).drilldownMarking;

  const selectRow = React.useCallback(
    (rowData: { [key: string]: string | number }) => {
      if (!groupMode.isOn)
        return dispatch(setCurrentWellId(rowData.wellId as string));

      dispatch(
        setCurrentGroup({
          subject: groupMode.subject,
          item: rowData[groupMode.subject] as string,
        }),
      );
    },
    [groupMode],
  );

  const markSeveralRows = React.useCallback(
    (rowData: { [key: string]: string | number }[]) => {
      if (!isAllowedToMark || rowData.length === 1) return;
      const filtered = rowData.filter(
        r =>
          !markedRows.includes(r[groupMode.subject || 'well']) &&
          r[groupMode.subject || 'well'] !== 'TOTAL',
      );

      if (filtered.length === 0) return;

      dispatch(
        addSeveralMarkedRows(
          filtered.map(r => r[groupMode.subject || 'well'] as string),
        ),
      );
    },
    [groupMode, markedRows, isAllowedToMark],
  );

  const markAll = React.useCallback(() => {
    if (!isAllowedToMark) return;

    dispatch(markAllRows());
  }, []);

  const markRow = React.useCallback(
    (rowData: { [key: string]: string | number }) => {
      if (
        !isAllowedToMark ||
        markedRows.includes(rowData[groupMode.subject || 'well'])
      )
        return;
      dispatch(addMarkedRow(rowData[groupMode.subject || 'well'] as string));
    },
    [groupMode, markedRows, isAllowedToMark],
  );

  const unmarkRow = React.useCallback(
    (rowData: { [key: string]: string | number }) => {
      if (!isAllowedToMark || !isMarkingRows) return;
      dispatch(removeMarkedRow(rowData[groupMode.subject || 'well'] as string));
    },
    [isMarkingRows, isAllowedToMark, groupMode],
  );

  const actions = React.useMemo(
    () => ({
      selectRow,
      markSeveralRows,
      markAll,
      markRow,
      unmarkRow,
    }),
    [selectRow, markSeveralRows, markAll, markRow, unmarkRow],
  );

  return actions;
};
