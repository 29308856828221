export interface WellDrilldownTableItem {
  wellId: string;
  well: string;
  oilActual: number;
  oilCapacity: number;
  oilVariance: number;
  gasActual: number;
  gasCapacity: number;
  gasVariance: number;
  waterActual: number;
  waterCapacity: number;
  waterVariance: number;
  boeActual: number;
  boeCapacity: number;
  boeVariance: number;
  color: string;
  subCause: string;
  planType?: string | null;
  startProdDate: Date;
}
export interface VarianceDrilldownTableItem {
  wellId: string;
  well: string;
  oilActual: number;
  oilCapacity: number;
  oilVariance: number;
  gasActual: number;
  gasCapacity: number;
  gasVariance: number;
  waterActual: number;
  waterCapacity: number;
  waterVariance: number;
  boeActual: number;
  boeCapacity: number;
  boeVariance: number;
  color: string;
  subCause: string;
  planType?: string | null;
  varianceOptionId: string;
  startProdDate: Date;
}
export interface ForecastDrilldownTableItem {
  wellId: string;
  well: string;
  oilForecast: number;
  oilCapacity: number;
  oilVariance: number;
  gasForecast: number;
  gasCapacity: number;
  gasVariance: number;
  waterForecast: number;
  waterCapacity: number;
  waterVariance: number;
  boeForecast: number;
  boeCapacity: number;
  boeVariance: number;
}
export interface VarianceTableItem {
  varianceOptionId: string;
  subCause: string;
  planType?: string | null;
  color: string;
  variance: number;
  actual: number;
  capacity: number;
}

export interface MaxColumnWidth {
  secondColWidth: number;
  thirdColWidth: number;
  fourthColWidth: number;
}

export type GroupDrilldownTable = {
  [groupCriterion: string]: string;
} & { oil: number; gas: number; water: number; boe: number };

export type DrilldownTable = {
  [firstColKey: string]: string;
} & { variance: number; planType?: string | null };

export const COMPARE_OPTION = {
  extVsCap: 'extVsCap',
  actVsExt: 'actVsExt',
  actual: 'actual',
};

export interface DrilldownTableParams {
  phase: string;
  minDate: Date;
  maxDate: Date;
  rateVolume: string;
  grossNet: string;
  compareOption: string;
}
export interface CommonTotalRow {
  secondCol: number;
  thirdCol: number;
  variance: number;
}

export type SearchResult = {
  matched: number[];
};

export const ASC = 'ASC';
export const DESC = 'DESC';

export const VAR_SORTING_ORDER = [
  { abs: false, direction: ASC },
  { abs: false, direction: DESC },
  { abs: true, direction: DESC },
];

export const CELL_HEIGHT = 18;

export const NO_CAPACITY_MESSAGE =
  'The capacity for this well is equal to the actual production because no capacity has been defined within this region of interest date range.';

export const NO_FORECAST_MESSAGE =
  'The RE forecast for this well is 0 because no RE Forecast has been defined within this region of interest date range';
