import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faAt } from '@fortawesome/pro-light-svg-icons';
import MentionWindow from 'modules/notes/components/MentionWindow';
import { END_TEXT_MENTION_REG_EXP } from 'modules/mentionNotification/models/mentionNotification';
import type { Attachment } from 'modules/notes/models/notes';
import type { User } from 'modules/user/models/user';

import Button from 'components/Button';

import NoteImages from './NoteImages';
import { TextareaAutosize } from '@material-ui/core';
import AttachmentFormItems from './AttachmentsFormItems';
import { isIdNew } from 'helpers';
import { isInside } from 'modules/chart/utils';
import ClickOutside from '../../../components/ClickOutside';
import { extensionsRegex } from '../models';

const ENTER_KEYCODE = 13;
const BYTES_50MB = 1024 * 1024 * 50;

interface EventNoteFormProps {
  isDeleteInitialNoteAttachments?: boolean;
  isForEdit?: boolean;
  noteAttachments?: Attachment[];
  onSubmit: (noteText: string, tempAttachments: any) => Promise<any> | void;
  noteText: string;
  usersByEmailName: { [emailName: string]: User };
  onUploadAttachment: (file: any) => any;
  setText: (text: string) => void;
  initialNoteAttachments?: any[];
  initialText: string;
  noteId: string;
  onSetTempAttachments: (attachmentItem: any, noteId: string) => void;
  onDeleteTempAttachments: (
    timestamp: any,
    noteId: string,
    fileId: string,
  ) => void;
  tempAttachments: any;
  notificate: (message: string) => void;
  hideNotification: () => void;
}

const EventNoteForm = ({
  onDeleteTempAttachments,
  isForEdit,
  noteAttachments = [],
  onSubmit,
  noteText,
  usersByEmailName,
  onUploadAttachment,
  setText,
  initialText,
  isDeleteInitialNoteAttachments,
  noteId,
  onSetTempAttachments,
  tempAttachments,
  notificate,
  hideNotification,
}: EventNoteFormProps) => {
  const [isSavingNote, setSavingNote] = React.useState(false);
  const noteContainerRef: React.RefObject<HTMLElement | null> =
    React.useRef(null);
  const textAreaEl = React.useRef<HTMLTextAreaElement>(null);
  const [mentionInput, setMentionInput] = React.useState('');
  const [isActive, setIsActive] = React.useState(false);

  let hasAnyFiles = false;

  for (const key in tempAttachments) {
    if (tempAttachments[key].file.type !== 'image/jpeg') {
      hasAnyFiles = true;
      break;
    }
  }

  const [mentionAnchorEl, setMentionAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const isAttachedImage = React.useMemo(
    () => !(R.isEmpty(noteAttachments) && R.isEmpty(tempAttachments)),
    [tempAttachments, noteAttachments],
  );

  const attachmentsToDisplay = React.useMemo(() => {
    return noteAttachments.concat(R.values(tempAttachments)).reduce<any>(
      (acc, n: any) => {
        if (extensionsRegex.test(n.name)) acc.images.push(n);
        else acc.files.push(n);

        return acc;
      },
      { images: [], files: [] },
    );
  }, [noteAttachments, tempAttachments]);

  const [noteImages, setNoteImages] = React.useState<string[]>([]);

  const pushImage = React.useCallback(
    (source: string) => {
      setNoteImages([...noteImages, source]);
    },
    [noteImages],
  );

  const transformImages = React.useCallback(
    async (e: React.ClipboardEvent) => {
      const items = e.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        const acceptedFiles = [
          'image/png',
          'image/bmp',
          'image/jpg',
          'image/jpeg',
          'image/png',
        ];
        const isValidFormat = acceptedFiles.includes(items[i].type);
        if (isValidFormat) {
          e.preventDefault();
          const file: File | null = items[i].getAsFile();
          const URL = window.URL;

          if (file) {
            const src = URL.createObjectURL(file);
            const newId = uuid();

            pushImage(src);
            const timestamp = new Date().toISOString();
            const newAttachmentItem = {
              url: src,
              id: newId,
              name: file.name,
              file,
              timestamp,
            };
            onSetTempAttachments(
              {
                type: 'local',
                timestamp,
                item: newAttachmentItem,
              },
              noteId,
            );
            const uploadResult = await onUploadAttachment(file);
            const attachmentId = uploadResult.payload.data.uploadAttachment.id;
            const newAttachmentWithId = R.assoc(
              'id',
              attachmentId,
              newAttachmentItem,
            );
            onSetTempAttachments(
              {
                type: 'remote',
                timestamp,
                item: newAttachmentWithId,
              },
              noteId,
            );
            hideNotification();
          }
        }
      }
    },
    [
      pushImage,
      onSetTempAttachments,
      hideNotification,
      noteId,
      onUploadAttachment,
    ],
  );

  const onPasteHandler = React.useCallback(
    (e: any) => {
      if (e.clipboardData && e.clipboardData.items.length > 0) {
        transformImages(e);
      }
    },
    [transformImages],
  );

  const handleTextInput = React.useCallback(
    (target: HTMLElement) => {
      if (!(target instanceof window.HTMLTextAreaElement)) {
        return;
      }
      const { value } = target;
      const positionOfCaret = target.selectionStart;
      const textBeforeCaret = value.slice(0, positionOfCaret);

      if (END_TEXT_MENTION_REG_EXP.test(textBeforeCaret)) {
        const matchedStringArray = textBeforeCaret.match(
          END_TEXT_MENTION_REG_EXP,
        );
        setMentionAnchorEl(target);
        setText(value);
        setMentionInput(R.last(matchedStringArray));
      } else {
        setText(value);
        setMentionInput('');
        setMentionAnchorEl(null);
      }
    },
    [setText],
  );

  const onTextInput = React.useCallback(
    (e: React.MouseEvent<HTMLTextAreaElement>) => {
      handleTextInput(e.currentTarget);
    },
    [handleTextInput],
  );

  const handleMentionWindowClose = React.useCallback(() => {
    setMentionAnchorEl(null);
    if (textAreaEl.current && textAreaEl.current.setSelectionRange) {
      const positionOfCaret = textAreaEl.current.selectionStart;
      textAreaEl.current.setSelectionRange(positionOfCaret, positionOfCaret);
    }
  }, [setMentionAnchorEl]);

  const handleMentionChoose = React.useCallback(
    async (emailName: string) => {
      if (textAreaEl.current) {
        const positionOfCaret = textAreaEl.current.selectionStart;
        const textBeforeCaret = noteText.slice(0, positionOfCaret);
        const textAfterCaret = noteText.slice(positionOfCaret);
        const newTextAreaValueBeforeCaret = textBeforeCaret.replace(
          END_TEXT_MENTION_REG_EXP,
          `@${emailName} `,
        );
        const newTextAreaValue = newTextAreaValueBeforeCaret + textAfterCaret;
        await setText(newTextAreaValue);
        setMentionAnchorEl(null);
        const newCaretPos =
          newTextAreaValue.search(emailName) + emailName.length + 1;
        if (textAreaEl.current && textAreaEl.current.setSelectionRange) {
          textAreaEl.current.setSelectionRange(newCaretPos, newCaretPos);
        }
      }
    },
    [noteText, setText, textAreaEl],
  );

  React.useEffect(() => {
    if (isForEdit) {
      textAreaEl?.current?.focus();
      textAreaEl?.current?.setSelectionRange(
        textAreaEl.current.value.length,
        textAreaEl?.current?.value.length,
      );
    }
  }, [isForEdit]);

  const handleSubmitWithCheck = React.useCallback(() => {
    if (
      R.values(tempAttachments).every(
        tempAttachment => !isIdNew(tempAttachment.id),
      )
    ) {
      setText(noteText.trim());
      onSubmit(noteText, tempAttachments);
      if (!isForEdit) {
        setText('');
      }
      textAreaEl.current?.blur();
      setIsActive(false);
    } else {
      notificate('Finishing Uploading Attachments...');
      setSavingNote(true);
    }
  }, [
    notificate,
    setText,
    noteText,
    onSubmit,
    textAreaEl,
    setIsActive,
    tempAttachments,
    isForEdit,
  ]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      const { keyCode, ctrlKey } = e;
      if (
        keyCode === ENTER_KEYCODE &&
        ctrlKey &&
        !(
          (noteText.trim() === '' || noteText.trim() === initialText.trim()) &&
          !isDeleteInitialNoteAttachments &&
          R.isEmpty(tempAttachments)
        )
      ) {
        handleSubmitWithCheck();
      }
    },
    [
      handleSubmitWithCheck,
      noteText,
      initialText,
      isDeleteInitialNoteAttachments,
      tempAttachments,
    ],
  );

  const addTempAttachment = React.useCallback(
    async e => {
      const { target } = e;
      const { files } = target;
      const tascks = [] as any;
      const tempAttachmentItem = [] as any;
      const fileInput = document.getElementById(
        `file-input-${noteId}`,
      ) as HTMLInputElement;
      for await (const file of files) {
        if (!file) return;
        if (file.size > BYTES_50MB) {
          notificate('File size is too big. Maximum file size is 50MB');
          return;
        }
        const URL = window.URL;
        const src = URL.createObjectURL(file);

        const timestamp = new Date().toISOString();
        const newId = uuid();
        const newAttachmentItem = {
          url: src,
          name: file.name,
          file,
          id: newId,
          timestamp,
        };
        tempAttachmentItem.push(newAttachmentItem);
        onSetTempAttachments(
          {
            type: 'local',
            timestamp,
            item: newAttachmentItem,
          },
          noteId,
        );
        tascks.push(onUploadAttachment(file));
      }
      if (fileInput) {
        fileInput.value = '';
      }
      Promise.all(tascks).then(actions => {
        actions.forEach((action: any, i) => {
          const attachmentId = action.payload.data.uploadAttachment.id;
          const newAttachmentWithId = R.assoc(
            'id',
            attachmentId,
            tempAttachmentItem[i],
          );

          onSetTempAttachments(
            {
              type: 'remote',
              timestamp: tempAttachmentItem[i].timestamp,
              item: newAttachmentWithId,
            },
            noteId,
          );
        });
        hideNotification();
      });
    },
    [
      onSetTempAttachments,
      noteId,
      onUploadAttachment,
      notificate,
      hideNotification,
    ],
  );

  const deleteTempAttachment = React.useCallback(
    (timestamp, fileId) => {
      onDeleteTempAttachments(timestamp, noteId, fileId);
    },
    [onDeleteTempAttachments, noteId],
  );

  const getContainerAreaCoords = React.useCallback(
    elementBoundingRect => ({
      x1: elementBoundingRect.x,
      x2: elementBoundingRect.x + elementBoundingRect.width,
      y1: elementBoundingRect.y,
      y2: elementBoundingRect.y + elementBoundingRect.height,
    }),
    [],
  );

  const onMouseClickBlurHandler = React.useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const currentPointerPosition = {
        clientX,
        clientY,
      };
      if (noteContainerRef.current && isActive && !isForEdit) {
        const noteContainerCoords = getContainerAreaCoords(
          noteContainerRef.current.getBoundingClientRect(),
        );
        const currentPointerIsInsideNote = isInside(
          currentPointerPosition,
          noteContainerCoords,
        );
        if (
          !currentPointerIsInsideNote &&
          noteText === '' &&
          R.isEmpty(tempAttachments)
        ) {
          setIsActive(false);
        }
      }
    },
    [
      getContainerAreaCoords,
      isForEdit,
      noteText,
      tempAttachments,
      setIsActive,
      isActive,
    ],
  );

  const onAtClick = React.useCallback(async () => {
    if (!textAreaEl.current) return;
    const positionOfCaret = textAreaEl.current.selectionStart;

    if (noteText[positionOfCaret - 1] !== '@') {
      await setText(textAreaEl.current.value + '@');
    }

    handleTextInput(textAreaEl.current);
  }, [noteText, handleTextInput, textAreaEl.current]);

  React.useEffect(() => {
    const dashboardWrapper = document.querySelector('#imageDetailRoot');
    dashboardWrapper?.addEventListener(
      'click',
      onMouseClickBlurHandler as EventListener,
    );
    return () =>
      dashboardWrapper?.removeEventListener(
        'click',
        onMouseClickBlurHandler as EventListener,
      );
  }, [onMouseClickBlurHandler]);

  React.useEffect(() => {
    if (
      isSavingNote &&
      R.values(tempAttachments).every(
        tempAttachment => !isIdNew(tempAttachment.id),
      )
    ) {
      setText(noteText.trim());
      onSubmit(noteText, tempAttachments);
      if (!isForEdit) {
        setText('');
      }
      textAreaEl.current?.blur();
      if (textAreaEl.current !== null) {
        textAreaEl.current.style.height = '36px';
      }
      setIsActive(false);
      setSavingNote(false);
    }
  }, [
    isSavingNote,
    setSavingNote,
    setText,
    noteText,
    onSubmit,
    textAreaEl,
    setIsActive,
    tempAttachments,
    isForEdit,
  ]);

  return (
    <EventNoteForm.Container
      ref={noteContainerRef}
      isForEdit={isForEdit}
      hasAnyAttachments={tempAttachments !== {}}
    >
      <EventNoteForm.Form>
        <EventNoteForm.FormWrapper isForEdit={isForEdit}>
          <ClickOutside action={() => setIsActive(false)}>
            <EventNoteForm.TextareaWrapper
              isActive={isActive}
              isForEdit={isForEdit}
              hasAnyAttachments={Object.keys(tempAttachments).length !== 0}
            >
              <EventNoteForm.Textarea
                id={`text-input-${noteId}`}
                isForEdit={isForEdit}
                onPaste={onPasteHandler}
                ref={textAreaEl}
                maxLength="2000"
                rows="1"
                value={noteText}
                onChange={onTextInput}
                onKeyDown={e => handleKeyDown(e)}
                placeholder="Write a note..."
                onFocus={() => setIsActive(true)}
              />
            </EventNoteForm.TextareaWrapper>

            <EventNoteForm.AllAttachments>
              {!isForEdit && (
                <NoteImages
                  attachments={attachmentsToDisplay.images}
                  deleteTempAttachment={deleteTempAttachment}
                  isEditing
                />
              )}

              {!isForEdit &&
                !R.isEmpty(attachmentsToDisplay.files) &&
                hasAnyFiles && (
                  <EventNoteForm.Files>
                    {R.values(tempAttachments).map((attachmentItem, idx) => {
                      return (
                        <AttachmentFormItems
                          key={idx + attachmentItem.timestamp}
                          attachment={attachmentItem}
                          handleAttachmentDelete={() => {
                            deleteTempAttachment(
                              attachmentItem.timestamp,
                              attachmentItem.id,
                            );
                          }}
                        />
                      );
                    })}
                  </EventNoteForm.Files>
                )}
            </EventNoteForm.AllAttachments>

            {Boolean(isActive || noteText.trim() || initialText) && (
              <EventNoteForm.ControlsArea>
                <EventNoteForm.Controls>
                  <EventNoteForm.Button
                    width={104}
                    onClick={handleSubmitWithCheck}
                    disabled={
                      noteText === initialText &&
                      !isDeleteInitialNoteAttachments &&
                      R.isEmpty(tempAttachments)
                    }
                  >
                    Save
                  </EventNoteForm.Button>

                  <EventNoteForm.Button width={34}>
                    <EventNoteForm.Label htmlFor={`file-input-${noteId}`}>
                      <FontAwesomeIcon
                        icon={faPaperclip}
                        flip="vertical"
                        style={{ fontSize: 18 }}
                      />
                    </EventNoteForm.Label>
                  </EventNoteForm.Button>

                  <EventNoteForm.Button width={34} onClick={onAtClick}>
                    <EventNoteForm.Label htmlFor={`text-input-${noteId}`}>
                      <FontAwesomeIcon icon={faAt} style={{ fontSize: 18 }} />
                    </EventNoteForm.Label>
                  </EventNoteForm.Button>
                </EventNoteForm.Controls>

                {!isAttachedImage && (
                  <EventNoteForm.PasteLabel>
                    Ctrl+V to paste image
                  </EventNoteForm.PasteLabel>
                )}
              </EventNoteForm.ControlsArea>
            )}
          </ClickOutside>

          <EventNoteForm.FileInput
            type="file"
            id={`file-input-${noteId}`}
            onChange={e => addTempAttachment(e)}
            multiple
          />
        </EventNoteForm.FormWrapper>

        {mentionAnchorEl && (
          <MentionWindow
            mentionInput={mentionInput}
            usersByEmailName={usersByEmailName}
            anchorEl={mentionAnchorEl}
            handleClose={handleMentionWindowClose}
            handleMentionChoose={handleMentionChoose}
          />
        )}
      </EventNoteForm.Form>
    </EventNoteForm.Container>
  );
};

EventNoteForm.Container = styled.div`
  width: 100%;
  position: relative;
  padding: ${({ isForEdit }) => (isForEdit ? '0' : '12px')} 0;
`;

EventNoteForm.FormWrapper = styled.div`
  background: ${({ isForEdit }) => (isForEdit ? 'initial' : '#fff')};
  width: 100%;
`;

EventNoteForm.Label = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

EventNoteForm.FileInput = styled.input`
  display: none;
`;

EventNoteForm.Files = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

EventNoteForm.ImagesWrapper = styled.div`
  background: #fff;
  width: 100%;
`;

EventNoteForm.Image = styled.img`
  background: #fff;
  width: 100%;
  margin-top: ${props => (!props.isFirst ? 0 : '10px')};
`;

EventNoteForm.Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato', sans-serif;
  position: relative;
`;

EventNoteForm.TextareaWrapper = styled.div`
  border: ${({ isForEdit }) => (isForEdit ? '0' : '1px solid #c1c1c1')};
  box-shadow: ${({ isForEdit }) =>
    isForEdit ? 'none' : '0 2px 3px rgba(0, 0, 0, 0.15)'};
  padding: ${({ isForEdit }) => (isForEdit ? '0' : '4px 6px')};
  margin-bottom: ${({ hasAnyAttachments }) =>
    hasAnyAttachments ? '12px' : '0'};
`;

EventNoteForm.Textarea = styled(TextareaAutosize)`
  width: 100%;
  height: auto;
  min-height: 18px;
  border: none;
  resize: none;
  background: transparent;
  ${({ isForEdit }) => (isForEdit ? 'padding: 0; line-height: 20px;' : '')}

  :focus {
    outline: 0;
  }
`;

EventNoteForm.AllAttachments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

EventNoteForm.PasteLabel = styled.div`
  color: #8e8e8e;
  max-height: 20px;
  font-size: 12px;
  line-height: 16px;
`;

EventNoteForm.Controls = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

EventNoteForm.ControlsArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`;

EventNoteForm.Button = styled(Button)`
  font-size: 14px;
  max-height: 30px;
`;

export default EventNoteForm;
