import * as React from 'react';

import { RIBBON_HEIGHT as height } from 'modules/chart/models/chart';
import { RibbonEvent, RibbonOption } from 'modules/ribbon/models';

import getRibbonEventBounds from '../../utils/getRibbonEventBounds';
import getRibbonEventsStackHeight from '../../utils/getRibbonEventsStackHeight';
import stackRibbonEventsVertically from '../../utils/stackRibbonEventsVertically';

import RibbonEventRect from './RibbonEventRect';

interface SVGRibbonChartProps {
  extremeDates: { min: Date; max: Date };
  isAxisDragging: boolean;
  onRibbonEventDialogOpen(string): void;
  onSetActiveEventId: (id: string | null) => void;
  ribbonEvents: RibbonEvent[];
  ribbonOptions: { [ribbon: string]: RibbonOption };
  today: Date;
  xScale: any;
}

const SVGRibbonChart = ({
  extremeDates,
  isAxisDragging,
  onRibbonEventDialogOpen,
  ribbonEvents,
  ribbonOptions,
  today,
  xScale,
  onSetActiveEventId,
}: SVGRibbonChartProps) => {
  const ribbonEventsData = React.useMemo(() => {
    if (!ribbonEvents) return [];
    return ribbonEvents.map(event => {
      const bounds = getRibbonEventBounds(event, today, extremeDates, xScale);
      const currentEvent = event;
      const currentBounds = bounds;
      return { currentEvent, currentBounds };
    });
  }, [ribbonEvents, today, extremeDates, xScale]);

  const stackedData = React.useMemo(() => {
    const ribbonEvents = stackRibbonEventsVertically(ribbonEventsData);
    const totalHeight = getRibbonEventsStackHeight(ribbonEvents, height);
    return { ribbonEvents, totalHeight };
  }, [ribbonEventsData]);

  const stackedRibbonEvents = React.useMemo(() => {
    return stackedData.ribbonEvents.map(e => {
      const { height, yLevel } = e.currentBounds;
      e.currentBounds.y1 = stackedData.totalHeight - height * yLevel;
      e.currentBounds.y2 = stackedData.totalHeight - height * (yLevel + 1);
      return e;
    });
  }, [stackedData]);

  return (
    <svg
      height={stackedData.totalHeight + 'px'}
      preserveAspectRatio="none"
      viewBox={`0 0 ${xScale.range()[1]} ${stackedData.totalHeight}`}
      width={xScale.range()[1] - 1}
      shapeRendering="geometricPrecision"
      style={{ position: 'relative', display: 'block' }}
      className="ribbon-chart-wrapper"
    >
      {stackedRibbonEvents?.map(event => (
        <RibbonEventRect
          key={event.currentEvent.id}
          currentEvent={event.currentEvent}
          currentBounds={event.currentBounds}
          isAxisDragging={isAxisDragging}
          onSetActiveEventId={onSetActiveEventId}
          onRibbonEventDialogOpen={onRibbonEventDialogOpen}
          ribbonOptions={ribbonOptions}
        />
      ))}
    </svg>
  );
};

export default React.memo<SVGRibbonChartProps>(SVGRibbonChart);
