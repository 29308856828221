import { area, curveStepAfter } from 'd3-shape';
import { utcDay } from 'd3-time';
import * as React from 'react';

import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

interface VarianceEventClickableAreaProps {
  varianceData: { date: Date; variance: number }[];
  eventIndex: number;
  height: number;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  title: string;
  varianceEvent: VarianceEvent;
  xScale: any;
  yScale: any;
}

const VarianceEventClickableArea = ({
  varianceData,
  eventIndex,
  height,
  onVarianceDialogOpen,
  title,
  varianceEvent,
  xScale,
  yScale,
}: VarianceEventClickableAreaProps) => {
  const createVarianceArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.date || 0))
        .y1((d: any) => yScale(d.variance))
        .y0(height / 2)
        .curve(curveStepAfter),
    [xScale, yScale, height],
  );

  const createClipArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.date || 0))
        .y1((d: any) => yScale(d.variance))
        .y0(yScale.range()[1])
        .curve(curveStepAfter),
    [xScale, yScale],
  );

  const lastEventDayLimit = utcDay.offset(varianceEvent.dayEnd, 1).getTime();
  const firstEventDayLimit = varianceEvent.dayStart.getTime();
  const filteredData = varianceData.filter(
    dataPoint =>
      dataPoint.date.getTime() <= lastEventDayLimit &&
      dataPoint.date.getTime() >= firstEventDayLimit,
  );

  const handleClick = React.useCallback(
    () => onVarianceDialogOpen(eventIndex, varianceEvent.id),
    [onVarianceDialogOpen, eventIndex, varianceEvent],
  );

  return (
    <>
      <path
        d={createVarianceArea(filteredData)}
        onMouseUp={handleClick}
        fill="transparent"
        cursor="pointer"
        className="variance-interactive interactive panInteraction"
      />
      <defs>
        <clipPath id={`clickableEventClip${varianceEvent.id + title}`}>
          <path d={createClipArea(filteredData)} />
        </clipPath>
      </defs>
    </>
  );
};

export default React.memo<VarianceEventClickableAreaProps>(
  VarianceEventClickableArea,
);
