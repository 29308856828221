import { format as d3Format } from 'd3-format';
import * as React from 'react';
import styled from 'styled-components';
import { throttle } from 'throttle-debounce';

const RESET_BTN_HEIGHT = 20;

type YAxisProps = {
  color?: string;
  disableZero?: boolean;
  format: string;
  height: number;
  hideLine?: () => void;
  isAdjusted: boolean;
  isDragging: boolean;
  isSeries?: boolean;
  isXAxisDragging?: boolean;
  maxDataPoint: number;
  minDataPoint?: number;
  onStartYAxisDragging?: () => void;
  onStopDragging?: () => void;
  setDisplayMaxDataPoint: (maxDataPoint: number) => void;
  setDisplayMinDataPoint?: (minDataPoint: number) => void;
  setIsAdjusted: (isAdjusted: boolean) => void;
  showLine?: (value: number) => void;
  resetMax: () => void;
  varianceTrellis?: boolean;
  yScale: any;
};

const YAxis = ({
  color,
  disableZero,
  format,
  height,
  hideLine,
  isDragging,
  isSeries,
  isXAxisDragging,
  maxDataPoint,
  minDataPoint = -maxDataPoint,
  onStartYAxisDragging,
  onStopDragging,
  setDisplayMaxDataPoint,
  setDisplayMinDataPoint,
  setIsAdjusted,
  showLine,
  isAdjusted,
  resetMax,
  varianceTrellis,
  yScale,
}: YAxisProps) => {
  const tickValues = yScale.ticks(5, d3Format(format));
  const axisEl = React.useRef() as React.RefObject<SVGSVGElement>;
  const [initialCursorPosition, setInitialCursorePosition] = React.useState(0);
  const [firstCursorPosition, setFirstCursorPosition] = React.useState(0);
  const [secondCursorPosition, setSecondCursorPosition] = React.useState(0);

  const handleWheel = (e: React.WheelEvent) => {
    const axisSizes =
      axisEl && axisEl.current ? axisEl.current.getBoundingClientRect() : null;
    if (axisSizes) {
      const { pageY } = e;
      const sign = Math.sign(e.deltaY);
      const yMultiplier = Math.abs(e.deltaY) > 40 ? 0.1 : 0.02;
      if (varianceTrellis && setDisplayMinDataPoint) {
        const yVarianceMultiplier = Math.abs(e.deltaY) > 40 ? 0.2 : 0.02;
        const range = Math.abs(minDataPoint) + Math.abs(maxDataPoint);
        const offset = sign * range * yVarianceMultiplier;
        const coefficient = (pageY - axisSizes.top - 1) / axisSizes.height;
        const newMaxDataPoint = maxDataPoint + offset * coefficient;
        const newMinDataPoint = minDataPoint - (offset - offset * coefficient);
        setDisplayMaxDataPoint(
          newMaxDataPoint < newMinDataPoint ? maxDataPoint : newMaxDataPoint,
        );
        setDisplayMinDataPoint &&
          setDisplayMinDataPoint(
            newMinDataPoint > newMaxDataPoint ? minDataPoint : newMinDataPoint,
          );
        setIsAdjusted(true);
      } else {
        const newMaxDataPoint =
          maxDataPoint + sign * maxDataPoint * yMultiplier;
        setDisplayMaxDataPoint(
          newMaxDataPoint < 1 ? maxDataPoint : newMaxDataPoint,
        );
        setIsAdjusted(true);
      }
    }
  };

  const handleHover = React.useCallback(
    (e: any) => {
      if (isSeries || !showLine) {
        return;
      }
      const axisSizes =
        axisEl && axisEl.current
          ? axisEl.current.getBoundingClientRect()
          : null;
      if (axisSizes) {
        const { pageY } = e;
        const cursorRate = yScale.invert(pageY - axisSizes.top - 1);
        showLine(cursorRate);
      }
    },
    [showLine, yScale, isSeries, axisEl],
  );
  const mouseLiveHandler = React.useCallback(() => {
    if (isSeries || !hideLine) {
      return;
    }
    hideLine();
  }, [isSeries, hideLine]);

  const moveYAxis = React.useCallback(
    (offset: number, pageY: number) => {
      setDisplayMaxDataPoint(maxDataPoint + offset);
      setDisplayMinDataPoint && setDisplayMinDataPoint(minDataPoint + offset);
    },
    [
      setDisplayMaxDataPoint,
      setDisplayMinDataPoint,
      minDataPoint,
      maxDataPoint,
    ],
  );

  const handleDrag = React.useCallback(
    (e: any) => {
      if (!varianceTrellis || !isDragging) {
        return;
      }
      const { pageY } = e;
      const axisSizes =
        axisEl && axisEl.current
          ? axisEl.current.getBoundingClientRect()
          : null;
      const offset = axisSizes
        ? initialCursorPosition - yScale.invert(pageY - axisSizes.top)
        : 0;
      moveYAxis(offset, pageY);
      setIsAdjusted(true);
    },
    [
      isDragging,
      varianceTrellis,
      yScale,
      moveYAxis,
      setIsAdjusted,
      initialCursorPosition,
      axisEl,
    ],
  );

  const handleDragStart = (e: any) => {
    if (!varianceTrellis || !onStartYAxisDragging || e.target.id === 'reset') {
      return;
    }
    const { pageY } = e;
    setFirstCursorPosition(pageY);
    setSecondCursorPosition(0);
    const axisSizes =
      axisEl && axisEl.current ? axisEl.current.getBoundingClientRect() : null;
    const positionOnAxis = axisSizes ? yScale.invert(pageY - axisSizes.top) : 0;
    setInitialCursorePosition(positionOnAxis);

    onStartYAxisDragging();
  };

  const handleDragEnd = React.useCallback(
    (e: any) => {
      if (!varianceTrellis || !onStopDragging) {
        return;
      }
      setSecondCursorPosition(e.pageY);
      setInitialCursorePosition(0);
      onStopDragging();
    },
    [varianceTrellis, onStopDragging, setInitialCursorePosition],
  );

  const handleClick = React.useCallback(
    (e: any) => {
      if (
        isSeries ||
        (varianceTrellis &&
          (secondCursorPosition === 0 ||
            firstCursorPosition !== secondCursorPosition))
      ) {
        return;
      }
      const axisSizes =
        axisEl && axisEl.current
          ? axisEl.current.getBoundingClientRect()
          : null;
      if (axisSizes) {
        const { pageY } = e;
        const cursorRate = yScale.invert(pageY - axisSizes.top - 1);
        if (varianceTrellis && setDisplayMinDataPoint) {
          if (cursorRate < 0) {
            setDisplayMinDataPoint(cursorRate);
          } else if (cursorRate > 0) {
            setDisplayMaxDataPoint(cursorRate);
          } else {
            return;
          }
        } else {
          setDisplayMaxDataPoint(Math.abs(cursorRate));
        }
        setIsAdjusted(true);
      }
    },
    [
      axisEl,
      isSeries,
      setDisplayMaxDataPoint,
      setDisplayMinDataPoint,
      setIsAdjusted,
      varianceTrellis,
      yScale,
      firstCursorPosition,
      secondCursorPosition,
    ],
  );

  React.useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd, true);
    }
    return () => {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [handleDragEnd, handleDrag, isDragging]);

  return (
    <svg
      ref={axisEl}
      height={height}
      preserveAspectRatio="none"
      viewBox={`0 0 50 ${height}`}
      width="100%"
      shapeRendering="geometricPrecision"
      onWheel={e => throttle(30, false, handleWheel(e))}
      style={{ cursor: isDragging || isXAxisDragging ? 'grabbing' : 'zoom-in' }}
      onMouseDown={handleDragStart}
      onMouseMove={e => handleHover(e)}
      onMouseOver={e => handleHover(e)}
      onMouseOut={mouseLiveHandler}
      onClick={e => handleClick(e)}
    >
      {tickValues.map(tick => {
        if (
          yScale(tick) < 6 ||
          ((disableZero || !varianceTrellis) && tick === 0)
        )
          return null;
        if (isAdjusted && yScale(tick) < RESET_BTN_HEIGHT) return null;

        return (
          <g key={tick}>
            <line
              stroke={color ? color : 'black'}
              strokeWidth="0.5"
              x1={43}
              x2={50}
              y1={yScale(tick)}
              y2={yScale(tick)}
            />
            {!(isAdjusted && yScale(tick) < RESET_BTN_HEIGHT) && (
              <text
                fontSize={10}
                style={{ userSelect: 'none' }}
                textAnchor="end"
                x={40}
                y={yScale(tick) + 3}
                fill={color ? color : 'black'}
              >
                {d3Format(format)(tick)}
              </text>
            )}
          </g>
        );
      })}

      {isAdjusted && (
        <g
          cursor="pointer"
          onClick={e => {
            e.stopPropagation();
            resetMax();
          }}
        >
          <rect fill="white" width="35" height={RESET_BTN_HEIGHT} y={2} x={5} />
          <YAxis.ResetText y={2} x={40} textAnchor="end">
            <tspan x="40" dy="1.4em" id="reset">
              Reset
            </tspan>
          </YAxis.ResetText>
        </g>
      )}
    </svg>
  );
};

YAxis.ResetText = styled.text`
  font-size: 12px;
  cursor: pointer;
  fill: ${(props: any) => props.theme.colors.criticalText};
  user-select: none;
`;

export default React.memo<YAxisProps>(YAxis);
