import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';
import type { Action, Selector } from 'store/models';
import { v4 as uuid } from 'uuid';

import {
  ADD_AVAILABLE_OPTIONS,
  ADD_OPTIONS,
  ADD_OPTIONS_GROUP,
  ADD_OPTION_TO_GROUP,
  namespace,
  REMOVE_AVAILABLE_OPTION,
  REMOVE_OPTION_FROM_GROUP,
  REMOVE_OPTIONS_GROUP,
  SET_AVAILABLE_CORESERIES,
  SET_INITIAL_STATE,
  SET_NEW_GROUP_ORDER,
  SET_NEW_OPTIONS_ORDER,
  SWITCH_CHART_TYPE,
  SWITCH_OPTION_VISIBLE,
  SET_CUSTOM_COLOR,
  SET_ALL_CORESERIES_OPTION,
  SET_CORESERIES_OPTION,
  SET_AVAILABLE_OPTIONS,
  SWITCH_OPTIONS_VISIBLE,
  SET_CORESERIES,
  RESET_DATA_SERIES,
  RESET_SENSOR_SERIES,
} from './ChartOptionsActions';
import {
  AvailableDataSeries,
  AvailableOptionsList,
  coreSeries,
  ChartOptionsGroup,
  GroupToDisplay,
  ListChartOptions,
  ChartOption,
  DataSeriesGroupToDisplay,
} from './models';

const chartOptoinsRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'chartOptions';

type ChartOptionsState = {
  availableDataSeries: AvailableDataSeries[];
  options: ListChartOptions;
  coreSeries: ListChartOptions;
  dataSeriesGroups: ChartOptionsGroup[];
};

const initialState = {
  availableDataSeries: [] as AvailableDataSeries[],
  options: {} as ListChartOptions,
  coreSeries,
  dataSeriesGroups: [],
};

const ChartOptionsReducer = (
  state: ChartOptionsState = initialState,
  action: Action,
) => {
  const { payload } = action;
  switch (action.type) {
    case SET_ALL_CORESERIES_OPTION: {
      const newCavOptions = R.clone(coreSeries);
      Object.keys(state.coreSeries).forEach(key => {
        newCavOptions[key].isShow = !payload.isShow;
        newCavOptions[key].isAvailable = payload.isShow;
      });
      return R.assoc('coreSeries', newCavOptions, state);
    }
    case SET_CORESERIES_OPTION: {
      if (payload.optionId === 'all') {
        return state;
      }
      return R.assocPath(
        ['coreSeries', payload.optionId, 'isShow'],
        payload.isShow,
        state,
      );
    }
    case SET_AVAILABLE_CORESERIES: {
      if (payload.optionId === 'all') {
        return state;
      }
      return R.assocPath(
        ['coreSeries', payload.optionId, 'isAvailable'],
        payload.isAvailable,
        state,
      );
    }
    case SET_CORESERIES: {
      return R.assoc('coreSeries', payload.series, state);
    }
    case SET_CUSTOM_COLOR: {
      const { id, color } = action.payload;
      return R.assocPath(['options', id, 'customColor'], color, state);
    }
    case SET_NEW_OPTIONS_ORDER: {
      const tempGroups = [...state.dataSeriesGroups];
      const groupIndex = tempGroups.findIndex(
        group => group.groupId === payload.groupId,
      );
      if (R.isNil(groupIndex)) {
        return state;
      }
      tempGroups[groupIndex] = payload;
      return R.assoc('dataSeriesGroups', tempGroups, state);
    }
    case SET_INITIAL_STATE: {
      return initialState;
    }
    case RESET_DATA_SERIES: {
      const newOptions = Object.entries(state.options).reduce(
        (acc, [key, value]) => {
          if (key.startsWith('s')) acc[key] = value;

          return acc;
        },
        {},
      );

      const newAvailableDataSeries = state.availableDataSeries.filter(e =>
        e.id.startsWith('s'),
      );

      const newSeriesGroups = state.dataSeriesGroups
        .map(e => ({
          ...e,
          options: e.options.filter(id => id.startsWith('s')),
        }))
        .filter(e => e.options.length !== 0);

      return {
        ...initialState,
        options: newOptions,
        availableDataSeries: newAvailableDataSeries,
        coreSeries: state.coreSeries,
        dataSeriesGroups: newSeriesGroups,
      };
    }
    case RESET_SENSOR_SERIES: {
      const newOptions = Object.entries(state.options).reduce(
        (acc, [key, value]) => {
          if (!key.startsWith('s')) acc[key] = value;

          return acc;
        },
        {},
      );

      const newAvailableDataSeries = state.availableDataSeries.filter(
        e => !e.id.startsWith('s'),
      );

      const newSeriesGroups = state.dataSeriesGroups
        .map(e => ({
          ...e,
          options: e.options.filter(id => !id.startsWith('s')),
        }))
        .filter(e => e.options.length !== 0);

      return {
        ...initialState,
        options: newOptions,
        availableDataSeries: newAvailableDataSeries,
        coreSeries: state.coreSeries,
        dataSeriesGroups: newSeriesGroups,
      };
    }
    case SET_NEW_GROUP_ORDER: {
      return R.assoc('dataSeriesGroups', payload, state);
    }
    case SWITCH_OPTION_VISIBLE: {
      const { optionId } = payload;
      const option = state.options[optionId];
      const isShow = state.options[optionId].isShow;
      return R.assocPath(
        ['options', optionId],
        {
          ...option,
          isShow: !isShow,
        },
        state,
      );
    }
    case SWITCH_OPTIONS_VISIBLE: {
      if (R.isEmpty(state.options)) {
        return state;
      }
      const { options, show } = payload;
      const copyStateOptions = R.clone(state.options);
      options.forEach(optionId => {
        copyStateOptions[optionId].isShow = show;
      });
      return R.assoc('options', copyStateOptions, state);
    }
    case SWITCH_CHART_TYPE: {
      const { optionId, type } = payload;
      const group = state.dataSeriesGroups.find(group =>
        group.options.find(id => id === optionId),
      );
      const groupOptions = R.pathOr([], ['options'], group).reduce(
        (acc, id) => {
          acc[id] = { ...state.options[id] };
          return acc;
        },
        [],
      );
      groupOptions[optionId].chartType = type;
      return R.assoc(
        'options',
        {
          ...state.options,
          ...groupOptions,
        },
        state,
      );
    }
    case REMOVE_OPTIONS_GROUP: {
      const groups = state.dataSeriesGroups.filter(
        group => group.groupId !== payload.groupId,
      );
      return R.assoc('dataSeriesGroups', groups, state);
    }
    case REMOVE_OPTION_FROM_GROUP: {
      const { groupId, optionId } = payload;
      if (!groupId || !optionId) {
        return state;
      }
      const groups = state.dataSeriesGroups;
      const groupIndex = groups.findIndex(group => group.groupId === groupId);
      if (!R.isNil(groupIndex) && groups[groupIndex].options.length < 2) {
        const filtredGroups = state.dataSeriesGroups.filter(
          group => group.groupId !== payload.groupId,
        );
        return R.assoc('dataSeriesGroups', filtredGroups, state);
      }
      const options = groups[groupIndex].options.filter(id => id !== optionId);
      const tempData = [...groups];
      tempData.splice(groupIndex, 1, {
        ...groups[groupIndex],
        options,
      });

      return R.assoc('dataSeriesGroups', tempData, state);
    }
    case REMOVE_AVAILABLE_OPTION: {
      const newAvailables = state.availableDataSeries.filter(
        option => option.id !== payload.optionId,
      );

      return R.assoc('availableDataSeries', newAvailables, state);
    }
    case ADD_AVAILABLE_OPTIONS: {
      const extendAvalibleOptions = state.availableDataSeries.concat(payload);
      return R.assoc('availableDataSeries', extendAvalibleOptions, state);
    }
    case SET_AVAILABLE_OPTIONS: {
      return R.assoc('availableDataSeries', payload, state);
    }
    case ADD_OPTIONS: {
      const extendedOptions = R.merge(state.options, payload);

      return R.assoc('options', extendedOptions, state);
    }
    case ADD_OPTIONS_GROUP: {
      const { optionId, newGroupIndex } = payload;
      const id = uuid();
      const newGroup = { groupId: id, options: [optionId] };
      const groupIndex = R.isNil(newGroupIndex)
        ? state.dataSeriesGroups.length
        : newGroupIndex;

      const tempGroups = [...state.dataSeriesGroups];
      tempGroups.splice(groupIndex, 0, newGroup);
      const option = state.options[optionId];

      return R.compose(
        R.assoc('dataSeriesGroups', tempGroups),
        R.assocPath(['options', optionId], { ...option, isShow: true }),
      )(state);
    }
    case ADD_OPTION_TO_GROUP: {
      const { groupId, optionId, newOptionIndex } = payload;
      if (!groupId || !optionId) {
        return state;
      }
      const groups = state.dataSeriesGroups;
      const groupIndex = groups.findIndex(group => group.groupId === groupId);
      const tempData = [...groups];
      if (R.isNil(groupIndex) || !tempData[groupIndex]) {
        return state;
      }
      const options = [...tempData[groupIndex].options];
      options.splice(newOptionIndex, 0, optionId);
      tempData[groupIndex] = {
        ...tempData[groupIndex],
        options,
      };
      const option = state.options[optionId];

      return R.compose(
        R.assoc('dataSeriesGroups', tempData),
        R.assocPath(['options', optionId], { ...option, isShow: true }),
      )(state);
    }

    default: {
      return state;
    }
  }
};

export const getState = (state: any): ChartOptionsState => state[STATE_KEY];

export const getAvailableDataSeriesOptions: Selector<AvailableOptionsList> =
  createSelector(getState, (state: ChartOptionsState) => {
    const optionsList = R.values(state.availableDataSeries).filter(
      v => !v.id.startsWith('s'),
    );
    optionsList.sort((a, b) => a.title.localeCompare(b.title));
    return optionsList;
  });

export const getAvailableSensorSeriesOption: Selector<ChartOption[]> =
  createSelector(getState, (state: ChartOptionsState) => {
    const optionsList = R.values(state.availableDataSeries).filter(v =>
      v.id.startsWith('s'),
    );
    optionsList.sort((a, b) => a.title.localeCompare(b.title));
    return optionsList;
  });

export const getAllDataSeriesOptions: Selector<ListChartOptions> =
  createSelector(
    getState,
    (state: ChartOptionsState): ListChartOptions => state.options,
  );

export const getDataSeriesOptionsGroups: Selector<ChartOptionsGroup[]> =
  createSelector(
    getState,
    getAppConfig,
    (state: ChartOptionsState, { sensorSeries }) =>
      state.dataSeriesGroups
        .map(g => ({
          ...g,
          options: g.options.filter(o => sensorSeries || !o.startsWith('s')),
        }))
        .filter(g => g.options.length !== 0),
  );

export const getDataSeriesGroupsToDisplay: Selector<GroupToDisplay> =
  createSelector(
    getState,
    getAppConfig,
    (state: ChartOptionsState, { sensorSeries }) => {
      const options = state.options;
      const isOptionValid = id => {
        const isShow = R.pathOr(false, [id, 'isShow'], options);

        return sensorSeries ? isShow : !id.startsWith('s') && isShow;
      };
      const groupsToDisplay = state.dataSeriesGroups.reduce((acc, group) => {
        const filtredOptions = group.options.filter(optionId =>
          isOptionValid(optionId),
        );
        if (filtredOptions.length) {
          acc.push({ ids: filtredOptions });
        }

        return acc;
      }, [] as GroupToDisplay);
      return groupsToDisplay;
    },
  );

export const getDataSeriesGroupsWithColorAndTypeToDisplay: Selector<DataSeriesGroupToDisplay> =
  createSelector(
    getState,
    getAppConfig,
    (state: ChartOptionsState, { sensorSeries }) => {
      const options = state.options;
      const isOptionValid = id => {
        const isShow = R.pathOr(false, [id, 'isShow'], options);

        return sensorSeries ? isShow : !id.startsWith('s') && isShow;
      };
      const groupsToDisplay = state.dataSeriesGroups.reduce((acc, group) => {
        const filtredOptions = group.options
          .filter(optionId => isOptionValid(optionId))
          .map(id => ({
            id,
            customColor: options[id].customColor
              ? options[id].customColor?.substr(1).toUpperCase()
              : '',
            chartType: options[id].chartType,
          }));
        if (filtredOptions.length) {
          acc.push({ dataSeriesGroup: filtredOptions });
        }

        return acc;
      }, [] as DataSeriesGroupToDisplay);
      return groupsToDisplay;
    },
  );

export const getCoreSeriesOptionList: Selector<ListChartOptions> =
  createSelector(getState, (state: ChartOptionsState) => state.coreSeries);

export const getCoreSeriesChartOption: Selector<ChartOption[]> = createSelector(
  getState,
  (state: ChartOptionsState) => R.values(state.coreSeries),
);

export const getAvailableCoreSeriesOption: Selector<ChartOption[]> =
  createSelector(getCoreSeriesChartOption, (options: ChartOption[]) => {
    return options.filter(option => option.isAvailable);
  });

export const getSelectedCoreSeriesOptions: Selector<ChartOption[]> =
  createSelector(getCoreSeriesChartOption, (options: ChartOption[]) => {
    return options.filter(option => !option.isAvailable);
  });

export const getCoreSeriesOptionsToDisplay: Selector<ChartOption[]> =
  createSelector(getCoreSeriesChartOption, (options: ChartOption[]) =>
    options.filter(option => option.isShow),
  );

export default filterActions(ChartOptionsReducer as any, action =>
  action.type.match(chartOptoinsRegExp),
);
