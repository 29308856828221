import * as R from 'ramda';
import * as React from 'react';
import { DropTarget, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import styled from 'styled-components';

import {
  ChartOptionsGroup,
  ITEM_TYPES,
  ItemTypes,
  ListChartOptions,
  OPTIONS_TYPES,
} from '../models';

interface GroupInstance {
  getNode(): HTMLDivElement | null;
}

const groupTarget = {
  drop(
    props: IntergroupSpaceProps,
    monitor: DropTargetMonitor,
    component: GroupInstance,
  ) {
    const item = monitor.getItem<any>();
    const node = component.getNode();
    if (!node || monitor.didDrop()) {
      return;
    }
    const hoverBoundingRect = node.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
    const newGroupIndex =
      hoverClientY < hoverMiddleY ? props.index : 1 + props.index;
    if (!monitor.didDrop() && item.props.itemType === ITEM_TYPES.available) {
      props.onCreateNewGroup({
        optionId: item.props.option.id,
        newGroupIndex,
      });
    } else if (
      !monitor.didDrop() &&
      item.props.itemType === ITEM_TYPES.selected
    ) {
      props.onCreateNewGroup({
        optionId: item.props.option.id,
        newGroupIndex,
        groupId: item.props.perentGroup.groupId,
      });
    }
  },
  hover(
    props: IntergroupSpaceProps,
    monitor: DropTargetMonitor,
    component: GroupInstance,
  ) {
    const item = monitor.getItem<any>();
    if (!component) {
      return;
    }
    const node = component.getNode();
    if (!node) {
      return null;
    }

    const groupBoundingRect = node.getBoundingClientRect();
    const { bottom, top } = groupBoundingRect;
    const { y } = monitor.getClientOffset() as XYCoord;
    const isAllowedAddToNextGroup =
      props.nextGroup !== null && props.nextGroup.options.length < 5;
    const isAllowedAddToPrevGroup =
      props.previousGroup !== null && props.previousGroup.options.length < 5;
    const optionId = R.path(['props', 'option', 'id'], item);
    const optionType = R.path(['options', optionId, 'type'], props);

    // handle selected item
    if (item.props.itemType === ITEM_TYPES.selected) {
      // handle cases where a fake group is displayed
      if (bottom && top) {
        if (
          item.props.groupIndex < props.index &&
          5 + y < top &&
          isAllowedAddToPrevGroup
        ) {
          const newHoveredGroupIndex = props.index - 1;
          if (newHoveredGroupIndex === item.props.groupIndex) {
            props.onSetIsShowOriginOption(true);
          }

          props.onSetHoveredGroupIndex(newHoveredGroupIndex);
          props.onSetTempGroupIndex(null);
          // @ts-expect-error
          props.onSetHoveredOptionIndex(props.previousGroup.options.length);
          return;
        }
        if (
          item.props.groupIndex === props.index &&
          5 + y < top &&
          isAllowedAddToPrevGroup
        ) {
          const newHoveredGroupIndex = props.index - 1;
          props.onSetHoveredGroupIndex(newHoveredGroupIndex);
          props.onSetTempGroupIndex(null);
          // @ts-expect-error
          props.onSetHoveredOptionIndex(props.previousGroup.options.length);
        }
        if (y + 3 > bottom && isAllowedAddToNextGroup) {
          props.onSetHoveredOptionIndex(0);
          props.onSetHoveredGroupIndex(props.index);
          props.onSetTempGroupIndex(null);
          if (props.index === item.props.groupIndex) {
            props.onSetIsShowOriginOption(true);
          }
          return;
        }
      }
      if (props.index !== props.tempGroupIndex) {
        props.onSetIsShowOriginOption(false);
        props.onSetTempGroupIndex(props.index);
      }
    }

    // handle available item
    if (item.props.itemType === ITEM_TYPES.available) {
      // handle cases where avaliable option is Series
      if (bottom && top) {
        if (optionType === OPTIONS_TYPES.series) {
          if (y + 3 > bottom && isAllowedAddToNextGroup) {
            props.onSetHoveredOptionIndex(0);
            props.onSetHoveredGroupIndex(props.index);
            props.onSetTempGroupIndex(null);
            return;
          }
          if (9 + y < top && isAllowedAddToPrevGroup && props.index !== 0) {
            props.onSetTempGroupIndex(null);
            //@ts-expect-error
            props.onSetHoveredOptionIndex(props.previousGroup.options.length);
            props.onSetHoveredGroupIndex(props.index - 1);
            return;
          }
        }
      }
      if (props.index !== props.tempGroupIndex) {
        props.onSetIsShowOriginOption(false);
        props.onSetTempGroupIndex(props.index);
      }
    }

    //handle single option group
    if (item.props.itemType === ITEM_TYPES.singleOptionGroup) {
      if (
        item.props.index >= props.index &&
        isAllowedAddToPrevGroup &&
        props.previousGroup?.groupId !== item.props.currentGroup.groupId
      ) {
        props.onSetHoveredGroupIndex(props.index - 1);
        //@ts-expect-error
        props.onSetHoveredOptionIndex(props.previousGroup.options.length);
        props.onSetIsShowOriginOption(false);
        props.onSetIsShowDraggedGroup(false);
        return null;
      }
      if (
        props.hoveredGroupIndex !== props.index &&
        isAllowedAddToNextGroup &&
        item.props.index <= props.index
      ) {
        props.onSetHoveredGroupIndex(props.index);
        props.onSetHoveredOptionIndex(0);
        props.onSetIsShowOriginOption(false);
        props.onSetIsShowDraggedGroup(false);
        return null;
      }

      if (!props.isShowDraggedGroup) {
        props.onSetIsShowDraggedGroup(true);
      }
    }

    if (y > top && y < bottom && props.hoveredGroupIndex !== null) {
      props.onSetHoveredGroupIndex(null);
      props.onSetHoveredOptionIndex(null);
    }
  },
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

interface IntergroupSpaceProps {
  connectDropTarget: (target: any) => any;
  nextGroup: ChartOptionsGroup | null;
  previousGroup: ChartOptionsGroup | null;
  hoveredGroupIndex: number | null;
  index: number;
  isInsideAvaliableArea: boolean;
  onCreateNewGroup: (data: {
    optionId: string;
    newGroupIndex: number;
    groupId?: string;
  }) => void;
  onSetHoveredGroupIndex: (value: number | null) => void;
  onSetHoveredOptionIndex: (index: number | null) => void;
  onSetTempGroupIndex: (value: number | null) => void;
  onStopHovering: () => void;
  options: ListChartOptions;
  tempGroupIndex: number | null;
  isInsideDragArea: boolean;
  onSetIsShowOriginOption: (value: boolean) => void;
  hoveredOptionIndex: number | null;
  onSetDraggedGroupIndex: (idnex: number | null) => void;
  onSetIsShowDraggedGroup: (value: boolean) => void;
  isShowDraggedGroup: boolean;
}

const IntergroupSpace = (
  {
    connectDropTarget,
    index,
    isInsideAvaliableArea,
    tempGroupIndex,
    isInsideDragArea,
  }: IntergroupSpaceProps,
  ref,
) => {
  const elementRef = React.useRef(null);
  React.useImperativeHandle<any, GroupInstance>(ref, () => ({
    getNode: () => elementRef.current,
  }));

  const showFakeGroup =
    index === tempGroupIndex && !isInsideAvaliableArea && isInsideDragArea;

  const padding = showFakeGroup
    ? index === 0
      ? '14px 0 24px'
      : '24px 0'
    : index === 0
    ? '6px 0'
    : '11px 0';

  return connectDropTarget(
    <div>
      <IntergroupSpace.Container padding={padding}>
        <IntergroupSpace.EmptySpace
          ref={elementRef}
          show={
            index === tempGroupIndex &&
            !isInsideAvaliableArea &&
            isInsideDragArea
          }
        />
      </IntergroupSpace.Container>
    </div>,
  );
};

IntergroupSpace.Container = styled.div`
  padding: ${props => props.padding};
`;

IntergroupSpace.EmptySpace = styled.div`
  display: ${props => (props.show ? 'block' : ' none')};
  height: ${props => (props.show ? '20px' : ' 0')};
  border: 1px solid;
`;

export default DropTarget(
  ItemTypes.SERIES,
  groupTarget,
  collect,
)(React.forwardRef<HTMLDivElement, IntergroupSpaceProps>(IntergroupSpace));
