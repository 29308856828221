import { REHYDRATE } from 'redux-persist';
import { all, put, fork, take, takeLatest } from 'redux-saga/effects';

import { getGraphqlPayload } from 'store/helpers';
import { FETCH_NEWRELIC_DATA, fetchNewRelicData } from './MetricsActions';

function* appInitialized(): Generator<any, any, any> {
  yield take(REHYDRATE);
  yield put(fetchNewRelicData());
}

function setNewRelicVariables(action) {
  const data = getGraphqlPayload(action);
  window.NREUM = window.NREUM || {};
  window.NREUM.loader_config = {
    accountID: data.accId,
    trustKey: data.accId,
    agentID: data.appId,
    licenseKey: data.licenseKey,
    applicationID: data.appId,
  };
  window.NREUM.info = {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: data.licenseKey,
    applicationID: data.appId,
    sa: 1,
  };
}

function* metricsSagas(): Generator<any, any, any> {
  yield all([
    fork(appInitialized),
    takeLatest(FETCH_NEWRELIC_DATA + '_SUCCESS', setNewRelicVariables),
  ]);
}

export default metricsSagas;
