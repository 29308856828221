import { all, put, takeLatest } from 'redux-saga/effects';

import { AUTHENTICATED } from 'modules/app/AppActions';

import { fetchAllocIssueCause } from './AllocIssueCauseActions';

function* fetchAllocIssueCauseSaga(): Generator<any, any, any> {
  yield put(fetchAllocIssueCause());
}

function* allocIssueCauseSagas(): Generator<any, any, any> {
  yield all([takeLatest(AUTHENTICATED, fetchAllocIssueCauseSaga)]);
}

export default allocIssueCauseSagas;
