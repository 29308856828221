import gql from 'graphql-tag';

import type {
  Action,
  GraphqlQueryAction,
  GraphqlMutationAction,
} from 'store/models';

export const namespace = 'appConfig';
export const FETCH_APP_CONFIG = `${namespace}/FETCH_APP_CONFIG`;
export const SET_APP_CONFIG = `${namespace}/SET_APP_CONFIG`;
export const SET_APP_CONFIG_LOCALLY = `${namespace}/SET_APP_CONFIG_LOCALLY`;
export const NORMALIZE_APP_CONFIG = `${namespace}/NORMALIZE_APP_CONFIG`;

const FETCH_APP_CONFIG_QUERY = gql`
  query {
    getAppConfig {
      appConfig
    }
  }
`;

type normalizeAppConfigAC = (payload: {
  drilldownDefaultFromDate: Date;
  drilldownDefaultToDate: Date;
  today: Date;
  groupBy: boolean;
  varianceTrellis: boolean;
  todayOffset: number;
  timeSeries: boolean;
  showExternalForecast: boolean;
  capacityVsExternal: boolean;
  minSummedCapacityDateSetting: boolean;
}) => Action;

export const normalizeAppConfig: normalizeAppConfigAC = payload => ({
  type: NORMALIZE_APP_CONFIG,
  payload,
});

type fetchAppConfigAC = () => GraphqlQueryAction;

export const fetchAppConfig: fetchAppConfigAC = () => ({
  type: FETCH_APP_CONFIG,
  payload: {
    key: 'getAppConfig',
    graphql: {
      query: FETCH_APP_CONFIG_QUERY,
    },
  },
});

const SET_APP_CONFIG_MUTATION = gql`
  mutation ($data: AppConfigInput!) {
    setAppConfig(data: $data) {
      groupBy
      varianceTrellis
      timeSeries
      showExternalForecast
      capacityVsExternal
      allocationIssues
    }
  }
`;

type setAppConfigAC = (payload: {
  [key: string]: boolean;
}) => GraphqlMutationAction;

export const setAppConfig: setAppConfigAC = payload => ({
  type: SET_APP_CONFIG,
  payload: {
    key: 'setAppConfig',
    graphql: {
      mutation: SET_APP_CONFIG_MUTATION,
      variables: { data: payload },
    },
  },
});

type setAppConfigLocallyAC = (payload: { [key: string]: any }) => Action;

export const setAppConfigLocally: setAppConfigLocallyAC = payload => ({
  type: SET_APP_CONFIG_LOCALLY,
  payload,
});
