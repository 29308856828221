import { all, put, takeLatest } from 'redux-saga/effects';

import { AUTHENTICATED } from 'modules/app/AppActions';

import { fetchAllocIssueStatus } from './AllocIssueStatusActions';

function* fetchAllocIssueStatusSaga(): Generator<any, any, any> {
  yield put(fetchAllocIssueStatus());
}

function* allocIssueStatusSagas(): Generator<any, any, any> {
  yield all([takeLatest(AUTHENTICATED, fetchAllocIssueStatusSaga)]);
}

export default allocIssueStatusSagas;
