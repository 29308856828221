import { timeFormat } from 'd3-time-format';
import { LOCATION_CHANGE } from 'connected-react-router';
import { Middleware } from 'redux';

import paths from 'modules/router/utils/paths';
import * as AllocIssueActions from 'modules/allocIssue/AllocIssueActions';
import * as AppActions from 'modules/app/AppActions';
import * as AuthActions from 'modules/auth/AuthActions';
import * as CapacityActions from 'modules/capacityChangeEvent/CapacityChangeEventActions';
import * as DrilldownTableActions from 'modules/drilldownTable/DrilldownTableActions';
import * as EventNoteActions from 'modules/eventNotes/EventNotesActions';
import * as ExternalForecastActions from 'modules/externalForecast/ExternalForecastActions';
import * as FilterActions from 'modules/filter/FilterActions';
import * as NoteAttachmentActions from 'modules/noteAttachment/NoteAttachmentActions';
import * as NoteActions from 'modules/notes/NotesActions';
import * as ProductionActions from 'modules/production/ProductionActions';
import * as SeriesLayoutActions from 'modules/seriesLayouts/SeriesLayoutsActions';
import * as UiActions from 'modules/ui/UIActions';
import * as InboxConversationActions from 'modules/inboxConversation/InboxConversationActions';
import * as VarianceEventActions from 'modules/varianceEvent/VarianceEventActions';
import * as MetricsActions from 'modules/metrics/MetricsActions';
import { USER_ACTION_IDS, USER_ACTION_NAMES } from 'modules/metrics/constants';
import type { Action } from 'store/models';

const dateFormat = timeFormat('%m/%d/%Y');

const newRelicMiddleware: Middleware =
  (store: any) => (next: any) => (action: Action) => {
    if (!window.newrelic || window.location.host.includes('localhost')) {
      return next(action);
    }

    const state = store.getState();
    const userMetadata = {
      ...state.auth,
      userName: `${state.auth.firstName} ${state.auth.lastName}`,
    };
    userMetadata.token = null;

    let actionId = USER_ACTION_IDS.UNKNOWN;
    let actionMetadata = {};

    switch (action.type) {
      case AppActions.AUTHENTICATED:
        actionId = USER_ACTION_IDS.USER_LOGIN;
        window.newrelic.setCustomAttribute('userId', userMetadata.userId);
        window.newrelic.setCustomAttribute('userName', userMetadata.userName);
        break;
      case AuthActions.LOGOUT:
        actionId = USER_ACTION_IDS.USER_LOGOUT;
        window.newrelic.setCustomAttribute('userId', null);
        window.newrelic.setCustomAttribute('userName', null);
        break;
      case AllocIssueActions.CREATE_REMOTE_ALLOC_ISSUE:
        actionId = USER_ACTION_IDS.ALLOC_ISSUE_CREATE;
        break;
      case AllocIssueActions.DELETE_ALLOC_ISSUE:
        actionId = USER_ACTION_IDS.ALLOC_ISSUE_DELETE;
        break;
      case AllocIssueActions.UPDATE_REMOTE_ALLOC_ISSUE:
        actionId = USER_ACTION_IDS.ALLOC_ISSUE_UPDATE;
        break;
      case CapacityActions.CREATE_REMOTE_CAPACITY:
        actionId = USER_ACTION_IDS.CAPACITY_CHANGE_CREATE;
        break;
      case CapacityActions.DELETE_REMOTE_CAPACITY:
        actionId = USER_ACTION_IDS.CAPACITY_CHANGE_DELETE;
        break;
      case CapacityActions.UPDATE_REMOTE_CAPACITY:
        actionId = USER_ACTION_IDS.CAPACITY_CHANGE_UPDATE;
        break;
      case DrilldownTableActions.SET_DRILLDOWN_TABLE_PARAMS:
        actionId = USER_ACTION_IDS.UI_SET_DRILLDOWN_TABLE_PARAMS;
        actionMetadata = action.payload;
        break;
      case DrilldownTableActions.SET_MIN_DRILLDOWN_TABLE_DATE:
        actionId = USER_ACTION_IDS.UI_SET_DRILLDOWN_TABLE_DATE_MIN;
        actionMetadata = { minDate: action.payload };
        break;
      case DrilldownTableActions.SET_MAX_DRILLDOWN_TABLE_DATE:
        actionId = USER_ACTION_IDS.UI_SET_DRILLDOWN_TABLE_DATE_MAX;
        actionMetadata = { maxDate: action.payload };
        break;
      case DrilldownTableActions.SET_DRILLDOWN_TABLE_PHASE:
        actionId = USER_ACTION_IDS.UI_SET_DRILLDOWN_TABLE_PHASE;
        actionMetadata = { phase: action.payload };
        break;
      case DrilldownTableActions.CONFIGURE_SORTING:
        actionId = USER_ACTION_IDS.UI_SET_DRILLDOWN_TABLE_SORT_DIRECTION;
        actionMetadata = action.payload;
        break;
      case DrilldownTableActions.INIT_CLEAR_DRILLDOWN_TABLE:
        actionId = USER_ACTION_IDS.UI_REFRESH_DRILLDOWN_TABLE;
        break;
      case EventNoteActions.CREATE_EVENT_NOTE_REMOTELY:
        actionId = USER_ACTION_IDS.EVENT_NOTE_CREATE;
        break;
      case EventNoteActions.REMOVE_EVENT_NOTE_REMOTELY:
        actionId = USER_ACTION_IDS.EVENT_NOTE_DELETE;
        break;
      case EventNoteActions.UPDATE_EVENT_NOTE_REMOTELY:
        actionId = USER_ACTION_IDS.EVENT_NOTE_UPDATE;
        break;
      case ExternalForecastActions.CHANGE_VISIBILITY_STATUS:
        actionId = USER_ACTION_IDS.UI_TOGGLE_EXTERNAL_FORECAST;
        break;
      case FilterActions.SET_FILTER:
        actionId = USER_ACTION_IDS.UI_SET_FILTER;
        actionMetadata = action.payload;
        break;
      case FilterActions.ADD_SEVERAL_FILTERS:
        actionId = USER_ACTION_IDS.UI_SET_FILTER;
        actionMetadata = action.payload;
        break;
      case FilterActions.REMOVE_FILTER:
        actionId = USER_ACTION_IDS.UI_REMOVE_FILTER;
        actionMetadata = action.payload;
        break;
      case FilterActions.CLEAR_FILTER:
        actionId = USER_ACTION_IDS.UI_REMOVE_FILTER;
        actionMetadata = { filterName: action.payload };
        break;
      case FilterActions.CLEAR_ALL_FILTERS:
        actionId = USER_ACTION_IDS.UI_CLEAR_FILTERS;
        break;
      case NoteAttachmentActions.UPLOAD_NOTE_ATTACHMENT:
        actionId = USER_ACTION_IDS.NOTE_ATTACHMENT_UPLOAD;
        break;
      case NoteAttachmentActions.DELETE_NOTE_ATTACHMENT:
        actionId = USER_ACTION_IDS.NOTE_ATTACHMENT_DELETE;
        break;
      case NoteActions.ADD_NOTE:
        actionId = USER_ACTION_IDS.WELL_NOTE_CREATE;
        break;
      case NoteActions.REMOVE_NOTE:
        actionId = USER_ACTION_IDS.WELL_NOTE_DELETE;
        break;
      case NoteActions.UPDATE_NOTE:
        actionId = USER_ACTION_IDS.WELL_NOTE_UPDATE;
        break;
      case ProductionActions.FETCH_WELL_PRODUCTION:
        actionId = USER_ACTION_IDS.WELL_LOAD_PRODUCTION;
        const actionPayload = action.payload.graphql.variables.payload;
        actionMetadata = {
          wellId: actionPayload.wellId,
          fromDate: dateFormat(actionPayload.minDate),
          toDate: dateFormat(actionPayload.maxDate),
        };
        break;
      case SeriesLayoutActions.CREATE_SERIES_LAYOUT_REMOTELY:
        actionId = USER_ACTION_IDS.SERIES_LAYOUT_CREATE;
        break;
      case SeriesLayoutActions.REMOVE_SERIES_LAYOUT_REMOTELY:
        actionId = USER_ACTION_IDS.SERIES_LAYOUT_DELETE;
        break;
      case SeriesLayoutActions.SET_CURRENT_SERIES_LAYOUT:
        actionId = USER_ACTION_IDS.SERIES_LAYOUT_LOAD;
        break;
      case UiActions.OPEN_SHORTCUT_PANEL:
        actionId = USER_ACTION_IDS.UI_TOGGLE_SHORTCUT_PANEL;
        break;
      case UiActions.OPEN_IMAGE_DETAILS_OVERLAY:
        actionId = USER_ACTION_IDS.NOTE_ATTACHMENT_VIEW;
        break;
      case UiActions.ENABLE_NOTES_MODE:
        actionId = USER_ACTION_IDS.UI_VIEW_NOTES;
        break;
      case UiActions.ENABLE_REGION_OF_INTEREST_MODE:
        actionId = USER_ACTION_IDS.UI_SET_REGION_OF_INTEREST;
        break;
      case UiActions.ENABLE_ZOOM_IN_MODE:
        actionId = USER_ACTION_IDS.UI_ZOOM_IN;
        break;
      case UiActions.SET_LEFT_PANEL_ACTIVE_TAB:
        if (action.payload === 'menu') {
          actionId = USER_ACTION_IDS.UI_VIEW_LOGO_MENU;
        } else if (action.payload === 'drilldown') {
          actionId = USER_ACTION_IDS.UI_VIEW_DRILLDOWN;
        } else if (action.payload === 'notes') {
          actionId = USER_ACTION_IDS.UI_VIEW_NOTES;
        }
        break;
      case UiActions.DISABLE_GROUP_MODE:
        actionId = USER_ACTION_IDS.UI_SET_CURRENT_GROUP;
        actionMetadata = { groupBy: 'well' };
        break;
      case UiActions.SET_CURRENT_GROUP:
        actionId = USER_ACTION_IDS.UI_SET_CURRENT_GROUP;
        actionMetadata = { groupBy: action.payload.subject };
        break;
      case InboxConversationActions.SET_ACTIVE_FILTER:
        actionId = USER_ACTION_IDS.UI_SET_INBOX_FILTER;
        actionMetadata = { filterName: action.payload };
        break;
      case InboxConversationActions.MARK_AS_READ:
        actionId = USER_ACTION_IDS.MARK_NOTES_AS_READ;
        actionMetadata = { notes: action.payload?.graphql?.variables?.payload };
        break;
      case InboxConversationActions.MARK_AS_UNREAD:
        actionId = USER_ACTION_IDS.MARK_NOTES_AS_UNREAD;
        actionMetadata = { notes: action.payload?.graphql?.variables?.payload };
        break;
      case InboxConversationActions.ARCHIVE:
        actionId = USER_ACTION_IDS.ARCHIVE_NOTES;
        actionMetadata = { notes: action.payload?.graphql?.variables?.payload };
        break;
      case InboxConversationActions.GO_TO_PAGE:
        actionId = USER_ACTION_IDS.UI_SET_INBOX_PAGE;
        actionMetadata = { page: action.payload };
        break;
      case VarianceEventActions.CREATE_REMOTE_VARIANCE_EVENT:
        actionId = USER_ACTION_IDS.VARIANCE_EVENT_CREATE;
        break;
      case VarianceEventActions.DELETE_REMOTE_VARIANCE_EVENT:
        actionId = USER_ACTION_IDS.VARIANCE_EVENT_DELETE;
        break;
      case VarianceEventActions.UPDATE_REMOTE_VARIANCE_EVENT:
        actionId = USER_ACTION_IDS.VARIANCE_EVENT_UPDATE;
        break;
      case DrilldownTableActions.SET_CURRENT_VARIANCE_OPTION:
        actionId = USER_ACTION_IDS.SET_VARIANCE_CATEGORY_FILTER;
        actionMetadata = action.payload;
        break;
      case DrilldownTableActions.ADD_MARKED_ROW:
        actionId = USER_ACTION_IDS.UI_DRILLDOWN_MARK_ROW;
        actionMetadata = { wellName: action.payload };
        break;
      case DrilldownTableActions.MARK_ALL_ROWS:
        actionId = USER_ACTION_IDS.UI_DRILLDOWN_MARK_ALL_ROWS;
        break;
      case DrilldownTableActions.ADD_SEVERAL_MARKED_ROWS:
        actionId = USER_ACTION_IDS.UI_DRILLDOWN_MARK_ROWS;
        actionMetadata = { wellNames: action.payload };
        break;
      case DrilldownTableActions.REMOVE_MARKED_ROW:
        actionId = USER_ACTION_IDS.UI_DRILLDOWN_UNMARK_ROW;
        actionMetadata = { wellName: action.payload };
        break;
      case LOCATION_CHANGE: {
        const path = action.payload.location.pathname;
        if (path === paths.BACKLOG()) {
          actionId = USER_ACTION_IDS.UI_VIEW_ALLOCATION_ISSUE_BACKLOG;
        } else if (path === paths.SPOTFIRE_DASHBOARDS()) {
          actionId = USER_ACTION_IDS.UI_VIEW_SPOTFIRE_DASHBOARDS;
        } else {
          return next(action);
        }
        break;
      }
      case MetricsActions.TRACK_EVENT:
        actionId = action.payload.id;
        actionMetadata = action.payload.data || {};
        break;
      default:
        return next(action);
    }

    window.newrelic.addPageAction(USER_ACTION_NAMES[actionId], {
      actionId: actionId,
      userMetadata: userMetadata,
      actionMetadata: actionMetadata,
    });

    return next(action);
  };

export default newRelicMiddleware;
