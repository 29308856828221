import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginViaSSO } from '../AuthActions';
import { getIsAuthenticated } from '../AuthReducer';

export const AuthCallback = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);

  React.useEffect(() => {
    const match = /(?<=code=).+&??/.exec(window.location.search);
    if (!match) return;

    dispatch(loginViaSSO({ code: match[0] }));
  }, []);

  return <>{isAuthenticated && <Redirect to="/dashboard" />}</>;
};
