import React from 'react';
import styled from 'styled-components';

import DragHandle from 'components/DragHandle';
import { CommonScrollbar } from 'components/CommonScrollbar';

type ResizableCellProps = {
  children: React.ReactNode;
  idx?: number;
  isHorizontal?: boolean;
  isDragging?: boolean;
  isOpen?: boolean;
  onSeparatorMouseDown?: () => void;
  onHeaderClick?: () => void;
  minSize?: number;
  maxSize?: number;
  title: string;
  subtitle?: string;
  headerHeight?: number;
  headerBorders?: 'both' | 'bottom' | 'top' | 'none';
  size?: number;
};

const ResizableCell = ({
  isHorizontal,
  isOpen,
  isDragging,
  onSeparatorMouseDown,
  onHeaderClick,
  idx,
  minSize,
  maxSize,
  title,
  subtitle,
  children,
  headerHeight = 32,
  headerBorders = 'both',
  size,
}: ResizableCellProps) => {
  const isFirst = idx === 0;
  const min = (minSize || 0) + (headerHeight || 0);
  const max = maxSize;
  const minSizeProp = isHorizontal ? 'minWidth' : 'minHeight';
  const maxSizeProp = isHorizontal ? 'maxWidth' : 'maxHeight';
  const style = { [minSizeProp]: size, [maxSizeProp]: max, 'flex-basis': size };

  const headerBordersStyle = React.useMemo(() => {
    const border = '1px solid #c1c1c1';

    if (headerBorders === 'both')
      return `border-bottom: ${border}; border-top: ${border};`;
    else if (headerBorders === 'bottom') return `border-bottom: ${border};`;
    else if (headerBorders === 'top') return `border-top: ${border};`;

    return 'border: 0;';
  }, [headerBorders]);

  return (
    <ResizableCell.Wrapper
      data-min-size={min}
      data-max-size={max}
      style={style}
    >
      {!isFirst && (
        <DragHandle
          data={{ idx }}
          onMouseDown={onSeparatorMouseDown}
          isDragging={isDragging}
        />
      )}

      <ResizableCell.Header
        height={headerHeight}
        isOpen={isOpen}
        onClick={onHeaderClick}
        data-idx={idx}
        borders={headerBordersStyle}
      >
        <ResizableCell.TitleIcon isOpen={isOpen} src="/sortIndicator.svg" />
        <ResizableCell.TitleContainer>
          <ResizableCell.Title isOpen={isOpen}>{title}</ResizableCell.Title>
          {subtitle && (
            <ResizableCell.Subtitle>{subtitle}</ResizableCell.Subtitle>
          )}
        </ResizableCell.TitleContainer>
      </ResizableCell.Header>

      <ResizableCell.Inner
        headerHeight={headerHeight}
        isHorizontal={isHorizontal}
      >
        <CommonScrollbar>{children}</CommonScrollbar>
      </ResizableCell.Inner>
    </ResizableCell.Wrapper>
  );
};

ResizableCell.Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 100%;
`;

ResizableCell.Inner = styled.div`
  &,
  > div {
    height: ${props =>
      props.isHorizontal ? '100%' : `calc(100% - ${props.headerHeight}px)`};
    width: ${props =>
      props.isHorizontal ? `calc(100% - ${props.headerHeight}px)` : '100%'};
  }
`;

ResizableCell.Header = styled.div`
  display: flex;
  align-items: center;
  height: ${({ height }) => (height ? height + 'px' : 'auto')};
  padding: 0 4px;
  cursor: pointer;
  ${({ borders }) => borders}

  background: #efefef;
`;

ResizableCell.TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

ResizableCell.TitleIcon = styled.img`
  width: 10px;
  height: 10px;
  margin: 0 8px;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '90deg')});
`;

ResizableCell.Title = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin: 0 8px 0 4px;
  white-space: nowrap;
`;

ResizableCell.Subtitle = styled.div`
  font-size: 12px;
  color: #909090;
  white-space: nowrap;
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ResizableCell;
