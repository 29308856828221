import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import { changeVisibilityStatus } from 'modules/externalForecast/ExternalForecastActions';
import { getForecastStatus } from 'modules/externalForecast/ExternalForecastReducer';

const useForecast = () => {
  const dispatch = useDispatch();

  const appConfig = useSelector(getAppConfig);
  const isVisible = useSelector(getForecastStatus);
  const isAvalableToShow = Boolean(appConfig.showExternalForecast);

  const setVisibility = React.useCallback(
    (status: boolean) => {
      dispatch(changeVisibilityStatus({ status: isAvalableToShow && status }));
    },
    [dispatch, isAvalableToShow],
  );

  const setVisibilityOn = React.useCallback(
    () => setVisibility(true),
    [setVisibility],
  );

  const setVisibilityOff = React.useCallback(
    () => setVisibility(false),
    [setVisibility],
  );

  const toggleVisibility = React.useCallback(
    () => setVisibility(!isVisible),
    [isVisible, setVisibility],
  );

  React.useEffect(() => {
    if (!isAvalableToShow) setVisibilityOff();
  }, [isAvalableToShow, setVisibilityOff]);

  return {
    isVisible,
    isAvalableToShow,
    setVisibility,
    setVisibilityOn,
    setVisibilityOff,
    toggleVisibility,
  };
};

export default useForecast;
