import { all, put, takeLatest } from 'redux-saga/effects';

import { AUTHENTICATED } from 'modules/app/AppActions';

import { fetchPhases } from './PhaseActions';

function* fetchPhasesSaga(): Generator<any, any, any> {
  yield put(fetchPhases());
}

function* phaseSagas(): Generator<any, any, any> {
  yield all([takeLatest(AUTHENTICATED, fetchPhasesSaga)]);
}

export default phaseSagas;
