import { utcDay } from 'd3-time';
import * as React from 'react';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import { getMinDate, getMaxDate, isIdNew } from 'helpers';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';

interface AllocIssueDividerProps {
  allocIssue: AllocIssue;
  extremeAllocDates: Date[];
  finishDrag: () => void;
  height: number;
  isAxisDragging: boolean;
  issueIndex: number;
  isDragging: boolean;
  isEditable: boolean;
  leftOffset: number;
  onAllocIssueUpdate: ({ updatedIssue: AllocIssue, data: Object }) => void;
  onLocalAllocIssueUpdate: ({ updatedIssue: AllocIssue, data: Object }) => void;
  onAllocIssueDividerHover: (issueId: string) => void;
  onAllocIssueDialogOpen: ({ index: number, id: string }) => void;
  onHighlightAllocIssueDividerOff: () => void;
  startDrag: () => void;
  xScale: any;
}

const AllocIssueDivider = ({
  allocIssue,
  extremeAllocDates,
  finishDrag,
  height,
  isAxisDragging,
  issueIndex,
  isEditable,
  isDragging,
  leftOffset,
  onAllocIssueDialogOpen,
  onAllocIssueDividerHover,
  onHighlightAllocIssueDividerOff,
  onAllocIssueUpdate,
  onLocalAllocIssueUpdate,
  startDrag,
  xScale,
}: AllocIssueDividerProps) => {
  const beginIssueStartDrag = (
    e: React.MouseEvent<SVGLineElement, MouseEvent>,
  ) => {
    if (!allocIssue.id || isIdNew(allocIssue.id)) return;
    if (isEditable) {
      document.addEventListener('mousemove', proceedIssueStartDrag);
      document.addEventListener('mouseup', finishIssueStartDrag);
      startDrag();
      onAllocIssueDialogOpen({
        index: issueIndex,
        id: allocIssue.id,
      });
    } else {
      onAllocIssueDialogOpen({
        index: issueIndex,
        id: allocIssue.id,
      });
    }
  };

  const proceedIssueStartDrag = React.useCallback(
    (e: MouseEvent) => {
      const fixedDateOfIssue = allocIssue.dateEnd;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = utcDay.round(xScale.invert(pointerPosition));
      const adjustedDate =
        fixedDateOfIssue && fixedDateOfIssue > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeAllocDates[0])
          : getMinDate(dateOfCursor, extremeAllocDates[1]);
      const newDay = utcDay.round(adjustedDate);

      onLocalAllocIssueUpdate({
        updatedIssue: allocIssue,
        data: {
          dateStart: getMinDate(fixedDateOfIssue, newDay),
          dateEnd: getMaxDate(fixedDateOfIssue, newDay),
        },
      });
    },
    [
      allocIssue,
      extremeAllocDates,
      onLocalAllocIssueUpdate,
      xScale,
      leftOffset,
    ],
  );

  const finishIssueStartDrag = React.useCallback(
    (e: MouseEvent) => {
      document.removeEventListener('mousemove', proceedIssueStartDrag);
      document.removeEventListener('mouseup', finishIssueStartDrag);
      finishDrag();
      const fixedDateOfIssue = allocIssue.dateEnd;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = utcDay.round(xScale.invert(pointerPosition));
      const adjustedDate =
        fixedDateOfIssue && fixedDateOfIssue > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeAllocDates[0])
          : getMinDate(dateOfCursor, extremeAllocDates[1]);
      const newDay = utcDay.round(adjustedDate);
      onAllocIssueUpdate({
        updatedIssue: allocIssue,
        data: {
          dateStart: newDay,
        },
      });
    },
    [
      allocIssue,
      extremeAllocDates,
      finishDrag,
      onAllocIssueUpdate,
      proceedIssueStartDrag,
      xScale,
      leftOffset,
    ],
  );

  const beginIssueEndDrag = (
    e: React.MouseEvent<SVGLineElement, MouseEvent>,
  ) => {
    if (!allocIssue.id || isIdNew(allocIssue.id)) return;
    if (isEditable) {
      document.addEventListener('mousemove', proceedIssueEndDrag);
      document.addEventListener('mouseup', finishIssueEndDrag);
      startDrag();
      onAllocIssueDialogOpen({
        index: issueIndex,
        id: allocIssue.id,
      });
    } else {
      onAllocIssueDialogOpen({
        index: issueIndex,
        id: allocIssue.id,
      });
    }
  };

  const proceedIssueEndDrag = React.useCallback(
    (e: MouseEvent) => {
      const fixedDateOfIssue = allocIssue.dateStart;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = utcDay.round(
        utcDay.offset(xScale.invert(pointerPosition), -1),
      );
      const adjustedDate =
        fixedDateOfIssue && fixedDateOfIssue > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeAllocDates[0])
          : getMinDate(dateOfCursor, extremeAllocDates[1]);
      const newDay = utcDay.round(adjustedDate);
      onLocalAllocIssueUpdate({
        updatedIssue: allocIssue,
        data: {
          dateStart: getMinDate(fixedDateOfIssue, newDay),
          dateEnd: getMaxDate(fixedDateOfIssue, newDay),
        },
      });
    },
    [
      extremeAllocDates,
      allocIssue,
      onLocalAllocIssueUpdate,
      xScale,
      leftOffset,
    ],
  );

  const finishIssueEndDrag = React.useCallback(
    (e: MouseEvent) => {
      document.removeEventListener('mousemove', proceedIssueEndDrag);
      document.removeEventListener('mouseup', finishIssueEndDrag);
      finishDrag();
      const fixedDateOfIssue = allocIssue.dateStart;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = utcDay.round(
        utcDay.offset(xScale.invert(pointerPosition), -1),
      );
      const adjustedDate =
        fixedDateOfIssue && fixedDateOfIssue > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeAllocDates[0])
          : getMinDate(dateOfCursor, extremeAllocDates[1]);
      const newDay = utcDay.round(adjustedDate);

      onAllocIssueUpdate({
        updatedIssue: allocIssue,
        data: {
          dateEnd: newDay,
        },
      });
    },
    [
      allocIssue,
      extremeAllocDates,
      finishDrag,
      onAllocIssueUpdate,
      proceedIssueEndDrag,
      xScale,
      leftOffset,
    ],
  );

  React.useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', proceedIssueEndDrag);
      document.removeEventListener('mousemove', proceedIssueStartDrag);
      document.removeEventListener('mouseup', finishIssueEndDrag);
      document.removeEventListener('mouseup', finishIssueStartDrag);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <g>
      <line
        className={`issueDragLine alloc-interactive interactive ${
          isEditable ? '' : 'panInteraction'
        }`}
        cursor={
          isAxisDragging ? 'grabbing' : isEditable ? 'ew-resize' : 'pointer'
        }
        onMouseDown={e => isEditable && beginIssueStartDrag(e)}
        onMouseUp={e => {
          !isEditable && beginIssueEndDrag(e);
        }}
        onMouseEnter={() =>
          isDragging ? null : onAllocIssueDividerHover(allocIssue.id)
        }
        onMouseLeave={() =>
          isDragging ? null : onHighlightAllocIssueDividerOff()
        }
        stroke={process.env.DEBUG ? 'rgba(120, 150, 50, 0.5)' : 'transparent'}
        strokeWidth="15"
        x1={xScale(allocIssue.dateStart)}
        x2={xScale(allocIssue.dateStart)}
        y1={0}
        y2={height}
        vectorEffect="non-scaling-stroke"
      />
      <line
        className={`issueDragLine alloc-interactive interactive ${
          isEditable ? '' : 'panInteraction'
        }`}
        cursor={
          isAxisDragging ? 'grabbing' : isEditable ? 'ew-resize' : 'pointer'
        }
        onMouseUp={e => {
          !isEditable && beginIssueEndDrag(e);
        }}
        onMouseDown={e => isEditable && beginIssueEndDrag(e)}
        onMouseEnter={e =>
          isDragging ? null : onAllocIssueDividerHover(allocIssue.id)
        }
        onMouseLeave={() =>
          isDragging ? null : onHighlightAllocIssueDividerOff()
        }
        stroke={process.env.DEBUG ? 'rgba(120, 150, 50, 0.5)' : 'transparent'}
        strokeWidth="15"
        x1={xScale(utcDay.offset(allocIssue.dateEnd, 1))}
        x2={xScale(utcDay.offset(allocIssue.dateEnd, 1))}
        y1={0}
        y2={height}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  );
};

export default React.memo<AllocIssueDividerProps>(AllocIssueDivider);
