export interface EventNote {
  id: string;
  eventType: string;
  eventId: string;
  userId: string;
  userOverwrite: string | null;
  noteTimestamp: Date;
  noteText: string;
  isEdited: boolean;
  attachments: any;
}

export interface WellEventNotes {
  variance: { [id: string]: EventNote };
  ribbon: { [id: string]: EventNote };
  acllocation: { [id: string]: EventNote };
  capacity: { [id: string]: EventNote };
}

export const extensionsRegex =
  /([a-zA-Z0-9\s_\\.\-():])+(.bmp|.jpg|.jpeg|.png)$/i;
