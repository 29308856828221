import * as R from 'ramda';
import { filterActions } from 'redux-ignore';

import type { Action } from 'store/models';
import { getGraphqlPayload } from 'store/helpers';

import { SHARE_SETTINGS, CLEAR_SHARE_ID } from './SettingsActions';

const SHARE_SETTINGS_SUCCESS = `${SHARE_SETTINGS}_SUCCESS`;
export const STATE_KEY = 'settings';

interface SettingsState {
  outgoingShareId: string;
}

export const initialState = {} as SettingsState;

const SettingsReducer = (
  state: SettingsState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case SHARE_SETTINGS_SUCCESS: {
      const settings = getGraphqlPayload(action);

      return { outgoingShareId: settings.id };
    }
    case `${CLEAR_SHARE_ID}`: {
      return { outgoingShareId: null };
    }
    default: {
      return state;
    }
  }
};

export const getShareId = R.path<string | null>([STATE_KEY, 'outgoingShareId']);

export default filterActions(SettingsReducer, [
  CLEAR_SHARE_ID,
  SHARE_SETTINGS_SUCCESS,
]);
