import React from 'react';

type ClickOutsideProps = {
  action: () => any;
  className?: string;
  children: React.ReactNode;
};

const ClickOutside = ({ action, className, children }: ClickOutsideProps) => {
  const wrapper = React.useRef(null);

  const handleClickOutside = React.useCallback(
    (e: MouseEvent) => {
      const wrapperEl = wrapper?.current as HTMLElement | null;
      const targetEl = e.target as HTMLElement;
      if (!wrapperEl || wrapperEl.contains(targetEl)) return;
      action();
    },
    [wrapper, action],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  return (
    <div ref={wrapper} className={className}>
      {children}
    </div>
  );
};

export default ClickOutside;
