import * as R from 'ramda';
import { filterActions } from 'redux-ignore';

import { getGraphqlPayload } from 'store/helpers';

import type { Phase } from 'modules/phase/models/phase';

import { FETCH_PHASES } from './PhaseActions';
import { Action } from 'store/models';

export const STATE_KEY = 'phase';
const FETCH_PHASES_SUCCESS = `${FETCH_PHASES}_SUCCESS`;
export type PhaseState = Phase[];

const initialState: PhaseState = [];

const PhaseReducer = (state: PhaseState = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_PHASES_SUCCESS: {
      const phases = getGraphqlPayload(action);
      return phases;
    }
    default: {
      return state;
    }
  }
};

export const getPhasesByName = (_: Record<string, any>) =>
  R.compose(
    R.indexBy<PhaseState, Phase>(R.prop('phaseName')),
    R.propOr([], STATE_KEY),
  )(_);
export const getPhasesById = (_: Record<string, any>) =>
  R.compose(
    R.indexBy<PhaseState, Phase>(R.prop('id')),
    R.propOr([], STATE_KEY),
  )(_);

export default filterActions(PhaseReducer, [FETCH_PHASES_SUCCESS]);
