import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import {
  NOTES_PER_PAGE,
  PAGE_ANCHOR_OFFSET,
  PAGE_LATERAL_OFFSET,
} from '../models';
import { createRange } from '../utils';
import { getPaginatedLatestEventNotes } from '../InboxConversationReducer';

interface PageCounterProps {
  availablePages: number;
  allNotesCount: number;
  paginatedNotesCount: number;
  currentPage: number;
  onSelectPage: (page: number) => void;
}

export const PageCounter = ({
  availablePages,
  allNotesCount,
  paginatedNotesCount,
  currentPage,
  onSelectPage,
}: PageCounterProps) => {
  const paginatedEventNotes = useSelector(getPaginatedLatestEventNotes);

  const notesIndicator = React.useMemo(() => {
    const from = (currentPage - 1) * NOTES_PER_PAGE + 1;
    const to = from + paginatedNotesCount - 1;

    return `${from} - ${to}`;
  }, [paginatedNotesCount, currentPage]);

  const pages = React.useMemo<(number | 'gap')[]>(() => {
    if (availablePages <= PAGE_ANCHOR_OFFSET)
      return createRange(1, availablePages);

    const endOffset = availablePages - currentPage;
    const startOffset = currentPage - 1;
    const output: (number | 'gap')[] = [1];

    if (startOffset > PAGE_ANCHOR_OFFSET) {
      output.push(
        'gap',
        ...createRange(
          currentPage -
            Math.max(PAGE_ANCHOR_OFFSET - endOffset, PAGE_LATERAL_OFFSET),
          currentPage,
        ),
      );
    } else {
      output.push(...createRange(2, currentPage));
    }

    if (endOffset <= PAGE_ANCHOR_OFFSET) {
      output.push(...createRange(currentPage + 1, availablePages));
    } else {
      output.push(
        ...createRange(
          currentPage + 1,
          currentPage +
            Math.max(PAGE_ANCHOR_OFFSET - startOffset - 1, PAGE_LATERAL_OFFSET),
        ),
        'gap',
        availablePages,
      );
    }

    return output;
  }, [availablePages, currentPage]);

  return (
    <PageCounter.PageCounterContainer>
      {paginatedEventNotes?.length !== 0 && (
        <>
          <PageCounter.PageCounter>
            <PageCounter.Arrow
              onClick={() => onSelectPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon
                color={currentPage === 1 ? '#c1c1c1' : '#484848'}
                icon={faChevronLeft}
              />
            </PageCounter.Arrow>

            <PageCounter.Pages>
              {pages.map(page =>
                page === 'gap' ? (
                  <PageCounter.Gap>...</PageCounter.Gap>
                ) : (
                  <PageCounter.Page
                    isSelected={page === currentPage}
                    itExtremePage={page === 1 || page === availablePages}
                    onClick={() => onSelectPage(page)}
                  >
                    {page}
                  </PageCounter.Page>
                ),
              )}
            </PageCounter.Pages>

            <PageCounter.Arrow
              onClick={() => onSelectPage(currentPage + 1)}
              disabled={currentPage === availablePages}
            >
              <FontAwesomeIcon
                color={currentPage === availablePages ? '#c1c1c1' : '#484848'}
                icon={faChevronRight}
              />
            </PageCounter.Arrow>
          </PageCounter.PageCounter>
          <PageCounter.PageInfo>{`${notesIndicator} of ${allNotesCount}`}</PageCounter.PageInfo>
        </>
      )}
    </PageCounter.PageCounterContainer>
  );
};

PageCounter.PageCounterContainer = styled.div`
  height: 40px;
  border-top: 1px solid #c1c1c1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 9px;
`;

PageCounter.PageCounter = styled.div`
  display: flex;
  align-items: center;
`;
PageCounter.PageInfo = styled.div`
  font-family: 'Lato', sans-serif;
  color: #909090;
  font-size: 14px;
`;

PageCounter.Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${({ isSelected }) => (isSelected ? '#484848' : 'initial')};
  color: ${({ isSelected }) => (isSelected ? '#ffffff' : '#484848')};
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
`;

PageCounter.Gap = styled(PageCounter.Page)`
  color: #c1c1c1;
  cursor: initial;
`;

PageCounter.Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

PageCounter.Pages = styled.div`
  display: flex;
  margin: 0 8px;
`;
