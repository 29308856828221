import InputCheckbox from 'components/InputCheckbox';
import useDrilldownTableState from 'modules/drilldownTable/hooks/useDrilldownTableState';
import useForecast from '../hooks/useForecast';

const ForecastCheckbox = () => {
  const forecast = useForecast();
  const drilldown = useDrilldownTableState();

  if (!forecast.isAvalableToShow) return null;

  return (
    <InputCheckbox
      name="re-forecast"
      checked={forecast.isVisible}
      onChange={forecast.toggleVisibility}
      disabled={!drilldown.isComparingActual}
      label="RE Forecast"
      marginBottom={0}
    />
  );
};

export default ForecastCheckbox;
