import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useNonInputKeydown } from 'hooks/useKeydown';
import { getCurrentWellId } from 'modules/ui/UIReducer';
import {
  getCustomSelectedFiltersLayoutsOptions,
  getSelectedFiltersLayoutsOptionsToDisplay,
  getWellCustomAttributesValues,
} from 'modules/filterLayouts/FilterLayoutsReducer';
import {
  updateWellCustomAttributeValues,
  createWellCustomAttributeValues,
} from 'modules/filterLayouts/FilterLayoutsActions';
import { getWellsAsArray } from 'modules/well/WellReducer';
import FiltersLayoutsDropdown from 'modules/filterLayouts/container/FiltersLayoutsDropdown';
import ExtraInputs from 'modules/category/forms/ExtraInputs';
import RightPanel from 'modules/dashboard/components/RightPanel';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
import { joinCustomAttributesToWells } from 'modules/filter/utils/customAttributes';

type CustomAttrsPayload<T extends string | number> = {
  wellId: string;
  wellCustomAttributeId: T;
  value: string;
}[];

const WellInfoPanel = () => {
  const dispatch = useDispatch();
  const rightPanel = useRightPanel();

  const wells = useSelector(getWellsAsArray);
  const wellId = useSelector(getCurrentWellId);
  const customValues = useSelector(getWellCustomAttributesValues, R.equals);
  const customAttributes = useSelector(
    getCustomSelectedFiltersLayoutsOptions,
    R.equals,
  );
  const selectedFilterLayoutsOptionsToDisplay = useSelector(
    getSelectedFiltersLayoutsOptionsToDisplay,
    R.equals,
  );

  const wellInputs = React.useMemo(() => {
    return selectedFilterLayoutsOptionsToDisplay.map(option => {
      return {
        label: option.displayName,
        name: option.filterName,
        readOnly: !option.id.includes('custom'),
      };
    });
  }, [selectedFilterLayoutsOptionsToDisplay]);

  const wellData = React.useMemo(() => {
    const attrs = customAttributes;
    const values = customValues;
    const allWells = joinCustomAttributesToWells(wells, attrs, values);
    const data = allWells.find(well => well.id === wellId);
    return data;
  }, [wells, customAttributes, customValues, wellId]);

  const handleWellInputsSubmit = React.useCallback(
    data => {
      if (!wellData) return;

      const attrsToUpdate: CustomAttrsPayload<string> = [];
      const attrsToCreate: CustomAttrsPayload<number> = [];

      selectedFilterLayoutsOptionsToDisplay.forEach(option => {
        const id = option.id.split('custom')[1];
        if (isNaN(id)) return;

        const value = data[option.filterName] ?? '';
        const isOptionExisted = wellData[option.filterName] !== undefined;
        const wellCustomAttributeId = isOptionExisted ? id : parseInt(id);
        const attrData = { wellId, wellCustomAttributeId, value };

        if (isOptionExisted) attrsToUpdate.push(attrData);
        else attrsToCreate.push(attrData);
      });

      if (attrsToUpdate.length) {
        dispatch(updateWellCustomAttributeValues(attrsToUpdate));
      }

      if (attrsToCreate.length) {
        dispatch(createWellCustomAttributeValues(attrsToCreate));
      }
    },
    [wellId, wellData, selectedFilterLayoutsOptionsToDisplay],
  );

  useNonInputKeydown(
    ({ keyName }) => {
      if (!rightPanel.isDialogOfType('WellInfo')) return;
      if (keyName === 'ESCAPE') return rightPanel.unsetDialog();
    },
    [rightPanel.unsetDialog, rightPanel.isDialogOfType],
  );

  if (!rightPanel.isDialogOfType('WellInfo')) return null;

  return (
    <RightPanel
      onDialogClose={rightPanel.unsetDialog}
      title="Well Information"
      isShown
    >
      <div>
        <WellInfoPanel.DropdownWrapper>
          <FiltersLayoutsDropdown />
        </WellInfoPanel.DropdownWrapper>

        <WellInfoPanel.InputsWrapper>
          {Boolean(wellData) && (
            <ExtraInputs
              inputs={wellInputs}
              data={wellData}
              onSubmit={handleWellInputsSubmit}
            />
          )}
        </WellInfoPanel.InputsWrapper>
      </div>
    </RightPanel>
  );
};

WellInfoPanel.DropdownWrapper = styled.div`
  padding: 9px 16px;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.05);
`;

WellInfoPanel.InputsWrapper = styled.div`
  margin-top: 10px;
  padding: 9px 16px;
`;
export default WellInfoPanel;
