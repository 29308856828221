import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import { getTextColorFromBgColor } from 'helpers';

import { OIL } from 'modules/phase/models/phase';

import Pattern from 'components/Pattern';
import { normalizeProductionLost } from '../utils/normalizeProductionLose';

const SPACE_RE = /\s/g;

const getRowTextMap = (rowData, titleName, planType?) => {
  const planTypeSuffix = planType ? ', ' + planType : '';
  if (!rowData.matched || (rowData.matched && R.isEmpty(rowData.matched)))
    return {
      beforeUnderlined: rowData[titleName],
      underlined: '',
      afterUnderlined: planTypeSuffix,
    };
  const { matched } = rowData;
  const title = rowData[titleName];
  const beforeUnderlined = title
    .substring(0, matched[0])
    .replace(SPACE_RE, String.fromCharCode(160));
  const underlined = title
    .substring(matched[0], matched[1])
    .replace(SPACE_RE, String.fromCharCode(160));
  const afterUnderlined =
    title.substring(matched[1]).replace(SPACE_RE, String.fromCharCode(160)) +
    planTypeSuffix;

  return { beforeUnderlined, underlined, afterUnderlined };
};

interface VarianceTableRowProps {
  className: string;
  bgColor?: string;
  index: number;
  onRowClick: (row: {
    event: MouseEvent;
    index: number;
    rowData: Record<string, any>;
  }) => void;
  rowData: Record<string, any>;
  style: { [prop: string]: string };
  firstColDataKey: string;
  secondColDataKey: string;
  thirdColDataKey: string;
  fourthColDataKey: string;
  optionColumns: string[];
  hasColorEffects?: boolean;
  isHighlighted?: boolean;
  format: (format: number) => string;
  wide: boolean;
  isTotalRow: boolean;
  columnWidths: { [key: string]: number };
  explainTitle: string;
}

const VarianceTableRow = ({
  className,
  bgColor,
  index,
  isHighlighted,
  onRowClick,
  rowData,
  style,
  firstColDataKey,
  secondColDataKey,
  thirdColDataKey,
  fourthColDataKey,
  optionColumns,
  hasColorEffects,
  format,
  wide,
  isTotalRow,
  columnWidths,
  explainTitle,
}: VarianceTableRowProps) => {
  const valueRowText =
    rowData.planType && rowData.planType !== 'Unknown'
      ? getRowTextMap(rowData, firstColDataKey, rowData.planType)
      : getRowTextMap(rowData, firstColDataKey);

  const clickHandler = !isTotalRow && onRowClick ? onRowClick : R.F;
  return (
    <div
      aria-rowindex={index}
      aria-label="row"
      tabIndex={0}
      className={className}
      role="row"
      style={style}
      onClick={event => clickHandler({ event, index, rowData })}
    >
      <VarianceTableRow.FirstCol
        aria-colindex="1"
        className="ReactVirtualized__Table__rowColumn"
        role="gridcell"
        title={rowData[firstColDataKey]}
        bgColor={bgColor}
      >
        {
          <>
            {rowData.subCause === 'Allocation Issue' && <Pattern />}
            <span>{valueRowText.beforeUnderlined}</span>
            <VarianceTableRow.SearchResult>
              {valueRowText.underlined}
            </VarianceTableRow.SearchResult>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {valueRowText.afterUnderlined}
            </span>
          </>
        }
      </VarianceTableRow.FirstCol>

      {optionColumns.includes(secondColDataKey) && (
        <VarianceTableRow.SecondCol
          aria-colindex="2"
          className="ReactVirtualized__Table__rowColumn"
          role="gridcell"
          title={isTotalRow ? '100' : rowData[secondColDataKey]}
          isHighlighted={isHighlighted}
          width={columnWidths[secondColDataKey]}
        >
          {isTotalRow ? '100 %' : `${rowData[secondColDataKey]} %`}
        </VarianceTableRow.SecondCol>
      )}
      {optionColumns.includes(thirdColDataKey) && (
        <VarianceTableRow.ThirdCol
          aria-colindex="2"
          className="ReactVirtualized__Table__rowColumn"
          role="gridcell"
          title={explainTitle}
          isHighlighted={isHighlighted}
          width={columnWidths[thirdColDataKey]}
        >
          {normalizeProductionLost(rowData[thirdColDataKey])}
        </VarianceTableRow.ThirdCol>
      )}

      <VarianceTableRow.FourthCol
        aria-colindex="2"
        className="ReactVirtualized__Table__rowColumn"
        role="gridcell"
        title={rowData[fourthColDataKey]}
        hasColorEffects={hasColorEffects}
        isHighlighted={isHighlighted}
        positive={rowData[fourthColDataKey] > 0}
        wide={wide}
      >
        {format(rowData[fourthColDataKey])}
      </VarianceTableRow.FourthCol>
    </div>
  );
};

export default VarianceTableRow;

VarianceTableRow.Col = styled.div`
  display: flex;
  overflow: hidden;
  font-size: 12px;
  align-items: center;
`;

VarianceTableRow.FirstCol = styled(VarianceTableRow.Col)`
  flex: 1 1 200px;
  background: ${(props: Record<string, any>) => props.bgColor};
  color: ${(props: Record<string, any>) =>
    getTextColorFromBgColor(props.bgColor)};
  position: relative;
  & > span {
    position: relative;
    z-index: 20;
  }
`;

VarianceTableRow.SecondCol = styled(VarianceTableRow.Col)`
  flex: 0 0 ${({ width }) => width ?? 65}px;
  margin-right: 0px !important;
  padding-right: 8px;
`;

VarianceTableRow.ThirdCol = styled(VarianceTableRow.Col)`
  flex: 0 0 ${({ width }) => width ?? 65}px;
  margin-right: 0px !important;
  padding-right: 8px;
`;

VarianceTableRow.FourthCol = styled(VarianceTableRow.Col)`
  flex: 0 0 ${(props: Record<string, any>) => (props.wide ? '70' : '55')}px;
  font-weight: ${(props: Record<string, any>) =>
    props.hasColorEffects ? (props.positive ? 'normal' : 'bold') : 'normal'};
  color: ${(props: Record<string, any>) =>
    props.hasColorEffects
      ? props.positive
        ? props.theme.colors[OIL]
        : props.theme.colors.criticalText
      : 'inherit'};
`;

VarianceTableRow.SearchResult = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;

VarianceTableRow.Span = styled.span`
  white-space: pre;
`;

VarianceTableRow.SearchResult = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;
