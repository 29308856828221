// Do not alter IDS of existing actions. They are used after importing into the data warehouse
export const USER_ACTION_IDS = {
  UNKNOWN: 0,
  // User Actions [100-199]
  USER_LOGIN: 100,
  USER_LOGOUT: 101,
  // UI Actions [200-299]
  UI_VIEW_LOGO_MENU: 200,
  UI_VIEW_ALLOCATION_ISSUE_BACKLOG: 201,
  UI_VIEW_ACCOUNT_SETTINGS: 202,
  UI_TOGGLE_SHORTCUT_PANEL: 203,
  UI_TOGGLE_EXTERNAL_FORECAST: 204,
  UI_SET_CURRENT_GROUP: 205,
  UI_SET_FILTER: 206,
  UI_REMOVE_FILTER: 207,
  UI_CLEAR_FILTERS: 208,
  UI_VIEW_NOTES: 209,
  UI_SET_REGION_OF_INTEREST: 210,
  UI_ZOOM_IN: 211,
  UI_SET_DRILLDOWN_TABLE_PARAMS: 212,
  UI_SET_DRILLDOWN_TABLE_PHASE: 213,
  UI_SET_DRILLDOWN_TABLE_DATE_MIN: 214,
  UI_SET_DRILLDOWN_TABLE_DATE_MAX: 215,
  UI_SET_DRILLDOWN_TABLE_SORT_DIRECTION: 216,
  UI_REFRESH_DRILLDOWN_TABLE: 217,
  UI_VIEW_DRILLDOWN: 218,
  UI_VIEW_SPOTFIRE_DASHBOARDS: 219,
  UI_OPEN_SPOTFIRE_DASHBOARD: 220,
  UI_VIEW_SPOTFIRE_DASHBOARD: 221,
  UI_SET_INBOX_FILTER: 222,
  UI_SET_INBOX_PAGE: 223,
  UI_DRILLDOWN_MARK_ROW: 224,
  UI_DRILLDOWN_MARK_ROWS: 225,
  UI_DRILLDOWN_MARK_ALL_ROWS: 226,
  UI_DRILLDOWN_UNMARK_ROW: 227,
  // UI Shortcut Actions [300-399]
  UI_SHORTCUT_CREATE_CAPACITY_CHANGE_EVENT: 300,
  UI_SHORTCUT_CREATE_VARIANCE_EVENT: 301,
  UI_SHORTCUT_CREATE_ALLOCATION_ISSUE: 302,
  UI_SHORTCUT_ZOOM_ALL: 303,
  UI_SHORTCUT_VIEW_NOTES: 304,
  UI_SHORTCUT_OPEN_ZOOM_MENU: 305,
  UI_SHORTCUT_OPEN_TRELLIS_MENU: 306,
  UI_SHORTCUT_ZOOM_IN: 307,
  UI_SHORTCUT_ZOOM_OUT: 308,
  UI_SHORTCUT_RESTORE_ZOOM: 309,
  UI_SHORTCUT_TOGGLE_SHORTCUT_PANEL: 310,
  UI_SHORTCUT_ZOOM_3_MONTHS: 311,
  UI_SHORTCUT_ZOOM_6_MONTHS: 312,
  UI_SHORTCUT_ZOOM_12_MONTHS: 313,
  UI_SHORTCUT_ZOOM_24_MONTHS: 314,
  UI_SHORTCUT_TOGGLE_ALL_TRELLISES: 315,
  UI_SHORTCUT_TOGGLE_BOE_TRELLIS: 316,
  UI_SHORTCUT_TOGGLE_OIL_TRELLIS: 317,
  UI_SHORTCUT_TOGGLE_GAS_TRELLIS: 318,
  UI_SHORTCUT_TOGGLE_GOR_TRELLIS: 319,
  UI_SHORTCUT_TOGGLE_WATER_TRELLIS: 320,
  UI_SHORTCUT_TOGGLE_WATERCUT_TRELLIS: 321,
  UI_SHORTCUT_TOGGLE_GLR_TRELLIS: 322,
  UI_SHORTCUT_TOGGLE_VARIANCE_TRELLIS: 323,
  // Allocation Issue Actions [400-499]
  ALLOC_ISSUE_CREATE: 400,
  ALLOC_ISSUE_DELETE: 401,
  ALLOC_ISSUE_UPDATE: 402,
  // Capacity Change Event Actions [500-599]
  CAPACITY_CHANGE_CREATE: 500,
  CAPACITY_CHANGE_DELETE: 501,
  CAPACITY_CHANGE_UPDATE: 502,
  // Note Actions [600-699]
  EVENT_NOTE_CREATE: 600,
  EVENT_NOTE_DELETE: 601,
  EVENT_NOTE_UPDATE: 602,
  WELL_NOTE_CREATE: 603,
  WELL_NOTE_DELETE: 604,
  WELL_NOTE_UPDATE: 605,
  NOTE_ATTACHMENT_UPLOAD: 606,
  NOTE_ATTACHMENT_DELETE: 607,
  NOTE_ATTACHMENT_VIEW: 608,
  MARK_NOTES_AS_READ: 609,
  MARK_NOTES_AS_UNREAD: 610,
  ARCHIVE_NOTES: 611,

  // Variance Event Actions [700-799]
  VARIANCE_EVENT_CREATE: 700,
  VARIANCE_EVENT_DELETE: 701,
  VARIANCE_EVENT_UPDATE: 702,
  SET_VARIANCE_CATEGORY_FILTER: 703,
  // Series Layout Actions [800-899]
  SERIES_LAYOUT_CREATE: 800,
  SERIES_LAYOUT_DELETE: 801,
  SERIES_LAYOUT_LOAD: 802,
  // Misc Actions [9000-9999]
  WELL_LOAD_PRODUCTION: 9000,
};

export const USER_ACTION_NAMES = {
  [USER_ACTION_IDS.UNKNOWN]: 'Unknown',
  [USER_ACTION_IDS.USER_LOGIN]: 'Log In',
  [USER_ACTION_IDS.USER_LOGOUT]: 'Log Out',
  [USER_ACTION_IDS.ALLOC_ISSUE_CREATE]: 'Create Allocation Issue',
  [USER_ACTION_IDS.ALLOC_ISSUE_DELETE]: 'Delete Allocation Issue',
  [USER_ACTION_IDS.ALLOC_ISSUE_UPDATE]: 'Update Allocation Issue',
  [USER_ACTION_IDS.CAPACITY_CHANGE_CREATE]: 'Create Capacity Change Event',
  [USER_ACTION_IDS.CAPACITY_CHANGE_DELETE]: 'Delete Capacity Change Event',
  [USER_ACTION_IDS.CAPACITY_CHANGE_UPDATE]: 'Update Capacity Change Event',
  [USER_ACTION_IDS.EVENT_NOTE_CREATE]: 'Create Event Note',
  [USER_ACTION_IDS.EVENT_NOTE_DELETE]: 'Delete Event Note',
  [USER_ACTION_IDS.EVENT_NOTE_UPDATE]: 'Update Event Note',
  [USER_ACTION_IDS.WELL_NOTE_CREATE]: 'Create Well Note',
  [USER_ACTION_IDS.WELL_NOTE_DELETE]: 'Delete Well Note',
  [USER_ACTION_IDS.WELL_NOTE_UPDATE]: 'Update Well Note',
  [USER_ACTION_IDS.NOTE_ATTACHMENT_UPLOAD]: 'Upload Note Attachment',
  [USER_ACTION_IDS.NOTE_ATTACHMENT_DELETE]: 'Delete Note Attachment',
  [USER_ACTION_IDS.NOTE_ATTACHMENT_VIEW]: 'View Note Attachment Image',
  [USER_ACTION_IDS.UI_TOGGLE_EXTERNAL_FORECAST]: 'Toggle External Forecast',
  [USER_ACTION_IDS.UI_VIEW_LOGO_MENU]: 'View Logo Menu',
  [USER_ACTION_IDS.UI_VIEW_DRILLDOWN]: 'View Drilldown',
  [USER_ACTION_IDS.UI_VIEW_SPOTFIRE_DASHBOARDS]: 'View Spotfire Dashboards',
  [USER_ACTION_IDS.UI_OPEN_SPOTFIRE_DASHBOARD]: 'Open Spotfire Dashboard',
  [USER_ACTION_IDS.UI_VIEW_SPOTFIRE_DASHBOARD]: 'View Spotfire Dashboard',
  [USER_ACTION_IDS.UI_SET_INBOX_FILTER]: 'Set Inbox Filter',
  [USER_ACTION_IDS.UI_SET_INBOX_PAGE]: 'Go to Inbox Page',
  [USER_ACTION_IDS.UI_TOGGLE_SHORTCUT_PANEL]: 'Toggle Shortcut Panel',
  [USER_ACTION_IDS.UI_VIEW_NOTES]: 'View Well Notes',
  [USER_ACTION_IDS.UI_SET_CURRENT_GROUP]: 'Set Current Group',
  [USER_ACTION_IDS.UI_SET_FILTER]: 'Set Drilldown Filter',
  [USER_ACTION_IDS.UI_REMOVE_FILTER]: 'Remove Drilldown Filter',
  [USER_ACTION_IDS.UI_CLEAR_FILTERS]: 'Clear Drilldown Filters',
  [USER_ACTION_IDS.UI_SET_REGION_OF_INTEREST]: 'Set Region of Interest',
  [USER_ACTION_IDS.UI_ZOOM_IN]: 'Zoom In',
  [USER_ACTION_IDS.UI_VIEW_ALLOCATION_ISSUE_BACKLOG]:
    'View Allocation Issue Backlog',
  [USER_ACTION_IDS.UI_VIEW_ACCOUNT_SETTINGS]: 'View Account Settings',
  [USER_ACTION_IDS.UI_REFRESH_DRILLDOWN_TABLE]: 'Refresh Drilldown Table',
  [USER_ACTION_IDS.UI_SET_DRILLDOWN_TABLE_PARAMS]:
    'Set Drilldown Table Parameters',
  [USER_ACTION_IDS.UI_SET_DRILLDOWN_TABLE_PHASE]: 'Set Drilldown Table Phase',
  [USER_ACTION_IDS.UI_SET_DRILLDOWN_TABLE_DATE_MIN]:
    'Set Drilldown Table Min Date',
  [USER_ACTION_IDS.UI_SET_DRILLDOWN_TABLE_DATE_MAX]:
    'Set Drilldown Table Max Date',
  [USER_ACTION_IDS.UI_SET_DRILLDOWN_TABLE_SORT_DIRECTION]:
    'Set Drilldown Table Sort Direction',
  [USER_ACTION_IDS.UI_DRILLDOWN_MARK_ROW]: 'Mark Drilldown Row',
  [USER_ACTION_IDS.UI_DRILLDOWN_MARK_ROWS]: 'Mark Multiple Drilldown Rows',
  [USER_ACTION_IDS.UI_DRILLDOWN_MARK_ALL_ROWS]: 'Mark All Drilldown Rows',
  [USER_ACTION_IDS.UI_DRILLDOWN_UNMARK_ROW]: 'Unmark Drilldown Row',
  [USER_ACTION_IDS.UI_SHORTCUT_CREATE_CAPACITY_CHANGE_EVENT]:
    'Used shortcut to Create Capacity Change Event',
  [USER_ACTION_IDS.UI_SHORTCUT_CREATE_VARIANCE_EVENT]:
    'Used shortcut to Create Variance Event',
  [USER_ACTION_IDS.UI_SHORTCUT_CREATE_ALLOCATION_ISSUE]:
    'Used shortcut to Create Allocation Issue',
  [USER_ACTION_IDS.UI_SHORTCUT_VIEW_NOTES]: 'Used shortcut to open Notes',
  [USER_ACTION_IDS.UI_SHORTCUT_OPEN_ZOOM_MENU]:
    'Used shortcut to open Zoom Menu',
  [USER_ACTION_IDS.UI_SHORTCUT_OPEN_TRELLIS_MENU]:
    'Used shortcut to open Trellis Menu',
  [USER_ACTION_IDS.UI_SHORTCUT_TOGGLE_SHORTCUT_PANEL]:
    'Used shortcut to Show Shortcuts Panel',
  [USER_ACTION_IDS.UI_SHORTCUT_ZOOM_ALL]: 'Used shortcut to Zoom to All',
  [USER_ACTION_IDS.UI_SHORTCUT_ZOOM_IN]: 'Used shortcut to Zoom In',
  [USER_ACTION_IDS.UI_SHORTCUT_ZOOM_OUT]: 'Used shortcut to Zoom Out',
  [USER_ACTION_IDS.UI_SHORTCUT_RESTORE_ZOOM]: 'Used shortcut to Restore Zoom',
  [USER_ACTION_IDS.UI_SHORTCUT_ZOOM_3_MONTHS]:
    'Used shortcut to Zoom to Prior 3 Months',
  [USER_ACTION_IDS.UI_SHORTCUT_ZOOM_6_MONTHS]:
    'Used shortcut to Zoom to Prior 6 Months',
  [USER_ACTION_IDS.UI_SHORTCUT_ZOOM_12_MONTHS]:
    'Used shortcut to Zoom to Prior 12 Months',
  [USER_ACTION_IDS.UI_SHORTCUT_ZOOM_24_MONTHS]:
    'Used shortcut to Zoom to Prior 24 Months',
  [USER_ACTION_IDS.UI_SHORTCUT_TOGGLE_ALL_TRELLISES]:
    'Used shortcut to Toggle all Trellises',
  [USER_ACTION_IDS.UI_SHORTCUT_TOGGLE_BOE_TRELLIS]:
    'Used shortcut to Toggle BOE Trellis',
  [USER_ACTION_IDS.UI_SHORTCUT_TOGGLE_OIL_TRELLIS]:
    'Used shortcut to Toggle Oil Trellis',
  [USER_ACTION_IDS.UI_SHORTCUT_TOGGLE_GAS_TRELLIS]:
    'Used shortcut to Toggle Gas Trellis',
  [USER_ACTION_IDS.UI_SHORTCUT_TOGGLE_GOR_TRELLIS]:
    'Used shortcut to Toggle GOR Trellis',
  [USER_ACTION_IDS.UI_SHORTCUT_TOGGLE_WATER_TRELLIS]:
    'Used shortcut to Toggle Water Trellis',
  [USER_ACTION_IDS.UI_SHORTCUT_TOGGLE_WATERCUT_TRELLIS]:
    'Used shortcut to Toggle Watercut Trellis',
  [USER_ACTION_IDS.UI_SHORTCUT_TOGGLE_GLR_TRELLIS]:
    'Used shortcut to Toggle GLR Trellis',
  [USER_ACTION_IDS.UI_SHORTCUT_TOGGLE_VARIANCE_TRELLIS]:
    'Used shortcut to Toggle Variance Trellis',
  [USER_ACTION_IDS.VARIANCE_EVENT_CREATE]: 'Create Variance Event',
  [USER_ACTION_IDS.VARIANCE_EVENT_DELETE]: 'Delete Variance Event',
  [USER_ACTION_IDS.VARIANCE_EVENT_UPDATE]: 'Update Variance Event',
  [USER_ACTION_IDS.SET_VARIANCE_CATEGORY_FILTER]:
    'Filtered to Variance Category',
  [USER_ACTION_IDS.MARK_NOTES_AS_READ]: 'Mark Notes as Read',
  [USER_ACTION_IDS.MARK_NOTES_AS_UNREAD]: 'Mark Notes as Unread',
  [USER_ACTION_IDS.ARCHIVE_NOTES]: 'Archive Notes',
  [USER_ACTION_IDS.WELL_LOAD_PRODUCTION]: 'Load Production Data for Well',
  [USER_ACTION_IDS.SERIES_LAYOUT_CREATE]: 'Create Series Data Layout',
  [USER_ACTION_IDS.SERIES_LAYOUT_DELETE]: 'Delete Series Data Layout',
  [USER_ACTION_IDS.SERIES_LAYOUT_LOAD]: 'Load Series Data Layout',
};
