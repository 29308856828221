import * as React from 'react';
import * as R from 'ramda';
import { CapacityVarianceContext } from './CapacityVarianceContext';
import { Phase } from './model';
import { utcHour } from 'd3-time';

export const useCapacityData = () => {
  const context = React.useContext(CapacityVarianceContext);

  if (!context)
    throw new Error(
      'CapacityVarianceContext must be used within a CapacityVarianceProvider!',
    );

  const getAll = React.useCallback(() => context.capacity, [context.capacity]);

  const getAllByPhase = React.useCallback(
    (phase: Phase, cutByLastDate = false) => {
      const capacity = context.capacity[phase];
      if (!capacity || capacity.length === 0) return [];

      if (!cutByLastDate) return capacity;

      const minDate =
        context.lastDate.getTime() > context.limitDate.getTime()
          ? context.limitDate
          : context.lastDate;

      let capacityIndex = -1;
      let lastDateIndex = -1;

      for (let i = 0; i < capacity.length; i++) {
        const segment = capacity[i];
        if (segment.length === 0 || segment[0].date > minDate) continue;

        capacityIndex = i;
        lastDateIndex = segment.findIndex(
          c => c.date.getTime() === minDate.getTime(),
        );
        break;
      }

      if (Math.max(capacityIndex, lastDateIndex) === -1) return capacity;

      return capacity.map((c, idx) => {
        if (idx < capacityIndex) return [];

        if (idx === capacityIndex) return c.slice(0, lastDateIndex + 1);

        return c;
      });
    },
    [context.capacity, context.lastDate, context.limitDate],
  );

  const getSingleByPhase = React.useCallback(
    (phase: Phase, eventId: string) => {
      const index = context.capacityEvents.findIndex(e => e.id === eventId);
      const capacity = context.capacity[phase];

      if (
        index < 0 ||
        !capacity ||
        capacity.length === 0 ||
        capacity.length !== context.capacityEvents.length
      )
        return [];
      return context.capacity[phase][index];
    },
    [context.capacity, context.capacityEvents],
  );

  const getVarianceAreas = React.useCallback(
    ({
      phase,
      removeLastDays = false,
      removeFirstDays = false,
    }: {
      phase?: Phase;
      removeLastDays?: boolean;
      removeFirstDays?: boolean;
    } = {}) => {
      const capacity = getAllByPhase(
        phase ?? (context.drilldownPhase as Phase),
      );

      const capacityWithoutLastDays = [...capacity]
        .reverse()
        .map((data, i, arr) => {
          const newDate = [...data];
          if (removeLastDays && i !== arr.length - 1) newDate.pop();
          if (removeFirstDays && i !== 0) newDate.shift();
          return newDate;
        });

      const flatCapacity = R.flatten(capacityWithoutLastDays);
      const minDate = flatCapacity[0]?.date;

      if (!minDate) return [];

      const sliceIntervals = context.varianceEvents.map((e, idx, arr) => [
        utcHour.count(minDate, e.dayStart) / 12,
        utcHour.count(minDate, utcHour.offset(e.dayEnd, 36)) / 12,
      ]);

      return sliceIntervals.map(([from, to]) => {
        if (from < 0 && to < 0) return [];
        if (from < 0) {
          const nullCount = Math.abs(from) + 1;
          const nullCapacity = Array.from({ length: nullCount }, (_, idx) => ({
            date: utcHour.offset(minDate, -12 * (nullCount - idx - 1)),
            capacity: null,
          }));
          return nullCapacity.concat(flatCapacity.slice(0, to));
        }

        return flatCapacity.slice(from, to);
      });
    },
    [context.capacity, context.varianceEvents, context.drilldownPhase],
  );

  const getEventById = React.useCallback(
    (eventId: string) => context.capacityEvents.find(e => e.id === eventId),
    [context.capacityEvents],
  );

  const getCapacityEvents = React.useCallback(
    () => context.capacityEvents,
    [context.capacityEvents],
  );

  const selectors = React.useMemo(
    () => ({
      getAll,
      getAllByPhase,
      getEventById,
      getSingleByPhase,
      getVarianceAreas,
      getCapacityEvents,
    }),
    [
      getAll,
      getAllByPhase,
      getEventById,
      getSingleByPhase,
      getVarianceAreas,
      getCapacityEvents,
    ],
  );

  return selectors;
};
