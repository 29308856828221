import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import { getGraphqlPayload } from 'store/helpers';

import type { Action, Selector } from 'store/models';
import type { Category } from 'modules/category/models/category';

import {
  FETCH_CAPACITY_CATEGORIES,
  FETCH_VARIANCE_CATEGORIES,
} from './CategoryActions';
const FETCH_CAPACITY_CATEGORIES_SUCCESS = `${FETCH_CAPACITY_CATEGORIES}_SUCCESS`;
const FETCH_VARIANCE_CATEGORIES_SUCCESS = `${FETCH_VARIANCE_CATEGORIES}_SUCCESS`;
export const STATE_KEY = 'category';

export type CategoryState = {
  capacity: { [id: string]: Category };
  variance: { [id: string]: Category };
};

const initialState = {
  capacity: {},
  variance: {},
};

const CategoryReducer = (
  state: CategoryState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case FETCH_CAPACITY_CATEGORIES_SUCCESS: {
      const rawCategories = getGraphqlPayload(action);
      const normalizedCategories = rawCategories.map(cat =>
        R.assoc('extraInputsType', JSON.parse(cat.extraInputsType), cat),
      );
      const categoriesById = R.indexBy(R.prop('id'), normalizedCategories);

      return R.assoc<{ [id: string]: Category }, CategoryState>(
        'capacity',
        categoriesById,
        state,
      );
    }
    case FETCH_VARIANCE_CATEGORIES_SUCCESS: {
      const rawCategories = getGraphqlPayload(action);
      const normalizedCategories = rawCategories.map(cat =>
        R.assoc('extraInputsType', JSON.parse(cat.extraInputsType), cat),
      );
      const categoriesById = R.indexBy(R.prop('id'), normalizedCategories);

      return R.assoc<{ [id: string]: Category }, CategoryState>(
        'variance',
        categoriesById,
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export const getAllCategories = (state: Record<string, any>) =>
  state[STATE_KEY];

export const getCapacityCategories = (state: Record<string, any>) =>
  state[STATE_KEY].capacity || {};

export const getVarianceCategories = (state: Record<string, any>) =>
  state[STATE_KEY].variance || {};

export const getCapacityCategoriesOptions: Selector<Record<string, any>> =
  createSelector(getCapacityCategories, capacityCategories =>
    R.values(capacityCategories).reduce((acc, category) => {
      const { planType, cause } = category;
      if (!acc[cause]) acc[cause] = {};
      if (!acc[cause][planType]) acc[cause][planType] = [];
      acc[cause][planType].push(category);

      return acc;
    }, {}),
  );

export const getDefaultCapacityCategoryId: Selector<string> = createSelector(
  getCapacityCategories,
  capacityCategoriesById => {
    const unknownCategory = R.values(capacityCategoriesById).find(
      category =>
        category.subCause === 'Unknown' || category.subCause === 'unknown',
    );

    return unknownCategory ? unknownCategory.id : '1';
  },
);

export const getVarianceCategoriesOptions: Selector<Record<string, any>> =
  createSelector(getVarianceCategories, varianceCategories =>
    R.values(varianceCategories).reduce((acc, category) => {
      const { planType, cause } = category;
      if (!acc[cause]) acc[cause] = {};
      if (!acc[cause][planType]) acc[cause][planType] = [];
      acc[cause][planType].push(category);

      return acc;
    }, {}),
  );

export const getDefaultVarianceCategoryId = (_: Record<string, any>) => {
  const varianceCategories = R.pathOr({}, [STATE_KEY, 'variance'], _);
  const unknownCategory = R.values(varianceCategories).find(
    category =>
      category.subCause === 'Unknown' || category.subCause === 'unknown',
  );

  return unknownCategory ? unknownCategory.id : '9';
};

export const getVarianceCategoriesColors: Selector<{
  [categoryId: string]: string;
}> = createSelector(getVarianceCategories, varianceCategories =>
  R.keys(varianceCategories).reduce((acc, categoryId) => {
    acc[categoryId] = varianceCategories[categoryId].color;

    return acc;
  }, {}),
);

export default filterActions(CategoryReducer, [
  FETCH_VARIANCE_CATEGORIES_SUCCESS,
  FETCH_CAPACITY_CATEGORIES_SUCCESS,
]);
