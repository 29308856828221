import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';

import useComponentSize from 'hooks/useComponentSize';

import { DataSeriesTooltipData, TooltipData } from 'modules/ui/models/ui';

import { Series, NormalizedSeriesMapping, NormalizedSeries } from '../models';
import SeriesBars from './SeriesBars';
import SeriesStepsLine from './SeriesStepsLine';
import { BAR_CHART, ListChartOptions } from 'modules/chartOptions/models';
import SeriesSelectedBar from './SeriesSelectedBar';
import { utcMinute } from 'd3-time';
import { getSensorSeriesAvailableDates } from '../SeriesReducer';

interface SVGSeriesTrellisProps {
  chartOptions: ListChartOptions;
  groupOptions: string[];
  height: number;
  isAxisDragging: boolean;
  onSetTooltipData: (tooltipData: DataSeriesTooltipData | null) => void;
  series: {
    data: NormalizedSeries[];
    sensor: NormalizedSeries[];
    joined: NormalizedSeries[];
  };
  seriesMapping: NormalizedSeriesMapping;
  showLine: boolean;
  dataMap: { [id: string]: Series[] };
  tooltipData: TooltipData;
  xScale: any;
  yAxisLinePos: number | null;
  yScale: any;
}

const SVGSeriesTrellis = ({
  chartOptions,
  dataMap,
  groupOptions,
  height,
  isAxisDragging,
  onSetTooltipData,
  series,
  seriesMapping,
  showLine,
  tooltipData,
  xScale,
  yAxisLinePos,
  yScale,
}: SVGSeriesTrellisProps) => {
  const svgEl: React.RefObject<HTMLElement | null> = React.useRef(null);
  const svgBoundingRect = useComponentSize(svgEl);
  const availableSensorSeriesDates = useSelector(getSensorSeriesAvailableDates);

  const getSeriesData = React.useCallback(
    (id: string) => {
      if (id.startsWith('s')) {
        const filtered = series.sensor.filter(s => s[id] !== undefined);
        const lastDay = filtered[filtered.length - 1];
        if (lastDay)
          //@ts-expect-error
          filtered.push({ ...lastDay, day: utcMinute.offset(lastDay.day, 1) });
        return filtered;
      }

      return series.data;
    },
    [seriesMapping, series],
  );

  return (
    <svg
      //@ts-expect-error
      ref={svgEl}
      className="trellis_chart series"
      height="100%"
      preserveAspectRatio="none"
      viewBox={`0 0 ${xScale.range()[1]} ${height}`}
      width={xScale.range()[1] - 1}
      shapeRendering="geometricPrecision"
      style={{ cursor: isAxisDragging ? 'grabbing' : 'auto' }}
    >
      {series &&
        !R.isEmpty(series) &&
        groupOptions.map(id =>
          chartOptions[id].chartType === BAR_CHART ? (
            <SeriesBars
              key={id}
              data={getSeriesData(id)}
              currentSeriesMapping={seriesMapping[id]}
              customColor={chartOptions[id].customColor}
              xScale={xScale}
              yScale={yScale}
            />
          ) : (
            <SeriesStepsLine
              key={id}
              data={getSeriesData(id)}
              currentSeriesMapping={seriesMapping[id]}
              customColor={chartOptions[id].customColor}
              xScale={xScale}
              yScale={yScale}
            />
          ),
        )}
      {yAxisLinePos && (
        <line
          stroke="#c42424"
          strokeWidth={1}
          x1={0}
          x2={xScale.range()[1]}
          y1={yAxisLinePos}
          y2={yAxisLinePos}
          vectorEffect="non-scaling-stroke"
        />
      )}
      {showLine && (
        <line
          stroke="#000000"
          strokeWidth={1}
          x1={0}
          x2={xScale.range()[1]}
          y1={yScale(0)}
          y2={yScale(0)}
        />
      )}
      <SeriesSelectedBar
        chartOptions={chartOptions}
        dataMap={dataMap}
        groupOptions={groupOptions}
        onSetTooltipData={onSetTooltipData}
        seriesMapping={seriesMapping}
        svgBoundingRect={svgBoundingRect}
        tooltipData={tooltipData}
        xScale={xScale}
        yScale={yScale}
        availableSensorSeriesDates={availableSensorSeriesDates}
      />
    </svg>
  );
};

export default React.memo<SVGSeriesTrellisProps>(SVGSeriesTrellis);
