import { HotTable } from '@handsontable/react';
import * as R from 'ramda';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { HandsontablelicenseKey } from 'config/AppConfig';
import { CELL_HEIGHT } from 'modules/drilldownTable/models/drilldownTable';
import {
  createWellCustomAttributeValues,
  removeWellCustomAttributeValues,
  updateWellCustomAttributeValues,
} from 'modules/filterLayouts/FilterLayoutsActions';
import { LayoutOption } from 'modules/filterLayouts/models';

import {
  ALLOWED_CELL_ACTIONS,
  CellInputData,
  MutationType,
  StretchType,
} from '../../models/filter';
import Handsontable from 'handsontable';
import {
  removeFilters,
  replaceFiltersValues,
} from 'modules/filter/FilterActions';

const ARROW_DOWN_KEY = 40;
const ARROW_UP_KEY = 38;

interface FiltersHotTableProps {
  colWidths: number[];
  countedValues: {
    [key: string]: { value: string; quantity: number }[];
  };
  dataToDisplay: { [key: string]: string }[];
  filters: { [key: string]: string[] };
  selectedFilterLayoutsOptionsToDisplay: LayoutOption[];
  onMouseOver: (e: MouseEvent, coords: any, td: any) => void;
  sortBy: { column: string; order: 'asc' | 'desc' } | null;
  resetSorting: () => void;
  setRowsOrder: React.Dispatch<React.SetStateAction<string[] | null>>;
  onMouseOut: () => void;
}

const FiltersHotTable = (
  {
    colWidths,
    countedValues,
    dataToDisplay,
    filters,
    selectedFilterLayoutsOptionsToDisplay,
    sortBy,
    resetSorting,
    setRowsOrder,
    onMouseOver,
    onMouseOut,
  }: FiltersHotTableProps,
  ref,
) => {
  const dispatch = useDispatch();

  const disabledCellRenderer = React.useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.renderers.TextRenderer.apply(instance, [
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
      if (prop.includes('custom')) return;

      td.style.background = '#FAFAFA';
    },
    [],
  );

  const selectedKeys = React.useMemo(
    () => R.pluck('filterName', selectedFilterLayoutsOptionsToDisplay),
    [selectedFilterLayoutsOptionsToDisplay],
  );

  const [localRowsOrder, setLocalRowsOrder] = React.useState<string[]>([]);

  const handleCellInput = React.useCallback(
    (data: CellInputData[]) => {
      const mutations: {
        [key in keyof typeof MutationType]?: CellInputData[];
      } = data.reduce((acc, m) => {
        const mutationType = m.prev
          ? m.next
            ? MutationType.update
            : MutationType.remove
          : MutationType.create;
        if (!acc[mutationType]) acc[mutationType] = [];
        acc[mutationType].push(m);
        return acc;
      }, {});

      for (const [mutation, data] of Object.entries(mutations)) {
        switch (mutation) {
          case MutationType.create: {
            const formattedData = data.map(d => ({
              wellId: d.wellId,
              wellCustomAttributeId: parseInt(d.wellCustomAttributeId),
              value: `${d.next}`,
            }));

            dispatch(createWellCustomAttributeValues(formattedData));
            break;
          }
          case MutationType.remove: {
            const formattedData = R.map(
              R.pick(['wellId', 'wellCustomAttributeId']),
              data,
            );
            const countedFilterValuesToRemove: { [key: string]: number } =
              data.reduce((acc, d) => {
                const attr = selectedFilterLayoutsOptionsToDisplay.find(
                  o => o.id === 'custom' + d.wellCustomAttributeId,
                );
                if (
                  !attr ||
                  !d.prev ||
                  !filters[attr.filterName].includes(d.prev)
                )
                  return acc;

                if (!acc[`${attr.filterName},${d.prev}`])
                  acc[`${attr.filterName},${d.prev}`] = 0;

                acc[`${attr.filterName},${d.prev}`]++;

                return acc;
              }, {});

            const filtersToRemove = Object.entries(
              countedFilterValuesToRemove,
            ).reduce((acc, [filterData, count]) => {
              const [filterName, filterValue] = filterData.split(',');
              const currentFilterValuesCount =
                countedValues[filterName].find(v => v.value === filterValue)
                  ?.quantity ?? 0;

              if (currentFilterValuesCount <= count) {
                if (!acc[filterName]) acc[filterName] = [];
                acc[filterName].push(filterValue);
              }
              return acc;
            }, {});

            if (Object.keys(filtersToRemove).length !== 0) {
              dispatch(removeFilters(filtersToRemove));
            }
            dispatch(removeWellCustomAttributeValues(formattedData));
            break;
          }
          case MutationType.update: {
            const formattedData = data.map(d => ({
              wellId: d.wellId,
              wellCustomAttributeId: d.wellCustomAttributeId,
              value: `${d.next}`,
            }));
            const countedFilterValuesToUpdate: { [key: string]: number } =
              data.reduce((acc, d) => {
                const attr = selectedFilterLayoutsOptionsToDisplay.find(
                  o => o.id === 'custom' + d.wellCustomAttributeId,
                );
                if (
                  !attr ||
                  !d.prev ||
                  !filters[attr.filterName].includes(d.prev)
                )
                  return acc;

                if (!acc[`${attr.filterName},${d.prev},${d.next}`])
                  acc[`${attr.filterName},${d.prev},${d.next}`] = 0;

                acc[`${attr.filterName},${d.prev},${d.next}`]++;

                return acc;
              }, {});
            const filterValuesToUpdate = Object.entries(
              countedFilterValuesToUpdate,
            ).reduce((acc, [filterData, count]) => {
              const [filterName, oldValue, newValue] = filterData.split(',');

              const currentValuesCount =
                countedValues[filterName].find(v => v.value === oldValue)
                  ?.quantity ?? 0;

              if (
                currentValuesCount > count ||
                !newValue ||
                !(oldValue && filters[filterName].includes(oldValue))
              )
                return acc;

              acc[filterName] = { oldValue, newValue };
              return acc;
            }, {});

            if (Object.keys(filterValuesToUpdate).length !== 0) {
              dispatch(replaceFiltersValues(filterValuesToUpdate));
            }

            dispatch(updateWellCustomAttributeValues(formattedData));
            break;
          }
        }
      }
    },
    [dataToDisplay, ref],
  );

  const getColumnsSetting = React.useCallback(() => {
    return selectedFilterLayoutsOptionsToDisplay.map(o => ({
      data: o.filterName,
      readOnly: !o.id.includes('custom'),
    }));
  }, [selectedFilterLayoutsOptionsToDisplay]);

  // Temporary potential fix of render bug
  React.useEffect(() => {
    setTimeout(() => ref.current?.hotInstance?.render(), 10);
  });

  const hotSettings = {
    data: dataToDisplay,
    height: '100%',
    rowHeight: CELL_HEIGHT + 5,
    headerHeight: CELL_HEIGHT + 5,
    autoColumnSize: false,
    autoRowSize: false,
    stretchH: StretchType.none,
    colWidths,
    viewportColumnRenderingOffset: selectedFilterLayoutsOptionsToDisplay.length,
    filters: true,
    licenseKey: HandsontablelicenseKey,
    beforeCopy: data => {
      const copyString = data.map(row => row.join('\t')).join('\r\n');
      const textField = document.createElement('textarea');
      textField.value = copyString;
      if (document.body) {
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
      }

      return false;
    },
    beforeKeyDown: e => {
      const hotInstance = R.pathOr(null, ['current', 'hotInstance'], ref);
      if (
        !hotInstance ||
        hotInstance.getSelected() === undefined ||
        !(e.keyCode === ARROW_UP_KEY || e.keyCode === ARROW_DOWN_KEY)
      )
        return;
      const [[start, , end]] = hotInstance.getSelected();
      if (
        (start === 0 && e.keyCode === ARROW_UP_KEY) ||
        (end === dataToDisplay.length - 1 && e.keyCode === ARROW_DOWN_KEY)
      ) {
        e.stopImmediatePropagation();
      }
    },
    beforeOnCellMouseOver: onMouseOver,
    beforeOnCellMouseOut: onMouseOut,
    cells: (row, col) => ({
      wellId: dataToDisplay[row]?.id,
      wellAttributeId: selectedFilterLayoutsOptionsToDisplay[col].id,
      renderer: disabledCellRenderer,
    }),
    beforeChange: () => {
      const hotInstance = R.pathOr(null, ['current', 'hotInstance'], ref);
      if (!hotInstance) return;

      const order = dataToDisplay.map(
        (_, idx) => hotInstance.getCellMeta(idx, 0).wellId,
      );

      setLocalRowsOrder(order);
    },
    afterChange: (change, source) => {
      if (!ALLOWED_CELL_ACTIONS.includes(source)) return;

      const data = change
        .filter(
          ([row, filterId, prev, next]) =>
            prev !== next && (prev || next) && filterId.includes('custom'),
        )
        .map(([row, filterId, prev, next]) => {
          const meta = ref.current.hotInstance.getCellMeta(
            row,
            selectedKeys.indexOf(filterId),
          );
          return {
            wellId: meta.wellId,
            wellCustomAttributeId: meta.wellAttributeId.split('custom')[1],
            prev: prev?.trim(),
            next: next?.trim(),
          };
        });

      handleCellInput(data);

      if (data.length === 0) return;

      const countToRemove = data.filter(d => !d.next).length;

      if (dataToDisplay.length - countToRemove === 0) setRowsOrder(null);
      else setRowsOrder(localRowsOrder);

      resetSorting();
    },
    columns: getColumnsSetting(),
    columnSorting: {
      initialConfig: sortBy
        ? {
            column: selectedFilterLayoutsOptionsToDisplay.findIndex(
              o => o.id === sortBy?.column,
            ),
            sortOrder: sortBy?.order,
          }
        : undefined,
    },
    copyPaste: {
      rowsLimit: Infinity,
    },
    selectionMode: 'range',
    outsideClickDeselects: false,
  };

  return <FiltersHotTable.FiltersHotTable settings={hotSettings} ref={ref} />;
};

FiltersHotTable.FiltersHotTable = styled(HotTable)`
  &&&&&& {
    --thumb-size: 12px;

    .ht__manualColumnMove--guideline {
      width: 4px;
      background: black;
    }

    table {
      border-collapse: collapse;
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      border-right: 1px solid #c1c1c1;
      padding: 0 8px;
    }

    th {
      padding: 0px 8px;
      background-color: #fff;

      > .relative {
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > .colHeader {
          color: #484848;
          font-weight: bold;
        }
      }
    }

    th.ht__highlight {
      background-color: #f0f0f0;
    }

    .ascending {
      ::before {
        display: block;
        background-image: url(sortIndicator.svg);
        margin-right: -3px;
      }
    }

    .descending {
      ::before {
        display: block;
        content: '';
        transform: rotate(180deg);
        background-image: url(sortIndicator.svg);
        margin-right: -3px;
      }
    }
    .wtHolder {
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    @supports selector(::-webkit-scrollbar-thumb) {
      .ht_clone_top.handsontable {
        width: calc(100% - var(--thumb-size)) !important;
      }
    }
  }
`;

FiltersHotTable.Container = styled.div``;

export default React.memo<FiltersHotTableProps & { ref: any }>(
  React.forwardRef<Element, FiltersHotTableProps>(FiltersHotTable),
);
