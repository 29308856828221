import Tooltip from '@material-ui/core/Tooltip';
import { utcDay, utcYear } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getMinDate } from 'helpers';
import {
  getAppConfig,
  getLocalAppSettings,
} from 'modules/appConfig/AppConfigReducer';
import {
  getVarianceCategoriesColors,
  getVarianceCategories,
} from 'modules/category/CategoryReducer';
import {
  CHARTS_OPTIONS,
  CHART_TITLE_HEIGHT,
  NO_SERIES_MESSAGE,
  VARIANCE_TRELLIS,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from 'modules/chart/models/chart';
import InformationTooltip from 'modules/chart/components/InformationTooltip';
import OverlayRegionOfInterest from 'modules/chart/components/OverlayRegionOfInterest';
import OverlayZoomIn from 'modules/chart/components/OverlayZoomIn';
import XAxis from 'modules/chart/components/XAxis';
import { getPillText } from 'modules/chart/utils';
import { GroupToDisplay, ChartOption } from 'modules/chartOptions/models';
import {
  getAllDataSeriesOptions,
  getDataSeriesGroupsToDisplay,
  getCoreSeriesOptionsToDisplay,
} from 'modules/chartOptions/ChartOptionsReducer';
import {
  initClearDrilldownTable,
  setDrilldownTableParams,
  setMaxDrilldownTableDate,
  setMinDrilldownTableDate,
} from 'modules/drilldownTable/DrilldownTableActions';
import { COMPARE_OPTION } from 'modules/drilldownTable/models/drilldownTable';
import { ForecastData } from 'modules/externalForecast/models';
import {
  getForecastFetchingStatus,
  getForecastStatus,
  getGroupForecast,
} from 'modules/externalForecast/ExternalForecastReducer';
import {
  getGroupChart,
  getGroupVarianceChart,
} from 'modules/groupChart/GroupChartReducer';
import {
  getDrilldownTableParams,
  getIsMarkingRows,
} from 'modules/drilldownTable/DrilldownTableReducer';
import { changeExtremeDates } from 'modules/production/ProductionActions';
import { OIL, GAS, WATER, BOE } from 'modules/phase/models/phase';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import GroupSeriesChart from 'modules/series/containers/GroupSeriesChart';
import { getCommonSeriesMapping } from 'modules/series/SeriesReducer';
import TopControls from 'modules/topControls/containers/TopControls';
import { DRILLDOWN_PANEL } from 'modules/ui/models/ui';
import {
  activateDrilldownPanel,
  disableRegionOfInterestMode,
  disableZoomInMode,
  setRightPanelDialog,
  setTooltipData,
} from 'modules/ui/UIActions';

import {
  getActivePanel,
  getAdditionMode,
  getCurrentGroup,
  getGroupChartLoadingStatus,
  getRegionOfInterestMode,
  getTooltipData,
  getZoomInMode,
  getGroupVarainceChartLoadingStatus,
} from 'modules/ui/UIReducer';
import { getWellsOfGroupQuant } from 'modules/well/WellReducer';
import LoadingPlaceholder from 'modules/wellChart/components/LoadingPlaceholder';

import useChartUIData from 'modules/chart/hooks/useChartUIData';
import useChartScaling from 'modules/chart/hooks/useChartScaling';
import useChartDragging from 'modules/chart/hooks/useChartDragging';
import useChartRect from 'modules/chart/hooks/useChartRect';

import useMouseDownOutside from 'hooks/useMouseDownOutside';

import DivGroupChart from '../components/DivGroupChart';
import GroupVarianceTrellis from '../components/GroupVarianceTrellis';
import VarianceTrellisLoader from '../components/VarianceTrellisLoader';
import LoaderIndicatorWithDelay from 'modules/chart/components/LoaderIndicatorWithDelay';

const GroupProductionChart = () => {
  const dispatch = useDispatch();

  const activePanel = useSelector(getActivePanel);
  const additionMode = useSelector(getAdditionMode);
  const tooltipData = useSelector(getTooltipData);
  const extremeDates = useSelector(getExtremeDates);
  const zoomInMode = useSelector(getZoomInMode);
  const currentGroup = useSelector(getCurrentGroup);
  const isMarkingRows = useSelector(getIsMarkingRows);
  const drilldownTableParams = useSelector(getDrilldownTableParams);
  const regionOfInterestMode = useSelector(getRegionOfInterestMode);
  const appConfig = useSelector(getAppConfig);
  const varianceColors = useSelector(getVarianceCategoriesColors);
  const varianceCategories = useSelector(getVarianceCategories);
  const seriesMapping = useSelector(getCommonSeriesMapping);
  const title = isMarkingRows ? '' : currentGroup.item;
  const groupForecastData: ForecastData = useSelector(state =>
    getGroupForecast(
      state,
      isMarkingRows ? { subject: '_marked', item: '_marked' } : currentGroup,
    ),
  );
  const forecastStatus: boolean = useSelector(getForecastStatus);
  const localSettings = useSelector(getLocalAppSettings);
  const groupChartData = useSelector(state =>
    getGroupChart(
      state,
      isMarkingRows ? { subject: '_marked', item: '_marked' } : currentGroup,
    ),
  );
  const groupVarianceChartData = useSelector(state =>
    getGroupVarianceChart(
      state,
      isMarkingRows ? { subject: '_marked', item: '_marked' } : currentGroup,
    ),
  );
  const groupVarianceChartSlice = React.useMemo(() => {
    if (R.isEmpty(groupVarianceChartData)) return groupVarianceChartData;
    const data: any = Object.values(groupVarianceChartData)[0];
    const fromDate = data[0]?.day;
    if (!fromDate || fromDate > localSettings.minSummedCapacityDateSetting)
      return groupVarianceChartData;

    const index = utcDay.count(
      fromDate,
      localSettings.minSummedCapacityDateSetting,
    );

    return R.mapObjIndexed(value => value.slice(index), groupVarianceChartData);
  }, [groupVarianceChartData, localSettings.minSummedCapacityDateSetting]);

  const wellsQuant = useSelector(state =>
    getWellsOfGroupQuant(state, currentGroup),
  );
  const groupToDisplay: GroupToDisplay = useSelector(
    getDataSeriesGroupsToDisplay,
  );
  const chartOptions = useSelector(getAllDataSeriesOptions);
  const isGroupChartLoading = useSelector(getGroupChartLoadingStatus);
  const cavSeries: ChartOption[] = useSelector(getCoreSeriesOptionsToDisplay);

  const isVarianceChartLoading = useSelector(
    getGroupVarainceChartLoadingStatus,
  );
  const forecastLoadingStatus = useSelector(getForecastFetchingStatus);
  const isDisplayingForecast = forecastStatus && appConfig.showExternalForecast;

  const [isDragging, setDragState] = React.useState(false);
  const startDrag = React.useCallback(() => setDragState(true), []);
  const finishDrag = React.useCallback(() => setDragState(false), []);

  const { width, leftOffset } = useChartRect();
  const { chartWidth, chartHeight } = useChartUIData();
  const { xScale, onXAxisScaling } = useChartScaling();
  const {
    isXAxisDragging,
    isYAxisDragging,
    handleDragStop,
    handleDragStartX,
    handleDragStartY,
    handleDragX,
  } = useChartDragging({ skipDragging: isDragging });

  // temporarily disregard Time Series
  // const hasEnoughData =
  //   !isGroupChartLoading &&
  //   !R.isEmpty(groupChartData) &&
  //   (!appConfig.timeSeries || !R.isEmpty(groupSeries));
  const hasEnoughData =
    !isGroupChartLoading && !R.isEmpty(groupChartData) && forecastLoadingStatus;
  const overlayRegionOfInterestEl = React.useRef(null);
  const regionOgInterestModeOff = React.useCallback(
    () => dispatch(disableRegionOfInterestMode()),
    [dispatch],
  );

  const showMessage =
    Object.keys(cavSeries).length + groupToDisplay.length === 0;

  const changeDrilldownTableParams = React.useCallback(
    newParams => {
      dispatch(setDrilldownTableParams(newParams));
      dispatch(activateDrilldownPanel());
      dispatch(initClearDrilldownTable());
    },
    [dispatch],
  );
  const zoomInModeOff = React.useCallback(
    () => dispatch(disableZoomInMode()),
    [dispatch],
  );
  const changeTwoExtremeDates = React.useCallback(
    ({ min, max }) => dispatch(changeExtremeDates({ min, max })),
    [dispatch],
  );

  const changeMinDrilldownTableDate = React.useCallback(
    date => {
      dispatch(setMinDrilldownTableDate(date));
    },
    [dispatch],
  );
  const changeMaxDrilldownTableDate = React.useCallback(
    date => {
      const maxAvailableDate =
        drilldownTableParams.compareOption === COMPARE_OPTION.extVsCap
          ? utcYear.offset(appConfig.today, 5)
          : utcDay.offset(appConfig.today, -1);
      dispatch(setMaxDrilldownTableDate(getMinDate(date, maxAvailableDate)));
    },
    [dispatch, appConfig.today, drilldownTableParams],
  );
  const resetDrilldownTable = React.useCallback(() => {
    dispatch(initClearDrilldownTable());
  }, [dispatch]);

  const onSetTooltipData = React.useCallback(
    trellisTooltipData => {
      !regionOfInterestMode.isOn &&
        dispatch(setTooltipData({ data: { trellisTooltipData } }));
    },
    [dispatch, regionOfInterestMode.isOn],
  );

  const trellisHeight =
    chartHeight / (groupToDisplay.length + cavSeries.length);

  const chartTitle =
    title === 'all'
      ? `All - ${wellsQuant} Wells`
      : `${title ? title + ' -' : ''} ${wellsQuant} Wells`;
  const interactiveTitle = title === 'all' ? `All` : `${title ? title : ''}`;
  const wellsCount = `${title ? ' -' : ''} ${wellsQuant} Wells`;
  const handleClickOutside = React.useCallback(() => {
    if (zoomInMode.isOn) zoomInModeOff();
    if (regionOfInterestMode.isOn) regionOgInterestModeOff();
  }, [
    zoomInMode,
    zoomInModeOff,
    regionOfInterestMode.isOn,
    regionOgInterestModeOff,
  ]);
  const overlayZoomInEl = React.useRef(null);
  useMouseDownOutside(
    [overlayZoomInEl, overlayRegionOfInterestEl],
    handleClickOutside,
  );

  const onPillClick = () => {
    dispatch(setRightPanelDialog({ type: 'ChartOptions' }));
  };

  const clearSelectionHandler = React.useCallback((e: any) => {
    const selection = window.getSelection();
    if (
      selection &&
      R.pathOr('', ['anchorNode', 'parentNode', 'id'], selection) ===
        'interactiveTitle' &&
      !e.target.classList.contains('chartTitle')
    ) {
      selection && selection.removeAllRanges();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('click', clearSelectionHandler);

    return () => {
      document.removeEventListener('click', clearSelectionHandler);
    };
  }, [clearSelectionHandler]);

  return (
    <GroupProductionChart.Wrapper
      onMouseDown={handleDragStartX}
      onMouseMove={handleDragX}
      onMouseUp={handleDragStop}
    >
      <GroupProductionChart.Container>
        <GroupProductionChart.TitleWrapper>
          <GroupProductionChart.Title id="chartTitle" className="chartTitle">
            <Tooltip title={chartTitle} placement="bottom-start">
              <span className="chartTitle">
                <GroupProductionChart.InteractiveTitle
                  id="interactiveTitle"
                  className="chartTitle"
                >
                  {interactiveTitle}
                </GroupProductionChart.InteractiveTitle>
                {wellsCount}
              </span>
            </Tooltip>
            {!hasEnoughData ? <LoaderIndicatorWithDelay /> : null}
          </GroupProductionChart.Title>
        </GroupProductionChart.TitleWrapper>
        {hasEnoughData ? (
          <GroupProductionChart.TrellisesWrapper
            height={chartHeight}
            className="trellises-wrapper"
          >
            {zoomInMode.isOn && (
              <OverlayZoomIn
                changeExtremeDates={changeTwoExtremeDates}
                extremeDates={extremeDates}
                height={chartHeight}
                leftOffset={leftOffset}
                ref={overlayZoomInEl}
                width={chartWidth}
                zoomInModeOff={zoomInModeOff}
              />
            )}
            {regionOfInterestMode.isOn && (
              <OverlayRegionOfInterest
                compareOption={drilldownTableParams.compareOption}
                coreSeries={cavSeries}
                dataSeries={groupToDisplay}
                extremeDates={extremeDates}
                height={chartHeight}
                leftOffset={leftOffset}
                phase={drilldownTableParams.phase}
                ref={overlayRegionOfInterestEl}
                regionOfInterestModeOff={regionOgInterestModeOff}
                today={appConfig.today}
                width={chartWidth}
                changeDrilldownTableParams={changeDrilldownTableParams}
              />
            )}
            {showMessage ? (
              <GroupProductionChart.MessageWrapper>
                <GroupProductionChart.Message>
                  {NO_SERIES_MESSAGE}
                </GroupProductionChart.Message>
              </GroupProductionChart.MessageWrapper>
            ) : null}
            {cavSeries.map((series, i) => {
              if (series.id !== VARIANCE_TRELLIS) {
                return (
                  <DivGroupChart
                    key={series.id}
                    changeMinDrilldownTableDate={changeMinDrilldownTableDate}
                    changeMaxDrilldownTableDate={changeMaxDrilldownTableDate}
                    clearDrilldownTable={resetDrilldownTable}
                    currentGroup={currentGroup}
                    displaysRegionOfInterest={[OIL, GAS, WATER, BOE].includes(
                      series.id,
                    )}
                    drilldownTableParams={drilldownTableParams}
                    onPillClick={onPillClick}
                    onSetTooltipData={onSetTooltipData}
                    onXAxisScaling={onXAxisScaling}
                    extremeDates={extremeDates}
                    regionOfInterestMode={regionOfInterestMode.isOn}
                    finishDrag={finishDrag}
                    groupForecastData={groupForecastData}
                    format={CHARTS_OPTIONS[series.id].format}
                    groupChartData={groupChartData[series.id] || []}
                    height={trellisHeight}
                    isDisplayingForecast={isDisplayingForecast}
                    isAxisDragging={isXAxisDragging}
                    isDragging={isDragging}
                    leftOffset={leftOffset}
                    position={i}
                    pillText={getPillText(
                      series.id,
                      drilldownTableParams.grossNet,
                      false,
                    )}
                    regionOfInterest={activePanel === DRILLDOWN_PANEL}
                    startDrag={startDrag}
                    today={appConfig.today}
                    tooltipData={tooltipData}
                    trellisTitle={series.id}
                    width={width}
                    xScale={xScale}
                    isLast={
                      i === cavSeries.length - 1 && R.isEmpty(groupToDisplay)
                    }
                  />
                );
              } else if (
                series.id === VARIANCE_TRELLIS &&
                !isVarianceChartLoading
              ) {
                return (
                  <GroupVarianceTrellis
                    key={series.id}
                    changeMaxDrilldownTableDate={changeMaxDrilldownTableDate}
                    changeMinDrilldownTableDate={changeMinDrilldownTableDate}
                    clearDrilldownTable={resetDrilldownTable}
                    isXAxisDragging={isXAxisDragging}
                    isYAxisDragging={isYAxisDragging}
                    onPillClick={onPillClick}
                    onStartYAxisDragging={handleDragStartY}
                    onStopDragging={handleDragStop}
                    displaysRegionOfInterest={true}
                    drilldownTableParams={drilldownTableParams}
                    extremeDates={extremeDates}
                    finishDrag={finishDrag}
                    format={CHARTS_OPTIONS[drilldownTableParams.phase].format}
                    groupChartData={
                      groupVarianceChartSlice[drilldownTableParams.phase] || []
                    }
                    height={trellisHeight}
                    leftOffset={leftOffset}
                    onXAxisScaling={onXAxisScaling}
                    onSetTooltipData={onSetTooltipData}
                    position={i}
                    pillText={getPillText(
                      drilldownTableParams.phase,
                      drilldownTableParams.grossNet,
                      true,
                    )}
                    regionOfInterest={activePanel === DRILLDOWN_PANEL}
                    regionOfInterestMode={regionOfInterestMode.isOn}
                    startDrag={startDrag}
                    trellisTitle={VARIANCE_TRELLIS}
                    today={appConfig.today}
                    tooltipData={tooltipData}
                    varianceColors={varianceColors}
                    width={width}
                    xScale={xScale}
                    isLast={
                      i === cavSeries.length - 1 && R.isEmpty(groupToDisplay)
                    }
                  />
                );
              } else if (
                series.id === VARIANCE_TRELLIS &&
                isVarianceChartLoading
              ) {
                return (
                  <VarianceTrellisLoader
                    key={series.id}
                    height={trellisHeight}
                    onPillClick={onPillClick}
                    isLast={
                      i === cavSeries.length - 1 && R.isEmpty(groupToDisplay)
                    }
                    pillText={getPillText(
                      drilldownTableParams.phase,
                      drilldownTableParams.grossNet,
                      true,
                    )}
                  />
                );
              }
              return null;
            })}
            {groupToDisplay.map((group, i) => (
              <GroupSeriesChart
                key={group.ids[0]}
                chartOptions={chartOptions}
                groupOptions={group.ids}
                height={trellisHeight}
                isLast={i === groupToDisplay.length - 1}
                onPillClick={onPillClick}
                seriesMapping={seriesMapping}
              />
            ))}
          </GroupProductionChart.TrellisesWrapper>
        ) : (
          <LoadingPlaceholder
            appConfig={appConfig}
            drilldownTableParams={drilldownTableParams}
            chartOptions={chartOptions}
            cavSeries={cavSeries}
            seriesMapping={seriesMapping}
            groupToDisplay={groupToDisplay}
            onPillClick={onPillClick}
            ribbonCount={0}
            selectedRibbonsData={[]}
            trellisHeight={trellisHeight}
          />
        )}
        {groupToDisplay.length + cavSeries.length > 0 && (
          <>
            <GroupProductionChart.XAxisContainer width={chartWidth}>
              <XAxis
                extremeDates={extremeDates}
                width={chartWidth}
                xScale={xScale}
                onXAxisScaling={onXAxisScaling}
                isDragging={isXAxisDragging}
              />
            </GroupProductionChart.XAxisContainer>
          </>
        )}
      </GroupProductionChart.Container>
      <TopControls />
      {!additionMode.isOn && tooltipData && !regionOfInterestMode.isOn && (
        <InformationTooltip
          isDisplayingForecast={isDisplayingForecast}
          tooltipData={tooltipData}
          forGroupChart
          varianceCategories={varianceCategories}
          today={appConfig.today}
        />
      )}
    </GroupProductionChart.Wrapper>
  );
};

GroupProductionChart.Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

GroupProductionChart.Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
`;

GroupProductionChart.TitleWrapper = styled.div`
  width: 100%;
  height: ${CHART_TITLE_HEIGHT}px;
  position: relative;
`;

GroupProductionChart.Title = styled.div`
  padding-left: 17px;
  height: ${CHART_TITLE_HEIGHT}px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props: Record<string, any>) => props.theme.colors.primaryText};
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: bold;
  user-select: none;
  position: relative;
  cursor: text;
  user-select: text;

  &::before {
    display: block;
    content: '';
    width: 6px;
    height: 27px;
    position: absolute;
    left: 0;
    top: 13px;
  }
`;

GroupProductionChart.TrellisesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 2;
  width: calc(100% - ${Y_AXIS_WIDTH}px);
  height: calc(100% - ${X_AXIS_HEIGHT + CHART_TITLE_HEIGHT}px);
  position: absolute;
  left: ${Y_AXIS_WIDTH}px;
  top: 48px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`;

GroupProductionChart.XAxisContainer = styled.div`
  width: ${(props: Record<string, any>) => props.width}px;
  position: absolute;
  bottom: 0;
  left: ${Y_AXIS_WIDTH}px;
  height: ${X_AXIS_HEIGHT}px;
  border-left: 1px solid black;
  overflow: hidden;
`;

GroupProductionChart.InteractiveTitle = styled.span`
  user-select: text;
`;
GroupProductionChart.MessageWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
`;

GroupProductionChart.Message = styled.span`
  font-size: 20px;
`;

export default GroupProductionChart;
