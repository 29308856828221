export type AppConfig = {
  drilldownDefaultFromDate: Date;
  drilldownDefaultToDate: Date;
  today: Date;
  showExternalForecast: boolean;
  capacityVsExternal: boolean;
  minSummedCapacityDateSetting: Date;
};

export const LOCAL_APP_SETTINGS = [
  {
    name: 'Oil Price Assumption ($/BBL)',
    key: 'oilPriceAssumption',
    convert: value => parseFloat(value),
    default: 70,
  },
  {
    name: 'Gas Price Assumption ($/MCF)',
    key: 'gasPriceAssumption',
    convert: value => parseFloat(value),
    default: 3,
  },
  {
    name: 'Minimum Date to Show Grouped Capacities',
    key: 'minSummedCapacityDateSetting',
    convert: value => new Date(value),
    default: new Date(0),
  },
];

export const LOCAL_APP_SETTINGS_KEYS = LOCAL_APP_SETTINGS.map(
  setting => setting.key,
);
