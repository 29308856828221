import * as React from 'react';
import { LayoutOption } from '../models';
import styled from 'styled-components';
import { MdPerson } from 'react-icons/all';
import { DeleteIcon, PencilIcon } from 'components/Icons';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import {
  deleteWellCustomAttribute,
  updateWellCustomAttribute,
} from '../FilterLayoutsActions';
import { clearFilter, replaceFilter } from 'modules/filter/FilterActions';
import { EditModalWindow } from 'components/ModalWindow/EditModalWindow';
import { DeleteModalWindow } from 'components/ModalWindow/DeleteModalWindow';
import ModalWindow from 'components/ModalWindow';

interface CustomAttributeProps {
  attribute: LayoutOption;
  displayNameHtml?: { __html: string };
  checkIfAttributeNameAlreadyExists: (name: string) => boolean;
  onSetEditMode?: (isEditMode: boolean) => void;
}

export const CustomAttribute = ({
  attribute,
  checkIfAttributeNameAlreadyExists,
  onSetEditMode,
  displayNameHtml,
}: CustomAttributeProps) => {
  const [isEditingCustomAttribute, setIsEditingCustomAttribute] =
    React.useState(false);
  const [isDeletingCustomAttribute, setIsDeletingCustomAttribute] =
    React.useState(false);
  const [attributeNameAlreadyExists, setAttributeNameAlreadyExists] =
    React.useState(false);
  const dispatch = useDispatch();

  const editAttributeName = React.useCallback(
    (newValue: string) => {
      if (newValue.trim().length === 0 || newValue === attribute.displayName)
        return;

      if (checkIfAttributeNameAlreadyExists(newValue)) {
        setAttributeNameAlreadyExists(true);
        return;
      }

      setIsEditingCustomAttribute(false);
      const formattedId = attribute.id.split('custom')[1];
      dispatch(
        replaceFilter({
          oldFilterName: attribute.filterName,
          newFilterName: 'custom ' + newValue,
        }),
      );
      dispatch(updateWellCustomAttribute({ id: formattedId, name: newValue }));
    },
    [
      attribute,
      checkIfAttributeNameAlreadyExists,
      dispatch,
      setIsEditingCustomAttribute,
      setAttributeNameAlreadyExists,
    ],
  );

  const deleteAttribute = React.useCallback(() => {
    const formattedId = attribute.id.split('custom')[1];
    dispatch(deleteWellCustomAttribute(formattedId));
    dispatch(clearFilter(attribute.filterName));
  }, [attribute, dispatch]);

  React.useEffect(() => {
    onSetEditMode?.call(
      null,
      isEditingCustomAttribute || isDeletingCustomAttribute,
    );
  }, [isEditingCustomAttribute || isDeletingCustomAttribute, onSetEditMode]);

  return (
    <CustomAttribute.ItemWrapper>
      <CustomAttribute.FilterOptionName>
        <div
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          title={attribute.displayName}
          dangerouslySetInnerHTML={displayNameHtml}
        >
          {!displayNameHtml ? attribute.displayName : null}
        </div>

        <CustomAttribute.RightContainer>
          <CustomAttribute.ButtonsInputLayout className="CustomAttribute__ButtonsInputLayout">
            <CustomAttribute.BackgroundlessButton
              iconWidth={20}
              onMouseDown={e => {
                e.stopPropagation();
                setIsEditingCustomAttribute(true);
              }}
            >
              <PencilIcon />
            </CustomAttribute.BackgroundlessButton>

            <CustomAttribute.BackgroundlessButton
              iconWidth={20}
              onMouseDown={e => {
                e.stopPropagation();
                setIsDeletingCustomAttribute(true);
              }}
            >
              <DeleteIcon color="#000000" />
            </CustomAttribute.BackgroundlessButton>
          </CustomAttribute.ButtonsInputLayout>

          <CustomAttribute.UserIcon iconWidth={20}>
            <MdPerson />
          </CustomAttribute.UserIcon>
        </CustomAttribute.RightContainer>
      </CustomAttribute.FilterOptionName>

      {isDeletingCustomAttribute && (
        <DeleteModalWindow
          close={() => setIsDeletingCustomAttribute(false)}
          title={`Are you sure you want to delete the "${attribute.displayName}" custom attribute?`}
          onCancel={() => setIsDeletingCustomAttribute(false)}
          onDelete={() => {
            setIsDeletingCustomAttribute(false);
            deleteAttribute();
          }}
        />
      )}

      {isEditingCustomAttribute && (
        <EditModalWindow
          close={() => setIsEditingCustomAttribute(false)}
          title={`Rename the "${attribute.displayName}" custom attribute:`}
          onCancel={() => setIsEditingCustomAttribute(false)}
          onEdit={editAttributeName}
          oldValue={attribute.displayName}
        />
      )}

      {attributeNameAlreadyExists && (
        <ModalWindow
          title="A custom attribute with this name already exists."
          controls={[
            {
              text: 'Back',
              action: () => setAttributeNameAlreadyExists(false),
              style: { width: '70px' },
            },
          ]}
          close={() => setAttributeNameAlreadyExists(false)}
        />
      )}
    </CustomAttribute.ItemWrapper>
  );
};

CustomAttribute.ItemWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: 20px;
  align-items: center;
`;

CustomAttribute.FilterOptionName = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  margin: 0 5px;

  :hover .CustomAttribute__ButtonsInputLayout {
    display: flex;
  }
`;

CustomAttribute.Source = styled.span`
  text-align: right;
  font-style: italic;
`;

CustomAttribute.AddButton = styled.button`
  height: 20px;
  border-radius: 0;
  border: none;
  border-left: 1px solid black;
  padding: 0;
  min-width: 23px;
  outline: none;
`;

CustomAttribute.RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

CustomAttribute.ButtonsInputLayout = styled.div`
  display: none;
  justify-content: space-between;
  overflow: hidden;
`;

CustomAttribute.BackgroundlessButton = styled(Button)`
  height: 20px;
  border-radius: 0;
  border: 0;
  padding: 0;
  background: none;
  box-shadow: none;
  width: initial;
  min-width: 16px;
  outline: none;

  svg {
    width: ${({ iconWidth }) => (iconWidth ?? 20) + 'px'};
  }
`;

CustomAttribute.UserIcon = styled(CustomAttribute.BackgroundlessButton)`
  & {
    cursor: default;

    svg {
      height: 95%;
    }
  }
`;
