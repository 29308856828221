import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent, faDollarSign } from '@fortawesome/pro-light-svg-icons';

import { SortIndicator } from 'components/Icons';

interface VarianceHeaderRowProps {
  className: string;
  firstColDataKey: string;
  style: { [prop: string]: string };
  secondColDataKey: string;
  thirdColDataKey: string;
  fourthColDataKey: string;
  rateVolume: string;
  optionColumns: string[];
  sort: (sortTarget: string) => void;
  sortBy: string;
  sortDirection: string;
  wide: boolean;
  isVarianceOptionSelected: boolean;
  resetCurrentVarianceOption: () => void;
  columnWidths: { [key: string]: number };
  explainTitle: string;
}

const VarianceHeaderRow = ({
  className,
  firstColDataKey,
  style,
  secondColDataKey,
  thirdColDataKey,
  rateVolume,
  optionColumns,
  fourthColDataKey,
  sort,
  sortBy,
  sortDirection,
  wide,
  isVarianceOptionSelected,
  resetCurrentVarianceOption,
  columnWidths,
  explainTitle,
}: VarianceHeaderRowProps) => (
  <div className={className} role="row" style={style}>
    <VarianceHeaderRow.FirstCol onClick={() => sort(firstColDataKey)}>
      <VarianceHeaderRow.FirstColName>
        Variance Category
        {sortBy === firstColDataKey && (
          <SortIndicator sortDirection={sortDirection} />
        )}
      </VarianceHeaderRow.FirstColName>

      {isVarianceOptionSelected && (
        <VarianceHeaderRow.ClearButton
          onClick={e => {
            e.stopPropagation();
            resetCurrentVarianceOption();
          }}
        >
          Clear Selection
        </VarianceHeaderRow.ClearButton>
      )}
    </VarianceHeaderRow.FirstCol>
    {optionColumns.includes(secondColDataKey) && (
      <VarianceHeaderRow.SecondCol width={columnWidths[secondColDataKey]}>
        <FontAwesomeIcon icon={faPercent} />
      </VarianceHeaderRow.SecondCol>
    )}
    {optionColumns.includes(thirdColDataKey) && (
      <VarianceHeaderRow.ThirdCol
        width={columnWidths[thirdColDataKey]}
        title={explainTitle}
      >
        <FontAwesomeIcon icon={faDollarSign} />
        {rateVolume === 'Rate' && '/Day'}
      </VarianceHeaderRow.ThirdCol>
    )}
    <VarianceHeaderRow.FourthCol
      onClick={() => sort(fourthColDataKey)}
      wide={wide}
    >
      Var.
      {sortBy === fourthColDataKey && (
        <VarianceHeaderRow.SortIndicatorWrapper leftOffset={wide ? '45' : '40'}>
          <SortIndicator sortDirection={sortDirection} />
        </VarianceHeaderRow.SortIndicatorWrapper>
      )}
    </VarianceHeaderRow.FourthCol>
  </div>
);

VarianceHeaderRow.FirstCol = styled.div`
  color: rgba(0, 0, 0, 0.8);
  text-transform: none;
  width: 189px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 2px;
  cursor: pointer;
  flex: 1 1 200px;
  line-height: 1.3em;
`;

VarianceHeaderRow.SecondCol = styled.div`
  color: rgba(0, 0, 0, 0.8);
  text-transform: none;
  flex: 0 0 ${({ width }) => width ?? 55}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #c1c1c1;
  padding-right: 2px;
  padding-left: 2px;
  cursor: default;
  line-height: 1.3em;
  position: relative;
`;

VarianceHeaderRow.ThirdCol = styled.div`
  color: rgba(0, 0, 0, 0.8);
  text-transform: none;
  flex: 0 0 ${({ width }) => width ?? 55}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #c1c1c1;
  padding-right: 2px;
  padding-left: 2px;
  cursor: default;
  line-height: 1.3em;
  position: relative;
`;

VarianceHeaderRow.FourthCol = styled.div`
  color: rgba(0, 0, 0, 0.8);
  text-transform: none;
  flex: 0 0 ${(props: Record<string, any>) => (props.wide ? '70' : '55')}px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-left: 1px solid #c1c1c1;
  padding-right: 2px;
  margin-right: 5px;
  padding-left: 5px;
  cursor: pointer;
  line-height: 1.3em;
  position: relative;
`;

VarianceHeaderRow.SortIndicatorWrapper = styled.div`
  position: absolute;
  left: ${(props: Record<string, any>) =>
    props.leftOffset ? props.leftOffset + 'px' : '40px'};
  top: 2px;
`;

VarianceHeaderRow.FirstColName = styled.div`
  display: flex;
`;

VarianceHeaderRow.ClearButton = styled.span`
  color: #c60000;
  margin-right: 6px;
`;

export default VarianceHeaderRow;
