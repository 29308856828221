import * as Sentry from '@sentry/browser';
import { Middleware } from 'redux';
import { Action } from 'store/models';

const sentryMiddleware: Middleware =
  (store: any) => (next: any) => (action: Action) => {
    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
    });

    return next(action);
  };

export default sentryMiddleware;
