import { utcDay, TimeInterval } from 'd3-time';
import * as R from 'ramda';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import { ForecastData } from 'modules/externalForecast/models';
import { ProductionPoint } from 'modules/production/models/production';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

const createDataMapForSelectedBar = (
  production: ProductionPoint[],
  productionKey: string,
  capacityData: { date: Date; capacity: number }[],
  varianceEvents: VarianceEvent[],
  allocIssues: AllocIssue[],
  forecastData: ForecastData,
) => {
  const dataMap =
    production && productionKey
      ? production.reduce((acc, prodPoint) => {
          const dayString = prodPoint.day.toISOString();
          acc[dayString] = {
            day: prodPoint.day,
            production: prodPoint[productionKey],
            capacity: null,
            forecast: 0,
          };

          return acc;
        }, {})
      : {};

  if (capacityData) {
    capacityData.slice(0, capacityData.length - 2).reduce((acc, prodPoint) => {
      const dayString = prodPoint.date.toISOString();
      if (acc[dayString]) {
        acc[dayString] = {
          ...acc[dayString],
          capacity: prodPoint.capacity,
        };
        return acc;
      }
      acc[dayString] = {
        day: prodPoint.date,
        capacity: prodPoint.capacity,
        production: 0,
        forecast: 0,
      };

      return acc;
    }, dataMap);
  }
  if (!R.isEmpty(forecastData)) {
    forecastData.reduce((acc, forecastPoint) => {
      const dayString = forecastPoint.day.toISOString();
      if (acc[dayString]) {
        acc[dayString] = {
          ...acc[dayString],
          forecast: forecastPoint[productionKey],
        };
        return acc;
      }
      acc[dayString] = {
        day: forecastPoint.day,
        forecast: forecastPoint[productionKey],
        production: 0,
        capacity: 0,
      };

      return acc;
    }, dataMap);
  }
  //@ts-expect-error
  const varianceEntities = varianceEvents.concat(allocIssues);
  const varianceCatIndexedByDay = varianceEntities.reduce((acc, entity) => {
    //@ts-expect-error
    const dayStart = entity.dayStart || entity.dateStart;
    //@ts-expect-error
    const dayEnd = entity.dayEnd || entity.dateEnd;
    const interval = utcDay.every(1) as TimeInterval;
    const timeRange = interval.range(dayStart, utcDay.offset(dayEnd));
    timeRange.forEach(
      date =>
        (acc[date.toISOString()] = entity.varianceOptionId || 'allocIssue'),
    );

    return acc;
  }, {});

  return Object.keys(dataMap).reduce((acc, dateString) => {
    acc[dateString] = {
      ...dataMap[dateString],
    };
    acc[dateString].variance =
      acc[dateString].capacity - acc[dateString].production;
    acc[dateString].varianceOptionId = varianceCatIndexedByDay[dateString];

    return acc;
  }, {});
};

export default createDataMapForSelectedBar;
