import { utcMonth, utcDay, utcYear } from 'd3-time';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import * as React from 'react';
import styled from 'styled-components';

import type { AppConfig } from 'modules/appConfig/models/appConfig';
import { getDefaultProductionRange } from 'modules/production/utils';

import Button from 'components/Button';
import DateInput from 'components/DateInput';

type ZoomMenuProps = {
  changeOneExtremeDate: ({ datePosition: string, dateValue: Date }) => void;
  closeZoomMenuWithTimeout: () => void;
  config: AppConfig;
  extremeDates: { min: Date; max: Date };
  isProductionLoading: boolean;
  offset: number;
  onZoomMenuHover: () => void;
  setZoomPreset: (data: string) => void;
  undoExtremeDatesChange: () => void;
  zoomInModeOn: () => void;
  zoomHistoryLength: number;
  zoomOut: () => void;
  zoomToAll: () => void;
};

const ZoomMenu = (
  {
    changeOneExtremeDate,
    closeZoomMenuWithTimeout,
    config,
    extremeDates,
    isProductionLoading,
    offset,
    onZoomMenuHover,
    setZoomPreset,
    undoExtremeDatesChange,
    zoomInModeOn,
    zoomHistoryLength,
    zoomOut,
    zoomToAll,
  }: ZoomMenuProps,
  zoomMenuEl,
) => {
  return (
    <ZoomMenu.Wrapper
      ref={zoomMenuEl}
      offset={offset}
      onMouseLeave={closeZoomMenuWithTimeout}
      onMouseEnter={onZoomMenuHover}
      className="zoom-menu"
    >
      <Paper>
        <List style={{ outline: 'none' }}>
          <ListItem>
            <ZoomMenu.InputGroup>
              <ZoomMenu.InputLabel>Start Date:</ZoomMenu.InputLabel>
              <DateInput
                id="production-range-start"
                name="production-range-start"
                date={extremeDates.min}
                min={new Date(0)}
                max={utcDay.offset(extremeDates.max, -1)}
                onProcess={date =>
                  changeOneExtremeDate({
                    datePosition: 'min',
                    dateValue: date,
                  })
                }
                disabled={isProductionLoading}
              />
            </ZoomMenu.InputGroup>
          </ListItem>

          <ListItem>
            <ZoomMenu.InputGroup>
              <ZoomMenu.InputLabel>End Date:</ZoomMenu.InputLabel>
              <DateInput
                id="production-range-end"
                name="production-range-end"
                date={utcDay.offset(extremeDates.max, -1)}
                min={extremeDates.min}
                max={utcYear.offset(new Date(), 10)}
                onProcess={date =>
                  changeOneExtremeDate({
                    datePosition: 'max',
                    dateValue: utcDay.offset(date, 1),
                  })
                }
                disabled={isProductionLoading}
              />
            </ZoomMenu.InputGroup>
          </ListItem>

          <ListItem>
            <Button
              width={155}
              onClick={zoomInModeOn}
              disabled={isProductionLoading}
            >
              Zoom In
            </Button>
          </ListItem>

          <ListItem>
            <Button
              width={155}
              onClick={zoomOut}
              disabled={isProductionLoading}
            >
              Zoom Out
            </Button>
          </ListItem>

          <ListItem>
            <Button
              width={155}
              onClick={undoExtremeDatesChange}
              disabled={isProductionLoading || zoomHistoryLength <= 1}
            >
              Zoom Previous
            </Button>
          </ListItem>
          <ListItem>
            <Button
              width={155}
              onClick={zoomToAll}
              disabled={isProductionLoading}
            >
              Show All
            </Button>
          </ListItem>
          <ListItem>
            <FormControl>
              <ZoomMenu.FormLabel component="legend">
                Zoom to Prior:
              </ZoomMenu.FormLabel>
              <ZoomMenu.RadioGroup
                aria-label="ZoomPreset"
                name="zoomPreset"
                value={
                  extremeDates.max.getTime() ===
                  getDefaultProductionRange(config.today).max.getTime()
                    ? utcMonth.count(extremeDates.min, config.today).toString()
                    : 'none'
                }
                onChange={e => setZoomPreset(e.target.value)}
              >
                <FormControlLabel
                  value="3"
                  control={<Radio color="primary" />}
                  label="3 Months"
                  disabled={isProductionLoading}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio color="primary" />}
                  label="6 Months"
                  disabled={isProductionLoading}
                />
                <FormControlLabel
                  value="12"
                  control={<Radio color="primary" />}
                  label="12 Months"
                  disabled={isProductionLoading}
                />
                <FormControlLabel
                  value="24"
                  control={<Radio color="primary" />}
                  label="24 Months"
                  disabled={isProductionLoading}
                />
              </ZoomMenu.RadioGroup>
            </FormControl>
          </ListItem>
        </List>
      </Paper>
    </ZoomMenu.Wrapper>
  );
};

ZoomMenu.Wrapper = styled.div`
  pointer-events: auto;
  position: absolute;
  top: 35px;
  right: ${props => (props.offset ? props.offset + 135 : 55)}px;
`;

ZoomMenu.InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

ZoomMenu.InputLabel = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;

ZoomMenu.FormLabel = styled.legend`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 9px;
`;

ZoomMenu.RadioGroup = styled(RadioGroup)`
  width: 155px;
`;

export default React.forwardRef<HTMLElement, ZoomMenuProps>(ZoomMenu);
