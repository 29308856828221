import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';

import * as React from 'react';
import styled from 'styled-components';

import type { Group } from 'modules/well/models/well';

import { GroupIcon } from 'components/Icons';
import Button from 'components/Button';

import useMenu from 'hooks/useMenu';

interface GroupMenuType {
  groupsByOrder: Group[];
  handleGroupSelection: (group: MouseEvent) => void;
}

const GroupMenu = ({ groupsByOrder, handleGroupSelection }: GroupMenuType) => {
  const menuEl = React.useRef(null);
  const [isMenuOpen, onMenuHover, onMenuLeave, onTargetClick, closeMenu] =
    useMenu(menuEl);

  const handleMenuItemChoose = React.useCallback(
    (e: MouseEvent) => {
      handleGroupSelection(e);
      closeMenu();
    },
    [handleGroupSelection, closeMenu],
  );

  return (
    <div>
      <GroupMenu.MenuButton
        width={30}
        height={26}
        open={isMenuOpen}
        onClick={onTargetClick}
      >
        <GroupIcon />
      </GroupMenu.MenuButton>
      {isMenuOpen && (
        <GroupMenu.GroupMenuWrapper
          ref={menuEl}
          onMouseLeave={onMenuLeave}
          onMouseEnter={onMenuHover}
        >
          <GroupMenu.StyledPaper>
            <GroupMenu.MenuList>
              {groupsByOrder.map(group => (
                <GroupMenu.MenuItem
                  key={group.key}
                  id={group.key}
                  onClick={handleMenuItemChoose}
                >
                  {group.title}
                </GroupMenu.MenuItem>
              ))}
            </GroupMenu.MenuList>
          </GroupMenu.StyledPaper>
        </GroupMenu.GroupMenuWrapper>
      )}
    </div>
  );
};

GroupMenu.GroupMenuWrapper = styled.div`
  position: absolute;
  top: 136px;
  left: 14px;
  pointer-events: auto;
  z-index: 999;
`;

GroupMenu.StyledPaper = styled(Paper)`
  box-shadow: none !important;
`;

GroupMenu.MenuList = styled(MenuList)`
  border: 1px solid #3297fd;
`;

GroupMenu.MenuItem = styled(MenuItem)`
  min-width: 175px;
  &:hover {
    background-color: #3297fd !important;
    color: white;
  }
`;

GroupMenu.MenuButton = styled(Button)`
  border: ${props => {
    return props.open ? '1px solid #3297fd' : '';
  }};
`;

export default GroupMenu;
