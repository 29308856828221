import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getSearchHighlightsHtml } from 'helpers';
import { PlusIcon } from 'components/Icons';
import SearchForm from 'components/SearchForm';
import LongNameMouseTooltip from 'components/LongNameMouseTooltip/LongNameMouseTooltip';
import ForecastCheckbox from 'modules/externalForecast/components/ForecastCheckbox';

import {
  ChartOption,
  MAX_AVAILABLE_NAME_WIDTH,
  NOT_EXISTING_SENSOR_SERIES_MESSAGE,
  PANEL_SIZES,
} from '../models';
import { CommonScrollbar } from 'components/CommonScrollbar';
import { getCurrentWellId } from 'modules/ui/UIReducer';
import { getSensorSeriesMapping } from 'modules/series/SeriesReducer';
import checkSensorSeriesExists from '../utils/checkSensorSeriesExists';

interface AvailableOtherSeriesMenuProps {
  coreSeries: ChartOption[];
  dataSeries: ChartOption[];
  sensorSeries: ChartOption[];
  height: number;
  onRemoveAvaliableCoreSeries: (data: {
    isAvailable: boolean;
    isShow: boolean;
    optionId: string;
  }) => void;
  onRemoveAvaliableDataSeries: (data: {
    isShow: boolean;
    optionId: string;
  }) => void;
  setAvailableSeriesHeight: (availableSeriesHeight: number) => void;
  getNewAvailableSeriesAreaHeight: (availableSeriesHeight: number) => number;
}
const AvailableOtherSeriesMenu = ({
  coreSeries,
  dataSeries,
  sensorSeries,
  onRemoveAvaliableCoreSeries,
  getNewAvailableSeriesAreaHeight,
  height,
  onRemoveAvaliableDataSeries,
  setAvailableSeriesHeight,
}: AvailableOtherSeriesMenuProps) => {
  const [searchWord, setSearchWord] = React.useState('');

  const currentWellId = useSelector(getCurrentWellId);
  const sensorSeriesMapping = useSelector(getSensorSeriesMapping);

  const containerRef = React.useRef<HTMLElement>(null);
  const onHandleMouseMove = React.useCallback(
    (e: MouseEvent) => {
      const mouseYPos = e.clientY;
      const newHeight = getNewAvailableSeriesAreaHeight(mouseYPos);
      setAvailableSeriesHeight(
        Math.max(PANEL_SIZES.minAvailableAreaHeight, newHeight),
      );
    },
    [setAvailableSeriesHeight, getNewAvailableSeriesAreaHeight],
  );

  const onHandleMouseUp = React.useCallback(() => {
    document.removeEventListener('mousemove', onHandleMouseMove);
  }, [onHandleMouseMove]);

  const onHandleMouseDown = React.useCallback(() => {
    document.addEventListener('mousemove', onHandleMouseMove);
    document.addEventListener('mouseup', onHandleMouseUp);
  }, [onHandleMouseMove, onHandleMouseUp]);

  const [tooltipText, setTooltipText] = React.useState('');
  const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });
  const onMouseMoveHandler = React.useCallback(
    (e: MouseEvent) => {
      setTooltipPosition({ x: e.clientX, y: e.clientY });
    },
    [setTooltipPosition],
  );

  const onMouseEnterHandler = React.useCallback(
    (description: string) => {
      setTooltipText(description);
    },
    [setTooltipText],
  );

  const onMouseLeaveHandler = React.useCallback(() => {
    setTooltipText('');
  }, [setTooltipText]);

  const filterSeriesBySearch = React.useCallback(
    (series: ChartOption) => {
      return series.title.toLowerCase().includes(searchWord.toLowerCase());
    },
    [searchWord],
  );
  const filteredCoreSeries = React.useMemo(() => {
    return coreSeries.filter(filterSeriesBySearch);
  }, [filterSeriesBySearch, coreSeries]);

  const filteredDataSeries = React.useMemo(() => {
    return dataSeries.filter(filterSeriesBySearch);
  }, [filterSeriesBySearch, dataSeries]);

  const sensorSeriesWithActiveState = React.useMemo(() => {
    if (!sensorSeriesMapping) return [];
    return sensorSeries.map(s => {
      return {
        ...s,
        exists: checkSensorSeriesExists(s, sensorSeriesMapping, currentWellId),
      };
    });
  }, [sensorSeries, sensorSeriesMapping, currentWellId]);

  const filteredSensorSeries = React.useMemo(() => {
    return sensorSeriesWithActiveState.filter(filterSeriesBySearch);
  }, [filterSeriesBySearch, sensorSeriesWithActiveState]);

  const renderOptionTitle = React.useCallback(
    (option: ChartOption) => {
      const __html = getSearchHighlightsHtml(option.title, searchWord);
      return <span dangerouslySetInnerHTML={{ __html }} />;
    },
    [searchWord],
  );

  return (
    <>
      <AvailableOtherSeriesMenu.Wrapper>
        <div>
          <AvailableOtherSeriesMenu.LineWrapper>
            <AvailableOtherSeriesMenu.DragHandle
              onMouseDown={onHandleMouseDown}
            >
              <AvailableOtherSeriesMenu.DragIcon />
            </AvailableOtherSeriesMenu.DragHandle>
          </AvailableOtherSeriesMenu.LineWrapper>

          <div
            style={{
              height: height + 'px',
            }}
          >
            <CommonScrollbar style={{ height: '100%' }}>
              <div>
                <AvailableOtherSeriesMenu.Container>
                  <AvailableOtherSeriesMenu.SearchContainer>
                    <SearchForm
                      placeholder="Search"
                      inputHandler={setSearchWord}
                      value={searchWord}
                      fullWidth
                      height={30}
                    />
                  </AvailableOtherSeriesMenu.SearchContainer>
                  {Boolean(filteredCoreSeries.length) && (
                    <>
                      <AvailableOtherSeriesMenu.TrellisMenuLegend>
                        Core Trellises
                        <ForecastCheckbox />
                      </AvailableOtherSeriesMenu.TrellisMenuLegend>
                      <AvailableOtherSeriesMenu.OptionWrapper
                        isOnlyCoreSeries={
                          !!dataSeries.length || !!filteredSensorSeries.length
                        }
                      >
                        {filteredCoreSeries.map((option, i) => (
                          <AvailableOtherSeriesMenu.ItemWrapper
                            key={option.id}
                            isFirst={i === 0}
                            isCoreSeries={true}
                          >
                            <AvailableOtherSeriesMenu.SeriesName>
                              {renderOptionTitle(option)}
                            </AvailableOtherSeriesMenu.SeriesName>
                            <AvailableOtherSeriesMenu.CoreSeriesLabel>
                              Core
                            </AvailableOtherSeriesMenu.CoreSeriesLabel>
                            <AvailableOtherSeriesMenu.AddButton
                              onClick={() =>
                                onRemoveAvaliableCoreSeries({
                                  optionId: option.id,
                                  isShow: true,
                                  isAvailable: !option.isAvailable,
                                })
                              }
                            >
                              <PlusIcon />
                            </AvailableOtherSeriesMenu.AddButton>
                          </AvailableOtherSeriesMenu.ItemWrapper>
                        ))}
                      </AvailableOtherSeriesMenu.OptionWrapper>
                    </>
                  )}

                  {Boolean(filteredDataSeries.length) && (
                    <>
                      <AvailableOtherSeriesMenu.TrellisMenuLegend>
                        Daily Data Series
                      </AvailableOtherSeriesMenu.TrellisMenuLegend>
                      <AvailableOtherSeriesMenu.OptionWrapper
                        ref={containerRef}
                        isOnlyCoreSeries={filteredSensorSeries.length}
                      >
                        {filteredDataSeries.map((option, i) => (
                          <AvailableOtherSeriesMenu.ItemWrapper
                            key={option.id}
                            isFirst={i === 0}
                          >
                            <AvailableOtherSeriesMenu.SeriesName
                              onMouseEnter={() =>
                                onMouseEnterHandler(option.title)
                              }
                              onMouseMove={onMouseMoveHandler}
                              onMouseLeave={onMouseLeaveHandler}
                            >
                              {renderOptionTitle(option)}
                            </AvailableOtherSeriesMenu.SeriesName>
                            <AvailableOtherSeriesMenu.CoreSeriesLabel>
                              Daily
                            </AvailableOtherSeriesMenu.CoreSeriesLabel>
                            <AvailableOtherSeriesMenu.AddButton
                              onClick={() =>
                                onRemoveAvaliableDataSeries({
                                  optionId: option.id,
                                  isShow: !option.isShow,
                                })
                              }
                            >
                              <PlusIcon />
                            </AvailableOtherSeriesMenu.AddButton>
                          </AvailableOtherSeriesMenu.ItemWrapper>
                        ))}
                      </AvailableOtherSeriesMenu.OptionWrapper>
                    </>
                  )}

                  {Boolean(filteredSensorSeries.length) && (
                    <>
                      <AvailableOtherSeriesMenu.TrellisMenuLegend>
                        Sensor Series
                      </AvailableOtherSeriesMenu.TrellisMenuLegend>
                      <AvailableOtherSeriesMenu.OptionWrapper
                        ref={containerRef}
                      >
                        {filteredSensorSeries.map((option, i) => (
                          <AvailableOtherSeriesMenu.ItemWrapper
                            key={option.id}
                            isFirst={i === 0}
                            isCoreSeries={true}
                          >
                            <AvailableOtherSeriesMenu.SeriesName
                              title={
                                option.exists
                                  ? undefined
                                  : NOT_EXISTING_SENSOR_SERIES_MESSAGE
                              }
                              onMouseEnter={() =>
                                onMouseEnterHandler(option.title)
                              }
                              onMouseMove={onMouseMoveHandler}
                              onMouseLeave={onMouseLeaveHandler}
                              isNotExists={!option.exists}
                              color={option.exists ? null : '#9a9a9a'}
                            >
                              {renderOptionTitle(option)}
                            </AvailableOtherSeriesMenu.SeriesName>
                            <AvailableOtherSeriesMenu.CoreSeriesLabel>
                              Sensor
                            </AvailableOtherSeriesMenu.CoreSeriesLabel>
                            <AvailableOtherSeriesMenu.AddButton
                              onClick={() =>
                                onRemoveAvaliableDataSeries({
                                  optionId: option.id,
                                  isShow: !option.isShow,
                                })
                              }
                            >
                              <PlusIcon />
                            </AvailableOtherSeriesMenu.AddButton>
                          </AvailableOtherSeriesMenu.ItemWrapper>
                        ))}
                      </AvailableOtherSeriesMenu.OptionWrapper>
                    </>
                  )}
                </AvailableOtherSeriesMenu.Container>
              </div>
            </CommonScrollbar>
          </div>
        </div>
      </AvailableOtherSeriesMenu.Wrapper>
      {tooltipText ? (
        <LongNameMouseTooltip
          position={tooltipPosition}
          text={tooltipText}
          maxWidth={MAX_AVAILABLE_NAME_WIDTH}
        />
      ) : null}
      {/* <LongNameMouseTooltip
        position={tooltipPosition}
        text={'tooltipText'}
        maxWidth={0}
        isAlwaysShow
      /> */}
    </>
  );
};

AvailableOtherSeriesMenu.DragHandle = styled.div`
  z-index: 53 !important;
  position: relative;
  top: -7px;
  min-height: 12px;
  max-height: 100%;
  width: 54px;
  cursor: ns-resize;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  border: 1px solid #c1c1c1;
  z-index: 2;
`;

AvailableOtherSeriesMenu.LineWrapper = styled.div`
  height: 0;
  display: flex;
  justify-content: center;
  position: relative;
`;

AvailableOtherSeriesMenu.DragIcon = styled.div`
  height: 4px;
  width: 20px;
  border-bottom: ${props => props.theme.borders.thingray};
  border-top: ${props => props.theme.borders.thingray};
`;

AvailableOtherSeriesMenu.SearchContainer = styled.div`
  margin: 6px 0 16px;
`;

AvailableOtherSeriesMenu.TrellisMenuLegend = styled.legend`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 7px;
  display: flex;
  justify-content: space-between;
`;

AvailableOtherSeriesMenu.Container = styled.div`
  border: ${props =>
    props.isDragging ? props.theme.borders.thingray : 'none'};
  margin: ${props => (props.isDragging ? '0' : '1px 0')};
  max-height: 100%;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-right: 16px;
`;

AvailableOtherSeriesMenu.ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 0.9fr) auto 25px;
  border-top: ${props =>
    props.isFirst ? 'none' : props.theme.borders.thingray};
  align-items: center;
`;

AvailableOtherSeriesMenu.CoreSeriesLabel = styled.span`
  color: #9a9a9a;
  margin-right: 5px;
  text-align: right;
`;

AvailableOtherSeriesMenu.OptionWrapper = styled.div`
  border: ${props => props.theme.borders.thingray};
  background-color: #efefef;
  margin-bottom: ${props => (props.isOnlyCoreSeries ? '10px' : '0')};
`;

AvailableOtherSeriesMenu.SeriesName = styled.span`
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ color }) => color ?? 'inherit'};
  text-decoration: ${({ isNotExists }) =>
    isNotExists ? 'line-through' : 'none'};
`;

AvailableOtherSeriesMenu.StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: white;
    border: 2px solid red;
    border-radius: 8px;
    padding: 0 8px;
    position: relative;
    top: -30px;
  }
`;

AvailableOtherSeriesMenu.Wrapper = styled.div`
  border-top: 1px solid #c1c1c1;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.05);
  max-height: 100%;
  align-self: end;
  display: flex;

  .match {
    font-weight: bold;
    text-decoration: underline;
  }

  > div {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-width: 100%;

    > div {
      padding: 0 0 0 16px;
    }
  }
`;

AvailableOtherSeriesMenu.AddButton = styled.button`
  height: 20px;
  border-radius: 0;
  border: none;
  border-left: ${props => props.theme.borders.thingray};
  padding: 0;
  min-width: 23px;
  outline: none;
`;

AvailableOtherSeriesMenu.Test = styled.div``;

export default AvailableOtherSeriesMenu;
