import { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import { VARIANCE_TRELLIS } from 'modules/chart/models/chart';
import {
  OIL,
  GAS,
  WATER,
  BOE,
  GOR,
  GLR,
  WATERCUT,
} from 'modules/phase/models/phase';
import { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';
import { Dialog } from './dialog';

export const NOTES_PANEL = 'notesPanel';
export const DRILLDOWN_PANEL = 'drilldownPanel';

export type IndexDialog = {
  show: boolean;
  index: number;
};

export type IdIndexDialog = {
  show: boolean;
  id: string;
  index: number;
};

export type IdDialog = {
  show: boolean;
  id: string;
};

export type Mode = {
  isOn: boolean;
  subject?: string;
};

export const DEFAULT_TRELLISES = {
  [BOE]: false,
  [OIL]: true,
  [GAS]: true,
  [GOR]: false,
  [GLR]: false,
  [WATER]: false,
  [WATERCUT]: false,
  [VARIANCE_TRELLIS]: false,
};

export const EMPTY_TRELLISES = {
  [BOE]: false,
  [OIL]: false,
  [GAS]: false,
  [GOR]: false,
  [GLR]: false,
  [WATER]: false,
  [WATERCUT]: false,
  [VARIANCE_TRELLIS]: false,
};

export const ADDITION_SUBJECT = {
  allocIssue: 'allocIssue',
  capacity: 'capacity',
  variance: 'variance',
};

export type TrellisTooltipData = {
  day: Date;
  production: number;
  capacity: number;
  forecast?: number;
  variance: number | null;
  clientX: number;
  clientY: number;
  trellis: string;
  varianceOptionId: string;
};
export type DataForSeriesTooltip = {
  name: string;
  value: number;
  color: string;
}[];

export type DataSeriesTooltipData = {
  day: Date;
  clientX: number;
  clientY: number;
  data: {
    name: string;
    value: number;
    color: string;
  }[];
};

export type RibbonTooltipData = {
  dayStart?: Date;
  dayEnd?: Date | undefined;
  ribbonType: string;
  optionType?: string;
  clientX: number;
  clientY: number;
};

export type DataFetchingStatus = {
  isCapacityEventsFetched: boolean;
  isVarianceEventsFetched: boolean;
  isExternalForecastFetched: boolean;
  isRibbonsFetched: boolean;
  isProductionFetched: boolean;
};

export type TooltipData = {
  trellisTooltipData?: TrellisTooltipData | null;
  ribbonTooltipData?: RibbonTooltipData | null;
  dataSeriesTooltipData?: DataSeriesTooltipData | null;
};

export type UIState = {
  activePanel: string | null;
  leftPanelActiveTabId: string | null;
  rightPanelExpanded: boolean;
  rightPanelDialog: Dialog | null;
  rightPanelDialogs: Record<Dialog['type'], Dialog['data']>;
  rightPanelTemplates: {
    ribbon?: string[];
    capacity?: string[];
    variance?: string[];
  };
  additionMode: {
    isOn: boolean;
    subject: string | null;
  };
  allocIssueAlertWindow: IdIndexDialog;
  allocIssuesVisibility: boolean;
  lastEditedCpacityChangeEvent: CapacityChangeEvent | null;
  newlyCreatedVarianceEvent: VarianceEvent | null;
  capacityDeleteAlertWindow: IndexDialog;
  capacityCancelEditsAlertWindow: IndexDialog;
  capacitySaveAlertWindow: IndexDialog;
  currentWellId: string;
  currentGroup: { subject: string; item: string } | Record<string, never>;
  groupMode: Mode;
  noteImageDetailsOverlay: { show: boolean };
  notesMode: Mode;
  notesProductionDay: Date;
  tooltipData: TooltipData | null;
  trellises: {
    [trellis: string]: boolean;
  };
  varianceAlertWindow: IndexDialog;
  zoomInMode: Mode;
  regionOfInterestMode: Mode;
  isFilterTableView: boolean;
  isOpenFilterLayoutsPanel: boolean;
  groupChartLoadingCounter: number;
  groupVarianceChartLoadingCounter: number;
  isEventConversationOpen: boolean;
  isProductionLoading: number;
  ribbons: {
    ribbonEventAlertWindow: IndexDialog;
    highlightedRibbonEvent: IndexDialog;
    selectedRibbons: { [riboons: string]: boolean };
  };
  isXAxisDragging: boolean;
  isYAxisDragging: boolean;
  wasDragging: boolean;
  highlightedAllocIssue: IdDialog;
  highlightedAllocIssueDivider: IdDialog;
  highlightedCapacity: IndexDialog;
  highlightedCapacityDivider: IndexDialog;
  highlightedVariance: IndexDialog;
  highlightedVarianceDivider: IndexDialog;
  dataFetchingStatus: DataFetchingStatus;
  chartWidth: number;
};

export const initialState: UIState = {
  activePanel: null,
  leftPanelActiveTabId: null,
  rightPanelExpanded: true,
  rightPanelDialog: { type: 'Legend' },
  rightPanelDialogs: {} as Record<Dialog['type'], Dialog['data']>,
  rightPanelTemplates: {},
  additionMode: {
    isOn: false,
    subject: null,
  },
  allocIssueAlertWindow: { show: false, index: 0, id: '' },
  allocIssuesVisibility: true,
  lastEditedCpacityChangeEvent: null,
  newlyCreatedVarianceEvent: null,
  capacityDeleteAlertWindow: {
    show: false,
    index: 0,
  },
  capacityCancelEditsAlertWindow: {
    show: false,
    index: 0,
  },
  capacitySaveAlertWindow: {
    show: false,
    index: 0,
  },
  currentWellId: '',
  currentGroup: {},
  groupMode: {
    isOn: false,
    subject: '',
  },
  highlightedAllocIssue: {
    show: false,
    id: '',
  },
  highlightedAllocIssueDivider: {
    show: false,
    id: '',
  },
  highlightedCapacity: {
    show: false,
    index: 0,
  },
  highlightedCapacityDivider: {
    show: false,
    index: 0,
  },
  highlightedVariance: {
    show: false,
    index: 0,
  },
  highlightedVarianceDivider: {
    show: false,
    index: 0,
  },
  noteImageDetailsOverlay: { show: false },
  notesMode: { isOn: false },
  notesProductionDay: new Date(),
  regionOfInterestMode: { isOn: false },
  ribbons: {
    ribbonEventAlertWindow: {
      show: false,
      index: 0,
    },
    selectedRibbons: {},
    highlightedRibbonEvent: {
      show: false,
      index: 0,
    },
  },
  varianceAlertWindow: {
    show: false,
    index: 0,
  },
  tooltipData: null,
  trellises: DEFAULT_TRELLISES,
  zoomInMode: { isOn: false },
  isEventConversationOpen: false,
  isFilterTableView: false,
  isOpenFilterLayoutsPanel: true,
  groupChartLoadingCounter: 0,
  groupVarianceChartLoadingCounter: 0,
  isProductionLoading: 0,
  isXAxisDragging: false,
  isYAxisDragging: false,
  wasDragging: false,
  dataFetchingStatus: {
    isCapacityEventsFetched: false,
    isVarianceEventsFetched: false,
    isExternalForecastFetched: false,
    isRibbonsFetched: false,
    isProductionFetched: false,
  },
  chartWidth: 0,
};
