import { all, put, takeLatest } from 'redux-saga/effects';

import { AUTHENTICATED } from 'modules/app/AppActions';

import {
  fetchCapacityCategories,
  fetchVarianceCategories,
} from './CategoryActions';

function* fetchCategoriesSaga(): Generator<any, any, any> {
  yield put(fetchCapacityCategories());
  yield put(fetchVarianceCategories());
}

function* categorySagas(): Generator<any, any, any> {
  yield all([takeLatest(AUTHENTICATED, fetchCategoriesSaga)]);
}

export default categorySagas;
