import * as React from 'react';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import SeriesPill from 'modules/chart/components/SeriesPill';
import { ListChartOptions } from 'modules/chartOptions/models';
import { NormalizedSeriesMapping } from '../models';

interface GroupSeriesChartProps {
  chartOptions: ListChartOptions;
  groupOptions: string[];
  height: number;
  isLast: boolean;
  onPillClick: () => void;
  seriesMapping: NormalizedSeriesMapping;
}

const GroupSeriesChart = ({
  chartOptions,
  groupOptions,
  seriesMapping,
  height,
  isLast,
  onPillClick,
}: GroupSeriesChartProps) => {
  return (
    <>
      <GroupSeriesChart.Container height={height} isLast={isLast}>
        <GroupSeriesChart.MessageContainer>
          <GroupSeriesChart.Message>
            Can&apos;t display for multiple wells.
          </GroupSeriesChart.Message>
        </GroupSeriesChart.MessageContainer>

        <GroupSeriesChart.YAxisContainer></GroupSeriesChart.YAxisContainer>
        <GroupSeriesChart.PillsContainer>
          {groupOptions.map(id => (
            <SeriesPill
              key={id}
              color={chartOptions[id].customColor || seriesMapping[id].color}
              onPillClick={onPillClick}
              text={`${seriesMapping[id].displayName}, ${seriesMapping[id].units}`}
            />
          ))}
        </GroupSeriesChart.PillsContainer>
      </GroupSeriesChart.Container>
    </>
  );
};

GroupSeriesChart.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

GroupSeriesChart.MessageContainer = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`;

GroupSeriesChart.Message = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

GroupSeriesChart.PillsContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 5px;
  display: flex;

  > div {
    position: initial;
    margin-left: 10px;
  }
`;

GroupSeriesChart.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

export default React.memo<GroupSeriesChartProps>(GroupSeriesChart);
