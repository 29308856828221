import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { throttle } from 'throttle-debounce';

import useComponentSize from 'hooks/useComponentSize';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import {
  getDrilldownLoadingStatus,
  getDrilldownTableParams,
  getRawCommonWellDrilldownTable,
  getRawVarianceDrilldownTable,
} from 'modules/drilldownTable/DrilldownTableReducer';
import {
  initDrilldownTableFetch,
  resetMarkedRows,
} from 'modules/drilldownTable/DrilldownTableActions';

import { getExtremeDates } from 'modules/production/ProductionReducer';
import { clearShareId } from 'modules/settings/SettingsActions';
import { getShareId } from 'modules/settings/SettingsReducer';
import { getAllWells } from 'modules/well/WellReducer';
import { highlightOffEverything, resetActivePanel } from 'modules/ui/UIActions';
import { getActivePanel } from 'modules/ui/UIReducer';
import { DRILLDOWN_PANEL } from 'modules/ui/models/ui';
import useRightPanel from 'modules/ui/hooks/useRightPanel';

import { LEFT_PANEL_WIDTH } from '../models/dashboard';
import ProductionChart from '../containers/ProductionChart';
import RightPanels from '../containers/RightPanels';
import ShareLinkWindow from '../components/ShareLinkWindow';
import { PreventProvider } from 'context/PreventContext';
import { getUrlValues } from 'modules/router/RouterReducer';
import LeftPanels from '../containers/LeftPanels';
import useClickOutside from 'hooks/useClickOutside';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const rightPanel = useRightPanel();
  const activePanel = useSelector(getActivePanel);
  const appConfig = useSelector(getAppConfig);
  const drilldownLoadingStatus = useSelector(getDrilldownLoadingStatus);
  const varianceDrilldownTable = useSelector(getRawVarianceDrilldownTable);
  const drilldownTableParams = useSelector(getDrilldownTableParams);
  const extremeDates = useSelector(getExtremeDates);
  const shareId = useSelector(getShareId);
  const wells = useSelector(getAllWells);
  const isAllowedToMark = useSelector(getAppConfig).drilldownMarking;

  const isWellTableAvailable = !R.isEmpty(
    useSelector(getRawCommonWellDrilldownTable),
  );

  const onShareWindowClose = React.useCallback(
    () => dispatch(clearShareId()),
    [dispatch],
  );
  const dashboardEl = React.useRef(null);
  const drilldownPanelEl = React.useRef<HTMLElement | null>(null);

  const width = useComponentSize(dashboardEl).width || 0;

  const parsedSearchParams = useSelector(getUrlValues);

  const hideDrilldown = !parsedSearchParams.search.drilldown;

  const onInitDrilldownTableFetch = React.useCallback(
    data => {
      dispatch(initDrilldownTableFetch(data));
    },
    [dispatch],
  );
  const throttleInitDrilldownTableFetch = React.useCallback(
    throttle(1000, true, data => onInitDrilldownTableFetch(data)),
    [],
  );

  React.useEffect(() => {
    if (
      R.isEmpty(appConfig) ||
      !extremeDates ||
      drilldownLoadingStatus.isWellTableFetching ||
      isWellTableAvailable
      // ||R.isEmpty(columnMapping)
    ) {
      return;
    } else if (R.isEmpty(varianceDrilldownTable)) {
      dispatch(highlightOffEverything());
      throttleInitDrilldownTableFetch(drilldownTableParams);
    }
    return;
  }, [
    extremeDates,
    drilldownTableParams,
    varianceDrilldownTable,
    wells,
    dispatch,
    drilldownLoadingStatus.isWellTableFetching,
    appConfig,
    isWellTableAvailable,
    throttleInitDrilldownTableFetch,
  ]);

  React.useEffect(() => {
    if (isAllowedToMark) return;
    dispatch(resetMarkedRows());
  }, [isAllowedToMark]);

  const resetActiveDrilldownPanel = React.useCallback(
    (e: MouseEvent) => {
      const { target } = e;
      if (
        !(target instanceof Element) ||
        target.id === 'region-min' ||
        target.id === 'region-max'
      )
        return;
      if (activePanel && activePanel === DRILLDOWN_PANEL) {
        dispatch(resetActivePanel());
      }
    },
    [activePanel, dispatch],
  );

  useClickOutside(
    drilldownPanelEl.current ? [drilldownPanelEl.current] : [],
    resetActiveDrilldownPanel,
    'mousedown',
    'modal-window-v2',
  );

  return (
    extremeDates && (
      <PreventProvider
        shouldIgnore={
          !(
            rightPanel.isDialogOfType('WellNotes') ||
            rightPanel.isDialogOfType('RibbonEvent') ||
            rightPanel.isDialogOfType('VarianceChangeEvent') ||
            rightPanel.isDialogOfType('CapacityChangeEvent')
          )
        }
      >
        <DashboardPage.Container ref={dashboardEl} id="imageDetailRoot">
          {!hideDrilldown && (
            <DashboardPage.LeftSidePanelWrapper ref={drilldownPanelEl}>
              <LeftPanels />
            </DashboardPage.LeftSidePanelWrapper>
          )}

          <ProductionChart
            hideDrilldown={hideDrilldown}
            dashboardWidth={width}
          />

          <RightPanels />

          {shareId && (
            <ShareLinkWindow
              handleClose={onShareWindowClose}
              shareId={shareId}
            />
          )}
        </DashboardPage.Container>
      </PreventProvider>
    )
  );
};

DashboardPage.Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  padding: 5px;
  display: flex;
`;

DashboardPage.LeftSidePanelWrapper = styled.div`
  position: relative;
  z-index: 100;
  width: ${LEFT_PANEL_WIDTH}px;
  margin-right: 5px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export default DashboardPage;
