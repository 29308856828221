import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import type { Action, Selector } from 'store/models';

import {
  getDrilldownTableParams,
  getIsMarkingRows,
} from 'modules/drilldownTable/DrilldownTableReducer';
import {
  getCurrentGroup,
  getCurrentWellId,
  getGroupMode,
} from 'modules/ui/UIReducer';

import {
  CHANGE_VISIBILITY_STATUS,
  CLEAR_GROUP_FORECASTS,
  FETCH_WELL_FORECAST,
  FETCH_WELL_FORECAST_DATE_RANGE,
  namespace,
  POPULATE_GROUP_FORECAST,
} from './ExternalForecastActions';
import { ForecastData, ForecastPoint } from './models';
import { normalizeForecastData } from './utils';

export const STATE_KEY = 'externalForecas';

const filterRegExp = new RegExp(`${namespace}/`);

export interface ExternalForecastState {
  forecastVisibility: boolean;
  forecast: {
    [wellId: string]: { date: ForecastData; isPartialData: boolean };
  };
  groupForecast: {
    net: {
      [subject: string]: {
        [groupName: string]: ForecastData;
      };
    };
    gross: {
      [subject: string]: {
        [groupName: string]: ForecastData;
      };
    };
  };
  fetchingStatus: {
    forecast: {
      [wellId: string]: boolean;
    };
    groupForecast: {
      net: {
        [subject: string]: {
          [groupName: string]: boolean;
        };
      };
      gross: {
        [subject: string]: {
          [groupName: string]: boolean;
        };
      };
    };
  };
}

const initialState = {
  forecastVisibility: false,
  forecast: {},
  groupForecast: { net: {}, gross: {} },
  fetchingStatus: {
    forecast: {},
    groupForecast: {
      net: {},
      gross: {},
    },
  },
};

const ExternalForecastReducer = (
  state: ExternalForecastState = initialState,
  action: Action,
) => {
  const { payload } = action;
  switch (action.type) {
    case CHANGE_VISIBILITY_STATUS: {
      return R.assoc('forecastVisibility', payload.status, state);
    }
    case `${FETCH_WELL_FORECAST_DATE_RANGE}_SUCCESS`: {
      const { forecastData } = getGraphqlPayload(action);
      const data = JSON.parse(forecastData);
      if (!R.isEmpty(data)) {
        const firstDataIndex = data.findIndex(
          el => el.oil !== 0 || el.gas !== 0 || el.water !== 0,
        );

        let lastDataIndex: number | undefined = undefined;

        for (let i = data.length - 1; i >= 0; i--) {
          const value = data[i];
          if (Object.values(value).filter(v => v !== 0).length <= 2) continue;

          lastDataIndex = i;
          break;
        }

        const normalizedData = normalizeForecastData(
          data.slice(firstDataIndex, lastDataIndex),
        );

        const wellId = data[0].wellId;
        return R.compose(
          R.assocPath(['forecast', wellId], {
            data: normalizedData,
            isPartialData: true,
          }),
          R.assocPath(['fetchingStatus', 'forecast', wellId], true),
        )(state);
      }
      const {
        payload: { wellId },
      } = getGraphqlPrevActionVariables(action);
      return R.assocPath(['fetchingStatus', 'forecast', wellId], true, state);
    }
    case `${FETCH_WELL_FORECAST}_SUCCESS`: {
      const { forecastData } = getGraphqlPayload(action);
      const data = JSON.parse(forecastData);
      if (!R.isEmpty(data)) {
        const normalizedData = normalizeForecastData(data);
        const wellId = data[0].wellId;
        return R.compose(
          R.assocPath(['forecast', wellId], {
            data: normalizedData,
            isPartialData: true,
          }),
          R.assocPath(['fetchingStatus', 'forecast', wellId], true),
        )(state);
      }
      const {
        payload: { wellId },
      } = getGraphqlPrevActionVariables(action);
      return R.assocPath(['fetchingStatus', 'forecast', wellId], true, state);
    }
    case POPULATE_GROUP_FORECAST: {
      const { groupSubject, groupName, forecastData, isNet } = action.payload;
      if (isNet) {
        return R.compose(
          R.assocPath(
            ['groupForecast', 'net', groupSubject, groupName],
            forecastData,
          ),
          R.assocPath(
            ['fetchingStatus', 'groupForecast', 'net', groupSubject, groupName],
            true,
          ),
        )(state);
      }
      return R.compose(
        R.assocPath(
          ['groupForecast', 'gross', groupSubject, groupName],
          forecastData,
        ),
        R.assocPath(
          ['fetchingStatus', 'groupForecast', 'gross', groupSubject, groupName],
          true,
        ),
      )(state);
    }
    case CLEAR_GROUP_FORECASTS: {
      if (!action.payload) {
        return R.compose(
          R.assoc('groupForecast', initialState.groupForecast),
          R.assocPath(
            ['fetchingStatus', 'groupForecast'],
            initialState.fetchingStatus.groupForecast,
          ),
        )(state);
      }
      const { subject, item } = action.payload;
      const netChart = R.path(['groupForecast', 'net', subject, item], state);
      const grossChart = R.path(
        ['groupForecast', 'gross', subject, item],
        state,
      );
      const netChartStatus = R.pathOr(
        false,
        ['fetchingStatus', 'groupForecast', 'net', subject, item],
        state,
      );
      const grossChartStatus = R.pathOr(
        false,
        ['fetchingStatus', 'groupForecast', 'gross', subject, item],
        state,
      );
      return R.compose(
        R.assocPath(['groupForecast', 'net'], {
          [subject]: { [item]: netChart },
        }),
        R.assocPath(['groupForecast', 'gross'], {
          [subject]: { [item]: grossChart },
        }),
        R.assocPath(['fetchingStatus', 'groupForecast', 'net'], {
          [subject]: { [item]: netChartStatus },
        }),
        R.assocPath(['fetchingStatus', 'groupForecast', 'gross'], {
          [subject]: { [item]: grossChartStatus },
        }),
      )(state);
    }
    default: {
      return state;
    }
  }
};

const getForecastState = (state: any): ExternalForecastState =>
  state[STATE_KEY];

export const getForecastStatus = createSelector(
  getForecastState,
  state => state.forecastVisibility,
);

export const getForecast = createSelector(
  state => state,
  getCurrentWellId,
  (state: Record<string, any>, wellId) => {
    const forecastData = R.pathOr(
      [],
      [STATE_KEY, 'forecast', wellId, 'data'],
      state,
    );

    return forecastData;
  },
);

export const getForecastFetchingStatus = createSelector(
  getForecastState,
  getGroupMode,
  getCurrentWellId,
  getCurrentGroup,
  getDrilldownTableParams,
  getIsMarkingRows,
  (
    state: Record<string, any>,
    groupMode,
    wellId,
    currentGroup,
    drilldownParams,
    isMarkingRows,
  ) => {
    if (groupMode.isOn) {
      const grossNet = R.pathOr(
        'gross',
        ['grossNet'],
        drilldownParams,
      ).toLowerCase();
      const subject = isMarkingRows ? '_marked' : currentGroup.subject;
      const item = isMarkingRows ? '_marked' : currentGroup.item;
      return R.pathOr(
        false,
        ['fetchingStatus', 'groupForecast', grossNet, subject, item],
        state,
      );
    }
    return R.pathOr(false, ['fetchingStatus', 'forecast', wellId], state);
  },
);

export const getForecastNri: Selector<ForecastPoint[]> = createSelector(
  getForecast,
  (_, props) => props.wellId,
  state => state.well.wells || [],
  (forecast, wellId, wells) => {
    if (R.isEmpty(wells) || R.isEmpty(forecast)) return [];
    if (!wells[wellId]) return forecast;
    const wellInfo = wells[wellId];
    const nri = wellInfo.NRI === '' ? 1 : wellInfo.NRI;
    const netForecastData = forecast.map(forecastPoint => ({
      ...forecastPoint,
      boe: forecastPoint.boe * nri,
      oil: forecastPoint.oil * nri,
      gas: forecastPoint.gas * nri,
      total_liquid: forecastPoint.total_liquid * nri,
      water: forecastPoint.water * nri,
    }));

    return netForecastData;
  },
);

export const getGroupForecast: Selector<ForecastData> = createSelector(
  (state: any) => state,
  (_, params) => params,
  getDrilldownTableParams,
  (
    state,
    { subject, item }: { subject: string; item: string },
    drilldownParams,
  ) => {
    const grossNet = R.pathOr(
      'gross',
      ['grossNet'],
      drilldownParams,
    ).toLowerCase();
    const chart = R.pathOr(
      [],
      [STATE_KEY, 'groupForecast', grossNet, subject, item],
      state,
    );

    return chart;
  },
);

export const getHasForecastDataForCurrentWell: Selector<any> = createSelector(
  getCurrentWellId,
  getForecastState,
  (wellId, forecastState) => !!forecastState.forecast[wellId],
);

export default filterActions(ExternalForecastReducer as any, action =>
  action.type.match(filterRegExp),
);
