import gql from 'graphql-tag';

import type {
  GraphqlQueryAction,
  Action,
  GraphqlMutationAction,
} from 'store/models';
import type { RibbonEvent } from './models';

export const namespace = 'RIBBON';

export const FETCH_RIBBONS = `${namespace}/FETCH_RIBBONS`;
export const FETCH_WELL_RIBBON_EVENTS = `${namespace}/FETCH_WELL_RIBBON_EVENTS`;
export const FETCH_WELL_RIBBON_EVENTS_DATE_RANGE = `${namespace}/FETCH_WELL_RIBBON_EVENTS_DATE_RANGE`;
export const FETCH_LIST_RIBBON_OPTION = `${namespace}/FETCH_LIST_RIBBON_OPTION`;
export const POPULATE_RIBBON_EVENTS = `${namespace}/POPULATE_RIBBON_EVENTS`;
export const DELETE_RIBBON_EVENT_LOCALLY = `${namespace}/DELETE_RIBBON_EVENT_LOCALLY`;
export const UPDATE_RIBBON_EVENT_DATES_LOCALLY = `${namespace}/UPDATE_RIBBON_EVENT_DATES_LOCALLY`;
export const CREATE_REMOTE_RIBBON_EVENT = `${namespace}/CREATE_REMOTE_RIBBON_EVENT`;
export const UPDATE_REMOTE_RIBBON_EVENT = `${namespace}/UPDATE_REMOTE_RIBBON_EVENT`;
export const DELETE_REMOTE_RIBBON_EVENT = `${namespace}/DELETE_REMOTE_RIBBON_EVENT`;
export const UPDATE_REMOTE_RIBBON_DESCRIPTION = `${namespace}/UPDATE_REMOTE_RIBBON_DESCRIPTION`;
export const UPDATE_RIBBON_EVENT_DESCRIPTION_LOCALLY = `${namespace}/UPDATE_RIBBON_EVENT_DESCRIPTION_LOCALLY`;
export const UPDATE_RIBBON_EVENT_OPTION_LOCALLY = `${namespace}/UPDATE_RIBBON_EVENT_OPTION_LOCALLY`;
export const UPDATE_RIBBON_EVENT_NOTES_LOCALLY = `${namespace}/UPDATE_RIBBON_EVENT_NOTES_LOCALLY`;
export const UPDATE_RIBBON_EVENT_EXTRA_INPUTS_DATA_LOCALLY = `${namespace}/UPDATE_RIBBON_EVENT_EXTRA_INPUTS_DATA_LOCALLY`;
export const UPDATE_RIBBON_EVENT_NO_END_DATE_LOCALLY = `${namespace}/UPDATE_RIBBON_EVENT_NO_END_DATE_LOCALLY`;
export const CREATE_RIBBON_EVENT_LOCALLY = `${namespace}/CREATE_RIBBON_EVENT_LOCALLY`;
const FETCH_LIST_RIBOONS_QUERY = gql`
  query {
    listRibbons {
      id
      name
      order
      readOnly
    }
  }
`;
const FETCH_LIST_RIBOON_OPTION_QUERY = gql`
  query {
    listRibbonOptions {
      id
      ribbonId
      type
      color
      extraInputsType
      order
    }
  }
`;
const FETCH_LIST_RIBOON_EVENTS_QUERY = gql`
  query ($payload: RibbonEventInput!) {
    listRibbonEvents(data: $payload) {
      id
      wellId
      ribbonId
      ribbonOptionId
      dayStart
      dayEnd
      extraInputsData
      notes
      noEndDate
      description
    }
  }
`;

const FETCH_LIST_RIBOON_EVENTS_DATE_RANGE_QUERY = gql`
  query ($payload: RibbonEventDateRangeInput!) {
    listRibbonEventsDateRange(data: $payload) {
      id
      wellId
      ribbonId
      ribbonOptionId
      dayStart
      dayEnd
      extraInputsData
      notes
      noEndDate
      description
    }
  }
`;

const UPDATE_REMOTE_RIBBON_EVENT_MUTATION = gql`
  mutation ($payload: UpdateRibbonEventInput!) {
    updateRibbonEvent(data: $payload) {
      id
      wellId
      ribbonId
      ribbonOptionId
      dayStart
      dayEnd
      extraInputsData
      notes
      noEndDate
      description
    }
  }
`;
const DELETE_REMOTE_RIBBON_EVENT_MUTATION = gql`
  mutation ($id: ID!) {
    deleteRibbonEvent(id: $id)
  }
`;

const CREATE_REMOTE_RIBBON_EVENT_MUTATION = gql`
  mutation ($payload: CreateRibbonEventInput!) {
    createRibbonEvent(data: $payload) {
      id
      wellId
      ribbonId
      ribbonOptionId
      dayStart
      dayEnd
      extraInputsData
      notes
      noEndDate
      description
    }
  }
`;

const UPDATE_REMOTE_RIBBON_DESCRIPTION_MUTATION = gql`
  mutation ($payload: UpdateRibbonEventDescriptionInput!) {
    updateRibbonEventDescription(data: $payload) {
      id
      description
    }
  }
`;

type createRemoteRibbonEventAC = (payload: any) => GraphqlMutationAction;

export const createRemoteRibbonEvent: createRemoteRibbonEventAC = payload => ({
  type: CREATE_REMOTE_RIBBON_EVENT,
  payload: {
    key: 'createRibbonEvent',
    graphql: {
      mutation: CREATE_REMOTE_RIBBON_EVENT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

type deleteRemoteRibbonEventAC = (id: string) => GraphqlMutationAction;

export const deleteRemoteRibbonEvent: deleteRemoteRibbonEventAC = id => ({
  type: DELETE_REMOTE_RIBBON_EVENT,
  payload: {
    graphql: {
      mutation: DELETE_REMOTE_RIBBON_EVENT_MUTATION,
      variables: {
        id,
      },
    },
  },
});

type fethcActions = () => GraphqlQueryAction;

export const fetchRibbons: fethcActions = () => ({
  type: FETCH_RIBBONS,
  payload: {
    key: 'listRibbons',
    graphql: {
      query: FETCH_LIST_RIBOONS_QUERY,
    },
  },
});

type fetchWellRibonEventsAC = (payload: {
  wellId: string;
}) => GraphqlQueryAction;

export const fetchWellRibbonEvents: fetchWellRibonEventsAC = payload => ({
  type: FETCH_WELL_RIBBON_EVENTS,
  payload: {
    key: 'listRibbonEvents',
    graphql: {
      query: FETCH_LIST_RIBOON_EVENTS_QUERY,
      variables: {
        payload,
      },
    },
  },
});

type fetchWellRibonEventsDateRangeAC = (payload: {
  wellId: string;
  from: Date;
  to: Date;
  ribbonIds: string[];
}) => GraphqlQueryAction;

export const fetchWellRibbonEventsDateRange: fetchWellRibonEventsDateRangeAC =
  payload => ({
    type: FETCH_WELL_RIBBON_EVENTS_DATE_RANGE,
    payload: {
      key: 'listRibbonEventsDateRange',
      graphql: {
        query: FETCH_LIST_RIBOON_EVENTS_DATE_RANGE_QUERY,
        variables: {
          payload,
        },
      },
    },
  });

export const fetchRibbonOptions: fethcActions = () => ({
  type: FETCH_LIST_RIBBON_OPTION,
  payload: {
    key: 'listRibbonOptions',
    graphql: {
      query: FETCH_LIST_RIBOON_OPTION_QUERY,
    },
  },
});

type updateRemoteRibbonEventAC = (data: RibbonEvent) => GraphqlMutationAction;

export const updateRemoteRibbonEvent: updateRemoteRibbonEventAC = payload => ({
  type: UPDATE_REMOTE_RIBBON_EVENT,
  payload: {
    graphql: {
      mutation: UPDATE_REMOTE_RIBBON_EVENT_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

type updateRemoteRibbonDescriptionAC = (payload: {
  id: string;
  description: string | null;
}) => GraphqlMutationAction;

export const updateRemoteRibbonDescription: updateRemoteRibbonDescriptionAC =
  payload => ({
    type: UPDATE_REMOTE_RIBBON_DESCRIPTION,
    payload: {
      graphql: {
        key: 'updateRibbonEventDescription',
        mutation: UPDATE_REMOTE_RIBBON_DESCRIPTION_MUTATION,
        variables: {
          payload,
        },
      },
    },
  });

type updateRibbonEventDatesLocallyAC = (data: {
  wellId: string;
  ribbonEventId: string;
  dates: Date[];
}) => Action;

export const updateRibbonEventDatesLocally: updateRibbonEventDatesLocallyAC =
  payload => ({
    type: UPDATE_RIBBON_EVENT_DATES_LOCALLY,
    payload,
  });

type deleteRibbonEventLocallyAC = (payload: {
  wellId: string;
  ribbonEventId: string;
}) => Action;

export const deleteRibbonEventLocally: deleteRibbonEventLocallyAC =
  payload => ({
    type: DELETE_RIBBON_EVENT_LOCALLY,
    payload,
  });

type updateRibbonEventOptionLocallyAC = (payload: {
  wellId: string;
  ribbonEventId: string;
  ribbonOptionId: string;
}) => Action;

export const updateRibbonEventOptionLocally: updateRibbonEventOptionLocallyAC =
  payload => ({
    type: UPDATE_RIBBON_EVENT_OPTION_LOCALLY,
    payload,
  });

type updateRibbonEventNotesLocallyAC = (payload: {
  wellId: string;
  ribbonEventId: string;
  notes: string;
}) => Action;

export const updateRibbonEventNotesLocally: updateRibbonEventNotesLocallyAC =
  payload => ({
    type: UPDATE_RIBBON_EVENT_NOTES_LOCALLY,
    payload,
  });

type updateRibbonEventExtraInputsLocallyAC = (payload: {
  wellId: string;
  ribbonEventId: string;
  extraInputsData: Record<string, any>;
}) => Action;

export const updateRibbonEventExtraInputsLocally: updateRibbonEventExtraInputsLocallyAC =
  payload => ({
    type: UPDATE_RIBBON_EVENT_EXTRA_INPUTS_DATA_LOCALLY,
    payload,
  });

type updateRibbonEventNoEndDateLocallyAC = (payload: {
  wellId: string;
  ribbonEventId: string;
  noEndDate: boolean;
}) => Action;

export const updateRibbonEventNoEndDateLocally: updateRibbonEventNoEndDateLocallyAC =
  payload => ({
    type: UPDATE_RIBBON_EVENT_NO_END_DATE_LOCALLY,
    payload,
  });

type populateRibbonEventsAC = (payload: {
  wellId: string;
  ribbonEvents: {
    [id: string]: RibbonEvent;
  };
}) => Action;

export const populateRibbonEvents: populateRibbonEventsAC = payload => ({
  type: POPULATE_RIBBON_EVENTS,
  payload,
});

type createRibbonEventLocallyAC = ({ wellId: string, date: Date }) => Action;

export const createRibbonEventLocally: createRibbonEventLocallyAC =
  payload => ({
    type: CREATE_RIBBON_EVENT_LOCALLY,
    payload,
  });

type updateRibbonEventDescriptionLocallyAC = (payload: {
  id: string;
  description: string | null;
  wellId: string;
}) => Action;

export const updateRibbonEventDescriptionLocally: updateRibbonEventDescriptionLocallyAC =
  payload => ({
    type: UPDATE_RIBBON_EVENT_DESCRIPTION_LOCALLY,
    payload,
  });
