import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import { ArrowIcon, CloseIcon } from 'components/Icons';

type RightPanelProps = {
  children: React.ReactNode;
  isShown: boolean;
  isShownLeftArrow?: boolean;
  isShownRightArrow?: boolean;
  handleKeyDown?: (e: KeyboardEvent) => void;
  onLeftArrowClick?: () => void;
  onRightArrowClick?: () => void;
  onDialogClose: () => void;
  title: string;
};

const RightPanel = ({
  children,
  isShown,
  isShownLeftArrow,
  isShownRightArrow,
  handleKeyDown,
  onDialogClose,
  onLeftArrowClick,
  onRightArrowClick,
  title,
}: RightPanelProps) => {
  const shownCloseButton =
    isShown &&
    (window.location.pathname.includes('/dashboard') ||
      window.location.pathname.includes('/filter'));

  React.useEffect(() => {
    if (handleKeyDown) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (handleKeyDown) {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [handleKeyDown]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RightPanel.Card>
      <RightPanel.Header>
        <RightPanel.TitleWrapper>
          <RightPanel.Title>{title}</RightPanel.Title>
          <RightPanel.ArrowContainer>
            <RightPanel.ButtonWrapper>
              {isShownLeftArrow && onLeftArrowClick ? (
                <Button transparent onClick={onLeftArrowClick}>
                  <ArrowIcon />
                </Button>
              ) : null}
            </RightPanel.ButtonWrapper>
            <RightPanel.ButtonWrapper>
              {isShownRightArrow && onRightArrowClick ? (
                <RightPanel.RightArrow>
                  <Button
                    transparent
                    onClick={() => {
                      onRightArrowClick();
                    }}
                  >
                    <ArrowIcon />
                  </Button>
                </RightPanel.RightArrow>
              ) : null}
            </RightPanel.ButtonWrapper>
          </RightPanel.ArrowContainer>
        </RightPanel.TitleWrapper>

        {shownCloseButton && (
          <RightPanel.CloseButton
            transparent
            onClick={onDialogClose}
            width={25}
          >
            <CloseIcon />
          </RightPanel.CloseButton>
        )}
      </RightPanel.Header>
      <RightPanel.Content>{children}</RightPanel.Content>
    </RightPanel.Card>
  );
};

RightPanel.Card = styled.div`
  background: white;
  height: 100%;
  overflow-x: hidden;
`;

RightPanel.Header = styled.div`
  height: 47px;
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
  padding: 15px 10px;
  font-size: 16px;
  border-bottom: 1px solid #000;
  font-weight: bold;
  align-items: center;
`;

RightPanel.Content = styled.div`
  height: calc(100% - 47px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

RightPanel.TitleWrapper = styled.div`
  display: flex;
  align-content: center;
`;

RightPanel.ArrowContainer = styled.div`
  display: flex;
`;

RightPanel.CloseButton = styled(Button)`
  justify-self: end;
`;

RightPanel.RightArrow = styled.div`
  transform: rotate(180deg);
  > button {
    padding: 0;
    width: 18px;
  }
`;

RightPanel.ButtonWrapper = styled.div`
  width: 20px;
  > button {
    padding: 0;
    width: 18px;
  }
`;

RightPanel.Title = styled.div`
  display: flex;
  align-items: center;
`;

export default React.memo<RightPanelProps>(RightPanel);
