import * as R from 'ramda';
import { timeFormat } from 'd3-time-format';
import { Middleware } from 'redux';

import * as AppActions from 'modules/app/AppActions';
import * as AuthActions from 'modules/auth/AuthActions';
import * as ProductionActions from 'modules/production/ProductionActions';
import type { Action } from 'store/models';

const dateFormat = timeFormat('%m/%d/%Y');

const heapMiddleware: Middleware =
  (store: any) => (next: any) => (action: Action) => {
    if (!window.heap) {
      return next(action);
    }

    const state = store.getState();
    const authData = R.omit(['token'], state.auth);
    const actionPayload = action.payload?.graphql?.variables?.payload || {};
    const wellName =
      state?.well?.wells?.[actionPayload.wellId]?.LEASE || actionPayload.wellId;

    switch (action.type) {
      case AppActions.AUTHENTICATED:
        window.heap.identify(authData.userUuid);
        window.heap.addUserProperties(authData);
        break;
      case AuthActions.LOGOUT:
        window.heap.resetIdentity();
        break;
      case ProductionActions.FETCH_WELL_PRODUCTION:
        window.heap.track('Load Production Data', {
          userName: `${state.auth.firstName} ${state.auth.lastName}`,
          wellName,
          fromDate: dateFormat(actionPayload.minDate),
          toDate: dateFormat(actionPayload.maxDate),
        });
        break;
    }

    return next(action);
  };

export default heapMiddleware;
