import { max } from 'd3-array';
import { utcDay } from 'd3-time';
import { format as d3Format } from 'd3-format';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import { Y_AXIS_WIDTH, VARIANCE_UNITS } from 'modules/chart/models/chart';
import { createNormalYScale } from 'modules/chart/utils';
import RegionOfInterest from 'modules/chart/components/RegionOfInterest';
import SeriesPill from 'modules/chart/components/SeriesPill';
import YAxis from 'modules/chart/components/YAxis';
import { ForecastData } from 'modules/externalForecast/models';
import type { ProductionPoint } from 'modules/production/models/production';
import type {
  IndexDialog,
  IdDialog,
  IdIndexDialog,
  TooltipData,
  TrellisTooltipData,
} from 'modules/ui/models/ui';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import usePrevious from 'hooks/usePrevious';

import { getTextMarksIndices } from '../utils';
import SVGWellTrellis from './SVGWellTrellis';
import SVGPhaseTrellisInteraction from './SVGPhaseTrellisInteraction';
import SecondaryInformationTooltip from 'modules/chart/components/SecondaryInformationTooltip';
import useGetCavTolltipData from 'hooks/useGetCavTolltipData';
import { normalizeProductionLost } from '../utils/normalizeProductionLost';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import { getNewlyCreatedVarianceEvent } from 'modules/ui/UIReducer';
import { getVarianceEvent } from 'modules/varianceEvent/VarianceEventReducer';
import { getLostProductionExplanation } from 'modules/appSettings/utils';

type CapacityData = { date: Date; capacity: number }[];

const varianceFormat = d3Format('-,.0f');

const getMaxDataPoint = (
  valueDataset: ProductionPoint[] = [],
  productionKey: string,
  capacityDataset: CapacityData[],
  extremeDates: { min: Date; max: Date },
  forecastData: ForecastData,
  phase: string,
) => {
  const valueFilteredData = valueDataset.filter(
    d => d.day <= extremeDates.max && d.day >= extremeDates.min,
  );
  const values = valueFilteredData.map(d => d[productionKey]);
  const tempArr: { date: Date; capacity: number }[] = [];
  const forecastValues = forecastData
    .filter(f => f.day <= extremeDates.max && f.day >= extremeDates.min)
    .map(f => f[phase]);
  const capacities = tempArr
    .concat(...capacityDataset)
    .filter(
      (f: { date: Date; capacity: number }) =>
        f.date <= extremeDates.max && f.date >= extremeDates.min,
    )
    .map((f: { date: Date; capacity: number }) => f.capacity);

  return max(values.concat(capacities.concat(forecastValues))) * 1.05 || 1000;
};

interface DivPhaseChartProps {
  allocIssues: AllocIssue[];
  allocIssueDialog: IdIndexDialog;
  allocIssuesVisibility: boolean;
  capacity: CapacityChangeEvent[];
  capacityData: CapacityData[];
  capacityDialog: { show: boolean; index: number };
  chartWasDragging: boolean;
  changeMinDrilldownTableDate: (minDate: Date) => void;
  changeMaxDrilldownTableDate: (maxDate: Date) => void;
  clearDrilldownTable: () => void;
  currentWellId: string;
  displaysRegionOfInterest: boolean;
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    grossNet: string;
    compareOption: string;
  };
  extremeDates: { min: Date; max: Date };
  eventColors: { [key: string]: string };
  finishDrag: () => void;
  forecastData: ForecastData;
  format: string;
  hasCapacityChanges: boolean;
  height: number;
  highlightedAllocIssue: IdDialog;
  highlightedAllocIssueDivider: IdDialog;
  highlightedEvent: IndexDialog;
  highlightedEventDivider: IndexDialog;
  isDisplayingForecast: boolean;
  isDragging: boolean;
  isPossibleEditCapacity: boolean;
  isPossibleEditVariance: boolean;
  isPossibleEditAlloc: boolean;
  leftOffset: number;
  nri: number;
  production: ProductionPoint[];
  startDrag: () => void;
  onAllocIssueDialogOpen: (didalogData: { index: number; id: string }) => void;
  onBFactorDrag: (bFactorData: {
    phase: string;
    capacityEventId: string;
    rate: number;
    date: Date;
  }) => void;
  onCapacityDialogClose: () => void;
  onCapacityDialogOpen: (index: number, eventId: string) => void;
  onDayInitChange: (dayData: {
    capacityEventId: string;
    newDayInit: Date;
  }) => void;
  onDeclineInitChange: (declineData: {
    phase: string;
    capacityEventId: string;
    date: Date;
    rate: number;
  }) => void;
  onAllocIssueDividerHover: (eventId: string) => void;
  onAllocIssueUpdate: (allocData: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  onCapacityDividerHover: (eventId: number) => void;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  onEventDividerHover: (eventId: number) => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  onHighlightAllocIssueDividerOff: () => void;
  onHighlightCapacityDividerOff: () => void;
  onHighlightEventDividerOff: () => void;
  onLocalAllocIssueUpdate: (allocData: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  onPillClick: () => void;
  onRateInitChange: (rateData: {
    phase: string;
    capacityEventId: string;
    newRate: number;
  }) => void;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  onVarianceEventUpdate: (varianceData: {
    dates: Date[];
    varianceEventId: string;
  }) => void;
  permissions: { [permission: string]: boolean };
  pillText: string;
  position: number;
  regionOfInterest: boolean;
  showBarHoverEffect: boolean;
  today: Date;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  varianceDialog: {
    show: boolean;
    index: number;
  };
  varianceEvents: VarianceEvent[];
  width: number;
  xScale: any;
  isLast: boolean;
  isAxisDragging: boolean;
  varianceEventSum: { oil: number; gas: number; water: number; boe: number }[];
  isTopMost: boolean;
}

const DivPhaseChart = ({
  allocIssues,
  allocIssueDialog,
  allocIssuesVisibility,
  capacity,
  capacityData,
  capacityDialog,
  changeMinDrilldownTableDate,
  changeMaxDrilldownTableDate,
  chartWasDragging,
  clearDrilldownTable,
  currentWellId,
  displaysRegionOfInterest,
  drilldownTableParams,
  extremeDates,
  eventColors,
  finishDrag,
  forecastData,
  format,
  hasCapacityChanges,
  height,
  highlightedAllocIssue,
  highlightedAllocIssueDivider,
  highlightedEvent,
  highlightedEventDivider,
  isAxisDragging,
  isDisplayingForecast,
  isDragging,
  isPossibleEditAlloc,
  isPossibleEditCapacity,
  isPossibleEditVariance,
  leftOffset,
  nri,
  production,
  startDrag,
  tooltipData,
  onBFactorDrag,
  onDayInitChange,
  onDeclineInitChange,
  onAllocIssueDividerHover,
  onCapacityDividerHover,
  onCapacityDialogClose,
  onCapacityDialogOpen,
  onVarianceDialogOpen,
  onEventDividerHover,
  onAllocIssueUpdate,
  onAllocIssueDialogOpen,
  onXAxisScaling,
  onHighlightAllocIssueDividerOff,
  onHighlightCapacityDividerOff,
  onHighlightEventDividerOff,
  onLocalAllocIssueUpdate,
  onPillClick,
  onSetTooltipData,
  onRateInitChange,
  onVarianceEventUpdate,
  pillText,
  position,
  regionOfInterest,
  showBarHoverEffect,
  today,
  trellisTitle,
  varianceDialog,
  varianceEvents,
  width,
  xScale,
  isLast,
  isTopMost,
  varianceEventSum,
}: DivPhaseChartProps) => {
  const [yAxisLinePos, setYAxisLinePos] = React.useState<null | number>(null);

  const appConfig = useSelector(getAppConfig);
  const explainTitle = React.useMemo(
    () =>
      getLostProductionExplanation({
        gasPrice: appConfig.gasPriceAssumption,
        oilPrice: appConfig.oilPriceAssumption,
      }),
    [appConfig.gasPriceAssumption, appConfig.oilPriceAssumption],
  );
  const staticCreatedVarianceEvent = useSelector(getNewlyCreatedVarianceEvent);
  const createdVarianceEvent = useSelector(state =>
    getVarianceEvent(state, {
      wellId: currentWellId,
      varianceEventId: staticCreatedVarianceEvent?.id ?? '',
    }),
  );
  const createdVarianceEventIndex = React.useMemo(
    () =>
      staticCreatedVarianceEvent
        ? varianceEvents.findIndex(v => v.id === staticCreatedVarianceEvent.id)
        : -1,
    [staticCreatedVarianceEvent],
  );

  const productionKey = trellisTitle.toLowerCase();
  const capacityVarianceData = React.useMemo(() => {
    return capacityData.map(dataset => {
      if (dataset[0] && dataset[0].date > today) return [];
      return dataset.filter(data => data.date <= today);
    });
  }, [capacityData, today]);

  const initialMaxDataPoint = React.useMemo(
    () =>
      getMaxDataPoint(
        production,
        productionKey,
        capacityData,
        extremeDates,
        forecastData,
        trellisTitle.toLocaleLowerCase(),
      ),
    [
      production,
      productionKey,
      capacityData,
      extremeDates,
      forecastData,
      trellisTitle,
    ],
  );

  const [rescaledYMaxDataPoint, setRescaledYMaxDataPoint] =
    React.useState(initialMaxDataPoint);
  const yScale = React.useMemo(
    () => createNormalYScale(height, rescaledYMaxDataPoint),
    [rescaledYMaxDataPoint, height],
  );

  const [isAdjusted, setIsAdjusted] = React.useState(false);
  const resetMax = React.useCallback(() => {
    setIsAdjusted(false);
    setRescaledYMaxDataPoint(initialMaxDataPoint);
  }, [setIsAdjusted, setRescaledYMaxDataPoint, initialMaxDataPoint]);

  const prevMaxDataPoint = usePrevious(initialMaxDataPoint);
  const prevWellId = usePrevious(currentWellId);
  const sortCapacityData = React.useMemo(() => {
    const filtredCapacity = capacityData.map((data, i) => {
      const newDate = [...data];
      i !== 0 && newDate.pop();
      return newDate;
    });
    return R.flatten(filtredCapacity).sort((a, b) =>
      utcDay.count(b.date, a.date),
    );
  }, [capacityData]);

  const eventCapacityData = React.useMemo(() => {
    let j = sortCapacityData.length - 1;
    return varianceEvents.reduce((acc, event) => {
      const tempArr: CapacityData = [];
      for (let i = j; i > 0; i--) {
        if (
          sortCapacityData[i].date >= utcDay.round(event.dayStart) &&
          sortCapacityData[i].date <
            utcDay.offset(utcDay.round(event.dayEnd), 1)
        ) {
          tempArr.push(sortCapacityData[i]);
          j = i;
        }
        if (sortCapacityData[i].date <= event.dayStart) {
          break;
        }
      }
      acc.push(tempArr);
      return acc;
    }, [] as CapacityData[]);
  }, [varianceEvents, sortCapacityData]);

  const showLine = React.useCallback(
    (rate: number) => {
      const linePosition = yScale(rate);
      setYAxisLinePos(linePosition);
    },
    [setYAxisLinePos, yScale],
  );
  const hideLine = React.useCallback(
    () => setYAxisLinePos(null),
    [setYAxisLinePos],
  );

  React.useEffect(() => {
    if (
      prevMaxDataPoint &&
      prevMaxDataPoint !== initialMaxDataPoint &&
      prevMaxDataPoint === rescaledYMaxDataPoint
    ) {
      setRescaledYMaxDataPoint(initialMaxDataPoint);
    }
  }, [prevMaxDataPoint, initialMaxDataPoint, rescaledYMaxDataPoint]);

  React.useEffect(() => {
    if (prevWellId !== currentWellId) resetMax();
  }, [currentWellId, prevWellId, resetMax]);

  const textMarks =
    R.isEmpty(production) || R.isEmpty(varianceEvents)
      ? []
      : getTextMarksIndices(
          varianceEvents,
          staticCreatedVarianceEvent
            ? { index: createdVarianceEventIndex, show: true }
            : highlightedEvent,
          highlightedEventDivider,
          staticCreatedVarianceEvent ? createdVarianceEvent : varianceDialog,
        );

  const secondaryTooltipData = useGetCavTolltipData({
    capacityVarianceData: capacityData,
    forecastData,
    production,
    tooltipData,
    trellisTitle,
  });

  return (
    <>
      <DivPhaseChart.Container height={height} isLast={isLast}>
        <DivPhaseChart.SVGWrapper className="trellis-chart-wrapper">
          <SVGWellTrellis
            allocIssues={allocIssues}
            allocIssueDialog={allocIssueDialog}
            allocIssuesVisibility={allocIssuesVisibility}
            capacity={capacity}
            capacityDialog={capacityDialog}
            capacityLineData={capacityData}
            capacityVarianceData={capacityVarianceData}
            eventCapacityData={eventCapacityData}
            eventColors={eventColors}
            forecastData={forecastData}
            height={height}
            highlightedAllocIssue={highlightedAllocIssue}
            highlightedAllocIssueDivider={highlightedAllocIssueDivider}
            isAxisDragging={isAxisDragging}
            isDisplayingForecast={isDisplayingForecast}
            isPossibleEditCapacity={isPossibleEditCapacity}
            isPossibleEditAlloc={isPossibleEditAlloc}
            maxDataPoint={rescaledYMaxDataPoint}
            onSetTooltipData={onSetTooltipData}
            production={production}
            tooltipData={tooltipData}
            trellisTitle={trellisTitle}
            showBarHoverEffect={showBarHoverEffect}
            varianceEvents={varianceEvents}
            xScale={xScale}
            yAxisLinePos={yAxisLinePos}
          />
          {!(isAxisDragging && chartWasDragging) && (
            <SVGPhaseTrellisInteraction
              allocIssueDialog={allocIssueDialog}
              allocIssues={allocIssues}
              allocIssuesVisibility={allocIssuesVisibility}
              capacity={capacity}
              capacityDialog={capacityDialog}
              drilldownTableParams={drilldownTableParams}
              eventCapacityData={eventCapacityData}
              height={height}
              isPossibleEditCapacity={isPossibleEditCapacity}
              isPossibleEditVariance={isPossibleEditVariance}
              isPossibleEditAlloc={isPossibleEditAlloc}
              isAxisDragging={isAxisDragging}
              startDrag={startDrag}
              finishDrag={finishDrag}
              isDragging={isDragging}
              leftOffset={leftOffset}
              maxDataPoint={rescaledYMaxDataPoint}
              nri={nri}
              onAllocIssueUpdate={onAllocIssueUpdate}
              onAllocIssueDividerHover={onAllocIssueDividerHover}
              onAllocIssueDialogOpen={onAllocIssueDialogOpen}
              onCapacityDialogOpen={onCapacityDialogOpen}
              onDayInitChange={onDayInitChange}
              onCapacityDividerHover={onCapacityDividerHover}
              onHighlightCapacityDividerOff={onHighlightCapacityDividerOff}
              capacityLineData={capacityData}
              onBFactorDrag={onBFactorDrag}
              onDeclineInitChange={onDeclineInitChange}
              onRateInitChange={onRateInitChange}
              onVarianceDialogOpen={onVarianceDialogOpen}
              onXAxisScaling={onXAxisScaling}
              onHighlightEventDividerOff={onHighlightEventDividerOff}
              onHighlightAllocIssueDividerOff={onHighlightAllocIssueDividerOff}
              onVarianceEventUpdate={onVarianceEventUpdate}
              onLocalAllocIssueUpdate={onLocalAllocIssueUpdate}
              onEventDividerHover={onEventDividerHover}
              production={production}
              today={today}
              trellisTitle={trellisTitle}
              varianceDialog={varianceDialog}
              varianceEvents={varianceEvents}
              xScale={xScale}
              width={width}
            />
          )}

          {displaysRegionOfInterest &&
            regionOfInterest &&
            drilldownTableParams &&
            drilldownTableParams.phase === trellisTitle && (
              <RegionOfInterest
                changeMaxDrilldownTableDate={changeMaxDrilldownTableDate}
                changeMinDrilldownTableDate={changeMinDrilldownTableDate}
                clearDrilldownTable={clearDrilldownTable}
                hasCapacityChanges={hasCapacityChanges}
                leftOffset={leftOffset}
                maxDate={drilldownTableParams.maxDate}
                minDate={drilldownTableParams.minDate}
                xScale={xScale}
                startDrag={startDrag}
                finishDrag={finishDrag}
                height={height}
                width={width}
                position={height * position}
                onCapacityDialogClose={onCapacityDialogClose}
              />
            )}
        </DivPhaseChart.SVGWrapper>

        {R.keys(textMarks).map(eventIndex => {
          const index = parseInt(eventIndex);

          const priceAssumption =
            productionKey === 'oil'
              ? appConfig.oilPriceAssumption
              : productionKey === 'gas'
              ? appConfig.gasPriceAssumption
              : null;
          const currentSum = R.path([index, productionKey], varianceEventSum);
          const productionLost =
            productionKey !== 'water'
              ? normalizeProductionLost(
                  Math.round(priceAssumption * currentSum),
                )
              : '';
          return (
            <DivPhaseChart.VarianceTextMarkWrapper
              key={trellisTitle + eventIndex + textMarks[index]}
              width={
                Math.min(
                  xScale(utcDay.offset(varianceEvents[index].dayEnd, 1)),
                  xScale.range()[1],
                ) -
                Math.max(
                  xScale(varianceEvents[index].dayStart),
                  xScale.range()[0],
                )
              }
              color="#484848"
              position={Math.max(
                xScale(varianceEvents[index].dayStart),
                xScale.range()[0],
              )}
              topOffset={isTopMost && !staticCreatedVarianceEvent ? 10 : 0}
            >
              <DivPhaseChart.Tooltip title={explainTitle}>
                {varianceFormat(
                  R.pathOr(0, [index, productionKey], varianceEventSum),
                )}{' '}
                {VARIANCE_UNITS[trellisTitle]}
                <br />
                {productionLost}
              </DivPhaseChart.Tooltip>
            </DivPhaseChart.VarianceTextMarkWrapper>
          );
        })}

        <DivPhaseChart.YAxisContainer>
          <YAxis
            format={format}
            height={height}
            hideLine={hideLine}
            isAdjusted={isAdjusted}
            isDragging={isDragging}
            isXAxisDragging={false}
            maxDataPoint={rescaledYMaxDataPoint}
            resetMax={resetMax}
            setDisplayMaxDataPoint={setRescaledYMaxDataPoint}
            setIsAdjusted={setIsAdjusted}
            showLine={showLine}
            yScale={yScale}
          />
        </DivPhaseChart.YAxisContainer>
        <SeriesPill onPillClick={onPillClick} text={pillText} />
        {tooltipData &&
          (tooltipData.trellisTooltipData ||
            tooltipData.ribbonTooltipData ||
            tooltipData.dataSeriesTooltipData) &&
          tooltipData.trellisTooltipData?.trellis !== trellisTitle && (
            <SecondaryInformationTooltip
              containerHeight={height}
              isDisplayingForecast={isDisplayingForecast}
              leftOffset={leftOffset}
              tooltipData={tooltipData}
              secondaryCavTooltipData={secondaryTooltipData}
              trellisTitle={trellisTitle}
              yScale={yScale}
              today={today}
            />
          )}
      </DivPhaseChart.Container>
    </>
  );
};

DivPhaseChart.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

DivPhaseChart.SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > svg,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

DivPhaseChart.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

DivPhaseChart.VarianceTextMarkWrapper = styled.div`
  width: ${(props: Record<string, any>) => props.width}px;
  color: ${(props: Record<string, any>) => props.color};
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  padding-top: ${({ topOffset }) => `${topOffset}px`};
  align-items: flex-start;
  position: absolute;
  left: ${(props: Record<string, any>) => props.position}px;
  text-align: center;
  user-select: none;
`;

DivPhaseChart.Tooltip = styled.div`
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  opacity: 0.89;
  border-radius: 2px;
  pointer-events: auto;
  background-color: #ffffff;
  padding: 2px;
  margin-top: 4px;
  line-height: 16px;
  font-weight: 700;
  z-index: 999;
  white-space: nowrap;
`;

DivPhaseChart.InteractionSVGWrapper = styled(DivPhaseChart.SVGWrapper)`
  height: ${(props: Record<string, any>) => props.height}px;
  z-index: 74;
  top: ${(props: Record<string, any>) => props.position}px;
`;

export default React.memo<DivPhaseChartProps>(DivPhaseChart);
