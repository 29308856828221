import { scaleUtc } from 'd3-scale';
import { utcMinute } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import { getMinDate, getMaxDate } from 'helpers';

interface OverlayZoomInProps {
  changeExtremeDates: (dates: { min: Date; max: Date }) => void;
  extremeDates: { min: Date; max: Date };
  height: number;
  leftOffset: number;
  width: number;
  zoomInModeOff: () => void;
}

const OverlayZoomIn = (
  {
    changeExtremeDates,
    extremeDates,
    height,
    leftOffset,
    width,
    zoomInModeOff,
  }: OverlayZoomInProps,
  ref,
) => {
  const xScale = scaleUtc()
    .range([0, width])
    .domain([extremeDates.min, extremeDates.max]);

  const [lineXPosition, setLineXPosition] = React.useState(0);
  const [firstDate, setFirstDate] = React.useState<Date | null>(null);
  const [secondDate, setSecondDate] = React.useState<Date | null>(null);

  const clickHandler = React.useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      const pointerPosition = e.pageX - leftOffset - Y_AXIS_WIDTH - 10;
      const dateOfCursor = utcMinute.round(xScale.invert(pointerPosition));
      if (!firstDate) {
        setFirstDate(dateOfCursor);
        return;
      }

      setSecondDate(
        firstDate.getTime() === dateOfCursor.getTime()
          ? utcMinute.offset(dateOfCursor, 1)
          : dateOfCursor,
      );
    },
    [leftOffset, firstDate, xScale],
  );

  const moveLine = React.useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      const pointerPosition = e.pageX - leftOffset - Y_AXIS_WIDTH - 10;
      setLineXPosition(pointerPosition);
    },
    [leftOffset],
  );

  const hideLine = () => {
    setLineXPosition(0);
  };

  React.useEffect(() => {
    if (firstDate && secondDate) {
      const min = getMinDate(firstDate, secondDate);
      const max = getMaxDate(firstDate, secondDate);

      changeExtremeDates({ min, max });
      zoomInModeOff();
    }
  }, [firstDate, secondDate, changeExtremeDates, zoomInModeOff]);

  return (
    <OverlayZoomIn.Container>
      <svg
        ref={ref}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
          clickHandler(e)
        }
        onMouseMove={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
          moveLine(e)
        }
        onMouseOver={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
          moveLine(e)
        }
        onMouseLeave={hideLine}
      >
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={process.env.DEBUG ? 'rgba(150, 0, 150, 0.5)' : 'transparent'}
        />

        {firstDate && (
          <line
            stroke="#c42424"
            strokeWidth="1"
            x1={xScale(firstDate)}
            x2={xScale(firstDate)}
            y1={0}
            y2={height}
            vectorEffect="non-scaling-stroke"
          />
        )}

        {secondDate && (
          <line
            stroke="#c42424"
            strokeWidth="1"
            x1={xScale(secondDate)}
            x2={xScale(secondDate)}
            y1={0}
            y2={height}
            vectorEffect="non-scaling-stroke"
          />
        )}

        {lineXPosition > 0 && (
          <line
            stroke="#c42424"
            strokeWidth="1"
            strokeDasharray="2"
            x1={0}
            x2={0}
            y1={0}
            y2={height}
            transform={`translate(${lineXPosition}, 0)`}
            vectorEffect="non-scaling-stroke"
          />
        )}
      </svg>
    </OverlayZoomIn.Container>
  );
};

OverlayZoomIn.Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
`;

export default React.forwardRef<Element, OverlayZoomInProps>(OverlayZoomIn);
