import { format } from 'd3-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Column, Table, AutoSizer } from 'react-virtualized';
import styled from 'styled-components';

import useMenu from 'hooks/useMenu';
import {
  CommonTotalRow,
  COMPARE_OPTION,
  VAR_SORTING_ORDER,
} from 'modules/drilldownTable/models/drilldownTable';
import type { Mode } from 'modules/ui/models/ui';
import type { Group } from 'modules/well/models/well';
import { getSpotfireFilteredWells } from 'modules/spotfire/SpotfireReducer';

import { CELL_HEIGHT } from '../models/drilldownTable';
import WellTableRow from './WellTableRow';
import WellHeaderRow from './WellHeaderRow';
import ContextMenu from './ContextMenu';
import { useDrilldownTableMarking } from '../hooks/useDrilldownTableMarking';
import { useMultipleSelection } from '../hooks/useMultipleSelection';
import {
  getCurrentMarkedRows,
  getIsMarkingRows,
  getTotalRowsCount,
} from '../DrilldownTableReducer';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';

const UP_KEYCODE = 38;
const DOWN_KEYCODE = 40;

interface RowProps {
  columns: React.ReactNode;
  bgColor?: string;
  index: number;
  isTotalRow: boolean;
  onRowClick: (row: {
    event: React.MouseEvent<HTMLDivElement>;
    index: number;
    rowData: Record<string, any>;
  }) => void;
  rowData: Record<string, any>;
  style: { [prop: string]: string };
  className: string;
  sortBy?: string;
}
interface RowHeader {
  className: string;
  firstColDataKey: string;
  groups: { [groupKey: string]: Record<string, any> };
  style: { [prop: string]: string };
  onClick: (data: { sortBy: string; sortDirection?: string }) => void;
  sortDirection: string;
  sortBy: string;
  varianceSortingParams: { abs: boolean; direction: string };
}
const numberRateFormat = format(',.1f');
const numberVolumeFormat = format(',d');

const searchRows = (
  searchWord,
  drilldownTableToDisplay,
  firstColDataKey,
): any[] => {
  if (searchWord) {
    const searchWordInLowerCase = searchWord.toLowerCase();
    const searchResult = drilldownTableToDisplay.filter(drilldownItem =>
      drilldownItem[firstColDataKey]
        .toLowerCase()
        .includes(searchWordInLowerCase),
    );
    const normMatchedItems = searchResult.map(drilldownItem => ({
      ...drilldownItem,
      matched: [
        drilldownItem[firstColDataKey]
          .toLowerCase()
          .indexOf(searchWordInLowerCase),
        drilldownItem[firstColDataKey]
          .toLowerCase()
          .indexOf(searchWordInLowerCase) + searchWord.length,
      ],
    }));

    return normMatchedItems;
  }

  return drilldownTableToDisplay.map(resultItem => ({
    ...resultItem,
    matched: [],
  }));
};

interface WellDrilldownTableColumnsProps {
  compareOption: string;
  currentGroup: { subject: string; item: string };
  currentTable: string | null;
  currentWellId: string;
  groupsByKey: { [key: string]: Group };
  groupedTable: { [key: string]: string | number }[];
  groupForecastTable: { [key: string]: string | number }[];
  groupMode: Mode;
  drilldownTable: { [key: string]: string | number }[];
  isLoading: boolean;
  onChoose: (data: { [key: string]: string | string }) => void;
  searchWord: string;
  setSorting: (data: { sortBy: string; sortDirection?: string }) => void;
  sortDirection: string;
  sortCriteria: string;
  sortVarDirectionIndex: number;
  totalRow: CommonTotalRow;
  tableHeight: number;
  volumeType: boolean;
  wellForecastTable: { [key: string]: string | number }[];
}

const WellDrilldownTableColumns = ({
  compareOption,
  currentTable,
  currentGroup,
  currentWellId,
  groupedTable,
  groupForecastTable,
  groupsByKey,
  drilldownTable,
  groupMode,
  isLoading,
  onChoose,
  searchWord,
  setSorting,
  sortDirection,
  sortCriteria,
  sortVarDirectionIndex,
  totalRow: commonTotalRow,
  tableHeight,
  volumeType,
  wellForecastTable,
}: WellDrilldownTableColumnsProps) => {
  const spotfireWells = useSelector(getSpotfireFilteredWells);
  const currentMarkedRows = useSelector(getCurrentMarkedRows);
  const isMarkingRows = useSelector(getIsMarkingRows);
  const isAllowedToMark = useSelector(getAppConfig).drilldownMarking;
  const totalRowsCount = useSelector(state =>
    getTotalRowsCount(state, {
      subject: groupMode.subject,
    }),
  );

  const numberFormat = volumeType ? numberVolumeFormat : numberRateFormat;
  const firstColDataKey: string =
    groupMode.isOn && groupMode.subject ? groupMode.subject : 'well';
  const secondColDataKey =
    compareOption !== COMPARE_OPTION.actual ? 'secondCol' : 'actual';
  const secondColTitle =
    compareOption === COMPARE_OPTION.extVsCap ? 'RE' : 'Act.';
  const thirdColDataKey =
    compareOption === COMPARE_OPTION.actual ? 'capacity' : 'thirdCol';
  const thirdColTitle =
    compareOption !== COMPARE_OPTION.actVsExt ? 'Cap.' : 'RE';
  const fourthColDataKey = 'variance';

  const drilldownTableToDisplay = React.useMemo(() => {
    if (groupMode.isOn)
      return compareOption !== COMPARE_OPTION.actual
        ? groupForecastTable
        : groupedTable;
    return compareOption !== COMPARE_OPTION.actual
      ? wellForecastTable
      : drilldownTable;
  }, [
    drilldownTable,
    groupMode.isOn,
    groupedTable,
    compareOption,
    wellForecastTable,
    groupForecastTable,
  ]);

  const rowRenderer = (props: RowProps) => {
    const { className, rowData } = props;
    const isMarked =
      isMarkingRows &&
      rowData[groupMode.subject || 'well'] !== 'TOTAL' &&
      currentMarkedRows.length !== totalRowsCount &&
      currentMarkedRows.includes(rowData[groupMode.subject || 'well']);
    const isSelected = groupMode.isOn
      ? currentGroup.item === rowData[firstColDataKey]
      : currentWellId === rowData.wellId;
    const isHighlighted = isMarkingRows ? isMarked : isSelected;

    const newClassName = isHighlighted
      ? className + ' row--highlighted'
      : className;

    return (
      <WellTableRow
        {...props}
        className={newClassName}
        compareOption={compareOption}
        firstColDataKey={firstColDataKey}
        maxColumnWidth={maxColumnWidth}
        secondColDataKey={secondColDataKey}
        thirdColDataKey={thirdColDataKey}
        fourthColDataKey={fourthColDataKey}
        hasColorEffects
        isHighlighted={isHighlighted}
        isTotalRow={
          props.index === resultList.length - 1 && !showBottomTotalRow
        }
        format={numberFormat}
      />
    );
  };

  const searchedTable = React.useMemo(() => {
    const searched = searchRows(
      searchWord,
      drilldownTableToDisplay,
      firstColDataKey,
    );

    if (!spotfireWells || spotfireWells.length < 1) return searched;

    return spotfireWells
      .map(id => searched.find(i => i.wellId === id))
      .filter(Boolean);
  }, [searchWord, drilldownTableToDisplay, spotfireWells, firstColDataKey]);

  const totalRow: any = React.useMemo(() => {
    return {
      [firstColDataKey]: 'TOTAL',
      [secondColDataKey]: commonTotalRow.secondCol,
      [thirdColDataKey]: commonTotalRow.thirdCol,
      [fourthColDataKey]: commonTotalRow.variance,
      wellId: '0',
    };
  }, [
    commonTotalRow,
    firstColDataKey,
    secondColDataKey,
    thirdColDataKey,
    fourthColDataKey,
  ]);

  const showBottomTotalRow =
    tableHeight <= CELL_HEIGHT * searchedTable.length + 68;
  const resultList = showBottomTotalRow
    ? searchedTable
    : searchedTable.concat(totalRow);

  const maxColumnWidth = React.useMemo(() => {
    const totalSecond = Math.max(
      8,
      numberFormat(totalRow[secondColDataKey]).length,
    );
    const totalThird = Math.max(
      8,
      numberFormat(totalRow[thirdColDataKey]).length,
    );

    const fourthSigned = resultList.reduce(
      (acc, n, idx, arr) => {
        if (idx === arr.length - 1 && !showBottomTotalRow) return acc;
        const value = n[fourthColDataKey];
        if (value < 0) acc.negative += value;
        else acc.positive += value;
        return acc;
      },
      { positive: 0, negative: 0 },
    );
    const totalFourth = Math.max(
      8,
      numberFormat(totalRow[fourthColDataKey]).length,
      numberFormat(fourthSigned.negative).length,
      numberFormat(fourthSigned.positive).length,
    );

    const columnsWidth = drilldownTableToDisplay.reduce(
      (acc: { second: number; third: number; fourth: number }, data) => {
        const second = data[secondColDataKey];
        const secondLength =
          typeof second === 'string'
            ? second.length
            : numberFormat(second).length;
        const third = data[thirdColDataKey];

        const thirdLength =
          typeof third === 'string' ? third.length : numberFormat(third).length;
        const fourth = data[fourthColDataKey];
        const fourthLength =
          typeof fourth === 'string'
            ? fourth.length
            : numberFormat(fourth).length;
        acc.second = Math.max(acc.second, secondLength);
        acc.third = Math.max(acc.third, thirdLength);
        acc.fourth = Math.max(acc.fourth, fourthLength);
        return acc;
      },
      {
        second: totalSecond,
        third: totalThird,
        fourth: totalFourth,
      },
    );

    return {
      secondColWidth: Math.round(columnsWidth.second * 6.4),
      thirdColWidth: Math.round(columnsWidth.third * 6.4),
      fourthColWidth: Math.round(columnsWidth.fourth * 6.4),
    };
  }, [
    drilldownTableToDisplay,
    totalRow,
    secondColDataKey,
    thirdColDataKey,
    fourthColDataKey,
    numberFormat,
    resultList,
    showBottomTotalRow,
  ]);

  const { markRow, markAll, markSeveralRows, selectRow, unmarkRow } =
    useDrilldownTableMarking();

  const { selectHandler } = useMultipleSelection<any>({
    isItemSelected: ({ rowData }) =>
      currentMarkedRows.includes(rowData[groupMode.subject || 'well']),
    selectedItemCount: currentMarkedRows.length,
    onSelect: ({ rowData }) => selectRow(rowData),
    onDeselect: ({ rowData }) => unmarkRow(rowData),
    onAddSelection: ({ rowData }) => markRow(rowData),
    onSelectMany: ({ from, to }) => markSeveralRows(resultList.slice(from, to)),
    shouldIgnoreSelection: ({ rowData }) =>
      rowData[groupMode.subject || 'well'] === 'TOTAL',
    selected: resultList.findIndex(
      r => r[groupMode.subject || 'well'] === currentMarkedRows[0],
    ),
  });

  const headerRowRenderer = (props: RowHeader) => {
    return (
      <WellHeaderRow
        {...props}
        firstColDataKey={firstColDataKey}
        maxColumnWidth={maxColumnWidth}
        secondColDataKey={secondColDataKey}
        secondColTitle={secondColTitle}
        thirdColDataKey={thirdColDataKey}
        thirdColTitle={thirdColTitle}
        fourthColDataKey={fourthColDataKey}
        groups={groupsByKey}
        markAllRows={() => markAll()}
        displayClearButton={
          isAllowedToMark && currentMarkedRows.length !== totalRowsCount
        }
      />
    );
  };

  const [scrollTop, setScrollTop] = React.useState<number | null>(0);

  const handleScroll = React.useCallback(({ scrollTop }) => {
    if (scrollTop !== null && scrollTop > 0) {
      setScrollTop(null);
    }
  }, []);

  React.useMemo(() => {
    setScrollTop(0);
  }, [sortDirection, sortCriteria, sortVarDirectionIndex]);

  const onEmptySpaceClick = React.useCallback(
    e => {
      if (e.target.className.includes('ReactVirtualized__Table__Grid'))
        markAll();
    },
    [markAll],
  );

  const chooseRowByKeyboard = React.useCallback(
    (e: KeyboardEvent) => {
      const willCrash =
        (groupMode.isOn && !groupMode.subject) ||
        (!groupMode.isOn && !currentWellId) ||
        R.isEmpty(searchedTable);
      if (willCrash || currentTable === 'VarianceDrilldownTable') return;
      const { target } = e;
      if (target instanceof HTMLElement) {
        const { tagName } = target;
        if (
          tagName === 'INPUT' ||
          tagName === 'TEXTAREA' ||
          tagName === 'SELECT'
        )
          return;
        const { keyCode } = e;

        const currentItem = groupMode.isOn ? currentGroup.item : currentWellId;
        const searchKey = groupMode.isOn ? firstColDataKey : 'wellId';
        const currentTableIndex = searchedTable.findIndex(
          item => item[searchKey] === currentItem,
        );
        if (keyCode === UP_KEYCODE) {
          e.preventDefault();
          const newIndex = Math.max(currentTableIndex - 1, 0);
          const newItem = searchedTable[newIndex];
          onChoose(newItem);
        } else if (keyCode === DOWN_KEYCODE) {
          e.preventDefault();
          const newIndex = Math.min(
            currentTableIndex + 1,
            searchedTable.length - 1,
          );
          const newItem = searchedTable[newIndex];
          onChoose(newItem);
        }
      }
    },
    [
      currentTable,
      currentWellId,
      groupMode,
      onChoose,
      searchedTable,
      currentGroup.item,
      firstColDataKey,
    ],
  );
  const contextMenuElRef = React.useRef(null);
  const [
    isMenuOpen,
    onMenuHover,
    onMenuLeave,
    onTargetClick,
    closeMenu,
    contextPosition,
  ] = useMenu(contextMenuElRef);

  React.useEffect(() => {
    document.addEventListener('keydown', chooseRowByKeyboard);
    return () => {
      document.removeEventListener('keydown', chooseRowByKeyboard);
    };
  }, [chooseRowByKeyboard]);

  return (
    <WellDrilldownTableColumns.Container
      onContextMenu={e => {
        e.preventDefault();
        onTargetClick(e);
      }}
      onClick={onEmptySpaceClick}
    >
      {!isLoading && !R.isEmpty(drilldownTable) ? (
        <>
          <AutoSizer>
            {({ height, width }) => (
              <WellDrilldownTableColumns.StyledTable
                rowStyle={{ alignItems: 'stretch' }}
                rowHeight={CELL_HEIGHT}
                headerHeight={CELL_HEIGHT}
                headerRowRenderer={(props: RowHeader) =>
                  headerRowRenderer({
                    ...props,
                    sortBy: sortCriteria,
                    sortDirection,
                    varianceSortingParams:
                      VAR_SORTING_ORDER[sortVarDirectionIndex],
                    onClick: setSorting,
                  })
                }
                rowCount={resultList.length}
                rowGetter={({ index }) => resultList[index]}
                height={showBottomTotalRow ? height - CELL_HEIGHT : height}
                width={width}
                onRowClick={selectHandler}
                rowRenderer={rowRenderer}
                sortBy={sortCriteria}
                sortDirection={sortDirection}
                sort={setSorting}
                scrollToIndex={scrollTop}
                onScroll={handleScroll}
                scrollToAlignment="auto"
                showBottomTotalRow={showBottomTotalRow}
              >
                <Column
                  label={
                    firstColDataKey[0].toUpperCase() +
                    firstColDataKey.substring(1)
                  }
                  dataKey={firstColDataKey}
                  flexGrow={1}
                  style={{ display: 'flex', alignItems: 'center' }}
                  width={120}
                />
                <Column
                  dataKey={secondColDataKey}
                  style={{ display: 'flex', alignItems: 'center' }}
                  width={74}
                />
                <Column
                  dataKey={thirdColDataKey}
                  style={{ display: 'flex', alignItems: 'center' }}
                  width={74}
                />
                <Column
                  dataKey={fourthColDataKey}
                  style={{ display: 'flex', alignItems: 'center' }}
                  width={74}
                />
              </WellDrilldownTableColumns.StyledTable>
            )}
          </AutoSizer>

          {showBottomTotalRow ? (
            <WellDrilldownTableColumns.TotalRow wide={volumeType}>
              <div>TOTAL</div>
              <WellDrilldownTableColumns.TotalCol
                width={maxColumnWidth.secondColWidth}
                title={totalRow[secondColDataKey]}
              >
                {numberFormat(totalRow[secondColDataKey])}
              </WellDrilldownTableColumns.TotalCol>
              <WellDrilldownTableColumns.TotalCol
                width={maxColumnWidth.thirdColWidth}
                title={totalRow[thirdColDataKey]}
              >
                {numberFormat(totalRow[thirdColDataKey])}
              </WellDrilldownTableColumns.TotalCol>
              <WellDrilldownTableColumns.TotalCol
                width={maxColumnWidth.fourthColWidth}
                title={totalRow[fourthColDataKey]}
              >
                {numberFormat(totalRow[fourthColDataKey])}
              </WellDrilldownTableColumns.TotalCol>
            </WellDrilldownTableColumns.TotalRow>
          ) : null}
        </>
      ) : (
        <WellDrilldownTableColumns.CircularProgressWrapper>
          <CircularProgress color="inherit" />
        </WellDrilldownTableColumns.CircularProgressWrapper>
      )}
      {isMenuOpen && (
        <ContextMenu
          closeMenu={closeMenu}
          contextPosition={contextPosition}
          ref={contextMenuElRef}
          onMenuHover={onMenuHover}
          onMenuLeave={onMenuLeave}
          data={searchedTable.concat(totalRow)}
          dataKeys={[
            firstColDataKey,
            secondColDataKey,
            thirdColDataKey,
            fourthColDataKey,
          ]}
        />
      )}
    </WellDrilldownTableColumns.Container>
  );
};

WellDrilldownTableColumns.Container = styled.div`
  height: calc(100% - 38px);
  overflow: hidden;
  font-family: 'Lato', sans-serif;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
`;

WellDrilldownTableColumns.StyledTable = styled(Table)`
  user-select: none;

  & * {
    outline: none;
  }

  & .ReactVirtualized__Table__headerRow {
    background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
    border-bottom: 1px solid #c1c1c1;
    font-size: 12px;
    padding-right: 8px;
  }

  & .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: flex-end;
    margin-right: 3px;

    &:nth-child(2) {
      justify-content: flex-start;
      flex-direction: row-reverse;
      border-left: 1px solid #c1c1c1;
    }
  }

  & .ReactVirtualized__Table__row {
    box-shadow: 1px 0 0 0 #c1c1c1, 1px 1px 0 0 #c1c1c1, 0 1px 0 0 #c1c1c1 inset;
    position: relative;
    z-index: 10;

    &:first-child {
      box-shadow: 1px 0 0 0 #c1c1c1, 1px 1px 0 0 #c1c1c1;
    }

    &:last-child {
      border-bottom: 1px solid #c1c1c1;

      > div {
        font-weight: ${props => (props.showBottomTotalRow ? '500' : '700')};
        color: ${props => (props.showBottomTotalRow ? '' : 'rgba(0,0,0,0.8)')};
      }
    }

    &:hover {
      box-shadow: 1px 0 0 0 black, 0 1px 0 0 black, 0 1px 0 0 black inset;
      z-index: 20;
    }

    &:last-child:hover {
      border-bottom: 1px solid black;
    }
  }

  & .ReactVirtualized__Table__rowColumn[aria-colindex='1'] {
    margin-left: 5px !important;
  }

  & .ReactVirtualized__Table__rowColumn[aria-colindex='2'],
  .ReactVirtualized__Table__rowColumn[aria-colindex='3'] {
    display: flex;
    justify-content: flex-end;
    border-left: 1px solid #c1c1c1;
    padding-right: 3px;
    margin-right: 0;
  }

  & span {
    text-transform: none;
    overflow: visible;
  }

  & .row--highlighted {
    background: #ebebeb;
  }
`;

WellDrilldownTableColumns.CircularProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

WellDrilldownTableColumns.TotalRow = styled.div`
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #c1c1c1;
  width: 100%;
  line-height: 1em;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  padding-right: 7px;
  padding-bottom: 1px;
  height: ${CELL_HEIGHT - 2}px;
  user-select: none;
  & > div {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-right: 2px;
    cursor: default;
  }
  & > div:first-child {
    flex: 1 1 120px;
    padding-left: 5px;
  }

  & > div:not(:first-child) {
    border-left: 1px solid #c1c1c1;
    justify-content: flex-end;
  }

  & > div:last-child {
    flex: 0 0 ${(props: Record<string, any>) => props.width + 1}px;
  }
`;
WellDrilldownTableColumns.TotalCol = styled.div`
  flex: 0 0 ${(props: Record<string, any>) => props.width + 1}px;
  box-sizing: content-box;
`;

export default React.memo<WellDrilldownTableColumnsProps>(
  WellDrilldownTableColumns,
);
