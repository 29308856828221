import { utcDay } from 'd3-time';
import * as R from 'ramda';

import { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

const RANGE_TYPES = {
  allocIssue: 'Allocation Issue',
  varianceEvent: 'Variance Event',
};

const getVarianceExtremeDates = (
  allocIssues: AllocIssue[],
  varianceEvents: VarianceEvent[],
  id: string,
  today: Date,
  searchType: 'Allocation Issue' | 'Variance Event',
) => {
  const yesterday = utcDay.offset(today, -1);
  const varianceEntities: Array<
    VarianceEvent | AllocIssue
    //@ts-expect-error
  > = allocIssues.concat(varianceEvents);
  const eventsDateRanges: {
    dateStart: Date;
    dateEnd: Date;
    id: string;
    type: string;
  }[] = varianceEntities.map((varianceEntity: AllocIssue | VarianceEvent) => {
    return {
      //@ts-expect-error
      dateStart: varianceEntity.dateStart || varianceEntity.dayStart,
      //@ts-expect-error
      dateEnd: varianceEntity.dateEnd || varianceEntity.dayEnd,
      //@ts-expect-error
      type: varianceEntity.dateStart
        ? RANGE_TYPES.allocIssue
        : RANGE_TYPES.varianceEvent,
      id: varianceEntity.id,
    };
  });
  const quantity = eventsDateRanges.length;
  const sortedRanges = R.sort(
    (a, b) => a.dateStart.getTime() - b.dateStart.getTime(),
    eventsDateRanges,
  );
  const rangeIndex = sortedRanges.findIndex(
    range => range.id === id && range.type === searchType,
  );

  if (quantity <= 1) return [new Date(0), yesterday];

  if (rangeIndex === 0) {
    const max = utcDay.offset(sortedRanges[rangeIndex + 1].dateStart, -1);
    return [new Date(0), max];
  } else if (rangeIndex === quantity - 1) {
    const min = utcDay.offset(sortedRanges[rangeIndex - 1].dateEnd, 1);
    const max = yesterday;

    return [min, max];
  }
  if (!sortedRanges[rangeIndex - 1])
    //eslint-disable-next-line no-console
    console.error({
      sortedRanges,
      rangeIndex,
      id,
      searchType,
    });
  const min = utcDay.offset(sortedRanges[rangeIndex - 1].dateEnd, 1);
  const max = sortedRanges[rangeIndex + 1]
    ? utcDay.offset(sortedRanges[rangeIndex + 1].dateStart, -1)
    : yesterday;

  return [min, max];
};

export default getVarianceExtremeDates;
