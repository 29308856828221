import React from 'react';

import type { Action } from 'store/models';
import Modal from 'components/ModalWindowV2';

import ChangePasswordForm from '../forms/ChangePassForm';

type ChangePasswordWindowProps = {
  changePasswordRequest: (data: {
    prevPassword: string;
    newPassword: string;
  }) => Promise<Action>;
  open: boolean;
  handleClose: () => void;
};

const ChangePasswordWindow = ({
  changePasswordRequest,
  open,
  handleClose,
}: ChangePasswordWindowProps) => {
  const onSubmit = React.useCallback(
    (values: { prevPassword: string; newPassword: string }) => {
      //@ts-expect-error
      changePasswordRequest(values).then((res, err) => {
        if (res.type.includes('SUCCESS')) {
          handleClose();
        }
      });
    },
    [changePasswordRequest, handleClose],
  );
  return (
    <Modal isOpen={open} handleClose={handleClose} title="Change Password">
      <ChangePasswordForm handleClose={handleClose} onSubmit={onSubmit} />
    </Modal>
  );
};

export default ChangePasswordWindow;
