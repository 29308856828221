import { utcDay } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';

import type { TrellisTooltipData } from 'modules/ui/models/ui';
import { isInside } from 'modules/chart/utils';
import { VARIANCE_TRELLIS } from 'modules/chart/models/chart';

const getExtremePoint = (date: string, formatedLayers: any): any => {
  const dayData = R.pathOr({}, [date], formatedLayers);
  if (dayData.lenght === 0) {
    return { min: 0, max: 0 };
  }
  const extremePoints = Object.keys(dayData).reduce(
    (acc, dataKey) => {
      const data = dayData[dataKey];
      const min = Math.min(data[0], data[1]);
      const max = Math.max(data[0], data[1]);
      if (min < acc.min) {
        acc = { ...acc, min };
      }
      if (max > acc.max) {
        acc = { ...acc, max };
      }
      return acc;
    },
    { min: 0, max: 0 },
  );
  return extremePoints;
};

interface GroupSelectedBarProps {
  eventId: string;
  formatedLayers: any;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  svgBoundingRect: any;
  today: Date;
  tooltipData: any;
  varianceData?: { date: Date; variace: number }[];
  xScale: any;
  yScale: any;
}

const GroupSelectedBar = ({
  eventId,
  formatedLayers,
  onSetTooltipData,
  svgBoundingRect,
  today,
  tooltipData,
  xScale,
  yScale,
}: GroupSelectedBarProps) => {
  const trellisTooltipData = R.pathOr(
    null,
    ['trellisTooltipData'],
    tooltipData,
  );
  const chartAreaCoords = React.useMemo(
    () => ({
      x1: svgBoundingRect.x,
      x2: svgBoundingRect.x + svgBoundingRect.width,
      y1: 55,
      y2: window.innerHeight - 35,
    }),
    [svgBoundingRect],
  );
  const showExtremeBar =
    trellisTooltipData !== null &&
    trellisTooltipData.trellis !== VARIANCE_TRELLIS;
  const isVarianceTrellis =
    trellisTooltipData !== null &&
    trellisTooltipData.trellis === VARIANCE_TRELLIS;
  const extremePoints = React.useMemo(
    () =>
      showExtremeBar
        ? getExtremePoint(trellisTooltipData.day, formatedLayers)
        : { min: 0, max: 0 },
    [trellisTooltipData, formatedLayers, showExtremeBar],
  );

  const barData = isVarianceTrellis
    ? R.pathOr({}, [trellisTooltipData.day, eventId], formatedLayers)
    : {};
  const trellisAreaCoords = React.useMemo(
    () => ({
      x1: svgBoundingRect.x,
      x2: svgBoundingRect.x + svgBoundingRect.width,
      y1: svgBoundingRect.y,
      y2: svgBoundingRect.y + svgBoundingRect.height,
    }),
    [svgBoundingRect],
  );

  const selectedBarHeight = React.useMemo(() => {
    const range = yScale.range();
    const domain = yScale.domain();
    const coef =
      (range[0] - range[1]) / (Math.abs(domain[0]) + Math.abs(domain[1]));

    if (showExtremeBar) {
      return Math.abs(extremePoints.max - extremePoints.min) * coef;
    }
    return !R.isEmpty(barData) ? Math.abs(barData[0] - barData[1]) * coef : 0;
  }, [barData, extremePoints, showExtremeBar, yScale]);

  const selectedBarX = React.useMemo(() => {
    if (showExtremeBar) {
      return xScale(trellisTooltipData.day);
    }
    return !R.isEmpty(barData) ? xScale(barData.data.day) : 0;
  }, [barData, showExtremeBar, trellisTooltipData, xScale]);

  const selectedBarY = React.useMemo(() => {
    if (showExtremeBar) {
      return yScale(extremePoints.max);
    }
    if (barData[1] <= 0 && barData[0] <= 0) {
      return yScale(Math.max(barData[0], barData[1])) - 1;
    }
    if (barData[1] < 0) {
      return yScale(barData[0]);
    }
    const y = yScale(barData[1]);
    return y - 1;
  }, [yScale, barData, extremePoints, showExtremeBar]);

  const barWidth = React.useMemo(
    () =>
      xScale.range()[1] / utcDay.count(xScale.domain()[0], xScale.domain()[1]),
    [xScale],
  );

  const mouseMoveListener = React.useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const currentPointerPosition = {
        clientX,
        clientY,
      };
      const currentPointerIsInsideTrellis = isInside(
        currentPointerPosition,
        trellisAreaCoords,
      );
      if (!currentPointerIsInsideTrellis) return;
      const value = yScale.invert(
        currentPointerPosition.clientY - trellisAreaCoords.y1,
      );
      const pointerDate = utcDay.floor(
        xScale.invert(currentPointerPosition.clientX - chartAreaCoords.x1),
      );
      const data = R.pathOr(false, [pointerDate, eventId], formatedLayers);
      const dataForTooltip =
        data &&
        ((value >= data[0] && value <= data[1]) ||
          (value <= data[0] && value >= data[1]))
          ? {
              day: pointerDate,
              production: 0,
              capacity: 0,
              variance: data ? data.data[eventId] : 0,
              clientX,
              clientY,
              trellis: VARIANCE_TRELLIS,
              varianceOptionId: eventId,
            }
          : null;
      onSetTooltipData(
        dataForTooltip !== null &&
          dataForTooltip.day.getTime() > utcDay.offset(today, -1).getTime()
          ? null
          : dataForTooltip,
      );
    },
    [
      chartAreaCoords,
      eventId,
      formatedLayers,
      onSetTooltipData,
      today,
      trellisAreaCoords,
      xScale,
      yScale,
    ],
  );
  const mouseLeaveListener = React.useCallback(
    () => onSetTooltipData(null),
    [onSetTooltipData],
  );

  React.useEffect(() => {
    const trellisesWrappers = document.querySelectorAll('.trellises-wrapper');
    const trellisesWrappersArray = Array.from(trellisesWrappers);
    trellisesWrappersArray.forEach(elem => {
      elem.addEventListener('mousemove', mouseMoveListener as EventListener);
      elem.addEventListener('mouseleave', mouseLeaveListener);
    });
    return () =>
      trellisesWrappersArray.forEach(elem => {
        elem.removeEventListener(
          'mousemove',
          mouseMoveListener as EventListener,
        );
        elem.removeEventListener('mouseleave', mouseLeaveListener);
      });
  }, [mouseMoveListener, mouseLeaveListener]);

  return (
    <>
      {(!R.isEmpty(barData) || showExtremeBar) && (
        <>
          <rect
            width={barWidth + 6}
            height={selectedBarHeight + 4}
            x={selectedBarX - 3}
            y={selectedBarY - 1}
            strokeWidth="1"
            stroke="black"
            fill="transparent"
            pointerEvents="none"
          />
        </>
      )}
      <div></div>
    </>
  );
};

export default GroupSelectedBar;
