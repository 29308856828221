import * as R from 'ramda';

export const Environment = {
  local: 'local', // localhost:3000
  development: 'development',
  demo: 'demo',
  stage: 'stage',
  production: 'production',
};

const environmentConfig = {
  environment: R.defaultTo(
    Environment.local,
    process.env.REACT_APP_ENVIRONMENT,
  ),
};

export default environmentConfig;
