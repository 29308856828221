import gql from 'graphql-tag';

import type { GraphqlQueryAction, GraphqlMutationAction } from 'store/models';

export const namespace = 'USER';
export const FETCH_USER = `${namespace}/FETCH_USER`;
export const FETCH_ALL = `${namespace}/FETCH_ALL`;
export const UPDATE_USER = `${namespace}/UPDATE_USER`;
export const DELETE_USER = `${namespace}/DELETE_USER`;
export const CREATE_USER = `${namespace}/CREATE_USER`;
export const REQUEST_PASSWORD_RESET = `${namespace}/REQUEST_PASSWORD_RESET`;
export const CONFIRM_PASSWORD_RESET = `${namespace}/CONFIRM_PASSWORD_RESET`;
export const CHECK_RESET_PASSWORD_TOKEN = `${namespace}/CHECK_RESET_PASSWORD_TOKEN`;

const FETCH_USER_QUERY = gql`
  query ($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      emailName
      isAllowedEditCapChanges
      isAllowedEditVarEvents
      isAllowedEditAllocIssues
      isAllowedEditRibbonEvents
      isAdmin
      inactive
      mustChangePassword
      userUuid
      isUserManager
    }
  }
`;

type fetchUserAC = (id: string) => GraphqlQueryAction;

export const fetchUser: fetchUserAC = id => ({
  type: FETCH_USER,
  payload: {
    key: 'getUser',
    graphql: {
      query: FETCH_USER_QUERY,
      variables: { id },
    },
  },
});

const REQUEST_PASSWORD_RESET_QUERY = gql`
  query ($email: String) {
    requestPasswordReset(email: $email)
  }
`;

type requestPasswordResetAC = (email: string) => GraphqlQueryAction;

export const requestPasswordReset: requestPasswordResetAC = email => ({
  type: REQUEST_PASSWORD_RESET,
  payload: {
    key: 'requestPasswordReset',
    graphql: {
      query: REQUEST_PASSWORD_RESET_QUERY,
      variables: { email },
    },
  },
});

const CONFIRM_PASSWORD_RESET_MUTATION = gql`
  mutation ($payload: ConfirmPasswordResetInput!) {
    confirmPasswordReset(data: $payload) {
      isTokenExpired
      user {
        id
        firstName
        lastName
        email
        emailName
        isAllowedEditCapChanges
        isAllowedEditVarEvents
        isAllowedEditAllocIssues
        isAllowedEditRibbonEvents
        isAdmin
        inactive
        mustChangePassword
        userUuid
        isUserManager
      }
    }
  }
`;

type confirmPasswordResetAC = (payload: {
  newPassword: string;
  token: string | undefined;
}) => GraphqlMutationAction;

export const confirmPasswordReset: confirmPasswordResetAC = payload => ({
  type: CONFIRM_PASSWORD_RESET,
  payload: {
    key: 'confirmPasswordReset',
    graphql: {
      mutation: CONFIRM_PASSWORD_RESET_MUTATION,
      variables: { payload },
    },
  },
});

const FETCH_ALL_QUERY = gql`
  query {
    listAllUsers {
      id
      firstName
      lastName
      email
      emailName
      isAllowedEditCapChanges
      isAllowedEditVarEvents
      isAllowedEditAllocIssues
      isAllowedEditRibbonEvents
      isAdmin
      inactive
      mustChangePassword
      userUuid
      isUserManager
    }
  }
`;

export const fetchAllUsers = () => ({
  type: FETCH_ALL,
  payload: {
    key: 'listAllUsers',
    graphql: {
      query: FETCH_ALL_QUERY,
    },
  },
});

const UPDATE_USER_MUTATION = gql`
  mutation ($payload: UserInput!) {
    updateUser(data: $payload) {
      id
      firstName
      lastName
      email
      emailName
      isAllowedEditCapChanges
      isAllowedEditVarEvents
      isAllowedEditAllocIssues
      isAllowedEditRibbonEvents
      isAdmin
      inactive
      mustChangePassword
      userUuid
      isUserManager
    }
  }
`;

type updateUserAC = (payload: {
  id: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isAdmin?: boolean;
  isAllowedEditCapChanges?: boolean;
  isAllowedEditVarEvents?: boolean;
  isAllowedEditAllocIssues?: boolean;
  isAllowedEditRibbonEvents?: boolean;
  inactive: boolean;
  mustChangePassword: boolean;
}) => GraphqlMutationAction;

export const updateUser: updateUserAC = payload => ({
  type: UPDATE_USER,
  payload: {
    key: 'updateUser',
    graphql: {
      mutation: UPDATE_USER_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

export const CREATE_USER_MUTATION = gql`
  mutation ($payload: UserInput!) {
    createUser(data: $payload) {
      id
      firstName
      lastName
      email
      emailName
      isAllowedEditCapChanges
      isAllowedEditVarEvents
      isAllowedEditAllocIssues
      isAllowedEditRibbonEvents
      isAdmin
      inactive
      mustChangePassword
      userUuid
      isUserManager
    }
  }
`;

type createUserAC = (payload: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isAdmin: boolean;
  isAllowedEditCapChanges: boolean;
  isAllowedEditVarEvents: boolean;
  isAllowedEditAllocIssues: boolean;
  isAllowedEditRibbonEvents: boolean;
  inactive: boolean;
  mustChangePassword: boolean;
}) => GraphqlMutationAction;

export const createUser: createUserAC = payload => ({
  type: CREATE_USER,
  payload: {
    key: 'createUser',
    graphql: {
      mutation: CREATE_USER_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const DELETE_USER_MUTATION = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id)
  }
`;

type deleteUserAC = (id: string) => GraphqlMutationAction;

export const deleteUser: deleteUserAC = payload => ({
  type: DELETE_USER,
  payload: {
    key: 'deleteUser',
    graphql: {
      mutation: DELETE_USER_MUTATION,
      variables: {
        id: payload,
      },
    },
  },
});

const CHECK_RESET_PASSWORD_TOKEN_QUERY = gql`
  query ($passwordToken: String!) {
    checkResetPasswordToken(passwordToken: $passwordToken)
  }
`;

type checkResetPasswordTokenAC = (passwordToken: string) => GraphqlQueryAction;

export const checkResetPasswordToken: checkResetPasswordTokenAC =
  passwordToken => ({
    type: CHECK_RESET_PASSWORD_TOKEN,
    payload: {
      key: 'checkResetPasswordToken',
      graphql: {
        query: CHECK_RESET_PASSWORD_TOKEN_QUERY,
        variables: {
          passwordToken,
        },
      },
    },
  });
