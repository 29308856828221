import * as R from 'ramda';

import { getGraphqlPayload } from 'store/helpers';

import type { Action } from 'store/models';
import { filterActions } from 'redux-ignore';

import type { AllocIssueCause } from './models/allocIssueCause';
import { FETCH_ALLOC_ISSUE_CAUSE } from './AllocIssueCauseActions';

export const STATE_KEY = 'allocIssueCause';

export interface AllocIssueCauseState {
  [id: string]: AllocIssueCause;
}

const initialState: AllocIssueCauseState = {};

const AllocIssueCauseReducer = (
  state: AllocIssueCauseState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case `${FETCH_ALLOC_ISSUE_CAUSE}_SUCCESS`: {
      const causes = getGraphqlPayload(action);

      return R.indexBy<string, AllocIssueCause>(R.prop('id'), causes);
    }
    default: {
      return state;
    }
  }
};

export const getAllocIssueCauses = R.pathOr({}, [STATE_KEY]);

export default filterActions(AllocIssueCauseReducer, [
  `${FETCH_ALLOC_ISSUE_CAUSE}_SUCCESS`,
]);
