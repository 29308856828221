import { Form, Formik } from 'formik';
import * as React from 'react';

import type { Category } from 'modules/category/models/category';
import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import ExtraInputs from './ExtraInputs';

interface NotesFormProps {
  currentCategory: Category;
  currentItem: VarianceEvent | CapacityChangeEvent;
  disabled?: boolean;
  onExtraInputsChange: (Extra: any) => void;
  onNotesChange: (note: string) => void;
}

const NotesForm = ({
  currentCategory,
  currentItem,
  disabled,
  onExtraInputsChange,
  onNotesChange,
}: NotesFormProps) => {
  return (
    // @ts-expect-error
    <Formik
      enableReinitialize
      initialValues={{
        type: currentCategory ? currentCategory.planType : '',
        cause: currentCategory ? currentCategory.cause : '',
        subCause: currentCategory ? currentCategory.subCause : '',
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          {values.type === currentCategory.planType &&
            values.cause === currentCategory.cause &&
            values.subCause === currentCategory.subCause &&
            currentCategory.extraInputsType && (
              <ExtraInputs
                inputs={currentCategory.extraInputsType}
                data={currentItem.extraInputsData}
                onSubmit={onExtraInputsChange}
                disabled={disabled}
              />
            )}
        </Form>
      )}
    </Formik>
  );
};
export default NotesForm;
