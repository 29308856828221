import React from 'react';
import styled from 'styled-components';

import useDragging from 'hooks/useDragging';
import ResizableGridCell from './ResizableGridCell';

type ResizableGridProps = {
  children: React.ReactElement[];
  mode?: 'vertical' | 'horizontal';
  initialTemplate?: string[];
  onTemplateChange?: (template: string[]) => void;
};

type Cell = {
  index: number;
  size: CellSize;
  element: HTMLElement;
};

type CellSize = {
  initial: number;
  min: number;
  max: number;
};

const ResizableGrid = ({
  mode = 'vertical',
  initialTemplate,
  onTemplateChange,
  children,
}: ResizableGridProps) => {
  const gridElement = React.useRef(null);
  const [startingMousePos, setStartingMousePos] = React.useState(0);
  const [draggingIndex, setDragginIndex] = React.useState(-1);
  const [template, setTemplateLocally] = React.useState<string[]>([]);

  const isHorizontal = mode === 'horizontal';
  const childrenCount = React.Children.count(children);

  const setTemplate = React.useCallback(
    (templateOrStateFn: string[] | ((t: string[]) => string[])) => {
      if (Array.isArray(templateOrStateFn)) {
        setTemplateLocally(templateOrStateFn);
        onTemplateChange?.(templateOrStateFn);
        return;
      }

      if (templateOrStateFn instanceof Function) {
        setTemplateLocally(currentTemplate => {
          const newTemplate = templateOrStateFn(currentTemplate);
          onTemplateChange?.(newTemplate);
          return newTemplate;
        });
      }
    },
    [onTemplateChange],
  );

  const defaultTemplate = React.useMemo(() => {
    const basis = 100 / React.Children.count(children) + '%';
    const template = Array.from({ length: childrenCount }).fill(basis);
    return template as string[];
  }, [childrenCount]);

  const getElementSize = React.useCallback(
    (element?: HTMLElement | null) => {
      return isHorizontal ? element?.offsetWidth : element?.offsetHeight;
    },
    [isHorizontal],
  );

  const getCellSize = React.useCallback(
    (element?: HTMLElement) => {
      return {
        initial: getElementSize(element),
        min: Number(element?.dataset?.minSize ?? 0),
        max: Number(element?.dataset?.maxSize ?? Infinity),
      };
    },
    [getElementSize],
  );

  const getCellByIndex = React.useCallback(
    (index: number) => {
      const grid = gridElement.current as Element | null;
      const element = grid?.children?.[index] as HTMLElement;
      const size = getCellSize(element);
      return { index, element, size } as Cell;
    },
    [gridElement.current, getCellSize],
  );

  const getAvailableSize = React.useCallback(() => {
    const grid = gridElement.current as HTMLElement | null;
    const gridSize = getElementSize(grid) || 0;
    const cells = React.Children.map(children, (_, idx) => getCellByIndex(idx));
    const cellsSize = cells.reduce((acc, cell) => acc + cell.size.initial, 0);
    return gridSize - cellsSize;
  }, [getCellByIndex, gridElement.current]);

  const getLargestCell = React.useCallback(() => {
    const cells = React.Children.map(children, (_, idx) => getCellByIndex(idx));
    return cells.reduce((acc, next) => {
      return next.size.initial > acc.size.initial ? next : acc;
    }, cells[0]);
  }, [getCellByIndex]);

  const isCellAtIndexOpened = React.useCallback(
    (idx: number) => {
      const templateSize = template[idx];
      const cell = getCellByIndex(idx);
      const minSize = cell.size.min + 'px';
      return templateSize !== minSize;
    },
    [template, getCellByIndex],
  );

  const getNextOpenedCellByIndex = React.useCallback(
    (idx: number) => {
      for (let i = idx; i < childrenCount; i++) {
        if (isCellAtIndexOpened(i)) return getCellByIndex(i);
      }
    },
    [childrenCount, isCellAtIndexOpened, getCellByIndex],
  );

  const getPrevOpenedCellByIndex = React.useCallback(
    (idx: number) => {
      for (let i = idx; i > -1; i--) {
        if (isCellAtIndexOpened(i)) return getCellByIndex(i);
      }
    },
    [isCellAtIndexOpened, getCellByIndex],
  );

  const getNextCellByIndex = React.useCallback(
    (idx: number, diff: number) => {
      if (diff > 0) return getCellByIndex(idx);
      return getNextOpenedCellByIndex(idx);
    },
    [getNextOpenedCellByIndex, getCellByIndex],
  );

  const getPrevCellByIndex = React.useCallback(
    (idx: number, diff: number) => {
      if (diff > 0) return getPrevOpenedCellByIndex(idx);
      return getCellByIndex(idx);
    },
    [getPrevOpenedCellByIndex, getCellByIndex],
  );

  const onDragEnd = React.useCallback(() => {
    setTemplateLocally(template => {
      onTemplateChange?.(template);
      return template;
    });
  }, [onTemplateChange]);

  const onDragStart = React.useCallback(
    (evt: React.MouseEvent) => {
      const separator = evt?.currentTarget as HTMLElement;
      const idx = Number(separator.dataset.idx);
      setDragginIndex(idx);
      setStartingMousePos(isHorizontal ? evt.clientX : evt.clientY);
    },
    [getCellByIndex, isHorizontal],
  );

  const onDrag = React.useCallback(
    (evt: MouseEvent) => {
      const mousePos = isHorizontal ? evt.clientX : evt.clientY;
      const diff = startingMousePos - mousePos;
      const prevOpenedCell = getPrevCellByIndex(draggingIndex - 1, diff);
      const nextOpenedCell = getNextCellByIndex(draggingIndex, diff);

      setStartingMousePos(mousePos);

      if (prevOpenedCell && nextOpenedCell) {
        const totalSize =
          prevOpenedCell.size.initial + nextOpenedCell.size.initial;

        const prevMin = prevOpenedCell.size.min;
        const targetMin = nextOpenedCell.size.min;
        const prevMax = prevOpenedCell.size.max;
        const targetMax = nextOpenedCell.size.max;

        let newTargetCellSize = nextOpenedCell.size.initial + diff;
        let newPrevCellSize = prevOpenedCell.size.initial - diff;

        if (newTargetCellSize > targetMax) {
          newPrevCellSize = totalSize - targetMax;
          newTargetCellSize = targetMax;
        } else if (newPrevCellSize < prevMin) {
          newTargetCellSize = totalSize - prevMin;
          newPrevCellSize = prevMin;
        } else if (newPrevCellSize > prevMax) {
          newTargetCellSize = totalSize - prevMax;
          newPrevCellSize = prevMax;
        } else if (newTargetCellSize < targetMin) {
          newTargetCellSize = targetMin;
          newPrevCellSize = totalSize - targetMin;
        }

        setTemplateLocally(template =>
          template?.map((item, idx) => {
            if (idx === nextOpenedCell?.index) return newTargetCellSize + 'px';
            if (idx === prevOpenedCell?.index) return newPrevCellSize + 'px';
            return item;
          }),
        );
      }
    },
    [startingMousePos, draggingIndex, getNextCellByIndex, getPrevCellByIndex],
  );

  const toggleCellVisibility = React.useCallback(
    (evt: React.MouseEvent) => {
      const elem = evt?.currentTarget as HTMLElement;
      const idx = Number(elem.dataset.idx);
      const targetCell = getCellByIndex(idx);

      if (isCellAtIndexOpened(idx)) {
        const nextOpenedCell = getNextOpenedCellByIndex(idx + 1);
        const prevOpenedCell = getPrevOpenedCellByIndex(idx - 1);
        const adjustingCell = nextOpenedCell || prevOpenedCell;
        const targetCellSize = targetCell.size.initial - targetCell.size.min;

        return setTemplate(template =>
          template?.map((item, idx) => {
            if (idx === targetCell.index) return targetCell.size.min + 'px';
            if (idx === adjustingCell?.index)
              return adjustingCell.size.initial + targetCellSize + 'px';
            return item;
          }),
        );
      }

      const availableSize = getAvailableSize();
      if (availableSize > 0) {
        return setTemplate(template =>
          template?.map((item, idx) => {
            if (idx === targetCell.index)
              return targetCell.size.initial + availableSize + 'px';
            return item;
          }),
        );
      }

      const largestCell = getLargestCell();
      const newSize =
        largestCell.size.min +
        (largestCell.size.initial - largestCell.size.min) / 2;

      setTemplate(template =>
        template?.map((item, idx) => {
          if (idx === targetCell.index) return newSize + 'px';
          if (idx === largestCell.index) return newSize + 'px';
          return item;
        }),
      );
    },
    [isCellAtIndexOpened, getCellByIndex, getLargestCell],
  );

  React.useEffect(() => {
    setTemplateLocally(initialTemplate || defaultTemplate);
  }, [initialTemplate?.join('-')]);

  const { startDragging, isDragging } = useDragging({
    onDrag,
    onDragStart,
    onDragEnd,
  });

  return (
    <ResizableGrid.Wrapper
      ref={gridElement}
      isDragging={isDragging}
      isHorizontal={isHorizontal}
    >
      {React.Children.map(children, (child, idx) =>
        React.cloneElement(
          child,
          {
            idx,
            key: idx,
            isHorizontal,
            size: template[idx],
            isOpen: isCellAtIndexOpened(idx),
            isDragging: idx === draggingIndex && isDragging,
            onSeparatorMouseDown: startDragging,
            onHeaderClick: toggleCellVisibility,
          },
          child.props.children,
        ),
      )}
    </ResizableGrid.Wrapper>
  );
};

ResizableGrid.Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-direction: ${({ isHorizontal }) => (isHorizontal ? 'row' : 'column')};
  ${({ isDragging }) => isDragging && 'user-select: none'}
`;

ResizableGrid.Cell = ResizableGridCell;

export default ResizableGrid;
