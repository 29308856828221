import { createLogger } from 'redux-logger';

const loggerMiddleware = createLogger({
  collapsed: true,
  duration: true,
  predicate: (getState, action) =>
    !(action.type.includes('HIGHLIGHT') || action.type.includes('TOOLTIP')),
  // predicate: (getState, action) =>
  //   action.type.includes('REMOTE') || action.type.includes('GROUP'),
  // predicate: () => false,
});

export default loggerMiddleware;
