import * as R from 'ramda';
import { filterActions } from 'redux-ignore';

import {
  ADD_FILTER,
  ADD_SEVERAL_FILTERS,
  CLEAR_ALL_FILTERS,
  CLEAR_FILTER,
  namespace,
  REMOVE_FILTER,
  REMOVE_FILTERS,
  REPLACE_FILTER,
  REPLACE_FILTERS_VALUES,
  REPLACE_FILTER_VALUE,
  SET_FILTER,
  SET_INITIAL_FILTER,
} from './FilterActions';
import { createSelector } from 'reselect';
import { Selector } from 'store/models';

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'filter';

type FilterState = {
  [filterKey: string]: string[];
};

const initialState = {};

const FilterReducer = (
  state: FilterState = initialState,
  action: Record<string, any>,
) => {
  switch (action.type) {
    case ADD_FILTER: {
      const { filterName, filterValue } = action.payload;
      const newFilter = R.compose(
        R.dropRepeats,
        R.append(filterValue),
        R.prop(filterName),
      )(state);

      return R.assoc<string[], FilterState>(filterName, newFilter, state);
    }
    case ADD_SEVERAL_FILTERS: {
      const { filterName, filterValues } = action.payload;
      const newFilter = R.compose(
        R.dropRepeats,
        R.concat(filterValues),
        R.propOr([], filterName),
      )(state);

      return R.assoc<string[], FilterState>(filterName, newFilter, state);
    }
    case REPLACE_FILTER: {
      const { oldFilterName, newFilterName } = action.payload;
      const values = state[oldFilterName];
      return R.compose(
        R.assoc(oldFilterName, []),
        R.assoc(newFilterName, values),
      )(state);
    }
    case REPLACE_FILTER_VALUE: {
      const { filterName, oldValue, newValue } = action.payload;
      const oldIndex = state[filterName].indexOf(oldValue);
      const newValues = [...state[filterName]];
      newValues.splice(oldIndex, 1, newValue);
      return R.assoc(filterName, newValues, state);
    }
    case REPLACE_FILTERS_VALUES: {
      const valuesToReplace: {
        [key: string]: { oldValue: string; newValue: string };
      } = action.payload;
      const updatedFilters = Object.entries(valuesToReplace).reduce(
        (acc, [filterName, filterData]) => {
          const values = acc[filterName] ?? [...state[filterName]];
          const index = values.indexOf(filterData.oldValue);
          values.splice(index, 1, filterData.newValue);
          acc[filterName] = values;
          return acc;
        },
        {},
      );
      const operations = Object.entries(updatedFilters).map(
        ([filterName, values]) => R.assocPath([filterName], values),
      );
      return R.compose(...operations)(state);
    }
    case REMOVE_FILTER: {
      const { filterName, filterValue } = action.payload;
      const newFilter = R.without([filterValue], state[filterName]);

      return R.assoc<string[], FilterState>(filterName, newFilter, state);
    }
    case REMOVE_FILTERS: {
      const filtersToRemove = action.payload;
      const operations = Object.entries(filtersToRemove).map(
        ([filterName, values]) =>
          R.assoc(filterName, R.difference(state[filterName], values)),
      );

      return R.compose(...operations)(state);
    }
    case SET_INITIAL_FILTER:
    case SET_FILTER: {
      const { filterName, filterValue } = action.payload;
      const newFilter = !R.isNil(filterValue) ? [filterValue] : [];

      return R.assoc<string[], FilterState>(filterName, newFilter, state);
    }
    case CLEAR_FILTER: {
      const filterName = action.payload;

      return R.assoc<string[], FilterState>(filterName, [], state);
    }
    case CLEAR_ALL_FILTERS: {
      const emptyFilters = Object.keys(state).reduce((acc, filterName) => {
        acc[filterName] = [];

        return acc;
      }, {});

      return emptyFilters;
    }
    default: {
      return state;
    }
  }
};

const getState = (state: any) => state[STATE_KEY];

export const getFilters: Selector<FilterState> = createSelector(
  getState,
  (state: FilterState) => ({ ...state }),
);

export default filterActions(FilterReducer, action =>
  action.type.match(filterRegExp),
);
