import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import type { Action, Selector } from 'store/models';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';

import { isIdNew } from 'helpers';
import {
  CREATE_REMOTE_CAPACITY,
  DELETE_CAPACITY_LOCALLY,
  DELETE_REMOTE_CAPACITY,
  MOVE_WELL_CAPACITY_TO_BUFFER,
  namespace,
  POPULATE_CAPACITY_AFTER_CREATING,
  POPULATE_CAPACITY_EVENTS,
  RESTORE_WELL_CAPACITY_FROM_BUFFER,
  SET_CAPACITY,
  UPDATE_CAPACITY_ANY_FIELD_LOCALLY,
  UPDATE_CAPACITY_BFACTOR_DIRECTLY_LOCALLY,
  UPDATE_CAPACITY_BFACTOR_INDIRECTLY_LOCALLY,
  UPDATE_CAPACITY_CATEGORY_LOCALLY,
  UPDATE_CAPACITY_DAY_LOCALLY,
  UPDATE_CAPACITY_DECLINE_DIRECTLY_LOCALLY,
  UPDATE_CAPACITY_DECLINE_INDIRECTLY_LOCALLY,
  UPDATE_CAPACITY_DESCRIPTION_LOCALLY,
  UPDATE_CAPACITY_EXTRA_INPUTS_LOCALLY,
  UPDATE_CAPACITY_RATE_LOCALLY,
} from './CapacityChangeEventActions';
import type { CapacityChangeEvent } from './models/capacityChangeEvent';
import {
  countBFactor,
  countIndicatorsCapacityChartDataByIndicator,
  countCapacityChartDataByPhase,
  normalizeCapacity,
  updateCapacityEventDeclineIndirectly,
} from './utils';
import { getCurrentWellId } from '../ui/UIReducer';

export const STATE_KEY = 'capacityChangeEvent';
const filterRegExp = new RegExp(`${namespace}/`);
interface WellCapacityEvent {
  [id: string]: CapacityChangeEvent;
}
export type CapacityChangeEventState = {
  [wellId: string]: WellCapacityEvent;
} & { [key: string]: { [wellId: string]: WellCapacityEvent } };

type IdHistory = { [oldId: string]: string };

export const initialState: CapacityChangeEventState = {
  buffer: {},
};

const CapacityChangeEventReducer = (
  state: CapacityChangeEventState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case POPULATE_CAPACITY_EVENTS: {
      const { wellId, events } = action.payload;

      return R.compose(
        R.assocPath<string, CapacityChangeEventState, Record<string, any>>(
          ['buffer', wellId],
          events,
        ),
        R.assoc<
          { [id: string]: CapacityChangeEvent },
          CapacityChangeEventState
        >(wellId, events),
      )(state);
    }
    case SET_CAPACITY: {
      const { capacityEventId, wellId, modifiedCapacityEvent } = action.payload;
      return R.assocPath(
        [wellId, capacityEventId],
        modifiedCapacityEvent,
        state,
      );
    }
    case POPULATE_CAPACITY_AFTER_CREATING: {
      const { wellId, newCapacityEvent } = action.payload;

      return R.assocPath<string, CapacityChangeEventState, CapacityChangeEvent>(
        [wellId, newCapacityEvent.id],
        newCapacityEvent,
        state,
      );
    }
    case UPDATE_CAPACITY_DESCRIPTION_LOCALLY: {
      const { wellId, id, description } = action.payload;

      return R.assocPath<string, CapacityChangeEventState, CapacityChangeEvent>(
        [wellId, id, 'description'],
        description,
        state,
      );
    }
    case UPDATE_CAPACITY_DAY_LOCALLY: {
      const { wellId, modifiedCapacityEvent } = action.payload;
      const newId = R.path(
        ['idHistory', modifiedCapacityEvent.id, 'newId'],
        state,
      );
      if (
        isIdNew(modifiedCapacityEvent.id) &&
        !R.path([wellId, modifiedCapacityEvent.id], state) &&
        !newId
      ) {
        return state;
      }
      const eventId =
        isIdNew(modifiedCapacityEvent.id) && newId
          ? newId
          : modifiedCapacityEvent.id;

      return R.assocPath<string, CapacityChangeEventState, CapacityChangeEvent>(
        [wellId, eventId],
        modifiedCapacityEvent,
        state,
      );
    }
    case UPDATE_CAPACITY_RATE_LOCALLY: {
      const { wellId, capacityEventId, newRate, phase } = action.payload;
      const newId = R.path(['idHistory', capacityEventId, 'newId'], state);
      if (
        isIdNew(capacityEventId) &&
        !R.path([wellId, capacityEventId], state) &&
        !newId
      ) {
        return state;
      }
      const eventId =
        isIdNew(capacityEventId) && newId ? newId : capacityEventId;

      return R.assocPath<string, CapacityChangeEventState, number>(
        [wellId, eventId, `${phase.toLowerCase()}RateInit`],
        newRate,
        state,
      );
    }
    case UPDATE_CAPACITY_BFACTOR_DIRECTLY_LOCALLY: {
      const { wellId, capacityEventId, newBFactor, phase } = action.payload;

      const newId = R.path(['idHistory', capacityEventId, 'newId'], state);
      if (
        isIdNew(capacityEventId) &&
        !R.path([wellId, capacityEventId], state) &&
        !newId
      ) {
        return state;
      }
      const eventId =
        isIdNew(capacityEventId) && newId ? newId : capacityEventId;

      return R.assocPath<string, CapacityChangeEventState, number>(
        [wellId, eventId, `${phase.toLowerCase()}BFactor`],
        newBFactor,
        state,
      );
    }
    case UPDATE_CAPACITY_BFACTOR_INDIRECTLY_LOCALLY: {
      const { wellId, capacityEventId, phase, rate, date } = action.payload;
      const newId = R.path(['idHistory', capacityEventId, 'newId'], state);
      if (
        isIdNew(capacityEventId) &&
        !R.path([wellId, capacityEventId], state) &&
        !newId
      ) {
        return state;
      }
      const eventId =
        isIdNew(capacityEventId) && newId ? newId : capacityEventId;
      const oldCapacityEvent = state[wellId][eventId];
      const newEvent = countBFactor(oldCapacityEvent, rate, date, phase);

      return R.assocPath<string, CapacityChangeEventState, CapacityChangeEvent>(
        [wellId, eventId],
        newEvent,
        state,
      );
    }
    case UPDATE_CAPACITY_CATEGORY_LOCALLY: {
      const { wellId, capacityEventId, categoryId } = action.payload;
      const newId = R.path(['idHistory', capacityEventId, 'newId'], state);
      if (
        isIdNew(capacityEventId) &&
        !R.path([wellId, capacityEventId], state) &&
        !newId
      ) {
        return state;
      }
      const eventId =
        isIdNew(capacityEventId) && newId ? newId : capacityEventId;
      return R.assocPath<string, CapacityChangeEventState, string>(
        [wellId, eventId, 'capacityChangeOptionId'],
        categoryId,
        state,
      );
    }
    case UPDATE_CAPACITY_EXTRA_INPUTS_LOCALLY: {
      const { wellId, capacityEventId, newExtraInputsData } = action.payload;
      const newId = R.path(['idHistory', capacityEventId, 'newId'], state);
      if (
        isIdNew(capacityEventId) &&
        !R.path([wellId, capacityEventId], state) &&
        !newId
      ) {
        return state;
      }
      const eventId =
        isIdNew(capacityEventId) && newId ? newId : capacityEventId;

      return R.assocPath<string, CapacityChangeEventState, string>(
        [wellId, eventId, 'extraInputsData'],
        newExtraInputsData,
        state,
      );
    }
    case UPDATE_CAPACITY_DECLINE_DIRECTLY_LOCALLY: {
      const { wellId, capacityEventId, newDeclineInit, phase } = action.payload;
      const newId = R.path(['idHistory', capacityEventId, 'newId'], state);
      if (
        isIdNew(capacityEventId) &&
        !R.path([wellId, capacityEventId], state) &&
        !newId
      ) {
        return state;
      }
      const eventId =
        isIdNew(capacityEventId) && newId ? newId : capacityEventId;

      return R.assocPath<string, CapacityChangeEventState, string>(
        [wellId, eventId, `${phase}DeclineInitDailyNom`],
        newDeclineInit,
        state,
      );
    }
    case UPDATE_CAPACITY_DECLINE_INDIRECTLY_LOCALLY: {
      const { wellId, capacityEventId, rate, date, phase } = action.payload;

      const newId = R.path(['idHistory', capacityEventId, 'newId'], state);
      if (
        isIdNew(capacityEventId) &&
        !R.path([wellId, capacityEventId], state) &&
        !newId
      ) {
        return state;
      }
      const eventId =
        isIdNew(capacityEventId) && newId ? newId : capacityEventId;
      const updatedCapacityEvent = updateCapacityEventDeclineIndirectly({
        oldCapacityEvent: state[wellId][eventId] as any,
        rate,
        date,
        phase,
      });

      return R.assocPath<string, CapacityChangeEventState, CapacityChangeEvent>(
        [wellId, eventId],
        updatedCapacityEvent,
        state,
      );
    }
    case UPDATE_CAPACITY_ANY_FIELD_LOCALLY: {
      const { wellId, capacityEventId, values } = action.payload;
      const newId = R.path(['idHistory', capacityEventId, 'newId'], state);
      if (
        isIdNew(capacityEventId) &&
        !R.path([wellId, capacityEventId], state) &&
        !newId
      ) {
        return state;
      }
      const eventId =
        isIdNew(capacityEventId) && newId ? newId : capacityEventId;
      const updatedEvent = state[wellId][eventId];
      const updatedCapacityEvent = R.merge(updatedEvent, values);

      return R.assocPath<string, CapacityChangeEventState, CapacityChangeEvent>(
        [wellId, eventId],
        updatedCapacityEvent,
        state,
      );
    }
    case DELETE_CAPACITY_LOCALLY: {
      const { wellId, capacityEventId } = action.payload;

      return R.dissocPath<string, Record<string, any>>(
        [wellId, capacityEventId],
        state,
      );
    }
    case `${DELETE_REMOTE_CAPACITY}_SUCCESS`: {
      const { id } = getGraphqlPrevActionVariables(action);
      const pathname = window.location.pathname;
      if (pathname.includes('/dashboard')) {
        const wellId = pathname.replace('/dashboard/', '');

        return R.dissocPath<string, Record<string, any>>(
          ['buffer', wellId, id],
          state,
        );
      }

      return state;
    }
    case CREATE_REMOTE_CAPACITY: {
      const { wellId, dayInit } = action.payload.graphql.variables.payload;
      const wellCapEvents: WellCapacityEvent = (state[wellId] ||
        {}) as WellCapacityEvent;
      const newWellCapEvents = Object.keys(wellCapEvents).reduce(
        (acc, capId) => {
          if (
            isIdNew(capId) &&
            wellCapEvents[capId].dayInit.getTime() === dayInit.getTime()
          ) {
            acc[capId] = { ...wellCapEvents[capId], syncing: true };
          } else {
            acc[capId] = wellCapEvents[capId];
          }

          return acc as CapacityChangeEvent;
        },
        {},
      );

      return R.assoc(wellId, newWellCapEvents, state);
    }
    case `${CREATE_REMOTE_CAPACITY}_SUCCESS`: {
      const newEvent = getGraphqlPayload(action);
      const normalizedEvent = normalizeCapacity([newEvent])[newEvent.id];
      const wellCapEvents = (state[newEvent.wellId] || {}) as WellCapacityEvent;
      let oldEventId;
      const newWellCapEvents = Object.keys(wellCapEvents).reduce(
        (acc, capId) => {
          if (
            isIdNew(capId) &&
            wellCapEvents[capId].dayInit.getTime() ===
              normalizedEvent.dayInit.getTime()
          ) {
            oldEventId = capId;
            acc[normalizedEvent.id] = normalizedEvent;
          } else {
            acc[capId] = wellCapEvents[capId];
          }

          return acc;
        },
        {},
      );
      const stateWithHistory = R.assocPath(
        ['idHistory', oldEventId],
        { newId: newEvent.id },
        state,
      );
      const stateWithNewEvent = R.assoc<
        { [id: string]: CapacityChangeEvent },
        CapacityChangeEventState
      >(newEvent.wellId, newWellCapEvents, stateWithHistory);

      const newStateWithEmptyBuffer = R.assocPath<
        string,
        CapacityChangeEventState,
        Record<string, any>
      >(['buffer', newEvent.wellId], newWellCapEvents, stateWithNewEvent);

      return newStateWithEmptyBuffer;
    }
    case MOVE_WELL_CAPACITY_TO_BUFFER: {
      const wellId = action.payload;
      const wellCapacityEvents = state[wellId] || {};
      const withoutNewIds = Object.keys(wellCapacityEvents).filter(
        capEventId => !isIdNew(capEventId),
      );
      const withoutNew = withoutNewIds.reduce((acc, id) => {
        acc[id] = wellCapacityEvents[id];

        return acc;
      }, {});

      return R.assocPath<
        string,
        CapacityChangeEventState,
        { [wellId: string]: CapacityChangeEvent }
      >(['buffer', wellId], withoutNew, state);
    }
    case RESTORE_WELL_CAPACITY_FROM_BUFFER: {
      const { wellId } = action.payload;
      const bufferedCapacityEvents = state.buffer[wellId] || {};

      return R.assoc<
        { [wellId: string]: CapacityChangeEvent },
        CapacityChangeEventState
      >(wellId, bufferedCapacityEvents, state);
    }
    default: {
      return state;
    }
  }
};

export const getCapacityByWell = (state: any): CapacityChangeEventState =>
  state[STATE_KEY] || {};

export const getWellCapacityEventsIndexedById: Selector<{
  [id: string]: CapacityChangeEvent;
}> = createSelector(
  getCapacityByWell,
  (_, props) => props.wellId,
  (capacity, wellId) => {
    return (capacity[wellId] || {}) as WellCapacityEvent;
  },
);

export const getWellCapacityEventsSorted: Selector<CapacityChangeEvent[]> =
  createSelector(getWellCapacityEventsIndexedById, capacityIndexedById => {
    const capacityArray = R.values(capacityIndexedById);

    const sorted = capacityArray
      .sort((a, b) => a.dayInit.getTime() - b.dayInit.getTime())
      .reverse();
    return sorted;
  });
export const getCapacityChangeEvent = createSelector(
  getWellCapacityEventsIndexedById as any,
  (_: CapacityChangeEventState, props: any) => props.capacityEventId,
  (wellCapacityByEventId: CapacityChangeEvent, capacityEventId: string) =>
    wellCapacityByEventId[capacityEventId],
);

export const getWellCapacityDataByPhasePerEvent: Selector<{
  [phase: string]: Array<{ date: Date; capacity: number }[]>;
}> = createSelector(
  getWellCapacityEventsSorted,
  (_, props) => props.lastDate,
  (capacityEvents, lastDate) =>
    countCapacityChartDataByPhase({
      capacityEvents,
      lastDate,
    }),
);

export const getWellCapacityDataByPhasePerEventNri: Selector<{
  [phase: string]: Array<{ date: Date; capacity: number }[]>;
}> = createSelector(
  getWellCapacityEventsSorted,
  (_, props) => props.lastDate,
  (_, props) => props.wellId,
  state => state.well.wells,
  (capacityEvents, lastDate, wellId, wells) => {
    const nri = R.pathOr(1, [wellId, 'NRI'], wells);
    return countCapacityChartDataByPhase({
      capacityEvents,
      lastDate,
      nri,
    });
  },
);
export const getFullWellCapacityDataByPhasePerEvent: Selector<{
  [phase: string]: Array<{ date: Date; capacity: number }[]>;
}> = createSelector(
  getWellCapacityEventsSorted,
  (state: any) => state.appConfig.today,
  (capacityEvents, lastDate) => {
    return countCapacityChartDataByPhase({
      capacityEvents,
      lastDate,
    });
  },
);

export const getFullWellCapacityDataByPhasePerEventNri: Selector<{
  [phase: string]: Array<{ date: Date; capacity: number }[]>;
}> = createSelector(
  getWellCapacityEventsSorted,
  (state: any) => state.appConfig.today,
  (_, props) => props.wellId,
  state => state.well.wells,
  (capacityEvents, lastDate, wellId, wells) => {
    const nri = R.pathOr(1, [wellId, 'NRI'], wells);
    return countCapacityChartDataByPhase({
      capacityEvents,
      lastDate,
      nri,
    });
  },
);

export const hasCapacityForIssueCalc: Selector<boolean> = createSelector(
  getCapacityByWell,
  (_, props) => props,
  (capacityByWell, props) => R.keys(capacityByWell).includes(props.wellId),
);

export const getIndicatorsCapacityNri: Selector<{
  [indicator: string]: Array<{ date: Date; capacity: number }>;
}> = createSelector(
  getWellCapacityEventsSorted,
  getWellCapacityDataByPhasePerEvent,
  (_, props) => props.lastDate,
  (_, props) => props.wellId,
  state => state.well.wells,
  (capacityEvents, capacityDataByPhase, lastDate, wellId, wells) => {
    const nri = R.pathOr(1, [wellId, 'NRI'], wells);
    return countIndicatorsCapacityChartDataByIndicator({
      capacityEvents,
      capacityDataByPhase,
      lastDate,
      nri,
    });
  },
);

export const getIndicatorsCapacity: Selector<{
  [indicator: string]: Array<{ date: Date; capacity: number }>;
}> = createSelector(
  getWellCapacityEventsSorted,
  getWellCapacityDataByPhasePerEvent,
  (_, props) => props.lastDate,
  (capacityEvents, capacityDataByPhase, lastDate) =>
    countIndicatorsCapacityChartDataByIndicator({
      capacityEvents,
      capacityDataByPhase,
      lastDate,
    }),
);

export const getIdHistory: Selector<IdHistory> = createSelector(
  getCapacityByWell,
  state => {
    return R.pathOr({}, ['idHistory'], state);
  },
);

export const getHasChanges: Selector<boolean> = (
  state: Record<string, any>,
  wellId: string,
) => {
  const buffered = state[STATE_KEY].buffer[wellId] || {};
  const local = state[STATE_KEY][wellId] || {};
  const hasNewUnsaved = R.values(local).some(capEvent => isIdNew(capEvent.id));

  return (
    (JSON.stringify(buffered) !== JSON.stringify(local) &&
      !R.isEmpty(buffered)) ||
    hasNewUnsaved
  );
};

export const getCapacityDataAvailibility: Selector<boolean> = createSelector(
  getCapacityByWell,
  (_, props) => props.wellId,
  (capacityByWell, wellId) => Object.keys(capacityByWell).includes(wellId),
);

export const getHasCapacityDataForCurrentWell: Selector<any> = createSelector(
  getCurrentWellId,
  getCapacityByWell,
  (wellId, capacityEvents) => !!capacityEvents[wellId],
);

export default filterActions(CapacityChangeEventReducer, action =>
  action.type.match(filterRegExp),
);
