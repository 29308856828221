import * as R from 'ramda';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import {
  authPersistConfig,
  chartOptionsPersistConfig,
  drilldownTablePersistConfig,
  forecastConfig,
  rootPersistConfig,
  UIReducerConfig,
  seriesReducerConfig,
  seriesLayoutConfig,
  filtersLayoutConfig,
  appConfigPersistConfig,
} from 'config/ReduxPersist';
import AllocIssueReducer, {
  STATE_KEY as ALLOC_ISSUE_STATE_KEY,
} from 'modules/allocIssue/AllocIssueReducer';
import AllocIssueCauseReducer, {
  STATE_KEY as ALLOC_ISSUE_CAUSE_STATE_KEY,
} from 'modules/allocIssueCause/AllocIssueCauseReducer';
import AllocIssueStatusReducer, {
  STATE_KEY as ALLOC_ISSUE_STATUS_STATE_KEY,
} from 'modules/allocIssueStatus/AllocIssueStatusReducer';
import AppConfigReducer, {
  STATE_KEY as APP_CONFIG_STATE_KEY,
} from 'modules/appConfig/AppConfigReducer';
import AuthReducer, {
  STATE_KEY as AUTH_STATE_KEY,
} from 'modules/auth/AuthReducer';
import CapacityReducer, {
  STATE_KEY as CAPACITY_STATE_KEY,
} from 'modules/capacityChangeEvent/CapacityChangeEventReducer';
import CategoryReducer, {
  STATE_KEY as CATEGORY_STATE_KEY,
} from 'modules/category/CategoryReducer';
import ChartOptionsReducer, {
  STATE_KEY as CHART_OPTIONS_STATE_KEY,
} from 'modules/chartOptions/ChartOptionsReducer';
import DrilldownTableReducer, {
  STATE_KEY as DRILLDOWN_TABLE_STATE_KEY,
} from 'modules/drilldownTable/DrilldownTableReducer';
import ExternalForecasReducer, {
  STATE_KEY as EXTERNAL_FORECAST_KEY,
} from 'modules/externalForecast/ExternalForecastReducer';
import EventNotesReducer, {
  STATE_KEY as EVENT_NOTES_STATE_KEY,
} from 'modules/eventNotes/EventNotesReducer';
import FilterReducer, {
  STATE_KEY as FILTER_STATE_KEY,
} from 'modules/filter/FilterReducer';
import FiltersLayoutsReducer, {
  STATE_KEY as FILTERS_LAYOUTS_STATE_KEY,
} from 'modules/filterLayouts/FilterLayoutsReducer';
import GroupChartReducer, {
  STATE_KEY as GROUP_CHART_STATE_KEY,
} from 'modules/groupChart/GroupChartReducer';
import InboxConversationReducer, {
  STATE_KEY as INBOX_CONVERSATION_STATE_KEY,
} from 'modules/inboxConversation/InboxConversationReducer';
import NotificationReducer, {
  STATE_KEY as NOTIFICATION_STATE_KEY,
} from 'modules/notification/NotificationReducer';
import NotesReducer, {
  STATE_KEY as NOTES_STATE_KEY,
} from 'modules/notes/NotesReducer';
import PhaseReducer, {
  STATE_KEY as PHASE_STATE_KEY,
} from 'modules/phase/PhaseReducer';
import ProductionReducer, {
  STATE_KEY as PRODUCTION_STATE_KEY,
} from 'modules/production/ProductionReducer';
import RibbonReducer, {
  STATE_KEY as RIBBON_STATE_KEY,
} from 'modules/ribbon/RibbonReducer';
import RouterReducer, {
  STATE_KEY as ROUTER_STATE_KEY,
} from 'modules/router/RouterReducer';
import RequestHelpReducer, {
  STATE_KEY as REQUEST_HELP_STATE_KEY,
} from 'modules/requestHelp/RequestHelpReducer';
import SeriesReducer, {
  STATE_KEY as SERIES_STATE_KEY,
} from 'modules/series/SeriesReducer';
import SeriesLayoutsReducer, {
  STATE_KEY as SERIES_LAYOUTS_STATE_KEY,
} from 'modules/seriesLayouts/SeriesLayoutsReducer';
import SettingsReducer, {
  STATE_KEY as SETTINGS_STATE_KEY,
} from 'modules/settings/SettingsReducer';
import UIReducer, { STATE_KEY as UI_STATE_KEY } from 'modules/ui/UIReducer';
import UserReducer, {
  STATE_KEY as USER_STATE_KEY,
} from 'modules/user/UserReducer';
import VarianceEventReducer, {
  STATE_KEY as VARIANCE_EVENT_STATE_KEY,
} from 'modules/varianceEvent/VarianceEventReducer';
import WellReducer, {
  STATE_KEY as WELL_STATE_KEY,
} from 'modules/well/WellReducer';
import SpotfireReducer, {
  STATE_KEY as SPOTFIRE_STATE_KEY,
} from 'modules/spotfire/SpotfireReducer';
import { History } from 'history';

const persist = R.curry(persistReducer)(rootPersistConfig);

const rootReducer = (history: History) =>
  R.compose(
    persist,
    combineReducers,
    R.assoc(ALLOC_ISSUE_STATE_KEY, AllocIssueReducer),
    R.assoc(ALLOC_ISSUE_CAUSE_STATE_KEY, AllocIssueCauseReducer),
    R.assoc(ALLOC_ISSUE_STATUS_STATE_KEY, AllocIssueStatusReducer),
    R.assoc(AUTH_STATE_KEY, persistReducer(authPersistConfig, AuthReducer)),
    R.assoc(
      APP_CONFIG_STATE_KEY,
      persistReducer(appConfigPersistConfig, AppConfigReducer),
    ),
    R.assoc(CAPACITY_STATE_KEY, CapacityReducer),
    R.assoc(CATEGORY_STATE_KEY, CategoryReducer),
    R.assoc(
      CHART_OPTIONS_STATE_KEY,
      persistReducer(chartOptionsPersistConfig, ChartOptionsReducer),
    ),
    R.assoc(
      DRILLDOWN_TABLE_STATE_KEY,
      persistReducer(drilldownTablePersistConfig, DrilldownTableReducer),
    ),
    R.assoc(EVENT_NOTES_STATE_KEY, EventNotesReducer),
    R.assoc(
      EXTERNAL_FORECAST_KEY,
      persistReducer(forecastConfig, ExternalForecasReducer),
    ),
    R.assoc(FILTER_STATE_KEY, FilterReducer),

    R.assoc(
      FILTERS_LAYOUTS_STATE_KEY,
      persistReducer(filtersLayoutConfig, FiltersLayoutsReducer),
    ),
    R.assoc(GROUP_CHART_STATE_KEY, GroupChartReducer),
    R.assoc(INBOX_CONVERSATION_STATE_KEY, InboxConversationReducer),
    R.assoc(NOTIFICATION_STATE_KEY, NotificationReducer),
    R.assoc(NOTES_STATE_KEY, NotesReducer),
    R.assoc(PHASE_STATE_KEY, PhaseReducer),
    R.assoc(PRODUCTION_STATE_KEY, ProductionReducer),
    R.assoc(RIBBON_STATE_KEY, RibbonReducer),
    R.assoc(REQUEST_HELP_STATE_KEY, RequestHelpReducer),
    R.assoc(ROUTER_STATE_KEY, RouterReducer(history)),
    R.assoc(
      SERIES_STATE_KEY,
      persistReducer(seriesReducerConfig, SeriesReducer),
    ),
    R.assoc(
      SERIES_LAYOUTS_STATE_KEY,
      persistReducer(seriesLayoutConfig, SeriesLayoutsReducer),
    ),
    R.assoc(SETTINGS_STATE_KEY, SettingsReducer),
    R.assoc(WELL_STATE_KEY, WellReducer),
    R.assoc(UI_STATE_KEY, persistReducer(UIReducerConfig, UIReducer)),
    R.assoc(VARIANCE_EVENT_STATE_KEY, VarianceEventReducer),
    R.assoc(USER_STATE_KEY, UserReducer),
    R.assoc(SPOTFIRE_STATE_KEY, SpotfireReducer),
  )({});

export default rootReducer;
