import * as R from 'ramda';
import { utcDay } from 'd3-time';

import type { CapacityChangeEvent } from '../models/capacityChangeEvent';

const normalizeCapacity = (
  rawCapacityChangeEvents: any[],
): { [id: string]: CapacityChangeEvent } => {
  const entries = rawCapacityChangeEvents.map(rawCapacityEvent =>
    R.compose(
      R.assoc('dayInit', utcDay.round(new Date(rawCapacityEvent.dayInit))),
      R.assoc('extraInputsData', JSON.parse(rawCapacityEvent.extraInputsData)),
    )(rawCapacityEvent),
  );

  return R.indexBy(R.prop('id'), entries);
};

export default normalizeCapacity;
