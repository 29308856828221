import React from 'react';

interface DraggingProps {
  onDragStart?: (evt: React.MouseEvent) => void;
  onDrag?: (evt: MouseEvent) => void;
  onDragEnd?: (evt: MouseEvent) => void;
}

export default function useDragging(props: DraggingProps = {}) {
  const [isDragging, setIsDragging] = React.useState(false);

  const handleStartDragging = React.useCallback(
    (evt: React.MouseEvent) => {
      setIsDragging(true);
      props.onDragStart?.(evt);
    },
    [props.onDragStart],
  );

  const handleDragging = React.useCallback(
    (evt: MouseEvent) => {
      if (!isDragging) return;
      props.onDrag?.(evt);
    },
    [props.onDrag, isDragging],
  );

  const handleStopDragging = React.useCallback(
    (evt: MouseEvent) => {
      setIsDragging(false);
      props.onDragEnd?.(evt);
    },
    [props.onDragEnd],
  );

  React.useEffect(() => {
    document.addEventListener('mousemove', handleDragging);
    document.addEventListener('mouseup', handleStopDragging);
    return () => {
      document.removeEventListener('mousemove', handleDragging);
      document.removeEventListener('mouseup', handleStopDragging);
    };
  }, [handleDragging, handleStopDragging]);

  return {
    isDragging,
    startDragging: handleStartDragging,
    stopDragging: handleStopDragging,
  };
}
