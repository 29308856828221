import { utcDay } from 'd3-time';
import * as R from 'ramda';
import { SortDirection } from 'react-virtualized';
import {
  all,
  put,
  takeLatest,
  take,
  select,
  putResolve,
  delay,
} from 'redux-saga/effects';

import {
  NORMALIZE_APP_CONFIG,
  setAppConfigLocally,
  SET_APP_CONFIG,
  SET_APP_CONFIG_LOCALLY,
} from 'modules/appConfig/AppConfigActions';
import { LOGIN } from 'modules/auth/AuthActions';
import { getVarianceCategories } from 'modules/category/CategoryReducer';
// import { getFilters } from 'modules/filter/FilterReducer';
import { OIL } from 'modules/phase/models/phase';
import { parseSearchParams } from 'modules/router/utils/router';
import {
  getAllWells,
  getAdditionalGroupKeys,
  // getColumnMappingBySource,
} from 'modules/well/WellReducer';
import { getCurrentWellId, getGroupMode } from 'modules/ui/UIReducer';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';

import {
  CONFIGURE_SORTING,
  clearDrilldownTable,
  FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE,
  FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE,
  FETCH_FILTERED_WELL_DRILLDOWN_TABLE,
  FETCH_FORECAST_DRILLDOWN_TABLE,
  FETCH_VARIANCE_DRILLDOWN_TABLE,
  FETCH_WELL_DRILLDOWN_TABLE,
  // fetchFilteredForecastDrilldownTable,
  // fetchFilteredDrilldownTable,
  // fetchFilteredVarianceDrilldownTable,
  // fetchForecastDrilldownTable,
  fetchVarianceDrilldownTable,
  fetchWellDrilldownTable,
  INIT_CLEAR_DRILLDOWN_TABLE,
  INIT_DRILLDOWN_TABLE_FETCH,
  populateFilteredVarianceDrilldownTable,
  populateFilteredWellDrilldownTable,
  populateVarianceDrilldownTable,
  populateWellDrilldownTable,
  populateForecastDrilldownTable,
  SET_DRILLDOWN_TABLE_PARAMS,
  setDrilldownTableParams,
  setSortCriteria,
  setSortDirection,
  setSortVarDirectionIndex,
  resetCurrentVarianceOption,
  setNumberOfRoiDays,
  SET_MAX_DRILLDOWN_TABLE_DATE,
  SET_MIN_DRILLDOWN_TABLE_DATE,
  initDrilldownTableFetch,
  resetMarkedRows,
  setMarkedRows,
  MARK_ALL_ROWS,
} from './DrilldownTableActions';
import {
  getDrilldownLoadingStatus,
  getDrilldownTableParams,
  getSortCriteria,
  getSortVarDirectionIndex,
  getFetchingDates,
  getIsMarkingRows,
  getRawWellDrilldownTable,
  getFullGroupedWellTable,
  getCurrentMarkedRows,
} from './DrilldownTableReducer';
import {
  checkIfDrilldownTableDatesAreDefaul,
  normalizeDrilldownTable,
  normalizeForecastDrilldownTable,
  normalizeVarianceDrilldownTable,
} from './utils';
import {
  ASC,
  COMPARE_OPTION,
  VAR_SORTING_ORDER,
} from './models/drilldownTable';
import { clearAllFilters } from 'modules/filter/FilterActions';
import { POPULATE_WELLS } from 'modules/well/WellActions';
import {
  ENABLE_GROUP_MODE,
  SET_CURRENT_GROUP,
  SET_CURRENT_WELL_ID,
} from 'modules/ui/UIActions';

// const isNotEmpty = (val, key) => !R.isEmpty(val);

/**
 * Sorts list, rounds variance sums and populates data with well names, field, pad
 * Adds BOE values
 * @param {Object} action
 */
function* normalizeVarianceDrilldownTableSaga(
  action,
): Generator<any, any, any> {
  const rawDrilldownTable = JSON.parse(getGraphqlPayload(action).varianceTable);
  const {
    payload: { minDate, maxDate },
  } = getGraphqlPrevActionVariables(action);
  const fetchingDates = yield select(getFetchingDates);
  if (fetchingDates.minDate !== minDate && fetchingDates.maxDate !== maxDate) {
    return;
  }
  const days = utcDay.count(minDate, maxDate) + 1;
  const wells = yield select(getAllWells);
  const varianceCategories = yield select(getVarianceCategories);
  const additionalKeys = yield select(getAdditionalGroupKeys, null);
  const drilldownTable = normalizeVarianceDrilldownTable(
    rawDrilldownTable,
    wells,
    varianceCategories,
    additionalKeys,
    days,
  );

  yield put(setNumberOfRoiDays(days));
  if (action.type === `${FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE}_SUCCESS`) {
    yield put(populateFilteredVarianceDrilldownTable(drilldownTable));
    return;
  }
  yield put(populateVarianceDrilldownTable(drilldownTable));
}

function* normalizeForecastDrilldownTableSaga(
  action,
): Generator<any, any, any> {
  const rawDrilldownTable = JSON.parse(getGraphqlPayload(action).forecastTable);
  const wells = yield select(getAllWells);
  const additionalKeys = yield select(getAdditionalGroupKeys, null);
  const drilldownTable = normalizeForecastDrilldownTable(
    rawDrilldownTable,
    wells,
    additionalKeys,
  );

  yield put(populateForecastDrilldownTable(drilldownTable));
}

/**
 * Sorts list, and populates data with well names, field, pad
 * Adds BOE values
 * @param {Object} action
 */
function* normalizeWellDrilldownTableSaga(action): Generator<any, any, any> {
  const rawDrilldownTable = JSON.parse(getGraphqlPayload(action).wellTable);
  if (action.type === `${FETCH_FILTERED_WELL_DRILLDOWN_TABLE}_SUCCESS`) {
    const wells = yield select(getAllWells);
    const currentWellId = yield select(getCurrentWellId);
    const isWellInTabel = rawDrilldownTable.find(
      row => row.wellId === currentWellId,
    );
    const groupMode = yield select(getGroupMode);

    if (!groupMode.isOn && R.path([currentWellId], wells) && !isWellInTabel) {
      yield put(clearAllFilters());
      return;
    }
  }
  const {
    payload: { minDate, maxDate },
  } = getGraphqlPrevActionVariables(action);
  const fetchingDates = yield select(getFetchingDates);
  if (fetchingDates.minDate !== minDate && fetchingDates.maxDate !== maxDate) {
    return;
  }
  const days = utcDay.count(minDate, maxDate) + 1;
  const wells = yield select(getAllWells);
  let fetchedWells = {};
  if (R.isEmpty(wells)) {
    const populateAtion = yield take(POPULATE_WELLS);
    fetchedWells = populateAtion.payload;
  }

  const additionalKeys = yield select(getAdditionalGroupKeys, null);
  const drilldownTable = normalizeDrilldownTable(
    rawDrilldownTable,
    R.isEmpty(wells) ? fetchedWells : wells,
    additionalKeys,
    days,
  );

  yield put(setNumberOfRoiDays(days));
  if (action.type === `${FETCH_FILTERED_WELL_DRILLDOWN_TABLE}_SUCCESS`) {
    yield put(populateFilteredWellDrilldownTable(drilldownTable));
    return;
  }
  yield put(populateWellDrilldownTable(drilldownTable));
}

/**
 * Generates request input data for drilldown fetching basing on drilldown table params, well column mapping and filters
 * @param {Object} action
 */
function* fetchDrilldownTableSaga(action): Generator<any, any, any> {
  const { payload } = action;
  if (R.isEmpty(payload)) return;
  const dates = R.pick(['minDate', 'maxDate'], payload);
  const normalizedRequest = {
    minDate: new Date(
      Math.min(dates.maxDate.getTime(), dates.minDate.getTime()),
    ),
    maxDate: new Date(
      Math.max(dates.maxDate.getTime(), dates.minDate.getTime()),
    ),
  };
  // Disabled for test
  // const filters = yield select(getFilters);
  // const filtersWithoutEmpty = R.pickBy(isNotEmpty, filters);
  // if (!R.isEmpty(filtersWithoutEmpty)) {
  //   const columnMapping = yield select(getColumnMappingBySource);
  //   const normalizedFilters = Object.keys(filtersWithoutEmpty).map(
  //     filterName => {
  //       const columnIndex = columnMapping[filterName].columnIndex;

  //       return {
  //         columnIndex,
  //         values: filtersWithoutEmpty[filterName],
  //       };
  //     },
  //   );
  //   const filtredRequst = {
  //     ...normalizedRequest,
  //     filters: normalizedFilters,
  //   };
  //   yield put(fetchFilteredDrilldownTable(filtredRequst));
  //   yield put(fetchFilteredVarianceDrilldownTable(filtredRequst));
  //   // yield put(fetchFilteredForecastDrilldownTable(filtredRequst));
  // }
  yield put(fetchWellDrilldownTable(normalizedRequest));
  yield put(fetchVarianceDrilldownTable(normalizedRequest));
  // yield put(fetchForecastDrilldownTable(normalizedRequest));
}
/**
 * Sets sorting in right order
 * @param {Object} action
 */
function* configureTableSortingSaga(action): Generator<any, any, any> {
  const { payload } = action;
  const { sortDirection, sortBy } = payload;
  const prevSortBy = yield select(getSortCriteria, null);
  const prevSortVarDirectionIndex = yield select(
    getSortVarDirectionIndex,
    null,
  );
  const groupMode = yield select(getGroupMode);
  const firstColDataKey =
    groupMode.isOn && groupMode.subject ? groupMode.subject : 'well';
  const secondColDataKey = 'actual';
  const thirdColDataKey = 'capacity';
  const fourthColDataKey = 'variance';

  const newSortCriterianIsSimple =
    sortBy === firstColDataKey ||
    sortBy === secondColDataKey ||
    sortBy === thirdColDataKey;
  const prevSortCriteriaIsSimple =
    prevSortBy === firstColDataKey ||
    prevSortBy === secondColDataKey ||
    prevSortBy === thirdColDataKey;

  if (newSortCriterianIsSimple && prevSortBy === fourthColDataKey) {
    yield putResolve(setSortDirection(ASC));
    yield put(setSortCriteria(sortBy));
  } else if (sortBy === fourthColDataKey && prevSortCriteriaIsSimple) {
    yield putResolve(setSortVarDirectionIndex(0));
    yield put(setSortCriteria(fourthColDataKey));
  } else if (sortBy === fourthColDataKey) {
    const newSortVarDirectionIndex =
      (prevSortVarDirectionIndex + 1) % VAR_SORTING_ORDER.length;
    yield putResolve(setSortVarDirectionIndex(newSortVarDirectionIndex));
    yield put(setSortCriteria(fourthColDataKey));
  } else if (sortBy === prevSortBy) {
    yield putResolve(setSortDirection(sortDirection));
    yield put(setSortCriteria(sortBy));
  } else {
    yield putResolve(setSortDirection(SortDirection.ASC));
    yield put(setSortCriteria(sortBy));
  }
}

function* setParamsSaga(action): Generator<any, any, any> {
  const config = action.payload;
  const drilldownTableParams = yield select(getDrilldownTableParams, null);
  const searchString = window.location.search;
  const routeSearchParams = parseSearchParams(searchString);
  if (R.isEmpty(drilldownTableParams)) {
    yield put(
      setDrilldownTableParams({
        phase: OIL,
        minDate: new Date(config.drilldownDefaultFromDate),
        maxDate: new Date(config.drilldownDefaultToDate),
        grossNet: routeSearchParams.grossNet,
        rateVolume: 'Rate',
      }),
    );
  } else if (
    checkIfDrilldownTableDatesAreDefaul(
      drilldownTableParams.minDate,
      drilldownTableParams.maxDate,
    )
  ) {
    yield put(
      setDrilldownTableParams({
        phase: OIL,
        minDate: new Date(config.drilldownDefaultFromDate),
        maxDate: new Date(config.drilldownDefaultToDate),
        rateVolume: 'Rate',
      }),
    );
  }
}

function* checkCapacitySaga(): Generator<any, any, any> {
  yield put(clearDrilldownTable());
}

function* clearCurentVarianceOptionSaga(action): Generator<any, any, any> {
  if (R.path(['payload', 'compareOption'], action) !== COMPARE_OPTION.actual)
    yield put(resetCurrentVarianceOption());
}

function* checkTableLoadingSaga(action): Generator<any, any, any> {
  const loadingStatus = yield select(getDrilldownLoadingStatus);
  const drilldownParams = yield select(getDrilldownTableParams);
  if (loadingStatus.isWellTableFetching) {
    yield delay(400);
    yield put(initDrilldownTableFetch(drilldownParams));
  }
}
function* handleCofigChangeSaga(action): Generator<any, any, any> {
  const payload =
    action.type === SET_APP_CONFIG_LOCALLY
      ? action.payload
      : action.payload.graphql.variables.data;
  if (!payload.capacityVsExternal) {
    yield put(
      setDrilldownTableParams({ compareOption: COMPARE_OPTION.actual }),
    );
  }
  if (payload.capacityVsExternal && !payload.showExternalForecast) {
    yield delay(100);
    yield put(setAppConfigLocally({ ...payload, showExternalForecast: true }));
  }
}

function* changeNumberOfRoiDaysSaga(): Generator<any, any, any> {
  const { minDate, maxDate } = yield select(getDrilldownTableParams);
  const days = utcDay.count(minDate, maxDate) + 1;

  yield put(setNumberOfRoiDays(days));
}

function* resetDrilldownMarkingSaga(): Generator<any, any, any> {
  const isMarkingRows = yield select(getIsMarkingRows);

  if (!isMarkingRows) return;

  yield put(resetMarkedRows());
}

function* setInitialMarkingSaga(action): Generator<any, any, any> {
  let wells = yield select(getAllWells);

  if (R.isEmpty(wells)) {
    yield take(POPULATE_WELLS);
  }

  wells = yield select(getAllWells);

  if (action.type === SET_CURRENT_GROUP) {
    return yield put(setMarkedRows([action.payload.item]));
  }

  const well = wells[action.payload];
  if (!well) return;

  yield put(setMarkedRows([well.LEASE]));
}

function* markAllRowsSaga(): Generator<any, any, any> {
  const groupMode = yield select(getGroupMode);
  const markerRows = yield select(getCurrentMarkedRows);

  if (groupMode.isOn) {
    const table = yield select(getFullGroupedWellTable, {
      subject: groupMode.subject,
    });
    const rows = table.map(r => r[groupMode.subject]);
    if (markerRows.length === rows.length) return;
    return yield put(setMarkedRows(rows));
  }

  const table = yield select(getRawWellDrilldownTable);
  const rows = table.map(r => r.well);
  if (markerRows.length === rows.length) return;
  return yield put(setMarkedRows(rows));
}

function* drilldownTableSagas(): Generator<any, any, any> {
  yield all([
    takeLatest([INIT_CLEAR_DRILLDOWN_TABLE, LOGIN], checkCapacitySaga),
    takeLatest(INIT_DRILLDOWN_TABLE_FETCH, fetchDrilldownTableSaga),
    takeLatest(
      [
        `${FETCH_VARIANCE_DRILLDOWN_TABLE}_SUCCESS`,
        `${FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE}_SUCCESS`,
      ],
      normalizeVarianceDrilldownTableSaga,
    ),
    takeLatest(
      [
        `${FETCH_WELL_DRILLDOWN_TABLE}_SUCCESS`,
        `${FETCH_FILTERED_WELL_DRILLDOWN_TABLE}_SUCCESS`,
      ],
      normalizeWellDrilldownTableSaga,
    ),
    takeLatest(
      [
        `${FETCH_FORECAST_DRILLDOWN_TABLE}_SUCCESS`,
        `${FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE}_SUCCESS`,
      ],
      normalizeForecastDrilldownTableSaga,
    ),
    takeLatest(SET_DRILLDOWN_TABLE_PARAMS, clearCurentVarianceOptionSaga),
    takeLatest(
      [SET_MAX_DRILLDOWN_TABLE_DATE, SET_MIN_DRILLDOWN_TABLE_DATE],
      checkTableLoadingSaga,
    ),
    takeLatest(
      [SET_MAX_DRILLDOWN_TABLE_DATE, SET_MIN_DRILLDOWN_TABLE_DATE],
      changeNumberOfRoiDaysSaga,
    ),
    takeLatest(CONFIGURE_SORTING, configureTableSortingSaga),
    takeLatest(NORMALIZE_APP_CONFIG, setParamsSaga),
    takeLatest([SET_APP_CONFIG, SET_APP_CONFIG_LOCALLY], handleCofigChangeSaga),
    takeLatest(
      [ENABLE_GROUP_MODE, SET_CURRENT_GROUP],
      resetDrilldownMarkingSaga,
    ),
    takeLatest([SET_CURRENT_WELL_ID, SET_CURRENT_GROUP], setInitialMarkingSaga),
    takeLatest(MARK_ALL_ROWS, markAllRowsSaga),
  ]);
}

export default drilldownTableSagas;
