import { utcFormat } from 'd3-time-format';
import { utcMinute } from 'd3-time';
import * as React from 'react';

import { ZOOM_LEVELS, X_AXIS_HEIGHT } from 'modules/chart/models/chart';

interface XAxisProps {
  extremeDates: { min: Date; max: Date };
  isDragging: boolean;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  xScale: any;
  width: number;
}

const XAxis = ({
  extremeDates,
  isDragging,
  onXAxisScaling,
  width,
  xScale,
}: XAxisProps) => {
  const axisEl = React.useRef() as React.RefObject<SVGSVGElement>;

  const displayedInterval = React.useMemo(
    () => utcMinute.count(extremeDates.min, extremeDates.max),
    [extremeDates],
  );
  const zoom = React.useMemo(
    () =>
      ZOOM_LEVELS.find(
        zoom =>
          zoom.rangeMin < displayedInterval &&
          xScale.ticks(zoom.primary.interval).length < width / 3 &&
          xScale.ticks(zoom.secondary.interval).length *
            zoom.secondary.labelWidth <
            width,
      ) || ZOOM_LEVELS[1],
    [displayedInterval, width, xScale],
  );

  const primaryTicks = React.useMemo(() => {
    const ticks = xScale.ticks(zoom.primary.interval);
    ticks.unshift(xScale.domain()[0]);
    return ticks;
  }, [xScale, zoom.primary.interval]);

  const secondaryTicks = React.useMemo(() => {
    const ticks = xScale.ticks(zoom.secondary.interval);
    ticks.unshift(xScale.domain()[0]);
    return ticks;
  }, [xScale, zoom.secondary.interval]);

  const primaryFormat = React.useMemo(() => {
    return typeof zoom.primary.format === 'string'
      ? utcFormat(zoom.primary.format)
      : zoom.primary.format;
  }, [zoom.primary.format]);
  const secondaryFormat = React.useMemo(
    () => utcFormat(zoom.secondary.format),
    [zoom.secondary.format],
  );

  const onWheelHandler = React.useCallback(
    e => {
      onXAxisScaling(e, axisEl);
    },
    [axisEl, onXAxisScaling],
  );

  return (
    <svg
      ref={axisEl}
      preserveAspectRatio="none"
      width={width}
      height={X_AXIS_HEIGHT}
      viewBox={`0 0 ${width} ${X_AXIS_HEIGHT}`}
      shapeRendering="crispEdges"
      style={{ cursor: isDragging ? 'grabbing' : 'zoom-in' }}
      onWheel={onWheelHandler}
    >
      {primaryTicks &&
        primaryTicks.map((tick, i) => {
          const tickPosition = Math.max(xScale(tick), xScale.range()[0]);
          const tickStep = primaryTicks[i + 1]
            ? xScale(primaryTicks[i + 1]) - tickPosition
            : xScale.range()[1] - tickPosition;
          return (
            <g key={'primary' + i + '_' + tick.toString()}>
              {tickPosition !== xScale.range()[0] &&
                tickPosition !== xScale.range()[1] && (
                  <line
                    x1={tickPosition}
                    x2={tickPosition}
                    y1={0}
                    y2={7}
                    stroke={i === 0 ? 'transparent' : 'black'}
                    strokeWidth="1"
                  />
                )}
              <text
                x={tickPosition + tickStep / 2}
                y={10}
                fontSize={10}
                textAnchor="middle"
                style={{ userSelect: 'none' }}
              >
                {zoom.primary.labelWidth - tickStep >= 10
                  ? ''
                  : primaryFormat(tick)}
              </text>
            </g>
          );
        })}

      {secondaryTicks &&
        secondaryTicks.map((tick, i) => {
          const tickPosition = Math.max(xScale(tick), xScale.range()[0]);
          const tickStep = secondaryTicks[i + 1]
            ? xScale(secondaryTicks[i + 1]) - tickPosition
            : xScale.range()[1] - tickPosition;

          return (
            <g key={'secondary' + i + '_' + tick.toString()}>
              {tickPosition !== xScale.range()[0] &&
                tickPosition !== xScale.range()[1] && (
                  <line
                    x1={tickPosition}
                    x2={tickPosition}
                    y1={0}
                    y2={20}
                    stroke="black"
                    strokeWidth="1"
                  />
                )}
              <text
                x={tickPosition + tickStep / 2}
                y={25}
                fontSize={10}
                textAnchor="middle"
                style={{ userSelect: 'none' }}
              >
                {tickStep < zoom.secondary.labelWidth
                  ? ''
                  : secondaryFormat(tick)}
              </text>
            </g>
          );
        })}

      <line
        key={'outer2'}
        x1={width}
        x2={width}
        y1={0}
        y2={20}
        stroke="black"
        strokeWidth="1"
      />
    </svg>
  );
};

export default XAxis;
