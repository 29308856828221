import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import type { Action, Selector } from 'store/models';
import { getGraphqlPayload } from 'store/helpers';

import {
  ADD_NOTE,
  DELETE_NOTE_ATTACHMENT_LOCALLY,
  FETCH_NOTES,
  namespace,
  REMOVE_NOTE,
  UPDATE_NOTE,
} from './NotesActions';

import {
  getNotesByProductionDay,
  normalizeNotes,
  normalizeNote,
} from 'modules/notes/utils/notes';
import type { Note } from 'modules/notes/models/notes';

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'notes';

interface NotesState {
  [wellId: string]: Note[];
}

const initialState = {};

const NotesReducer = (state: NotesState = initialState, action: Action) => {
  switch (action.type) {
    case `${FETCH_NOTES}_SUCCESS`: {
      const notes = getGraphqlPayload(action);
      if (action.meta) {
        const wellId =
          action.meta.previousAction.payload.graphql.variables.wellId;
        const wellNotes = normalizeNotes(notes);

        return R.assoc<Record<string, any>, NotesState>(
          wellId,
          wellNotes,
          state,
        );
      }
      return state;
    }
    case `${ADD_NOTE}_SUCCESS`: {
      const newNote = getGraphqlPayload(action);
      const newNormalizedNote = normalizeNote(newNote);
      const { wellId } = newNote;
      const newWellNotes = R.append(newNormalizedNote, state[wellId]);

      return R.assoc<Note[], NotesState>(wellId, newWellNotes, state);
    }
    case `${UPDATE_NOTE}_SUCCESS`: {
      if (action.meta) {
        const updatedNote = getGraphqlPayload(action);
        const updatedNormalizedNote = normalizeNote(updatedNote);
        const { wellId } = updatedNote;

        const wellNotes = state[wellId].filter(
          note => note.id !== updatedNote.id,
        );
        const newWellNotes = R.append(updatedNormalizedNote, wellNotes);

        return R.assoc<Note[], NotesState>(wellId, newWellNotes, state);
      }

      return state;
    }
    case `${REMOVE_NOTE}_SUCCESS`: {
      if (action.meta) {
        const { id, wellId } =
          action.meta.previousAction.payload.graphql.variables;
        const wellNotes = state[wellId];
        const newWellNotes = wellNotes.filter(note => note.id !== id);

        return R.assoc<Note[], NotesState>(wellId, newWellNotes, state);
      }

      return state;
    }
    case DELETE_NOTE_ATTACHMENT_LOCALLY: {
      const { wellId, noteId, attachmentId } = action.payload;
      const wellNotes = state[wellId];
      const newWellNotes = wellNotes.map(note => {
        if (note.id === noteId) {
          const newAttachments = note.attachments.filter(
            attachment => attachment.id !== attachmentId,
          );

          return { ...note, attachments: newAttachments };
        }

        return note;
      });
      return R.assoc<Note[], NotesState>(wellId, newWellNotes, state);
    }
    default: {
      return state;
    }
  }
};

export const getAllNotes = (state: Record<string, any>) => state[STATE_KEY];

export const getWellNotes: Selector<Note[]> = createSelector(
  getAllNotes,
  (_, wellId) => wellId,
  (notes, wellId) => notes[wellId] || [],
);

export const getWellNotesByDay: Selector<{
  [date: string]: Note[];
}> = createSelector(getWellNotes, (wellNotes: Note[]) =>
  getNotesByProductionDay(wellNotes),
);

export const getWellNotesDates: Selector<Date[]> = createSelector(
  getWellNotesByDay,
  notesByDay => R.keys(notesByDay).map(key => new Date(key)),
);
export const getNote = (
  state: Record<string, any>,
  wellId: string,
  noteId: string,
) => {
  const wellNotes = R.pathOr([], [STATE_KEY, wellId], state);
  return wellNotes.find(note => note.id === noteId);
};

export default filterActions(NotesReducer, action =>
  action.type.match(filterRegExp),
);
