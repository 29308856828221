import * as React from 'react';
import styled from 'styled-components';

import {
  getMaxCapacityPointDate,
  getMinCapacityPointDate,
} from 'modules/dashboard/utils/dashboard';
import type { CapacityChangeEvent } from '../models/capacityChangeEvent';

import DateInput from 'components/DateInput';

interface CapacityDateFormProps {
  capacityEventsSorted: CapacityChangeEvent[];
  disabled: boolean;
  capacityEvent: CapacityChangeEvent;
  onCapacityDayUpdate: (day: Date) => void;
  pointIndex: number;
}

const CapacityDateForm = ({
  capacityEventsSorted,
  disabled,
  pointIndex,
  capacityEvent,
  onCapacityDayUpdate,
}: CapacityDateFormProps) => (
  <CapacityDateForm.Form>
    <CapacityDateForm.Header>Start Date</CapacityDateForm.Header>

    <CapacityDateForm.DateInputContainer>
      <DateInput
        id="dayInit"
        name="dayInit"
        disabled={disabled}
        date={capacityEvent.dayInit}
        max={getMaxCapacityPointDate(capacityEventsSorted, pointIndex)}
        min={getMinCapacityPointDate(capacityEventsSorted, pointIndex)}
        onProcess={date => onCapacityDayUpdate(date)}
        transparent
      />
    </CapacityDateForm.DateInputContainer>
  </CapacityDateForm.Form>
);

CapacityDateForm.Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

CapacityDateForm.Header = styled.p`
  font-size: 14px;
`;

CapacityDateForm.DateInputContainer = styled.div`
  height: 30px;
  width: 255px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid #c1c1c1;
  padding: 0;
`;

export default CapacityDateForm;
