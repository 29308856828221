import gql from 'graphql-tag';

import type { Action } from 'store/models';
import { MetricsAction } from './models';

export const namespace = 'METRICS';
export const TRACK_EVENT = `${namespace}/TRACK_EVENT`;
export const FETCH_NEWRELIC_DATA = `${namespace}/FETCH_NEWRELIC_DATA`;

const newRelicDataQuery = gql`
  query {
    listNewRelicData {
      accId
      appId
      licenseKey
    }
  }
`;

export const fetchNewRelicData = () => ({
  type: FETCH_NEWRELIC_DATA,
  payload: {
    key: 'listNewRelicData',
    graphql: {
      query: newRelicDataQuery,
    },
  },
});

type trackEventAC = (payload: MetricsAction) => Action;

export const trackEvent: trackEventAC = payload => ({
  type: TRACK_EVENT,
  payload,
});
