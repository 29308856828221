import { utcFormat } from 'd3-time-format';
import { format } from 'd3-format';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import type { Category } from 'modules/category/models/category';
import { CHARTS_OPTIONS, VARIANCE_TRELLIS } from 'modules/chart/models/chart';
import { GOR, GLR, WATERCUT } from 'modules/phase/models/phase';
import type { TrellisTooltipData } from 'modules/ui/models/ui';

interface InformatioinTooltipProps {
  isDisplayingForecast?: boolean;
  tooltipData: TrellisTooltipData;
  varianceCategories?: { [id: string]: Category };
  forGroupChart: boolean;
  today: Date;
}

const TrellisInformation = ({
  isDisplayingForecast,
  tooltipData,
  varianceCategories,
  forGroupChart,
  today,
}: InformatioinTooltipProps) => {
  const {
    production,
    capacity,
    forecast,
    variance,
    day,
    trellis,
    varianceOptionId,
  } = tooltipData;

  const isFutureDate = React.useMemo(() => {
    return day >= today;
  }, [day, today]);

  const shouldDisplayVariance = React.useMemo(
    () => trellis !== GOR && trellis !== GLR && trellis !== WATERCUT,
    [trellis],
  );
  const isVariance = trellis === VARIANCE_TRELLIS;

  const numbersFormat = React.useMemo(
    () => format(R.pathOr(',d', [trellis, 'format'], CHARTS_OPTIONS)),
    [trellis],
  );
  const varianceCategory =
    varianceOptionId === 'allocIssue'
      ? 'Allocation Issue'
      : R.pathOr<string, string, Record<string, any>, Record<string, any>>(
          'Uncategorized',
          [varianceOptionId, 'subCause'],
          varianceCategories,
        );

  return (
    <>
      <TrellisInformation.Date>
        {utcFormat('%m/%d/%Y')(day)}
      </TrellisInformation.Date>
      {!isVariance && !isFutureDate && (
        <>
          <span>Actual:</span>
          <TrellisInformation.Value>
            {numbersFormat(production)}
          </TrellisInformation.Value>
        </>
      )}
      {!isVariance && capacity !== null && (
        <>
          <span>Capacity:</span>
          <TrellisInformation.Value>
            {numbersFormat(capacity)}
          </TrellisInformation.Value>
        </>
      )}
      {!isVariance && forecast && isDisplayingForecast ? (
        <>
          <span>RE Fcst:</span>
          <TrellisInformation.Value>
            {numbersFormat(forecast)}
          </TrellisInformation.Value>
        </>
      ) : null}
      {!forGroupChart && !isFutureDate && (
        <TrellisInformation.Category>
          {varianceCategory}
        </TrellisInformation.Category>
      )}
      {shouldDisplayVariance &&
        isVariance &&
        variance !== null &&
        variance !== undefined && (
          <>
            <span>Variance:</span>
            <TrellisInformation.Value>
              {numbersFormat(Math.abs(variance))}
            </TrellisInformation.Value>
            <TrellisInformation.Category>
              {varianceOptionId === 'allocIssue'
                ? 'Allocation Issue'
                : varianceCategory}
            </TrellisInformation.Category>
          </>
        )}
    </>
  );
};

TrellisInformation.Date = styled.span`
  grid-area: 1 / 1 / 2 / 3;
  text-align: center;
`;

TrellisInformation.Value = styled.span`
  text-align: right;
`;

TrellisInformation.Category = styled.span`
  grid-area: 5 / 1 / 5 / 3;
  text-align: center;
`;

export default TrellisInformation;
