import { all, put, select, takeLatest } from 'redux-saga/effects';

import { AUTHENTICATED } from 'modules/app/AppActions';
import {
  clearAllFilters,
  REPLACE_FILTER,
  REPLACE_FILTERS_VALUES,
  REPLACE_FILTER_VALUE,
  SET_FILTER,
} from 'modules/filter/FilterActions';
import { getGraphqlPayload } from 'store/helpers';

import {
  FETCH_WELL_COLUMN_MAPPING,
  FETCH_WELLS,
  fetchWells,
  fetchWellColumnMapping,
  populateWells,
  populateWellsLeaseToIdMapping,
  setWellsWithCustomValues,
  POPULATE_WELLS,
} from './WellActions';
import { getColumnMapping, getWellsAsArray } from './WellReducer';
import {
  setFilterOptions,
  SET_WELL_CUSTOM_ATTRIBUTES,
} from 'modules/filterLayouts/FilterLayoutsActions';
import {
  getCustomOptions,
  getFiltersOptions,
  getWellCustomAttributesValues,
} from 'modules/filterLayouts/FilterLayoutsReducer';
import { joinCustomAttributesToWells, normalizeWells } from './utils/well';

/**
 * Fetches column mapping
 */
function* fetchColumnMappingSaga(): Generator<any, any, any> {
  yield put(fetchWellColumnMapping());
}

/**
 * Fetches list of wells basing on well collumn mapping
 */
function* fetchWellsSaga(): Generator<any, any, any> {
  const columnMapping = yield select(getColumnMapping);
  const options = yield select(getFiltersOptions);
  const columnsIndices = columnMapping.map(column => column.columnIndex);

  yield put(fetchWells(columnsIndices));

  const filterColumns = columnMapping.reduce((acc, columnMappingItem) => {
    const { filterColumn } = columnMappingItem;
    const arrayIndex = parseFloat(filterColumn) - 1;
    if (!acc[arrayIndex]) acc[arrayIndex] = [];
    acc[arrayIndex].push(columnMappingItem);

    return acc;
  }, []);

  let filterColumnsTableView = filterColumns.reduce((acc, column) => {
    return [
      ...acc,
      ...column.sort((a, b) =>
        a.filterColumnOrder > b.filterColumnOrder ? 1 : -1,
      ),
    ];
  }, []);
  const wellColumn = filterColumnsTableView.find(
    col => col.displayName === 'Well',
  );

  if (wellColumn !== undefined) {
    filterColumnsTableView = filterColumnsTableView.filter(
      col => col.displayName !== 'Well',
    );
    filterColumnsTableView.splice(0, 0, wellColumn);
  }

  const filterOptions = filterColumnsTableView.reduce(
    (
      acc,
      { id, displayName, wiserockBinding, sourceName, source, description },
      idx,
    ) => {
      acc[id] = {
        id,
        displayName,
        filterName: wiserockBinding || sourceName,
        order: idx + 1,
        isShown: options[id]?.isShown ?? false,
        isSelected: options[id]?.isSelected ?? false,
        source,
        description,
      };
      return acc;
    },
    {},
  );
  filterOptions['0'] = {
    id: '0',
    displayName: 'Wise Rock ID',
    filterName: 'id',
    order: 0,
    isShown: options['0']?.isShown ?? false,
    isSelected: options['0']?.isSelected ?? false,
  };
  yield put(setFilterOptions({ options: filterOptions }));
}

function* clearFiltersSaga(action): Generator<any, any, any> {
  const existedWells = yield select(getWellsAsArray);
  const { wells } = getGraphqlPayload(action);

  const fetchedWells = JSON.parse(wells);
  const columnMapping = yield select(getColumnMapping);
  const { normalizedWells, leaseToIdMapping } = normalizeWells(
    fetchedWells,
    columnMapping,
  );
  yield put(populateWells(normalizedWells));
  yield put(populateWellsLeaseToIdMapping(leaseToIdMapping));
  const existedWellsIds = existedWells
    .map(well => well.id)
    .sort((a, b) => a - b);
  const fetchedWellsIds = fetchedWells
    .map(well => well.id)
    .sort((a, b) => a - b);
  const needsToClearFilters =
    JSON.stringify(existedWellsIds) !== JSON.stringify(fetchedWellsIds);

  if (existedWellsIds.length && needsToClearFilters) {
    yield put(clearAllFilters());
  }
}

function* joinCustomValuesToWellsSaga(): Generator<any, any, any> {
  const values = yield select(getWellCustomAttributesValues);
  const attributes = yield select(getCustomOptions);
  const wells = yield select(getWellsAsArray);

  const wellsWithCustomValues = joinCustomAttributesToWells(
    wells,
    attributes,
    values,
  ).reduce((acc, well) => {
    acc[well.id] = well;
    return acc;
  }, {});

  yield put(setWellsWithCustomValues(wellsWithCustomValues));
}

function* wellSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(`${FETCH_WELL_COLUMN_MAPPING}_SUCCESS`, fetchWellsSaga),
    takeLatest(AUTHENTICATED, fetchColumnMappingSaga),
    takeLatest(`${FETCH_WELLS}_SUCCESS`, clearFiltersSaga),
    takeLatest(
      [
        SET_FILTER,
        REPLACE_FILTER,
        REPLACE_FILTERS_VALUES,
        REPLACE_FILTER_VALUE,
        SET_WELL_CUSTOM_ATTRIBUTES,
        POPULATE_WELLS,
      ],
      joinCustomValuesToWellsSaga,
    ),
  ]);
}

export default wellSagas;
