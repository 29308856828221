import type { RibbonEvent } from '../models';

const normalizeExtraInputsData = (extraInputsData: any) => {
  if (!extraInputsData) return {};
  try {
    return JSON.parse(extraInputsData);
  } catch (err) {
    return {};
  }
};

const normalizeRibbonEventsData = (events: RibbonEvent[], today: Date) => {
  const normalizedData = events.reduce((acc, event) => {
    acc[event.id] = {
      ...event,
      dayStart: new Date(event.dayStart),
      dayEnd: event.dayEnd ? new Date(event.dayEnd) : today,
      extraInputsData: normalizeExtraInputsData(event.extraInputsData),
    };
    return acc;
  }, {});

  return normalizedData;
};

export default normalizeRibbonEventsData;
