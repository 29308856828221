import * as React from 'react';
import { Ribbon } from 'modules/ribbon/models';

import styled from 'styled-components';
import ShowTrellisButton from './ShowTrellisButton';
import { PANEL_SIZES } from '../models';

interface RibbonMenuProps {
  ribbons: Ribbon[];
  selectedRibbons: { [key: string]: boolean };
  onRibbonCheck: (ribbon: { id: string; checked: boolean }) => void;
}
const RibbonMenu = ({
  ribbons,
  selectedRibbons,
  onRibbonCheck,
}: RibbonMenuProps) => {
  return (
    <>
      <RibbonMenu.Wrapper>
        <RibbonMenu.TrellisMenuLegend>Ribbons</RibbonMenu.TrellisMenuLegend>
        <RibbonMenu.Container>
          <RibbonMenu.OptionWrapper>
            {ribbons.map((ribbon, i) => (
              <RibbonMenu.ItemWrapper key={ribbon.id} isFirst={i === 0}>
                <RibbonMenu.RibbonName>{ribbon.name}</RibbonMenu.RibbonName>
                <ShowTrellisButton
                  show={!!selectedRibbons[ribbon.id]}
                  onShowClick={() =>
                    onRibbonCheck({
                      id: `${ribbon.id}`,
                      checked: !selectedRibbons[ribbon.id],
                    })
                  }
                />
              </RibbonMenu.ItemWrapper>
            ))}
          </RibbonMenu.OptionWrapper>
        </RibbonMenu.Container>
      </RibbonMenu.Wrapper>
    </>
  );
};

RibbonMenu.TrellisMenuLegend = styled.legend`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
`;

RibbonMenu.Container = styled.div`
  border: ${props =>
    props.isDragging ? props.theme.borders.thingray : 'none'};
  margin: ${props => (props.isDragging ? '0' : '1px 0')};
  max-height: ${PANEL_SIZES.maxRibbonsAreaHeight}px;
  overflow-y: scroll;
  padding-right: 9px;
`;

RibbonMenu.ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 25px;
  border-top: ${props =>
    props.isFirst ? 'none' : props.theme.borders.thingray};
  align-items: center;
`;

RibbonMenu.OptionWrapper = styled.div`
  border: ${props => props.theme.borders.thingray};
  background-color: #efefef;
`;

RibbonMenu.RibbonName = styled.span`
  margin-left: 5px;
`;

RibbonMenu.Wrapper = styled.div`
  border-top: 1px solid #c1c1c1;
  padding: 0 0 0 16px;
`;

export default RibbonMenu;
