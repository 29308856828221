import * as R from 'ramda';
import { CHARTS_OPTIONS } from 'modules/chart/models/chart';
import { OIL, GAS, WATER, BOE } from 'modules/phase/models/phase';

const getPillText = (
  trellis: string,
  grossNet: string,
  isVarince: boolean,
): string => {
  const grossNetText =
    trellis === BOE || trellis === OIL || trellis === GAS || trellis === WATER
      ? grossNet + ' '
      : '';
  const pillText = `
    ${
      grossNetText + R.pathOr('', [trellis, 'title'], CHARTS_OPTIONS)
    }, ${R.pathOr('', [trellis, 'units'], CHARTS_OPTIONS)}`;
  const pillVarianceText = `
  ${grossNet} ${R.pathOr(
    '',
    [trellis, 'title'],
    CHARTS_OPTIONS,
  )} Variance, ${R.pathOr('', [trellis, 'units'], CHARTS_OPTIONS)} `;
  return !isVarince ? pillText : pillVarianceText;
};

export default getPillText;
