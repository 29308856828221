import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { isIdNew } from 'helpers';
import { useNonInputKeydown } from 'hooks/useKeydown';
import { getPermissions } from 'modules/auth/AuthReducer';
import {
  updateLocalAllocIssue,
  deleteAllocIssue,
  deleteLocalAllocIssue,
} from 'modules/allocIssue/AllocIssueActions';
import {
  getAllAllocIssueAsArray,
  getWellAllocIssues,
} from 'modules/allocIssue/AllocIssueReducer';
import { getAllocIssueCauses } from 'modules/allocIssueCause/AllocIssueCauseReducer';
import { getAllocIssueStatuses } from 'modules/allocIssueStatus/AllocIssueStatusReducer';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import { hasCapacityForIssueCalc } from 'modules/capacityChangeEvent/CapacityChangeEventReducer';
import { getWellVarianceEventsSortedByDate } from 'modules/varianceEvent/VarianceEventReducer';
import RightPanel from 'modules/dashboard/components/RightPanel';
import DetailNotesPanel from 'modules/eventNotes/containers/EventNoteThread';
import { redirectToChartFromBacklog } from 'modules/router/RouterActions';
import { getDataAvailibility } from 'modules/production/ProductionReducer';
import {
  openAllocIssueAlertWindow,
  closeAllocIssueAlertWindow,
} from 'modules/ui/UIActions';
import {
  getAllocIssueAlertWindow,
  getCurrentWellId,
  getNoteImageDetailsOverlay,
  getWasDragging,
} from 'modules/ui/UIReducer';
import { getUsers } from 'modules/user/UserReducer';
import useRightPanel from 'modules/ui/hooks/useRightPanel';

import AlertWindow from 'components/AlertWindow';
import Button from 'components/Button';
import CloseOnMouseClickOutside from 'components/CloseOnMouseClickOutside';

import EditAllocIssueForm from '../forms/EditAllocIssueForm';

const EditAllocIssuePanel = () => {
  const dispatch = useDispatch();
  const appConfig = useSelector(getAppConfig);

  const rightPanel = useRightPanel();
  const chartWasDragging = useSelector(getWasDragging);
  const allocIssueAlertWindow = useSelector(getAllocIssueAlertWindow);
  const onAllocIssueAlertWindowClose = React.useCallback(
    () => dispatch(closeAllocIssueAlertWindow()),
    [dispatch],
  );
  const onAllocIssueUpdate = React.useCallback(
    ({ updatedIssue, data }) => {
      dispatch(
        updateLocalAllocIssue({ updatedIssue, data, interactive: false }),
      );
    },
    [dispatch],
  );

  const allAllocIssues = useSelector(getAllAllocIssueAsArray);
  const allocIssuesById = React.useMemo(
    () => R.indexBy(R.prop('id'), allAllocIssues),
    [allAllocIssues],
  );
  const allocIssueCauses = useSelector(getAllocIssueCauses);
  const allocIssueStatuses = useSelector(getAllocIssueStatuses);
  const editedIssue = React.useMemo(
    () => allocIssuesById[rightPanel.dialogId] || {},
    [rightPanel.dialogId, allocIssuesById],
  );
  const currentWellId = useSelector(getCurrentWellId);
  const issueWellId = editedIssue.wellId;
  const varianceEvents = useSelector(state =>
    getWellVarianceEventsSortedByDate(state, {
      wellId: issueWellId || currentWellId,
    }),
  );

  const onDialogClose = React.useCallback(() => {
    if (!editedIssue.id || isIdNew(editedIssue.id)) return;
    rightPanel.unsetDialog();
  }, [rightPanel.unsetDialog, editedIssue.id]);

  const handleDelete = React.useCallback(() => {
    const id = rightPanel.dialogId;
    const index = rightPanel.dialogIndex;
    if (!isIdNew(id)) dispatch(openAllocIssueAlertWindow({ index, id }));
  }, [rightPanel.dialogIndex, rightPanel.dialogId, dispatch]);

  const users = useSelector(getUsers);
  const permissions = useSelector(getPermissions);
  const imageDetailsOverlay = useSelector(getNoteImageDetailsOverlay);
  const { isAllowedEditAllocIssues } = permissions;

  const onChartRedirect = React.useCallback(() => {
    dispatch(redirectToChartFromBacklog(editedIssue));
  }, [editedIssue, dispatch]);

  const hasCapacity = useSelector(store =>
    hasCapacityForIssueCalc(store, { wellId: issueWellId }),
  );
  const hasProduction = useSelector(state =>
    getDataAvailibility(state, issueWellId),
  );

  const onDeleteAllocIssue = React.useCallback(() => {
    const { id } = allocIssueAlertWindow;
    const wellId = allocIssuesById[id].wellId;
    dispatch(deleteLocalAllocIssue({ wellId, id }));
    dispatch(deleteAllocIssue(id));
    onAllocIssueAlertWindowClose();
  }, [
    allocIssuesById,
    allocIssueAlertWindow,
    dispatch,
    onAllocIssueAlertWindowClose,
  ]);

  const currentWellIssues = useSelector(state =>
    getWellAllocIssues(state, {
      wellId: issueWellId || currentWellId,
    }),
  );

  const isShownRightArrow = React.useMemo(
    () => currentWellIssues.length - 1 > rightPanel.dialogIndex,
    [currentWellIssues, rightPanel.dialogIndex],
  );

  const isShownLeftArrow = React.useMemo(
    () => rightPanel.dialogIndex > 0,
    [rightPanel.dialogIndex],
  );

  const onRightArrowClick = React.useCallback(() => {
    if (isShownRightArrow) {
      const index = rightPanel.dialogIndex + 1;
      const id = currentWellIssues[index]?.id;
      rightPanel.setDialog({ type: 'AllocationIssue', data: { id, index } });
    }
  }, [isShownRightArrow, rightPanel.dialogIndex, currentWellIssues, dispatch]);

  const onLeftArrowClick = React.useCallback(() => {
    if (isShownLeftArrow) {
      const index = rightPanel.dialogIndex - 1;
      const id = currentWellIssues[index]?.id;
      rightPanel.setDialog({ type: 'AllocationIssue', data: { id, index } });
    }
  }, [isShownLeftArrow, rightPanel.dialogIndex, currentWellIssues, dispatch]);

  useNonInputKeydown(
    ({ keyName }) => {
      if (!rightPanel.isDialogOfType('AllocationIssue')) return;
      if (imageDetailsOverlay.show) return;
      if (keyName === 'RIGHT') return onRightArrowClick();
      if (keyName === 'LEFT') return onLeftArrowClick();
      if (keyName === 'ESCAPE') return onDialogClose();
      if (keyName === 'DELETE') return handleDelete();
    },
    [
      imageDetailsOverlay.show,
      rightPanel.isDialogOfType,
      onDialogClose,
      handleDelete,
      onLeftArrowClick,
      onRightArrowClick,
    ],
  );

  const handleClickOutside = React.useCallback(() => {
    if (document.activeElement?.tagName !== 'TEXTAREA' && !chartWasDragging) {
      onDialogClose();
    }
  }, [chartWasDragging, onDialogClose]);

  if (!rightPanel.isDialogOfType('AllocationIssue')) return null;

  return (
    <RightPanel
      isShown
      onDialogClose={onDialogClose}
      title="Allocation Issue Details"
      isShownLeftArrow={isShownLeftArrow}
      isShownRightArrow={isShownRightArrow}
      onLeftArrowClick={onLeftArrowClick}
      onRightArrowClick={onRightArrowClick}
    >
      <EditAllocIssuePanel.Container>
        {Boolean(editedIssue.id) && (
          <CloseOnMouseClickOutside
            exceptForClassName="alloc-interactive"
            closeHandler={handleClickOutside}
            event="mouseup"
          >
            <EditAllocIssuePanel.Scrollable>
              <EditAllocIssuePanel.Content
                id="alloc-issue-panel-content"
                className="alloc-interactive interactive"
              >
                <EditAllocIssueForm
                  allocIssue={editedIssue}
                  allocIssues={allAllocIssues}
                  allocIssueCauses={allocIssueCauses}
                  allocIssueStatuses={allocIssueStatuses}
                  appConfig={appConfig}
                  disabled={!isAllowedEditAllocIssues}
                  hasDataForIssueCalc={hasCapacity && hasProduction}
                  onAllocIssueUpdate={onAllocIssueUpdate}
                  users={users}
                  varianceEvents={varianceEvents}
                />
              </EditAllocIssuePanel.Content>
              <DetailNotesPanel
                eventId={editedIssue.id}
                eventType="allocation"
                wellId={editedIssue.wellId}
              />
            </EditAllocIssuePanel.Scrollable>

            <EditAllocIssuePanel.Footer>
              {window.location.pathname.includes('/dashboard') ? (
                <Link to="/backlog">
                  <Button width={120}>View Backlog</Button>
                </Link>
              ) : (
                <Button width={185} onClick={onChartRedirect}>
                  View Production Chart
                </Button>
              )}
              <EditAllocIssuePanel.MarginBtnWrapper>
                <Button
                  disabled={!isAllowedEditAllocIssues}
                  width={63}
                  variant="critical"
                  onClick={handleDelete}
                  className="alloc-interactive interactive"
                >
                  Delete
                </Button>
              </EditAllocIssuePanel.MarginBtnWrapper>
              {window.location.pathname.includes('/dashboard') && (
                <EditAllocIssuePanel.MarginBtnWrapper>
                  <Button width={53} onClick={onDialogClose}>
                    Done
                  </Button>
                </EditAllocIssuePanel.MarginBtnWrapper>
              )}
            </EditAllocIssuePanel.Footer>
          </CloseOnMouseClickOutside>
        )}
      </EditAllocIssuePanel.Container>

      {allocIssueAlertWindow.show && (
        <AlertWindow
          handleClose={onAllocIssueAlertWindowClose}
          onDelete={onDeleteAllocIssue}
          subject="allocation issue"
        />
      )}
    </RightPanel>
  );
};

EditAllocIssuePanel.Container = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr) min-content;
  grid-template-columns: 1fr;
  height: 100%;
`;

EditAllocIssuePanel.Content = styled.div`
  width: 100%;
  padding: 15px 15px 0 15px;
  margin-bottom: -20px;
`;

EditAllocIssuePanel.Scrollable = styled.div`
  overflow-y: auto;
`;

EditAllocIssuePanel.MarginBtnWrapper = styled.div`
  margin-left: 10px;
`;

EditAllocIssuePanel.Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 7px;
  margin: 10px 0;
`;

export default EditAllocIssuePanel;
