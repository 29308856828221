import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import styled from 'styled-components';

const generateTSVString = (data, dataKeys, header) => {
  const columnHeaders = header ? header : dataKeys;
  const firstLine = columnHeaders
    .map(key => key[0].toUpperCase() + key.slice(1))
    .join('\t');
  const rows = data
    .map(datarow => {
      return dataKeys
        .map((dataKey, i) => {
          if (i !== 0) return datarow[dataKey];
          return datarow.planType
            ? datarow[dataKey] + ', ' + datarow.planType
            : datarow[dataKey];
        })
        .join('\t');
    })
    .join('\r\n');
  const result = firstLine + '\r\n' + rows;
  return result;
};

interface ContextMenuProps {
  closeMenu: () => void;
  contextPosition: { clientX: number; clientY: number } | null;
  data: any;
  dataKeys: string[];
  header?: string[];
  onMenuHover: () => void;
  onMenuLeave: () => void;
}

const ContextMenu = (
  {
    closeMenu,
    contextPosition,
    data,
    dataKeys,
    header,
    onMenuHover,
    onMenuLeave,
  }: ContextMenuProps,
  ref,
) => {
  const copyString = React.useMemo(
    () => generateTSVString(data, dataKeys, header),
    [data, dataKeys, header],
  );
  const copyToClipboard = React.useCallback(() => {
    const textField = document.createElement('textarea');
    textField.value = copyString;
    if (document.body) {
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
    }
    closeMenu();
  }, [copyString, closeMenu]);
  return (
    <ContextMenu.ContextMenuWrapper
      ref={ref}
      onMouseLeave={onMenuLeave}
      onMouseEnter={onMenuHover}
      x={contextPosition ? contextPosition.clientX : 0}
      y={contextPosition ? contextPosition.clientY : 0}
      onClick={copyToClipboard}
    >
      <ContextMenu.StyledPaper>
        <ContextMenu.MenuList>
          <ContextMenu.MenuItem>
            Copy Table Contents to Clipboard
          </ContextMenu.MenuItem>
        </ContextMenu.MenuList>
      </ContextMenu.StyledPaper>
    </ContextMenu.ContextMenuWrapper>
  );
};

ContextMenu.ContextMenuWrapper = styled.div`
  position: fixed;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  pointer-events: auto;
  z-index: 999;
`;

ContextMenu.StyledPaper = styled(Paper)`
  border-radius: 5px;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.5) !important;
`;

ContextMenu.MenuList = styled(MenuList)``;

ContextMenu.MenuItem = styled(MenuItem)`
  min-width: 175px;
  &:hover {
    background-color: #e7e7e7 !important;
  }
`;

export default React.forwardRef<HTMLElement, ContextMenuProps>(ContextMenu);
