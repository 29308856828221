import { EventNote } from '../models';
import * as R from 'ramda';

const normalizeEventNotes = (notes: EventNote[], wellId: string): any => {
  const normilizedNotes = notes.reduce((acc, note) => {
    return R.assocPath(
      [note.eventType, note.eventId, note.id],
      { ...note, noteTimestamp: new Date(note.noteTimestamp) },
      acc,
    );
  }, {});
  return normilizedNotes;
};
export default normalizeEventNotes;
