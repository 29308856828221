import React from 'react';
import { useDispatch } from 'react-redux';

import { USER_ACTION_IDS } from '../constants';
import { GetMetricsActionFn } from '../models';
import * as MetricsActions from '../MetricsActions';

const useMetrics = () => {
  const dispatch = useDispatch();

  const trackEvent = React.useCallback(
    (getAction: GetMetricsActionFn) => {
      const action = getAction(USER_ACTION_IDS);
      dispatch(MetricsActions.trackEvent(action));
    },
    [dispatch],
  );

  return {
    trackEvent,
  };
};

export default useMetrics;
