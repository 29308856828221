import { utcDay, utcHour } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import { throttle } from 'throttle-debounce';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import { getMinDate } from 'helpers';

interface NotesProductionLineProps {
  xScale: any;
  leftOffset: number;
  notesDates: Date[];
  notesProductionDay: Date;
  setNotesProductionDay: (date: Date) => void;
  startDrag: () => void;
  finishDrag: () => void;
  today: Date;
  isAdditionalMode: boolean;
}

const NotesProductionLine = ({
  xScale,
  leftOffset,
  notesDates,
  notesProductionDay,
  setNotesProductionDay,
  startDrag,
  finishDrag,
  today,
  isAdditionalMode,
}: NotesProductionLineProps) => {
  const extraPositions = [
    xScale.range()[0],
    xScale(getMinDate(xScale.domain()[1], today)),
  ];

  const handleLineMove = React.useCallback(
    (e: MouseEvent) => {
      const pointerPosition = e.clientX - leftOffset - Y_AXIS_WIDTH - 10;

      if (pointerPosition < extraPositions[0]) {
        const newDay = utcDay.round(xScale.invert(extraPositions[0]));
        setNotesProductionDay(newDay);

        return;
      } else if (pointerPosition > extraPositions[1]) {
        const newDay = utcDay.round(xScale.invert(extraPositions[1]));
        setNotesProductionDay(newDay);

        return;
      }

      const newDay = utcDay.floor(xScale.invert(pointerPosition));
      setNotesProductionDay(newDay);
    },
    [extraPositions, setNotesProductionDay, xScale, leftOffset],
  );

  const throttleHandleLineMove = React.useCallback(
    throttle(50, false, e => handleLineMove(e)),
    [xScale],
  );
  // eslint-disable-next-line
  const handleMouseMove = e => {
    throttleHandleLineMove(e);
  };

  const handleMouseUp = React.useCallback(() => {
    finishDrag();
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [finishDrag, handleMouseMove]);

  const handleMouseDown = React.useCallback(
    e => {
      e.preventDefault();
      startDrag();
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [handleMouseUp, handleMouseMove, startDrag],
  );

  React.useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const linePosition = xScale(utcHour.offset(notesProductionDay, 12));
  const trianglesPositions = React.useMemo(
    () =>
      notesDates.reduce((acc, date, i, sourceArr) => {
        const position = Math.round(xScale(utcHour.offset(date, 12)));
        if (
          position >= 0 &&
          (position - R.pathOr(-7, [acc.length - 1], acc) > 7 ||
            i === sourceArr.length - 1)
        ) {
          acc.push(position);
        }
        return acc;
      }, [] as number[]),
    [notesDates, xScale],
  );

  return (
    <NotesProductionLine.Container>
      <NotesProductionLine.Line
        position={linePosition}
        hasTriangle={
          !!notesDates.find(
            date => date.getTime() === notesProductionDay.getTime(),
          )
        }
      />
      <NotesProductionLine.Subline
        id="note-production-line"
        position={linePosition}
        onMouseDown={e => handleMouseDown(e)}
        isAdditionalMode={isAdditionalMode}
      />
      {trianglesPositions.map(position => {
        if (position !== linePosition)
          return (
            <NotesProductionLine.NoteArrow key={position} position={position} />
          );
        return null;
      })}
    </NotesProductionLine.Container>
  );
};

NotesProductionLine.Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  pointer-events: none;
`;

NotesProductionLine.NoteArrow = styled.div`
  display: block;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid
    ${(props: Record<string, any>) => props.theme.colors.activeInput};
  position: absolute;
  top: 0;
  left: calc(${(props: Record<string, any>) => props.position}px - 7px);
`;

NotesProductionLine.Line = styled.div`
  position: relative;
  width: 2px;
  height: 100%;
  border-left: 2px solid
    ${(props: Record<string, any>) => props.theme.colors.activeInput};
  transform: ${(props: Record<string, any>) =>
    `translate(${props.position - 1}px)`};

  &::before {
    content: '';
    display: ${(props: Record<string, any>) =>
      props.hasTriangle ? 'block' : 'none'};
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid
      ${(props: Record<string, any>) => props.theme.colors.activeInput};
    position: absolute;
    top: 0;
    left: -8px;
  }
`;

NotesProductionLine.Subline = styled.div`
  width: ${({ isAdditionalMode }) => (isAdditionalMode ? 0 : '10px')};
  height: 100%;
  background-color: transparent;
  position: relative;
  top: -100%;
  transform: ${(props: Record<string, any>) =>
    `translate(${props.position - 5}px)`};
  cursor: ew-resize;
  pointer-events: auto;
`;

export default NotesProductionLine;
