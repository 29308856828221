import { utcDay, utcMonth } from 'd3-time';
import { utcFormat, utcParse } from 'd3-time-format';
import * as R from 'ramda';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import {
  BAR_CHART,
  DataSeriesGroupToDisplay,
  STEP_LINE_CHART,
} from 'modules/chartOptions/models';
import { COMPARE_OPTION } from 'modules/drilldownTable/models/drilldownTable';
import { getDefaultProductionRange } from 'modules/production/utils';

import {
  CORESERIES_BIT_MAP,
  EventPanel,
  EventPanelData,
  TRELLISES_BIT_MAP,
} from '../models/router';

const SIGNS_REGEX = /([.~#?&/|\\@"'% ])+/g;

export const decodeTrellises = (trellisCode: string): string[] => {
  const parsedCode = parseInt(trellisCode, 10);

  return Object.keys(TRELLISES_BIT_MAP).reduce((acc, trellisName) => {
    if (Boolean(parsedCode & TRELLISES_BIT_MAP[trellisName]))
      acc.push(trellisName);

    return acc;
  }, [] as string[]);
};

export const encodeCoreSeries = (coreSeriesNamesArray: string[]): string => {
  return coreSeriesNamesArray.reduce((acc, coreSeriesName) => {
    if (acc === '0') {
      return CORESERIES_BIT_MAP[coreSeriesName];
    }
    return acc + '-' + CORESERIES_BIT_MAP[coreSeriesName];
  }, '0');
};

export const decodeCoreSeries = (coreSeriesCode: string): string[] => {
  const coreSeriesUrlParams = coreSeriesCode.split('-');

  return Object.keys(CORESERIES_BIT_MAP).reduce((acc, coreSeriesName) => {
    if (coreSeriesUrlParams.includes(CORESERIES_BIT_MAP[coreSeriesName]))
      acc.push(coreSeriesName);
    return acc;
  }, [] as string[]);
};

export const encodeDataSeries = (
  dataSeriesOptionsGroups: DataSeriesGroupToDisplay,
): string =>
  dataSeriesOptionsGroups
    ? dataSeriesOptionsGroups.reduce((acc, group) => {
        const groupToString = group.dataSeriesGroup.reduce(
          (acc, { id, customColor, chartType }) => {
            const seriesColor = customColor ? '_' + customColor : '';
            const seriesType = chartType === BAR_CHART ? '_b' : '';
            const dataSeriesString = id + seriesColor + seriesType;
            return acc === '' ? dataSeriesString : acc + '-' + dataSeriesString;
          },
          '',
        );
        return acc === '0' ? groupToString : acc + '--' + groupToString;
      }, '0')
    : '';

export const encodeSensorSeries = (
  sensorSeriesOptionsGroups: DataSeriesGroupToDisplay,
): string => {
  return encodeDataSeries(
    sensorSeriesOptionsGroups.map(g => ({
      dataSeriesGroup: g.dataSeriesGroup.map(o => ({
        ...o,
        id: o.id.substring(1),
      })),
    })),
  );
};

export const decodeDataSeries = (
  dataSeriesString: string,
): DataSeriesGroupToDisplay => {
  if (dataSeriesString === '0') {
    return [];
  }
  const groups = dataSeriesString.split('--');
  return groups.reduce(
    (acc, group) => {
      const options = group.split('-');
      const dataSeriesGroup = options.map(option => {
        const hexCodeRegExp = /^[0-9A-F]{6}$/i;
        const optionParams = option.split('_');
        return {
          id: optionParams[0],
          customColor:
            optionParams[1] && hexCodeRegExp.test(optionParams[1])
              ? optionParams[1]
              : '',
          chartType:
            (optionParams[1] && optionParams[1] === 'b') ||
            (optionParams[2] && optionParams[2] === 'b')
              ? BAR_CHART
              : STEP_LINE_CHART,
        };
      });
      return [...acc, { dataSeriesGroup }];
    },
    [] as {
      dataSeriesGroup: {
        id: string;
        chartType: string;
        customColor: string;
      }[];
    }[],
  );
};

export const decodeSensorSeries = (
  sensorSeriesString: string | DataSeriesGroupToDisplay,
): DataSeriesGroupToDisplay => {
  const series = Array.isArray(sensorSeriesString)
    ? sensorSeriesString
    : decodeDataSeries(sensorSeriesString);

  return series.map(g => ({
    dataSeriesGroup: g.dataSeriesGroup.map(o => ({ ...o, id: `s${o.id}` })),
  }));
};

export const parseRibbons = (ribbons: string): RegExpMatchArray | null =>
  ribbons.match(/\d+/g);

const parseEventPanel = (eventPanel: string | null): EventPanelData | null => {
  if (eventPanel === null) return null;

  const [stringType, stringId] = eventPanel.split('_');

  const type = EventPanel[stringType];

  if (type === undefined) {
    return null;
  }

  return { type, id: stringId };
};

export const stringifySearchParams = ({
  extremeDates,
  coreSeries,
  sensorSeries,
  drilldown,
  legend,
  grossNet,
  ribbons,
  external,
  eventPanel,
  compare,
  debug,
  dataSeries,
}: {
  extremeDates?: { min: Date | null; max?: Date | null } | null;
  coreSeries?: string[] | null;
  drilldown?: boolean | null;
  legend?: boolean | null;
  grossNet?: string | null;
  ribbons?: string[] | null;
  external?: boolean | null;
  eventPanel?: EventPanelData | null;
  compare?: string | null;
  debug?: string | null;
  dataSeries?: DataSeriesGroupToDisplay | null;
  sensorSeries?: DataSeriesGroupToDisplay | null;
}): string => {
  const format = utcFormat('%m-%d-%Y');
  const urlSearchParams = new URLSearchParams();

  if (extremeDates && extremeDates.min && extremeDates.max) {
    const { min, max } = extremeDates;
    urlSearchParams.append('fromDate', format(min));
    const offsetMax = utcDay.offset(max, -1);
    const maxDate = offsetMax.getTime() < min.getTime() ? min : offsetMax;
    urlSearchParams.append('toDate', format(maxDate));
  }

  urlSearchParams.append('coreseries', encodeCoreSeries(coreSeries ?? []));

  urlSearchParams.append('dataseries', encodeDataSeries(dataSeries ?? []));

  if (sensorSeries) {
    urlSearchParams.append(
      'sensorseries',
      encodeSensorSeries(sensorSeries ?? []),
    );
  }

  urlSearchParams.append('drilldown', String(drilldown ?? 'true'));

  urlSearchParams.append('legend', String(legend ?? 'false'));

  urlSearchParams.append('grossNet', grossNet === 'Net' ? 'net' : 'gross');

  if (ribbons && ribbons.length > 0)
    urlSearchParams.append('ribbons', ribbons.join('-'));

  urlSearchParams.append('external', String(external ?? 'false'));

  if (eventPanel) {
    urlSearchParams.append(
      'eventPanel',
      `${EventPanel[eventPanel.type]}_${eventPanel.id}`,
    );
  }

  if (compare === 'extVsCap') {
    urlSearchParams.append('compare', 'external');
  } else if (compare === 'actVsExt') {
    urlSearchParams.append('compare', 'actualexternal');
  } else {
    urlSearchParams.append('compare', 'actual');
  }

  if (debug === '1') {
    urlSearchParams.append('debug', '1');
  }

  return urlSearchParams.toString();
};

export interface SearchParams {
  min: Date | null;
  max: Date | null;
  extremeDates: {
    min: Date | null;
    max: Date | null;
  } | null;
  coreSeries: string[] | null;
  dataSeries: DataSeriesGroupToDisplay | null;
  sensorSeries: DataSeriesGroupToDisplay | null;
  drilldown: boolean;
  legend: boolean;
  grossNet: string;
  ribbons: RegExpMatchArray | null;
  eventPanel: EventPanelData | null;
  external: boolean;
  compare: string;
  debug: string;
}

export const parseSearchParams = (searchString: string): SearchParams => {
  const parser = utcParse('%m-%d-%Y');
  const urlSearchParams = new URLSearchParams(searchString);
  const min = urlSearchParams.get('fromDate');
  const max = urlSearchParams.get('toDate');
  const coreSeries = urlSearchParams.get('coreseries');
  const trellises = urlSearchParams.get('trellises');
  const dataSeries = urlSearchParams.get('dataseries');
  const sensorSeries = urlSearchParams.get('sensorseries');
  const drilldown = urlSearchParams.get('drilldown');
  const legend = urlSearchParams.get('legend');
  const grossNet = urlSearchParams.get('grossNet');
  const ribbons = urlSearchParams.get('ribbons');
  const eventPanel = urlSearchParams.get('eventPanel');
  const external = urlSearchParams.get('external');
  const compare = urlSearchParams.get('compare');
  const debug = urlSearchParams.get('debug');

  return {
    min: min ? parser(min) : null,
    max: max ? utcDay.offset(parser(max) as Date, 1) : null,
    extremeDates:
      min && max
        ? {
            min: min ? parser(min) : null,
            max: max ? utcDay.offset(parser(max) as Date, 1) : null,
          }
        : null,
    coreSeries: trellises
      ? decodeTrellises(trellises)
      : coreSeries
      ? decodeCoreSeries(coreSeries)
      : null,
    dataSeries: dataSeries ? decodeDataSeries(dataSeries) : null,
    sensorSeries: sensorSeries ? decodeDataSeries(sensorSeries) : null,
    drilldown: drilldown === 'false' ? false : true,
    legend: legend === 'false' ? false : true,
    grossNet: grossNet === 'net' ? 'Net' : 'Gross',
    ribbons: ribbons ? parseRibbons(ribbons) : [],
    eventPanel: parseEventPanel(eventPanel),
    external: external === 'false' ? false : true,
    compare:
      compare === 'actualexternal'
        ? 'actVsExt'
        : compare === 'external'
        ? 'extVsCap'
        : COMPARE_OPTION.actual,
    debug: debug === '1' ? '1' : '0',
  };
};

export const countNewDateRangeForRedirectFromBacklog = (
  editedIssue: AllocIssue,
  extremeDates: { min: Date; max: Date },
  today: Date,
): { min: Date; max: Date; shouldUpdate: boolean } => {
  if (!editedIssue || R.isEmpty(editedIssue)) {
    return { ...extremeDates, shouldUpdate: false };
  }
  if (
    editedIssue.dateStart.getTime() <= extremeDates.min.getTime() ||
    editedIssue.dateEnd.getTime() >= extremeDates.max.getTime()
  ) {
    const defaultProductionRange = getDefaultProductionRange(today);
    const min = utcMonth.offset(utcMonth.round(editedIssue.dateStart), -3);
    const max = defaultProductionRange.max;

    return { min, max, shouldUpdate: true };
  }

  return { ...extremeDates, shouldUpdate: false };
};

export const encodeGroup = (
  subject: string,
  item: string,
): { subject: string; item: string } => {
  return {
    subject: subject ? subject.replace(SIGNS_REGEX, '-') : 'empty',
    item: item ? item.replace(SIGNS_REGEX, '-') : 'empty',
  };
};
