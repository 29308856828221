import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CloseIcon } from 'components/Icons';

import { hideNotification } from '../NotificationActions';
import { getNotification, isNotificationOpen } from '../NotificationReducer';

const NotificationsCenter = () => {
  const dispatch = useDispatch();
  const notification = useSelector(getNotification);
  const isOpen = useSelector(isNotificationOpen);
  const handleClose = () => dispatch(hideNotification());

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      message={R.prop('message', notification)}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon color="white" />
        </IconButton>,
      ]}
    />
  );
};

export default NotificationsCenter;
