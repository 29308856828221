import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { deleteRibbonEventLocally } from 'modules/ribbon/RibbonActions';
import { getEventsOfSelectedRibbons } from 'modules/ribbon/RibbonReducer';
import { RibbonEvent } from 'modules/ribbon/models';
import { getCurrentWellId } from 'modules/ui/UIReducer';
import useRightPanelDialogs from 'modules/ui/hooks/useRightPanelDialogs';

const useLocalRibbonEvent = () => {
  const dispatch = useDispatch();
  const { ribbonDetailsDialog } = useRightPanelDialogs();
  const wellId = useSelector(getCurrentWellId);
  const selectedRibbonEvents = useSelector(getEventsOfSelectedRibbons);

  const latestLocalEvent = React.useMemo(() => {
    const ribbonEvents = selectedRibbonEvents[ribbonDetailsDialog.index] || [];
    return ribbonEvents.reduce((acc, event) => {
      const eventLocallyCreatedAt = event.locallyCreatedAt || 0;
      const resultLocallyCreatedAt = acc?.locallyCreatedAt || 0;
      if (eventLocallyCreatedAt > resultLocallyCreatedAt) acc = event;
      return acc;
    }, null as RibbonEvent | null);
  }, [selectedRibbonEvents, ribbonDetailsDialog.index]);

  const deleleLatestLocalRibbonEvent = React.useCallback(() => {
    if (!latestLocalEvent) return;
    const ribbonEventId = latestLocalEvent.id;
    dispatch(deleteRibbonEventLocally({ wellId, ribbonEventId }));
  }, [dispatch, wellId, deleteRibbonEventLocally, latestLocalEvent]);

  return {
    latestLocalEvent,
    deleleLatestLocalRibbonEvent,
  };
};

export default useLocalRibbonEvent;
