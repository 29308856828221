import { XYCoord } from 'dnd-core';
import React from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import styled from 'styled-components';

import { ChartOptionsMenuIcon } from 'components/Icons';
import { ItemTypes, SeriesLayoutOption } from '../models/seriesLayouts';
import LayaoutOptionControlButtons from './LayaoutOptionControlButtons';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface LayaoutDragOptionProps {
  setLayout: (setFieldValue, layout) => void;
  setFieldValue: any;
  layout: SeriesLayoutOption;
  setHoveredOption: (id: string) => void;
  hoveredOption: string;
  changeLayoutOrder: (dragId: string, hoverId: string) => void;
  index: number;
  setIdLayoutToRemove: any;
  setIdLayoutToRename: any;
  reorderUsersLayoutRemotly: () => void;
}

const LayaoutDragOption = ({
  setLayout,
  reorderUsersLayoutRemotly,
  setFieldValue,
  layout,
  setHoveredOption,
  hoveredOption,
  changeLayoutOrder,
  index,
  setIdLayoutToRemove,
  setIdLayoutToRename,
}: LayaoutDragOptionProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.LAYOUT,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY + 18 < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY - 18 > hoverMiddleY) {
        return;
      }
      changeLayoutOrder(layout.id, item.id);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.LAYOUT,
    item: () => {
      return { id: layout.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        reorderUsersLayoutRemotly();
      }
    },
  });
  preview(drop(ref));
  return (
    <LayaoutDragOption.ListItemWrapper isDragging={isDragging}>
      <LayaoutDragOption.ListItem
        ref={ref}
        data-handler-id={handlerId}
        isDragging={isDragging}
        isHovered={hoveredOption === layout.id}
        onClick={() => {
          if (!isDragging) setLayout(setFieldValue, layout);
        }}
        onMouseMove={() => setHoveredOption(layout.id)}
        onMouseLeave={() => setHoveredOption('')}
      >
        <LayaoutDragOption.DragAreaWrapper ref={drag}>
          <div>
            {hoveredOption === layout.id ? (
              <LayaoutDragOption.DragWrapper>
                <LayaoutDragOption.DragIconWrapper>
                  <LayaoutDragOption.ChartOptionsMenuIcon />
                </LayaoutDragOption.DragIconWrapper>
              </LayaoutDragOption.DragWrapper>
            ) : null}
          </div>
          <LayaoutDragOption.TitleWrapper>
            <span>{layout.name}</span>
          </LayaoutDragOption.TitleWrapper>
        </LayaoutDragOption.DragAreaWrapper>
        {hoveredOption === layout.id ? (
          <LayaoutOptionControlButtons
            onRenameLayout={setIdLayoutToRename}
            layout={layout}
            onDeleteLayaout={setIdLayoutToRemove}
          />
        ) : (
          <div />
        )}
      </LayaoutDragOption.ListItem>
    </LayaoutDragOption.ListItemWrapper>
  );
};

export default LayaoutDragOption;

LayaoutDragOption.ListItemWrapper = styled.div`
  border-top: ${props =>
    props.isDragging ? ' 1px solid black' : '1px solid transparent'};
  border-bottom: ${props =>
    props.isDragging ? ' 1px solid black' : '1px solid transparent'};
`;

LayaoutDragOption.ListItem = styled.li`
  display: grid;
  grid-template-columns: auto 50px;
  align-items: center;
  list-style: none;
  opacity: ${props => (props.isDragging ? '0' : '1')};

  :hover {
    background: ${props => (props.isHovered ? '#EFEFEF' : '#ffffff')};
    cursor: pointer;
  }
`;

LayaoutDragOption.ChartOptionsMenuIcon = styled(ChartOptionsMenuIcon)`
  font-size: 8px;
`;

LayaoutDragOption.TitleWrapper = styled.div`
  white-space: nowrap;
  padding: 2px 0 3px 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

LayaoutDragOption.DragWrapper = styled.div`
  align-self: center;
  height: 100%;
  padding: 2px 5px 3px 3px;
  cursor: ns-resize;
  display: flex;
  justify-content: center;
  align-items: center;
`;

LayaoutDragOption.DragIconWrapper = styled.div`
  width: 8px;
  display: flex;
`;

LayaoutDragOption.DragAreaWrapper = styled.div`
  display: grid;
  grid-template-columns: 22.5px auto;
`;
