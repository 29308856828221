import gql from 'graphql-tag';

import type { Action, GraphqlQueryAction } from 'store/models';

import type { ProductionPoint } from './models/production';

export const namespace = 'PRODUCTION';
export const FETCH_WELL_PRODUCTION = `${namespace}/FETCH_WELL_PRODUCTION`;
export const FETCH_WELL_PRODUCTION_DATE_RANGE = `${namespace}/FETCH_WELL_PRODUCTION_DATE_RANGE`;
export const CHANGE_EXTREME_DATE = `${namespace}/CHANGE_EXTREME_DATE`;
export const CHANGE_EXTREME_DATES = `${namespace}/CHANGE_EXTREME_DATES`;
export const CHANGE_EXTREME_DATES_BY_DAGGING = `${namespace}/CHANGE_EXTREME_DATES_BY_DAGGING`;
export const CHANGE_EXTREME_DATES_AFTER_SCALING = `${namespace}/CHANGE_EXTREME_DATES_AFTER_SCALING`;
export const SET_INITIAL_EXTREME_DATES = `${namespace}/SET_INITIAL_EXTREME_DATES`;
export const INIT_FETCH_PRODUCTION = `${namespace}/INIT_FETCH_PRODUCTION`;
export const UNDO_EXTREME_DATES_CHANGE = `${namespace}/UNDO_EXTREME_DATES_CHANGE`;
export const POPULATE_PRODUCTION = `${namespace}/POPULATE_PRODUCTION`;
export const SET_DATA_AVAILIBILITY = `${namespace}/SET_DATA_AVAILIBILITY`;

export const initFetchProduction = () => ({
  type: INIT_FETCH_PRODUCTION,
});

const FETCH_WELL_PRODUCTION_QUERY = gql`
  query ($payload: ProductionInput!) {
    listWellProduction(data: $payload) {
      responseData
    }
  }
`;

const FETCH_WELL_PRODUCTION_DATE_RANGE_QUERY = gql`
  query ($payload: DateRangeProductionInput!) {
    listWellProductionDateRange(data: $payload) {
      responseData
    }
  }
`;

type fetchWellProductionDateRangeAC = (payload: {
  wellId: string;
  minDate: Date;
  maxDate: Date;
}) => GraphqlQueryAction;

export const fetchWellProductionDateRange: fetchWellProductionDateRangeAC =
  payload => ({
    type: FETCH_WELL_PRODUCTION_DATE_RANGE,
    payload: {
      key: 'listWellProductionDateRange',
      graphql: {
        query: FETCH_WELL_PRODUCTION_DATE_RANGE_QUERY,
        variables: {
          payload,
        },
      },
    },
  });
type fetchWellProductionAC = ({ wellId }) => GraphqlQueryAction;

export const fetchWellProduction: fetchWellProductionAC = payload => ({
  type: FETCH_WELL_PRODUCTION,
  payload: {
    key: 'listWellProduction',
    graphql: {
      query: FETCH_WELL_PRODUCTION_QUERY,
      variables: {
        payload: {
          wellId: payload.wellId,
        },
      },
    },
  },
});

type changeExtremeDateAC = ({
  datePosition: string,
  dateValue: Date,
}) => Action;

export const changeExtremeDate: changeExtremeDateAC = payload => ({
  type: CHANGE_EXTREME_DATE,
  payload,
});

type changeExtremeDatesAC = (payload: { min: Date; max: Date }) => Action;

export const changeExtremeDates: changeExtremeDatesAC = payload => ({
  type: CHANGE_EXTREME_DATES,
  payload,
});
export const changeExtremeDatesByDragging: changeExtremeDatesAC = payload => ({
  type: CHANGE_EXTREME_DATES_BY_DAGGING,
  payload,
});
export const changeExtremeDatesAfterScaling = () => ({
  type: CHANGE_EXTREME_DATES_AFTER_SCALING,
});

type setInitialExtremeDatesAC = (payload: { min: Date; max: Date }) => Action;

export const setInitialExtremeDates: setInitialExtremeDatesAC = payload => ({
  type: SET_INITIAL_EXTREME_DATES,
  payload,
});

export const undoExtremeDatesChange = () => ({
  type: UNDO_EXTREME_DATES_CHANGE,
});

type populateProductionAC = (payload: {
  wellId: string;
  data: ProductionPoint[];
}) => Action;

export const populateProduction: populateProductionAC = payload => ({
  type: POPULATE_PRODUCTION,
  payload,
});

type setDataAvailibilityAC = (payload: {
  wellId: string;
  fromDay: Date;
}) => Action;

export const setDataAvailibility: setDataAvailibilityAC = payload => ({
  type: SET_DATA_AVAILIBILITY,
  payload,
});
