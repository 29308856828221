import { utcDay } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';

import { getMaxDate, getMinDate, getRibbonExtremeDates } from 'helpers';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import { RibbonEvent } from 'modules/ribbon/models';
import useRibbonEvent from 'modules/ribbon/hooks/useRibbonEvent';

interface RibbonInteractiveDividerProps {
  allRibbonEvents: { [id: number]: RibbonEvent };
  finishDrag(): void;
  isYAxisDragging: boolean;
  leftOffset: number;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  selectedEventId: string;
  startDrag(): void;
  today: Date;
  xScale: any;
}

const RibbonInteractiveDivider = ({
  allRibbonEvents,
  finishDrag,
  isYAxisDragging,
  leftOffset,
  onXAxisScaling,
  today,
  selectedEventId,
  startDrag,
  xScale,
}: RibbonInteractiveDividerProps) => {
  const { updateRibbonEventDates } = useRibbonEvent();

  const ribbonEvent: RibbonEvent = allRibbonEvents[selectedEventId];
  const extremeEventDates = getRibbonExtremeDates(today);

  const divEl = React.useRef(null);
  const beginEventStartDrag = (e: React.MouseEvent) => {
    if (!ribbonEvent.id) {
      return;
    }
    document.addEventListener('mousemove', proceedEventStartDrag);
    document.addEventListener('mouseup', finishEventStartDrag);
    startDrag();
  };

  const proceedEventStartDrag = React.useCallback(
    (e: MouseEvent) => {
      const fixedDateOfEvent = ribbonEvent.dayEnd;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = utcDay.round(xScale.invert(pointerPosition));
      const adjustedDate =
        fixedDateOfEvent && fixedDateOfEvent > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeEventDates[0])
          : getMinDate(dateOfCursor, extremeEventDates[1]);
      const newDay: Date = utcDay.round(adjustedDate);

      updateRibbonEventDates({
        ribbonEventId: ribbonEvent.id,
        dates: [newDay, ribbonEvent.dayEnd || today],
      });
    },
    [
      ribbonEvent.dayEnd,
      ribbonEvent.id,
      extremeEventDates,
      updateRibbonEventDates,
      xScale,
      leftOffset,
      today,
    ],
  );

  const finishEventStartDrag = React.useCallback(
    (e: MouseEvent) => {
      document.removeEventListener('mousemove', proceedEventStartDrag);
      document.removeEventListener('mouseup', finishEventStartDrag);
      finishDrag();
      const fixedDateOfEvent = ribbonEvent.dayEnd;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = utcDay.round(xScale.invert(pointerPosition));
      const adjustedDate =
        fixedDateOfEvent && fixedDateOfEvent > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeEventDates[0])
          : getMinDate(dateOfCursor, extremeEventDates[1]);
      const newDay = utcDay.round(adjustedDate);
      updateRibbonEventDates({
        ribbonEventId: ribbonEvent.id,
        dates: [newDay, ribbonEvent.dayEnd || today],
      });
    },
    [
      ribbonEvent.dayEnd,
      ribbonEvent.id,
      extremeEventDates,
      finishDrag,
      updateRibbonEventDates,
      proceedEventStartDrag,
      xScale,
      leftOffset,
      today,
    ],
  );

  const beginEventEndDrag = (e: React.MouseEvent) => {
    if (!ribbonEvent.id) return;
    document.addEventListener('mousemove', proceedEventEndDrag);
    document.addEventListener('mouseup', finishEventEndDrag);
    startDrag();
  };

  const proceedEventEndDrag = React.useCallback(
    (e: MouseEvent) => {
      const fixedDateOfEvent = ribbonEvent.dayStart;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = utcDay.round(
        utcDay.offset(xScale.invert(pointerPosition), -1),
      );
      const adjustedDate =
        fixedDateOfEvent && fixedDateOfEvent > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeEventDates[0])
          : getMinDate(dateOfCursor, extremeEventDates[1]);
      const newDay = utcDay.round(adjustedDate);
      updateRibbonEventDates({
        ribbonEventId: ribbonEvent.id,
        dates: [newDay, fixedDateOfEvent],
      });
    },
    [
      extremeEventDates,
      ribbonEvent,
      updateRibbonEventDates,
      xScale,
      leftOffset,
    ],
  );

  const finishEventEndDrag = React.useCallback(
    (e: MouseEvent) => {
      document.removeEventListener('mousemove', proceedEventEndDrag);
      document.removeEventListener('mouseup', finishEventEndDrag);
      finishDrag();
      const fixedDateOfEvent = ribbonEvent.dayStart;
      const pointerPosition = Math.min(
        Math.max(e.clientX - leftOffset - Y_AXIS_WIDTH - 10, xScale.range()[0]),
        xScale.range()[1],
      );
      const dateOfCursor = utcDay.round(
        utcDay.offset(xScale.invert(pointerPosition), -1),
      );
      const adjustedDate =
        fixedDateOfEvent && fixedDateOfEvent > dateOfCursor
          ? getMaxDate(dateOfCursor, extremeEventDates[0])
          : getMinDate(dateOfCursor, extremeEventDates[1]);
      const newDay = utcDay.round(adjustedDate);

      updateRibbonEventDates({
        ribbonEventId: ribbonEvent.id,
        dates: [ribbonEvent.dayStart, newDay],
      });
    },
    [
      ribbonEvent.dayStart,
      ribbonEvent.id,
      extremeEventDates,
      finishDrag,
      updateRibbonEventDates,
      proceedEventEndDrag,
      xScale,
      leftOffset,
    ],
  );

  React.useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', proceedEventEndDrag);
      document.removeEventListener('mousemove', proceedEventStartDrag);
      document.removeEventListener('mouseup', finishEventEndDrag);
      document.removeEventListener('mouseup', finishEventStartDrag);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RibbonInteractiveDivider.Container
      ref={divEl}
      onWheel={e => onXAxisScaling(e, divEl)}
      className="ribbonInteractiveOverlay panInteraction"
      id="ribbonInteractiveDivider"
      isYAxisDragging={isYAxisDragging}
    >
      <RibbonInteractiveDivider.Line
        position={xScale(ribbonEvent.dayStart)}
        onMouseDown={e => beginEventStartDrag(e)}
        onClick={e => e.stopPropagation()}
        className="ribbon-event-interactive"
      />
      {!ribbonEvent.noEndDate && (
        <RibbonInteractiveDivider.Line
          onMouseDown={e => beginEventEndDrag(e)}
          onClick={e => e.stopPropagation()}
          position={xScale(utcDay.offset(ribbonEvent.dayEnd, 1))}
          className="ribbon-event-interactive"
        />
      )}
    </RibbonInteractiveDivider.Container>
  );
};

RibbonInteractiveDivider.Container = styled.div`
  position: absolute;
  z-index: 74;
  height: 100%;
  width: 100%;
  display: flex;
  cursor: ${props => (props.isYAxisDragging ? 'grabbing' : 'auto')};
`;

RibbonInteractiveDivider.Line = styled.div`
  width: 10px;
  height: 100%;
  position: absolute;
  left: ${props => props.position - 5}px;
  cursor: ew-resize;
`;

export default React.memo<RibbonInteractiveDividerProps>(
  RibbonInteractiveDivider,
);
