import { utcFormat } from 'd3-time-format';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useNonInputKeydown } from 'hooks/useKeydown';
import Button from 'components/Button';
import CloseOnMouseClickOutside from 'components/CloseOnMouseClickOutside';
import { getPermissions } from 'modules/auth/AuthReducer';
import RightPanel from 'modules/dashboard/components/RightPanel';

import {
  getRibbonOptions,
  getEventsOfSelectedRibbons,
  getRibbonById,
} from 'modules/ribbon/RibbonReducer';
import {
  getCurrentWellId,
  getHighlightedRibbonEvent,
  getWasDragging,
} from 'modules/ui/UIReducer';
import {
  highlightRibbonEventDivider,
  highlightRibbonEventDividerOff,
} from 'modules/ui/UIActions';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
import useAdditionMode from 'modules/ui/hooks/useAdditionMode';
import EventNoteTooltip from 'modules/eventNotes/components/EventNoteTooltip';

const RibbonDetailsPanel = () => {
  const dispatch = useDispatch();
  const rightPanel = useRightPanel();
  const ribbonOptions = useSelector(getRibbonOptions);

  const isPossibleEditRibbonEvent =
    useSelector(getPermissions).isAllowedEditRibbonEvents;
  const currentRibbon = useSelector(state =>
    getRibbonById(state, rightPanel.dialogIndex),
  );
  const currentWellId = useSelector(getCurrentWellId);
  const selectedRibbonEvents = useSelector(getEventsOfSelectedRibbons);
  const chartWasDragging = useSelector(getWasDragging);

  const sortedRibbonEvents = React.useMemo(() => {
    const ribbonEvents = selectedRibbonEvents[rightPanel.dialogIndex] || [];
    return R.clone(ribbonEvents)
      .sort((a, b) => a.dayStart.getTime() - b.dayStart.getTime())
      .reverse();
  }, [selectedRibbonEvents, rightPanel.dialogIndex]);

  const currentRibbonEvent = useSelector(getHighlightedRibbonEvent);
  const onRibbonDetailsPanelClose = React.useCallback(() => {
    rightPanel.unsetDialogOfType('RibbonDetails');
  }, [rightPanel.unsetDialogOfType]);

  const { additionMode, additionModeOn } = useAdditionMode();

  useNonInputKeydown(
    ({ keyName }) => {
      if (!rightPanel.isDialogOfType('RibbonDetails')) return;
      if (keyName === 'ESCAPE') return onRibbonDetailsPanelClose();
    },
    [rightPanel.isDialogOfType, onRibbonDetailsPanelClose],
  );

  return (
    <RightPanel
      onDialogClose={onRibbonDetailsPanelClose}
      isShown
      title={`${R.pathOr('', ['name'], currentRibbon)} Ribbon`}
    >
      <CloseOnMouseClickOutside
        exceptForClassName="ribbon-details-interactive"
        closeHandler={() => !chartWasDragging && onRibbonDetailsPanelClose()}
        event="mouseup"
      >
        <RibbonDetailsPanel.Section>
          {sortedRibbonEvents &&
            !R.isEmpty(sortedRibbonEvents) &&
            !R.isEmpty(ribbonOptions) && (
              <>
                <RibbonDetailsPanel.SectionHeader>
                  {`${R.pathOr('', ['name'], currentRibbon)} Ribbon Events`}
                </RibbonDetailsPanel.SectionHeader>
                {sortedRibbonEvents.map(event => (
                  <RibbonDetailsPanel.Item
                    key={`ribbon_event_${event.id}`}
                    onClick={() =>
                      rightPanel.setDialog({
                        type: 'RibbonEvent',
                        data: { id: event.id },
                      })
                    }
                    onMouseEnter={() =>
                      dispatch(highlightRibbonEventDivider(event.id))
                    }
                    onMouseLeave={() =>
                      dispatch(highlightRibbonEventDividerOff())
                    }
                    isHighlighted={
                      currentRibbonEvent &&
                      currentRibbonEvent.show &&
                      currentRibbonEvent.index === event.id
                    }
                  >
                    {ribbonOptions[event.ribbonOptionId] &&
                      ribbonOptions[event.ribbonOptionId].color && (
                        <RibbonDetailsPanel.ColorMark
                          color={ribbonOptions[event.ribbonOptionId].color}
                        />
                      )}
                    <RibbonDetailsPanel.EventLabel>
                      {`${utcFormat('%m/%d/%y')(event.dayStart)}: ${
                        ribbonOptions[event.ribbonOptionId].type
                      }`}
                      <RibbonDetailsPanel.CommentContainer>
                        <EventNoteTooltip
                          eventType="ribbon"
                          eventId={event.id}
                          wellId={currentWellId}
                        />
                      </RibbonDetailsPanel.CommentContainer>
                    </RibbonDetailsPanel.EventLabel>
                  </RibbonDetailsPanel.Item>
                ))}
              </>
            )}
        </RibbonDetailsPanel.Section>

        <div></div>
        <RibbonDetailsPanel.Footer>
          <RibbonDetailsPanel.BtnWrapper>
            {!additionMode.isOn && (
              <Button
                width={125}
                onClick={() => additionModeOn('ribbonEvent')}
                disabled={!isPossibleEditRibbonEvent || currentRibbon?.readOnly}
              >
                Add Ribbon Event
              </Button>
            )}
          </RibbonDetailsPanel.BtnWrapper>
        </RibbonDetailsPanel.Footer>
      </CloseOnMouseClickOutside>
    </RightPanel>
  );
};

export default RibbonDetailsPanel;

RibbonDetailsPanel.Section = styled.div``;
RibbonDetailsPanel.SectionHeader = styled.div`
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 0 20px;
  font-size: 14px;
  font-weight: bold;
`;

RibbonDetailsPanel.Item = styled.div`
  display: flex;
  padding: 10px 0 10px 20px;
  align-items: center;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props: Record<string, any>) =>
    props.isHighlighted ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

RibbonDetailsPanel.ColorMark = styled.div`
  min-width: 20px;
  min-height: 20px;
  background-color: ${(props: Record<string, any>) =>
    props.color ? props.color : 'black'};
  margin-right: 8px;
`;

RibbonDetailsPanel.BtnWrapper = styled.div`
  margin-right: 10px;
`;

RibbonDetailsPanel.EventLabel = styled.div``;

RibbonDetailsPanel.CommentIconWrapper = styled.div`
  margin-left: 3px;
`;

RibbonDetailsPanel.Tooltip = styled.div`
  margin-left: 3px;
  border: 1px;
`;
RibbonDetailsPanel.CommentContainer = styled.span`
  display: inline-block;
`;

RibbonDetailsPanel.Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  margin: 10px 0;
`;

RibbonDetailsPanel.TooltipContent = styled.div`
  white-space: pre-line;
  max-width: 290px;
`;
