/* eslint-disable no-useless-escape */
import { utcDay } from 'd3-time';
import { utcFormat } from 'd3-time-format';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { pushNotification } from 'modules/notification/NotificationActions';

import usePrevious from 'hooks/usePrevious';

import Input from 'components/Input';
import DatePicker from 'components/DatePicker';

const ENTER_KEYCODE = 13;

const FULL_DATE_REGEXP = new RegExp(/\d{1,2}[\/\-\.]\d{1,2}[\/\-\.]\d{1,4}/);
const SHORT_DATE_REGEXP = new RegExp(/\d{1,2}[\/\-\.]\d{1,2}/);
const DASH_OR_SLASH_OR_DOT_REGEXP = new RegExp(/[\/\-\.]/);

const isValidDay = dayNumber => dayNumber <= 31 && dayNumber > 0;
const isValidMonth = monthNumber => monthNumber <= 11 && monthNumber >= 0;
const isValidYear = yearNumber => yearNumber >= 1990 && yearNumber <= 2099;
const isValidFullDate = dateComponents =>
  isValidDay(dateComponents.date) &&
  isValidMonth(dateComponents.month) &&
  isValidYear(dateComponents.year);

const createDate = dateComponents => {
  const date = new Date(
    dateComponents.year,
    dateComponents.month,
    dateComponents.date,
  );

  return utcDay.round(date);
};

const normalizeYear = yearNumber => {
  if (!yearNumber) return new Date().getFullYear();
  if (yearNumber >= 0 && yearNumber <= 99) return 2000 + yearNumber;

  return yearNumber;
};

const processParsing = (string, regexp) => {
  const parseResult = string.match(regexp);
  if (parseResult) {
    const parsedString = parseResult[0];
    const numbers = parsedString
      .split(DASH_OR_SLASH_OR_DOT_REGEXP)
      .map(str => parseInt(str));
    const year = normalizeYear(numbers[2]);
    const dateComponents = { date: numbers[1], month: numbers[0] - 1, year };
    if (!isValidFullDate(dateComponents)) return null;

    return createDate(dateComponents);
  }

  return null;
};

const parseDate = string => {
  if (FULL_DATE_REGEXP.test(string))
    return processParsing(string, FULL_DATE_REGEXP);
  if (SHORT_DATE_REGEXP.test(string))
    return processParsing(string, SHORT_DATE_REGEXP);

  return null;
};

const stringifyDate = utcFormat('%m/%d/%Y');

type DateInputProps = {
  activeColor?: string;
  disabled?: boolean;
  id: string;
  max: Date;
  min: Date;
  name: string;
  onProcess: (date: Date) => void;
  date: Date;
  isActive?: boolean;
  tabIndex?: number;
  transparent?: boolean;
  datePickerChildren?: (close: () => void) => React.ReactNode;
} & { [key: string]: any };

const DateInput = ({
  activeColor,
  date,
  disabled,
  id,
  max,
  min,
  name,
  onProcess,
  isActive,
  tabIndex,
  transparent,
  datePickerChildren,
  ...props
}: DateInputProps) => {
  const dispatch = useDispatch();

  const [fieldValue, setFieldValue] = React.useState(stringifyDate(date));
  const [isValid, setValidationStatus] = React.useState(true);
  const prevDate = usePrevious(date);
  const prevMin = usePrevious(min);
  const prevMax = usePrevious(max);
  const [isFocused, setFocus] = React.useState(false);
  const inputEl = React.useRef<HTMLElement>(null);

  const validateField = React.useCallback(
    (newDate: Date | null) => {
      if (!newDate) {
        if (isValid) {
          dispatch(
            pushNotification({
              message: `The date is invalid. Please, type the date between ${stringifyDate(
                min,
              )} and ${stringifyDate(max)} in mm/dd/yyyy format`,
              level: 'Error',
              duration: 5000,
            }),
          );
        }
        setValidationStatus(false);
        return;
      }
      if (
        newDate &&
        newDate.getTime() === date.getTime() &&
        (newDate <= max || newDate >= min)
      ) {
        setFieldValue(stringifyDate(newDate));
        setValidationStatus(true);
        return;
      }
      if (newDate && (newDate > max || newDate < min)) {
        if (isValid) {
          dispatch(
            pushNotification({
              message: `The date should be between ${stringifyDate(
                min,
              )} and ${stringifyDate(max)}`,
              level: 'Error',
            }),
          );
        }
        setFieldValue(stringifyDate(newDate));
        setValidationStatus(false);
        return;
      }
      if (newDate && newDate <= max && newDate >= min) {
        onProcess(newDate);
        setValidationStatus(true);
        setFieldValue(stringifyDate(newDate));
        return;
      }
    },
    [date, isValid, max, min, onProcess, dispatch],
  );

  const processInput = React.useCallback(
    inputString => {
      const result = parseDate(inputString);
      validateField(result);
    },
    [validateField],
  );

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { keyCode, target } = e;
    if (!(target instanceof window.HTMLInputElement)) {
      return;
    }
    if (keyCode === ENTER_KEYCODE) {
      e.preventDefault();
      processInput(target.value);
      setFocus(false);
      if (inputEl.current) inputEl.current.blur();
    }
  };

  React.useEffect(() => {
    if (!prevDate || date.getTime() !== prevDate.getTime()) {
      validateField(date);
      setFieldValue(stringifyDate(date));
      return;
    }
    if (
      !prevMax ||
      prevMax.getTime() !== max.getTime() ||
      !prevMin ||
      prevMin.getTime() !== min.getTime()
    ) {
      processInput(fieldValue);
    }
  }, [
    setFieldValue,
    date,
    prevDate,
    prevMin,
    prevMax,
    fieldValue,
    max,
    min,
    processInput,
    validateField,
  ]);

  return (
    <DateInput.Wrapper {...props}>
      <Input
        activeColor={activeColor}
        ref={inputEl}
        disabled={disabled}
        id={id}
        name={name}
        value={fieldValue}
        isValid={isValid}
        onChange={e => setFieldValue(e.target.value)}
        onBlur={e => processInput(e.target.value)}
        onKeyDown={e => handleKeyDown(e)}
        onClick={() => {
          if (!isFocused && !disabled) setFocus(true);
        }}
        style={{
          width: transparent ? (name === 'maxDate' ? '81px' : '85px') : '105px',
          paddingLeft: transparent
            ? name === 'maxDate'
              ? '0'
              : '5px'
            : '12px',
        }}
        isActive={isActive || false}
        tabIndex={tabIndex}
        transparent={transparent}
      />
      {isFocused && (
        <DatePicker
          inputEl={inputEl}
          defaultDate={date}
          maxDate={max}
          minDate={min}
          selectedDate={parseDate(fieldValue)}
          inputSizes={
            inputEl && inputEl.current
              ? inputEl.current.getBoundingClientRect()
              : {}
          }
          close={() => setFocus(false)}
          onChange={processInput}
          children={datePickerChildren}
        />
      )}
    </DateInput.Wrapper>
  );
};

DateInput.Wrapper = styled.div`
  position: relative;
`;

export default DateInput;
