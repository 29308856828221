import { area, curveStepAfter } from 'd3-shape';
import { utcDay } from 'd3-time';
import * as React from 'react';
import { useSelector } from 'react-redux';

import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';
import type { ProductionPoint } from 'modules/production/models/production';

import { getEventClipPathId } from '../../utils';
import { getToday } from '../../../appConfig/AppConfigReducer';

interface EventProductionAreaProps {
  varianceEvent: VarianceEvent;
  color: string;
  eventData: ProductionPoint[];
  xScale: any;
  yScale: any;
  productionKey: string;
  title: string;
}

const EventProductionArea = ({
  varianceEvent,
  eventData,
  xScale,
  yScale,
  color,
  productionKey,
  title,
}: EventProductionAreaProps): JSX.Element => {
  const lastEventDayLimit = utcDay
    .offset(utcDay.round(varianceEvent.dayEnd), 1)
    .getTime();
  const firstEventDayLimit = utcDay.round(varianceEvent.dayStart).getTime();
  const filteredData = eventData.filter(
    dataPoint =>
      dataPoint.day.getTime() <= lastEventDayLimit &&
      dataPoint.day.getTime() >= firstEventDayLimit,
  );
  const today = useSelector(getToday);
  const newDate = { ...filteredData[filteredData.length - 1] };
  newDate.day = filteredData.length
    ? utcDay.offset(utcDay.round(varianceEvent.dayEnd), 1)
    : newDate.day;
  let filteredDataWithNewDay = [...filteredData, newDate];
  if (newDate.day && today.getTime() < newDate.day.getTime()) {
    filteredDataWithNewDay = [...filteredData];
  }

  const createArea: (data: any) => any = area()
    .x((d: any) => xScale(d.day || 0))
    .y1((d: any) => yScale(d[productionKey]))
    .y0(yScale(0))
    .curve(curveStepAfter);

  return (
    <path
      fill={color}
      d={createArea(
        filteredData.length ? filteredDataWithNewDay : filteredData,
      )}
      clipPath={`url(#${getEventClipPathId(title, varianceEvent.id)}`}
    />
  );
};

export default EventProductionArea;
