import { XYCoord } from 'dnd-core';
import * as R from 'ramda';
import * as React from 'react';
import { DragSource, DropTarget, DropTargetMonitor } from 'react-dnd';
import styled from 'styled-components';

import {
  ChartOption,
  ChartOptionsGroup,
  DraggableOption,
  ITEM_TYPES,
  ItemTypes,
  ListChartOptions,
  OPTIONS_TYPES,
} from '../models';
import ChartTypeButton from './ChartTypeButton';
import OptionTitle from './OptionTitle';
import ShowTrellisButton from './ShowTrellisButton';

interface GroupInstance {
  getNode(): HTMLDivElement | null;
}

const optionSource = {
  beginDrag(props: GroupItemProps, monitor, component: any) {
    if (!component) {
      return props;
    }
    props.onSetMovedOptionIndex(props.index);
    props.onSetMovedOption({
      optionId: props.option.id,
      groupId: props.perentGroup.groupId,
    });
    props.onSetIsShowOriginOption(true);
    props.setIsAnyOptionDraging(true);

    return { index: props.index, props: props };
  },
  canDrag(props: GroupItemProps) {
    return props.perentGroup.options.length <= 1 ? false : true;
  },
  endDrag(props: GroupItemProps, monitor, component: GroupInstance) {
    props.onSetMovedOptionIndex(null);
    props.onSetIsShowOriginOption(false);
    props.onStopHovering();
    if (!component) {
      return;
    }
    props.onSetMovedOption({
      optionId: '',
      groupId: '',
    });
    const node = component.getNode() as HTMLDivElement;
    node.style.border = 'none';
    props.setIsAnyOptionDraging(false);
  },
};
const optionsTarget = {
  drop(
    props: GroupItemProps,
    monitor: DropTargetMonitor,
    component: GroupInstance,
  ) {
    const node = component.getNode();
    if (!node) {
      return null;
    }
    const item = monitor.getItem<any>();
    const hoverBoundingRect = node.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
    const newOptionIndex =
      hoverClientY < hoverMiddleY ? props.index : 1 + props.index;

    if (item.props.groupId === props.groupId) {
      props.onStopOptionMoving();
      return;
    }
    if (item.props.itemType === ITEM_TYPES.available) {
      props.onAddOptionToGroup({
        groupId: props.groupId,
        optionId: item.props.option.id,
        newOptionIndex,
      });
    } else if (item.props.itemType === ITEM_TYPES.selected) {
      props.onAddOptionToGroup({
        groupId: props.groupId,
        optionId: item.props.option.id,
        newOptionIndex,
        previousGroupId: item.props.perentGroup.groupId,
      });
    } else if (item.props.itemType === ITEM_TYPES.singleOptionGroup) {
      props.onAddOptionToGroup({
        groupId: props.groupId,
        optionId: item.props.currentGroup.options[0],
        newOptionIndex,
        previousGroupId: item.props.currentGroup.groupId,
      });
    }
  },
  canDrop(props: GroupItemProps, monitor: DropTargetMonitor) {
    const item = monitor.getItem<any>();

    const optionGroupId = R.pathOr(
      '',
      ['props', 'perentGroup', 'groupId'],
      item,
    );

    const optionId = R.path(['props', 'option', 'id'], item);
    const optionType = R.path(['optionsList', optionId, 'type'], props);
    const isGroup =
      item.props.itemType !== ITEM_TYPES.group &&
      ((item.props.itemType === ITEM_TYPES.singleOptionGroup &&
        item.props.currentGroup.groupId !== props.groupId) ||
        item.props.itemType !== ITEM_TYPES.singleOptionGroup);

    if (
      isGroup &&
      optionType !== OPTIONS_TYPES.cav &&
      (props.perentGroup.options.length < 5 ||
        props.perentGroup.groupId === optionGroupId)
    ) {
      return true;
    }
    return false;
  },
  hover(
    props: GroupItemProps,
    monitor: DropTargetMonitor,
    component: GroupInstance,
  ) {
    const isOver = monitor.isOver();
    const item = monitor.getItem<any>();
    const groupId = item.props.groupId;
    const isGroup =
      item.props.itemType !== ITEM_TYPES.group &&
      ((item.props.itemType === ITEM_TYPES.singleOptionGroup &&
        item.props.currentGroup.groupId !== props.groupId) ||
        item.props.itemType !== ITEM_TYPES.singleOptionGroup);
    const node = component.getNode();
    if (!node) {
      return null;
    }
    const hoverIndex = props.index;
    const hoverBoundingRect = node.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
    const tempOptionIndex =
      hoverClientY < hoverMiddleY ? props.index : 1 + props.index;

    if (isGroup) {
      const optionId =
        item.props.itemType === ITEM_TYPES.singleOptionGroup
          ? R.path(['props', 'options', 0], item)
          : R.path(['props', 'option', 'id'], item);
      const optionType = R.path(['optionsList', optionId, 'type'], props);

      if (
        isOver &&
        props.perentGroup.options.length <= 5 &&
        optionType !== OPTIONS_TYPES.cav &&
        groupId !== props.groupId
      ) {
        props.onSetHoveredOptionIndex(tempOptionIndex);
      }
    }

    const dragIndex = item.index;
    if (
      item.itemType === 'GROUP' ||
      groupId !== props.groupId ||
      R.isNil(dragIndex) ||
      !component
    ) {
      return;
    }

    if (props.option.id === item.id || dragIndex === hoverIndex) {
      return;
    }
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.onMoveOption(hoverIndex, dragIndex);
    monitor.getItem<any>().index = hoverIndex;
  },
};

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

interface GroupItemProps {
  color: string;
  connectDragSource: (dragSource: any) => any;
  connectDropTarget: (target: any) => any;
  groupId: string;
  hoveredOptionIndex: number | null;
  isCorrentGroupHover: boolean;
  index: number;
  isDragging: boolean;
  itemType: string;
  onAddOptionToGroup: (data: {
    groupId: string;
    optionId: string;
    newOptionIndex: number;
    previousGroupId?: string;
  }) => void;
  onChartTypeMenuOpen: (data: {
    optionId: string;
    elemRect: ClientRect;
    event: MouseEvent;
  }) => void;
  onMoveOption: (
    hoveredOptionIndex: number,
    optionIndex?: number,
    groupId?: string,
  ) => void;
  onSetHoveredOptionIndex: (value: number) => void;
  onStopHovering: () => void;
  onSetMovedOption: (value: DraggableOption) => void;
  onStopOptionMoving: () => void;
  onSwitchOptionVisibility;
  option: ChartOption;
  optionsList: ListChartOptions;
  perentGroup: ChartOptionsGroup;
  onSetMovedOptionIndex: (index: number | null) => void;
  tempGroupIndex: number | null;
  groupIndex: number;
  hoveredGroupIndex: number | null;
  isShowOriginOption: boolean;
  onSetIsShowOriginOption: (value: boolean) => void;
  isGroupDragged: boolean;
  setIsAnyOptionDraging: (data: boolean) => void;
  setTooltipPosition: (data: { x: number; y: number }) => void;
  setTooltipText: (data: string) => void;
  isNotExist: boolean;
}

const GroupItem = (
  {
    color,
    connectDragSource,
    connectDropTarget,
    hoveredOptionIndex,
    index,
    isGroupDragged,
    isShowOriginOption,
    isCorrentGroupHover,
    isDragging,
    onChartTypeMenuOpen,
    onSwitchOptionVisibility,
    option,
    tempGroupIndex,
    setTooltipPosition,
    setTooltipText,
    isNotExist,
  }: GroupItemProps,
  ref,
) => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  React.useImperativeHandle<any, GroupInstance>(ref, () => ({
    getNode: () => elementRef.current,
  }));

  const onChartTypeClick = React.useCallback(
    (event: MouseEvent) => {
      if (elementRef && elementRef.current) {
        const elemRect = elementRef.current.getBoundingClientRect();
        onChartTypeMenuOpen({ optionId: option.id, elemRect, event });
      }
    },
    [onChartTypeMenuOpen, option],
  );

  return connectDragSource(
    connectDropTarget(
      <div>
        {hoveredOptionIndex === 0 &&
          isCorrentGroupHover &&
          index === 0 &&
          R.isNil(tempGroupIndex) &&
          !isDragging &&
          !isShowOriginOption &&
          !isGroupDragged && <GroupItem.EmptySpace isFirst />}
        <GroupItem.Container
          ref={elementRef}
          isDragging={isDragging}
          isHidden={!isShowOriginOption && isDragging}
        >
          <OptionTitle
            title={option.title}
            setTooltipPosition={setTooltipPosition}
            setTooltipText={setTooltipText}
            isNotExist={isNotExist}
          />
          <GroupItem.ButtonWrapper>
            <ChartTypeButton
              color={color}
              onChangeChartType={onChartTypeClick}
              type={option.chartType as string}
            />
            <ShowTrellisButton
              onShowClick={() => onSwitchOptionVisibility(option.id)}
              show={option.isShow}
            />
          </GroupItem.ButtonWrapper>
        </GroupItem.Container>
        {hoveredOptionIndex === 1 + index &&
          isCorrentGroupHover &&
          R.isNil(tempGroupIndex) &&
          !isDragging &&
          !isShowOriginOption &&
          !isGroupDragged && <GroupItem.EmptySpace />}
      </div>,
    ),
  );
};

GroupItem.Container = styled.div`
  display: ${props => (props.isHidden ? 'none' : 'grid')};
  opacity: ${props => (props.isDragging ? 0 : 1)};
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  background-color: #efefef;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  border: none;
`;

GroupItem.ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
`;

GroupItem.EmptySpace = styled.div`
  border-top: ${props =>
    props.isFirst ? 'none' : props.theme.borders.thingray};
  border-bottom: ${props =>
    props.isFirst ? props.theme.borders.thingray : 'none'};
  height: 20px;
`;

export default DragSource(
  ItemTypes.SERIES,
  optionSource,
  collect,
)(
  DropTarget(
    ItemTypes.SERIES,
    optionsTarget,
    dropCollect,
  )(React.forwardRef<HTMLDivElement, GroupItemProps>(GroupItem)),
);
