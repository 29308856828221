import { utcMonth, utcDay } from 'd3-time';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  BarsMenuIcon,
  NotesIcon,
  OpenLegendPanelIcon,
  ZoomIcon,
  QuestionIcon,
  InfoIcon,
} from 'components/Icons';
import { getMinDate } from 'helpers';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import { getPermissions } from 'modules/auth/AuthReducer';
import { VARIANCE_TRELLIS } from 'modules/chart/models/chart';
import { switchCavOptionInTopContols } from 'modules/chartOptions/ChartOptionsActions';
import {
  getCoreSeriesOptionsToDisplay,
  getDataSeriesGroupsToDisplay,
  getCoreSeriesOptionList,
} from 'modules/chartOptions/ChartOptionsReducer';

import {
  getDrilldownTableParams,
  getIsMarkingRows,
} from 'modules/drilldownTable/DrilldownTableReducer';
import {
  getFirstGroupProdDay,
  getLastGroupProdDay,
} from 'modules/groupChart/GroupChartReducer';
import { getDefaultProductionRange } from 'modules/production/utils';
import {
  changeExtremeDate,
  changeExtremeDates,
  undoExtremeDatesChange,
} from 'modules/production/ProductionActions';
import {
  getExtremeDates,
  getExtremeDatesHistoryLength,
  getProductionStartDay,
} from 'modules/production/ProductionReducer';
import {
  OIL,
  GAS,
  WATER,
  BOE,
  GOR,
  WATERCUT,
  GLR,
  TOTAL_LIQUID,
} from 'modules/phase/models/phase';

import {
  enableZoomInMode,
  disableNotesMode,
  disableRegionOfInterestMode,
  disableZoomInMode,
} from 'modules/ui/UIActions';
import {
  getAllocIssueAlertWindow,
  getAllocIssuesVisibility,
  getCapacityDeleteAlertWindow,
  getCapacityEditsAlertWindow,
  getCurrentGroup,
  getGroupMode,
  getNotesMode,
  getProductionLoadingStatus,
  getRegionOfInterestMode,
  getZoomInMode,
  getVarianceAlertWindow,
} from 'modules/ui/UIReducer';
import useAdditionMode from 'modules/ui/hooks/useAdditionMode';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
import useMetrics from 'modules/metrics/hooks/useMetrics';
import SeriesLayoutsDropdownButton from 'modules/seriesLayouts/containers/SeriesLayoutsDropdownButton';

import Button from 'components/Button';

import CloseOnMouseClickOutside from 'components/CloseOnMouseClickOutside';
import useMouseDownOutside from 'hooks/useMouseDownOutside';
import useShortcut, { Code } from 'hooks/useShortcut';
import { usePreventContext } from 'context/PreventContext';

import AddEventMenu from '../components/AddEventMenu';
import ZoomMenu from '../components/ZoomMenu';
import { refetchSensorSeries } from 'modules/series/SeriesActions';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useChartUIData from 'modules/chart/hooks/useChartUIData';

const offsetMonths = (monthsQuant: number, today: Date) => {
  const firstDayOfCurrentMonth = utcMonth.floor(today);

  return utcMonth.offset(firstDayOfCurrentMonth, monthsQuant);
};

const TopControls = () => {
  const dispatch = useDispatch();
  const zoomInMode = useSelector(getZoomInMode);
  const regionOfInterestMode = useSelector(getRegionOfInterestMode);
  const allocIssuesVisibility = useSelector(getAllocIssuesVisibility);
  const extremeDates = useSelector(getExtremeDates);
  const notesMode = useSelector(getNotesMode);
  const groupMode = useSelector(getGroupMode);
  const isMarkingRows = useSelector(getIsMarkingRows);
  const isProductionLoading = useSelector(getProductionLoadingStatus);
  const zoomHistoryLength = useSelector(getExtremeDatesHistoryLength);
  const permissions = useSelector(getPermissions);
  const {
    isAllowedEditCapChanges,
    isAllowedEditVarEvents,
    isAllowedEditAllocIssues,
  } = permissions;
  const config = useSelector(getAppConfig);
  const coreSeriesToDisplay = useSelector(getCoreSeriesOptionsToDisplay);
  const dataSeriesToDisplay = useSelector(getDataSeriesGroupsToDisplay);
  const trellisesMap = useSelector(getCoreSeriesOptionList);
  const allocIssueAlert = useSelector(getAllocIssueAlertWindow);
  const capacityDeleteAlert = useSelector(getCapacityDeleteAlertWindow);
  const capacityEditsAlert = useSelector(getCapacityEditsAlertWindow);
  const currentGroup = useSelector(getCurrentGroup);
  const varianceAlert = useSelector(getVarianceAlertWindow);
  const drilldownTableParams = useSelector(getDrilldownTableParams);
  const productionStartDate = useSelector(state =>
    state.ui.groupMode.isOn
      ? getFirstGroupProdDay(state, currentGroup)
      : isMarkingRows
      ? getFirstGroupProdDay(state, { subject: '_marked', item: '_marked' })
      : getProductionStartDay(state),
  );
  const productionLastDate = useSelector(state =>
    state.ui.groupMode.isOn
      ? getLastGroupProdDay(state, currentGroup)
      : isMarkingRows
      ? getFirstGroupProdDay(state, { subject: '_marked', item: '_marked' })
      : config.today,
  );
  const anyAlertOpen =
    allocIssueAlert.show ||
    capacityDeleteAlert.show ||
    capacityEditsAlert.show ||
    varianceAlert.show;

  const rightPanel = useRightPanel();
  const { additionMode, additionModeOn, additionModeOff } = useAdditionMode();
  const { trackEvent } = useMetrics();

  const prevent = usePreventContext();

  const shouldOffsetButtons =
    !rightPanel.isExpanded &&
    !rightPanel.isDialogOfType('Legend') &&
    rightPanel.dialog;
  const buttonsOffset = shouldOffsetButtons ? rightPanel.width - 42 : 0;

  const { chartWidth } = useChartUIData();

  const fetchSensorSeries = useDebouncedCallback(
    () => {
      dispatch(refetchSensorSeries({ chartWidth }));
    },
    [dispatch, chartWidth],
    500,
  );

  const changeOneExtremeDate = React.useCallback(
    dateInfo => {
      dispatch(changeExtremeDate(dateInfo));
      fetchSensorSeries();
    },
    [dispatch, fetchSensorSeries],
  );
  const changeTwoExtremeDates = React.useCallback(
    dates => {
      return dispatch(changeExtremeDates(dates));
    },
    [dispatch],
  );

  const zoomInModeOn = React.useCallback(
    () => dispatch(enableZoomInMode()),
    [dispatch],
  );
  const zoomInModeOff = React.useCallback(
    () => dispatch(disableZoomInMode()),
    [dispatch],
  );
  const regionOfInterestModeOff = React.useCallback(
    () => dispatch(disableRegionOfInterestMode()),
    [dispatch],
  );

  const notesModeOff = React.useCallback(
    () => dispatch(disableNotesMode()),
    [dispatch],
  );

  const onNotesDialogOpen = React.useCallback(() => {
    rightPanel.setDialog({ type: 'WellNotes' });
  }, [rightPanel.setDialog]);

  const onNotesDialogClick = React.useCallback(() => {
    prevent.dispatchEvent(onNotesDialogOpen);
  }, [
    onNotesDialogOpen,
    prevent.dispatchEvent,
    rightPanel.isDialogOfType,
    rightPanel.unsetDialog,
  ]);

  const onWellInfoDialogClick = React.useCallback(() => {
    if (!rightPanel.isDialogOfType('WellInfo')) {
      rightPanel.setDialog({ type: 'WellInfo' });
    } else {
      prevent.dispatchEvent(() => rightPanel.unsetDialog());
    }
  }, [
    onNotesDialogOpen,
    prevent.dispatchEvent,
    rightPanel.isDialogOfType,
    rightPanel.unsetDialog,
  ]);

  const onAddingTerminating = React.useCallback(
    () => rightPanel.unsetDialog(),
    [dispatch],
  );

  const [isZoomMenuOpened, setZoomMenu] = React.useState(false);
  const [zoomMenuTimer, setZoomMenuTimer] = React.useState<any>(null);
  const [isTrellisMenuOpened, setTrellisMenu] = React.useState(false);

  const onTrellisCheck = React.useCallback(
    ({ name, checked }) => {
      dispatch(switchCavOptionInTopContols({ optionId: name, checked }));
    },
    [dispatch],
  );

  const onOpenShortcutPanel = React.useCallback(() => {
    if (rightPanel.isDialogOfType('WellNotes')) {
      prevent.dispatchEvent(() => rightPanel.setDialog({ type: 'Shortcuts' }));
    } else {
      rightPanel.setDialog({ type: 'Shortcuts' });
    }
  }, [rightPanel.setDialog, rightPanel.isDialogOfType, prevent.dispatchEvent]);

  const openZoomMenu = React.useCallback(
    () => setZoomMenu(true),
    [setZoomMenu],
  );
  const closeZoomMenu = React.useCallback(() => {
    const zoomStartDateInput = document.getElementById(
      'production-range-start',
    );
    const zoomEndDateInput = document.getElementById('production-range-end');
    const documentActiveElement = document.activeElement;
    if (
      documentActiveElement === zoomEndDateInput ||
      documentActiveElement === zoomStartDateInput
    )
      return;
    setZoomMenu(false);
  }, [setZoomMenu]);
  const closeZoomMenuWithTimeout = () => {
    setZoomMenuTimer(setTimeout(closeZoomMenu, 500));
  };
  const onZoomMenuHover = () => {
    if (zoomMenuTimer) clearTimeout(zoomMenuTimer);
  };

  const openTrellisMenu = React.useCallback(() => {
    if (rightPanel.isDialogOfType('ChartOptions')) return;

    prevent.dispatchEvent(() => rightPanel.setDialog({ type: 'ChartOptions' }));
  }, [
    prevent.dispatchEvent,
    isTrellisMenuOpened,
    setTrellisMenu,
    rightPanel.isDialogOfType,
    rightPanel.setDialog,
    rightPanel.unsetDialog,
  ]);

  const closeTrellisMenu = () => setTrellisMenu(false);

  const zoomMenuEl = React.useRef(null);
  const trellisMenuEl = React.useRef(null);
  // useMouseDownOutside([zoomMenuEl], closeZoomMenu);
  useMouseDownOutside([trellisMenuEl], closeTrellisMenu);

  const setZoomPreset = React.useCallback(
    (months: string) => {
      const monthsNumber = parseInt(months);
      changeTwoExtremeDates({
        min: offsetMonths(-monthsNumber, config.today),
        max: getDefaultProductionRange(config.today).max,
      });
      fetchSensorSeries();
    },
    [changeTwoExtremeDates, config.today, fetchSensorSeries],
  );
  const zoomOut = React.useCallback(() => {
    const currentTimeline = Math.max(
      utcDay.count(extremeDates.min, extremeDates.max),
      32,
    );
    const halfTimeLine = currentTimeline / 2;
    const newDayStart = utcMonth.round(
      utcDay.offset(extremeDates.min, -halfTimeLine),
    );
    const newDayEnd = utcMonth.round(
      utcDay.offset(extremeDates.max, halfTimeLine),
    );
    changeTwoExtremeDates({
      min: newDayStart,
      max: getMinDate(newDayEnd, getDefaultProductionRange(config.today).max),
    });
    fetchSensorSeries();
  }, [changeTwoExtremeDates, extremeDates, config.today]);

  const zoomToAll = React.useCallback(() => {
    const duration = utcDay.count(
      new Date(productionStartDate),
      productionLastDate,
    );
    const maxDate = getDefaultProductionRange(config.today).max;

    const fivePercent = duration / 20;
    if (
      drilldownTableParams.compareOption === 'external' &&
      utcDay.count(drilldownTableParams.maxDate, maxDate) < 0
    ) {
      changeTwoExtremeDates({
        min: utcDay.offset(new Date(productionStartDate), -fivePercent),
        max: utcMonth.offset(utcMonth.floor(drilldownTableParams.maxDate), 2),
      });
      return;
    }
    changeTwoExtremeDates({
      min: utcDay.offset(new Date(productionStartDate), -fivePercent),
      max: maxDate,
    });
    fetchSensorSeries();
  }, [
    changeTwoExtremeDates,
    config.today,
    productionStartDate,
    productionLastDate,
    drilldownTableParams,
    fetchSensorSeries,
  ]);

  const shouldIgnoreShortcut = React.useCallback((e: KeyboardEvent) => {
    const { target } = e;
    if (e.metaKey || e.shiftKey || e.ctrlKey || e.altKey || anyAlertOpen)
      return true;
    if (target instanceof HTMLElement) {
      const { tagName } = target;
      if (tagName === 'INPUT' || tagName === 'TEXTAREA' || tagName === 'SELECT')
        return true;
    }

    return false;
  }, []);

  const digit0ShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      trackEvent(ids => ({ id: ids.UI_SHORTCUT_TOGGLE_ALL_TRELLISES }));
      onTrellisCheck({
        name: 'all',
        checked: !Object.keys(trellisesMap).every(
          k => trellisesMap[k].isAvailable,
        ),
      });
    },
    [trackEvent, onTrellisCheck, trellisesMap],
  );

  const digit1ShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      if (isZoomMenuOpened) {
        trackEvent(ids => ({ id: ids.UI_SHORTCUT_ZOOM_3_MONTHS }));
        setZoomPreset('3');
      } else {
        trackEvent(ids => ({ id: ids.UI_SHORTCUT_TOGGLE_BOE_TRELLIS }));
        onTrellisCheck({
          name: BOE,
          checked: !trellisesMap[BOE].isAvailable,
        });
      }
    },
    [trackEvent, isZoomMenuOpened, onTrellisCheck, setZoomPreset, trellisesMap],
  );

  const digit2ShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      if (isZoomMenuOpened) {
        trackEvent(ids => ({ id: ids.UI_SHORTCUT_ZOOM_6_MONTHS }));
        setZoomPreset('6');
      } else {
        trackEvent(ids => ({ id: ids.UI_SHORTCUT_TOGGLE_OIL_TRELLIS }));
        onTrellisCheck({
          name: OIL,
          checked: !trellisesMap[OIL].isAvailable,
        });
      }
    },
    [trackEvent, isZoomMenuOpened, onTrellisCheck, setZoomPreset, trellisesMap],
  );

  const digit3ShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      if (isZoomMenuOpened) {
        trackEvent(ids => ({ id: ids.UI_SHORTCUT_ZOOM_12_MONTHS }));
        setZoomPreset('12');
      } else {
        trackEvent(ids => ({ id: ids.UI_SHORTCUT_TOGGLE_GAS_TRELLIS }));
        onTrellisCheck({
          name: GAS,
          checked: !trellisesMap[GAS].isAvailable,
        });
      }
    },
    [trackEvent, isZoomMenuOpened, onTrellisCheck, setZoomPreset, trellisesMap],
  );

  const digit4ShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      if (isZoomMenuOpened) {
        trackEvent(ids => ({ id: ids.UI_SHORTCUT_ZOOM_24_MONTHS }));
        setZoomPreset('24');
      } else {
        trackEvent(ids => ({ id: ids.UI_SHORTCUT_TOGGLE_GOR_TRELLIS }));
        onTrellisCheck({
          name: GOR,
          checked: !trellisesMap[GOR].isAvailable,
        });
      }
    },
    [trackEvent, isZoomMenuOpened, onTrellisCheck, setZoomPreset, trellisesMap],
  );

  const digit5ShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event) || isZoomMenuOpened) return;
      cancel();

      trackEvent(ids => ({ id: ids.UI_SHORTCUT_TOGGLE_WATER_TRELLIS }));
      onTrellisCheck({
        name: WATER,
        checked: !trellisesMap[WATER].isAvailable,
      });
    },
    [trackEvent, isZoomMenuOpened, onTrellisCheck, trellisesMap],
  );

  const digit6ShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event) || isZoomMenuOpened) return;
      cancel();

      trackEvent(ids => ({ id: ids.UI_SHORTCUT_TOGGLE_WATERCUT_TRELLIS }));
      onTrellisCheck({
        name: WATERCUT,
        checked: !trellisesMap[WATERCUT].isAvailable,
      });
    },
    [trackEvent, isZoomMenuOpened, onTrellisCheck, trellisesMap],
  );

  const digit7ShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event) || isZoomMenuOpened) return;
      cancel();

      trackEvent(ids => ({ id: ids.UI_SHORTCUT_TOGGLE_GLR_TRELLIS }));
      onTrellisCheck({
        name: GLR,
        checked: !trellisesMap[GLR].isAvailable,
      });
    },
    [trackEvent, isZoomMenuOpened, onTrellisCheck, trellisesMap],
  );

  const digit8ShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event) || isZoomMenuOpened) return;
      cancel();

      onTrellisCheck({
        name: TOTAL_LIQUID,
        checked: !trellisesMap[TOTAL_LIQUID].isAvailable,
      });
    },
    [isZoomMenuOpened, onTrellisCheck, trellisesMap],
  );

  const digit9ShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event) || isZoomMenuOpened) return;
      cancel();

      trackEvent(ids => ({ id: ids.UI_SHORTCUT_TOGGLE_VARIANCE_TRELLIS }));
      onTrellisCheck({
        name: VARIANCE_TRELLIS,
        checked: !trellisesMap[VARIANCE_TRELLIS].isAvailable,
      });
    },
    [trackEvent, isZoomMenuOpened, onTrellisCheck, trellisesMap],
  );

  const keyAShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;

      if (
        !!allocIssuesVisibility &&
        !additionMode.isOn &&
        !R.isEmpty(trellisesMap) &&
        isAllowedEditAllocIssues &&
        !isZoomMenuOpened &&
        !isTrellisMenuOpened &&
        !groupMode.isOn &&
        !isMarkingRows &&
        config.allocationIssues
      ) {
        cancel();
        additionModeOn('allocIssue');
        trackEvent(ids => ({ id: ids.UI_SHORTCUT_CREATE_ALLOCATION_ISSUE }));
      }
    },
    [
      trackEvent,
      additionModeOn,
      allocIssuesVisibility,
      additionMode,
      trellisesMap,
      isAllowedEditAllocIssues,
      isZoomMenuOpened,
      isTrellisMenuOpened,
      groupMode,
      config,
      isMarkingRows,
    ],
  );

  const keyCShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;

      if (
        !additionMode.isOn &&
        !R.isEmpty(trellisesMap) &&
        isAllowedEditCapChanges &&
        !isZoomMenuOpened &&
        !isTrellisMenuOpened &&
        !groupMode.isOn &&
        !isMarkingRows
      ) {
        cancel();
        additionModeOn('capacity');
        trackEvent(ids => ({
          id: ids.UI_SHORTCUT_CREATE_CAPACITY_CHANGE_EVENT,
        }));
      }
    },
    [
      trackEvent,
      additionModeOn,
      additionMode,
      trellisesMap,
      isZoomMenuOpened,
      isAllowedEditCapChanges,
      isTrellisMenuOpened,
      groupMode,
      isMarkingRows,
    ],
  );

  const keyIShortcutHandler = React.useCallback(
    (cancel, event) => {
      const isTrellis =
        coreSeriesToDisplay.length + dataSeriesToDisplay.length > 0;
      if (shouldIgnoreShortcut(event) || !isTrellis) return;
      cancel();

      zoomInModeOn();
      trackEvent(ids => ({ id: ids.UI_SHORTCUT_ZOOM_IN }));
    },
    [trackEvent, coreSeriesToDisplay, dataSeriesToDisplay, zoomInModeOn],
  );

  const keyNShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event) || groupMode.isOn || isMarkingRows)
        return;
      cancel();

      onNotesDialogOpen();
      trackEvent(ids => ({ id: ids.UI_SHORTCUT_VIEW_NOTES }));
    },
    [groupMode, isMarkingRows, trackEvent, onNotesDialogOpen],
  );

  const keyOShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      zoomOut();
      trackEvent(ids => ({ id: ids.UI_SHORTCUT_ZOOM_OUT }));
    },
    [trackEvent, zoomOut],
  );

  const keyPShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      dispatch(undoExtremeDatesChange());
      trackEvent(ids => ({ id: ids.UI_SHORTCUT_RESTORE_ZOOM }));
    },
    [trackEvent, dispatch, undoExtremeDatesChange],
  );

  const keySShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      zoomToAll();
      trackEvent(ids => ({ id: ids.UI_SHORTCUT_ZOOM_ALL }));
    },
    [zoomToAll, trackEvent],
  );

  const keyTShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      closeZoomMenu();
      openTrellisMenu();
      trackEvent(ids => ({ id: ids.UI_SHORTCUT_OPEN_TRELLIS_MENU }));
    },
    [trackEvent, closeZoomMenu, openTrellisMenu],
  );

  const keyVShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;

      if (
        !additionMode.isOn &&
        !R.isEmpty(trellisesMap) &&
        isAllowedEditVarEvents &&
        !isZoomMenuOpened &&
        !isTrellisMenuOpened &&
        !groupMode.isOn &&
        !isMarkingRows
      ) {
        cancel();
        additionModeOn('variance');
        trackEvent(ids => ({ id: ids.UI_SHORTCUT_CREATE_VARIANCE_EVENT }));
      }
    },
    [
      trackEvent,
      additionModeOn,
      additionMode,
      trellisesMap,
      isAllowedEditVarEvents,
      isZoomMenuOpened,
      isTrellisMenuOpened,
      groupMode,
      isMarkingRows,
    ],
  );

  const keyZShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      closeTrellisMenu();
      openZoomMenu();
      trackEvent(ids => ({ id: ids.UI_SHORTCUT_OPEN_ZOOM_MENU }));
    },
    [closeTrellisMenu, trackEvent, openZoomMenu],
  );

  const escapeShortcutHandler = React.useCallback(
    (_, event) => {
      if (shouldIgnoreShortcut(event)) return;

      if (additionMode.isOn) {
        onAddingTerminating();
        additionModeOff();
      }
      if (zoomInMode.isOn) {
        zoomInModeOff();
      }
      if (regionOfInterestMode.isOn) {
        regionOfInterestModeOff();
      }
      if (notesMode.isOn) {
        notesModeOff();
      }
      if (isZoomMenuOpened) {
        closeZoomMenu();
      }
      if (isTrellisMenuOpened) {
        closeTrellisMenu();
      }
    },
    [
      onAddingTerminating,
      additionModeOff,
      zoomInModeOff,
      regionOfInterestModeOff,
      notesModeOff,
      closeZoomMenu,
      closeTrellisMenu,
      additionMode,
      zoomInMode,
      regionOfInterestMode,
      notesMode,
      isZoomMenuOpened,
      isTrellisMenuOpened,
    ],
  );

  const slashShortcutHandler = React.useCallback(
    (cancel, event) => {
      if (shouldIgnoreShortcut(event)) return;
      cancel();

      rightPanel.toggleDialog({ type: 'Shortcuts' });
      trackEvent(ids => ({ id: ids.UI_SHORTCUT_TOGGLE_SHORTCUT_PANEL }));
    },
    [trackEvent, rightPanel.toggleDialog],
  );

  useShortcut(digit1ShortcutHandler, Code.Digit1, [digit1ShortcutHandler]);
  useShortcut(digit2ShortcutHandler, Code.Digit2, [digit2ShortcutHandler]);
  useShortcut(digit3ShortcutHandler, Code.Digit3, [digit3ShortcutHandler]);
  useShortcut(digit4ShortcutHandler, Code.Digit4, [digit4ShortcutHandler]);
  useShortcut(digit5ShortcutHandler, Code.Digit5, [digit5ShortcutHandler]);
  useShortcut(digit6ShortcutHandler, Code.Digit6, [digit6ShortcutHandler]);
  useShortcut(digit7ShortcutHandler, Code.Digit7, [digit7ShortcutHandler]);
  useShortcut(digit8ShortcutHandler, Code.Digit8, [digit8ShortcutHandler]);
  useShortcut(digit9ShortcutHandler, Code.Digit9, [digit9ShortcutHandler]);
  useShortcut(digit0ShortcutHandler, Code.Digit0, [digit0ShortcutHandler]);
  useShortcut(keyAShortcutHandler, Code.KeyA, [keyAShortcutHandler]);
  useShortcut(keyCShortcutHandler, Code.KeyC, [keyCShortcutHandler]);
  useShortcut(keyIShortcutHandler, Code.KeyI, [keyIShortcutHandler]);
  useShortcut(keyNShortcutHandler, Code.KeyN, [keyNShortcutHandler]);
  useShortcut(keyOShortcutHandler, Code.KeyO, [keyOShortcutHandler]);
  useShortcut(keyPShortcutHandler, Code.KeyP, [keyPShortcutHandler]);
  useShortcut(keySShortcutHandler, Code.KeyS, [keySShortcutHandler]);
  useShortcut(keyTShortcutHandler, Code.KeyT, [keyTShortcutHandler]);
  useShortcut(keyVShortcutHandler, Code.KeyV, [keyVShortcutHandler]);
  useShortcut(keyZShortcutHandler, Code.KeyZ, [keyZShortcutHandler]);
  useShortcut(escapeShortcutHandler, Code.Escape, [escapeShortcutHandler]);
  useShortcut(slashShortcutHandler, Code.Slash, [slashShortcutHandler]);

  const isInteractionMode = React.useMemo(
    () =>
      additionMode.isOn ||
      notesMode.isOn ||
      regionOfInterestMode.isOn ||
      zoomInMode.isOn,
    [additionMode, notesMode, regionOfInterestMode, zoomInMode],
  );

  const additionModeCancelText = React.useMemo(() => {
    const { subject } = additionMode;
    if (subject === 'variance') return 'Cancel Adding Variance Event';
    if (subject === 'capacity') return 'Cancel Adding Capacity Change';
    if (subject === 'ribbonEvent') return 'Cancel Adding Ribbon Event';
    return 'Cancel Adding Allocation Issue';
  }, [additionMode]);

  return (
    <TopControls.ControlsContainer>
      {isInteractionMode && (
        <>
          {zoomInMode.isOn && (
            <TopControls.CancelButton
              width={120}
              onClick={() => {
                zoomInModeOff();
              }}
            >
              Cancel Zoom
            </TopControls.CancelButton>
          )}

          {additionMode.isOn && (
            <TopControls.CancelButton width={210} onClick={additionModeOff}>
              {additionModeCancelText}
            </TopControls.CancelButton>
          )}

          {regionOfInterestMode.isOn && (
            <TopControls.CancelButton
              width={175}
              onClick={regionOfInterestModeOff}
            >
              Cancel
            </TopControls.CancelButton>
          )}

          {notesMode.isOn && (
            <TopControls.CancelButton width={175} onClick={notesModeOff}>
              Cancel
            </TopControls.CancelButton>
          )}
        </>
      )}

      {!isInteractionMode && (
        <>
          <TopControls.ButtonsOffset offset={buttonsOffset}>
            {R.isEmpty(trellisesMap) &&
              !rightPanel.isDialogOfType('ChartOptions') && (
                <TopControls.IconButton onClick={openTrellisMenu}>
                  <BarsMenuIcon />
                </TopControls.IconButton>
              )}

            {!R.isEmpty(trellisesMap) && (
              <>
                <TopControls.ButtonWrapper>
                  <TopControls.IconButton onClick={openZoomMenu}>
                    <ZoomIcon />
                  </TopControls.IconButton>

                  <SeriesLayoutsDropdownButton onClick={openTrellisMenu} />
                </TopControls.ButtonWrapper>

                {!groupMode.isOn &&
                  !isMarkingRows &&
                  (isAllowedEditAllocIssues ||
                    isAllowedEditCapChanges ||
                    isAllowedEditVarEvents) && (
                    <AddEventMenu
                      additionModeOn={additionModeOn}
                      allocIssuesVisibility={allocIssuesVisibility}
                      isAllowedEditAllocIssues={isAllowedEditAllocIssues}
                      isAllowedEditCapChanges={isAllowedEditCapChanges}
                      isAllowedEditVarEvents={isAllowedEditVarEvents}
                      isAllowAllocIssues={config.allocationIssues}
                    />
                  )}

                {isZoomMenuOpened && (
                  <CloseOnMouseClickOutside
                    exceptForClassName="zoom-menu"
                    closeHandler={() => closeZoomMenu()}
                    event="mouseup"
                  >
                    <ZoomMenu
                      changeOneExtremeDate={changeOneExtremeDate}
                      closeZoomMenuWithTimeout={closeZoomMenuWithTimeout}
                      config={config}
                      extremeDates={extremeDates}
                      isProductionLoading={!!isProductionLoading}
                      offset={buttonsOffset}
                      onZoomMenuHover={onZoomMenuHover}
                      setZoomPreset={setZoomPreset}
                      undoExtremeDatesChange={() => {
                        fetchSensorSeries();
                        dispatch(undoExtremeDatesChange());
                      }}
                      zoomInModeOn={zoomInModeOn}
                      zoomHistoryLength={zoomHistoryLength}
                      zoomOut={zoomOut}
                      zoomToAll={zoomToAll}
                      ref={zoomMenuEl}
                    />
                  </CloseOnMouseClickOutside>
                )}
              </>
            )}

            {!groupMode.isOn &&
              !isMarkingRows &&
              !rightPanel.isDialogOfType('WellNotes') && (
                <TopControls.RightButton onClick={onNotesDialogClick}>
                  <NotesIcon />
                </TopControls.RightButton>
              )}

            {!groupMode.isOn &&
              !isMarkingRows &&
              !rightPanel.isDialogOfType('WellInfo') && (
                <TopControls.RightButton onClick={onWellInfoDialogClick}>
                  <InfoIcon width={24} height={24} />
                </TopControls.RightButton>
              )}

            {!rightPanel.isDialogOfType('Shortcuts') && (
              <TopControls.QuestionButton onClick={onOpenShortcutPanel}>
                <QuestionIcon />
              </TopControls.QuestionButton>
            )}
          </TopControls.ButtonsOffset>

          {!rightPanel.isExpanded && (
            <TopControls.OpenLegendButton onClick={rightPanel.expand}>
              <OpenLegendPanelIcon />
            </TopControls.OpenLegendButton>
          )}
        </>
      )}
    </TopControls.ControlsContainer>
  );
};

TopControls.ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 8px;
  right: 7px;
  height: 35px;
  z-index: 105;
  background: white;
  pointer-events: none;
`;

TopControls.ButtonsOffset = styled.div`
  display: flex;
  padding-right: ${({ offset }) => offset}px;

  > :not(:first-child) {
    margin-left: 8px;
  }
`;

TopControls.CancelButton = styled(Button)`
  height: 31px;
  pointer-events: auto;
  margin-right: 55px;
`;

TopControls.FormControl = styled(FormControl)`
  position: absolute;
  top: -10px;
  pointer-events: auto;
`;

TopControls.PlusButton = styled(Button)`
  height: 31px;
  width: 90px;
  background-color: #f0f0f0;
  pointer-events: auto;
  padding: 3px;
  margin-right: 10px;
`;

TopControls.IconButton = styled(Button)`
  height: 31px;
  width: 31px;
  background-color: #f0f0f0;
  pointer-events: auto;
  padding: 2px;
`;

TopControls.RightButton = styled(Button)`
  height: 31px;
  width: 31px;
  background-color: #f0f0f0;
  pointer-events: auto;
  padding: 2px;
`;

TopControls.OpenLegendButton = styled(TopControls.RightButton)`
  margin-left: 8px;
`;

TopControls.QuestionButton = styled(TopControls.RightButton)`
  svg {
    width: 31px;
    height: 31px;
  }
`;

TopControls.ShareButton = styled(Button)`
  margin: 15px 30px;
`;

TopControls.TrellisMenuWrapper = styled.div`
  position: absolute;
  top: 35px;
  pointer-events: auto;
  right: ${props => (props.offset ? props.offset + 135 : 0)}px;
`;

TopControls.MenuItem = styled(MenuItem)`
  /* padding: 0 !important;
  height: 30px !important;
  min-height: 30px !important;
  width: 100% !important; */
`;

TopControls.FormLabel = styled.legend`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 9px;
`;

TopControls.TrellisMenuLegend = styled(TopControls.FormLabel)`
  margin-left: 15px;
  margin-top: 10px;
`;

TopControls.FormControlLabel = styled(FormControlLabel)`
  width: 240px;
  margin: 0 !important;
  line-height: 1em !important;
  height: 30px;
  padding: 0 24px 0 0 !important;

  & .MuiTypography-root {
    font-size: 0.875rem !important;
  }

  & > label {
    width: 100%;
  }
`;

TopControls.ButtonWrapper = styled.div`
  display: flex;

  > :nth-child(2) {
    margin-left: 8px;
  }
`;

TopControls.OpenLegendWrapper = styled.div`
  width: 36px;
`;

TopControls.FormGroup = styled(FormGroup)`
  width: 155px;
`;

export default TopControls;
