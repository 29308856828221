import { SeriesMapping, NormalizedSeriesMapping } from '../models';

const normalizeSeriesMapping = (
  mapping: SeriesMapping[],
): NormalizedSeriesMapping => {
  const normalizedMapping = mapping.reduce(
    (
      acc: NormalizedSeriesMapping,
      data: SeriesMapping,
    ): NormalizedSeriesMapping => {
      acc[data.id] = data;
      return acc;
    },
    {},
  );
  return normalizedMapping;
};

export default normalizeSeriesMapping;
