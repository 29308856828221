import * as R from 'ramda';
import {
  DrilldownTableParams,
  VarianceDrilldownTableItem,
  WellDrilldownTableItem,
} from '../models/drilldownTable';

export const calculateWellTotalColumn = (
  varianceTable: VarianceDrilldownTableItem[],
  wellTabel: WellDrilldownTableItem[],
  markedRows: string[],
  groupMode: { subject: string; isOn: boolean },
  isMarkingRows: boolean,
  forecastTable: any,
  params: DrilldownTableParams,
  currentVarianceOptions,
) => {
  const phase = R.pathOr('oil', ['phase'], params).toLowerCase();
  const capacityKey = phase + 'Capacity';
  const varianceKey = phase + 'Variance';
  const actualKey = phase + 'Actual';
  const secondColKey = phase + 'SecondCol';
  const thirdColKey = phase + 'ThirdCol';
  const markedRowKey = groupMode.isOn ? groupMode.subject : 'well';
  const wellTeabelSum = wellTabel.reduce(
    (acc, row) => {
      acc[capacityKey] += row[capacityKey] ?? row[actualKey];
      acc[actualKey] += row[actualKey];
      return acc;
    },
    {
      [actualKey]: 0,
      [capacityKey]: 0,
    },
  );

  if (R.isEmpty(currentVarianceOptions)) {
    if (params.compareOption === 'actual') {
      const wellTeabelTotalRow = {
        secondCol: wellTeabelSum[actualKey],
        thirdCol: wellTeabelSum[capacityKey],
        variance: isMarkingRows
          ? wellTabel.reduce(
              (acc, n) =>
                acc +
                (markedRows.includes(n[markedRowKey]) ? n[varianceKey] : 0),
              0,
            )
          : wellTeabelSum[actualKey] - wellTeabelSum[capacityKey],
      };
      return wellTeabelTotalRow;
    }
    if (params.compareOption === 'extVsCap') {
      const wellTeabelSum = forecastTable.reduce(
        (acc, row) => {
          acc.secondCol += row[secondColKey];
          acc.thirdCol += row[thirdColKey] ?? row[actualKey];

          const variance =
            row[thirdColKey] !== null
              ? row[varianceKey]
              : row[actualKey] - row[secondColKey];

          if (isMarkingRows) {
            if (markedRows.includes(row[markedRowKey]))
              acc.variance += variance;
          } else {
            acc.variance += variance;
          }

          return acc;
        },
        {
          secondCol: 0,
          thirdCol: 0,
          variance: 0,
        },
      );
      return wellTeabelSum;
    }
    if (params.compareOption === 'actVsExt') {
      const wellTeabelSum = forecastTable.reduce(
        (acc, row) => {
          acc.secondCol += row[secondColKey];
          acc.thirdCol += row[thirdColKey];
          acc.variance += row[varianceKey];
          return acc;
        },
        {
          secondCol: 0,
          thirdCol: 0,
          variance: 0,
        },
      );
      return wellTeabelSum;
    }
  }
  const varianceSum = varianceTable
    .filter(v => !isMarkingRows || markedRows.includes(v[markedRowKey]))
    .reduce((acc, row) => (acc += row[varianceKey]), 0);
  if (params.compareOption === 'actual') {
    const wellTableTotalRow = {
      secondCol: wellTeabelSum[actualKey],
      thirdCol: wellTeabelSum[capacityKey],
      variance: varianceSum,
    };
    return wellTableTotalRow;
  }
};
