import { all, put, takeLatest } from 'redux-saga/effects';

import { AUTHENTICATED } from 'modules/app/AppActions';

import { fetchAllUsers } from './UserActions';

function* fetchAllUsersSaga(): Generator<any, any, any> {
  yield put(fetchAllUsers());
}

function* userSagas(): Generator<any, any, any> {
  yield all([takeLatest(AUTHENTICATED, fetchAllUsersSaga)]);
}

export default userSagas;
