import { scaleUtc } from 'd3-scale';
import { utcDay } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';

import { getRibbonExtremeDates } from 'helpers';
import { useNonInputKeydown } from 'hooks/useKeydown';

import useRibbonEvent from 'modules/ribbon/hooks/useRibbonEvent';
import useLocalRibbonEvent from 'modules/ribbon/hooks/useLocalRibbonEvent';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';

interface OverlayAddRibbonEventProps {
  additionModeOff(): void;
  extremeDates: { [key: string]: Date };
  height: number;
  leftOffset: number;
  today: Date;
  width: number;
}

const OverlayAddRibbonEvent = (
  {
    additionModeOff,
    extremeDates,
    height,
    leftOffset,
    today,
    width,
  }: OverlayAddRibbonEventProps,
  ref,
) => {
  const { latestLocalEvent: localEvent } = useLocalRibbonEvent();
  const {
    createLocalRibbonEvent,
    createRibbonEvent,
    updateRibbonEventDates,
    deleteRibbonEvent,
  } = useRibbonEvent();

  const xScale = scaleUtc()
    .range([0, width])
    .domain([extremeDates.min, extremeDates.max]);

  const [isCreating, setCreatingStatus] = React.useState(false);
  const [lineXPosition, setLineXPosition] = React.useState(0);
  const [localEventStartDate, setLocalEventStartDate] =
    React.useState<Date | null>(null);

  const hideLine = React.useCallback(() => setLineXPosition(0), []);
  const moveLine = React.useCallback(
    e => setLineXPosition(e.clientX - leftOffset - Y_AXIS_WIDTH - 10),
    [leftOffset],
  );

  const initEventCreating = React.useCallback(
    (date: Date) => {
      setLocalEventStartDate(date);
      setCreatingStatus(true);
      createLocalRibbonEvent(date);
    },
    [createLocalRibbonEvent],
  );

  const exitEventCreating = React.useCallback(() => {
    setCreatingStatus(false);
    setLocalEventStartDate(null);
    additionModeOff();
  }, [additionModeOff]);

  const finishEventCreating = React.useCallback(() => {
    if (!localEventStartDate || !localEvent) return;
    createRibbonEvent(localEvent);
    exitEventCreating();
  }, [exitEventCreating, localEventStartDate, createRibbonEvent, localEvent]);

  const cancelEventCreating = React.useCallback(() => {
    if (!isCreating || !localEvent) return;
    deleteRibbonEvent(localEvent.id);
    exitEventCreating();
  }, [isCreating, localEvent, deleteRibbonEvent, exitEventCreating]);

  const updateEventOnMove = React.useCallback(
    (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
      if (!isCreating || !localEvent) return;

      const extremeDates = getRibbonExtremeDates(today);
      const dateOfCursor = utcDay.floor(
        xScale.invert(e.clientX - leftOffset - Y_AXIS_WIDTH - 10),
      );

      if (dateOfCursor < extremeDates[0] || dateOfCursor > extremeDates[1]) {
        return;
      }

      updateRibbonEventDates({
        ribbonEventId: localEvent.id,
        dates: [dateOfCursor, localEventStartDate],
      });
    },
    [xScale, localEventStartDate, isCreating, localEvent?.id],
  );

  const handleMouseClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (!isCreating) {
        const date = utcDay.floor(
          xScale.invert(e.clientX - leftOffset - Y_AXIS_WIDTH - 10),
        );
        if (date >= today) return;
        initEventCreating(date);
      } else {
        finishEventCreating();
      }
    },
    [
      today,
      leftOffset,
      finishEventCreating,
      initEventCreating,
      isCreating,
      xScale,
    ],
  );

  useNonInputKeydown(
    ({ keyName }) => {
      if (!isCreating) return;
      if (keyName === 'ESCAPE') return cancelEventCreating();
      if (keyName === 'DELETE') return cancelEventCreating();
    },
    [isCreating, cancelEventCreating],
  );

  return (
    <OverlayAddRibbonEvent.Container
      height={height}
      className="ribbon-interactive interactive"
    >
      <svg
        ref={ref}
        height={height}
        onMouseMove={moveLine}
        onMouseOver={moveLine}
        preserveAspectRatio="none"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
      >
        <g onClick={handleMouseClick} onMouseMove={updateEventOnMove}>
          <rect
            fill={process.env.DEBUG ? 'rgba(150, 0, 150, 0.5)' : 'transparent'}
            height={height}
            width={width}
            x={0}
            y={0}
          />

          <polygon
            fill={process.env.DEBUG ? 'rgba(50, 50, 50, 0.7)' : 'transparent'}
            onMouseEnter={() => hideLine()}
            onMouseMove={e => {
              if (!isCreating) e.stopPropagation();
              hideLine();
            }}
            onClick={e => {
              if (!isCreating) e.stopPropagation();
            }}
            points={`${xScale(today)},0 ${xScale(extremeDates.max)},0 ${xScale(
              extremeDates.max,
            )},${height} ${xScale(today)}, ${height}`}
          />

          <line
            stroke={isCreating ? 'transparent' : 'black'}
            strokeDasharray="2"
            strokeWidth="1"
            x1={0}
            x2={0}
            y1={0}
            y2={height}
            transform={`translate(${lineXPosition}, 0)`}
          />
        </g>
      </svg>
    </OverlayAddRibbonEvent.Container>
  );
};

OverlayAddRibbonEvent.Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 75;
`;

export default React.memo<OverlayAddRibbonEventProps & { ref: any }>(
  React.forwardRef<Element, OverlayAddRibbonEventProps>(OverlayAddRibbonEvent),
);
