import * as React from 'react';

import type { Note } from 'modules/notes/models/notes';
import type { User } from 'modules/user/models/user';

import NoteForm from '../forms/NoteForm';
import NoteSection from '../components/NoteSection';

interface EditableNote extends Note {
  attachmentIds: string[];
}

interface NoteSectionGroupProps {
  notes: Note[];
  editingDialog: {
    show: boolean;
    noteId: string | null;
  };
  noteIdsInProgress: string[];
  onEditNoteSubmit: (note: EditableNote) => void;
  closeEditingDialog: () => void;
  usersByEmailName: { [name: string]: User };
  onAttachmentDelete: (fileName: string) => void;
  onAttachmentUpload: (file: any) => Promise<any>;
  notificate: (notification: string) => void;
  hideMessage: () => void;
  users: { [key: string]: User };
  currentUserId: string;
  onSaveStateChange?: (hasUnsavedData: boolean) => void;
  openEditingDialog: (dialogData: {
    noteId: string;
    productionDay: Date;
  }) => void;
  openAlertDeleteWindow: (noteId: string, productionDay: Date) => void;
  dayString: string;
}

const NotesSectionGroup = ({
  notes,
  editingDialog,
  noteIdsInProgress,
  onEditNoteSubmit,
  closeEditingDialog,
  usersByEmailName,
  onAttachmentDelete,
  onAttachmentUpload,
  onSaveStateChange,
  notificate,
  hideMessage,
  users,
  currentUserId,
  openEditingDialog,
  openAlertDeleteWindow,
  dayString,
}: NoteSectionGroupProps) => {
  const notesProgressStatusById = React.useMemo(() => {
    return notes.reduce((acc, note) => {
      acc[note.id] = noteIdsInProgress.includes(note.id);
      return acc;
    }, {});
  }, [notes, noteIdsInProgress]);

  return (
    <>
      {notes.map(note =>
        editingDialog.show && editingDialog.noteId === note.id ? (
          <NoteForm
            key={note.id}
            isForEdit
            isInProgress={noteIdsInProgress.includes(note.id)}
            noteText={note.noteText}
            noteAttachments={note.attachments}
            onSubmit={(noteText, attachmentIds) =>
              onEditNoteSubmit({
                ...note,
                noteText,
                attachmentIds,
              })
            }
            onSaveStateChange={onSaveStateChange}
            close={closeEditingDialog}
            usersByEmailName={usersByEmailName}
            onAttachmentDelete={onAttachmentDelete}
            onAttachmentUpload={onAttachmentUpload}
            notificate={notificate}
            hideNotification={hideMessage}
          />
        ) : (
          <NoteSection
            key={note.id}
            isInProgress={notesProgressStatusById[note.id]}
            note={note}
            authorUser={users[note.userId]}
            currentUserId={currentUserId}
            openEditingDialog={openEditingDialog}
            removeNote={openAlertDeleteWindow}
            productionDayString={dayString}
            usersByEmailName={usersByEmailName}
          />
        ),
      )}
    </>
  );
};

export default React.memo<NoteSectionGroupProps>(NotesSectionGroup);
