import React from 'react';
import styled from 'styled-components';
import { LatestEventNote, NoteFilter } from '../models';
import { timeFormat } from 'd3-time-format';
import { highlightMentions } from 'modules/eventNotes/utils/mentions';
import { User } from 'modules/user/models/user';
import InputCheckbox from 'components/InputCheckbox';
import {
  CloseEnvelope,
  OpenEnvelope,
  Archive,
  MoveToInbox,
} from 'components/Icons';
import { useConversation } from '../utils/useConversation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage, faPaperclip } from '@fortawesome/pro-regular-svg-icons';

interface EventNoteProps {
  eventNote: LatestEventNote;
  isActive: boolean;
  usersByEmailName: { [name: string]: User };
  onConversationArchived: () => void;
  toggleNote: (isActive: any) => void;
  noteIsChecked: boolean;
  itLastNoteWithPaginatorOnPage: boolean;
}

export const EventNote = ({
  eventNote,
  isActive,
  usersByEmailName,
  onConversationArchived,
  toggleNote,
  noteIsChecked,
  itLastNoteWithPaginatorOnPage,
}: EventNoteProps) => {
  const ref = React.useRef<HTMLElement | null>(null);
  const conversation = useConversation();

  const shouldDisplayInboxMark = React.useMemo(
    () => eventNote.inbox && conversation.activeFilter !== NoteFilter.incoming,
    [eventNote.inbox, conversation.activeFilter],
  );

  const getFormattedEventName = React.useCallback((type: string) => {
    switch (type) {
      case 'capacity':
        return 'Capacity Change';
      case 'ribbon':
        return 'Ribbon Event';
      case 'variance':
        return 'Variance Event';
      default:
        return '';
    }
  }, []);

  const getFormattedAuthors = React.useCallback((authors: string[]) => {
    return authors.slice(0, 2).join(', ') + (authors.length > 2 ? `...` : '');
  }, []);

  const getNumberOfHiddenAuthors = React.useCallback((authors: string[]) => {
    return authors.length > 2 ? '+' + (authors.length - 2) : '';
  }, []);

  React.useEffect(() => {
    if (!isActive) return;

    requestAnimationFrame(() => {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    });
  }, [isActive]);

  const onActionButtonClick = React.useCallback(
    e => {
      e.stopPropagation();

      if (!eventNote.inbox)
        return conversation.moveConversationToInbox(eventNote);

      if (
        conversation.activeFilter === NoteFilter.incoming ||
        conversation.activeFilter === NoteFilter.sent
      )
        onConversationArchived();

      return conversation.archiveConversation(eventNote);
    },
    [
      conversation.activeFilter,
      conversation.archiveConversation,
      conversation.moveConversationToInbox,
      eventNote,
      onConversationArchived,
    ],
  );

  return (
    <EventNote.Container
      ref={ref}
      isActive={isActive}
      noteIsChecked={noteIsChecked}
      itLastNoteWithPaginatorOnPage={itLastNoteWithPaginatorOnPage}
      onClick={() => {
        if (isActive) return;

        conversation.openConversation(eventNote);
        conversation.readConversation(eventNote);
      }}
    >
      <EventNote.CheckboxContainer onClick={e => e.stopPropagation()}>
        <EventNote.CheckboxContainerForRef>
          <InputCheckbox
            paddingLeft={'0'}
            width={'16px'}
            marginBottom={'0'}
            checked={noteIsChecked}
            onChange={() => {
              toggleNote(!noteIsChecked);
            }}
          />
        </EventNote.CheckboxContainerForRef>
      </EventNote.CheckboxContainer>

      <EventNote.RowsContainer>
        <EventNote.TopRow>
          <EventNote.Authors isRead={eventNote.read}>
            {getFormattedAuthors(eventNote.authors)}
            <EventNote.AuthorsCounter>
              {getNumberOfHiddenAuthors(eventNote.authors)}
            </EventNote.AuthorsCounter>
          </EventNote.Authors>

          <EventNote.NoteMeta>
            <EventNote.EventIcons>
              {eventNote.read ? (
                <EventNote.ReadOrUnread
                  onClick={e => {
                    e.stopPropagation();
                    conversation.unreadConversation(eventNote);
                  }}
                >
                  <EventNote.TitleContainer title="Mark as Unread">
                    <CloseEnvelope />
                  </EventNote.TitleContainer>
                </EventNote.ReadOrUnread>
              ) : (
                <EventNote.ReadOrUnread
                  onClick={e => {
                    e.stopPropagation();
                    conversation.readConversation(eventNote);
                  }}
                >
                  <EventNote.TitleContainer title="Mark as Read">
                    <OpenEnvelope />
                  </EventNote.TitleContainer>
                </EventNote.ReadOrUnread>
              )}

              <EventNote.MainActionButton onClick={onActionButtonClick}>
                {eventNote.inbox ? (
                  <EventNote.TitleContainer title="Archive">
                    <Archive color="#4f4f4f" width={20} height={20} />
                  </EventNote.TitleContainer>
                ) : (
                  <EventNote.TitleContainer title="Move to Inbox">
                    <MoveToInbox color="#4f4f4f" width={20} height={20} />
                  </EventNote.TitleContainer>
                )}
              </EventNote.MainActionButton>
            </EventNote.EventIcons>

            <EventNote.Date>
              <EventNote.Attachments>
                {eventNote.hasAttachedPhotos && (
                  <FontAwesomeIcon color="#909090" icon={faFileImage} />
                )}

                {eventNote.hasAttachedFiles && (
                  <FontAwesomeIcon
                    color="#909090"
                    icon={faPaperclip}
                    flip="vertical"
                  />
                )}
              </EventNote.Attachments>

              <EventNote.Date>
                Sent {timeFormat('%_m/%-d/%Y')(eventNote.noteTimestamp)}
              </EventNote.Date>
            </EventNote.Date>
          </EventNote.NoteMeta>
        </EventNote.TopRow>

        <EventNote.MiddleRow isRead={eventNote.read}>
          <EventNote.WellName>{eventNote.wellName}</EventNote.WellName>

          <EventNote.EventType>
            {getFormattedEventName(eventNote.eventType)}
          </EventNote.EventType>

          <EventNote.EventInterval>
            {timeFormat('%_m/%-d/%Y')(eventNote.dateStart)}
            {eventNote.dateEnd && ' – '}
            {eventNote.dateEnd
              ? timeFormat('%_m/%-d/%Y')(eventNote.dateEnd)
              : null}
          </EventNote.EventInterval>
        </EventNote.MiddleRow>

        <EventNote.BottomRow>
          {shouldDisplayInboxMark && (
            <EventNote.InboxMark>Inbox</EventNote.InboxMark>
          )}

          <EventNote.Text
            dangerouslySetInnerHTML={highlightMentions(
              eventNote.noteText.replace(/\n/g, ' '),
              usersByEmailName,
              '#35995b',
            )}
          />
        </EventNote.BottomRow>
      </EventNote.RowsContainer>
    </EventNote.Container>
  );
};

EventNote.CheckboxContainerForRef = styled.div``;

EventNote.Date = styled.div`
  color: #909090;
  display: flex;
  gap: 8px;
  font-size: 12px;
`;

EventNote.EventIcons = styled.div`
  align-items: center;
  gap: 8px;
  display: none;
`;

EventNote.ReadOrUnread = styled.div`
  z-index: 1;
`;

EventNote.MainActionButton = styled.div`
  margin-right: 5px;
`;

EventNote.Archive = styled.div`
  margin-right: 12px;
  z-index: 1;
`;

EventNote.Container = styled.div`
  position: relative;
  padding-right: 8px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 34px minmax(0, 1fr);
  border-bottom: ${({ itLastNoteWithPaginatorOnPage }) =>
    itLastNoteWithPaginatorOnPage ? 'none' : '1px solid #c1c1c1'};
  cursor: pointer;
  background-color: ${({ isActive, noteIsChecked }) =>
    isActive ? '#efefef' : noteIsChecked ? '#F5FAF7' : 'initial'};

  ::after {
    content: '';
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1.5px;
    background-color: #249b55;
  }

  :hover {
    ${EventNote.EventIcons} {
      display: flex;
    }

    ${EventNote.Date} {
      display: none;
    }
  }
`;

EventNote.CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative; /* required for getting offset position */
`;

EventNote.RowsContainer = styled.div`
  padding: 5px 5px 8px 0;
`;

EventNote.TopRow = styled.div`
  height: 24px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  justify-content: space-between;
`;

EventNote.Authors = styled.div`
  font-size: 15px;
  line-height: 24px;
  font-weight: ${({ isRead }) => (isRead ? 'normal' : '700')};
`;

EventNote.AuthorsCounter = styled.span`
  font-size: 12px;
  margin-left: 4px;
`;

EventNote.NoteMeta = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

EventNote.Attachments = styled.div`
  display: flex;
  gap: 4px;

  &&& svg {
    font-size: 12px;
    width: 16px;
  }
`;

EventNote.MiddleRow = styled.div`
  display: flex;
  height: 22px;
  line-height: 19px;
  margin-top: -0.6px;
  font-size: 14px;
  font-weight: ${({ isRead }) => (isRead ? 'normal' : '700')};

  text-overflow: ellipsis;
  white-space: nowrap;
`;

EventNote.BottomRow = styled.div`
  height: 17px;
  font-size: 12px;
  margin-top: 0.6px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

EventNote.Text = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

EventNote.InboxMark = styled.span`
  width: 38px;
  height: 17px;
  background-color: #c1c1c1;
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  flex-shrink: 0;
`;

EventNote.WellName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

EventNote.EventType = styled.div`
  border-right: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  padding: 0 7px;
  margin: 0 7px;
`;

EventNote.EventInterval = styled.div``;
EventNote.TitleContainer = styled.div``;
