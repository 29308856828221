import * as R from 'ramda';
import * as React from 'react';

import {
  RIBBON_HEIGHT,
  Y_AXIS_WIDTH,
  VARIANCE_TRELLIS,
} from 'modules/chart/models/chart';
import styled from 'styled-components';
import type { AppConfig } from 'modules/appConfig/models/appConfig';
import { NormalizedSeriesMapping } from 'modules/series/models';

import { getPillText } from 'modules/chart/utils';
import SeriesPill from 'modules/chart/components/SeriesPill';
import { ChartOption } from 'modules/chartOptions/ChartOptionsSagas';
import { ListChartOptions } from 'modules/chartOptions/models';

interface LoadingPlaceholderProps {
  appConfig: AppConfig & { timeSeries: boolean };
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    grossNet: string;
  };
  cavSeries: ChartOption[];
  chartOptions: ListChartOptions;
  groupToDisplay: { ids: string[] }[];
  onPillClick: () => void;
  ribbonCount: number;
  selectedRibbonsData: { id: string; name: string; order: number }[];
  trellisHeight: number;
  seriesMapping: NormalizedSeriesMapping;
}

const LoadingPlaceholder = ({
  appConfig,
  drilldownTableParams,
  cavSeries,
  chartOptions,
  groupToDisplay,
  onPillClick,
  ribbonCount,
  selectedRibbonsData,
  seriesMapping,
  trellisHeight,
}: LoadingPlaceholderProps) => {
  const onGetPillText = (id: string) => {
    if (id === VARIANCE_TRELLIS) {
      return getPillText(
        drilldownTableParams.phase,
        drilldownTableParams.grossNet,
        true,
      );
    }
    return getPillText(id, drilldownTableParams.grossNet, false);
  };
  return (
    <LoadingPlaceholder.Wrapper>
      {cavSeries.map((series, i) => (
        <LoadingPlaceholder.Container key={i} height={trellisHeight}>
          <LoadingPlaceholder.YAxisBorder />
          <SeriesPill
            text={onGetPillText(series.id)}
            onPillClick={onPillClick}
          />
        </LoadingPlaceholder.Container>
      ))}
      {groupToDisplay.map((group, i) => (
        <LoadingPlaceholder.Container key={i} height={trellisHeight}>
          <LoadingPlaceholder.YAxisBorder />
          <LoadingPlaceholder.PillsContainer>
            {group.ids.map(id => (
              <SeriesPill
                key={id}
                text={R.pathOr('', [id, 'title'], chartOptions)}
                onPillClick={onPillClick}
                color={chartOptions[id].customColor || chartOptions[id].color}
              />
            ))}
          </LoadingPlaceholder.PillsContainer>
        </LoadingPlaceholder.Container>
      ))}
      {!!ribbonCount && (
        <LoadingPlaceholder.RibbonWrapper ribbonCount={ribbonCount}>
          {selectedRibbonsData.map((ribbon, i) => (
            <LoadingPlaceholder.RibbonPlaceholder key={i}>
              <LoadingPlaceholder.YAxisBorder />
            </LoadingPlaceholder.RibbonPlaceholder>
          ))}
        </LoadingPlaceholder.RibbonWrapper>
      )}
    </LoadingPlaceholder.Wrapper>
  );
};

LoadingPlaceholder.Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

LoadingPlaceholder.RibbonWrapper = styled.div`
  height: ${props => props.ribbonCount * RIBBON_HEIGHT}px;
  z-index: 75;
  border-bottom: 1px solid black;
`;

LoadingPlaceholder.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: ${(props: Record<string, any>) =>
    (50 / (props.index + 1)).toFixed(0)};
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
  font-size: 12px;
  font-weight: 600;
`;

LoadingPlaceholder.YAxisBorder = styled.div`
  height: 100%;
  width: ${Y_AXIS_WIDTH + 1}px;
  border-right: 1px solid black;
`;

LoadingPlaceholder.PillsContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 5px;
  display: flex;

  > div {
    position: initial;
    margin-left: 10px;
  }
`;

LoadingPlaceholder.RibbonPlaceholder = styled.div`
  height: ${RIBBON_HEIGHT}px;
  display: flex;
  cursor: pointer;
`;

export default React.memo<LoadingPlaceholderProps>(LoadingPlaceholder);
