import { format } from 'd3-format';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';

import usePrevious from 'hooks/usePrevious';

import {
  convertDeclineEffectiveToNominal,
  convertDeclineNominalToEffective,
} from '../utils';

const NOT_POSITIVE_NUMBER_REGEXP = new RegExp(/[^0-9,.]/);
const NOT_NUMBER_REGEXP = new RegExp(/[^0-9,.-]/);
const ENTER_KEYCODE = 13;
const BACKSPACE_KEYCODE = 8;
const LEFT_KEYCODE = 37;
const RIGHT_KEYCODE = 39;
const DELETE_KEYCODE = 46;

const isNotNumber = string => NOT_NUMBER_REGEXP.test(string);
const isNotPositiveNumber = string => NOT_POSITIVE_NUMBER_REGEXP.test(string);
const isNotForTextEditing = keyCode =>
  keyCode !== BACKSPACE_KEYCODE &&
  keyCode !== LEFT_KEYCODE &&
  keyCode !== RIGHT_KEYCODE &&
  keyCode !== DELETE_KEYCODE;

const rateInitFormat: (rate: number) => string = format(',d');
const declineInitFormat: (decline: number) => string = decline => {
  if (decline < 0) return format('.4~f')(decline);
  return format('.2~f')(decline) + '%';
};
const bFactorFormat: (bFactor: number) => string = format('.3~');

const parseNumber = (fieldValue: string): number => {
  const parsedValue = parseFloat(
    fieldValue
      .replace(' ', '')
      .replace(',', '')
      .replace('%', '')
      .replace(NOT_NUMBER_REGEXP, '')
      .trim(),
  );
  if (isNaN(parsedValue)) return 0;

  return parsedValue;
};

interface DeclineParametersFormProps {
  capacityEvent: CapacityChangeEvent;
  disabled: boolean;
  onCapacityUpdate: (data: any) => void;
}

const DeclineParametersForm = ({
  capacityEvent,
  disabled,
  onCapacityUpdate,
}: DeclineParametersFormProps) => {
  const prevEvent = usePrevious(capacityEvent);
  const {
    oilRateInit,
    oilDeclineInitDailyNom,
    oilBFactor,
    gasRateInit,
    gasDeclineInitDailyNom,
    gasBFactor,
    waterRateInit,
    waterDeclineInitDailyNom,
    waterBFactor,
    isLockSlopeCapacityLine,
  } = capacityEvent;

  const initialValues = {
    oilRateInit: rateInitFormat(oilRateInit),
    oilDeclineInitDailyNom: declineInitFormat(
      convertDeclineNominalToEffective(oilDeclineInitDailyNom, oilBFactor),
    ),
    oilBFactor: bFactorFormat(oilBFactor),
    gasRateInit: rateInitFormat(gasRateInit),
    gasDeclineInitDailyNom: declineInitFormat(
      convertDeclineNominalToEffective(gasDeclineInitDailyNom, gasBFactor),
    ),
    gasBFactor: bFactorFormat(gasBFactor),
    waterRateInit: rateInitFormat(waterRateInit),
    waterDeclineInitDailyNom: declineInitFormat(
      convertDeclineNominalToEffective(waterDeclineInitDailyNom, waterBFactor),
    ),
    waterBFactor: bFactorFormat(waterBFactor),
  };

  const [values, setValues] = React.useState(initialValues);

  const setFieldValue = (fieldName: string, fieldValue: string) => {
    const newValues = R.assoc<string, Record<string, any>>(
      fieldName,
      fieldValue,
      values,
    );
    setValues(newValues);
  };

  const processInput = (fieldName: string, fieldValue: string) => {
    const number = parseNumber(fieldValue);

    if (
      fieldName === 'oilRateInit' ||
      fieldName === 'gasRateInit' ||
      fieldName === 'waterRateInit'
    ) {
      const formattedValue: string = rateInitFormat(number);
      setFieldValue(fieldName, formattedValue);
      onCapacityUpdate({ [fieldName]: number || 0 });
      return;
    }
    if (
      fieldName === 'oilDeclineInitDailyNom' ||
      fieldName === 'gasDeclineInitDailyNom' ||
      fieldName === 'waterDeclineInitDailyNom'
    ) {
      const validNumber = number > 99.99 ? 99.99 : number;
      const formattedValue = declineInitFormat(validNumber);
      const bFactor =
        fieldName === 'oilDeclineInitDailyNom'
          ? capacityEvent.oilBFactor
          : fieldName === 'gasDeclineInitDailyNom'
          ? capacityEvent.gasBFactor
          : capacityEvent.waterBFactor;
      const nominalDecline = convertDeclineEffectiveToNominal(
        validNumber,
        bFactor,
      );
      setFieldValue(fieldName, formattedValue);
      onCapacityUpdate({
        [fieldName]: nominalDecline || 0,
      });

      return;
    }
    if (
      fieldName === 'oilBFactor' ||
      fieldName === 'gasBFactor' ||
      fieldName === 'waterBFactor'
    ) {
      const validNumber = Math.min(number, 5) || 0;
      const formattedValue = bFactorFormat(validNumber);
      const declineField = fieldName.replace(/BFactor/, 'DeclineInitDailyNom');
      const nominalDecline = convertDeclineEffectiveToNominal(
        parseNumber(values[declineField]),
        validNumber,
      );
      setFieldValue(fieldName, formattedValue);
      onCapacityUpdate({
        [fieldName]: validNumber,
        [declineField]: nominalDecline,
      });
    }
    if (
      fieldName === 'oilDeclineMinimum' ||
      fieldName === 'gasDeclineMinimum' ||
      fieldName === 'waterDeclineMinimum'
    ) {
      const validNumber = number > 99.99 ? 99.99 : number;
      const formattedValue: string = declineInitFormat(validNumber);
      setFieldValue(fieldName, formattedValue);
      onCapacityUpdate({ [fieldName]: validNumber || 0 });
      return;
    }
  };

  const trimPercent = (fieldName, fieldValue) => {
    const stringWithoutPercent = fieldValue.replace('%', '');
    setFieldValue(fieldName, stringWithoutPercent);
  };

  const handleKeyDown = (e, { allowNegative }) => {
    const { keyCode, key, target } = e;
    const fieldName = target.id;
    const fieldValue = target.value;
    if (keyCode === ENTER_KEYCODE) {
      processInput(fieldName, fieldValue);
      e.target.blur();
    } else if (
      allowNegative &&
      isNotForTextEditing(keyCode) &&
      isNotNumber(key)
    ) {
      e.preventDefault();
    }
    if (
      !allowNegative &&
      isNotForTextEditing(keyCode) &&
      isNotPositiveNumber(key)
    ) {
      e.preventDefault();
    }
  };

  // update fields from chart interactions
  React.useEffect(() => {
    if (
      prevEvent &&
      JSON.stringify(prevEvent) !== JSON.stringify(capacityEvent)
    ) {
      const newValues = {
        oilRateInit: rateInitFormat(capacityEvent.oilRateInit),
        oilDeclineInitDailyNom: declineInitFormat(
          convertDeclineNominalToEffective(
            capacityEvent.oilDeclineInitDailyNom,
            capacityEvent.oilBFactor,
          ),
        ),
        oilBFactor: bFactorFormat(capacityEvent.oilBFactor),
        oilDeclineMinimum: declineInitFormat(
          capacityEvent.oilDeclineMinimum || 0,
        ),
        gasRateInit: rateInitFormat(capacityEvent.gasRateInit),
        gasDeclineInitDailyNom: declineInitFormat(
          convertDeclineNominalToEffective(
            capacityEvent.gasDeclineInitDailyNom,
            capacityEvent.gasBFactor,
          ),
        ),
        gasBFactor: bFactorFormat(capacityEvent.gasBFactor),
        gasDeclineMinimum: declineInitFormat(
          capacityEvent.gasDeclineMinimum || 0,
        ),
        waterRateInit: rateInitFormat(capacityEvent.waterRateInit),
        waterDeclineInitDailyNom: declineInitFormat(
          convertDeclineNominalToEffective(
            capacityEvent.waterDeclineInitDailyNom,
            capacityEvent.waterBFactor,
          ),
        ),
        waterBFactor: bFactorFormat(capacityEvent.waterBFactor),
        waterDeclineMinimum: declineInitFormat(
          capacityEvent.waterDeclineMinimum || 0,
        ),
      };
      setValues(newValues);
    }
  }, [capacityEvent, prevEvent]);

  return (
    <form>
      <DeclineParametersForm.Table>
        <tbody>
          <tr>
            <DeclineParametersForm.ColumnHeader />
            <DeclineParametersForm.ColumnHeader>
              Oil
            </DeclineParametersForm.ColumnHeader>
            <DeclineParametersForm.ColumnHeader>
              Gas
            </DeclineParametersForm.ColumnHeader>
            <DeclineParametersForm.ColumnHeader>
              Water
            </DeclineParametersForm.ColumnHeader>
          </tr>

          <tr>
            <DeclineParametersForm.RowTitle>
              Initial Rate
            </DeclineParametersForm.RowTitle>

            <DeclineParametersForm.InputCell>
              <DeclineParametersForm.Input
                id="oilRateInit"
                disabled={disabled}
                name="oilRateInit"
                value={values.oilRateInit}
                onChange={e => setFieldValue(e.target.id, e.target.value)}
                onKeyDown={e => handleKeyDown(e, { allowNegative: false })}
                onBlur={e => processInput(e.target.id, e.target.value)}
              />
            </DeclineParametersForm.InputCell>

            <DeclineParametersForm.InputCell>
              <DeclineParametersForm.Input
                id="gasRateInit"
                disabled={disabled}
                name="gasRateInit"
                value={values.gasRateInit}
                onChange={e => setFieldValue(e.target.id, e.target.value)}
                onKeyDown={e => handleKeyDown(e, { allowNegative: false })}
                onBlur={e => processInput(e.target.id, e.target.value)}
              />
            </DeclineParametersForm.InputCell>

            <DeclineParametersForm.InputCell>
              <DeclineParametersForm.Input
                id="waterRateInit"
                disabled={disabled}
                name="waterRateInit"
                value={values.waterRateInit}
                onChange={e => setFieldValue(e.target.id, e.target.value)}
                onKeyDown={e => handleKeyDown(e, { allowNegative: false })}
                onBlur={e => processInput(e.target.id, e.target.value)}
              />
            </DeclineParametersForm.InputCell>
          </tr>

          <tr>
            <DeclineParametersForm.RowTitle>
              Initial Decline
            </DeclineParametersForm.RowTitle>

            <DeclineParametersForm.InputCell>
              <DeclineParametersForm.Input
                id="oilDeclineInitDailyNom"
                disabled={disabled || isLockSlopeCapacityLine}
                name="oilDeclineInitDailyNom"
                value={values.oilDeclineInitDailyNom}
                onChange={e => setFieldValue(e.target.id, e.target.value)}
                onKeyDown={e => handleKeyDown(e, { allowNegative: true })}
                onBlur={e => processInput(e.target.id, e.target.value)}
                onFocus={e => trimPercent(e.target.id, e.target.value)}
              />
            </DeclineParametersForm.InputCell>

            <DeclineParametersForm.InputCell>
              <DeclineParametersForm.Input
                id="gasDeclineInitDailyNom"
                disabled={disabled || isLockSlopeCapacityLine}
                name="gasDeclineInitDailyNom"
                value={values.gasDeclineInitDailyNom}
                onChange={e => setFieldValue(e.target.id, e.target.value)}
                onKeyDown={e => handleKeyDown(e, { allowNegative: true })}
                onBlur={e => processInput(e.target.id, e.target.value)}
                onFocus={e => trimPercent(e.target.id, e.target.value)}
              />
            </DeclineParametersForm.InputCell>

            <DeclineParametersForm.InputCell>
              <DeclineParametersForm.Input
                id="waterDeclineInitDailyNom"
                disabled={disabled || isLockSlopeCapacityLine}
                name="waterDeclineInitDailyNom"
                value={values.waterDeclineInitDailyNom}
                onChange={e => setFieldValue(e.target.id, e.target.value)}
                onKeyDown={e => handleKeyDown(e, { allowNegative: true })}
                onBlur={e => processInput(e.target.id, e.target.value)}
                onFocus={e => trimPercent(e.target.id, e.target.value)}
              />
            </DeclineParametersForm.InputCell>
          </tr>

          <tr>
            <DeclineParametersForm.RowTitle>
              B Factor
            </DeclineParametersForm.RowTitle>

            <DeclineParametersForm.InputCell>
              <DeclineParametersForm.Input
                id="oilBFactor"
                disabled={disabled || isLockSlopeCapacityLine}
                name="oilBFactor"
                value={values.oilBFactor}
                onChange={e => setFieldValue(e.target.id, e.target.value)}
                onKeyDown={e => handleKeyDown(e, { allowNegative: false })}
                onBlur={e => processInput(e.target.id, e.target.value)}
              />
            </DeclineParametersForm.InputCell>

            <DeclineParametersForm.InputCell>
              <DeclineParametersForm.Input
                id="gasBFactor"
                disabled={disabled || isLockSlopeCapacityLine}
                name="gasBFactor"
                value={values.gasBFactor}
                onChange={e => setFieldValue(e.target.id, e.target.value)}
                onKeyDown={e => handleKeyDown(e, { allowNegative: false })}
                onBlur={e => processInput(e.target.id, e.target.value)}
              />
            </DeclineParametersForm.InputCell>

            <DeclineParametersForm.InputCell>
              <DeclineParametersForm.Input
                id="waterBFactor"
                disabled={disabled || isLockSlopeCapacityLine}
                name="waterBFactor"
                value={values.waterBFactor}
                onChange={e => setFieldValue(e.target.id, e.target.value)}
                onKeyDown={e => handleKeyDown(e, { allowNegative: false })}
                onBlur={e => processInput(e.target.id, e.target.value)}
              />
            </DeclineParametersForm.InputCell>
          </tr>
        </tbody>
      </DeclineParametersForm.Table>
    </form>
  );
};

DeclineParametersForm.Table = styled.table`
  width: 100%;
  line-height: 24px;
  border-collapse: collapse;
`;

DeclineParametersForm.ColumnHeader = styled.th`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;

  background-color: #f5f5f5;
  border: 1px solid #c1c1c1;

  :first-child {
    background-color: initial;
    border: 0;
  }
`;

DeclineParametersForm.RowTitle = styled.td`
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
  text-align: right;
  padding: 0 10px;
  white-space: nowrap;
  background-color: #f5f5f5;
  border: 1px solid #c1c1c1;
`;

DeclineParametersForm.InputCell = styled.td`
  border: 1px solid #c1c1c1;
`;

DeclineParametersForm.Input = styled.input`
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  text-align: center;
  height: 24px;
  width: 100%;
  border: none;
`;

export default React.memo<DeclineParametersFormProps>(DeclineParametersForm);
