import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getCurrentVarianceOptions,
  getDrilldownLoadingStatus,
  getDrilldownTableTotalRow,
  getSortCriteria,
  getSortDirection,
  getSortedForecastGroupedTable,
  getSortedGroupedTable,
  getSortedVarianceDrilldownTable,
  getSortedWellDrilldownTable,
  getSortedWellForecastDrilldownTable,
  getSortVarDirectionIndex,
} from 'modules/drilldownTable/DrilldownTableReducer';
import {
  addCurrentVarianceOption,
  addSeveralVarianceOptions,
  configureSorting,
  initClearDrilldownTable,
  removeOneVarianceOption,
  resetCurrentVarianceOption,
  setCurrentVarianceOption,
  setMaxDrilldownTableDate,
  setMinDrilldownTableDate,
  setDrilldownTablePhase,
  setDrilldownTableParams,
} from 'modules/drilldownTable/DrilldownTableActions';

import useDrilldownTableState from './useDrilldownTableState';

type DrilldownTableParams = {
  /**
   * Subject from `groupMode` (`groupMode.subject`)
   */
  subject?: string;
  onVarianceTableRowChoose?: () => any;
  onAddVarianceOption?: () => any;
  onAddSeveralVarianceOptionIds?: () => any;
  onRemoveVarianceOption?: () => any;
  onResetVarianceOption?: () => any;
};

export type DrilldownTableData = ReturnType<typeof useDrilldownTable>;

const useDrilldownTable = (params: DrilldownTableParams) => {
  const dispatch = useDispatch();

  const { currentDrilldownTableState, isComparingActual } =
    useDrilldownTableState();
  const currentVarianceOptionIds = useSelector(getCurrentVarianceOptions);
  const wellDrilldownTable = useSelector(getSortedWellDrilldownTable);
  const varianceDrilldownTable = useSelector(getSortedVarianceDrilldownTable);
  const sortCriteria = useSelector(getSortCriteria);
  const sortDirection = useSelector(getSortDirection);
  const sortVarDirectionIndex = useSelector(getSortVarDirectionIndex);
  const drilldownLoadingStatus = useSelector(getDrilldownLoadingStatus);
  const totalRow = useSelector(getDrilldownTableTotalRow);
  const wellForecastTabale = useSelector(getSortedWellForecastDrilldownTable);
  const groupedTable = useSelector(state =>
    getSortedGroupedTable(state, { subject: params.subject }),
  );
  const groupForecastTable = useSelector(state =>
    getSortedForecastGroupedTable(state, { subject: params.subject }),
  );

  const onVarianceTableRowChoose = React.useCallback(
    rowData => {
      dispatch(setCurrentVarianceOption(rowData.varianceOptionId));
      params?.onVarianceTableRowChoose?.();
    },
    [dispatch],
  );

  const addVarianceOption = React.useCallback(
    optionId => {
      dispatch(addCurrentVarianceOption(optionId));
      params?.onAddVarianceOption?.();
    },
    [dispatch],
  );

  const addSeveralVarianceOptionIds = React.useCallback(
    optionIds => {
      dispatch(addSeveralVarianceOptions(optionIds));
      params?.onAddSeveralVarianceOptionIds?.();
    },
    [dispatch],
  );

  const removeVarianceOption = React.useCallback(
    optionId => {
      dispatch(removeOneVarianceOption(optionId));
      params?.onRemoveVarianceOption?.();
    },
    [dispatch],
  );

  const resetVarianceOption = React.useCallback(() => {
    dispatch(resetCurrentVarianceOption());
    params?.onResetVarianceOption?.();
  }, [dispatch]);

  const clear = React.useCallback(() => {
    dispatch(initClearDrilldownTable());
  }, [dispatch]);

  const setMaxDate = React.useCallback(
    date => dispatch(setMaxDrilldownTableDate(date)),
    [dispatch],
  );

  const setMinDate = React.useCallback(
    date => dispatch(setMinDrilldownTableDate(date)),
    [dispatch],
  );

  const setPhase = React.useCallback(
    phase => dispatch(setDrilldownTablePhase(phase)),
    [dispatch],
  );

  const setGrossNet = React.useCallback(
    grossNet => dispatch(setDrilldownTableParams({ grossNet })),
    [dispatch],
  );

  const setRateVolume = React.useCallback(
    rateVolume => dispatch(setDrilldownTableParams({ rateVolume })),
    [dispatch],
  );

  const setCompareOption = React.useCallback(
    compareOption => dispatch(setDrilldownTableParams({ compareOption })),
    [dispatch],
  );

  const setSorting = React.useCallback(
    ({ sortBy, sortDirection }) => {
      dispatch(
        configureSorting({
          sortBy,
          sortDirection,
        }),
      );
    },
    [dispatch],
  );

  return {
    currentDrilldownTableState,
    currentVarianceOptionIds,
    wellDrilldownTable,
    varianceDrilldownTable,
    sortCriteria,
    sortDirection,
    sortVarDirectionIndex,
    drilldownLoadingStatus,
    totalRow,
    wellForecastTabale,
    groupedTable,
    groupForecastTable,
    isComparingActual,

    onVarianceTableRowChoose,
    addVarianceOption,
    addSeveralVarianceOptionIds,
    removeVarianceOption,
    resetVarianceOption,
    clear,
    setMaxDate,
    setMinDate,
    setPhase,
    setGrossNet,
    setRateVolume,
    setSorting,
    setCompareOption,
  };
};

export default useDrilldownTable;
