import * as React from 'react';
import { ExtendedTabInfo } from 'components/Tabs/Model';
import { Tab } from './Tab';
import styled from 'styled-components';
import { SortAmountDownIcon } from 'components/Icons';

export const Drilldown = ({ tab }: { tab?: ExtendedTabInfo }) => {
  return tab ? (
    <Tab onClick={tab.openTab} isActive={tab.isActive}>
      <Drilldown.TabName>{tab.tabName}</Drilldown.TabName>
      <SortAmountDownIcon width={19} height={19} fill="#484848" />
    </Tab>
  ) : null;
};

Drilldown.TabName = styled.div`
  margin-right: 6px;
`;
