import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getBaseSeriesLayoutsArray,
  getCurrentLayout,
  getUserSeriesLayoutsArray,
} from 'modules/seriesLayouts/SeriesLayoutsReducer';
import { setCurrentSeriesLayoutFromRoute } from 'modules/seriesLayouts/SeriesLayoutsActions';

const useSeriesLayouts = () => {
  const dispatch = useDispatch();

  const baseSeriesLayouts = useSelector(getBaseSeriesLayoutsArray);
  const userSeriesLayouts = useSelector(getUserSeriesLayoutsArray);
  const currentSeriesLayout = useSelector(getCurrentLayout);

  const setCurrentSeriesLayout = React.useCallback(
    data => dispatch(setCurrentSeriesLayoutFromRoute(data)),
    [dispatch],
  );

  return {
    baseSeriesLayouts,
    userSeriesLayouts,
    currentSeriesLayout,
    setCurrentSeriesLayout,
  };
};

export default useSeriesLayouts;
