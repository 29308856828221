import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import { HotColumn, HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';

import { HandsontablelicenseKey } from 'config/AppConfig';

const ALLOWED_CELL_EVENT_TYPES = ['edit', 'CopyPaste.paste'];

const generateExtraInputsDataFromTable = (
  data,
  rowHeaders,
  inputsIndexedByLabel,
) => {
  const tableData = data.reduce((acc, item, index) => {
    const label = rowHeaders[index];
    if (!inputsIndexedByLabel[label]) {
      return acc;
    }
    const key = inputsIndexedByLabel[label].name;
    acc[key] = item[0];

    return acc;
  }, {});

  return tableData;
};

interface ExtraInputsProps {
  inputs: { name: string; label: string; readOnly?: boolean }[];
  data: any;
  disabled?: boolean;
  onSubmit: (data: any) => void;
}

const ExtraInputs = ({
  disabled,
  inputs,
  data,
  onSubmit,
}: ExtraInputsProps) => {
  const inputsIndexedByName = React.useMemo(
    () => R.indexBy(R.prop('name'), inputs),
    [inputs],
  );
  const inputsIndexedByLabel = React.useMemo(
    () => R.indexBy(R.prop('label'), inputs),
    [inputs],
  );
  const [initialData, setInitialData] = React.useState(data);

  const initialExtraInputsData = React.useMemo(
    () =>
      inputs.reduce((acc, input) => {
        if (!initialData) {
          acc[input.name] = '';
        } else {
          acc[input.name] = initialData[input.name] || '';
        }

        return acc;
      }, {}),
    [inputs, initialData],
  );

  const rowHeaders = React.useMemo(
    () =>
      initialExtraInputsData
        ? Object.keys(initialExtraInputsData).map(
            key => inputsIndexedByName[key].label,
          )
        : [],
    [initialExtraInputsData, inputsIndexedByName],
  );
  const values = React.useMemo(
    () =>
      initialExtraInputsData
        ? Object.entries(initialExtraInputsData).map(([key, value]) => [value])
        : [],
    [initialExtraInputsData],
  );
  const tableRef = React.useRef<any>(null);

  const onBeforeCopy = React.useCallback((data, coords) => {
    if (!!tableRef.current) {
      const t = tableRef.current.hotInstance;
      const highlightedHeader = document.querySelectorAll(
        '.ht__active_highlight',
      );
      if (highlightedHeader.length > 0) {
        const range = t.getSelectedRange()[0];
        const sRow = range.from.row;
        const eRow = range.to.row;
        const rowHeaders = t.getRowHeader().slice(sRow, eRow + 1);

        data.forEach((row, i) => row.unshift(rowHeaders[i]));
      }
    }
  }, []);

  const onBeforeChange = React.useCallback(
    (_, eventType) => {
      if (!ALLOWED_CELL_EVENT_TYPES.includes(eventType)) return;
      const table = tableRef.current;
      if (table) {
        const hotInstance = table.hotInstance;
        const data = hotInstance.getData();
        const tableData = generateExtraInputsDataFromTable(
          data,
          table.props.rowHeaders,
          inputsIndexedByLabel,
        );
        onSubmit(tableData);
      }
    },
    [inputsIndexedByLabel, onSubmit],
  );

  React.useEffect(() => {
    setInitialData(data);
  }, [data]);

  // set readOly flag for cell
  React.useLayoutEffect(() => {
    if (!!tableRef.current) {
      const t = tableRef.current.hotInstance;
      inputs.forEach((input, i) => {
        if (input.readOnly) {
          t.setCellMetaObject(i, 0, { readOnly: true, className: 'readOnly' });
        }
      });
      t.render();
    }

    setTimeout(() => tableRef.current?.hotInstance?.render(), 0);
  });

  return (
    <ExtraInputs.Container>
      <HotTable
        ref={tableRef}
        data={values}
        rowHeaders={rowHeaders}
        rowHeaderWidth={120}
        licenseKey={HandsontablelicenseKey}
        height="auto"
        fillHandle={false}
        afterChange={onBeforeChange}
        beforeCopy={onBeforeCopy}
        beforeCut={onBeforeCopy}
        selectionMode="range"
        colWidths="100%"
        afterGetRowHeader={(_, TH) => {
          TH.className = 'rowHeader';
        }}
        stretchH="all"
      >
        <HotColumn readOnly={disabled} />
      </HotTable>
    </ExtraInputs.Container>
  );
};

ExtraInputs.Container = styled.div`
  &&& {
    max-height: 400px;

    .wtHider {
      max-width: 0;
    }

    .readOnly {
      background: #f0f0f0;
    }

    .rowHeader {
      text-align: left;

      > div {
        height: 100%;
        display: grid;
        align-items: center;
      }
    }
  }

  .handsontable th,
  .handsontable td {
    white-space: normal;
    vertical-align: middle;
    line-height: 20px;
  }
`;

export default React.memo<ExtraInputsProps>(
  ExtraInputs,
  (prev, props) =>
    JSON.stringify(prev.data) === JSON.stringify(props.data) &&
    JSON.stringify(prev.inputs) === JSON.stringify(props.inputs),
);
