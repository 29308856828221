import {
  utcDay,
  timeMinute,
  timeDay,
  utcMinute,
  utcMillisecond,
  TimeInterval,
} from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';

import { isInside } from 'modules/chart/utils';
import { ListChartOptions } from 'modules/chartOptions/models';
import type { DataSeriesTooltipData, TooltipData } from 'modules/ui/models/ui';

import { Series, NormalizedSeriesMapping } from '../models';

interface SeriesSelectedBarProps {
  chartOptions: ListChartOptions;
  dataMap: { [id: string]: Series[] };
  groupOptions: string[];
  onSetTooltipData: (tooltipData: DataSeriesTooltipData | null) => void;
  seriesMapping: NormalizedSeriesMapping;
  svgBoundingRect: Record<string, any>;
  tooltipData: TooltipData | null;
  xScale: any;
  yScale: any;
  availableSensorSeriesDates: { [key: string]: { min: Date; max: Date } };
}

const SeriesSelectedBar = ({
  chartOptions,
  dataMap,
  groupOptions,
  onSetTooltipData,
  seriesMapping,
  svgBoundingRect,
  tooltipData,
  xScale,
  yScale,
  availableSensorSeriesDates,
}: SeriesSelectedBarProps): JSX.Element => {
  const dataIsEmpty =
    R.isNil(tooltipData?.dataSeriesTooltipData) &&
    R.isNil(tooltipData?.trellisTooltipData);
  const date = React.useMemo(
    () =>
      R.pathOr('', ['dataSeriesTooltipData', 'day'], tooltipData) ||
      R.pathOr('', ['trellisTooltipData', 'day'], tooltipData),
    [tooltipData],
  );

  const containsSensor = React.useMemo(
    () => groupOptions.some(o => o.startsWith('s')),
    [groupOptions],
  );

  const chartAreaCoords = React.useMemo(
    () => ({
      x1: svgBoundingRect.x,
      x2: svgBoundingRect.x + svgBoundingRect.width,
      y1: 55,
      y2: window.innerHeight - 35,
    }),
    [svgBoundingRect],
  );

  const trellisAreaCoords = React.useMemo(
    () => ({
      x1: svgBoundingRect.x,
      x2: svgBoundingRect.x + svgBoundingRect.width,
      y1: svgBoundingRect.y,
      y2: svgBoundingRect.y + svgBoundingRect.height,
    }),
    [svgBoundingRect],
  );

  const getSeriesIdFromOption = React.useCallback(
    (id: string) => {
      return id.startsWith('s') ? id : `series${seriesMapping[id].seriesIndex}`;
    },
    [seriesMapping],
  );

  const dayDateRange = React.useMemo(
    () =>
      groupOptions.reduce(
        (acc, seriesId) => {
          const isSensor = seriesId.startsWith('s');

          if (
            !date ||
            (isSensor &&
              (!availableSensorSeriesDates[seriesId] ||
                date.getTime() >
                  availableSensorSeriesDates[seriesId].max.getTime()))
          ) {
            return acc;
          }
          const nearestSensorSeriesDates = tooltipData?.trellisTooltipData
            ? (utcMinute.every(1) as TimeInterval).range(
                utcDay.offset(date, -1),
                utcDay.offset(date, 1),
              )
            : (utcMinute.every(1) as TimeInterval)
                .range(
                  utcDay.offset(date, -1),
                  utcMinute.offset(utcMinute.floor(date), 1),
                )
                .reverse();

          const existingDate = isSensor
            ? nearestSensorSeriesDates.find(
                d =>
                  R.path(
                    [d.toISOString(), getSeriesIdFromOption(seriesId)],
                    dataMap,
                  ) !== undefined,
              )
            : utcDay.floor(date);

          if (!existingDate) return acc;
          const tempData = R.pathOr(
            0,
            [existingDate.toISOString(), getSeriesIdFromOption(seriesId)],
            dataMap,
          );
          if (tempData > acc.max) {
            acc.max = tempData;
          }
          if (tempData < acc.min) {
            acc.min = tempData;
          }
          return acc;
        },
        {
          min: 0,
          max: -1000,
        },
      ),
    [
      dataMap,
      date,
      groupOptions,
      availableSensorSeriesDates,
      tooltipData?.trellisTooltipData,
    ],
  );

  const selectedBarPoints = React.useMemo(() => {
    const pointerDate =
      tooltipData?.trellisTooltipData || !containsSensor
        ? utcDay.floor(date)
        : date;

    const y = yScale(dayDateRange.max);
    const height = yScale(dayDateRange.min) - y + 6;

    const x = xScale(pointerDate || new Date());
    const delta = utcMillisecond.count(xScale.domain()[0], xScale.domain()[1]);
    const timeDelta =
      containsSensor && tooltipData?.dataSeriesTooltipData
        ? delta / (60 * 1000)
        : delta / (24 * 3600 * 1000);

    const barWidth = xScale.range()[1] / timeDelta;
    const width = barWidth + 6;
    return { height, y, x, width };
  }, [
    yScale,
    dayDateRange,
    date,
    xScale,
    containsSensor,
    tooltipData,
    containsSensor,
  ]);

  const mouseMoveListenerD = React.useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const currentPointerPosition = {
        clientX,
        clientY,
      };

      const currentPointerIsInsideTrellis = isInside(
        currentPointerPosition,
        trellisAreaCoords,
      );
      if (!currentPointerIsInsideTrellis) return;

      const value = yScale.invert(
        currentPointerPosition.clientY - trellisAreaCoords.y1,
      );

      const pointerDate = utcMinute.floor(
        xScale.invert(currentPointerPosition.clientX - chartAreaCoords.x1),
      );

      const today = utcDay.floor(
        timeMinute.offset(
          timeDay.floor(new Date()),
          -new Date().getTimezoneOffset(),
        ),
      );

      const nearestSensorSeriesDates = (utcMinute.every(1) as TimeInterval)
        .range(
          utcDay.offset(pointerDate, -1),
          utcMinute.offset(utcMinute.floor(pointerDate), 1),
        )
        .reverse();

      const curretnDayDateRange = groupOptions.reduce(
        (acc, seriesId) => {
          const isSensor = seriesId.startsWith('s');
          if (
            isSensor &&
            (!availableSensorSeriesDates[seriesId] ||
              pointerDate.getTime() >
                availableSensorSeriesDates[seriesId].max.getTime())
          )
            return acc;

          const date = isSensor
            ? nearestSensorSeriesDates.find(
                d =>
                  R.path(
                    [d.toISOString(), getSeriesIdFromOption(seriesId)],
                    dataMap,
                  ) !== undefined,
              )
            : utcDay.floor(pointerDate);
          if (!date) return acc;

          const tempData = R.pathOr(
            0,
            [date.toISOString(), getSeriesIdFromOption(seriesId)],
            dataMap,
          );

          if (tempData > acc.max) {
            acc.max = tempData;
          }
          if (tempData < acc.min) {
            acc.min = tempData;
          }
          return acc;
        },
        {
          min: 0,
          max: 0,
        },
      );

      if (
        utcDay.count(pointerDate, today) < 0 ||
        value > curretnDayDateRange.max ||
        value < curretnDayDateRange.min
      ) {
        onSetTooltipData(null);
        return;
      }
      const dataForTooltip = groupOptions.reduce(
        (acc, seriesId) => {
          const name = seriesMapping[seriesId]?.displayName;
          const color = (chartOptions[seriesId].customColor ||
            chartOptions[seriesId].color) as string;
          const date = seriesId.startsWith('s')
            ? nearestSensorSeriesDates.find(
                d =>
                  R.path(
                    [d.toISOString(), getSeriesIdFromOption(seriesId)],
                    dataMap,
                  ) !== undefined,
              )
            : utcDay.floor(pointerDate);
          if (!date) return acc;
          const value = R.pathOr(
            0,
            [date.toISOString(), getSeriesIdFromOption(seriesId)],
            dataMap,
          );
          acc.data.push({
            name,
            color,
            value,
          });
          return acc;
        },
        {
          day: pointerDate,
          clientX,
          clientY,
          data: [],
        } as DataSeriesTooltipData,
      );

      onSetTooltipData(dataForTooltip);
    },
    [
      chartAreaCoords,
      chartOptions,
      dataMap,
      groupOptions,
      onSetTooltipData,
      seriesMapping,
      trellisAreaCoords,
      containsSensor,
      xScale,
      yScale,
      availableSensorSeriesDates,
    ],
  );
  const mouseLeaveListener = React.useCallback(
    () => onSetTooltipData(null),
    [onSetTooltipData],
  );

  React.useEffect(() => {
    const trellisesWrappers: NodeListOf<Element> =
      document.querySelectorAll('.trellises-wrapper');
    const trellisesWrappersArray = Array.from(trellisesWrappers);

    trellisesWrappersArray.forEach(elem => {
      elem.addEventListener('mousemove', mouseMoveListenerD as EventListener);
      elem.addEventListener('mouseleave', mouseLeaveListener);
    });
    return () =>
      trellisesWrappersArray.forEach(elem => {
        elem.removeEventListener(
          'mousemove',
          mouseMoveListenerD as EventListener,
        );
        elem.removeEventListener('mouseleave', mouseLeaveListener);
      });
  }, [mouseMoveListenerD, mouseLeaveListener]);

  return (
    <>
      {!dataIsEmpty ? (
        <rect
          width={selectedBarPoints.width}
          height={selectedBarPoints.height}
          x={selectedBarPoints.x - 3}
          y={selectedBarPoints.y - 3}
          strokeWidth="1"
          stroke="black"
          fill="transparent"
          pointerEvents="none"
        />
      ) : null}
    </>
  );
};

export default SeriesSelectedBar;
