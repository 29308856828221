import {
  countBoeValue,
  countGorValue,
  countWatercutValue,
  countGlrValue,
  countTotalLiquidValue,
} from 'modules/production/utils';

import { ForecastData, ForecastDBPoint } from '../models';

const normalizeForecastData = (groupData: ForecastDBPoint[]): ForecastData => {
  const withIndicators = groupData.map(groupForecastDataPoint => {
    const boe = countBoeValue(
      groupForecastDataPoint.oil,
      groupForecastDataPoint.gas,
    );

    const gor = countGorValue(
      groupForecastDataPoint.oil,
      groupForecastDataPoint.gas,
    );

    const watercut = countWatercutValue(
      groupForecastDataPoint.oil,
      groupForecastDataPoint.water,
    );

    const glr = countGlrValue(
      groupForecastDataPoint.oil,
      groupForecastDataPoint.gas,
      groupForecastDataPoint.gas,
    );
    const total_liquid = countTotalLiquidValue(
      groupForecastDataPoint.oil,
      groupForecastDataPoint.water,
    );

    return {
      ...groupForecastDataPoint,
      boe,
      gor,
      watercut,
      glr,
      total_liquid,
      day: new Date(groupForecastDataPoint.day),
    };
  });
  const sorted = withIndicators.sort(
    (a: ForecastDBPoint, b: ForecastDBPoint) =>
      a.day.getTime() - b.day.getTime(),
  );

  return sorted;
};

export default normalizeForecastData;
