import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  createRibbonEventLocally,
  createRemoteRibbonEvent,
  deleteRibbonEventLocally,
  updateRibbonEventDatesLocally,
  updateRibbonEventOptionLocally,
  updateRibbonEventNoEndDateLocally,
  updateRibbonEventExtraInputsLocally,
} from 'modules/ribbon/RibbonActions';
import { getCurrentWellId } from 'modules/ui/UIReducer';

const useRibbonEvent = () => {
  const dispatch = useDispatch();
  const wellId = useSelector(getCurrentWellId);

  const createLocalRibbonEvent = React.useCallback(
    date => {
      dispatch(createRibbonEventLocally({ wellId, date }));
    },
    [wellId, dispatch],
  );

  const createRibbonEvent = React.useCallback(
    ribbonEvent => {
      const newEvent = R.omit(['id', 'locallyCreatedAt'], ribbonEvent);
      dispatch(createRemoteRibbonEvent(newEvent));
    },
    [dispatch],
  );

  const updateRibbonEventDates = React.useCallback(
    ({ dates, ribbonEventId }) => {
      const payload = { wellId, ribbonEventId, dates };
      dispatch(updateRibbonEventDatesLocally(payload));
    },
    [wellId, dispatch],
  );

  const updateRibbonEventOption = React.useCallback(
    ({ ribbonEventId, ribbonOptionId }) => {
      const payload = { wellId, ribbonOptionId, ribbonEventId };
      dispatch(updateRibbonEventOptionLocally(payload));
    },
    [wellId, dispatch],
  );

  const updateRibbonEventNoEndDate = React.useCallback(
    ({ ribbonEventId, noEndDate }) => {
      const payload = { wellId, ribbonEventId, noEndDate };
      dispatch(updateRibbonEventNoEndDateLocally(payload));
    },
    [wellId, dispatch],
  );

  const updateRibbonEventExtraInputs = React.useCallback(
    ({ ribbonEventId, extraInputsData }) => {
      const payload = { wellId, ribbonEventId, extraInputsData };
      dispatch(updateRibbonEventExtraInputsLocally(payload));
    },
    [wellId, dispatch],
  );

  const deleteRibbonEvent = React.useCallback(
    ribbonEventId => {
      dispatch(deleteRibbonEventLocally({ wellId, ribbonEventId }));
    },
    [wellId, dispatch],
  );

  return {
    createLocalRibbonEvent,
    createRibbonEvent,
    updateRibbonEventDates,
    updateRibbonEventOption,
    updateRibbonEventNoEndDate,
    updateRibbonEventExtraInputs,
    deleteRibbonEvent,
  };
};

export default useRibbonEvent;
