import Logo from 'components/Logo';
import { ExtendedTabInfo } from 'components/Tabs/Model';
import { Tab } from './Tab';

export const Menu = ({ tab }: { tab?: ExtendedTabInfo }) => {
  return tab ? (
    <Tab isActive={tab.isActive} onClick={tab.openTab}>
      <Logo noText width={41} />
    </Tab>
  ) : null;
};
