import * as R from 'ramda';
import { all, put, takeLatest, select, take, fork } from 'redux-saga/effects';

import { AUTHENTICATED } from 'modules/app/AppActions';
import {
  SET_CHART_WIDTH,
  SET_CURRENT_WELL_ID,
  SET_WAS_DRAGGING,
  START_X_AXIS_DRAGGING,
  STOP_X_AXIS_DRAGGING,
} from 'modules/ui/UIActions';
import { CHANGE_EXTREME_DATES } from 'modules/production/ProductionActions';
import { getChartWidth, getCurrentWellId } from 'modules/ui/UIReducer';
import { getColumnMapping } from 'modules/well/WellReducer';
import {
  decodeDataSeries,
  decodeSensorSeries,
  parseSearchParams,
} from 'modules/router/utils/router';

import {
  FETCH_GROUP_SERIES,
  fetchSeriesMapping,
  setGroupSeries,
  REFETCH_SERIES,
  fetchWellSeriesDateRange,
  fetchSensorSeriesMapping,
  FETCH_SENSOR_SERIES,
  populateSensorSeries,
  fetchSensorSeriesSliced,
  FETCH_SENSOR_SERIES_MAPPING,
  INIT_FETCH_SENSOR_SERIES,
  clearSeries,
  initFetchSensorSeries,
  REFETCH_SENSOR_SERIES,
  refetchSensorSeries,
  FETCH_SENSOR_SERIES_SLICES,
  fetchSensorSeries,
} from './SeriesActions';
import {
  getSensorSeriesMapping,
  getAllSeriesByWell,
  getSensorSeriesAvailableRange,
} from './SeriesReducer';
import { normalizeSeries } from './utils';

import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import {
  getCoreSeriesOptionsToDisplay,
  getDataSeriesGroupsToDisplay,
  getDataSeriesGroupsWithColorAndTypeToDisplay,
} from 'modules/chartOptions/ChartOptionsReducer';
import { normalizeSensorSeries } from './utils/normalizeSensorSeries';
import {
  setCoreSeriesFromRoute,
  setDataSeriesFromRoute,
} from 'modules/chartOptions/ChartOptionsActions';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import { SET_APP_CONFIG_LOCALLY } from 'modules/appConfig/AppConfigActions';
import { setCurrentSeriesLayoutFromRoute } from 'modules/seriesLayouts/SeriesLayoutsActions';
import {
  getCurrentLayout,
  getCurrentSeriesLayoutData,
} from 'modules/seriesLayouts/SeriesLayoutsReducer';

import { utcMinute } from 'd3-time';
import { MAGNIFICATION_FACTOR } from './models';

function* fetchSeriesMappingSaga(): Generator<any, any, any> {
  yield put(fetchSeriesMapping());
  yield put(fetchSensorSeriesMapping());
}

function* initFetchWellSeriesSaga(): Generator<any, any, any> {
  // TODO: handle the case when the series is disabled
  // const appConfig = yield select(getAppConfig);
  // if (!appConfig.timeSeries) {
  //   console.error('2', appConfig);

  //   return;
  // }
  const currentWellId = yield select(getCurrentWellId);
  if (!currentWellId) {
    return;
  }
  const currentWellSeries = yield select(state =>
    getAllSeriesByWell(state, { wellId: currentWellId }),
  );
  const groupToDisplay = yield select(getDataSeriesGroupsToDisplay);
  const seriesToDisplay = groupToDisplay.reduce(
    (acc, group) => acc.concat(group.ids.filter(id => !id.startsWith('s'))),
    [],
  );

  if (R.isEmpty(currentWellSeries.data)) {
    const extemeDates = yield select(getExtremeDates);
    const searchString = window.location.search;
    const routeSearchParams = parseSearchParams(searchString);
    const dateSource = R.isNil(extemeDates) ? routeSearchParams : extemeDates;

    yield put(
      fetchWellSeriesDateRange({
        wellId: currentWellId,
        minDate: R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        maxDate: R.pathOr(new Date(), ['max'], dateSource),
        series: seriesToDisplay,
      }),
    );
  }
}

function* fetchSensorSeriesSaga(action): Generator<any, any, any> {
  const currentWellId = yield select(getCurrentWellId);

  if (!currentWellId) return;
  const { series: seriesFilterList, chartWidth } = action.payload;

  if (chartWidth <= 0) {
    const chartWidth = (yield take(SET_CHART_WIDTH)).payload;
    return yield fetchSensorSeriesSaga({
      ...action,
      payload: { ...action.payload, chartWidth },
    });
  }

  const extremeDates = yield select(getExtremeDates);
  const searchString = window.location.search;
  const routeSearchParams = parseSearchParams(searchString);
  const dateSource = R.isNil(extremeDates) ? routeSearchParams : extremeDates;
  let seriesMapping = yield select(getSensorSeriesMapping);

  const layout = yield select(getCurrentLayout);
  const layoutData = yield select(getCurrentSeriesLayoutData);

  const configuration =
    layout?.configuration ?? layoutData?.configuration ?? '';

  const sensorSeriesString = configuration.match(/(?<=sensorseries=)[^&]+&??/);
  const dataSeriesString = configuration.match(/(?<=dataseries=)[^&]+&??/);

  const sensorSeries = sensorSeriesString
    ? decodeSensorSeries(sensorSeriesString[0]).map(s =>
        s.dataSeriesGroup.map(g => g.id),
      )
    : [];
  const dataSeries = dataSeriesString
    ? decodeDataSeries(dataSeriesString[0]).map(s =>
        s.dataSeriesGroup.filter(g => g.id.startsWith('s')).map(g => g.id),
      )
    : [];
  const series = sensorSeries.concat(dataSeries).flat();

  if (R.isEmpty(seriesMapping) || seriesMapping === null) {
    yield take(`${FETCH_SENSOR_SERIES_MAPPING}_SUCCESS`);
    seriesMapping = yield select(getSensorSeriesMapping);
    if (R.isEmpty(seriesMapping)) return;
  }

  const filteredSeries: string[] = (seriesFilterList ?? series).filter(
    id => !!seriesMapping[id],
  );

  const tagIds = filteredSeries.reduce<{ [key: string]: string }>((acc, id) => {
    const tagId = seriesMapping[id].tags.find(
      t => t.wellId === currentWellId,
    )?.tagId;

    if (tagId) acc[id] = tagId;
    return acc;
  }, {});

  if (Object.keys(tagIds).length === 0) return;

  for (const [seriesId, tagId] of Object.entries(tagIds)) {
    yield put(
      fetchSensorSeries({
        tagId,
        minDate: utcMinute.floor(
          R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        ),
        maxDate: utcMinute.ceil(R.pathOr(new Date(), ['max'], dateSource)),
        pointsCount: +chartWidth.toFixed(0),
        seriesId,
        wellId: currentWellId,
      }),
    );
  }
}

function* initFetchSensorSeriesAfterPanningSaga(
  action,
): Generator<any, any, any> {
  let chartWidth = yield select(getChartWidth);

  if (chartWidth === 0) {
    while (true) {
      const action = yield take(SET_CHART_WIDTH);
      if (action.payload !== 0) {
        chartWidth = action.payload;
        break;
      }
    }
  }

  if (action.type === START_X_AXIS_DRAGGING) {
    const { type } = yield take([STOP_X_AXIS_DRAGGING, SET_WAS_DRAGGING]);
    if (type === STOP_X_AXIS_DRAGGING) return;
    yield take(STOP_X_AXIS_DRAGGING);

    const extremeDates = yield select(getExtremeDates);
    const timeDelta = utcMinute.count(extremeDates.min, extremeDates.max);
    const offset = timeDelta / 4;
    const availableSeriesRange = yield select(getSensorSeriesAvailableRange);

    if (
      !availableSeriesRange ||
      (utcMinute.count(availableSeriesRange.min, extremeDates.min) > offset &&
        utcMinute.count(extremeDates.max, availableSeriesRange.max) > offset)
    )
      return;

    return yield put(refetchSensorSeries({ chartWidth }));
  }

  yield put(
    refetchSensorSeries({
      chartWidth,
    }),
  );
}
// temporarily disable series for group mode
// function* initFetchGrouSeriesSaga(action): Generator<any, any, any> {
//   const { payload } = action;
//   const appConfig = yield select(getAppConfig);
//   if (!appConfig.timeSeries) {
//     return;
//   }
//   const groupMode = yield select(getGroupMode);
//   if (!groupMode.isOn) return;
//   const columnMapping = yield select(getColumnMappingBySource);
//   if (!columnMapping || R.isEmpty(columnMapping)) return;
//   const filters = yield select(getFilters);
//   const filtersWithoutEmpty = R.pickBy(isNotEmpty, filters);
//   const normalizedFilters = Object.keys(filtersWithoutEmpty).map(filterName => {
//     const columnIndex = columnMapping[filterName].columnIndex;

//     return {
//       columnIndex,
//       values: filtersWithoutEmpty[filterName],
//     };
//   });
//   const currentGroup = yield select(getCurrentGroup);
//   const subject = !R.isNil(payload.subject)
//     ? payload.subject
//     : currentGroup.subject;
//   const item = !R.isNil(payload.item)
//     ? payload.item === 'empty'
//       ? ''
//       : payload.item
//     : currentGroup.item;

//   if (R.isNil(subject) || R.isNil(item)) {
//     return;
//   }

//   const groupSeries = yield select(getGroupedSeries, { subject, item });
//   if (!R.isEmpty(groupSeries)) {
//     return;
//   }
//   const column = columnMapping[subject];
//   const group =
//     !!column && !R.isEmpty(column)
//       ? { name: item, columnIndex: column.columnIndex }
//       : null;
//   yield delay(700);

//   yield put(
//     fetchGroupSeries({
//       group,
//       filters: normalizedFilters,
//     }),
//   );
// }

function* normalizeGroupSeriesSaga(action): Generator<any, any, any> {
  const { seriesChartData } = getGraphqlPayload(action);
  const { groupData } = JSON.parse(seriesChartData);
  const prevActionPayload = getGraphqlPrevActionVariables(action);
  const {
    payload: { group },
  } = prevActionPayload;
  const columnMapping = yield select(getColumnMapping);
  const subject = group
    ? columnMapping.find(column => column.columnIndex === group.columnIndex)
        .sourceName
    : 'all';
  const groupName = group ? group.name : 'all';
  const normalizedSeries = normalizeSeries(groupData);
  yield put(
    setGroupSeries({
      groupSubject: subject,
      groupName: groupName,
      series: normalizedSeries,
    }),
  );
}

function* clearSensorSeriesSaga(action): Generator<any, any, any> {
  yield put(clearSeries());
  const chartWidth = yield select(getChartWidth);
  yield put(initFetchSensorSeries({ chartWidth }));
}

function* normalizeSensorSeriesSaga(action): Generator<any, any, any> {
  const seriesId = action.meta.previousAction.meta.seriesId;
  const requestedWellId = action.meta.previousAction.meta.wellId;

  const wellId = yield select(getCurrentWellId);
  if (requestedWellId !== wellId) return;
  const sensorSeriesData = JSON.parse(getGraphqlPayload(action).data);

  const seriesMapping = yield select(getSensorSeriesMapping);

  const normalizedSeries = normalizeSensorSeries(
    sensorSeriesData,
    wellId,
    seriesMapping,
  );

  yield put(
    populateSensorSeries({
      seriesId,
      data: normalizedSeries,
    }),
  );
}

function* fetchSlicedSensorSeries(action): Generator<any, any, any> {
  const appConfig = yield select(getAppConfig);
  const sevenXLoading = appConfig['7xLoading'];
  if (!sevenXLoading) return;

  const extremeDates = yield select(getExtremeDates);
  const currentWellId = yield select(getCurrentWellId);
  const seriesMapping = yield select(getSensorSeriesMapping);
  const chartWidth = yield select(getChartWidth);
  const sensorId = action.meta.previousAction.meta.seriesId;
  const searchString = window.location.search;
  const routeSearchParams = parseSearchParams(searchString);
  const dateSource = R.isNil(extremeDates) ? routeSearchParams : extremeDates;
  const sensorTagId: string = seriesMapping[sensorId].tags.find(
    t => t.wellId === currentWellId,
  )?.tagId;

  if (!sensorTagId) return;

  yield put(
    fetchSensorSeriesSliced({
      tagId: sensorTagId,
      extremeDates: {
        min: utcMinute.floor(
          R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        ),
        max: utcMinute.ceil(R.pathOr(new Date(), ['max'], dateSource)),
      },
      pointsCount: +chartWidth.toFixed(0),
      seriesId: sensorId,
      slices: MAGNIFICATION_FACTOR,
      wellId: currentWellId,
    }),
  );
}

function* fetchSensorSeriesAfterEnablingSaga(): Generator<any, any, any> {
  while (true) {
    const previous = { ...(yield select(getAppConfig)) };
    const next = yield take(SET_APP_CONFIG_LOCALLY);

    if (previous.sensorSeries === next.payload.sensorSeries) continue;

    if (next.payload.sensorSeries !== true) {
      const layout = yield select(getCurrentLayout);
      yield put(setCurrentSeriesLayoutFromRoute(layout));
      continue;
    }

    const chartWidth = yield select(getChartWidth);
    const series = yield select(getDataSeriesGroupsWithColorAndTypeToDisplay);

    const sortedSeries = series.reduce(
      (acc, n) => {
        if (n.dataSeriesGroup.some(o => !o.id.startsWith('s')))
          acc.data.push(n);
        else acc.sensor.push(n);

        return acc;
      },
      { data: [], sensor: [] },
    );
    const coreSeries = yield select(getCoreSeriesOptionsToDisplay);

    yield put(setCoreSeriesFromRoute({ series: coreSeries.map(s => s.id) }));
    yield put(
      setDataSeriesFromRoute({
        dataSeries: sortedSeries.data,
        sensorSeries: sortedSeries.sensor,
      }),
    );
    yield put(refetchSensorSeries({ chartWidth }));
  }
}

function* SeriesSaga(): Generator<any, any, any> {
  yield all([
    takeLatest(AUTHENTICATED, fetchSeriesMappingSaga),
    takeLatest([SET_CURRENT_WELL_ID, REFETCH_SERIES], initFetchWellSeriesSaga),
    takeLatest(
      [START_X_AXIS_DRAGGING, CHANGE_EXTREME_DATES],
      initFetchSensorSeriesAfterPanningSaga,
    ),
    takeLatest(
      [INIT_FETCH_SENSOR_SERIES, REFETCH_SENSOR_SERIES],
      fetchSensorSeriesSaga,
    ),
    fork(fetchSensorSeriesAfterEnablingSaga),
    takeLatest(SET_CURRENT_WELL_ID, clearSensorSeriesSaga),
    //temporarily disable series for group mode
    // takeLatest(
    //   [SET_CURRENT_GROUP, REFETCH_GROUP_SERIES],
    //   initFetchGrouSeriesSaga,
    // ),
    takeLatest(`${FETCH_GROUP_SERIES}_SUCCESS`, normalizeGroupSeriesSaga),
    takeLatest(
      [
        `${FETCH_SENSOR_SERIES}_SUCCESS`,
        `${FETCH_SENSOR_SERIES_SLICES}_SUCCESS`,
      ],
      normalizeSensorSeriesSaga,
    ),
    takeLatest(`${FETCH_SENSOR_SERIES}_SUCCESS`, fetchSlicedSensorSeries),
  ]);
}

export default SeriesSaga;
