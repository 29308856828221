import { line } from 'd3-shape';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import { countCapacity } from 'modules/capacityChangeEvent/utils';

import { getRateHandleClipPathId } from '../../utils';
import {
  Phase,
  useCapacityChange,
  useCapacityData,
} from 'modules/wellChart/context/capacityVariance';

const B_KEYCODE = 66;

interface InteractiveCapacityLineProps {
  capacityDialog: { show: boolean; index: number };
  capacityEvent: CapacityChangeEvent;
  capacitySegment: { date: Date; capacity: number }[];
  finishDrag: () => void;
  grossNet: string;
  isEditable: boolean;
  nri: number;
  onBFactorDrag: (data: {
    phase: string;
    capacityEventId: string;
    rate: number;
    date: Date;
  }) => void;
  onCapacityDialogOpen: (index: number, eventId: string) => void;
  onDeclineInitChange: (data: {
    phase: string;
    capacityEventId: string;
    rate: number;
    date: Date;
  }) => void;
  onRateInitChange: (data: {
    phase: string;
    newRate: number;
    capacityEventId: string;
  }) => void;
  pointIndex: number;
  startDrag: () => void;
  trellisTitle: string;
  xScale: any;
  yScale: any;
  svgSizes: ClientRect | null;
}

const InteractiveCapacityLine = ({
  capacityDialog,
  capacityEvent,
  capacitySegment: seg,
  finishDrag,
  grossNet,
  isEditable,
  nri,
  onBFactorDrag,
  onCapacityDialogOpen,
  onDeclineInitChange,
  onRateInitChange,
  pointIndex,
  startDrag,
  trellisTitle,
  xScale,
  yScale,
  svgSizes,
}: InteractiveCapacityLineProps) => {
  const dispatch = useDispatch();
  const [isBPressed, setBKeyPressing] = React.useState(false);
  const myStateRef = React.useRef(0);
  const setMyState = data => {
    myStateRef.current = data;
  };
  const grossNetCoefficient = grossNet === 'Net' ? nri : 1;
  const capacityContext = useCapacityChange({ eventId: capacityEvent.id });
  const capacityData = useCapacityData();
  const updatedEvent = React.useMemo(
    () => capacityData.getEventById(capacityEvent.id),
    [capacityData.getEventById],
  );
  const capacitySegment = capacityData.getSingleByPhase(
    trellisTitle as Phase,
    capacityEvent.id,
  );
  const { isLockSlopeCapacityLine } = capacityEvent;

  const handleDeclineMouseMove = React.useCallback(
    (e: MouseEvent) => {
      const { id } = capacityEvent;
      if (!id || !svgSizes) return;
      const newYPosition = e.pageY - svgSizes.top;
      const xPosition = e.pageX - svgSizes.left;
      const newRate =
        yScale.invert(newYPosition) / grossNetCoefficient - myStateRef.current;
      const dateOfMovement = xScale.invert(xPosition);

      capacityContext.changeDecline(
        trellisTitle as Phase,
        dateOfMovement,
        newRate,
      );
    },
    [
      dispatch,
      myStateRef,
      grossNetCoefficient,
      svgSizes,
      capacityEvent,
      capacityContext.changeDecline,
      trellisTitle,
      xScale,
      yScale,
    ],
  );

  const handleDeclineMouseUp = React.useCallback(() => {
    capacityContext.finishEditingCurve();
    finishDrag();
    document.removeEventListener('mousemove', handleDeclineMouseMove);
    document.removeEventListener('mouseup', handleDeclineMouseUp);
  }, [capacityContext.finishEditingCurve, finishDrag, handleDeclineMouseMove]);

  const handleRateMouseMove = React.useCallback(
    (e: MouseEvent) => {
      const { id } = capacityEvent;
      if (!id || !svgSizes) return;
      const newYPosition = e.pageY - svgSizes.top;
      const newRate =
        Math.max(yScale.invert(newYPosition), 0) / grossNetCoefficient;
      capacityContext.changeInitialRate(trellisTitle as Phase, newRate);
    },
    [
      capacityEvent,
      capacityContext.changeInitialRate,
      onRateInitChange,
      svgSizes,
      yScale,
      trellisTitle,
      grossNetCoefficient,
    ],
  );

  const handleRateMouseUp = React.useCallback(() => {
    capacityContext.finishEditingCurve();
    finishDrag();
    document.removeEventListener('mousemove', handleRateMouseMove);
    document.removeEventListener('mouseup', handleRateMouseUp);
  }, [capacityContext.finishEditingCurve, finishDrag, handleRateMouseMove]);

  const handleRateMouseDown = React.useCallback(
    (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
      if (isEditable) {
        startDrag();
        if (!capacityDialog.show || capacityDialog.index !== pointIndex) {
          onCapacityDialogOpen(pointIndex, capacityEvent.id);
        }
        document.addEventListener('mousemove', handleRateMouseMove);
        document.addEventListener('mouseup', handleRateMouseUp);
      } else {
        onCapacityDialogOpen(pointIndex, capacityEvent.id);
      }
    },
    [
      isEditable,
      capacityEvent,
      capacityDialog.index,
      capacityDialog.show,
      handleRateMouseMove,
      handleRateMouseUp,
      onCapacityDialogOpen,
      pointIndex,
      startDrag,
    ],
  );

  const handleBFactorMouseMove = React.useCallback(
    (e: MouseEvent) => {
      const { id } = capacityEvent;
      if (!id || !svgSizes) return;
      const newYPosition = e.pageY - svgSizes.top;
      const xPosition = e.pageX - svgSizes.left;
      const newRate = yScale.invert(newYPosition);
      const dateOfMovement = xScale.invert(xPosition);

      capacityContext.changeBFactor(
        trellisTitle as Phase,
        dateOfMovement,
        newRate / grossNetCoefficient,
      );
    },
    [
      capacityEvent,
      capacityContext.changeBFactor,
      onBFactorDrag,
      svgSizes,
      trellisTitle,
      xScale,
      yScale,
      grossNetCoefficient,
    ],
  );

  const handleBFactorMouseUp = React.useCallback(
    (e: MouseEvent) => {
      capacityContext.finishEditingCurve();
      finishDrag();
      document.removeEventListener('mousemove', handleBFactorMouseMove);
      document.removeEventListener('mouseup', handleBFactorMouseUp);
    },
    [capacityContext.finishEditingCurve, finishDrag, handleBFactorMouseMove],
  );

  const handleLineMouseDown = React.useCallback(
    (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
      if (isLockSlopeCapacityLine) {
        onCapacityDialogOpen(pointIndex, capacityEvent.id);
        return;
      }
      if (isEditable) {
        startDrag();
        if (!capacityDialog.show || capacityDialog.index !== pointIndex) {
          onCapacityDialogOpen(pointIndex, capacityEvent.id);
        }
        if (isBPressed) {
          document.addEventListener('mousemove', handleBFactorMouseMove);
          document.addEventListener('mouseup', handleBFactorMouseUp);
        } else {
          if (svgSizes && updatedEvent) {
            const newYPosition = e.pageY - svgSizes.top;
            const xPosition = e.pageX - svgSizes.left;
            const newRate = yScale.invert(newYPosition);

            const dateOfMovement = xScale.invert(xPosition);
            const offset =
              newRate -
              countCapacity(
                dateOfMovement,
                updatedEvent,
                trellisTitle.toLocaleLowerCase(),
              );
            setMyState(offset / grossNetCoefficient);
          }
          document.addEventListener('mousemove', handleDeclineMouseMove);
          document.addEventListener('mouseup', handleDeclineMouseUp);
        }
      } else {
        onCapacityDialogOpen(pointIndex, capacityEvent.id);
      }
    },
    [
      capacityDialog.index,
      capacityDialog.show,
      capacityEvent,
      updatedEvent,
      grossNetCoefficient,
      isBPressed,
      isEditable,
      handleBFactorMouseMove,
      handleBFactorMouseUp,
      handleDeclineMouseMove,
      handleDeclineMouseUp,
      onCapacityDialogOpen,
      pointIndex,
      startDrag,
      svgSizes,
      trellisTitle,
      isLockSlopeCapacityLine,
      xScale,
      yScale,
    ],
  );

  const createLine: (data: any) => any = line()
    .x((d: any) => xScale(d.date || 0))
    .y((d: any) => yScale(d.capacity || 0));

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === B_KEYCODE) setBKeyPressing(true);
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.keyCode === B_KEYCODE) setBKeyPressing(false);
    };
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleRateMouseMove);
      document.removeEventListener('mouseup', handleRateMouseUp);
      document.removeEventListener('mousemove', handleDeclineMouseMove);
      document.removeEventListener('mouseup', handleDeclineMouseUp);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <g
      className={
        isEditable ? 'capacity-interactive interactive' : 'capacity-interactive'
      }
    >
      <path
        d={createLine(capacitySegment)}
        fill="none"
        stroke="transparent"
        strokeWidth="20"
        onMouseDown={handleLineMouseDown}
        cursor={
          isEditable && !isLockSlopeCapacityLine ? 'row-resize' : 'pointer'
        }
        className={
          isEditable
            ? 'capacity-interactive interactive'
            : 'capacity-interactive'
        }
        pointerEvents="stroke"
      />
      {capacitySegment[0] && (
        <circle
          cx={xScale(capacitySegment[0].date) || 0}
          cy={yScale(capacitySegment[0].capacity) || 0}
          fill="transparent"
          // fill="rgba(0, 73, 179, 0.7"
          r={35}
          stroke="none"
          cursor={isEditable ? 'ns-resize' : 'pointer'}
          onMouseDown={e => handleRateMouseDown(e)}
          clipPath={`url(#${getRateHandleClipPathId(
            trellisTitle,
            capacityEvent.id,
          )})`}
          className={
            isEditable
              ? 'capacity-interactive interactive'
              : 'capacity-interactive'
          }
        />
      )}
    </g>
  );
};

export default React.memo<InteractiveCapacityLineProps>(
  InteractiveCapacityLine,
);
