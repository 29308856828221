import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';
import type { Action, Selector } from 'store/models';

import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';

import {
  NormalizedSeries,
  NormalizedSeriesMapping,
  Series,
  SelectedSeriesMapping,
  NormalizedSensorSeriesMapping,
} from './models';
import {
  CLEAR_ALL_GROUP_SERIES,
  CLEAR_SERIES,
  FETCH_SENSOR_SERIES,
  FETCH_SENSOR_SERIES_MAPPING,
  FETCH_SERIES,
  FETCH_SERIES_DATE_RANGE,
  FETCH_SERIES_MAPPING,
  namespace,
  POPULATE_SENSOR_SERIES,
  SET_GROUP_SERIES,
  SWITCH_SERIES_MAPPING,
} from './SeriesActions';
import { normalizeSeriesMapping, normalizeSeries } from './utils';
import normalizeSensorSeriesMapping from './utils/normalizeSensorSeriesMapping';

const filterRegExp = new RegExp(`${namespace}/`);

export const STATE_KEY = 'series';

interface SeriesState {
  seriesMapping: NormalizedSeriesMapping;
  sensorSeriesMapping: NormalizedSensorSeriesMapping | null;
  selectedSeriesMapping: SelectedSeriesMapping | Record<string, never>;
  series:
    | { [key: string]: { data: NormalizedSeries[]; isPartialData: false } }
    | Record<string, never>;
  fetchedSensorSeries: { [key: string]: boolean };
  sensorSeries: { [key: string]: NormalizedSeries[] };
  groupSeries:
    | { subject: { item: NormalizedSeries[] } }
    | Record<string, never>;
  wasDateRangeFatched: boolean;
}

const initialState: SeriesState = {
  seriesMapping: {},
  sensorSeriesMapping: null,
  selectedSeriesMapping: {},
  series: {},
  fetchedSensorSeries: {},
  sensorSeries: {},
  groupSeries: {},
  wasDateRangeFatched: false,
};

const SeriesReducer = (
  state: SeriesState | undefined = initialState,
  action: Action,
) => {
  switch (action.type) {
    case `${FETCH_SERIES_MAPPING}_SUCCESS`: {
      const listSeriesMapping = getGraphqlPayload(action);

      return R.assoc(
        'seriesMapping',
        normalizeSeriesMapping(listSeriesMapping),
        state,
      );
    }
    case FETCH_SENSOR_SERIES: {
      return R.assocPath(
        ['fetchedSensorSeries', action.payload.meta.seriesId],
        true,
        state,
      );
    }
    case `${FETCH_SENSOR_SERIES_MAPPING}_SUCCESS`: {
      const seriesMapping = getGraphqlPayload(action);

      return R.assoc(
        'sensorSeriesMapping',
        normalizeSensorSeriesMapping(seriesMapping),
        state,
      );
    }
    case SET_GROUP_SERIES: {
      const { series, groupSubject, groupName } = action.payload;
      return R.assocPath(
        ['groupSeries', groupSubject, groupName],
        series,
        state,
      );
    }
    case POPULATE_SENSOR_SERIES: {
      const { seriesId, data } = action.payload;
      return R.assocPath(['sensorSeries', seriesId], data, state);
    }
    case CLEAR_ALL_GROUP_SERIES: {
      if (!action.payload) {
        return R.assocPath(['groupSeries'], {}, state);
      }
      const { subject, item } = action.payload;
      const currenData = R.path(['groupSeries', subject, item], state);
      return R.assocPath(
        ['groupSeries'],
        { [subject]: { [item]: currenData } },
        state,
      );
    }
    case SWITCH_SERIES_MAPPING: {
      const { id } = action.payload;
      if (R.pathOr(false, ['selectedSeriesMapping', id], state)) {
        return R.assocPath(
          ['selectedSeriesMapping'],
          R.omit(id, state.selectedSeriesMapping),
          state,
        );
      }
      return R.assocPath(
        ['selectedSeriesMapping'],
        R.assoc(id, true, state.selectedSeriesMapping),
        state,
      );
    }
    case `${FETCH_SERIES_DATE_RANGE}_SUCCESS`: {
      const wellId = getGraphqlPrevActionVariables(action).payload.wellId;
      const { seriesChartData } = getGraphqlPayload(action);
      const { seriesData } = JSON.parse(seriesChartData);
      const series = normalizeSeries(seriesData);

      return R.assocPath(
        ['series', wellId],
        { data: series, isPartialData: true },
        state,
      );
    }
    case `${FETCH_SERIES}_SUCCESS`: {
      const wellId = getGraphqlPrevActionVariables(action).payload.wellId;
      const { seriesChartData } = getGraphqlPayload(action);
      const { seriesData } = JSON.parse(seriesChartData);
      const series = normalizeSeries(seriesData);
      return R.assocPath(
        ['series', wellId],
        { data: series, isPartialData: false },
        state,
      );
    }
    default: {
      return state;
    }
    case CLEAR_SERIES: {
      return {
        ...state,
        sensorSeries: [],
        series: {},
        fetchedSensorSeries: {},
      };
    }
  }
};

export const getSerieState = (state: any): SeriesState => state[STATE_KEY];
export const getSeriesMapping: Selector<NormalizedSeriesMapping> =
  createSelector(getSerieState, (state: SeriesState) => state.seriesMapping);

export const getSensorSeriesMapping: Selector<NormalizedSensorSeriesMapping | null> =
  createSelector(
    getSerieState,
    (state: SeriesState) => state.sensorSeriesMapping,
  );

export const getCommonSeriesMapping: Selector<NormalizedSensorSeriesMapping> =
  createSelector(
    getSeriesMapping,
    getSensorSeriesMapping,
    (seriesMapping, sensorSeriesMapping) => {
      if (R.isEmpty(seriesMapping) || sensorSeriesMapping === null) return {};
      const convertedSensorSeriesMapping = Object.values(
        sensorSeriesMapping,
      ).reduce((acc, n, idx) => {
        acc[n.sensorSeriesId] = {
          id: n.sensorSeriesId,
          aggregateMethod: '',
          color: n.color,
          displayName: n.name,
          order: idx + 1,
          seriesIndex: -1,
          sourceName: n.source,
          units: n.units,
        };
        return acc;
      }, {});

      return {
        ...seriesMapping,
        ...convertedSensorSeriesMapping,
      };
    },
  );

export const getSelectedSeriesMapping: Selector<SelectedSeriesMapping> =
  createSelector(
    getSerieState,
    (state: SeriesState) => state.selectedSeriesMapping,
  );

export const getSensorSeries: Selector<NormalizedSeries[]> = createSelector(
  getSerieState,
  (_, props) => props.seriesId,
  (state, seriesId) => R.pathOr([], ['sensorSeries', seriesId], state),
);

export const getSensorSeriesAvailableDates: Selector<{
  [key: string]: { min: Date; max: Date };
}> = createSelector(getSerieState, state => {
  const series = state.sensorSeries;

  return Object.entries(series).reduce((acc, [id, series]) => {
    if (series.length !== 0)
      acc[id] = {
        min: series[0].day,
        max: series[series.length - 1].day,
      };
    return acc;
  }, {});
});

export const getSensorSeriesAvailableRange: Selector<{
  min: Date;
  max: Date;
} | null> = createSelector(getSerieState, state => {
  const series = state.sensorSeries;

  const dates = Object.entries(series).reduce<{
    min: Date | null;
    max: Date | null;
  }>(
    (acc, [id, series]) => {
      if (series.length !== 0) {
        if (!acc.min || acc.min.getTime() > series[0].day.getTime())
          acc.min = series[0].day;
        if (
          !acc.max ||
          acc.max.getTime() < series[series.length - 1].day.getTime()
        )
          acc.max = series[series.length - 1].day;
      }
      return acc;
    },
    { min: null, max: null },
  );

  return dates.min === null
    ? null
    : (dates as {
        min: Date;
        max: Date;
      });
});

export const getAllSeriesByWell: Selector<{
  [key: string]: NormalizedSeries[];
}> = createSelector(
  getSerieState,
  (_, props) => props.wellId,
  (state: SeriesState, wellId: string) => {
    const dataSeries = R.pathOr([], ['series', wellId, 'data'], state);
    const sensorSeries = R.pathOr({}, ['sensorSeries'], state);

    const flatSensorSeries = Object.values<NormalizedSeries[]>(sensorSeries)
      .flat()
      .sort((a, b) => a.day.getTime() - b.day.getTime());

    const joinedSeries: Series[] = dataSeries
      .concat(flatSensorSeries)
      .reduce((acc, n) => {
        const day = n.day.toISOString();
        if (!acc[day]) acc[day] = n;
        else acc[day] = { ...acc[day], ...n };
        return acc;
      }, {});

    const sorted = Object.values(joinedSeries).sort(
      (a, b) => a.day.getTime() - b.day.getTime(),
    );

    return {
      data: dataSeries,
      sensor: flatSensorSeries,
      joined: sorted,
    };
  },
);

export const getSeriesFetchedStatusByWell: Selector<Series[]> = createSelector(
  getSerieState,
  (_, props) => props.wellId,
  (state: SeriesState, wellId: string) =>
    R.pathOr(false, ['series', wellId, 'isPartialData'], state),
);

export const getGroupedSeries: Selector<Series[]> = createSelector(
  getSerieState,
  (_, props) => props,
  (state: SeriesState, props: { subject: string; item: string }) => {
    const series = R.pathOr(
      [],
      ['groupSeries', props.subject, props.item],
      state,
    );
    return series;
  },
);

export default filterActions(SeriesReducer as any, action =>
  action.type.match(filterRegExp),
);
