import gql from 'graphql-tag';

import { GraphqlMutationAction, GraphqlQueryAction } from 'store/models';

export const namespace = 'AUTH';
export const CHANGE_MY_PASSWORD = `${namespace}/CHANGE_MY_PASSWORD`;
export const CHANGE_PASSWORD = `${namespace}/CHANGE_PASSWORD`;
export const CHANGE_REMEMBER = `${namespace}/CHANGE_REMEMBER`;
export const LOGIN = `${namespace}/LOGIN`;
export const LOGIN_VIA_SSO = `${namespace}/LOGIN_VIA_SSO`;
export const LOGOUT = `${namespace}/LOGOUT`;
export const REGISTER = `${namespace}/REGISTER`;
export const UPDATE_TOKEN = `${namespace}/UPDATE_TOKEN`;
export const FETCH_AUTH_CONFIG = `${namespace}/FETCH_AUTH_CONFIG`;
export const FETCH_USER_PERMISSIONS = `${namespace}/FETCH_USER_PERMISSIONS`;

const LOGIN_MUTATION = gql`
  mutation login($payload: SignInInput!) {
    login(data: $payload) {
      token
      user {
        id
        email
        firstName
        lastName
        isAdmin
        isAllowedEditCapChanges
        isAllowedEditVarEvents
        isAllowedEditAllocIssues
        isAllowedEditRibbonEvents
        inactive
        mustChangePassword
        userUuid
        isUserManager
      }
    }
  }
`;

type loginAC = (payload: {
  email: string;
  password: string;
}) => GraphqlMutationAction;

export const login: loginAC = payload => ({
  type: LOGIN,
  payload: {
    key: 'login',
    graphql: {
      mutation: LOGIN_MUTATION,
      variables: { payload },
    },
  },
});

const LOGIN_VIA_SSO_MUTATION = gql`
  mutation loginViaSSO($payload: SignInSSOInput!) {
    loginViaSSO(data: $payload) {
      token
      user {
        id
        email
        firstName
        lastName
        isAdmin
        isAllowedEditCapChanges
        isAllowedEditVarEvents
        isAllowedEditAllocIssues
        isAllowedEditRibbonEvents
        inactive
        mustChangePassword
        userUuid
        isUserManager
      }
    }
  }
`;

type loginViaSSOAC = (payload: { code: string }) => GraphqlMutationAction;

export const loginViaSSO: loginViaSSOAC = payload => ({
  type: LOGIN_VIA_SSO,
  payload: {
    key: 'loginViaSSO',
    graphql: {
      mutation: LOGIN_VIA_SSO_MUTATION,
      variables: { payload },
    },
  },
});

const REGISTER_MUTATION = gql`
  mutation signup($payload: SignUpInput!) {
    signup(data: $payload) {
      token
      user {
        id
        email
        firstName
        lastName
        isAdmin
        isAllowedEditCapChanges
        isAllowedEditVarEvents
        isAllowedEditAllocIssues
        isAllowedEditRibbonEvents
        inactive
        mustChangePassword
        userUuid
        isUserManager
      }
    }
  }
`;

type registerAC = (payload: {
  password: string;
  email: string;
}) => GraphqlMutationAction;

export const register: registerAC = payload => ({
  type: REGISTER,
  payload: {
    key: 'signup',
    graphql: {
      mutation: REGISTER_MUTATION,
      variables: { payload },
    },
  },
});

export const logout = () => ({
  type: LOGOUT,
});

export const changeRemember = () => ({
  type: CHANGE_REMEMBER,
});

const CHANGE_MY_PASSWORD_MUTATION = gql`
  mutation ($payload: ChangeMyPasswordInput!) {
    changeMyPassword(data: $payload) {
      id
    }
  }
`;

type changeMyPasswordAC = (payload: {
  prevPassword: string;
  newPassword: string;
}) => GraphqlMutationAction;

export const changeMyPassword: changeMyPasswordAC = payload => ({
  type: CHANGE_MY_PASSWORD,
  payload: {
    key: 'changeMyPassword',
    graphql: {
      mutation: CHANGE_MY_PASSWORD_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ($payload: ChangePasswordInput!) {
    changePassword(data: $payload) {
      id
      email
      firstName
      lastName
      isAdmin
      isAllowedEditCapChanges
      isAllowedEditVarEvents
      isAllowedEditAllocIssues
      isAllowedEditRibbonEvents
      inactive
      mustChangePassword
    }
  }
`;

type changePasswordAC = (payload: {
  email: string;
  newPassword: string;
}) => GraphqlMutationAction;

export const changePassword: changePasswordAC = payload => ({
  type: CHANGE_PASSWORD,
  payload: {
    key: 'changePassword',
    graphql: {
      mutation: CHANGE_PASSWORD_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const UPDATE_TOKEN_MUTATION = gql`
  mutation {
    updateAuthToken {
      token
      user {
        id
        email
        firstName
        lastName
        isAdmin
        isAllowedEditCapChanges
        isAllowedEditVarEvents
        isAllowedEditAllocIssues
        isAllowedEditRibbonEvents
        inactive
        mustChangePassword
      }
    }
  }
`;

type updateTokenAC = () => GraphqlMutationAction;

export const updateToken: updateTokenAC = () => ({
  type: UPDATE_TOKEN,
  payload: {
    key: 'changePassword',
    graphql: {
      mutation: UPDATE_TOKEN_MUTATION,
    },
  },
});

const FETCH_AUTH_CONFIG_QUERY = gql`
  query {
    getAuthConfig {
      authScheme
      authConfig {
        provider
      }
      internalAuthConfig {
        provider
      }
      cognitoAuthDomain
      cognitoGlobalUrl
      cognitoClientId
      cognitoRedirectUri
    }
  }
`;

type fetchAuthConfigAC = () => GraphqlMutationAction;

export const fetchAuthConfig: fetchAuthConfigAC = () => ({
  type: FETCH_AUTH_CONFIG,
  payload: {
    key: 'getAuthConfig',
    graphql: {
      mutation: FETCH_AUTH_CONFIG_QUERY,
    },
  },
});

const FETCH_USER_PERMISSIONS_QUERY = gql`
  query ($id: ID!) {
    getUser(id: $id) {
      isAllowedEditCapChanges
      isAllowedEditVarEvents
      isAllowedEditAllocIssues
      isAllowedEditRibbonEvents
      isAdmin
      isUserManager
    }
  }
`;

type fetchUserPermissionsAC = (id: string) => GraphqlQueryAction;

export const fetchUserPermissions: fetchUserPermissionsAC = id => ({
  type: FETCH_USER_PERMISSIONS,
  payload: {
    key: 'getUser',
    graphql: {
      query: FETCH_USER_PERMISSIONS_QUERY,
      variables: { id },
    },
  },
});
