import ButtonDropdownSplit from 'components/ButtonDropdownSplit';
import { BarsMenuIcon } from 'components/Icons';

import useSeriesLayouts from '../hooks/useSeriesLayouts';

type SeriesLayoutsDropdownButtonProps = {
  onClick: () => any;
};

const SeriesLayoutsDropdownButton = ({
  onClick,
}: SeriesLayoutsDropdownButtonProps) => {
  const {
    baseSeriesLayouts,
    userSeriesLayouts,
    currentSeriesLayout,
    setCurrentSeriesLayout,
  } = useSeriesLayouts();

  const hasBaseLayouts = Boolean(baseSeriesLayouts.length);
  const hasUserLayouts = Boolean(userSeriesLayouts.length);

  return (
    <ButtonDropdownSplit
      buttonProps={{ width: 31, height: 31 }}
      buttonAction={onClick}
      buttonChildren={<BarsMenuIcon />}
    >
      {(hasBaseLayouts || hasUserLayouts) && (
        <>
          {hasBaseLayouts && (
            <ButtonDropdownSplit.MenuTitle>
              Shared Layouts:
            </ButtonDropdownSplit.MenuTitle>
          )}
          {baseSeriesLayouts.map(layout => (
            <ButtonDropdownSplit.MenuItem
              isActive={layout.id === currentSeriesLayout.id}
              key={layout.name}
              onClick={() => setCurrentSeriesLayout(layout)}
            >
              {layout.name}
            </ButtonDropdownSplit.MenuItem>
          ))}

          {hasUserLayouts && (
            <ButtonDropdownSplit.MenuTitle>
              Your Layouts:
            </ButtonDropdownSplit.MenuTitle>
          )}
          {userSeriesLayouts.map(layout => (
            <ButtonDropdownSplit.MenuItem
              isActive={layout.id === currentSeriesLayout.id}
              key={layout.name}
              onClick={() => setCurrentSeriesLayout(layout)}
            >
              {layout.name}
            </ButtonDropdownSplit.MenuItem>
          ))}
        </>
      )}
    </ButtonDropdownSplit>
  );
};

export default SeriesLayoutsDropdownButton;
