import { init } from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Environment } from './config/EnvironmentConfig';
import sentryConfig from './config/SentryConfig';
import { unregister } from './registerServiceWorker';

// Unregister service worker
unregister();

if (sentryConfig.environment !== Environment.local) {
  init(sentryConfig);
}

const mountEl = document.getElementById('root');

const render = () => {
  if (mountEl) {
    return ReactDOM.render(<App />, mountEl);
  }

  throw new Error('React failed to mount, becuase mount element is missing');
};

render();

if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}
