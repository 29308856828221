import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { timeFormat } from 'd3-time-format';
import { useDispatch, useSelector } from 'react-redux';

import { getId } from 'modules/auth/AuthReducer';
import { EventNote, extensionsRegex } from 'modules/eventNotes/models';
import { uploadAttachment } from 'modules/noteAttachment/NoteAttachmentActions';
import {
  pushNotification,
  hideNotification,
} from 'modules/notification/NotificationActions';
import { User } from 'modules/user/models/user';
import { getUsers, getUsersByEmailName } from 'modules/user/UserReducer';

import Portal from 'components/Portal';

import EventNoteForm from '../components/EventNoteForm';
import InactiveNote from '../components/InactiveNote';
import {
  createEventNoteLocally,
  removeEventNoteAttachment,
  removeEventNoteLocally,
  updateEventNoteLocally,
} from '../EventNotesActions';
import { getEventNotes } from '../EventNotesReducer';
import ImageDatailOverlay from '../components/ImageDetailOverlay';
import {
  openImageDetailsOverlay,
  closeImageDetailsOverlay,
} from 'modules/ui/UIActions';
import { getNoteImageDetailsOverlay } from 'modules/ui/UIReducer';
import AlertWindow from 'components/AlertWindow';
const ESC_KEYCODE = 27;

const getUserDisplayName = (users: { [id: string]: User }, note: EventNote) => {
  const { userOverwrite, userId } = note;
  if (!R.isNil(userOverwrite) && userOverwrite !== '') {
    return userOverwrite as string;
  }
  const firsName = R.pathOr('', [userId, 'firstName'], users);
  const lastName = R.pathOr('', [userId, 'lastName'], users);
  const userName = `${firsName} ${lastName}`;

  return userName.trim() !== '' ? userName : 'Deleted user';
};

interface EventNoteThreadProps {
  wellId: string;
  eventId: string;
  eventType: string;
  noAnyMessages?: boolean;
  onNoteSaveStateChange?: (hasUnsaved: boolean) => void;
}

const EventNoteThread = ({
  eventId,
  wellId,
  eventType,
  noAnyMessages,
  onNoteSaveStateChange,
}: EventNoteThreadProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getId);
  const notes = useSelector(store =>
    getEventNotes(store, { wellId, eventType }),
  );
  const users = useSelector(getUsers);
  const usersByEmailName = useSelector(getUsersByEmailName);
  const imageDetailsOverlay = useSelector(getNoteImageDetailsOverlay);
  const tempAttachments = React.useRef({});
  const [tempLocalAttachments, setTempLocalAttachments] = React.useState({});
  const [idNoteToRemove, setIdNoteToRemove] = React.useState('');
  const [selectedNote, setSelectedNote] = React.useState<EventNote | null>(
    null,
  );
  const [text, setText] = React.useState(selectedNote?.noteText || '');
  const [newNoteText, setNewNoteText] = React.useState('');
  const [editedNoteId, setEditedNoteId] = React.useState('');
  const [selectedImage, setSelectedImage] = React.useState('');

  const hasUnsaved = React.useMemo(
    () =>
      (selectedNote && text !== selectedNote.noteText) ||
      newNoteText.length !== 0,
    [text.length, newNoteText.length, selectedNote],
  );

  const onSetTempAttachments = (data: any, noteId) => {
    const { item, timestamp } = data;

    const oldAttachments = tempAttachments.current;
    tempAttachments.current = {
      ...oldAttachments,
      [noteId]: { ...oldAttachments[noteId], [timestamp]: item },
    };
    setTempLocalAttachments(
      R.assocPath([noteId, timestamp], item, tempLocalAttachments),
    );
  };

  const onDeleteTempAttachments = React.useCallback(
    (timestamp: any, noteId: string, fileId: string) => {
      const oldAttachments = tempAttachments.current;
      tempAttachments.current = R.dissocPath(
        [noteId, timestamp],
        oldAttachments,
      );
      if (noteId !== 'newNote') {
        dispatch(
          removeEventNoteAttachment({
            wellId,
            eventType,
            eventId,
            noteId,
            fileId,
          }),
        );
      }
      setTempLocalAttachments(
        R.dissocPath([noteId, timestamp], oldAttachments),
      );
    },
    [tempAttachments, dispatch, wellId, eventType, eventId],
  );

  const onDeleteExistedNoteAttachments = React.useCallback(
    (timestamp: any, noteId: string, fileIds: string[]) => {
      const oldAttachments = tempAttachments.current;
      tempAttachments.current = R.dissocPath(
        [noteId, timestamp],
        oldAttachments,
      );
      fileIds.forEach(fileId => {
        dispatch(
          removeEventNoteAttachment({
            wellId,
            eventType,
            eventId,
            noteId,
            fileId,
          }),
        );
      });

      setTempLocalAttachments(
        R.dissocPath([noteId, timestamp], oldAttachments),
      );
    },
    [tempAttachments, dispatch, wellId, eventType, eventId],
  );

  const onCloseImageDetailsOverlay = React.useCallback(() => {
    dispatch(closeImageDetailsOverlay());
  }, [dispatch]);

  const onOpenImageDetailsOverlay = React.useCallback(() => {
    dispatch(openImageDetailsOverlay());
  }, [dispatch]);

  const onSelectNote = React.useCallback(
    (note: EventNote | null) => {
      if (note === null || R.isEmpty(note.attachments)) {
        onCloseImageDetailsOverlay();
        setSelectedNote(note);
        return;
      }
      if (note.id !== editedNoteId) {
        setText(note?.noteText);
        setEditedNoteId('');
      }
      setSelectedNote(note);
    },
    [onCloseImageDetailsOverlay, editedNoteId],
  );

  const currentEventNotes = React.useMemo(() => {
    const notesList: EventNote[] = R.compose(
      R.values(),
      R.pathOr({}, [eventId]),
    )(notes);

    return notesList.sort(
      (a, b) => b.noteTimestamp.getTime() - a.noteTimestamp.getTime(),
    );
  }, [eventId, notes]);

  const onUploadAttachment = React.useCallback(
    (attachment: Record<string, any>) => dispatch(uploadAttachment(attachment)),
    [dispatch],
  );

  const notificate = React.useCallback(
    message => dispatch(pushNotification({ message })),
    [dispatch],
  );
  const hideMessage = React.useCallback(
    () => dispatch(hideNotification()),
    [dispatch],
  );

  const onCreateEventNote = React.useCallback(
    (noteText, attachments) => {
      dispatch(
        createEventNoteLocally({
          wellId,
          noteText: noteText.trim(),
          eventType,
          eventId,
          attachments,
        }),
        (tempAttachments.current = R.assoc(
          'newNote',
          {},
          tempAttachments.current,
        )),
      );
    },
    [wellId, eventType, eventId, dispatch, tempAttachments],
  );

  const onRemoveEventNote = React.useCallback(
    (id: string) => {
      if (selectedNote?.id === id) {
        onSelectNote(null);
      }
      dispatch(removeEventNoteLocally({ wellId, id, eventType, eventId }));
    },
    [wellId, eventType, eventId, dispatch, selectedNote, onSelectNote],
  );

  const onUpdateEventNote = React.useCallback(
    (noteText: string, note: EventNote, attachments) => {
      setEditedNoteId('');
      dispatch(
        updateEventNoteLocally({
          wellId,
          note: { ...note, noteText, isEdited: true, attachments },
        }),
      );
    },
    [wellId, setEditedNoteId, dispatch],
  );

  const onCloseNoteImput = React.useCallback(() => {
    setEditedNoteId('');
    onSelectNote(null);
  }, [setEditedNoteId, onSelectNote]);
  const onStartNoteEdeting = React.useCallback(
    (id: string) => {
      const note = R.path([eventId, id], notes);
      if (note) {
        setEditedNoteId(id);
        setSelectedNote(note);
        setText(note.noteText);
      }
    },
    [setEditedNoteId, notes, eventId, setSelectedNote],
  );

  const onImageAttacmentClick = React.useCallback(
    (noteIndex: number, imageId: string) => {
      onOpenImageDetailsOverlay();
      const note = currentEventNotes[noteIndex];
      onSelectNote(note);
      setSelectedImage(imageId);
      if (note.id !== selectedNote?.id) setText(note.noteText);
    },
    [currentEventNotes, selectedNote, onSelectNote, onOpenImageDetailsOverlay],
  );
  const notesWithImages = React.useMemo(() => {
    const filtredNotes = currentEventNotes.filter(note => {
      const images = note.attachments.filter(attachment =>
        attachment.name.match(extensionsRegex),
      );
      return !R.isEmpty(images);
    });
    return filtredNotes;
  }, [currentEventNotes]);

  const deleteEmptyAttachments = React.useCallback(
    () =>
      currentEventNotes.forEach(note => {
        if (!note.attachments.length && !note.noteText) {
          onRemoveEventNote(note.id);
        }
      }),
    [currentEventNotes, onRemoveEventNote],
  );

  React.useEffect(() => {
    const handleEscKeyDown = (e: KeyboardEvent) => {
      const { keyCode, target } = e;
      if (!(target instanceof window.HTMLElement)) {
        return;
      }
      const { tagName } = target;
      if (tagName === 'INPUT' || tagName === 'TEXTAREA') return;

      if (keyCode === ESC_KEYCODE && imageDetailsOverlay.show) {
        onCloseImageDetailsOverlay();
      }
    };
    document.addEventListener('keydown', handleEscKeyDown);

    return () => {
      document.removeEventListener('keydown', handleEscKeyDown);
      deleteEmptyAttachments();
      dispatch(closeImageDetailsOverlay);
    };
  });

  React.useEffect(() => {
    onNoteSaveStateChange?.call(null, hasUnsaved);
  }, [hasUnsaved]);

  return (
    <>
      <EventNoteThread.Wrapper>
        <EventNoteThread.Container>
          <EventNoteThread.PaddedContainer>
            <EventNoteForm
              onSubmit={onCreateEventNote}
              noteText={newNoteText}
              onUploadAttachment={onUploadAttachment}
              usersByEmailName={usersByEmailName}
              initialText={''}
              setText={setNewNoteText}
              noteId="newNote"
              tempAttachments={R.pathOr(
                {},
                ['newNote'],
                tempAttachments.current,
              )}
              onSetTempAttachments={onSetTempAttachments}
              onDeleteTempAttachments={onDeleteTempAttachments}
              notificate={notificate}
              hideNotification={hideMessage}
            />
          </EventNoteThread.PaddedContainer>

          <EventNoteThread.Divider noAnyMessages={noAnyMessages} />

          <EventNoteThread.PaddedMessagesContainer>
            <EventNoteThread.DayNotes>
              {currentEventNotes.map((note, i) => {
                return (
                  <InactiveNote
                    onDeleteExistedNoteAttachments={
                      onDeleteExistedNoteAttachments
                    }
                    onDeleteTempAttachments={onDeleteTempAttachments}
                    key={note.id}
                    dateLabel={timeFormat('%a %m/%d/%Y %_I:%M %p')(
                      note.noteTimestamp,
                    )}
                    hideNotification={hideMessage}
                    isEdited={note.isEdited}
                    isCurrentUser={currentUser === note.userId}
                    initialNoteAttachments={selectedNote?.attachments || []}
                    initialText={selectedNote?.noteText || ''}
                    isEditing={note.id === editedNoteId}
                    noteAttachments={note.attachments}
                    noteId={note.id}
                    noteText={
                      selectedNote?.id === note.id ? text : note.noteText
                    }
                    notificate={notificate}
                    onAttacmentClick={imageId =>
                      onImageAttacmentClick(i, imageId)
                    }
                    onCloseNoteImput={onCloseNoteImput}
                    onDeleteClick={() => {
                      setIdNoteToRemove(note.id);
                    }}
                    onEditClick={onStartNoteEdeting}
                    onRemoveEventNote={onRemoveEventNote}
                    onSubmit={(text, attachments) => {
                      setSelectedNote(null);
                      onUpdateEventNote(text, note, attachments);
                      tempAttachments.current = R.dissocPath(
                        [note.id],
                        tempAttachments.current,
                      );
                    }}
                    onSetTempAttachments={onSetTempAttachments}
                    onUploadAttachment={onUploadAttachment}
                    setText={setText}
                    tempAttachments={R.pathOr(
                      {},
                      [note.id],
                      tempAttachments.current,
                    )}
                    userName={getUserDisplayName(users, note)}
                    usersByEmailName={usersByEmailName}
                  />
                );
              })}
            </EventNoteThread.DayNotes>
          </EventNoteThread.PaddedMessagesContainer>
        </EventNoteThread.Container>
      </EventNoteThread.Wrapper>

      {selectedNote && imageDetailsOverlay.show ? (
        <Portal root="imageDetailRoot">
          <ImageDatailOverlay
            currentNote={selectedNote}
            notesWithImages={notesWithImages}
            onSetSelectedNote={onSelectNote}
            isAllowModification={currentUser === selectedNote.userId}
            dateLabel={timeFormat('%_m/%e/%y at %_I:%M %p')(
              selectedNote.noteTimestamp,
            )}
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            initialText={selectedNote?.noteText || ''}
            noteText={text}
            onCloseImageDetailsOverlay={onCloseImageDetailsOverlay}
            onDeleteClick={() => {
              setIdNoteToRemove(selectedNote.id);
            }}
            onSubmit={text => {
              onUpdateEventNote(text, selectedNote, []);
            }}
            setText={t => {
              setText(t);
            }}
            tempAttachments={R.pathOr(
              {},
              [selectedNote.id],
              tempAttachments.current,
            )}
            userName={getUserDisplayName(users, selectedNote)}
            usersByEmailName={usersByEmailName}
          />
        </Portal>
      ) : null}

      {idNoteToRemove && (
        <AlertWindow
          handleClose={() => setIdNoteToRemove('')}
          onDelete={() => onRemoveEventNote(idNoteToRemove)}
          subject="note"
        />
      )}
    </>
  );
};

EventNoteThread.Wrapper = styled.div`
  padding-bottom: 10px;
  flex: 1;
`;

EventNoteThread.Container = styled.div``;

EventNoteThread.PaddedContainer = styled.div`
  padding: 0 16px;
`;

EventNoteThread.PaddedMessagesContainer = styled.div`
  padding: 0 16px;
`;

EventNoteThread.DaySection = styled.div``;

EventNoteThread.DayTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
`;

EventNoteThread.DayNotes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
`;

EventNoteThread.Divider = styled.div`
  height: 1px;
  ${props => (props.noAnyMessages ? '' : 'background-color: #c1c1c1')};
`;

EventNoteThread.Label = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
`;

EventNoteThread.EditNoteWrapper = styled.div`
  padding: 12px 0 0 0;
  margin-bottom: -11px;
`;

EventNoteThread.UsernameLabel = styled.p`
  font-weight: bold;
  font-size: 12px;
`;

EventNoteThread.Date = styled.p`
  font-size: 10px;
  color: #9a9a9a;
`;

EventNoteThread.Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 20px;
  outline: none;
`;

EventNoteThread.Date = styled.p`
  color: #9a9a9a;
  margin: 0 5px;
`;

EventNoteThread.NoteInformationContainer = styled.div`
  display: flex;
  align-content: center;
  margin: 3px 0;
  font-size: 12px;
`;
export default EventNoteThread;
