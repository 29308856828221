import { utcDay } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';

interface RegionOfInterestProps {
  changeMinDrilldownTableDate: (minDate: Date) => void;
  changeMaxDrilldownTableDate: (maxDate: Date) => void;
  clearDrilldownTable: () => void;
  finishDrag: () => void;
  hasCapacityChanges?: boolean;
  height: number;
  leftOffset: number;
  minDate: Date;
  maxDate: Date;
  onCapacityDialogClose?: () => void;
  position: number;
  startDrag: () => void;
  width: number;
  xScale: any;
}

const RegionOfInterest = ({
  changeMaxDrilldownTableDate,
  changeMinDrilldownTableDate,
  clearDrilldownTable,
  finishDrag,
  hasCapacityChanges,
  height,
  leftOffset,
  minDate,
  maxDate,
  onCapacityDialogClose,
  position,
  startDrag,
  width,
  xScale,
}: RegionOfInterestProps) => {
  const minPosition = xScale(minDate);
  const maxPosition = xScale(utcDay.offset(maxDate, 1));
  const extremeMinDates = [xScale.domain()[0], maxDate];
  const extremeMaxDates = [minDate, xScale.domain()[1]];

  const handleMinMouseMove = React.useCallback(
    (e: MouseEvent) => {
      const pointerPosition = e.clientX - leftOffset - Y_AXIS_WIDTH - 10;
      const dateOfCursor = utcDay.round(xScale.invert(pointerPosition));
      if (dateOfCursor < extremeMinDates[0]) {
        changeMinDrilldownTableDate(extremeMinDates[0]);
        return;
      } else if (dateOfCursor > extremeMinDates[1]) {
        changeMinDrilldownTableDate(extremeMinDates[1]);
        return;
      }
      changeMinDrilldownTableDate(dateOfCursor);
    },
    [changeMinDrilldownTableDate, extremeMinDates, xScale, leftOffset],
  );

  const handleMinMouseUp = React.useCallback(() => {
    finishDrag();
    clearDrilldownTable();
    document.removeEventListener('mousemove', handleMinMouseMove);
    document.removeEventListener('mouseup', handleMinMouseUp);
  }, [finishDrag, clearDrilldownTable, handleMinMouseMove]);

  const handleMinMouseDown = e => {
    e.preventDefault();
    if (hasCapacityChanges && onCapacityDialogClose) {
      onCapacityDialogClose();
    } else {
      startDrag();
      document.addEventListener('mousemove', handleMinMouseMove);
      document.addEventListener('mouseup', handleMinMouseUp);
    }
  };

  const handleMaxMouseMove = React.useCallback(
    (e: MouseEvent) => {
      const pointerPosition = e.clientX - leftOffset - Y_AXIS_WIDTH - 10;
      const dateOfCursor = utcDay.offset(
        utcDay.round(xScale.invert(pointerPosition)),
        -1,
      );
      if (dateOfCursor < extremeMaxDates[0]) {
        changeMaxDrilldownTableDate(extremeMaxDates[0]);
        return;
      } else if (dateOfCursor > extremeMaxDates[1]) {
        changeMaxDrilldownTableDate(extremeMaxDates[1]);
        return;
      }
      changeMaxDrilldownTableDate(dateOfCursor);
    },
    [changeMaxDrilldownTableDate, extremeMaxDates, xScale, leftOffset],
  );
  const handleMaxMouseUp = React.useCallback(() => {
    finishDrag();
    clearDrilldownTable();
    document.removeEventListener('mousemove', handleMaxMouseMove);
    document.removeEventListener('mouseup', handleMaxMouseUp);
  }, [clearDrilldownTable, finishDrag, handleMaxMouseMove]);

  const handleMaxMouseDown = React.useCallback(
    e => {
      e.preventDefault();
      if (hasCapacityChanges && onCapacityDialogClose) {
        onCapacityDialogClose();
      } else {
        startDrag();
        document.addEventListener('mousemove', handleMaxMouseMove);
        document.addEventListener('mouseup', handleMaxMouseUp);
      }
    },
    [
      startDrag,
      handleMaxMouseMove,
      handleMaxMouseUp,
      onCapacityDialogClose,
      hasCapacityChanges,
    ],
  );

  React.useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMinMouseMove);
      document.removeEventListener('mouseup', handleMinMouseUp);
      document.removeEventListener('mousemove', handleMaxMouseMove);
      document.removeEventListener('mouseup', handleMaxMouseUp);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RegionOfInterest.Wrapper height={height} width={width} position={position}>
      <RegionOfInterest.Line position={minPosition} />
      <RegionOfInterest.Subline
        position={minPosition}
        onMouseDown={handleMinMouseDown}
        id="region-min"
      />
      <RegionOfInterest.Line position={maxPosition} />
      <RegionOfInterest.Subline
        position={maxPosition}
        id="region-max"
        onMouseDown={handleMaxMouseDown}
      />
    </RegionOfInterest.Wrapper>
  );
};

RegionOfInterest.Wrapper = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 75;
`;

RegionOfInterest.Line = styled.div`
  width: 2px;
  background-color: ${(props: Record<string, any>) =>
    props.theme.colors.primary};
  position: absolute;
  height: 100%;
  top: 0;
  transform: ${(props: Record<string, any>) =>
    `translate(${(props.position - 1).toString()}px)`};

  &::before {
    display: block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid
      ${(props: Record<string, any>) => props.theme.colors.primary};
    position: absolute;
    top: 0;
    left: -6px;
    content: '';
  }
`;

RegionOfInterest.Subline = styled.div`
  position: absolute;
  width: 10px;
  height: 100%;
  left: ${(props: Record<string, any>) => (props.position - 5).toString()}px;
  background: transparent;
  cursor: ew-resize;
  pointer-events: auto;
`;

export default RegionOfInterest;
