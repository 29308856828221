import { area, curveStepAfter } from 'd3-shape';
import * as React from 'react';

import { COLORS } from 'modules/chart/models/chart';

interface VarianceStackedGroupProps {
  clipPath: string;
  layers: any;
  setEventId: (id: string) => void;
  varianceColors: { [varianceOptionId: string]: string };
  xScale: any;
  yScale: any;
}

const VarianceStackedGroup = ({
  clipPath,
  layers,
  setEventId,
  varianceColors,
  xScale,
  yScale,
}: VarianceStackedGroupProps) => {
  const createArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.data.day || 0))
        .y1(d => yScale(d[0]))
        .y0(d => yScale(d[1]))
        .curve(curveStepAfter),
    [xScale, yScale],
  );

  const getColor = React.useCallback(
    layer => {
      const varianceKey = layer.key;
      if (!varianceKey || varianceKey === 'null') {
        return COLORS.uncategorizedVariance;
      } else if (varianceKey === 'allocIssue') {
        return '#f7b4b4';
      }
      return varianceColors[varianceKey] || COLORS.uncategorizedVariance;
    },
    [varianceColors],
  );
  return (
    <>
      <g
        onMouseMove={(e: React.MouseEvent<SVGAElement>) => {
          const target = e.target as SVGPathElement;
          setEventId(target.id);
        }}
      >
        {layers.map((layer, i) => (
          <path
            key={layer.key}
            id={layer.key}
            fill={getColor(layer)}
            d={createArea(layer)}
            clipPath={clipPath}
            className="panInteraction"
          />
        ))}
      </g>
    </>
  );
};

export default React.memo<VarianceStackedGroupProps>(VarianceStackedGroup);
